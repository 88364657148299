import { createAsyncThunk } from "@reduxjs/toolkit"

import { getRatesSendValueBatch, getRatesReceiveValueBatch } from "./service";

export const getRatesSendValuesBatch = createAsyncThunk(
    'sendValues/getRates',
    async ({ currency, send_value_ids, transaction_day }) => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj;

        const params = currency !== undefined ? { cpf_cnpj, send_value_ids, currency, transaction_day } : { cpf_cnpj, send_value_ids };
        const response = await getRatesSendValueBatch(params);
        return response.data;
    }
);

export const getRatesReceiveValuesBatch = createAsyncThunk(
    'receiveValues/getRates',
    async ({ currency, receive_value_ids, transaction_day }) => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj

        const params = currency !== undefined ? { cpf_cnpj, receive_value_ids, currency, transaction_day } : { cpf_cnpj, receive_value_ids }
        const response = await getRatesReceiveValueBatch(params)
        return response.data
    }
)