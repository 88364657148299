import React from "react";
import { Row, Col, Typography, Button } from "antd";

import "./styles.scss";
import { beautifyCpfCnpj, ellipsis } from "../../../utils/beautify";
import { formatCurrency } from "../../../utils/functions";
import CompanyIcon from "../icons/CompanyIcon";
import ProfileIcon from "../icons/ProfileIcon";
import CircleTransactionStatus from "../CircleTransactionStatus";
import { history } from "../../../helpers/history";
import { ArrowUpOutlined, RedoOutlined } from "@ant-design/icons";
import { repeatTransactionStatus } from "../../../utils/consts";


const { Title } = Typography;

const redirectToTransactionPage = (transaction) => {
    const { id, status, owner } = transaction
    let baseUrl = null

    if (owner.type === "company") {
        baseUrl = `/dashboard/empresas/${owner.id}`
    } else if (owner.type === "person") {
        baseUrl = `/dashboard/pessoas/${owner.id}`
    }

    if (transaction.type === "send_value") {
        if (["draft", "need_changes"].includes(status)) {
            history.push(`${baseUrl}/cambio/enviar-valores/${id}`);
        } else {
            history.push(`${baseUrl}/cambio/operacao-envio/${id}`);
        }
    } else if (transaction.type === "receive_value") {
        history.push(`${baseUrl}/cambio/receber-valores/${id}`);
    }
}

const redirectToNewTransaction = (transaction) => {
    const { owner } = transaction
    let baseUrl = null

    if (owner.type === "company") {
        baseUrl = `/dashboard/empresas/${owner.id}`
    } else if (owner.type === "person") {
        baseUrl = `/dashboard/pessoas/${owner.id}`
    }

    if (transaction.type === "send_value") {
        history.push(`${baseUrl}/cambio/enviar-valores`);
        localStorage.setItem("repeatedSendValueId", transaction.id);
    } else if (transaction.type === "receive_value") {
        localStorage.setItem("repeatedReceiveValueId", transaction.id);
        history.push(`${baseUrl}/cambio/receber-valores`);
    }
}

const TransactionCard = ({ data, onClickDuplicate, isResponsible }) => {


    return isResponsible ? (
        <Row
            style={{ width: "100%" }}
            className="card-transaction"
            gutter={[8, 0]}
        >
            <Col
                xs={2} sm={2} className="col-icon"
                onClick={() => redirectToTransactionPage(data)}
            >
                {data.owner.type === "company" ? (
                    <CompanyIcon />
                ) : (
                    <ProfileIcon />
                )}
            </Col>
            <Col xs={22} sm={22} className="transaction-info">
                <Row onClick={() => redirectToTransactionPage(data)}>
                    <Col>
                        <Title level={5}>{data.owner.name}</Title>
                        <div>{beautifyCpfCnpj(data.owner.cpf_cnpj)}</div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="transaction-value"
                            onClick={() => redirectToTransactionPage(data)}
                        >
                            {formatCurrency(data.value, data.currency)}
                            <ArrowUpOutlined rotate={data.type === "send_value" ? 45 : 225} />
                        </div>
                        <Button
                            type="link"
                            onClick={() => onClickDuplicate()}
                            disabled={![
                                // "completed",
                                "finished",
                            ].includes(data.status)}
                        >
                            Repetir Transação
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    ) : (
        <Col sm={24}>
            <Row
                justify="space-between"
                gutter={[0, 0]}
                className="card-transaction"
            >
                <Col sm={1} className="col-icon"
                    onClick={() => redirectToTransactionPage(data)}
                >
                    {data.owner.type === "company" ? (
                        <CompanyIcon />
                    ) : (
                        <ProfileIcon />
                    )}
                </Col>
                <Col
                    sm={13}
                    onClick={() => redirectToTransactionPage(data)}
                >
                    <Title level={5}>{ellipsis(data.owner.name, 50)}</Title>
                    <div>{beautifyCpfCnpj(data.owner.cpf_cnpj)}</div>
                </Col>
                <Col sm={8}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", flexDirection: "column" }}>
                        <div className="transaction-value"
                            onClick={() => redirectToTransactionPage(data)}
                        >
                            {formatCurrency(data.value, data.currency)}
                            <ArrowUpOutlined rotate={data.type === "send_value" ? 45 : 225} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                style={{ width: "120px" }}
                                type="link"
                                onClick={() => onClickDuplicate()}
                                disabled={![
                                    // "completed",
                                    "finished",
                                ].includes(data.status)}
                            >
                                Repetir Transação
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Col>
    );
};

const TransactionStatusCard = ({ data, isResponsible }) => {
    return (
        <>
            {isResponsible ? (
                <Row
                    style={{ width: "100%", marginBottom: "15px" }}
                    onClick={() => redirectToTransactionPage(data)}>
                    <Col xs={24} sm={24}>
                        <Row gutter={[8, 0]}>
                            <Col sm={3} lg={1}>
                                {data.owner.type === "company" ? (
                                    <CompanyIcon />
                                ) : (
                                    <ProfileIcon />
                                )}
                            </Col>
                            <Col sm={21} lg={23}>
                                <Row style={{ flexDirection: "column" }}>
                                    <Col>
                                        <Title level={5}>
                                            {ellipsis(data.owner.name, 40)}
                                        </Title>
                                        <div>
                                            {beautifyCpfCnpj(
                                                data.owner.cpf_cnpj
                                            )}
                                        </div>
                                    </Col>
                                    <Col>
                                        <div style={{ height: "5px" }}></div>
                                        <div className="transaction-value-responsible">
                                            {formatCurrency(
                                                data.value,
                                                data.currency
                                            )}
                                            <ArrowUpOutlined rotate={data.type === "send_value" ? 45 : 225} />
                                        </div>
                                        <div className="transaction-status-responsible">
                                            <CircleTransactionStatus
                                                status={data.status}
                                            />
                                            {data.status_display}
                                        </div>
                                    </Col>
                                    {repeatTransactionStatus.includes(data.status) ? (
                                        <Col >
                                            <RedoOutlined style={{ marginRight: 8 }} />
                                            <Typography.Text style={{ cursor: "pointer" }} onClick={(e) => {
                                                e.stopPropagation();
                                                redirectToNewTransaction(data);
                                            }}>Repetir Transação</Typography.Text>
                                        </Col>
                                    ) : null}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ) : (
                <Row
                    style={{ width: "100%" }}
                    justify="space-between"
                    gutter={[8, 0]}
                    className="card-transaction"
                    onClick={() => redirectToTransactionPage(data)}
                >
                    <Col sm={1} lg={2} className="col-icon">
                        {data.owner.type === "company" ? (
                            <CompanyIcon />
                        ) : (
                            <ProfileIcon />
                        )}
                    </Col>
                    <Col sm={13} lg={12}>
                        <Title level={5}>{data.owner.name}</Title>
                        <div>{beautifyCpfCnpj(data.owner.cpf_cnpj)}</div>
                    </Col>
                    <Col sm={10} lg={10} style={{ textAlign: "right" }}>
                        <div className="transaction-value">
                            {formatCurrency(data.value, data.currency)}
                            <ArrowUpOutlined rotate={data.type === "send_value" ? 45 : 225} />
                        </div>
                        <Row justify="start" >
                            <Col>
                                <CircleTransactionStatus status={data.status} />
                            </Col>
                        </Row>
                        <Row justify="end" style={{ gap: "10px" }}>
                            <Col className="transaction-status">

                                <div>{data.status_display === "Operação criada pelo BO" ? "Ordem de pagamento recebida" : data.status_display}</div>
                                <div>{data.status_updated_at}</div>
                            </Col>
                            {repeatTransactionStatus.includes(data.status) ? (
                                <Col>
                                    <RedoOutlined style={{ marginRight: 6 }} />
                                    <Typography.Text onClick={(e) => {
                                        e.stopPropagation();
                                        redirectToNewTransaction(data);
                                    }}>Repetir Transação</Typography.Text>
                                </Col>
                            ) : null}
                        </Row>

                    </Col>
                </Row>
            )}
        </>
    );
};

export { TransactionCard, TransactionStatusCard };
