import StateStatus from "../../utils/status"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { register } from "./InitialRegisterService"

const initialState = {
    status: StateStatus.idle,
    errorMsg: null
}

export const registerCompany = createAsyncThunk('initialRegister/registerCompany', async (data) => {
    try {
        const response = await register(data)
        return response.data
    } catch(err) {
        const customError = {
            name: "Api Error",
            message: err.response && err.response.status < 500 ? err.response.data.msg : "Erro no sistema, tente mais tarde!",
            data: err.response.data
          };
        throw customError;
    }
})


export const initialRegister = createSlice({
    name: 'initialRegister',
    initialState: initialState,
    reducers: {
        reset: (state) => {
          state.status = StateStatus.idle;
        },
      },
    extraReducers: {
        [registerCompany.pending]: (state) => {
            state.status = StateStatus.loading
        },
        [registerCompany.fulfilled]: (state, action) => {
            state.status = StateStatus.succeeded
            localStorage.setItem("access_token", action.payload.access_token)
            localStorage.setItem("refresh_token", action.payload.refresh_token)
            localStorage.setItem("first_login", action.payload.first_login)
        },
        [registerCompany.rejected]: (state, action) => {
            state.status = StateStatus.failed
            state.errorMsg = action.error.message
        }
    }
})

export const selectInitialRegister = (state) => state.initialRegister