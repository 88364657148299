import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Progress, Typography, Divider, Card, Select, Form, notification } from "antd";
import { formatCurrency, formatCurrencyExchange } from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { getRatesReceiveValues } from "../../../features/Exchange/ReceiveValue/thunks";
import { selectExchange } from "../../../features/Exchange/slice";
import StateStatus from "../../../utils/status";
import "./styles.scss";
import NotOpenToExchange from "../NotOpenToExchange";
import { CanCloseToday, isCommercialHourRange } from "../../../utils/date";
import TooltipEfex from "../Tooltip";
import { SyncOutlined } from "@ant-design/icons";


const PriceReceiveValue = ({
	maxTime,
	valueToReceive = 0,
	isCurrencyTarget,
	currency,
	taxes,
	status,
	isClosed,
	onChange = (data) => { },
	onChangeSelect = (data) => { },
	commercialHour = false,
	getRates = false,
	receiveValueStatus,
	isScheduled,
	selectedReceiveDate,
	setIsLoading,
	isLoading,
	setThereCurrency,
	thereCurrency
}) => {
	const [timer, updateTimer] = useState(-1);
	const [timeout, updateTimeOut] = useState(0);
	const [progress, updateProgress] = useState(100);
	const dispatch = useDispatch();
	const exchange = useSelector(selectExchange);
	const [calcValues, updateCalcValues] = useState({
		toMe: 0,
		toBrl: 0,
		iof: { value: 0, tax: 0 },
		tarifa: { value: 0, tax: null },
	});
	const [closeToday, setCloseToday] = useState(false);
	const [showSelect, setShowSelect] = useState(false);
	const [fields, setFields] = useState([]);

	const waitingExchange = [
		"new",
		"draft",
		"waiting_approval",
		"docs_approved",
		"created_by_bo"
	].includes(status);

	const currencyPrice = waitingExchange || status === "approved"
		? (exchange.rates.rate ?? 0) / 10000
		: exchange.receivedValue.currency_rates / 10000;
	// calculando com base na Moeda Extrangeira
	const calcFromME = () => {
		let finalValue = 0;
		let iof = taxes.filter((x) => x.name.toUpperCase() === "IOF")[0] ?? 0;
		let tarifa = taxes.filter((x) => x.name.toUpperCase() === "TARIFA")[0] ?? 0

		const valueMe = parseInt(valueToReceive) / 100
		const iofTax = iof === 0 ? 0 : iof.tax / 10000;
		tarifa = tarifa === 0 ? 0 : tarifa.value / 100;

		if (isCurrencyTarget && valueMe > 0 && currencyPrice > 0) {
			iof = (valueMe * currencyPrice) * iofTax;
			iof = Math.trunc(iof * 10000) / 10000 // manter 4 casas decimais
			finalValue = (valueMe * currencyPrice) - iof - tarifa;
			finalValue = Math.trunc(finalValue * 10000) / 10000 // manter 4 casas decimais
		} else {
			iof = 0
			tarifa = 0
		}
		onChange({
			finalValue: finalValue.toFixed(2),
		});

		updateCalcValues({
			toBrl: finalValue.toFixed(2),
			iof: {
				value: iof.toFixed(2),
				tax: (iofTax * 100).toFixed(2),
			},
			tarifa: {
				value: tarifa.toFixed(2),
				tax: null
			},
			vet: currencyPrice,
		});
	};

	// calculando com base na Moeda Brasileira
	const calcFromBrl = () => {
		let finalValue = 0;
		let iof = taxes.filter((x) => x.name.toUpperCase() === "IOF")[0] ?? 0;
		let tarifa = taxes.filter((x) => x.name.toUpperCase() === "TARIFA")[0] ?? 0;

		const valueBrl = parseInt(valueToReceive) / 100
		const iofTax = iof === 0 ? 0 : iof.tax / 10000;
		tarifa = tarifa === 0 ? 0 : tarifa.value / 100;


		if (!isCurrencyTarget && valueBrl > 0 && currencyPrice > 0) {
			let calc = valueBrl / (1 - iofTax)
			calc = Math.trunc(calc * 10000) / 10000 // manter 4 casas decimais
			iof = calc * iofTax;

			finalValue = ((calc + tarifa) / currencyPrice);
			finalValue = Math.trunc(finalValue * 10000) / 10000 // manter 4 casas decimais

		} else {
			iof = 0
			tarifa = 0
		}



		onChange({
			finalValue: finalValue.toFixed(2),
		});

		updateCalcValues({
			toMe: finalValue.toFixed(2),
			iof: {
				value: iof.toFixed(2),
				tax: (iofTax * 100).toFixed(2),
			},
			tarifa: {
				value: tarifa.toFixed(2),
				tax: null,
			},
			vet: currencyPrice,
		});
	};

	useEffect(() => {
		CanCloseToday().then((response) => {
			setCloseToday(response.data.can_close_today)
		})
		isCommercialHourRange().then((response) => {
			setShowSelect(response.data.is_open_to_exchange)
		})
	}, []);

	useEffect(() => {
		if (isCurrencyTarget) {
			calcFromME();
		} else {
			calcFromBrl();
		}
	}, [currencyPrice, currency, isCurrencyTarget, valueToReceive, taxes]);

	useEffect(() => {
		if (exchange.status.getRatesReceiveValues === StateStatus.succeeded) {
			updateTimer(maxTime);
		}
	}, [exchange.status.getRatesReceiveValues]);

	const fetchRates = () => {
		setIsLoading(true)
		var receive_value_id = exchange.receivedValue?.id;
		dispatch(getRatesReceiveValues({
			receive_value_id: receive_value_id || undefined,
			currency: currency || undefined,
			transaction_day: selectedReceiveDate || undefined
		})).then((resp) => {
			if (resp.payload) {
				setThereCurrency(resp?.payload?.there_currency_coverages)
				setFields(resp?.payload?.fields)
				updateTimer(maxTime)
			} else {
				notification.error({
					message: `Erro!`,
					description: resp.error.message || "Erro interno do servidor, tente novamente.",
					placement: "topRight",
				})
			}
			setIsLoading(false)
		})
	};

	useEffect(() => {
		if (selectedReceiveDate) {
			if ((waitingExchange || status === "approved") && !isClosed) {
				if (timer > 0) {
					clearTimeout(timeout);
					updateTimeOut(
						setTimeout(() => {
							updateTimer(timer - 1);
						}, 1000),
					);
				} else {
					fetchRates();
				}
				updateProgress((100 * timer) / maxTime);
			}
		}
	}, [timer, selectedReceiveDate]);

	useEffect(() => {
		if (selectedReceiveDate) {
			if (getRates) {
				updateTimer(maxTime)
				updateCalcValues({
					toMe: 0,
					toBrl: 0,
					iof: { value: 0, tax: 0 },
					tarifa: { value: 0, tax: null },
				})
				fetchRates()
			}
		}
	}, [getRates, selectedReceiveDate])

	const formatTax = () => {
		if (isCurrencyTarget) {
			const calc = (calcValues.toBrl / valueToReceive)
			const calcArray = calc.toString().split("").slice(3, 8)
			calcArray.splice(1, 0, ",")
			const calcStr = calcArray.join("")

			return `BRL ${calcStr}`
		} else {
			const calc = (valueToReceive / calcValues.toMe)
			const calcArray = calc.toString().replace(".", "").split("")
			calcArray.splice(1, 0, ",")
			const calcStr = calcArray.slice(0, 6).join("")

			return `BRL ${calcStr}`
		}
	}


	useEffect(() => {
		fetchRates()
	}, [selectedReceiveDate])
	return (
		<div>
			<Card className="card-pricereceive-simulate" data-simulation={waitingExchange}>
				<Typography.Title level={4} className="title-box">
					{waitingExchange ? "Simulação" : isScheduled ? "Remessa Agendada" : "Remessa Aprovada"}
				</Typography.Title>
				<br />
				<Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}>
					{thereCurrency && showSelect && (receiveValueStatus === "waiting_approval" || receiveValueStatus === "approved") ? (
						<Form.Item
							className="send-day-form"
							label={
								<>
									Escolha abaixo a data para o recebimento dos recursos:
									<TooltipEfex
										className="tooltip"
										title="A liquidação no mesmo dia só pode ser contratada até às 12:00h.
										Atenção:  data de liquidação dos recursos pode sofrer alterações quando houver feriado bancários nos Estados Unidos.
										Caso tenha alguma dúvida, basta falar conosco pelo chat."
									/>
								</>
							}
							name="send_date"
							rules={[
								{
									required: true,
									message: "Por gentileza informe a data para o envio",
									validator: (_, value) => {
										if (value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error("Por favor, selecione uma opção."));
									},
								},
							]}
						>
							<Select onChange={onChangeSelect} disabled={isLoading}>
								<Select.Option value="D0" disabled={!closeToday || !fields.includes('D0')}>
									Receber os recursos hoje
								</Select.Option>
								<Select.Option value="D1" disabled={!fields.includes('D1')}>
									Receber os recursos no próximo dia útil
								</Select.Option>
								<Select.Option value="D2" disabled={!fields.includes('D2')}>
									Receber os recursos em 2 dias úteis
								</Select.Option>
							</Select>
						</Form.Item>
					) : null}
				</Col>
				{!commercialHour && !waitingExchange ? <NotOpenToExchange isScheduled={isScheduled} sendValue={true} currency={currency} /> : <Row gutter={[8, 8]} justify="space-around" align="middle" className={waitingExchange ? "simulation-content" : ""}>
					{(waitingExchange || status === "approved") && (
						<Col xs={11} sm={11} md={11} lg={8}
							className="box-currency-progress">
							{thereCurrency && !selectedReceiveDate ? null :
								isLoading ? <SyncOutlined spin style={{ color: "white", fontSize: "30px" }} /> :
									<Progress
										type="circle"
										width={60}
										status={timer <= 5 ? "exception" : "success"}
										percent={progress}
										format={(percent) => `${timer}s`}
									/>
							}

							<div className="box-value">
								<label>Seu câmbio</label>
								<div>
									<span>
										{(thereCurrency && !selectedReceiveDate) || isLoading ? "" : formatCurrencyExchange(calcValues.vet * 10000, "")}
									</span>
								</div>
							</div>
						</Col>
					)}

					<Col xs={13} sm={13} md={13} lg={16}>
						<Row justify="space-around">
							<Col xs={24} className="box-exchange-value" >
								<Typography.Title level={5}>Você recebe</Typography.Title>
								<div className="box-value">
									{(thereCurrency && !selectedReceiveDate && status === "approved") || isLoading ? <span /> :
										<span>
											{!isCurrencyTarget
												? formatCurrency(calcValues.toMe?.toString(), currency)
												: formatCurrency(valueToReceive.toString(), currency)}
										</span>
									}
								</div>
							</Col>
							<Col xs={24} className="box-exchange-value">
								<Typography.Title level={5}>Convertemos para</Typography.Title>
								<div className="box-value">
									{(thereCurrency && !selectedReceiveDate && status === "approved") || isLoading ? <span /> :
										<span>
											{isCurrencyTarget
												? formatCurrency(calcValues.toBrl?.toString(), "BRL")
												: formatCurrency(valueToReceive.toString(), "BRL")}
										</span>
									}
								</div>
								{taxes.length > 0 ? (
									<div className="box-exchange-taxes">
										{(thereCurrency && !selectedReceiveDate && status === "approved") || isLoading ? <span /> :
											<span>
												<p>Impostos e tarifas inclusos no valor final:</p>
												<p>
													IOF: {formatCurrency(calcValues.iof.value, "BRL")}
													{" "}({calcValues.iof.tax}%)
												</p>
												{(calcValues?.vet > 0 && receiveValueStatus !== "approved") && (
													<p>
														VET: {formatTax()}
													</p>
												)}
												{calcValues.tarifa.value > 0 && (
													<p>
														Tarifa: {formatCurrency(calcValues.tarifa.value, "BRL")}
													</p>
												)}
											</span>
										}
									</div>
								) : null}
							</Col>
						</Row>
					</Col>
				</Row>}

			</Card>
			{waitingExchange && (
				<div className="price-variation-info">* A taxa de câmbio e valores de impostos efetivamente aplicadas só serão definidas no fechamento da operação e podem ser diferentes das mostradas acima</div>
			)}
		</div >
	);
};

export default PriceReceiveValue;
