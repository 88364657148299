import React from 'react';
import { useHistory } from 'react-router-dom';
import "./styles.scss"
import { useDispatch } from 'react-redux';
import { getCompany, setCompany } from '../../../features/ChooseCompany/ChooseCompanySlice';
import { getPerson } from '../../../features/Person/slice';

export const OrangeLink = ({ to, children, style, isUploading, item }) => {
    const history = useHistory()
    const dispatch = useDispatch();

    const handleClick = () => {
        if (isUploading) {
            dispatch(getCompany(item.id)).then((resp) => {
                if (resp.payload) {
                    history.push(to);
                }
            });
        } else {
            history.push(to);
        }
    };


    return <div style={style}
        onClick={handleClick}
        className="efex-orange-link">{children}</div>

}

export const GreenLink = ({ to, children, style, isUploading, item }) => {
    const history = useHistory()
    const dispatch = useDispatch();

    const handleClick = () => {
        if (isUploading) {
            dispatch(getPerson(item.id)).then((resp) => {
                if (resp.payload) {
                    history.push(to);
                }
            });
        } else {
            history.push(to);
        }
    };

    return <div style={style} onClick={handleClick} className="efex-green-link">{children}</div>

}