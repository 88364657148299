import { Layout, Table, Tooltip, Spin } from 'antd';
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import routes from '../../../routes/routes';
import { useDispatch } from 'react-redux';
import { companyPersonUpdates, getReceivedValue, getSendValue, readNotification } from '../EfexHeader/slice';
import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { UpdateCompany, UpdatePerson } from '../../../utils/functions';
import { getPerson } from '../../../features/Person/slice';

const { Content } = Layout;

const TableNotification = ({ dataSource, pagination, setIsModalVisible, ...props }) => {
    const [updates, setUpdates] = useState([]);
    const [loadingId, setLoadingId] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();

    const columnsPeople = [
        {
            title: 'descrição',
            dataIndex: 'description'
        },
        {
            title: 'Ações',
            dataIndex: 'id',
            fixed: 'right',
            width: 100,
            render: (_, record) => (
                <Tooltip title='Visualizar'>
                    {loadingId === record.id ? (
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    ) : (
                        <EyeOutlined onClick={() => handleIconClick(record)} />
                    )}
                </Tooltip>
            ),
        },
    ];

    const handleIconClick = (record) => {
        setLoadingId(record.id);
        dispatch(readNotification({ notification_id: record.id }))
            .then(() => {
                if (record.status === "created_by_bo" || record.status === "receive_value_approved") {
                    const cpf_cnpj = record.person ? record.person?.cpf : record.company?.cnpj;
                    const receive_value_id = record.receive_value?.id;

                    dispatch(getReceivedValue({ id: receive_value_id, cpf_cnpj })).then((resp) => {
                        if (resp.payload) {
                            history.push(`${record.person ? routes.people : routes.chooseCompany}/${record.person?.id || record.company?.id}/cambio/receber-valores/${receive_value_id}`);
                            setIsModalVisible(false);
                        }
                        setLoadingId(null)
                    });
                } else if (record.status === "send_value_approved") {
                    const cpf_cnpj = record.person ? record.person?.cpf : record.company?.cnpj;
                    const send_value_id = record.send_value?.id;

                    dispatch(getSendValue({ id: send_value_id, cpf_cnpj })).then((resp) => {
                        if (resp.payload) {
                            history.push(`${record.person ? routes.people : routes.chooseCompany}/${record.person?.id || record.company?.id}/cambio/operacao-envio/${record.send_value?.id}`);
                            setIsModalVisible(false);
                        }
                        setLoadingId(null)
                    });
                } else if (record.status === "company_approved") {
                    history.push(`${routes.chooseCompany}/${record.company?.id}/cambio`);
                    setIsModalVisible(false);
                    setLoadingId(null)
                } else if (record.status === "person_approved") {
                    history.push(`${routes.people}/${record.person?.id}/cambio`);
                    setIsModalVisible(false);
                    setLoadingId(null)
                } else if (record.status === "company_need_changes") {
                    dispatch(companyPersonUpdates({ company_id: record.company.id })).then((resp) => {
                        if (resp.payload) {
                            history.push(UpdateCompany(resp.payload.lst[0]?.updates));
                            setIsModalVisible(false);
                        }
                        setLoadingId(null)
                    });
                } else if (record.status === "person_need_changes") {
                    dispatch(companyPersonUpdates({ person_id: record.person.id })).then((resp) => {
                        if (resp.payload) {
                            setUpdates(resp.payload.lst[0]?.updates);

                            dispatch(getPerson(record.person.id)).then((resp) => {
                                if (resp.payload) {
                                    history.push(UpdatePerson(updates));
                                    setIsModalVisible(false);
                                }
                                setLoadingId(null)
                            });
                        }
                    });
                }
            })
            .catch(() => {
                setLoadingId(null)
            });
    };

    return (
        <Content className="content-people">
            <Table
                className="efex-table"
                dataSource={dataSource}
                columns={columnsPeople}
                pagination={pagination}
                rowKey="id"
                {...props}
            />
        </Content>
    );
}

export default TableNotification;
