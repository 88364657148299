import React from "react";
import { InputNumber } from "antd";
// import CurrencyInput from "react-currency-input";
import { formatCurrency, formatCurrencyPatrimony } from "../../../utils/functions";

const CurrencyField = ({
    prefix,
    acceptNegative,
    ...props
}) => {
    return (acceptNegative ?
        <InputNumber 
            className="efex-currency-field"
            formatter={value => formatCurrencyPatrimony(value, prefix ?? "R$ ")}
            parser={money => money.replace(/(?!\-)\D/g, "")}
            {...props}
            placeholder={`${prefix} 0,00`}
        />
        :
        <InputNumber
            className="efex-currency-field"
            min={0}
            formatter={value => formatCurrency(value, prefix ?? "R$ ")}
            parser={money => money.replace(/\D/g, "")}
            {...props}
        /> 
    );
};

export default CurrencyField;
