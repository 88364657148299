import StateStatus from "../../utils/status";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDocuments, sendDocuments, getQsaDocuments, sendQsaDocuments, deleteDocument, deleteQsaDocument, checkDocuments } from "./UploadFilesService";

const initialState = {
  status: {
    getDocs: StateStatus.idle,
    sendDocs: StateStatus.idle,
    getQsaDocs: StateStatus.idle,
    sendQsaDocs: StateStatus.idle,
    getCheckDocs: StateStatus.idle,
  },
  errorMsg: null,
  docs: {
    sents: 0,
    docs_to_send: []
  },
  qsaDocs: {
    sents: 0,
    docs_to_send: []
  },
};

export const getDocumentsSlice = createAsyncThunk(
  "documents/getDocuments",
  async () => {
    const cnpj = JSON.parse(localStorage.getItem('company')).cnpj;
    const response = await getDocuments(cnpj);
    return response.data;
  }
);

export const sendDocumentsSlice = createAsyncThunk(
  "documents/sendDocuments",
  async ({ formData, config, onSuccess, onError }) => {
    try {
      const cnpj = JSON.parse(localStorage.getItem('company')).cnpj;
      const response = await sendDocuments(cnpj, formData, config);
      onSuccess()
      return response.data;
    } catch (err) {
      onError(err)
      throw err
    }
  }
);

export const removeDoc = createAsyncThunk(
  "documents/removeDoc",
  async (config) => {
    try {
      const cnpj = JSON.parse(localStorage.getItem('company')).cnpj;
      const response = await deleteDocument(cnpj, config);
      return response.data;
    } catch (err) {
      throw err
    }
  }
);

export const getQsaDocumentsThunk = createAsyncThunk(
  "documents/getQsaDocuments",
  async () => {
    const cnpj = JSON.parse(localStorage.getItem('company')).cnpj;
    const response = await getQsaDocuments(cnpj);
    return response.data;
  }
);

export const sendQsaDocumentsThunk = createAsyncThunk(
  "documents/sendQsaDocuments",
  async ({ formData, config, onSuccess, onError }) => {
    try {
      const cnpj = JSON.parse(localStorage.getItem('company')).cnpj;
      const response = await sendQsaDocuments(cnpj, formData, config);
      onSuccess()
      return response.data;
    } catch (err) {
      onError(err)
      throw err
    }
  }
);

export const removeQsaDoc = createAsyncThunk(
  "documents/removeQsaDoc",
  async (config) => {
    try {
      const cnpj = JSON.parse(localStorage.getItem('company')).cnpj;
      const response = await deleteQsaDocument(cnpj, config);
      return response.data;
    } catch (err) {
      throw err
    }
  }
);

export const getCheckDocs = createAsyncThunk(
  'documents/getCheckDocs', async (isUploading, thunkAPI) => {
    const cnpj = JSON.parse(localStorage.getItem('company')).cnpj;
    const qsaDocs = (await getQsaDocuments(cnpj)).data;
    const docs = (await getDocuments(cnpj)).data;
    const checkDocs = (await checkDocuments(cnpj, isUploading)).data;
    return { qsaDocs, docs, checkDocs }
  }
)


export const documents = createSlice({
  name: "documents",
  initialState: initialState,
  extraReducers: {
    [getCheckDocs.pending]: (state) => {
      state.status.getCheckDocs = StateStatus.loading;
    },
    [getCheckDocs.fulfilled]: (state, action) => {
      state.status.getCheckDocs = StateStatus.succeeded;
      state.docs.docs_to_send = action.payload.docs.docs_to_send;
      state.qsaDocs.docs_to_send = action.payload.qsaDocs.docs_to_send;
    },
    [getCheckDocs.rejected]: (state, action) => {
      state.status.getCheckDocs = StateStatus.failed;
      state.errorMsg = action.error.message;
      state.docs = null;
    },
    [getDocumentsSlice.pending]: (state) => {
      state.status.getDocs = StateStatus.loading;
    },
    [getDocumentsSlice.fulfilled]: (state, action) => {
      state.status.getDocs = StateStatus.succeeded;
      state.docs.docs_to_send = action.payload.docs_to_send;
    },
    [getDocumentsSlice.rejected]: (state, action) => {
      state.status.getDocs = StateStatus.failed;
      state.errorMsg = action.error.message;
      state.docs = null;
    },
    [sendDocumentsSlice.pending]: (state) => {
      state.status.sendDocs = StateStatus.loading;
    },
    [sendDocumentsSlice.fulfilled]: (state) => {
      state.status.sendDocs = StateStatus.succeeded;
      state.docs.sents = state.docs.sents + 1
    },
    [sendDocumentsSlice.rejected]: (state, action) => {
      state.status.sendDocs = StateStatus.failed;
      state.errorMsg = action.error.message;
    },
    [getQsaDocumentsThunk.pending]: (state) => {
      state.status.getQsaDoc = StateStatus.loading;
    },
    [getQsaDocumentsThunk.fulfilled]: (state, action) => {
      state.status.getQsaDoc = StateStatus.succeeded;
      state.qsaDocs.docs_to_send = action.payload.docs_to_send;
    },
    [getQsaDocumentsThunk.rejected]: (state, action) => {
      state.status.getQsaDoc = StateStatus.failed;
      state.errorMsg = action.error.message;
      state.qsaDocs = null;
    },
    [sendQsaDocumentsThunk.pending]: (state) => {
      state.status.sendQsaDocs = StateStatus.loading;
    },
    [sendQsaDocumentsThunk.fulfilled]: (state) => {
      state.status.sendQsaDocs = StateStatus.succeeded;
      state.qsaDocs.sents = state.qsaDocs.sents + 1
    },
    [sendQsaDocumentsThunk.rejected]: (state, action) => {
      state.status.sendQsaDocs = StateStatus.failed;
      state.errorMsg = action.error.message;
    },
  },
});

export const selectDocuments = (state) => state.documents;
