import React from "react";
import { Modal } from "antd";

const SuccessModal = ({ content }) => {
    const { success } = Modal

    return success({
        content:content,
    });
}

SuccessModal.defaultProps = {
    content: React.Children || String,
}

export default SuccessModal;