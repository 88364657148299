import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StateStatus from "../../utils/status";
import { listQsa, getResponsibles as finResponsibles } from "./SelectPartnersService";

export const fetchQsa = createAsyncThunk(
  "financialResponsibles/fetchQsa",
  async () => {
    const cnpj = JSON.parse(localStorage.getItem("company")).cnpj;
    const response = await listQsa(cnpj);
    return response.data;
  }
);

export const getResponsibles = createAsyncThunk(
  "financialResponsibles/getResponsibles",
  async () => {
    const cnpj = JSON.parse(localStorage.getItem('company')).cnpj;
    const response = await finResponsibles(cnpj);
    return response.data;
  }
);

const initialState = {
  status: {
    list: StateStatus.idle,
    responsibles: StateStatus.idle,
  },
  data: {
    allQsa: [],
    newQsa: [],
    responsibles: [],
    phoneList: []
  },
  errorMsg: null,
};

export const financialResponsibles = createSlice({
  name: "financialResponsibles",
  initialState,
  reducers: {
    setResponsibles: (state, action) => {
      state.status.responsibles = StateStatus.loading;
      state.data.responsibles = action.payload;
      state.status.responsibles = StateStatus.succeeded;
    },
    resetResponsiblesStatus: (state) => {
      state.status.responsibles = StateStatus.idle;
    },
    toggleSelectedQsa: (state, action) => {
      if (
        state.data.responsibles.filter(
          (item) => item.name === action.payload.name
        ).length > 0
      ) {
        state.data.responsibles = state.data.responsibles.filter(
          (item) => item.name !== action.payload.name
        );
      } else {
        if (state.data.responsibles.length === 5) {
          return;
        }
        const quali = action.payload.qualification
        if (quali !== "procurador" && quali !== "resp-legal") {
          action.payload.qualification = "socio"
        }
        state.data.responsibles = [...state.data.responsibles, action.payload]
      }
    },
    addNewQsa: (state, action) => {
      let exists = false;
      state.data.allQsa.forEach((qsa) => {
        if (qsa.cpf === action.payload.cpf) {
          exists = true;
        }
      });
      if (!exists) {
        state.data.allQsa.push(action.payload);
      }
    },
    removeQsa: (state, action) => {
      state.data.allQsa = state.data.allQsa.filter(
        (qsa) => qsa.name !== action.payload.name
      );
    },
    setLstPhoneValue: (state, action) => {
      state.data.phoneList = action.payload
    }
  },
  extraReducers: {
    [fetchQsa.pending]: (state) => {
      state.status.list = StateStatus.loading;
    },
    [fetchQsa.fulfilled]: (state, action) => {
      state.status.list = StateStatus.succeeded;
      state.data.allQsa = action.payload.companies[0].qsa;
      const finResp = JSON.parse(
        localStorage.getItem("financial_responsibles")
      );
      if (finResp !== null) {
        const newFinResp = finResp.map((item) => {
          return item;
        });
        state.data.responsibles = newFinResp;
        localStorage.setItem(
          "financial_responsibles",
          JSON.stringify(newFinResp)
        );
      }
    },
    [fetchQsa.rejected]: (state, action) => {
      state.status.list = StateStatus.failed;
      state.errorMsg = action.error.message;
    },
    [getResponsibles.pending]: (state) => {
      state.status.responsibles = StateStatus.loading;
    },
    [getResponsibles.fulfilled]: (state, action) => {
      state.status.responsibles = StateStatus.succeeded;
      state.data.responsibles = action.payload.financial_responsibles;
      localStorage.setItem("financial_responsibles", JSON.stringify(action.payload.financial_responsibles));
    },
    [getResponsibles.rejected]: (state, action) => {
      state.status.responsibles = StateStatus.failed;
      state.errorMsg = action.error.message;
    },
  },
});
export const { toggleSelectedQsa, addNewQsa, removeQsa, resetResponsiblesStatus, setLstPhoneValue, setResponsibles } =
  financialResponsibles.actions;

export const selectQsa = (state) => state.financialResponsibles;