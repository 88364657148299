import React, { useEffect, useState } from "react";
import { Form, Layout, Row, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../routes/routes";
import { loginThunk, selectLogin } from "./LoginSlice";
import StateStatus from "../../utils/status";
import ErrorMsg from "../../common/components/ErrorMsg";
import EfexInput from "../../common/components/Input";
import { GreyButton } from "../../common/components/Button";
import { OrangeLink } from "../../common/components/Link";
import EfexModal from "../../common/components/Modal";
import LoadingIcon from "../../common/components/loading_icon/loading_icon";
import { confirmEmail } from "./LoginService";
import { SmileOutlined } from "@ant-design/icons";
import "./Login.scss";

const Login = (props) => {
    const dispatch = useDispatch();
    const login = useSelector(selectLogin);
    const [confirmEmailState, updateConfirmEmailState] = useState({
        showModal: false,
        loading: false,
        success: false,
    });
    const [isVisible, setIsVisible] = useState(false);

    const url = window.location.href;

    useEffect(() => {
        const accessToken = localStorage.getItem("access_token");
        if (accessToken !== null && accessToken !== "undefined") {
            props.history.push(Routes.chooseCompany);
        }
    });

    useEffect(() => {
        if (login.status.login === StateStatus.succeeded) {
            props.history.push(Routes.dashboard);
        }
    }, [login, props.history]);

    const onFinish = (values) => {
        dispatch(loginThunk(values)).then((resp) => {
            if (resp?.error?.message === "Email não confirmado!") {
                setIsVisible(true);
            }
        });
    };

    useEffect(() => {
        if (isVisible) {
            notification.success({
                message: (
                    <p>
                        Enviamos uma confirmação para o seu email, verifique
                        para fazer login.
                    </p>
                ),
                duration: 3,
            });
            setIsVisible(false);
        }
    }, [isVisible]);

    useEffect(() => {
        const [_, query] = url.split("#")[1].split("?");
        const params = Object.fromEntries(new URLSearchParams(query));

        if (params.token) {
            updateConfirmEmailState({
                ...confirmEmailState,
                loading: true,
                showModal: true,
            });

            confirmEmail(params.token)
                .then(() => {
                    updateConfirmEmailState({
                        showModal: true,
                        loading: false,
                        success: true,
                    });
                })
                .catch((error) => {
                    updateConfirmEmailState({
                        showModal: true,
                        loading: false,
                        success: false,
                        msg: error.response.data.msg,
                    });
                });
        }
    }, [url]);

    return (
        <Layout className="left-right-layout">
            <div className="left-container"></div>
            <div className="right-container">
                <EfexModal
                    visible={confirmEmailState.showModal}
                    title="Confirmar E-mail"
                    onCancel={() =>
                        updateConfirmEmailState({
                            ...confirmEmailState,
                            showModal: false,
                        })
                    }
                    footer={""}
                >
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "14px",
                        }}
                    >
                        {confirmEmailState.loading ? (
                            <LoadingIcon />
                        ) : (
                            <>
                                {confirmEmailState.success
                                    ? "E-mail confirmado com sucesso!"
                                    : confirmEmailState.msg}
                            </>
                        )}
                    </div>
                </EfexModal>
                <div className="welcome">Bem-vindo!</div>
                {login.status.login === StateStatus.failed ? (
                    <ErrorMsg message={login.errorMsg}></ErrorMsg>
                ) : null}

                <Form name="basic" onFinish={onFinish} layout="vertical">
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Informe o email",
                            },
                            {
                                type: "email",
                                message: "Informe um email válido.",
                            },
                        ]}
                    >
                        <EfexInput
                            type="email"
                            placeholder="Email"
                            prefix={
                                <UserOutlined className="site-form-item-icon" />
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        style={{ marginBottom: 0 }}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Por favor, informe a senha.",
                            },
                        ]}
                    >
                        <EfexInput
                            type="password"
                            width="350px"
                            prefix={
                                <LockOutlined className="site-form-item-icon" />
                            }
                            placeholder="Senha"
                        />
                    </Form.Item>
                    <div style={{ height: "20px" }} />
                    <Form.Item>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "space-between",
                            }}
                        >
                            <GreyButton
                                type="primary"
                                loading={
                                    login.status.login === StateStatus.loading
                                }
                                htmlType="submit"
                            >
                                {login.status.login === StateStatus.loading
                                    ? "Carregando"
                                    : "Entrar"}
                            </GreyButton>
                            <div style={{ height: "10px" }} />
                            <OrangeLink to={Routes.forgotPassword}>
                                Esqueci a senha
                            </OrangeLink>
                        </div>
                    </Form.Item>
                </Form>

                <Row justify="end">
                    Ainda não tenho
                    <OrangeLink
                        style={{ marginLeft: "4px" }}
                        to={Routes.register}
                    >
                        registro
                    </OrangeLink>
                </Row>
            </div>
        </Layout>
    );
};

export default Login;
