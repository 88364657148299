import React, { useEffect, useState } from "react"
import { Typography } from "antd"
import { CheckCircleOutlined } from "@ant-design/icons"
import { GreenButton, OrangeButton } from "../../common/components/Button"

import "./styles.scss"
import Routes from "../../routes/routes"

const { Title } = Typography

const FinishedRegister = ({ location, history }) => {
    const [type, setType] = useState("company")

    useEffect(() => {
        if (location.state && location.state.type) {
            setType(location.state.type)
        }
    }, [location])

    return (
        <div className={`finished-msg ${type}`}>
            <Title level={3}>Cadastro enviado para análise com sucesso!</Title>
            <div className="show-icon">
                <CheckCircleOutlined />
            </div>
            <div className="description" style={{marginBottom: 20}}>
                Seu cadastro já está sendo analisado pela nossa equipe.
                Em breve enviaremos as fichas cadastrais para o email informado do responsável legal para assinatura. 
                O prazo para análise e conclusão do cadastro é de até 3 dias úteis. 
                Não se preocupe, caso nossa equipe precise de qualquer informação adicional, 
                entraremos em contato pelo e-mail de usuário cadastrado. Se precisar falar com a gente, estamos disponíveis no chat.
            </div>
            <div>
                {type === "company" ? (
                    <OrangeButton
                        htmlType=""
                        type="primary"
                        onClick={() => history.push(Routes.dashboard)}
                    >
                        Voltar ao início
                    </OrangeButton>
                ) : (
                    <GreenButton
                        htmlType="submit"
                        type="primary"
                        onClick={() => history.push(Routes.dashboard)}
                    >
                        Voltar ao início
                    </GreenButton>
                )}
            </div>
        </div>
    )
}



export default FinishedRegister