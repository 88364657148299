import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Input, Select } from "antd";
import StateStatus from "../../../../utils/status";
import { useState } from "react";
import ErrorMsg from "../../../../common/components/ErrorMsg";
import {
	editBankAccountReceive,
	listBankAccountReceive,
	saveBankAccountReceive,
	selectExchange,
} from "../../slice";
import { BankList } from "../../../../utils/lists";
import EfexModal from "../../../../common/components/Modal";
import { useRouteMatch } from "react-router-dom";
import "./styles.scss";
import TooltipEfex from "../../../../common/components/Tooltip";


const BankAccountReceiveModal = ({ visible, data, onOk, onCancel }) => {
	const [form] = Form.useForm();
	const [account, setAccount] = useState({});
	const exchange = useSelector(selectExchange);
	const dispatch = useDispatch();
	const { path } = useRouteMatch();

	useEffect(() => {
		if (data === null || data === undefined) {
			form.resetFields();
		} else {
			setAccount(data);
			form.setFieldsValue(account);
		}
	}, [data, form, account]);

	return (
		<EfexModal
			className={path.match("empresas") ? "modal-company" : "modal-person"}
			title={
				<>
					{(account.id ? "Editar" : "Nova") + " conta bancária"}
					<TooltipEfex title="Não se esqueça que os dados bancários precisam da empresa cadastrada, ok?"/>
				</>
			}
			visible={visible}
			confirmLoading={
				exchange.status.saveBankAccountReceive === StateStatus.loading ||
				exchange.status.editBankAccountReceive === StateStatus.loading
			}
			onCancel={() => {
				form.resetFields()
				onCancel()
			}}
			onOk={() => {
				form.validateFields()
					.then((values) => {
						if (account.id) {
							dispatch(editBankAccountReceive({ bankId: account.id, data: values }))
								.then(({ payload }) => {
									if (payload !== undefined) {
										onOk(payload.data);
										dispatch(listBankAccountReceive());
									}
								});
						} else {
							dispatch(saveBankAccountReceive(values))
								.then(
									({ payload }) => {
										if (payload !== undefined) {
											onOk(payload);
											dispatch(listBankAccountReceive());
											form.resetFields();
										}
									},
								);
						}
					})
					.catch((info) => {
					});
			}}
		>
			<Form
				aria-autocomplete="none"
				form={form}
				layout="vertical"
			>

				{exchange.status.saveBankAccountReceive === StateStatus.failed ||
					exchange.status.editBankAccountReceive === StateStatus.failed ? (
					<ErrorMsg message={exchange.errorMsg}></ErrorMsg>
				) : null}

				<p className="subtitle">Informe abaixo os dados bancários da empresa cadastrada para o qual iremos depositar os Reais.</p>

				<Row gutter={[8, 0]}>
					<Col span={24}>
						<Form.Item
							label="Banco"
							name={"bank_name"}
							rules={[
								{
									required: true,
									message: "Nome do banco inválido.",
								},
							]}
						>
							<Select
								showSearch
								filterOption={(
									input,
									option,
								) =>
									option.children
										.toLowerCase()
										.indexOf(
											input.toLowerCase(),
										) >= 0
								}
							>
								{BankList.map((item) => (
									<Select.Option
										key={item.key}
										value={item.value}
									>
										{item.key}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[8, 0]}>
					<Col span={12}>
						<Form.Item
							label="Agência"
							name={"agency_number"}
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<Input placeholder="Agência" type="text"></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Número da conta"
							name={"account_number"}
							rules={[
								{
									required: true,
									message: "Campo obrigatório.",
								},
							]}
						>
							<Input
								placeholder="Número da conta"
								type="text"
							></Input>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</EfexModal >
	);
};

export default BankAccountReceiveModal;
