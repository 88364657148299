import React, { useEffect } from "react";
import { Typography, Row, Col } from "antd";
import RegisterSteps from "../steps/steps";
import "./RegisterBaseLayout.scss";
import Routes from "../../../routes/routes";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyByCnpj, selectCompany } from "../../../features/ChooseCompany/ChooseCompanySlice";
import StateStatus from "../../../utils/status";

const { Title, Text } = Typography;
const RegisterBaseLayout = ({ current, titleL3, text, children, history }) => {
    const dispatch = useDispatch();
    const company = useSelector(selectCompany);
    const items = [
        { title: "Dados da empresa", route: Routes.legalPerson, key: "started" },
        { title: "KYC", route: Routes.legalPersonExtra, key: "started" },
        { title: "Responsáveis", route: Routes.selectPartners, key: "completed_company_data" },
        { title: "Dados dos responsáveis", route: Routes.completePartnersData, key: "completed_financial_responsible" },
        { title: "Confirmar dados", route: Routes.confirmRegister, key: "completed_financial_responsible" },
        { title: "Upload de documentos", route: Routes.uploadFile, key: "accepted_review" },
    ];

    useEffect(() => {
        if (company.status.getCompanyByCnpj === StateStatus.idle) {
            dispatch(getCompanyByCnpj());
        }
    }, [company.status.getCompanyByCnpj, dispatch]);

    return (
        <Row className="row-steps-content">
            <Col xs={24} sm={24} lg={5} className="col-steps">
                <RegisterSteps
                    steps={company.company?.register_steps}
                    history={history}
                    items={items}
                    className="steps-yellow"
                    current={current}
                    register_update={company.company?.register_update[0] || false}
                ></RegisterSteps>
            </Col>
            <Col xs={24} sm={24} lg={19} className="col-content">
                <div className="titulo-desc">
                    <Title level={3}>{titleL3}</Title>
                    <Text>{text}</Text>
                </div>
                {children}
            </Col>
        </Row>
    );
};

export default RegisterBaseLayout;
