import RegisterBaseLayout from "../../common/components/RegisterBaseLayout/RegisterBaseLayout";
import CurrencyField from "../../common/components/currency_field/currency_field";
import { selectLoadingData } from "../LoadingData/LoadingDataSlice";
import { Form, Input, Typography, Row, Col, Divider, Select } from "antd";
import { OrangeButton } from "../../common/components/Button";
import { cnpj as cnpjValidator } from "cpf-cnpj-validator";
import { getAddressByCepThunk } from "./LegalPersonSlice";
import TooltipEfex from "../../common/components/Tooltip";
import { EfexCard } from "../../common/components/Card";
import { useDispatch, useSelector } from "react-redux";
import EfexInput from "../../common/components/Input";
import React, { useState, useEffect } from "react";
import { scrollUp, strFormatTimeApi } from "../../utils/functions";
import { MaskedInput } from "antd-mask-input";
import StateStatus from "../../utils/status";
import { mask } from "../../utils/consts";
import Routes from "../../routes/routes";
import "./LegalPerson.scss";
import { BrazilStates } from "../../utils/lists";
import { Option } from "antd/lib/mentions";
import { selectCompany, updateCompany, setCompany as setCompanyReducer, getCompanyByCnpj } from "../ChooseCompany/ChooseCompanySlice";
import moment from "moment";
import { getAddressByCep } from "../../service/viacep.service";
import { toBrazilianDateString } from "../../utils/date";

const { Title } = Typography;

const LegalPerson = (props) => {
    const [form] = Form.useForm();
    const [maskPhone, setmaskPhone] = useState(mask.phone);
    const loadingData = useSelector(selectLoadingData);
    const dispatch = useDispatch();
    const [company, setCompany] = useState([])
    const [registerUpdate, setRegisterUpdate] = useState([])
    const selectedCompany = useSelector(selectCompany);

    useEffect(() => {
        if (selectedCompany.status?.getCompanyByCnpj === "succeeded") {
            setCompany(selectedCompany.company)
            setRegisterUpdate(selectedCompany.company.register_update[0])
        }
    }, [selectedCompany])

    useEffect(() => {
        if (company !== null) {
            if (company.phone && company.phone.length > 10) {
                setmaskPhone(mask.cell);
            }

            form.setFieldsValue({
                cnpj: cnpjValidator.format(company.cnpj)
            })

            form.setFieldsValue({
                ...company,
                activity: company.economic_activity || null,
                init_date: toBrazilianDateString(company.init_date) || null,
                phone: company.phone_0 || null,
            });

            if (company.addresses && company.addresses[0]) {
                const address = company.addresses[0]
                form.setFieldsValue({
                    zipcode: address.zipcode || null,
                    province: address.province || null,
                    city_name: address.city_name || null,
                    complement: address.complement || null,
                    neighborhood: address.neighborhood || null,
                    number: address.number || null,
                    public_place: address.public_place || null,
                    country_name: address.country_name
                })
            }
        }
    }, [form, company]);

    const onFinish = (values) => {
        if (!values.patrimony) {
            values.patrimony = 0;
        }
        if (!values.monthly_invoicing) {
            values.monthly_invoicing = 0;
        }

        values.cnpj = cnpjValidator.strip(values.cnpj);
        values.tel = cnpjValidator.strip(values.tel);

        if (registerUpdate) {
            values.init_date = strFormatTimeApi(values.init_date);
            values.zipcode = values.zipcode.replace(/[^a-zA-Z0-9]/g, '')

        }

        const data = {
            ...values,
            company_id: company.id,
            current_update_step: "registration_form"
        };

        let update_docs = registerUpdate?.company_incorporation_document ||
            registerUpdate?.board_election_minutes ||
            registerUpdate?.representative_proof_of_address ||
            registerUpdate?.representative_identity_document ||
            registerUpdate?.financial_documentation

        if (registerUpdate?.registration_form) {
            dispatch(updateCompany(data)).then((resp) => {
                if (resp.payload) {
                    dispatch(getCompanyByCnpj()).then((resp) => {
                        resp.payload && setCompanyReducer((resp.payload))
                    })
                    if (registerUpdate?.kyc) {
                        props.history.push(Routes.kyc);
                    } else if (registerUpdate?.representative_form) {
                        props.history.push(Routes.selectPartners);
                    } else if (update_docs) {
                        props.history.push(Routes.uploadFile);
                    } else {
                        props.history.push({
                            pathname: Routes.finishedRegister,
                            state: { type: "company" }
                        });
                    }

                }
            })
        } else {
            localStorage.setItem("company", JSON.stringify(values));
            props.history.push(Routes.legalPersonExtra);
        }
        scrollUp();
    };

    const onChangeForm = (changedValues, allValues) => {
        const { zipcode, phone } = changedValues;

        if (zipcode) {
            let cep = cnpjValidator.strip(zipcode);

            if (cep.length === 8) {
                getAddressByCep(cep).then((response) => {
                    let fields = {
                        public_place: response.logradouro,
                        neighborhood: response.bairro,
                        city_name: response.localidade,
                        province: response.uf,
                        complement: null,
                        number: "",
                    };
                    form.setFieldsValue(fields);
                });
            }
        }

        if (phone) {
            let strippedPhone = cnpjValidator.strip(phone);
            if (strippedPhone.charAt(2) === "9") {
                setmaskPhone(mask.cell);
            } else {
                setmaskPhone(mask.phone);
            }
        }
    };


    return (
        <RegisterBaseLayout
            {...props}
            current={0}
            titleL3="Cadastro da empresa"
            text={registerUpdate?.registration_form ?
                "Complete os campos em branco com as informações atualizadas da empresa" :
                "Complete os campos que faltam em branco com as informações da empresa. Para te ajudar, já buscamos algumas informações na Base Nacional de empresas. Se algo não estiver certo, utilize o campo 'Observação' para nos avisar."
            }
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onValuesChange={onChangeForm}
                requiredMark={false}
                className="legal-person"
                scrollToFirstError={true}
            >
                <Row gutter={[8, 8]} className="row-empresa-endereco">
                    <Col xs={24} sm={24} lg={12}>
                        <EfexCard>
                            <Title level={5}>Empresa</Title>
                            <Divider />
                            <Form.Item name="company_name" label="Razão social">
                                <EfexInput placeholder="Razão social" />
                            </Form.Item>
                            <Form.Item name="cnpj" label="CNPJ">
                                <EfexInput disabled={true} />
                            </Form.Item>
                            <Form.Item
                                name="activity"
                                label="Atividade"
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe a Atividade",
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    type="text"
                                    placeholder="Atividade"
                                />
                            </Form.Item>
                            <Form.Item
                                name="juridical_nature_description"
                                label="Natureza jurídica"
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe a Natureza jurídica",
                                    },
                                ]}
                            >
                                <EfexInput placeholder="Natureza Jurídica" />
                            </Form.Item>
                            {registerUpdate?.registration_form ? (
                                <Form.Item
                                    name="init_date"
                                    label="Data de fundação"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                const dateOnlyNumbers =
                                                    value.replace(
                                                        /\D/g,
                                                        ""
                                                    );
                                                const date = moment(
                                                    value,
                                                    "DD/MM/YYYY"
                                                );
                                                if (
                                                    dateOnlyNumbers.length <
                                                    8 ||
                                                    !date.isValid()
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            "Data inválida"
                                                        )
                                                    );
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            },
                                        }),
                                    ]
                                    }
                                >
                                    <MaskedInput
                                        mask={mask.date}
                                    ></MaskedInput>
                                </Form.Item>
                            ) : (
                                <Form.Item
                                    name="init_date"
                                    label="Data de fundação"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Data da fundação",
                                        },
                                    ]}
                                >
                                    <EfexInput />
                                </Form.Item>
                            )}

                            <Form.Item
                                name="country_name"
                                label="País"
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe o país",
                                    },
                                ]}
                            >
                                <EfexInput placeholder="País" />
                            </Form.Item>

                            <Form.Item
                                name="share_capital"
                                label="Capital social"
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe o capital social",
                                    },
                                ]}
                            >
                                <CurrencyField style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item
                                name="monthly_invoicing"
                                label={
                                    <>
                                        Faturamento aproximado nos últimos 12
                                        meses
                                        <TooltipEfex title="Se sua empresa for recém constituída, o campo pode permanecer zerado." />
                                    </>
                                }
                                initialValue={null}
                            >
                                <CurrencyField style={{ width: "100%" }} />
                            </Form.Item>
                            <Form.Item
                                name="patrimony"
                                label={
                                    <>
                                        Patrimônio líquido
                                        <TooltipEfex title="Se sua empresa for recém constituída, o campo pode permanecer zerado." />
                                    </>
                                }
                                initialValue={null}
                            >
                                <CurrencyField
                                    style={{ width: "100%" }}
                                    acceptNegative={true}
                                    prefix="R$"
                                />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label={
                                    <>
                                        E-mail institucional
                                        <TooltipEfex title="Não será usado para contato" />
                                    </>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe o email",
                                    },
                                    {
                                        type: "email",
                                        message: "Informe um email válido.",
                                    },
                                ]}
                            >
                                <EfexInput type="email" placeholder="Email" />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                label={
                                    <>
                                        Telefone institucional
                                        <TooltipEfex title="Não será usado para contato" />
                                    </>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe o telefone",
                                    },
                                ]}
                            >
                                <MaskedInput
                                    mask={maskPhone}
                                    placeholder="Telefone"
                                />
                            </Form.Item>
                        </EfexCard>
                    </Col>
                    <Col xs={24} sm={24} lg={12}>
                        <EfexCard>
                            <Title level={5}>Endereço</Title>
                            <Divider />
                            <Form.Item
                                name="zipcode"
                                label="CEP"
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe o CEP",
                                    },
                                ]}
                            >
                                <MaskedInput
                                    mask={mask.cep}
                                    placeholder="CEP"
                                />
                            </Form.Item>
                            <Form.Item
                                name="public_place"
                                label="Logradouro"
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe o logradouro",
                                    },
                                ]}
                            >
                                <EfexInput placeholder="Logradouro" />
                            </Form.Item>
                            <Form.Item
                                name="number"
                                label="Número"
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe o número",
                                    },
                                ]}
                            >
                                <EfexInput placeholder="Número" />
                            </Form.Item>
                            <Form.Item
                                name="neighborhood"
                                label="Bairro"
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe o bairro",
                                    },
                                ]}
                            >
                                <EfexInput placeholder="Bairro" />
                            </Form.Item>

                            <Form.Item
                                name="complement"
                                label="Complemento"
                                initialValue=""
                            >
                                <Input placeholder="Complemento" />
                            </Form.Item>

                            <Form.Item
                                name="city_name"
                                label="Cidade"
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe a cidade",
                                    },
                                ]}
                            >
                                <EfexInput placeholder="Cidade" />
                            </Form.Item>
                            <Form.Item
                                name="province"
                                label="Estado"
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe o estado",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {BrazilStates.map((item) => {
                                        return (
                                            <Option
                                                key={item.key}
                                                value={item.key}
                                            >
                                                {item.value}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </EfexCard>
                        <div style={{ height: "15px" }} />
                        <EfexCard className="observacao">
                            <Title level={5} className="obs">
                                Observação
                                <TooltipEfex title="Utilize esse campo para nos contar caso alguma informação não esteja certa." />
                            </Title>
                            <Divider />
                            <Form.Item name="comments" initialValue="">
                                <Input.TextArea placeholder="Caso alguma informação esteja inconsistente, informe neste campo" />
                            </Form.Item>
                        </EfexCard>
                    </Col>
                </Row>
                <Form.Item className="btn-back-next">
                    {registerUpdate?.registration_form ? (
                        <OrangeButton
                            style={{ width: "100px", fontWeight: "500" }}
                            type="primary"
                            htmlType="submit"
                            loading={loadingData.status === StateStatus.loading}
                        >
                            Atualizar
                        </OrangeButton>
                    )
                        :
                        (<OrangeButton
                            style={{ width: "100px", fontWeight: "500" }}
                            type="primary"
                            htmlType="submit"
                            loading={loadingData.status === StateStatus.loading}
                        >
                            {loadingData.status === StateStatus.loading
                                ? "Carregando"
                                : "Próximo"}
                        </OrangeButton>)
                    }
                </Form.Item>
            </Form>
        </RegisterBaseLayout>
    );
};

export default LegalPerson;
