import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StateStatus from "../../../utils/status";
import { postKycData, getKycData as getData } from "./service";

const initialState = {
	status: {
        addKycData: StateStatus.idle,
        getKycData: StateStatus.idle
	},
	kycData: null,
	errorMsg: null,
};

export const addKycData = createAsyncThunk(
	"secondStep/addKycData",
	async (data) => {
		const response = await postKycData(data);
		return response.data;
	},
);

export const getKycData = createAsyncThunk(
	"secondStep/getKycData",
	async ({ cpf }) => {
		const response = await getData(cpf);
		return response.data;
	},
);

export const secondStep = createSlice({
	name: "secondStep",
	initialState,
	extraReducers: {
		[addKycData.pending]: (state) => {
			state.status.addKycData = StateStatus.loading;
		},
		[addKycData.fulfilled]: (state) => {
			state.status.addKycData = StateStatus.succeeded;
		},
		[addKycData.rejected]: (state, action) => {
			state.status.addKycData = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[getKycData.pending]: (state) => {
			state.status.getKycData = StateStatus.loading;
		},
		[getKycData.fulfilled]: (state, action) => {
			state.status.getKycData = StateStatus.succeeded;
            state.kycData = action.payload
		},
		[getKycData.rejected]: (state, action) => {
			state.status.getKycData = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
	},
});

export const selectSecondStep = (state) => state.secondStep;
