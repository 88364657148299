import { Card, Typography } from "antd";
import React from "react"
import "./style.scss"

const CardNotice = () => {
    return (
        <Card className="card-notice">
            <Typography.Text level={4} className="title-white">
                Nossa equipe está avaliando os documentos da transação e, em breve, o câmbio ficará disponível para fechamento
			</Typography.Text>
        </Card>
    )
}

export default CardNotice;