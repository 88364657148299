import api from "../../../service/api";
import apiRoutes from "../../../service/apiRoutes";

export const listNewNotifications = (page) => api.get(`${apiRoutes.notifications}/notifications`, { params: { read: false, page, new_notifications: true } });
export const patchNotification = (notification_id) => api.patch(`${apiRoutes.notification}`, { notification_id });
export const patchAllNotifications = (read_all) => api.patch(`${apiRoutes.notification}`, { read_all })

export const getCompanyPersonUpdates = async (params) => api.get(`${apiRoutes.dashboard}/need_changes`, { params })

export const getReceiveValue = async (receiveDraftId, cpf_cnpj) => api.get(`${apiRoutes.receiveValue}?cpf_cnpj=${cpf_cnpj}&receive_value_id=${receiveDraftId}`);
export const getSendOperation = async (send_value_id, cpf_cnpj) => api.get(`${apiRoutes.sendValue}?cpf_cnpj=${cpf_cnpj}&send_value_id=${send_value_id}`)