import { Row, Col, Empty, Pagination, Checkbox } from "antd";
import React from "react";
import { DeleteOutlined, EyeOutlined, LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import "./styles.scss";
import { formatCurrencyBrl, getColorByStatus } from "../../../utils/functions";
import TooltipEfex from "../Tooltip";

const TableCard = ({ dataSource, render, pagination, onChange, loading }) => {
    return (
        <>
            <div className="table-card">
                {loading ? (
                    <Row
                        style={{ marginLeft: "0", marginRight: "0" }}
                        justify="center"
                    >
                        <div style={{ height: "200px", lineHeight: "200px" }}>
                            <SyncOutlined spin />
                        </div>
                    </Row>
                ) : (
                    <Row
                        gutter={[16, 16]}
                        style={{ width: `${dataSource.length * 220}px` }}
                    >
                        {dataSource.length === 0 ? (
                            <Col md={24}>
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="Sem resultados"
                                />
                            </Col>
                        ) : (
                            dataSource.map((item, index) => (
                                <Col key={index}>{render(item, index)}</Col>
                            ))
                        )}
                    </Row>
                )}
            </div>
            <Row justify="center">
                <Pagination
                    size="small"
                    showSizeChanger={false}
                    current={pagination ? pagination.current : 1}
                    total={pagination ? pagination.total : 1}
                    pageSize={pagination ? pagination.pageSize : 1}
                    onChange={onChange}
                />
            </Row>
        </>
    );
};

const OperationCard = (
    { data,
        onClick,
        onDelete,
        isDelete,
        isAproved,
        selectedCurrency,
        currency,
        setSelectedExchanges,
        setSelectedCurrency,
        id,
        selectedExchanges,
        exchange,
        setIsCurrencyTarget,
        storedSelectedExchanges,
        isCurrencyTarget,
        typeOfExchange,
        loading,
        isMultiple,
        operationDetails,
    }) => {
    return (
        <div className={isMultiple ? "multi-operation-card" : "operation-card"}>
            <div className="operation-card-header">
                <div className="operation-card-header-name">
                    <div style={{ marginRight: "10px" }}>{data.id}</div>

                    <div
                        style={{
                            color: "black",
                            fontWeight: "500",
                            textOverflow: "string",
                            overflow: "hidden",
                            height: "20px",
                        }}
                    >
                        {data.recipient_name ? data.recipient_name : ""}
                    </div>
                </div>
                <section style={{ display: "flex", gap: "5px" }}>
                    <div>
                        {isMultiple ? (
                            loading[id] ? (
                                <LoadingOutlined style={{ fontSize: "18px" }} />
                            ) : (
                                <EyeOutlined
                                    style={{ fontSize: "18px" }}
                                    onClick={() => { operationDetails(exchange) }}
                                />
                            )
                        ) : (
                            <EyeOutlined
                                style={{ fontSize: "18px" }}
                                onClick={onClick}
                            />
                        )}
                    </div>
                    {data.status === "draft" ||
                        data.status === "waiting_approval" ||
                        data.status === "need_changes" ||
                        data.status === "approved" ||
                        data.status === "waiting_exchange" ? (
                        <DeleteOutlined
                            style={{ fontSize: "18px" }}
                            onClick={onDelete}
                        />
                    ) : null}
                </section>
            </div>
            <div className="operation-card-body">
                <Row justify="space-between">
                    <Col flex="auto">
                        {typeOfExchange === "send_value" ? (
                            <div style={{ height: "70px", marginTop: "10px" }}>
                                <Row justify="space-between">
                                    <div style={{ color: "#105151", fontWeight: "500" }}>
                                        Banco no exterior
                                    </div>
                                    {isAproved && selectedExchanges &&
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox
                                                disabled={(selectedCurrency && currency !== selectedCurrency) || (selectedExchanges.length > 0 && exchange.is_currency_target !== isCurrencyTarget)}
                                                checked={selectedExchanges.includes(id)}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setSelectedExchanges((prevSelected) => [...prevSelected, exchange.id]);
                                                        setSelectedCurrency(currency);
                                                        setIsCurrencyTarget(exchange.is_currency_target)
                                                    } else {
                                                        if (selectedExchanges.length > 0) {
                                                            setSelectedExchanges((prevSelected) => {
                                                                const updatedSelection = prevSelected.filter((id) => id !== exchange.id);
                                                                if (updatedSelection.length <= 0) {
                                                                    setSelectedCurrency("");
                                                                    setIsCurrencyTarget()
                                                                }
                                                                return updatedSelection;
                                                            });
                                                        }
                                                    }
                                                }}
                                            />
                                            <TooltipEfex
                                                title="Você pode selecionar operações, aprovadas, para serem fechadas em lote. As operações selecionadas podem ser de envio ou de recebimento, e todas selecionadas devem ser da mesma moeda."
                                            />
                                        </div>
                                    }
                                </Row>
                                <div>{data.bank_name}</div>
                            </div>
                        ) : (
                            <div style={{ height: "70px", marginTop: "10px" }}>
                                <Row justify="space-between">
                                    <div style={{ color: "#105151", fontWeight: "500" }}>
                                        Banco no Brasil
                                    </div>
                                    {isAproved && selectedExchanges &&
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Checkbox
                                                disabled={(selectedCurrency && currency !== selectedCurrency) || (selectedExchanges.length > 0 && exchange.is_currency_target !== isCurrencyTarget)}
                                                checked={selectedExchanges.includes(id)}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setSelectedExchanges((prevSelected) => [...prevSelected, exchange.id]);
                                                        setSelectedCurrency(currency);
                                                        setIsCurrencyTarget(exchange.is_currency_target)
                                                    } else {
                                                        if (selectedExchanges.length > 0) {
                                                            setSelectedExchanges((prevSelected) => {
                                                                const updatedSelection = prevSelected.filter((id) => id !== exchange.id);
                                                                if (updatedSelection.length <= 0) {
                                                                    setSelectedCurrency("");
                                                                    setIsCurrencyTarget()
                                                                }
                                                                return updatedSelection;
                                                            });
                                                        }
                                                    }
                                                }}
                                            />
                                            <TooltipEfex
                                                title="Você pode selecionar operações, aprovadas, para serem fechadas em lote. As operações selecionadas podem ser de envio ou de recebimento, e todas selecionadas devem ser da mesma moeda."
                                            />
                                        </div>
                                    }
                                </Row>
                                <div>{data.bank_name} - {data.account_number}</div>
                            </div>
                        )}
                    </Col>
                    {isMultiple && typeOfExchange === "receive_value" && data.payer &&
                        <Col span={24}>
                            <div>Ordenante - {data.payer}</div>
                        </Col>}
                </Row>
                <div style={{ height: "30px" }}>
                    <div style={{ color: "#105151", fontWeight: "500" }}>
                        Estado
                    </div>
                    <div className="status">
                        <div
                            className={`status-circle ${getColorByStatus(
                                data.status
                            )}`}
                        ></div>
                        <div>
                            {data.status_display === "Operação criada pelo BO"
                                ? "Ordem de Pagamento Recebida"
                                : data.status_display}
                            {data.status_display ===
                                "Operação criada pelo BO" && (
                                    <TooltipEfex title="Por favor, clique no ícone na parte superior direito do card para preencher as informações desta operação" />
                                )}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        marginTop: "15px",
                        color: "black",
                        fontWeight: "500",
                        fontSize: "16px",
                    }}
                >
                    {data.is_currency_target
                        ? `${data.currency} ${formatCurrencyBrl(
                            data.target_value.toString()
                        )}`
                        : `BRL ${formatCurrencyBrl(
                            data.target_value.toString()
                        )}`}
                </div>
            </div>
        </div>
    );
};

export { TableCard, OperationCard };
