import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StateStatus from "../../utils/status";
import { postAcceptReview, putClientProfileUpdate } from './ConfirmRegisterService';

const initialState = {
    status: {
        confirmRegister: StateStatus.idle
    },
    errorMsg: null,
}


export const acceptReview = createAsyncThunk('confirmReg/acceptReview', async () => {
    const cnpj = JSON.parse(localStorage.getItem('company')).cnpj;
    const response = await postAcceptReview(cnpj)
    return response.data
})


export const confirmReg = createSlice({
    name: 'confirmReg',
    initialState,
    extraReducers: {
        [acceptReview.pending]: (state) => {
            state.status.confirmRegister = StateStatus.loading
        },
        [acceptReview.fulfilled]: (state) => {
            state.status.confirmRegister = StateStatus.succeeded
        },
        [acceptReview.rejected]: (state, action) => {
            state.status.confirmRegister = StateStatus.failed
            state.errorMsg = action.error.message
        }
    }
})

export const selectConfirmReg = (state) => state.confirmReg;