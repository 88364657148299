import React from "react";
import { Row, Col, Form, Input, Select, Typography } from "antd";
import { useState } from "react";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import StateStatus from "../../../../utils/status";
import "./styles.scss";
import { editContact, listContactSend, saveContactSend, selectContactSend } from "../../Contacts/ContactsSend/slice";
import ErrorMsg from "../../../../common/components/ErrorMsg";
import { countriesList } from "../../../../utils/lists";
import EfexModal from "../../../../common/components/Modal";
import { useRouteMatch } from "react-router-dom";
import TooltipEfex from "../../../../common/components/Tooltip";

const { Text } = Typography;
const { Option } = Select;

const ContactSendModal = ({
	visible,
	onOk,
	onCancel,
	// onFinish,
	...props
}) => {
	const [form] = Form.useForm();
	const contactState = useSelector(selectContactSend);
	const [state, setState] = useState({});
	const { path } = useRouteMatch();
	const dispatch = useDispatch();

	useEffect(() => {
		form.resetFields()
		if (contactState.contactSend.id) {
			form.setFieldsValue({ ...contactState.contactSend });
		}
	}, [contactState.contactSend, form, visible]);

	const updateState = (key, value) => {
		state[key] = value;
		setState({ ...state });
	};

	return (
		<EfexModal
			className={path.match("empresas") ? "modal-company" : "modal-person"}
			title={"Contato no exterior"}
			visible={visible}
			confirmLoading={
				contactState.status.saveContactSend === StateStatus.loading ||
				contactState.status.editContact === StateStatus.loading
			}
			onCancel={() => {
				form.resetFields();
				if (contactState.contactSend.id) {
					form.setFieldsValue({ ...contactState.contactSend });
				}
				onCancel();
			}}
			onOk={() => {
				form.validateFields()
					.then((values) => {
						if (contactState.contactSend.id) {
							values.accounts = state.accounts;
							if (values.addr_complement === "")
								delete values.addr_complement;
							dispatch(
								editContact({
									contactId: contactState.contactSend.id,
									data: values,
								}),
							).then(({ payload }) => {
								if (payload !== undefined) {
									onOk(payload.data);
									dispatch(listContactSend())
								}
							});
						} else {
							dispatch(saveContactSend(values)).then(
								({ payload }) => {
									if (payload !== undefined) {
										onOk(payload.data);
										dispatch(listContactSend())
										form.resetFields();
									}
								},
							);
						}
					})
					.catch((info) => {
					});
			}}
		>
			<p className="subtitle">Por favor, preencha abaixo as informações de quem vai receber os recursos no exterior.</p>
			<Form
				style={{ width: "100%" }}
				aria-autocomplete="none"
				form={form}
				layout="vertical"
				className="aaaaaaaa"
			>
				{contactState.status.saveContactSend === StateStatus.failed ||
					contactState.status.editContact === StateStatus.failed ? (
					<ErrorMsg message={contactState.errorMsg}></ErrorMsg>
				) : null}
				<Row gutter={[8, 0]}>
					<Col xs={9} sm={7} md={7} lg={7}>
						<Form.Item
							shouldUpdate={(prevValues, curValues) => {
								if (prevValues.type !== curValues.type) {
									updateState("type", curValues.type);
								}
							}}
							name="type"
							label="Tipo de contato"
							rules={[
								{
									required: true,
									message: "Seleção Obrigatória.",
								},
							]}
						>
							<Select
								onSelect={(value) => updateState(`type`, value)}
							>
								<Option value={"person"}>Pessoa</Option>
								<Option value={"company"}>Empresa</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col xs={15} sm={17} md={17} lg={17}>
						<Form.Item
							label={
								state.type === "company"
									? "Razão social completa"
									: "Nome completo"
							}
							name="name"
							rules={[
								{
									required: true,
									message: "Campo obrigatório.",
								},
							]}
						>
							<Input type="text" placeholder="Nome" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[8, 0]}>
					<Col span={state.type === "company" ? 12 : 24}>
						<Form.Item
							label="Identidade Fiscal"
							name="tax_identity"
						>
							<Input
								type="text"
								placeholder="Identidade Fiscal"
							/>
						</Form.Item>
					</Col>
					{state.type === "company" && (
						<Col span={12}>
							<Form.Item
								shouldUpdate={(prevValues, curValues) => {
									if (
										prevValues.is_governmental !==
										curValues.is_governmental
									) {
										updateState(
											`governmental`,
											curValues.is_governmental,
										);
									}
								}}
								name="is_governmental"
								label="É orgão governamental?"
								rules={[
									{
										required: state.type === "company",
										message: "Seleção Obrigatória.",
									},
								]}
							>
								<Select
									onSelect={(value) =>
										updateState(`governmental`, value)
									}
								>
									<Option value={true}>Sim</Option>
									<Option value={false}>Não</Option>
								</Select>
							</Form.Item>
						</Col>
					)}
				</Row>
				<div style={{ padding: "20px 0" }}>
					<Text type="secondary">
						{<>
							{state.type === "company"
							? "Endereço comercial"
							: "Endereço residencial"}
							<TooltipEfex title="Precisamos que insira abaixo a cidade e país de residência de quem for receber o dinheiro."/>
						</>}
					</Text>
				</div>
				<Row gutter={[8, 0]}>
					<Col xs={13} sm={13} md={13} lg={13}>
						<Form.Item
							label="Cidade"
							name={"addr_city_name"}
						>
							<Input placeholder="Cidade" type="text"></Input>
						</Form.Item>
					</Col>
					<Col xs={11} sm={11} md={11} lg={11}>
						<Form.Item
							label="País"
							name={"addr_country"}
						>
							<Select
								showSearch
								filterOption={(input, option) =>
									option.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
							>
								{countriesList.map((item) => {
									return (
										<Option
											key={item.pais}
											value={item.pais}
										>
											{item.pais}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item
							label="Endereço"
							name={"addr"}
						>
							<Input.TextArea placeholder="Endereço" rows={3}></Input.TextArea>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</EfexModal>
	);
};

export default ContactSendModal;
