import React from "react";
import { Col, Row } from "antd";
import { cnpj as cnpjValidator } from "cpf-cnpj-validator";
import actualStatus from "../../../../utils/registerStatus"
import { OrangeLink } from "../../../../common/components/Link"
import { ExclamationOutlined } from "@ant-design/icons"
import "./styles.scss";
import { ellipsis } from "../../../../utils/beautify";
import { DeleteOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";

const CompanyCard = ({ company, onClick, loading, inCreating, ...props }) => {
  const [statusTextLength, setStatusTextLength] = useState(35);
  const [companyNameLength, setCompanyNameLength] = useState(35)

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 373) {
        setCompanyNameLength(30)
        setStatusTextLength(24)
      } else if (window.innerWidth > 372 && window.innerWidth < 425) {
        setCompanyNameLength(35)
        setStatusTextLength(32)
      } else {
        setCompanyNameLength(60)
        setStatusTextLength(60)
      }
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <div
      style={{ paddingTop: "18px", paddingRight: '4px', paddingBottom: '4px', paddingLeft: '4px', width: "100%", backgroundColor: props.isOdd ? "white" : "#F7F7F7" }}
    >
      <Row>
        {
          inCreating ? <Col sm={1}>
            <div style={{ marginTop: "4px", marginRight: "5px", display: "flex", justifyContent: "center", alignItems: "center", width: "18px", height: "18px", backgroundColor: "#F99D1C", borderRadius: "50px", color: "white" }}>
              <ExclamationOutlined />
            </div>
          </Col>
            : null
        }
        <Col className="company-infos" sm={inCreating ? 22 : 24}>
          {
            props.isResponsible ? <Col>
              <Row>
                <Col style={{ cursor: "pointer" }} onClick={company.can_register && actualStatus(company.register_steps) !== "Aguardar aprovação" ? onClick : null}>
                  <div className="company-name" >{ellipsis(company.company_name, companyNameLength)}</div>
                  <div style={{ fontSize: "14px", fontFamily: "Roboto", color: "#838282" }}>
                    {cnpjValidator.format(company.cnpj)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: "" }}>
                    {
                      inCreating ?
                        <div><b>Status: </b>&nbsp;{company.can_register ? ellipsis(company.register_steps[company.register_steps.length - 1].value, statusTextLength) : 'Situação cadastral atípica'}</div>
                        : <OrangeLink to={`/dashboard/empresas/${company.id}/cambio`}>Ver empresa e fazer câmbio</OrangeLink>
                    }
                  </div>
                </Col>
              </Row>
              {
                inCreating ?
                  <Row >
                    <Col className="delete-column-mobile">
                      <DeleteOutlined style={{ fontSize: '18px' }} onClick={props.onDelete} />
                    </Col>
                  </Row>
                  : null
              }
            </Col>
              : <Row>
                <Col style={{ cursor: "pointer" }} onClick={company.can_register && actualStatus(company.register_steps) !== "Aguardar aprovação" ? onClick : null} sm={13}>
                  <div className="company-name">{ellipsis(company.company_name, 40)}</div>
                  <div style={{ fontSize: "14px", fontFamily: "Roboto", color: "#838282" }}>
                    {cnpjValidator.format(company.cnpj)}
                  </div>
                </Col>
                <Col sm={10}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: "" }}>
                    {
                      inCreating ?
                        <div><b>Status: </b>&nbsp;{company.can_register ? company.register_steps[company.register_steps.length - 1].value : 'Situação cadastral atípica'}</div>
                        : <OrangeLink to={`/dashboard/empresas/${company.id}/cambio`}>Ver empresa e fazer câmbio</OrangeLink>
                    }
                  </div>
                </Col>
                {
                  inCreating ?
                    <Col className="delete-column-mobile">
                      <DeleteOutlined style={{ fontSize: "18px", }} onClick={props.onDelete} />
                    </Col>
                    : null
                }
              </Row>
          }
        </Col>
      </Row>
    </div>
  );
};

export default CompanyCard;
