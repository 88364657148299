import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import "./styles.scss"

const ConfirmModal = ({ onOkFunc, onCancelFunc, okText, cancelText, title, content, applyClass }) => {
	const { confirm } = Modal;

	return confirm({
		okText: okText,
		cancelText: cancelText,
		title: title,
		icon: <ExclamationCircleFilled />,
		content: content,
		okButtonProps: { className: applyClass ? "confirm-button" : "" },
		onOk() {
			onOkFunc()
		},
		onCancel() {
			onCancelFunc()
		},
	});
};

ConfirmModal.defaultProps = {
	okText: String,
	cancelText: String,
	title: String,
	content: String,
	applyClass: false,
}

export default ConfirmModal