import React from "react";
import "./styles.scss";

const NotOpenToExchange = ({ isScheduled, sendValue = false, currency }) => {
    return (
        <div className="not-open">
            {isScheduled ? (
                <>
                    <br />
                    <br />
                    <p>
                        Essa operação está programada para fechamento automático. Se desejar, você pode cancelar a programação abaixo, até às 08:59 do próximo dia útil
                    </p>
                </>
            ) : (
                <>
                    <br />
                    No momento não é possível realizar sua operação.<br /><br />
                    Horário de funcionamento do mercado de câmbio: <br />
                    De 09:00 às 17:00 horas, horário de Brasília, de segunda a sexta (exceto feriados bancários). <br /><br />
                    {sendValue && currency === "USD" && (
                        <>
                            Se preferir, você pode deixar sua operação programada para fechamento automático, abaixo.
                            <br /><br />
                        </>
                    )}
                    <a href="https://efexhub.com.br/faq/" target="_blank" rel="noreferrer"><strong>Consultar FAQ</strong></a>
                </>
            )}
        </div>
    );
}

export default NotOpenToExchange;
