import React from "react";

import { Col, } from "antd";

// const { Sider } = Layout
import "./styles.scss";
// import ExchangeBread from "../_components/ExchangeBread";
import ContactsSend from "./ContactsSend";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import CreateContactSend from "./ContactsSend/ContactSend";

const Contacts = (props) => {
	let { path } = useRouteMatch();

	return (
		<>
			<Col className="contacts">
				{/* <ExchangeBread pageName="Contatos" /> */}
				<Col>
					<Switch>
						<Route exact path={[path, `${path}/novo`]}>
							<ContactsSend></ContactsSend>
						</Route>
						<Route path={`${path}/:id`}>
							<CreateContactSend />
						</Route>
					</Switch>
				</Col>
			</Col>
		</>
	);
};

export default Contacts;
