import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Typography, Form, Input, Select, Divider, message } from "antd";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
    addNewQsa,
    selectQsa,
    getResponsibles,
} from "../SelectPartners/SelectPartnersSlice";
import { beautifyCpfCnpj } from "../../utils/beautify";
import MaskedInput from "antd-mask-input";
import { cpf as cpfValidator, cnpj as cnpjValidator } from "cpf-cnpj-validator";
import IdentedField from "./components/IdentedField";
import { BankList, BrazilStates, countriesList } from "../../utils/lists";
import { postQsaInfo, selectCompleteQsaInfo } from "./CompleteQsaInfoSlice";
import { getAddressByCep } from "../../service/viacep.service";
import RegisterBaseLayout from "../../common/components/RegisterBaseLayout/RegisterBaseLayout";
import StateStatus from "../../utils/status";
import Routes from "../../routes/routes";
import moment from "moment";
import TooltipEfex from "../../common/components/Tooltip";
import { mask, options_nat } from "../../utils/consts";
import ErrorMsg from "../../common/components/ErrorMsg";
import { toBrazilianDateString } from "../../utils/date";
import { OrangeButton, WhiteButton } from "../../common/components/Button";
import { EfexCard } from "../../common/components/Card";
import CurrencyField from "../../common/components/currency_field/currency_field";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import br from "world_countries_lists/data/countries/en/world.json";
import _ from "lodash";
import { getFindCpf } from "./CompleteQsaInfoSlice";
import { SyncOutlined } from "@ant-design/icons";
import { selectCompany } from "../ChooseCompany/ChooseCompanySlice";

const { Title, Text } = Typography;
const { Option } = Select;

const TypeSelector = (index, initialValue) => (
    <Form.Item
        name={["doc_type", index]}
        initialValue={initialValue}
        rules={[
            {
                required: true,
                message: "Valor inválido.",
            },
        ]}
        noStyle
    >
        <Select placeholder="Escolher">
            <Option value="CNH">CNH</Option>
            <Option value="RG">RG</Option>
            <Option value="RNE">RNE</Option>
        </Select>
    </Form.Item>
);

const CompleteQsaInfo = (props) => {
    const financialResponsibles = useSelector(selectQsa);
    const completeQsaInfo = useSelector(selectCompleteQsaInfo);
    const [state, setState] = useState({});
    const [lstValueCountryNumber, setLstValueCountryNumber] = useState([]);
    const [update, setUpdate] = useState(false);
    const [updateLstPhone, setUpdateLstPhone] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [useCep, setUseCep] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allPhones, setAllPhones] = useState([])
    const [registerUpdate, setRegisterUpdate] = useState([])
    const selectedCompany = useSelector(selectCompany);

    const formRef = useRef(null);

    const handleFinishFailed = ({ values, errorFields }) => {
        errorFields.forEach(({ name }) => {
            const [fieldName] = name;
            scrollToField(fieldName);
        });
        message.error('Por favor, preencha todos os campos obrigatórios.');
    };

    useEffect(() => {
        if (selectedCompany.status?.getCompanyByCnpj === "succeeded") {
            setRegisterUpdate(selectedCompany.company.register_update[0])
        }
    }, [selectedCompany])

    // eslint-disable-next-line
    const updateState = (key, value) => {
        state[key] = value;
        setState({ ...state });
    };

    useEffect(() => {
        let aux = [];
        if (
            financialResponsibles.status.responsibles === StateStatus.succeeded
        ) {
            financialResponsibles.data.responsibles.forEach((item, index) => {
                aux[index] = false;
                if (!item.cpf.includes("*")) {
                    const cpf = String(item.cpf)
                    setLoading(true)
                    dispatch(getFindCpf({ cpf: cpf })).then((resp) => {
                        setLoading(false)
                        if (resp.payload) {
                            const data = resp.payload.financial_responsible?.cadastral_data ? resp.payload.financial_responsible?.cadastral_data : resp.payload?.financial_responsible;
                            let phone = data?.cell_phone
                            if (phone) {
                                phone.split("-")
                                setAllPhones(prevState => ({
                                    ...prevState,
                                    [cpf]: { code: parseInt(phone[0]), phone: phone[1] }
                                }));
                            }
                            form.setFieldValue(['doc_type', index], data?.doc_type);
                            form.setFieldValue(['doc_number', index], data?.doc_number,);
                            form.setFieldValue(['doc_dispatcher', index], data?.doc_dispatcher);
                            form.setFieldValue(['doc_uf', index], data?.doc_uf);
                            form.setFieldValue(['doc_shipping_date', index], toBrazilianDateString(data?.doc_shipping_date));
                            form.setFieldValue(['birthday', index], toBrazilianDateString(data?.birthday));
                            form.setFieldValue(['email', index], data?.email);
                            form.setFieldValue(['profession', index], data?.profession);
                            form.setFieldValue(['mother_name', index], data?.mother_name);
                            form.setFieldValue(['father_name', index], data?.father_name);
                            form.setFieldValue(['gender', index], data?.gender);
                            form.setFieldValue(['birthplace', index], data?.birthplace);
                            form.setFieldValue(['residential_country', index], data?.residential_country);
                            form.setFieldValue(['nationality', index], data?.nationality);
                            form.setFieldValue(['marital_status', index], data?.marital_status)
                            form.setFieldValue(['addr_zipcode', index], data?.addr_zipcode);
                            form.setFieldValue(['addr_public_place', index], data?.addr_public_place);
                            form.setFieldValue(['addr_number', index], data?.addr_number);
                            form.setFieldValue(['addr_neighborhood', index], data?.addr_neighborhood);
                            form.setFieldValue(['addr_complement', index], data?.addr_complement);
                            form.setFieldValue(['addr_city_name', index], data?.addr_city_name);
                            form.setFieldValue(['addr_province', index], data?.addr_province);
                            form.setFieldValue(['bank_name', index], data?.bank_name);
                            form.setFieldValue(['agency_number', index], data?.agency_number);
                            form.setFieldValue(['bank_account_number', index], data?.bank_account_number);
                        }
                    });
                }
                if (item.has_public_agent_link) {
                    updateState(`${index}publicLink`, true);
                }
                if (item.is_public_agent) {
                    updateState(`${index}publicAgent`, true);
                }
                if (item.marital_status === "C") {
                    updateState(`${index}marital`, "C");
                }
                if (
                    (!_.isUndefined(item.residential_country) &&
                        !_.isNull(item.residential_country) &&
                        item.residential_country.toLowerCase() === "brasil") || _.isUndefined(item.residential_country)
                ) {
                    aux[index] = true;
                }
            });
            setUseCep(aux);
            setUpdate(!update);
        }
    }, [financialResponsibles.status.responsibles]);


    useEffect(() => {
        if (financialResponsibles.status.responsibles === StateStatus.idle) {
            dispatch(getResponsibles());
        }
        if (completeQsaInfo.status === StateStatus.succeeded) {
            dispatch(getResponsibles());
            props.history.push(Routes.confirmRegister);
        }
    }, [
        financialResponsibles.data.responsibles,
        completeQsaInfo.status,
        dispatch,
        financialResponsibles.status.responsibles,
        props.history,
        updateState,
    ]);

    const onChangeCountryPhone = (value, index) => {
        var aux = _.cloneDeep(lstValueCountryNumber);
        aux[index] = value;
        setLstValueCountryNumber(aux);
        setUpdateLstPhone(!updateLstPhone);
    };

    const scrollToField = (name) => {
        const field = formRef.current.getFieldInstance(name);
        if (field) {
            field.focus();
            field.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    const customValidator = (_, value) => {
        if (!value) {
            scrollToField('cell_phone');
            return Promise.reject('Telefone é obrigatório');
        }
        return Promise.resolve();
    };


    const onFinish = (values) => {
        for (let i = 0; i < values.monthly_income.length; i++) {
            if (!values.monthly_income[i]) {
                values.monthly_income[i] = 0
            }
        }
        const newQsas = [];
        const Qsas = [];
        financialResponsibles.data.responsibles.forEach((it, index) => {
            const newQsa = {};
            newQsa.is_pep = false;
            newQsa.participation = 1;
            Object.entries(values).forEach((item) => {
                let finalValue;
                if (item[0].search("zipcode") !== -1) {
                    finalValue = item[1][index].replace(/\D/g, "");
                } else if (item[0].search("cpf") !== -1) {
                    finalValue = cpfValidator.strip(item[1][index]);
                } else if (item[0].search("cell_phone") !== -1) {
                    if (item[1][index].phone !== undefined) {
                        finalValue = `${item[1][index].code}-${item[1][
                            index
                        ].phone.replace(/\D/g, "")}`;
                    } else {
                        finalValue = item[1][index];
                    }
                } else if (item[0].search("bank_name") !== -1) {
                    let bankCode = item[1][index].split(" | ");
                    finalValue = item[1][index];
                    newQsa["bank_code"] = bankCode[0];
                } else if (
                    item[0].search("birthday") !== -1 ||
                    item[0].search("date") !== -1
                ) {
                    // verifica se o valor não é nulo antes de tentar criar a data
                    if (
                        item[1][index] !== null &&
                        item[1][index] !== undefined &&
                        item[1][index] !== ""
                    ) {
                        const splitedDate = item[1][index].split("/");
                        finalValue = `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`;
                    }
                } else {
                    finalValue = item[1][index];
                }
                newQsa[item[0]] = finalValue;
            });

            if (newQsa.is_public_agent) {
                newQsa["public_ocupation_details"] = {
                    public_agent_occupation: newQsa.public_agent_occupation,
                    public_agent_start_date: newQsa.public_agent_start_date,
                    public_agent_end_date: newQsa.public_agent_end_date,
                    public_agent_cnpj: cnpjValidator.strip(
                        newQsa.public_agent_cnpj
                    ),
                };
                delete newQsa.public_agent_occupation;
                delete newQsa.public_agent_start_date;
                delete newQsa.public_agent_end_date;
                delete newQsa.public_agent_cnpj;
            }
            if (newQsa.has_public_agent_link) {
                newQsa["public_agent_link_details"] = {
                    public_agent_link_name: newQsa.public_agent_link_name,
                    public_agent_link_cpf: newQsa.public_agent_link_cpf,
                    public_agent_link_occupation:
                        newQsa.public_agent_link_occupation,
                    public_agent_link_type: newQsa.public_agent_link_type,
                };

                delete newQsa.public_agent_link_name;
                delete newQsa.public_agent_link_cpf;
                delete newQsa.public_agent_link_occupation;
                delete newQsa.public_agent_link_type;
            }

            if (it.qualification === "socio") {
                newQsa["id"] = `${it.id}`;
                newQsa["qualification"] = it.qualification;
                newQsa["name"] = it.name;
                Qsas.push(newQsa);
            } else {
                newQsa["cpf"] = it.cpf;
                newQsa["qualification"] = it.qualification;
                newQsa["country_name"] = newQsa.residential_country;
                newQsa["name"] = it.name;
                newQsas.push(newQsa);
            }

            if (it.addr_complement === "") {
                delete newQsa.addr_complement;
            }
        });
        const cmp = JSON.parse(localStorage.getItem("company"));

        const is_upolading = !!registerUpdate?.representative_form;
        dispatch(postQsaInfo({ qsa: Qsas, new_qsa: newQsas, cnpj: cmp.cnpj, is_uploading: is_upolading, current_update_step: "representative_form" }));
    };

    return (
        <RegisterBaseLayout
            {...props}
            current={3}
            titleL3="Informações Sobre os Responsáveis"
            text="Complete as informações para cada responsável financeiro"
        >
            <Form
                ref={formRef}
                onFinishFailed={handleFinishFailed}
                aria-autocomplete="none"
                form={form}
                layout="vertical"
                onFinish={(values) => onFinish(values)}
                scrollToFirstError={true}
                onValuesChange={(val, fields) => {
                    if (useCep[0]) {
                        val.addr_zipcode?.forEach((el, index) => {
                            const zipcode = el.replace(/\D/g, "");
                            if (zipcode.length === 8) {
                                getAddressByCep(zipcode).then((response) => {
                                    fields.addr_public_place[index] =
                                        response.logradouro;
                                    fields.addr_neighborhood[index] =
                                        response.bairro;
                                    fields.addr_city_name[index] =
                                        response.localidade;
                                    fields.addr_province[index] = response.uf;

                                    form.setFieldsValue(fields);
                                });
                            }
                        });
                    }

                    if (val.residential_country) {
                        let aux = [];
                        val.residential_country.forEach((el, index) => {
                            aux[index] = false;
                            if (el.toLowerCase() === "brasil") {
                                aux[index] = true;
                            }
                        });
                        setUseCep(aux);
                    }
                }}
            >
                {completeQsaInfo.status === StateStatus.failed ? (
                    <ErrorMsg message={completeQsaInfo.errorMsg} />
                ) : null}

                {financialResponsibles.data.responsibles.map(
                    (responsible, index) => {
                        dispatch(addNewQsa(responsible));
                        return (
                            <div key={index}>
                                <Row gutter={[8, 8]}>
                                    <Col xs={24} sm={24} lg={12}>
                                        <EfexCard>
                                            <Title level={5}>
                                                {responsible.name}
                                            </Title>
                                            <div style={{ textAlign: "left" }}>
                                                <Text type="secondary">
                                                    {beautifyCpfCnpj(
                                                        responsible.cpf
                                                    )}
                                                </Text>
                                            </div>
                                            <br />
                                            <Title
                                                level={5}
                                                className="subtitle"
                                            >
                                                Dados cadastrais
                                            </Title>
                                            <Divider />
                                            <Form.Item
                                                hidden={true}
                                                name={["qualification", index]}
                                                initialValue={
                                                    responsible.qualification
                                                }
                                            >
                                                <Input type="text"></Input>
                                            </Form.Item>
                                            <Row justify="space-between">
                                                <Col xs={24} sm={24} lg={24}>
                                                    <Form.Item
                                                        label="Tipo e número do documento de identificação"
                                                        name={[
                                                            "doc_number",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.doc_number
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Documento inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            addonBefore={TypeSelector(
                                                                index,
                                                                responsible.doc_type
                                                            )}
                                                            placeholder="Número"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={12} sm={12} lg={11}>
                                                    <Form.Item
                                                        label="Orgão emissor"
                                                        name={[
                                                            "doc_dispatcher",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.doc_dispatcher
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Orgão emissor obrigatório",
                                                            },
                                                            {
                                                                pattern:
                                                                    new RegExp(
                                                                        /^\D{2,}$/
                                                                    ),
                                                                message:
                                                                    "Nome inválido",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Nome do orgão"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={11} sm={12} lg={12}>
                                                    <Form.Item
                                                        label="UF emissora"
                                                        name={["doc_uf", index]}
                                                        initialValue={
                                                            responsible.doc_uf
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "UF emissora obrigatório",
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholde="UF">
                                                            <Select.Option value="AC">
                                                                AC
                                                            </Select.Option>
                                                            <Select.Option value="AL">
                                                                AL
                                                            </Select.Option>
                                                            <Select.Option value="AP">
                                                                AP
                                                            </Select.Option>
                                                            <Select.Option value="AM">
                                                                AM
                                                            </Select.Option>
                                                            <Select.Option value="BA">
                                                                BA
                                                            </Select.Option>
                                                            <Select.Option value="CE">
                                                                CE
                                                            </Select.Option>
                                                            <Select.Option value="DF">
                                                                DF
                                                            </Select.Option>
                                                            <Select.Option value="ES">
                                                                ES
                                                            </Select.Option>
                                                            <Select.Option value="GO">
                                                                GO
                                                            </Select.Option>
                                                            <Select.Option value="MA">
                                                                MA
                                                            </Select.Option>
                                                            <Select.Option value="MS">
                                                                MS
                                                            </Select.Option>
                                                            <Select.Option value="MT">
                                                                MT
                                                            </Select.Option>
                                                            <Select.Option value="MG">
                                                                MG
                                                            </Select.Option>
                                                            <Select.Option value="PA">
                                                                PA
                                                            </Select.Option>
                                                            <Select.Option value="PB">
                                                                PB
                                                            </Select.Option>
                                                            <Select.Option value="PR">
                                                                PR
                                                            </Select.Option>
                                                            <Select.Option value="PE">
                                                                PE
                                                            </Select.Option>
                                                            <Select.Option value="PI">
                                                                PI
                                                            </Select.Option>
                                                            <Select.Option value="RJ">
                                                                RJ
                                                            </Select.Option>
                                                            <Select.Option value="RN">
                                                                RN
                                                            </Select.Option>
                                                            <Select.Option value="RS">
                                                                RS
                                                            </Select.Option>
                                                            <Select.Option value="RO">
                                                                RO
                                                            </Select.Option>
                                                            <Select.Option value="RR">
                                                                RR
                                                            </Select.Option>
                                                            <Select.Option value="SC">
                                                                SC
                                                            </Select.Option>
                                                            <Select.Option value="SP">
                                                                SP
                                                            </Select.Option>
                                                            <Select.Option value="SE">
                                                                SE
                                                            </Select.Option>
                                                            <Select.Option value="TO">
                                                                TO
                                                            </Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row justify="space-between">
                                                <Col xs={24} sm={24} lg={11}>
                                                    <Form.Item
                                                        label="Data de expedição"
                                                        name={[
                                                            "doc_shipping_date",
                                                            index,
                                                        ]}
                                                        initialValue={toBrazilianDateString(
                                                            responsible.doc_shipping_date
                                                        )}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Orgão emissor obrigatório",
                                                            },
                                                            () => ({
                                                                validator(
                                                                    _,
                                                                    value
                                                                ) {
                                                                    const dateOnlyNumbers =
                                                                        value.replace(
                                                                            /\D/g,
                                                                            ""
                                                                        );
                                                                    const date =
                                                                        moment(
                                                                            value,
                                                                            "DD/MM/YYYY"
                                                                        );
                                                                    if (
                                                                        dateOnlyNumbers.length <
                                                                        8 ||
                                                                        !date.isValid()
                                                                    ) {
                                                                        return Promise.reject(
                                                                            new Error(
                                                                                "Data inválida"
                                                                            )
                                                                        );
                                                                    } else {
                                                                        return Promise.resolve();
                                                                    }
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <MaskedInput
                                                            mask={mask.date}
                                                        ></MaskedInput>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} lg={12}>
                                                    <Form.Item
                                                        label="Data de nascimento"
                                                        name={[
                                                            "birthday",
                                                            index,
                                                        ]}
                                                        initialValue={toBrazilianDateString(
                                                            responsible.birthday
                                                        )}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Data obrigatória",
                                                            },
                                                            () => ({
                                                                validator(
                                                                    _,
                                                                    value
                                                                ) {
                                                                    const dateOnlyNumbers =
                                                                        value.replace(
                                                                            /\D/g,
                                                                            ""
                                                                        );
                                                                    const date =
                                                                        moment(
                                                                            value,
                                                                            "DD/MM/YYYY"
                                                                        );
                                                                    const actualDate =
                                                                        new Date();
                                                                    const miniumAge =
                                                                        moment([
                                                                            actualDate.getFullYear() -
                                                                            18,
                                                                            actualDate.getMonth(),
                                                                            actualDate.getDate(),
                                                                        ]);
                                                                    if (
                                                                        dateOnlyNumbers.length <
                                                                        8 ||
                                                                        !date.isValid()
                                                                    ) {
                                                                        return Promise.reject(
                                                                            new Error(
                                                                                "Data inválida"
                                                                            )
                                                                        );
                                                                    } else if (
                                                                        date.isBefore(
                                                                            miniumAge
                                                                        )
                                                                    ) {
                                                                        return Promise.resolve();
                                                                    } else {
                                                                        return Promise.reject(
                                                                            new Error(
                                                                                "Não pode ser menor de idade"
                                                                            )
                                                                        );
                                                                    }
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <MaskedInput
                                                            mask={mask.date}
                                                        ></MaskedInput>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} lg={24}>
                                                    <Form.Item
                                                        label={
                                                            <>
                                                                E-mail do
                                                                responsável
                                                                legal
                                                                <TooltipEfex
                                                                    title="Aqui precisamos do e-mail do responsável legal da empresa, combinado?
                                                            Os links de assinatura eletrônica das fichas de cadastro e contratos de câmbio serão eviados
                                                            para este endereço"
                                                                />
                                                            </>
                                                        }
                                                        name={["email", index]}
                                                        initialValue={
                                                            responsible.email
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Informe o email.",
                                                            },
                                                            {
                                                                type: "email",
                                                                message:
                                                                    "Informe um email válido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Email"
                                                            type="email"
                                                        ></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} lg={24}>
                                                    <Form.Item
                                                        label="Profissão"
                                                        name={[
                                                            "profession",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.profession
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Profissão inválida.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Profissão"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} lg={11}>
                                                    <Form.Item
                                                        label="Nome completo da mãe"
                                                        name={[
                                                            "mother_name",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.mother_name
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Nome inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Nome completo"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} lg={12}>
                                                    <Form.Item
                                                        label="Nome completo do pai"
                                                        name={[
                                                            "father_name",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.father_name
                                                        }
                                                    >
                                                        <Input
                                                            placeholder="Nome completo"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} lg={24}>
                                                    <Form.Item
                                                        label="Renda mensal aproximada"
                                                        name={[
                                                            "monthly_income",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.monthly_income
                                                        }
                                                    >
                                                        <CurrencyField
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row justify="space-between">
                                                <Col xs={24} sm={24} lg={11}>
                                                    <Form.Item
                                                        name={["gender", index]}
                                                        label="Gênero"
                                                        initialValue={
                                                            responsible.gender
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Seleção Obrigatória.",
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="Escolher">
                                                            <Option value="M">
                                                                Masculino
                                                            </Option>
                                                            <Option value="F">
                                                                Feminino
                                                            </Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} lg={12}>
                                                    <Form.Item
                                                        label="Telefone"
                                                        name={['cell_phone', index]}
                                                        initialValue={responsible.cell_phone}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Telefone inválido.'
                                                            }
                                                        ]}
                                                    >
                                                        <ConfigProvider locale={br}>
                                                            <CountryPhoneInput className="register-country-phone" onChange={(v) => {
                                                                var aux = lstValueCountryNumber;
                                                                aux = _.cloneDeep(lstValueCountryNumber);
                                                                aux[index] = v;
                                                                setLstValueCountryNumber(aux);
                                                                form.setFieldsValue({ 'cell_phone': lstValueCountryNumber });
                                                            }} value={_.cloneDeep(lstValueCountryNumber[index])} />
                                                        </ConfigProvider>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Form.Item
                                                label="Cidade natal"
                                                name={["birthplace", index]}
                                                initialValue={
                                                    responsible.birthplace
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Nome inválid0.",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="Nome completo"
                                                    type="text"
                                                ></Input>
                                            </Form.Item>
                                            <Row justify="space-between">
                                                <Col xs={24} sm={24} lg={10}>
                                                    <Form.Item
                                                        label="País onde mora"
                                                        name={[
                                                            "residential_country",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.residential_country
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Nome inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            filterOption={(
                                                                input,
                                                                option
                                                            ) =>
                                                                option.children
                                                                    .toLowerCase()
                                                                    .indexOf(
                                                                        input.toLowerCase()
                                                                    ) >= 0
                                                            }
                                                            onChange={() => {
                                                                setUpdate(
                                                                    !update
                                                                )
                                                                const resetAddressFields = {
                                                                    addr_public_place: undefined,
                                                                    addr_neighborhood: undefined,
                                                                    addr_city_name: undefined,
                                                                    addr_province: undefined,
                                                                    addr_zipcode: undefined,
                                                                };
                                                                form.setFieldsValue(resetAddressFields)
                                                            }
                                                            }
                                                        >
                                                            {countriesList.map(
                                                                (item) => {
                                                                    return (
                                                                        <Option
                                                                            key={
                                                                                item.pais
                                                                            }
                                                                            value={
                                                                                item.pais
                                                                            }
                                                                        >
                                                                            {
                                                                                item.pais
                                                                            }
                                                                        </Option>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} lg={10}>
                                                    <Form.Item
                                                        label="Nacionalidade"
                                                        name={[
                                                            "nationality",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.nationality
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Nome inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            options={
                                                                options_nat
                                                            }
                                                        >
                                                            {" "}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Form.Item
                                                name={["marital_status", index]}
                                                label="Estado civil"
                                                initialValue={
                                                    responsible.marital_status
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Seleção Obrigatória.",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    onSelect={(value) =>
                                                        updateState(
                                                            `${index}marital`,
                                                            value
                                                        )
                                                    }
                                                >
                                                    <Option value="C">
                                                        Casado(a)
                                                    </Option>
                                                    <Option value="S">
                                                        Solteiro(a)
                                                    </Option>
                                                    <Option value="D">
                                                        Divorciado(a)
                                                    </Option>
                                                    <Option value="V">
                                                        Viúvo(a)
                                                    </Option>
                                                    <Option value="U">
                                                        União Estável
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                            {state[`${index}marital`] === "C" ||
                                                state[`${index}marital`] === "U" ? (
                                                <IdentedField color="orange">
                                                    <Form.Item
                                                        label="Nome do cônjuge"
                                                        name={[
                                                            "spouse_name",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.spouse_name
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Nome inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Nome completo"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="CPF do cônjuge"
                                                        name={[
                                                            "spouse_cpf",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.spouse_cpf
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Informe o CPF",
                                                            },
                                                            () => ({
                                                                validator(
                                                                    _,
                                                                    value
                                                                ) {
                                                                    if (
                                                                        !value ||
                                                                        cpfValidator.isValid(
                                                                            value
                                                                        )
                                                                    ) {
                                                                        return Promise.resolve();
                                                                    }

                                                                    return Promise.reject(
                                                                        new Error(
                                                                            "CPF inválido."
                                                                        )
                                                                    );
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <MaskedInput
                                                            mask={mask.cpf}
                                                        ></MaskedInput>
                                                    </Form.Item>
                                                </IdentedField>
                                            ) : null}
                                            <Form.Item
                                                label="CEP"
                                                name={["addr_zipcode", index]}
                                                initialValue={
                                                    responsible.addr_zipcode
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "CEP inválido.",
                                                    },
                                                ]}
                                            >
                                                {useCep[index] ? (
                                                    <MaskedInput
                                                        autoComplete="false"
                                                        mask={mask.cep}
                                                    ></MaskedInput>
                                                ) : (
                                                    <Input
                                                        placeholder="Código Postal"
                                                        type="text"
                                                    ></Input>
                                                )}
                                            </Form.Item>
                                            <Row justify="space-between">
                                                <Col xs={24} sm={24} lg={18}>
                                                    <Form.Item
                                                        label="Logradouro"
                                                        name={[
                                                            "addr_public_place",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.addr_public_place
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Endereço inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Informar"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} lg={5}>
                                                    <Form.Item
                                                        label="Número"
                                                        name={[
                                                            "addr_number",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.addr_number
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Número inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input type="text"></Input>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row justify="space-between">
                                                <Col xs={24} sm={24} lg={11}>
                                                    <Form.Item
                                                        label="Bairro"
                                                        name={[
                                                            "addr_neighborhood",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.addr_neighborhood
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Bairro inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Informar"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} lg={12}>
                                                    <Form.Item
                                                        label="Complemento"
                                                        name={[
                                                            "addr_complement",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.addr_complement
                                                        }
                                                        rules={[
                                                            {
                                                                message:
                                                                    "Complemento inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Informar"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row justify="space-between">
                                                <Col xs={24} sm={24} lg={15}>
                                                    <Form.Item
                                                        label="Cidade"
                                                        name={[
                                                            "addr_city_name",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.addr_city_name
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Cidade inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Informar"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} lg={5}>
                                                    <Form.Item
                                                        label="Estado"
                                                        name={[
                                                            "addr_province",
                                                            index]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Estado inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        {useCep[index] ? (
                                                            <Select
                                                                showSearch
                                                                filterOption={(input, option) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {BrazilStates.map((item) => (
                                                                    <Option key={item.key} value={item.key}>
                                                                        {item.value}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        ) : (
                                                            <Input type="text" />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Title
                                                level={5}
                                                className="subtitle"
                                            >
                                                Dados bancários
                                            </Title>
                                            <Divider />
                                            <Form.Item
                                                label="Banco"
                                                name={["bank_name", index]}
                                                initialValue={
                                                    responsible.bank_name
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Nome do Banco inválido.",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    filterOption={(
                                                        input,
                                                        option
                                                    ) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(
                                                                input.toLowerCase()
                                                            ) >= 0
                                                    }
                                                >
                                                    {BankList.map((item) => (
                                                        <Option
                                                            key={item.key}
                                                            value={item.value}
                                                        >
                                                            {item.key}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Row justify="space-between">
                                                <Col sm={24} lg={12}>
                                                    <Form.Item
                                                        label="Agência"
                                                        name={[
                                                            "agency_number",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.agency_number
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Cidade inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Informar"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col sm={24} lg={12}>
                                                    <Form.Item
                                                        label={
                                                            <>
                                                                Número da conta
                                                                (com dígito)
                                                                <TooltipEfex
                                                                    title="Nosso cadastro precisa dos dados bancários do responsável legal. 
                                                                Não se preocupe, não serão utilizados pela nossa equipe. 
                                                                Os dados bancários da empresa poderão ser informados posteriormente."
                                                                />
                                                            </>
                                                        }
                                                        name={[
                                                            "bank_account_number",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.bank_account_number
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Cidade inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Informar"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </EfexCard>
                                    </Col>
                                    <Col xs={24} sm={24} lg={12}>
                                        <EfexCard>
                                            <Title
                                                level={5}
                                                className="subtitle"
                                            >
                                                Dados autodeclarados
                                            </Title>
                                            <Divider />
                                            <Form.Item
                                                name={[
                                                    "is_public_agent",
                                                    index,
                                                ]}
                                                label={
                                                    <>
                                                        É agente/funcionário(a)
                                                        público?
                                                    </>
                                                }
                                                initialValue={
                                                    responsible.is_public_agent
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Seleção Obrigatória.",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    onSelect={(value) =>
                                                        updateState(
                                                            `${index}publicAgent`,
                                                            value
                                                        )
                                                    }
                                                >
                                                    <Option value={true} id="is_public_agent_yes">
                                                        Sim
                                                    </Option>
                                                    <Option value={false} id="is_public_agent_not">
                                                        Não
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                            {state[`${index}publicAgent`] ? (
                                                <IdentedField color="orange">
                                                    <Form.Item
                                                        label="CNPJ"
                                                        name={[
                                                            "public_agent_cnpj",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.public_agent_cnpj
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Informe o CNPJ",
                                                            },
                                                            () => ({
                                                                validator(
                                                                    _,
                                                                    value
                                                                ) {
                                                                    if (
                                                                        !value ||
                                                                        cnpjValidator.isValid(
                                                                            value
                                                                        )
                                                                    ) {
                                                                        return Promise.resolve();
                                                                    }

                                                                    return Promise.reject(
                                                                        new Error(
                                                                            "CNPJ inválido."
                                                                        )
                                                                    );
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <MaskedInput
                                                            mask={mask.cnpj}
                                                        ></MaskedInput>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Ocupação"
                                                        name={[
                                                            "public_agent_occupation",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.public_agent_occupation
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Valor inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Informe"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                    <Row justify="space-between">
                                                        <Col
                                                            xs={24}
                                                            sm={24}
                                                            lg={12}
                                                        >
                                                            <Form.Item
                                                                label="Data de início"
                                                                name={[
                                                                    "public_agent_start_date",
                                                                    index,
                                                                ]}
                                                                initialValue={toBrazilianDateString(
                                                                    responsible.public_agent_start_date
                                                                )}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            "Data obrigatória",
                                                                    },
                                                                    () => ({
                                                                        validator(
                                                                            _,
                                                                            value
                                                                        ) {
                                                                            const dateOnlyNumbers =
                                                                                value.replace(
                                                                                    /\D/g,
                                                                                    ""
                                                                                );
                                                                            const date =
                                                                                moment(
                                                                                    value,
                                                                                    "DD/MM/YYYY"
                                                                                );
                                                                            if (
                                                                                dateOnlyNumbers.length <
                                                                                8 ||
                                                                                !date.isValid()
                                                                            ) {
                                                                                return Promise.reject(
                                                                                    new Error(
                                                                                        "Data inválida"
                                                                                    )
                                                                                );
                                                                            } else {
                                                                                return Promise.resolve();
                                                                            }
                                                                        },
                                                                    }),
                                                                ]}
                                                            >
                                                                <MaskedInput
                                                                    mask={
                                                                        mask.date
                                                                    }
                                                                ></MaskedInput>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col
                                                            xs={24}
                                                            sm={24}
                                                            lg={12}
                                                        >
                                                            <Form.Item
                                                                label="Data de término"
                                                                initialValue={toBrazilianDateString(
                                                                    responsible.public_agent_end_date
                                                                )}
                                                                name={[
                                                                    "public_agent_end_date",
                                                                    index,
                                                                ]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            "Data obrigatória",
                                                                    },
                                                                    () => ({
                                                                        validator(
                                                                            _,
                                                                            value
                                                                        ) {
                                                                            const dateOnlyNumbers =
                                                                                value.replace(
                                                                                    /\D/g,
                                                                                    ""
                                                                                );
                                                                            const date =
                                                                                moment(
                                                                                    value,
                                                                                    "DD/MM/YYYY"
                                                                                );
                                                                            if (
                                                                                dateOnlyNumbers.length <
                                                                                8 ||
                                                                                !date.isValid()
                                                                            ) {
                                                                                return Promise.reject(
                                                                                    new Error(
                                                                                        "Data inválida"
                                                                                    )
                                                                                );
                                                                            } else {
                                                                                return Promise.resolve();
                                                                            }
                                                                        },
                                                                    }),
                                                                ]}
                                                            >
                                                                <MaskedInput
                                                                    mask={
                                                                        mask.date
                                                                    }
                                                                ></MaskedInput>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </IdentedField>
                                            ) : null}
                                            <Form.Item
                                                name={[
                                                    "has_public_agent_link",
                                                    index,
                                                ]}
                                                initialValue={
                                                    responsible.has_public_agent_link
                                                }
                                                label="É casado, parente ou tem relação de proximidade com pessoa que ocupou cargo, emprego ou função pública no Brasil ou no exterior nos últimos cinco anos?"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Seleção Obrigatória.",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    onSelect={(value) => {
                                                        updateState(
                                                            `${index}publicLink`,
                                                            value
                                                        );
                                                    }}
                                                >
                                                    <Option value={true} id="has_public_agent_link_yes">
                                                        Sim
                                                    </Option>
                                                    <Option value={false} id="has_public_agent_link_not">
                                                        Não
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                            {state[`${index}publicLink`] ? (
                                                <IdentedField color="orange">
                                                    <Form.Item
                                                        label="Nome completo da pessoa"
                                                        name={[
                                                            "public_agent_link_name",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.public_agent_link_name
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Nome inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Informar"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="CPF da pessoa"
                                                        name={[
                                                            "public_agent_link_cpf",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.public_agent_link_cpf
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Informe o CPF",
                                                            },
                                                            () => ({
                                                                validator(
                                                                    _,
                                                                    value
                                                                ) {
                                                                    if (
                                                                        !value ||
                                                                        cpfValidator.isValid(
                                                                            value
                                                                        )
                                                                    ) {
                                                                        return Promise.resolve();
                                                                    }

                                                                    return Promise.reject(
                                                                        new Error(
                                                                            "CPF inválido."
                                                                        )
                                                                    );
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <MaskedInput
                                                            mask={mask.cpf}
                                                        ></MaskedInput>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Ocupação da pessoa"
                                                        name={[
                                                            "public_agent_link_occupation",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.public_agent_link_occupation
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Ocupação inválida.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Informar"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Tipo do relacionamento com a pessoa"
                                                        name={[
                                                            "public_agent_link_type",
                                                            index,
                                                        ]}
                                                        initialValue={
                                                            responsible.public_agent_link_type
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Relacionamento inválido.",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Informar"
                                                            type="text"
                                                        ></Input>
                                                    </Form.Item>
                                                </IdentedField>
                                            ) : null}
                                            <Form.Item
                                                name={["is_us_person", index]}
                                                initialValue={
                                                    responsible.is_us_person
                                                }
                                                label={
                                                    <>
                                                        É US person?{" "}
                                                        <TooltipEfex title="Tem Cidadania norte-americana, incluindo dupla nacionalidade e passaporte norte-americano, ainda que resida fora dos Estados Unidos; tem residência fiscal nos EUA, ou seja, que possui Green Card e pode se tornar um residente permanente legalizado; esteve presente nos Estados Unidos para fins tributários por pelo menos 31 dias neste ano ou 183 dias nos últimos 3 anos." />
                                                    </>
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Seleção Obrigatória.",
                                                    },
                                                ]}
                                            >
                                                <Select>
                                                    <Option value={true} id="is_us_person_yes">
                                                        Sim
                                                    </Option>
                                                    <Option value={false} id="is_us_person_not">
                                                        Não
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name={[
                                                    "has_link_with_bs2",
                                                    index,
                                                ]}
                                                initialValue={
                                                    responsible.has_link_with_bs2
                                                }
                                                label={
                                                    <>
                                                        Possui relação com o
                                                        Banco BS2?{" "}
                                                        <TooltipEfex title="É colaborador direto/indireto do Banco BS2" />
                                                    </>
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Seleção Obrigatória.",
                                                    },
                                                ]}
                                            >
                                                <Select>
                                                    <Option value={true}>
                                                        Sim
                                                    </Option>
                                                    <Option value={false}>
                                                        Não
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </EfexCard>
                                    </Col>
                                </Row>
                                <br />
                            </div>
                        );
                    }
                )}
                <Row justify="space-between">
                    <WhiteButton
                        hoverColor="orange"
                        onClick={() => {
                            props.history.push(Routes.selectPartners);
                        }}
                    >
                        Voltar
                    </WhiteButton>
                    <Form.Item>
                        <OrangeButton
                            type="primary"
                            htmlType="submit"
                            loading={
                                completeQsaInfo.status === StateStatus.loading
                            }
                        >
                            {completeQsaInfo.status === StateStatus.loading
                                ? "Carregando"
                                : "Avançar"}
                        </OrangeButton>
                    </Form.Item>
                </Row>
            </Form>
        </RegisterBaseLayout>
    );
};

export default CompleteQsaInfo;