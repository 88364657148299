import React, { useEffect, useState } from "react";
import { cpf as cpfValidator } from "cpf-cnpj-validator";
import { useDispatch, useSelector } from "react-redux";
import MaskedInput from "antd-mask-input";

import { Form, Typography, Row, Col, message, Divider, Select } from "antd";
import { SyncOutlined, UserOutlined } from "@ant-design/icons";
import {
    listPeople,
    selectPerson,
    postAddPerson,
    getPerson,
    resetAddPerson,
    listPeopleCompleted,
    listLastTransactions,
    listStatusTransactions,
    resetDuplicateState,
    duplicateTransaction,
    deletePersonThunk,
} from "./slice";
import PersonCard from "./components/PersonCard";
import StateStatus from "../../utils/status";
import { PersonCheckStep } from "../../utils/functions";
import { GreenButton, WhiteButton } from "../../common/components/Button";
import { EfexCard } from "../../common/components/Card";
import EfexModal from "../../common/components/Modal";
import ErrorMsg from "../../common/components/ErrorMsg";
import EfexInput from "../../common/components/Input";
import {
    TransactionCard,
    TransactionStatusCard,
} from "../../common/components/TransactionCard";

import "./styles.scss";
import { handleResize } from "../../utils/responsible";
import { mask, sendValueAndReceiveValueStatus } from "../../utils/consts";
import InfiniteScrollList from "../../common/components/InfiniteScrollList";
import BlankPerson from "./components/BlankPerson";

const { Title } = Typography;

const Person = ({ history }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const person = useSelector(selectPerson);
    const [showPersonModal, updateShowPersonModal] = useState(false);
    const [isResponsible, updateResponsible] = useState(false);
    const [deleteModal, setDeleteModal] = useState({
        visible: false,
        personName: null,
        personId: null,
    });
    const [filteredStatus, setFilteredStatus] = useState(null);
    const [filteredOrder, setFilteredOrder] = useState("DESC");
    const [statusTransactions, setStatusTransactions] = useState([]);
    const [page, setPage] = useState(1);
    const [isEmpty, setIsEmpty] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [errorMessage, setErrorMessage] = useState("")

    const getMoreStatusTransactions = () => {
        dispatch(
            listStatusTransactions({
                page: page + 1,
                status: filteredStatus,
                order: filteredOrder,
            })
        ).then((resp) => {
            if (resp.payload) {
                if (resp.payload.pagination.total_pages <= resp.payload.pagination.current_page) {
                    setHasMore(false)
                } else {
                    setPage(resp.payload.pagination.current_page)
                    setStatusTransactions((old) => [...old, ...resp.payload.lst]);
                }
            }
        });
    };

    useEffect(() => {
        setHasMore(true)
        dispatch(listStatusTransactions({})).then(resp => {
            if (resp.payload) {
                resp.payload.pagination.total_pages <= resp.payload.pagination.current_page && setHasMore(false)
                setStatusTransactions(resp.payload.lst)
            }
        });
        dispatch(listLastTransactions({}));
        window.addEventListener("resize", () =>
            handleResize(updateResponsible)
        );
        handleResize(updateResponsible);
    }, []);

    useEffect(() => {
        if (person.status.listPeople === StateStatus.idle) {
            dispatch(listPeople());
        }
        if (person.status.listPeopleCompleted === StateStatus.idle) {
            dispatch(listPeopleCompleted());
        }
    });

    useEffect(() => {
        if (person.status.addPerson === StateStatus.succeeded) {
            history.push(`/dashboard/pessoas/dados-cadastrais`);
            dispatch(resetAddPerson());
        }
    }, [person.status.addPerson]);

    useEffect(() => {
        if (person.status.duplicateTransaction === StateStatus.loading) {
            message.loading({ key: 1, content: "Duplicando transação..." });
        }

        if (person.status.duplicateTransaction === StateStatus.succeeded) {
            dispatch(resetDuplicateState());
            const { id, type, owner_id } = person.duplicatedTransaction;
            const route = `/dashboard/pessoas/${owner_id}/cambio`;

            if (type === "send_value") {
                history.push(`${route}/enviar-valores/${id}`);
            } else {
                history.push(`${route}/operacoes-abertas/${id}`);
            }
        }

        if (person.status.duplicateTransaction === StateStatus.failed) {
            message.error({ key: 1, content: "Erro ao duplicar transação." });
        }
    }, [person.status.duplicateTransaction]);

    useEffect(() => {
        if (person.status.getPerson === StateStatus.succeeded) {
            const status =
                person.person.register_steps[
                    person.person.register_steps.length - 1
                ].key;
            message.destroy(1);
            if (status === "waiting_sign_documents") {
                message.info({
                    key: 1,
                    content:
                        "Para concluir esta etapa, pedimos que verifique seu email e assine os documentos necessários para concluirmos o cadastro.",
                });
            }
        }
    }, [person.status.getPerson]);

    const onFinish = (values) => {
        values.cpf = cpfValidator.strip(values.cpf);
        dispatch(postAddPerson(values)).then((resp) => {
            resp.error && setErrorMessage(resp.error.name)
        });
    };

    useEffect(() => {
        if (person.status.deletePerson === StateStatus.succeeded) {
            message.success({
                key: 1,
                content: "Pessoa excluída com sucesso!",
            });
            dispatch(listPeople());
        }
    }, [person.status.deletePerson]);

    const onDeletePerson = (personId) => {
        dispatch(deletePersonThunk(personId));
        setDeleteModal({ visible: false });
    };

    return (
        <>
            <EfexModal
                title="Você deseja excluir esta pessoa?"
                onOk={() => onDeletePerson(deleteModal.personId)}
                visible={deleteModal.visible}
                onCancel={() => setDeleteModal({ visible: false })}
            >
                Você deseja excluir {deleteModal.personName}?
                <br />
                ATENÇÃO: Esta operação <span style={{ color: "red" }}>
                    NÃO
                </span>{" "}
                poderá ser desfeita.
            </EfexModal>

            <Row
                gutter={[16, 16]}
                style={{ "margin-left": 0, "margin-right": 0 }}
                justify="center"
                className="people people-flow-default"
            >
                {isResponsible ? (
                    <GreenButton
                        size="small"
                        type="primary"
                        style={{ width: "140px", fontWeight: "500" }}
                        onClick={() => updateShowPersonModal(true)}
                    >
                        Nova pessoa
                    </GreenButton>
                ) : null}
                <Col xs={24} sm={24} lg={12} style={{ width: "100%" }}>
                    <EfexCard className="person-list">
                        <div className="people-in-register-header">
                            <Title level={4}>Pessoas em cadastro</Title>
                            {!isResponsible ? (
                                <GreenButton
                                    size="small"
                                    type="primary"
                                    style={{
                                        width: "140px",
                                        fontWeight: "500",
                                    }}
                                    onClick={() => updateShowPersonModal(true)}
                                >
                                    Nova pessoa
                                </GreenButton>
                            ) : null}
                        </div>
                        {person.status.listPeople === StateStatus.loading ? (
                            <Row justify="center">
                                <SyncOutlined spin className="loading-icon" />
                            </Row>
                        ) : (
                            <>
                                {person.people.length > 0 ? (
                                    person.people.map((per, index) => {
                                        return (
                                            <Row key={per.cpf}>
                                                <PersonCard
                                                    isResponsible={
                                                        isResponsible
                                                    }
                                                    isOdd={index % 2 === 0}
                                                    inCreating={true}
                                                    loading={
                                                        person.status
                                                            .addPerson ===
                                                        StateStatus.loading
                                                    }
                                                    index={index}
                                                    person={per}
                                                    onDelete={() => {
                                                        setDeleteModal({
                                                            visible: true,
                                                            personName:
                                                                per.name,
                                                            personId: per.id,
                                                        });
                                                    }}
                                                    onClick={() => {
                                                        message.loading({
                                                            key: 1,
                                                            content:
                                                                "Carregando...",
                                                        });
                                                        dispatch(
                                                            getPerson(per.id)
                                                        ).then(
                                                            ({ payload }) => {
                                                                if (
                                                                    payload &&
                                                                    payload.id
                                                                ) {
                                                                    PersonCheckStep(
                                                                        payload
                                                                            .register_steps[
                                                                            payload
                                                                                .register_steps
                                                                                .length -
                                                                            1
                                                                        ].key,
                                                                        history,
                                                                        payload.id,
                                                                        payload?.register_update
                                                                    );
                                                                }
                                                            }
                                                        );
                                                    }}
                                                />
                                            </Row>
                                        );
                                    })
                                ) : (
                                    <BlankPerson />
                                )}
                            </>
                        )}
                    </EfexCard>

                    <EfexCard className="person-list">
                        <div className="people-in-register-header">
                            <Title level={4}>Pessoas cadastradas</Title>
                        </div>
                        {person.status.listPeopleCompleted ===
                            StateStatus.loading ? (
                            <Row justify="center">
                                <SyncOutlined spin className="loading-icon" />
                            </Row>
                        ) : (
                            <>
                                {person.peopleCompleted.length > 0 ? (
                                    person.peopleCompleted.map((per, index) => {
                                        return (
                                            <Row key={index}>
                                                <PersonCard
                                                    isResponsible={
                                                        isResponsible
                                                    }
                                                    isOdd={index % 2 === 0}
                                                    loading={
                                                        person.status
                                                            .addPerson ===
                                                        StateStatus.loading
                                                    }
                                                    person={per}
                                                    onClick={() => {
                                                        message.loading({
                                                            key: 1,
                                                            content:
                                                                "Carregando...",
                                                        });
                                                        dispatch(
                                                            getPerson(per.id)
                                                        ).then(
                                                            ({ payload }) => {
                                                                if (
                                                                    payload &&
                                                                    payload.id
                                                                ) {
                                                                    PersonCheckStep(
                                                                        payload
                                                                            .register_steps[
                                                                            payload
                                                                                .register_steps
                                                                                .length -
                                                                            1
                                                                        ].key,
                                                                        history,
                                                                        payload.id
                                                                    );
                                                                }
                                                            }
                                                        );
                                                    }}
                                                />
                                            </Row>
                                        );
                                    })
                                ) : (
                                    <BlankPerson />
                                )}
                            </>
                        )}
                    </EfexCard>
                </Col>
                <Col xs={24} sm={24} lg={12} style={{ width: "100%" }}>
                    <Row gutter={[16, 16]}>
                        <Col sm={24} style={{ width: "100%" }}>
                            <EfexCard className="card-transactions">
                                <div>
                                    <Row
                                        justify="space-between"
                                        className="transaction-status-header"
                                    >
                                        <Col xs={24} sm={8} md={8} lg={8}>
                                            <Title level={4}>
                                                Status das transações
                                            </Title>
                                        </Col>
                                        <Col
                                            xs={24}
                                            sm={16}
                                            md={16}
                                            lg={16}
                                            className="selects-col"
                                        >
                                            <Row
                                                className="filter-selects"
                                                style={{
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <Col>
                                                    <Select
                                                        defaultValue="Todos status"
                                                        placeholder="ordenar"
                                                        className="transactions-select"
                                                        dropdownStyle={{
                                                            minWidth: "250px",
                                                        }}
                                                        placement="bottomRight"
                                                        size="small"
                                                        onChange={(value) => {
                                                            setHasMore(true)
                                                            setPage(1);
                                                            setStatusTransactions(
                                                                []
                                                            );
                                                            setFilteredStatus(
                                                                value
                                                            );
                                                            setIsEmpty(false);
                                                            dispatch(
                                                                listStatusTransactions(
                                                                    {
                                                                        status: value,
                                                                        order: filteredOrder,
                                                                    }
                                                                )
                                                            ).then((resp) => {
                                                                if (
                                                                    resp.payload
                                                                ) {
                                                                    setStatusTransactions(
                                                                        resp
                                                                            .payload
                                                                            .lst
                                                                    );
                                                                    if (
                                                                        resp
                                                                            .payload
                                                                            .lst
                                                                            .length ===
                                                                        0
                                                                    ) {
                                                                        setIsEmpty(
                                                                            true
                                                                        );
                                                                    }
                                                                }
                                                            });
                                                        }}
                                                        options={
                                                            sendValueAndReceiveValueStatus
                                                        }
                                                    />
                                                </Col>
                                                <Col>
                                                    <Select
                                                        defaultValue="DESC"
                                                        placeholder="ordenar"
                                                        className="transactions-select"
                                                        size="small"
                                                        onChange={(value) => {
                                                            setHasMore(true)
                                                            setPage(1)
                                                            setStatusTransactions([])
                                                            setFilteredOrder(value)
                                                            setIsEmpty(false)
                                                            dispatch(listStatusTransactions({ order: value, status: filteredStatus }))
                                                                .then(resp => {
                                                                    if (resp.payload) {
                                                                        resp.payload.pagination.total_pages <= resp.payload.pagination.current_page && setHasMore(false)
                                                                        setStatusTransactions(resp.payload.lst)

                                                                        if (resp.payload.lst.length === 0) {
                                                                            setIsEmpty(true)
                                                                        }
                                                                    }
                                                                });
                                                        }}
                                                    >
                                                        <Select.Option value="DESC">
                                                            Recentes
                                                        </Select.Option>
                                                        <Select.Option value="ASC">
                                                            Antigas
                                                        </Select.Option>
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Divider></Divider>
                                    </Row>
                                </div>

                                {!isEmpty ? (
                                    <InfiniteScrollList
                                        list={statusTransactions}
                                        nextFn={getMoreStatusTransactions}
                                        isStatus={true}
                                        isResponsible={isResponsible}
                                        hasMore={hasMore}
                                        endMessage={<h4 style={{ textAlign: "center" }}> Fim</h4>}
                                    />
                                ) : isEmpty && !filteredStatus ? (
                                    <p
                                        style={{
                                            textAlign: "center",
                                            color: "#ccc",
                                        }}
                                    >
                                        Você ainda não possui nenhuma transação
                                    </p>
                                ) : (
                                    <h4 style={{ textAlign: "center" }}>Não encontrado</h4>
                                )}
                            </EfexCard>
                        </Col>
                        <Col sm={24} style={{ width: "100%" }}>
                            <EfexCard className="card-transactions">
                                <div>
                                    <Row justify="space-between">
                                        <Title level={4}>
                                            Transações concluídas
                                        </Title>
                                        <Select
                                            defaultValue="DESC"
                                            placeholder="ordenar"
                                            className="transactions-select"
                                            style={{ width: "160px" }}
                                            onChange={(value) => {
                                                dispatch(
                                                    listLastTransactions({
                                                        order: value,
                                                    })
                                                );
                                            }}
                                        >
                                            <Select.Option value="DESC">
                                                Recentes
                                            </Select.Option>
                                            <Select.Option value="ASC">
                                                Antigas
                                            </Select.Option>
                                        </Select>
                                    </Row>
                                    <Divider></Divider>
                                </div>
                                {person.status.listLastTransactions ===
                                    StateStatus.loading ? (
                                    <Row
                                        justify="center"
                                        className="row-loading"
                                    >
                                        <SyncOutlined spin />
                                    </Row>
                                ) : !person.lastTransactions.length === 0 ? (
                                    <Row>
                                        {person.lastTransactions.map(
                                            (item, index) => {
                                                return (
                                                    <TransactionCard
                                                        index={index}
                                                        isResponsible={
                                                            isResponsible
                                                        }
                                                        data={{
                                                            id: item.id,
                                                            created_at:
                                                                item.created_at,
                                                            owner: item.owner,
                                                            type: item.type,
                                                            value: item.value,
                                                            currency:
                                                                item.currency,
                                                            status: item.status,
                                                            status_display:
                                                                item.status_display,
                                                            status_updated_at:
                                                                item.status_updated_at,
                                                        }}
                                                        onClickDuplicate={() => {
                                                            dispatch(
                                                                duplicateTransaction(
                                                                    {
                                                                        id: item.id,
                                                                        type: item.type,
                                                                        owner_id:
                                                                            item
                                                                                .owner
                                                                                .id,
                                                                        cpf: item
                                                                            .owner
                                                                            .cpf_cnpj,
                                                                    }
                                                                )
                                                            );
                                                        }}
                                                    />
                                                );
                                            }
                                        )}
                                    </Row>
                                ) : (
                                    <p
                                        style={{
                                            textAlign: "center",
                                            color: "#ccc",
                                        }}
                                    >
                                        Você ainda não concluiu nenhuma
                                        transação
                                    </p>
                                )}
                            </EfexCard>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <EfexModal
                className={"modal-person"}
                error={person.errorMsg}
                visible={showPersonModal}
                // form={form}
                confirmLoading={person.status.addPerson === StateStatus.loading}
                onCancel={() => {
                    updateShowPersonModal(false);
                    form.resetFields();
                }}
                title="Adicionar nova pessoa"
                footer={
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                        }}
                    >
                        <WhiteButton
                            onClick={() => {
                                updateShowPersonModal(false);
                                form.resetFields();
                            }}
                        >
                            Cancelar
                        </WhiteButton>
                        <div style={{ width: "10px" }}></div>
                        <GreenButton
                            loading={
                                person.status.addPerson === StateStatus.loading
                            }
                            onClick={form.submit}
                        >
                            OK
                        </GreenButton>
                    </div>
                }
            >
                <div
                    style={{
                        fontSize: "14px",
                        lineHeight: "25px",
                        marginBottom: "20px",
                    }}
                >
                    <p>
                        Atenção! Você precisará ter em mãos alguns documentos e
                        informações da pessoa.
                    </p>
                    <p>
                        Para iniciar, insira abaixo o <strong>CPF</strong> e o{" "}
                        <strong>nome completo</strong>.
                    </p>
                </div>
                {person.status.addPerson === StateStatus.failed ? (
                    <ErrorMsg message={errorMessage ? errorMessage : person.errorMsg} />
                ) : null}

                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <Form.Item
                        name={"cpf"}
                        rules={[
                            {
                                required: true,
                                message: "Informe o CPF",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value || cpfValidator.isValid(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error("CPF inválido.")
                                    );
                                },
                            }),
                        ]}
                    >
                        <MaskedInput
                            placeholder="CPF"
                            prefix={
                                <UserOutlined className="site-form-item-icon" />
                            }
                            mask={mask.cpf}
                        ></MaskedInput>
                    </Form.Item>
                    <Form.Item
                        name={"name"}
                        rules={[
                            {
                                required: true,
                                message: "Informe o nome completo",
                            },
                        ]}
                    >
                        <EfexInput placeholder="Nome completo" />
                    </Form.Item>
                </Form>
            </EfexModal>
        </>
    );
};

export default Person;
