import React from 'react'
import './styles.scss'

const IdentedField = ({children, color}) => {
    return (
        <div className={color === "orange" ? "idented-box-orange" : "idented-box-green"}>
            {children}
        </div>
    )
}

export default IdentedField