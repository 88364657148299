import api from "../../service/api";
import apiRoutes from "../../service/apiRoutes";
import qs from "qs";

const paramsSerializer = params => { return qs.stringify(params) } // fix array query param

export const addCompany = (data) => api.post(apiRoutes.company, data)

export const getCompanies = (params) => api.get(apiRoutes.company, { params })

export const patchCompany = (data) => api.patch(apiRoutes.company, data)

export const getCompany_ = (params) => api.get(`${apiRoutes.company}profile`, { params })

export const getTransactions = (params) => api.get(apiRoutes.transactions, { params, paramsSerializer })

export const sendValueDuplicate = async (data) => api.post(`${apiRoutes.sendValue}duplicate`, data)

export const receiveValueDuplicate = async (data) => api.post(`${apiRoutes.receiveValue}/duplicate`, data)

export const deleteCompany = async (company_id) => api.delete(apiRoutes.company, { params: { company_id } })