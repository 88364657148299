
import { TransactionCard, TransactionStatusCard } from "../TransactionCard";
import { duplicateTransaction } from "../../../features/ChooseCompany/ChooseCompanySlice";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { SyncOutlined } from "@ant-design/icons";

const InfiniteScrollList = ({
    list,
    nextFn,
    isStatus,
    isLast,
    isResponsible,
    hasMore,
    endMessage
}) => {
    const dispatch = useDispatch();

    return (
        <div id="divInifinite" style={{ maxHeight: 350, overflow: "auto" }}>
            <InfiniteScroll
                dataLength={list.length}
                next={nextFn}
                hasMore={hasMore}
                loader={<h4 style={{ textAlign: "center" }}><SyncOutlined spin /></h4>}
                scrollableTarget="divInifinite"
                scrollThreshold={1.0}
                endMessage={endMessage}
            >
                {list.map((item, index) => {
                    if (isStatus) {
                        return (
                            <TransactionStatusCard
                                key={index}
                                isResponsible={isResponsible}
                                data={{
                                    id: item.id,
                                    created_at: item.created_at,
                                    owner: item.owner,
                                    type: item.type,
                                    value: item.value,
                                    currency: item.currency,
                                    status: item.status,
                                    status_display: item.status_display,
                                    status_updated_at: item.status_updated_at,
                                }}
                            />
                        );
                    }

                    if (isLast) {
                        return (
                            <TransactionCard
                                index={index}
                                isResponsible={isResponsible}
                                data={{
                                    id: item.id,
                                    created_at: item.created_at,
                                    owner: item.owner,
                                    type: item.type,
                                    value: item.value,
                                    currency: item.currency,
                                    status: item.status,
                                    status_display: item.status_display,
                                    status_updated_at: item.status_updated_at,
                                }}
                                onClickDuplicate={() => {
                                    dispatch(
                                        duplicateTransaction({
                                            id: item.id,
                                            type: item.type,
                                            cpf_cnpj: item.owner.cpf_cnpj,
                                            owner_type: item.owner.type,
                                            owner_id: item.owner.id,
                                        })
                                    );
                                }}
                            />
                        );
                    }
                })}
            </InfiniteScroll>
        </div>
    );
};

export default InfiniteScrollList;
