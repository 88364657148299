import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StateStatus from "../../utils/status";
import { addQsaInfo } from "./CompleteQsaInfoService";
import { findCpf } from "./CompleteQsaInfoService";

const initialState = {
    status: StateStatus.idle,
    errorMsg: null,
    errorFields: null,
    allQsa: null,
}

export const postQsaInfo = createAsyncThunk('qsaInfo/postQsaInfo', async (data, { rejectWithValue }) => {
    try {
        const cnpj = JSON.parse(localStorage.getItem('company')).cnpj;
        const response = await addQsaInfo(cnpj, data)
        return response.data
    } catch (err) {
        throw rejectWithValue(err);
    }
})

export const getFindCpf = createAsyncThunk(
    "firstStep/getFindCpf",
    async (data) => {
        const response = await findCpf(data);
        return response.data;
    }
);


export const qsaInfoSlice = createSlice({
    name: 'qsaInfo',
    initialState,
    reducers: {
        resetCompleteStatus: (state) => {
            state.status = StateStatus.idle;
        }
    },
    extraReducers: {
        [postQsaInfo.pending]: (state) => {
            state.status = StateStatus.loading
        },
        [postQsaInfo.fulfilled]: (state) => {
            state.status = StateStatus.succeeded
        },
        [postQsaInfo.rejected]: (state, action) => {
            state.status = StateStatus.failed
            state.errorMsg = action.payload.message
            state.errorFields = action.payload.data
        }
    }
})

export const { resetCompleteStatus } = qsaInfoSlice.actions;

export const selectCompleteQsaInfo = (state) => state.qsaInfo;