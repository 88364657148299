import React, { useState, useEffect } from "react";
import PriceReceiveValue from "../../../common/components/Price";
import { useDispatch, useSelector } from "react-redux";
import { selectExchange } from "../slice";
import {
    Typography,
    Form,
    Select,
    Row,
    Col,
    InputNumber,
    Upload,
    Space,
    notification,
    message,
    Divider,
    Card,
    Timeline,
    Input,
    Checkbox,
    Tabs,
} from "antd";
import { formatCurrency } from "../../../utils/functions";
import ReceiveTab from "./receive-tab";
import SendTab from "./send-tab";

const { TabPane } = Tabs;

const Simulation = () => {
    const [form] = Form.useForm();
    const [taxes, setTaxes] = useState([
        {
            name: "IOF",
            tax: 110,
            description: "Imposto",
        },
    ]);
    const [isCurrencyTarget, setIsCurrencyTarget] = useState(true);
    const [currencySelected, setCurrencySelected] = useState(null);
    const [currency, setCurrency] = useState("");
    const exchange = useSelector(selectExchange);
    const dispatch = useDispatch();
    const [isClosed, setIsClosed] = useState(false);
    const [commercialHour, setCommercialHour] = useState(false);
    const [tabSelected, setTabSelected] = useState("1");

    const getCorrectValueToCalculate = () => {
        if (isCurrencyTarget) {
            return form.getFieldValue("target_value") ?? 0;
        } else {
            return form.getFieldValue("value") ?? 0;
        }
    };

    const canEditThisFieldByStatus = (state) =>
        !state.receivedValue ||
        ["draft", "need_changes", "created_by_bo"].includes(
            state.receivedValue.status
        );

    return (
        <Card style={{ padding: "20px" }}>
            <Tabs
                defaultActiveKey="1"
                onChange={(value) => {
                    setTabSelected(value)
                }}
            >
                <TabPane tab="Enviar" key="1">
                    <SendTab freezeCurrency={tabSelected !== "1"} getRates={tabSelected === "1"}/>
                </TabPane>
                <TabPane tab="Receber" key="2">
                    <ReceiveTab isClosed={tabSelected !== "2"} getRates={tabSelected === "2"}/>
                </TabPane>
            </Tabs>
        </Card>
    )
}

export default Simulation;