const apiRoutes = {
    register: '/client/v1/register/',
    listQsa: '/client/v1/company_profile/qsa/',
    refreshToken: '/auth/refesh/',
    companyProfile: '/client/v1/company_profile',
    findCpf: '/client/v1/company_profile/find_person/',
    companyExtraData: '/client/v1/company_profile/extra/',
    financialResponsibles: '/client/v1/company_profile/financial_responsibles/',
    documents: '/client/v1/company_profile/documents/',
    qsaDocuments: '/client/v1/company_profile/financial_responsibles/documents/',
    login: '/client/v1/login/',
    company: '/client/v1/company/',
    acceptReview: '/client/v1/company_profile/step/accept_review/',
    forgotPassword: '/client/v1/register/forgot-password',
    person: '/client/v1/person/',
    personProfile: '/client/v1/person_profile/',
    profile: '/client/v1/profile/',
    contactSend: '/client/v1/exchange/contact_send',
    contactSendBankAccount: '/client/v1/exchange/bank_account_send',
    bankAccountReceive: '/client/v1/exchange/bank_account_receive',
    receiveValue: '/client/v1/exchange/receive_value',
    sendValue: '/client/v1/exchange/send_value/',
    sendValues: '/client/v1/exchange/send_values',
    rates: '/client/v1/exchange/rates',
    files: '/client/v1/exchange/files',
    downloadClickSign: '/client/v1/exchange/files_signed/download',
    transactions: '/client/v1/dashboard/transactions',
    confimEmail: '/client/v1/register/confirm-email',
    clientProfile: '/client/v1/register/update',
    isOpen: '/client/v1/exchange/is_open',
    CanCloseToday: '/client/v1/exchange/can_close_today',
    notification: '/client/v1/exchange/notification',
    dashboard: "client/v1/dashboard",
    notifications: '/client/v1/notification'
}
export default apiRoutes