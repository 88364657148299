import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import "./register_steps.scss";

const { Step } = Steps;

const RegisterSteps = (props) => {
    const [isFixed, setisFixed] = useState(false);
    const [items, setItems] = useState([...props.items]);

    useEffect(() => {
        const newItems = props?.items.map(item => {
            const newItem = { ...item }
            if (props?.steps) {
                props?.steps.forEach(step => {
                    if (newItem.key === step.key) {
                        newItem.isClickable = true;
                    }
                });
            }
            if (isStepDisabled(newItem)) {
                newItem.isClickable = false;
            }
            return newItem;
        });

        if (JSON.stringify(newItems) !== JSON.stringify(items)) {
            setItems(newItems);
        }
    }, [props?.items, props?.steps, items]);


    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 75) {
                if (!isFixed) {
                    setisFixed(true);
                }
            } else {
                if (isFixed) {
                    setisFixed(false);
                }
            }
        });
    }, [isFixed]);

    const isStepDisabled = (el) => {
        let update_docs = false
        if (props.register_update &&
            (props.register_update?.company_incorporation_document ||
                props.register_update?.board_election_minutes ||
                props.register_update?.representative_proof_of_address ||
                props.register_update?.representative_identity_document ||
                props.register_update?.financial_documentation)) {
            update_docs = true
        }

        if (el.title === "Dados da empresa" && props.register_update && !props.register_update?.registration_form) {
            return true;
        } else if (el.title === "KYC" && props.register_update && !props.register_update?.kyc) {
            return true
        } else if ((el.title === "Responsáveis" ||
            el.title === "Dados dos responsáveis" ||
            el.title === "Confirmar dados")
            && props.register_update &&
            !props.register_update?.representative_form) {
            return true
        } else if ((el.title === "Upload de documentos") && props.register_update && !update_docs) {
            return true
        }
        return false
    };


    return (
        <>
            <div
                className={`steps-card ${isFixed ? "sticky" : ""} ${props.className
                    }`}
            >
                <Steps current={props.current} direction="vertical">
                    {items.map((el) => {
                        return (
                            <Step
                                status={el.isClickable && "finish"}
                                key={el.title}
                                title={el.title}
                                className={el.isClickable ? "clickable" : null}
                                onClick={() => (el.isClickable && !isStepDisabled(el)) && props.history.push(el.route)}
                                disabled={!el.isClickable}
                            />
                        );
                    })}
                </Steps>
            </div>

            <div className={`steps-card-mobile ${props.className}`}>
                <Steps
                    current={props.current}
                    direction="horizontal"
                    responsive={false}
                >
                    {props.items.map((el) => (
                        <Step key={el.title} />
                    ))}
                </Steps>
            </div>
        </>
    );
};

export default RegisterSteps;
