import React, { useState } from "react";

import { Row, Col, Typography } from "antd";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

import { history } from "../../helpers/history";

import { ArrowUpOutlined, LaptopOutlined, UserOutlined } from "@ant-design/icons";

import Contacts from "./Contacts";
import ProfileCompany from "./Company";
import ProfilePerson from "./Person";
import { getExchangeOwner, resetSendValue, selectExchange } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import { beautifyCpfCnpj, ellipsis } from "../../utils/beautify";
import { useEffect } from "react";
import StateStatus from "../../utils/status";
import SendValueApproved from "./SendValue/Approved";
import SendValueMultApproved from "./SendValue/MultApproved";
import ReceiveValue from "./ReceiveValue";
import TransactionProof from "./Payments/PaymentList/TransactionProof";
import OngoingOperations from "./OngoingOperations";
import SendValue from "./SendValue";
import CompletedOperations from "./CompletedOperations";
import SideMenuExchange from "./_components/SideMenuExchange";
import "./styles.scss";
import ProfileIcon from "../../common/components/icons/ProfileIcon";
import { CalendarIcon } from "../../common/components/icons/CalendarIcon";
import { OprationsIcon } from "../../common/components/icons/OperationsIcon";
import CompanyIcon from "../../common/components/icons/CompanyIcon";
import { handleResize } from "../../utils/responsible";
import { useForm } from "antd/lib/form/Form";
import Simulation from "./Simulation";
import MultApproved from "./SendValue/MultApproved";

const { Title, Text } = Typography;

const Exchange = () => {
    let { path, url } = useRouteMatch();
    const { id } = useParams();
    const exchange = useSelector(selectExchange);
    const dispatch = useDispatch();
    const [isResponsible, updateResponsible] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", () =>
            handleResize(updateResponsible)
        );
        handleResize(updateResponsible);
    }, []);

    const items = [
        {
            title: path.match("empresas") ? "Empresa" : "Pessoa",
            icon: path.match("empresas") ? <CompanyIcon /> : <ProfileIcon />,
            active_path: "/perfil",
            onClick: () => history.push(`${url}/perfil`),
        },
        {
            title: "Contatos",
            icon: <CalendarIcon />,
            active_path: "/contatos",
            onClick: () => history.push(`${url}/contatos`),
        },
        {
            title: "Enviar",
            icon: <ArrowUpOutlined rotate={45} />,
            active_path: "/enviar-valores",
            onClick: () => {
                dispatch(resetSendValue(null));
                history.push(`${url}/enviar-valores`);
            },
        },
        {
            title: "Receber",
            id: "receive_icon",
            icon: <ArrowUpOutlined rotate={-135} />,
            active_path: "/receber-valores",
            onClick: () => {
                dispatch(resetSendValue(null));
                history.push(`${url}/receber-valores`);
            },
        },
        {
            title: "Operações",
            icon: <OprationsIcon />,
            active_path: "/operacoes-abertas",
            onClick: () => history.push(`${url}/operacoes-abertas`),
        },
        {
            title: "Simulação",
            icon: <LaptopOutlined />,
            active_path: "/simulacao",
            onClick: () => history.push(`${url}/simulacao`),
        },
    ];

    useEffect(() => {
        dispatch(
            getExchangeOwner({
                isPerson: path.match("pessoas"),
                id: id,
            })
        );
    }, []);

    return (
        <>
            <Row
                style={{ height: "100%" }}
                className={`exchange ${path.match("empresas") ? "exchange-yellow" : ""
                    }`}
                wrap={true}
            >
                <Col xs={24} sm={6} md={4} lg={3} className="exchange-sidemenu">
                    <SideMenuExchange
                        items={items}
                        isResponsible={isResponsible}
                    />
                </Col>
                {exchange.status.getOwner === StateStatus.succeeded && (
                    <Col
                        xs={24}
                        sm={18}
                        md={20}
                        lg={21}
                        className="exchange-content"
                    >
                        <Row gutter={[16, 0]} style={{ margin: "16px 0" }}>
                            <Col style={{ paddingRight: "15px" }}>
                                <div className="icon-type">
                                    {exchange.owner.cpf ? (
                                        <UserOutlined />
                                    ) : (
                                        <CompanyIcon />
                                    )}
                                </div>
                            </Col>
                            {exchange.owner.cpf ? (
                                <Col>
                                    <Title level={4}>
                                        {isResponsible
                                            ? ellipsis(exchange.owner.name, 24)
                                            : exchange.owner.name}
                                    </Title>
                                    <Text type="secondary">
                                        {" "}
                                        {beautifyCpfCnpj(exchange.owner.cpf)}
                                    </Text>
                                </Col>
                            ) : (
                                <Col>
                                    <Title level={4}>
                                        {isResponsible
                                            ? ellipsis(
                                                exchange.owner.fantasy_name ||
                                                exchange.owner
                                                    .company_name,
                                                24
                                            )
                                            : exchange.owner.fantasy_name ||
                                            exchange.owner.company_name}
                                    </Title>
                                    <Text type="secondary">
                                        {beautifyCpfCnpj(exchange.owner.cnpj)}
                                    </Text>
                                </Col>
                            )}
                        </Row>
                        <Switch>
                            <Route path={`${path}/contatos`}>
                                <Contacts />
                            </Route>

                            <Route path={`${path}/operacoes-concluidas`}>
                                <CompletedOperations />
                            </Route>
                            <Route
                                path={[
                                    `${path}/receber-valores/:receiveid?`,
                                    `${path}/operacoes-abertas/:receiveid`,
                                ]}
                            >
                                <ReceiveValue />
                            </Route>
                            {/** path para tela de criar ou editar Envio de Valor  */}
                            <Route
                                path={[`${path}/enviar-valores/:sendValueId?`]}
                            >
                                <SendValue />
                            </Route>
                            {/** path para tela de visualizar ou dar segmento ao Envio de Valor  */}
                            <Route
                                path={[`${path}/operacao-envio/operacoes-aprovadas`]}
                            >
                                <SendValueMultApproved />
                            </Route>
                            {/** nao ta sendo usado  */}
                            <Route
                                path={`${path}/operacao-envio/:sendValueId/comprovantes`}
                            >
                                <TransactionProof />
                            </Route>
                            {/** path para tela de visualizar ou dar segmento ao Envio de Valor  */}
                            <Route
                                path={[`${path}/operacao-envio/:sendValueId`]}
                            >
                                <SendValueApproved />
                            </Route>
                            {/** path para tela de listar Envio ou Recebimento de Valor em andamento  */}
                            <Route path={`${path}/operacoes-abertas`}>
                                <OngoingOperations />
                            </Route>
                            <Route path={`${path}/simulacao`}>
                                <Simulation />
                            </Route>
                            <Route
                                path={[
                                    `/dashboard/pessoas/:id/cambio/perfil`,
                                    "/dashboard/pessoas/:id/cambio",
                                ]}
                            >
                                <ProfilePerson />
                            </Route>
                            <Route
                                path={[
                                    `/dashboard/empresas/:id/cambio/perfil`,
                                    "/dashboard/empresas/:id/cambio",
                                ]}
                            >
                                <ProfileCompany />
                            </Route>
                            <Route exact path={path}>
                                <h3>
                                    path: {path} - url: {url}
                                </h3>
                            </Route>
                        </Switch>
                    </Col>
                )}
            </Row>
        </>
    );
};

export default Exchange;
