import React, { useEffect, useState } from "react";
import { useLocation, NavLink, useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Menu, Avatar, Dropdown, Col, Row, Divider, notification, Badge, Modal } from "antd";
import { BellOutlined, CaretDownOutlined } from "@ant-design/icons";
import { handleResize } from "../../../utils/responsible";
import StateStatus from "../../../utils/status";
import ManagerProfile from "../../../features/Dashboard/ManagerProfile/ManagerProfile";
import { OrangeButton } from "../Button"
import { getProfile, selectLogin } from "../../../features/Login/LoginSlice";
import { listPeople, listPeopleCompleted, resetPeopleStates } from "../../../features/Person/slice";
import {
  getCompanies,
  getCompaniesCompleted,
  resetAddCompany,
  resetCompaniesStates,
  resetGetCompany,
} from "../../../features/ChooseCompany/ChooseCompanySlice";
import "./styles.scss";
import WelcomeModal from "../WelcomeModal";
import Routes from "../../../routes/routes";
import { resetTransactionsStates } from "../../../features/Home/slice";
import NotificationModal from "../NotificationModal";
import { newNotifications } from "./slice";


const { Header } = Layout;


const EfexHeader = () => {
  const [isResponsible, updateResponsible] = useState(false);
  const [isVisible, updateVisible] = useState(false);
  const [isVisibleWelcome, setVisWelcome] = useState(false)
  const [isNotificationModalVisible, setIsNotificationModalVisible] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [timeout, updateTimeOut] = useState(0);
  const [timer, updateTimer] = useState(1);
  const [isAllDeleted, setIsAllDeleted] = useState(false)
  const location = useLocation();
  const loginData = useSelector(selectLogin);
  const dispatch = useDispatch();
  const history = useHistory();
  let { path } = useRouteMatch();


  useEffect(() => {
    if (isAllDeleted) {
      updateTimer(1)
    }
  }, [isAllDeleted])

  useEffect(() => {
    if (timer > 0) {
      clearTimeout(timeout);
      updateTimeOut(
        setTimeout(() => {
          updateTimer(timer - 1);
        }, 1000),
      );
    } else {
      loadNotifications();
      updateTimer(30)
    }
  }, [timer]);

  const loadNotifications = () => {
    dispatch(newNotifications({ page: 1 })).then(resp => {
      if (resp.payload) {
        setNotifications(resp.payload);
        if (isAllDeleted) {
          setIsAllDeleted(false)
        }
      }
    });
  };

  const onLogout = () => {
    localStorage.clear();
    history.replace("/");
    dispatch(resetCompaniesStates())
    dispatch(resetPeopleStates())
    dispatch(resetTransactionsStates())
  };

  const showNotificationModal = () => {
    setIsNotificationModalVisible(true);
  };

  const handleCancelNotificationModal = () => {
    setIsNotificationModalVisible(false);
  };

  const onCancel = () => {
    updateVisible(false);
  };
  const openMgProfileModal = () => {
    updateVisible(true);
  };

  useEffect(() => {
    window.addEventListener("resize", () =>
      handleResize(updateResponsible)
    );
    handleResize(updateResponsible);
  }, []);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    if (loginData.status.profile === StateStatus.succeeded) {
      window.dataLayer = window.dataLayer || [];
      const first_login = localStorage.getItem("first_login");

      if (first_login !== null && first_login !== "undefined") {
        let hasRister = false;
        setVisWelcome(true);
        window.dataLayer.find(el => {
          if (el?.event === 'registro') {
            hasRister = true
          }
        })
        if (!hasRister) {
          window.dataLayer.push({
            'event': 'registro',
            'authentication_method': 'email',
            'user_email': loginData.profile.email
          });
        }
      } else {
        let hasLogin = false;
        window.dataLayer.find(el => {
          if (el?.event === 'login') {
            hasLogin = true
          }
        })
        if (!hasLogin) {
          window.dataLayer.push({
            'event': 'login',
            'authentication_method': 'email',
            'user_email': loginData.profile.email
          });
        }
      }
    }
  }, [loginData.status.profile])

  let menuKey = "/dashboard";

  if (location.pathname.startsWith("/dashboard/empresas")) {
    menuKey = "/dashboard/empresas";
  } else if (location.pathname.startsWith("/dashboard/pessoas")) {
    menuKey = "/dashboard/pessoas";
  }

  return (
    <div className="background-efexhub-header">
      <Header className="efexhub-header">
        <div
          className={
            path.match("/dashboard")
              ? "internal-logo"
              : "logo"
          }
          onClick={() => window.open("https://efexhub.com.br/")}
        />
        {!isResponsible ? (
          <Menu
            mode="horizontal"
            defaultSelectedKeys={["/dashboard"]}
            selectedKeys={[menuKey]}
          >
            <Menu.Item key="/dashboard">
              <NavLink to="/dashboard">Início</NavLink>
            </Menu.Item>
            <Menu.Item key="/dashboard/empresas">
              <NavLink
                to="/dashboard/empresas"
                onClick={() => {
                  dispatch(resetGetCompany());
                  dispatch(resetAddCompany());
                  dispatch(getCompanies());
                  dispatch(getCompaniesCompleted());
                }}
              >
                Empresas
              </NavLink>
            </Menu.Item>
            <Menu.Item key="/dashboard/pessoas">
              <NavLink
                to="/dashboard/pessoas"
                onClick={() => {
                  dispatch(listPeople());
                  dispatch(listPeopleCompleted());
                }}
              >
                Pessoas
              </NavLink>
            </Menu.Item>
          </Menu>
        ) : (
          <Menu
            mode="horizontal"
            defaultSelectedKeys={["/dashboard"]}
            selectedKeys={[menuKey]}
          >
            <Menu.Item key="/dashboard">
              <NavLink to="/dashboard">Início</NavLink>
            </Menu.Item>
          </Menu>
        )}
        <Col className="avatar-container">
          {loginData.status.profile !== StateStatus.succeeded ? null : (
            <Dropdown
              visible={isVisible ? false : undefined}
              overlay={() =>
                userMenu(
                  loginData.profile,
                  onLogout,
                  history,
                  isVisible,
                  openMgProfileModal,
                  onCancel
                )
              }
              trigger={["click"]}
              placement="bottomRight"
            >
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  style={{
                    marginRight: "5px",
                    backgroundColor: "#7B7B7B",
                  }}
                >
                  {loginData.profile.name[0]}
                </Avatar>
                <CaretDownOutlined style={{ color: "#C6C6C6" }} />
              </div>
            </Dropdown>
          )}
        </Col>

        <Col className="avatar-container">
          {notifications.success && notifications.pagination?.total > 0 ? (
            <div style={{ marginRight: "10px" }}>
              <Badge count={notifications.pagination?.total} style={{ backgroundColor: '#f5222d' }}>
                <BellOutlined
                  style={{ fontSize: "25px", cursor: "pointer", color: "#474747" }}
                  onClick={showNotificationModal}
                />
              </Badge>
            </div>
          ) : (
            <div>
              <BellOutlined
                style={{ fontSize: "25px", cursor: "pointer", color: "#474747" }}
                onClick={showNotificationModal}
              />
            </div>
          )}
        </Col>
      </Header>
      <WelcomeModal
        visible={isVisibleWelcome}
        onCancel={() => setVisWelcome(false)}
        onCompany={() => {
          history.push(`${Routes.chooseCompany}?open_register=true`)
          setVisWelcome(false)
        }}
        onPerson={() => {
          history.push(`${Routes.people}?open_register=true`)
          setVisWelcome(false)
        }} />
      <Modal
        title="Notificações"
        visible={isNotificationModalVisible}
        onOk={null}
        onCancel={handleCancelNotificationModal}
        maskClosable={true}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        notifications={notifications}
        className="notification-modal"
        style={{ top: 0, right: 10, position: 'fixed' }}
      >
        <NotificationModal
          notifications={notifications}
          setNotifications={setNotifications}
          setIsModalVisible={setIsNotificationModalVisible}
          setIsAllDeleted={setIsAllDeleted}
        />
      </Modal>
    </div>
  )
}

const userMenu = (
  profile,
  onLogout,
  history,
  isVisible,
  openMgProfileModal,
  onCancel
) => {
  return (
    <div className="user-menu">
      <div className="triangle"></div>
      <Row>
        <Col span={6}>
          <Avatar style={{ backgroundColor: "#7B7B7B" }}>
            {profile.name[0]}
          </Avatar>
        </Col>
        <Col span={18}>
          <div
            style={{
              //marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="user-menu-name">{profile.name}</div>
            <OrangeButton
              style={{
                fontSize: "12px",
                border: "none",
                color: "orange",
                backgroundColor: "transparent",
                background: "none",
              }}
              onClick={openMgProfileModal}
            >
              Gerenciar sua conta
            </OrangeButton>
          </div>
        </Col>
      </Row>
      <Divider style={{ margin: "5px 0px 10px 0" }} />
      <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            fontSize: "12px",
            alignItems: "center",
            width: "100%",
          }}
        >
          <p
            className="item-menu"
            onClick={() => history.push("/dashboard/empresas")}
          >
            Empresas cadastradas
          </p>
          <p
            className="item-menu"
            onClick={() => history.push("/dashboard/pessoas")}
          >
            Pessoas cadastradas
          </p>
          <p style={{ cursor: "pointer" }} onClick={onLogout}>
            Sair
          </p>
        </div>
      </Row>
      <ManagerProfile visible={isVisible} onCancel={onCancel} />
    </div>
  );
};

export default EfexHeader