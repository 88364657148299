import React from "react";
import company from "../../../../common/assets/add_company.png";
import "./styles.scss";

const BlankCompany = () => {
  return (
    <div className="blank-container">
      <img src={company} alt='imagem que representa que Você ainda não cadastrou nenhuma empresa'/>
      <p>Você ainda não cadastrou nenhuma empresa</p>
    </div>
  );
};

export default BlankCompany;
