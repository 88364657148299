import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StateStatus from "../../utils/status";
import {
	addCompany,
	getCompanies as companies,
	deleteCompany,
	getCompany_,
	getTransactions,
	patchCompany,
	receiveValueDuplicate,
	sendValueDuplicate,
} from "./ChooseCompanyService";
import { strFormatTime } from "../../utils/functions";

const initialState = {
	status: {
		addCompany: StateStatus.idle,
		getCompanies: StateStatus.idle,
		getCompaniesCompleted: StateStatus.idle,
		getByCnpj: StateStatus.idle,
		getCompany: StateStatus.idle,
		getResponsables: StateStatus.idle,
		getCompanyByCnpj: StateStatus.idle,
		listStatusTransactions: StateStatus.idle,
		listLastTransactions: StateStatus.idle,
		duplicateTransaction: StateStatus.idle,
		deleteCompany: StateStatus.idle

	},
	cnpj: null,
	errorMsg: null,
	companies: [],
	companiesCompleted: [],
	financialResponsibles: [],
	statusTransactions: [],
	lastTransactions: [],
	duplicatedTransaction: null,
	user: null,
	company: null,
};

export const postAddCompany = createAsyncThunk(
	"company/addCompany",
	async (data) => {
		const response = await addCompany(data);
		return response.data;
	},
);

export const getCompanies = createAsyncThunk(
	"company/getCompanies",
	async () => {
		const finalized = false;
		const response = await companies({ finalized });
		return response.data;
	},
);

export const deleteCompanyThunk = createAsyncThunk(
	"company/delete",
	async (companyId) => {
		const response = await deleteCompany(companyId);
		return response.data;
	},
);

export const getCompaniesCompleted = createAsyncThunk(
	"company/getCompaniesCompleted",
	async () => {
		const finalized = true;
		const response = await companies({ finalized });
		return response.data;
	},
);

export const getCompany = createAsyncThunk("company/getCompany", async (id) => {
	const response = await getCompany_({ id });
	return response.data;
});

export const updateCompany = createAsyncThunk("company/updateCompany", async (data) => {
	const response = await patchCompany(data);
	return response.data;
});

export const getCompanyByCnpj = createAsyncThunk(
	"company/getCompanyByCnpj",
	async () => {
		let cnpj = localStorage.getItem("cnpj");
		const response = await getCompany_({ cnpj });
		return response.data;
	},
);

export const listStatusTransactions = createAsyncThunk(
	"company/listStatusTransactions",
	async ({ order, sortBy, cnpj, status, page }) => {
		const response = await getTransactions({
			cnpj: cnpj ?? null,
			owner_type: "company",
			sort_by: sortBy ?? "status_updated_at",
			sort_order: order ?? "DESC",
			status: status ?? null,
			page
		});
		return response.data;
	});

export const listLastTransactions = createAsyncThunk(
	"company/listLastTransactions",
	async ({ order, sortBy }) => {
		const response = await getTransactions({
			owner_type: "company",
			sort_by: sortBy ?? "created_at",
			sort_order: order ?? "DESC",
			status: [
				// "contract_signed",
				// "waiting_deposit_receipt",
				// "completed",
				"finished",
			]
		});
		return response.data;
	});

export const duplicateTransaction = createAsyncThunk(
	'company/duplicatedTransaction',
	async ({ id, type, cnpj, owner_id }) => {
		let response = null;

		if (type === "send_value") {
			response = await sendValueDuplicate({
				cpf_cnpj: cnpj,
				send_value_id: id
			});
		} else {
			response = await receiveValueDuplicate({
				cpf_cnpj: cnpj,
				receive_value_id: id
			});
		}

		return { type, owner_id, id: response.data.id }
	},
)

export const resetCompaniesStates = createAsyncThunk(
	'company/resetCompaniesStates',
	async () => {
		return { getCompanies: StateStatus.idle, getCompaniesCompleted: StateStatus.idle };
	}
);

export const company = createSlice({
	name: "company",
	initialState,
	reducers: {
		setCompany: (state, action) => {
			const company = action.payload;
			localStorage.setItem(
				"register_steps",
				JSON.stringify(company.register_steps),
			);
			localStorage.setItem(
				"company",
				JSON.stringify({
					...company,
					company_name: company.company_name,
					cnpj: company.cnpj,
					activity: company?.cnaes?.filter(
						(item) => item.is_principal === true,
					)[0].description,
					juridical_nature_description:
						company?.juridical_nature_description,
					country_name: company?.addresses?.[0]?.country_name,
					init_date: company?.init_date ? strFormatTime(company?.init_date) : null,
					share_capital: company?.share_capital,
					email: company?.email,
					phone: company?.phone_0,
					zipcode: company?.addresses?.[0]?.zipcode,
					public_place: company?.addresses?.[0]?.public_place,
					number: company?.addresses?.[0]?.number,
					complement: company?.addresses?.[0]?.complement,
					neighborhood: company?.addresses?.[0]?.neighborhood,
					city_name: company?.addresses?.[0]?.city_name,
					province: company?.addresses?.[0]?.province,
					register_update: company?.register_update?.[0]
				}),
			);
			localStorage.setItem(
				"companyExtra",
				JSON.stringify({
					...company,
					company_name: company?.company_name,
					cnpj: company?.cnpj,
					activity: company?.cnaes?.filter(
						(item) => item.is_principal === true,
					)[0].description,
					juridical_nature_description:
						company?.juridical_nature_description,
					country_name: company?.addresses?.[0]?.country_name,
					init_date: company?.init_date ? strFormatTime(company?.init_date) : null,
					share_capital: company?.share_capital,
					email: company?.email,
					phone: company?.phone_0,
					zipcode: company?.addresses?.[0]?.zipcode,
					public_place: company?.addresses?.[0]?.public_place,
					number: company?.addresses?.[0]?.number,
					complement: company?.addresses?.[0]?.complement,
					neighborhood: company?.addresses?.[0]?.neighborhood,
					city_name: company?.addresses?.[0]?.city_name,
					province: company?.addresses?.[0]?.province,
				}),
			);
		},
		setCnpj: (state, action) => {
			localStorage.removeItem("cnpj");
			localStorage.setItem("cnpj", action.payload);
			state.cnpj = action.payload;
		},
		resetGetCompaniesStatus: (state) => {
			state.status.getCompanies = StateStatus.idle;
		},
		resetAddCompany: (state) => {
			state.status.addCompany = StateStatus.idle;
		},
		resetGetCompany: (state) => {
			state.status.getCompany = StateStatus.idle;
			localStorage.removeItem("financial_responsibles");
			localStorage.removeItem("companyExtra");
			localStorage.removeItem("register_steps");
			localStorage.removeItem("companies");
			localStorage.removeItem("company");
		},
		resetGetCompanyByCnpj: (state) => {
			state.status.getCompanyByCnpj = StateStatus.idle;
		},
		resetDuplicateState: (state) => {
			state.status.duplicateTransaction = StateStatus.idle;
		},
	},
	extraReducers: {
		[postAddCompany.pending]: (state) => {
			state.status.addCompany = StateStatus.loading;
		},
		[postAddCompany.fulfilled]: (state) => {
			state.status.addCompany = StateStatus.succeeded;
			localStorage.removeItem("financial_responsibles");
			localStorage.removeItem("companyExtra");
			localStorage.removeItem("register_steps");
			localStorage.removeItem("companies");
			localStorage.removeItem("company");
		},
		[postAddCompany.rejected]: (state, action) => {
			state.status.addCompany = StateStatus.failed;
			state.errorMsg = action.error.message;
		},

		[getCompanies.pending]: (state) => {
			state.status.getCompanies = StateStatus.loading;
		},
		[getCompanies.fulfilled]: (state, action) => {
			state.status.getCompanies = StateStatus.succeeded;
			state.companies = action.payload.companies;
			localStorage.setItem(
				"companies",
				JSON.stringify(action.payload.companies),
			);
		},
		[getCompanies.rejected]: (state, action) => {
			state.status.getCompanies = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[getCompaniesCompleted.pending]: (state) => {
			state.status.getCompaniesCompleted = StateStatus.loading;
		},
		[getCompaniesCompleted.fulfilled]: (state, action) => {
			state.status.getCompaniesCompleted = StateStatus.succeeded;
			state.companiesCompleted = action.payload.companies;
			localStorage.setItem(
				"companiesCompleted",
				JSON.stringify(action.payload.companies),
			);
		},
		[getCompaniesCompleted.rejected]: (state, action) => {
			state.status.getCompaniesCompleted = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[getCompany.pending]: (state) => {
			state.status.getCompany = StateStatus.loading;
		},
		[getCompany.fulfilled]: (state, action) => {
			state.status.getCompany = StateStatus.succeeded;
			state.company = action.payload;
		},
		[getCompany.rejected]: (state, action) => {
			state.status.getCompany = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[getCompanyByCnpj.pending]: (state) => {
			state.status.getCompanyByCnpj = StateStatus.loading;
		},
		[getCompanyByCnpj.fulfilled]: (state, action) => {
			state.status.getCompanyByCnpj = StateStatus.succeeded;
			state.company = action.payload;
			let company = action.payload;
			localStorage.setItem(
				"register_steps",
				JSON.stringify(company.register_steps),
			);
			localStorage.setItem(
				"company",
				JSON.stringify({
					can_register: company.can_register,
					company_name: company.company_name,
					cnpj: company.cnpj,
					activity: company.cnaes.filter(
						(item) => item.is_principal === true,
					)[0].description,
					juridical_nature_description:
						company.juridical_nature_description,
					country_name: company.addresses[0].country_name,
					init_date: strFormatTime(company.init_date),
					share_capital: company.share_capital,
					email: company.email,
					phone: company.phone_0,
					zipcode: company.addresses[0].zipcode,
					public_place: company.addresses[0].public_place,
					number: company.addresses[0].number,
					complement: company.addresses[0].complement,
					neighborhood: company.addresses[0].neighborhood,
					city_name: company.addresses[0].city_name,
					province: company.addresses[0].province,
				}),
			);
		},
		[getCompanyByCnpj.rejected]: (state, action) => {
			state.status.getCompanyByCnpj = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[listStatusTransactions.pending]: (state) => {
			state.status.listStatusTransactions = StateStatus.loading;
		},
		[listStatusTransactions.fulfilled]: (state, action) => {
			state.status.listStatusTransactions = StateStatus.succeeded;
			state.statusTransactions = action.payload.lst.slice(0, 4);
		},
		[listStatusTransactions.rejected]: (state, action) => {
			state.status.listStatusTransactions = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[listLastTransactions.pending]: (state) => {
			state.status.listLastTransactions = StateStatus.loading;
		},
		[listLastTransactions.fulfilled]: (state, action) => {
			state.status.listLastTransactions = StateStatus.succeeded;
			state.lastTransactions = action.payload.lst.slice(0, 4);
		},
		[listLastTransactions.rejected]: (state, action) => {
			state.status.listLastTransactions = StateStatus.failed;
			state.errorMsg = action.error.message;
		},

		[duplicateTransaction.pending]: (state) => {
			state.status.duplicateTransaction = StateStatus.loading;
		},
		[duplicateTransaction.fulfilled]: (state, action) => {
			state.duplicatedTransaction = action.payload;
			state.status.duplicateTransaction = StateStatus.succeeded;
		},
		[duplicateTransaction.rejected]: (state, action) => {
			state.status.duplicateTransaction = StateStatus.failed;
			state.errorMsg = action.error.message;
		},


		// delete companies
		[deleteCompanyThunk.pending]: (state) => {
			state.status.deleteCompany = StateStatus.loading;
		},
		[deleteCompanyThunk.fulfilled]: (state) => {
			// state.duplicatedTransaction = action.payload;
			state.status.deleteCompany = StateStatus.succeeded;
		},
		[deleteCompanyThunk.rejected]: (state, action) => {
			state.status.deleteCompany = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[resetCompaniesStates.fulfilled]: (state, action) => {
			state.status.getCompanies = action.payload.getCompanies;
			state.status.getCompaniesCompleted = action.payload.getCompaniesCompleted;
		},
	},
});

export const {
	setCompany,
	setCnpj,
	getByCnpj,
	resetGetCompaniesStatus,
	resetAddCompany,
	resetGetCompany,
	resetGetCompanyByCnpj,
	resetDuplicateState,
} = company.actions;

export const selectCompany = (state) => state.company;
