import React from "react";
import "./styles.scss";

const BlankContactReceive = () => {
  return (
    <div className="blank-container">
      <p>Nenhum contato para recebimento de recursos</p>
    </div>
  );
};

export default BlankContactReceive;
