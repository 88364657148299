import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'


export const getColorByStatus = (status) => {
  switch (status) {
    case null:
    case "draft":
    case "waiting_approval":
    case "need_changes":
    case "docs_approved":
    case "created_by_bo":
      return "red";
    case "approved":
    case "scheduled":
    case "waiting_exchange":
    case "making_exchange":
    case "waiting_contract":
    case "waiting_sign_doc":
    case "contract_signed":
    case "completed":
    case "waiting_deposit_receipt":
      return "yellow";
    case "finished":
      return "green";
  }
}



const CircleTransactionStatus = ({ status }) => {
  return (
    <div className={`transaction-status-circle ${getColorByStatus(status)}`}></div>
  )
}

CircleTransactionStatus.propTypes = {
  status: PropTypes.string
}

export default CircleTransactionStatus