import React from "react";
import { Layout, Row, Col } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import "./styles.scss";

const { Footer } = Layout;

const EfexFooter = () => {
    return (
        <Footer className="background-efexhub-footer">
            <Row justify="center" gutter={[16, 16]}>
                <Col xs={24} sm={24} md={11} lg={11}>
                    <div>
                        <div
                            className="footer-logo"
                            style={{ width: "100%" }}
                        ></div>
                        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                            (11) 3253-5628
                        </div>
                    </div>
                    <div className="long-text">
                        Correspondente cambial credenciado no Banco Central do Brasil pelo Banco BS2.
                    </div>
                </Col>
                <Col xs={24} sm={24} md={11} lg={11} style={{ paddingTop: "60px" }}>
                    <Row justify="space-between">
                        <Col style={{ paddingTop: '12px' }}>
                            <br />
                            <br />
                            <div>contato@efexhub.com.br</div>
                        </Col>
                        <Col style={{ padding: 0, display: 'flex', alignItems: 'center' }}>
                            <a href="https://wa.me/551132535628" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', color: '#25D366' }}>
                                <WhatsAppOutlined style={{ fontSize: '24px' }} />
                                <span style={{ marginLeft: '8px', color: "white" }}>Fale conosco pelo WhatsApp</span>
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Footer>
    );
};

export default EfexFooter;
