import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Input, notification, Tooltip } from "antd";

import { useRouteMatch, useHistory } from "react-router-dom";
import { cpf as cpfValidator } from "cpf-cnpj-validator";
import {
    selectLogin,
    updatePassword,
    resetPasswordStatus,
} from "../../../Login/LoginSlice.js";
import EfexModal from "../../../../common/components/Modal";
import StateStatus from "../../../../utils/status";

import "../../styles.scss";


const ChangePasswordModal = ({ visible, onCancel }) => {
    const [form] = Form.useForm();
    const loginProfile = useSelector(selectLogin);

    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const history = useHistory();


    useEffect(() => {
        form.setFieldsValue(loginProfile.profile);
    }, [form]);

    useEffect(() => {
        if (loginProfile.status.updatePassword === StateStatus.succeeded) {
            notification.success({
                message: `Sucesso!`,
                description: "Perfil atualizado com sucesso!",
                placement: "topRight",
            });
            form.setFieldsValue(loginProfile.profile);
            dispatch(resetPasswordStatus());
            onCancel();
        }

        if (loginProfile.status.updatePassword === StateStatus.failed) {
            notification.error({
                message: `Erro!`,
                description: loginProfile.errorMsg,
                placement: "topRight",
            });
            dispatch(resetPasswordStatus());
        }
    }, [loginProfile]);

    return (
        <EfexModal
            className={
                path.match("empresas") ? "modal-company" : "modal-person"
            }
            title={"Alterar Senha"}
            visible={visible}
            confirmLoading={loginProfile.status.updatePassword === StateStatus.loading}
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        let data = {
                            ...values,
                            phone: cpfValidator.strip(values.phone)
                        }
                        dispatch(updatePassword(data)).then((value) => {
                            localStorage.clear();
                            history.replace("/");
                        }).catch((err) => err.message);
                    })
                    .catch((info) => {
                    });
            }}
        >
            <Form aria-autocomplete="none" form={form} layout="vertical">
                <Row gutter={[8, 0]}>
                    <Col span={24}>
                        <Form.Item
                            name="new_password"
                            label="Nova Senha"
                            rules={[
                                {
                                    required: true,
                                    message: 'Informe uma senha',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            name="confirm_password"
                            label="Confirmar senha"
                            dependencies={['new_password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Confirme a senha',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('new_password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('As senhas são diferentes'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </EfexModal>
    );
};

export default ChangePasswordModal;
