import React, { useState, useEffect } from "react";

import { Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import StateStatus from "../../utils/status";
import {
  fetchQsa,
  selectQsa,
  toggleSelectedQsa,
  addNewQsa,
  removeQsa,
  getResponsibles,
  setResponsibles,
  resetResponsiblesStatus
} from "./SelectPartnersSlice";
import PartnerCard from "../../common/components/PartnerCard";
import AddNewQsa from "./modals/AddNewQsa";
import Routes from "../../routes/routes";
import RegisterBaseLayout from "../../common/components/RegisterBaseLayout/RegisterBaseLayout";
import LoadingIcon from "../../common/components/loading_icon/loading_icon";
import BlankQsa from "./components/blankQsa";

import { resetStatus } from '../LegalPersonExtra/LegalPersonExtraSlice';
import { OrangeButton, WhiteButton } from "../../common/components/Button";
import { EfexCard } from "../../common/components/Card";
import { handleResize } from "../../utils/responsible";
import { selectCompany } from "../ChooseCompany/ChooseCompanySlice";

const AdmData = (props) => {
  const financialResponsibles = useSelector(selectQsa);
  const [addQsa, updateAddQsa] = useState(false);
  const [isResponsible, updateResponsible] = useState(false);
  // let company = JSON.parse(localStorage.getItem("company"));
  const dispatch = useDispatch();
  const [registerUpdate, setRegisterUpdate] = useState([])
  const selectedCompany = useSelector(selectCompany);

  useEffect(() => {
    if (selectedCompany.status?.getCompanyByCnpj === "succeeded") {
      setRegisterUpdate(selectedCompany.company.register_update[0])
    }
  }, [selectedCompany])

  useEffect(() => {
    if (registerUpdate?.representative_form) {
      dispatch(setResponsibles([]));
      localStorage.setItem("financial_responsibles", JSON.stringify([]));
    }
  }, [registerUpdate, dispatch])

  useEffect(() => {
    if (financialResponsibles.status.responsibles === StateStatus.idle) {
      dispatch(getResponsibles());
    }
    if (
      financialResponsibles.status.list === StateStatus.idle &&
      financialResponsibles.status.responsibles === StateStatus.succeeded
    ) {
      dispatch(fetchQsa());
    }
  }, [financialResponsibles.status, dispatch]);

  useEffect(() => {
    dispatch(fetchQsa())
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => handleResize(updateResponsible));
    handleResize(updateResponsible);
  }, []);

  const onAddQsa = (values) => {
    values.cpf = values.cpf.replace(/\D/g, "");
    dispatch(addNewQsa(values));
    dispatch(toggleSelectedQsa(values));
    updateAddQsa(false);
  };

  return (
    <RegisterBaseLayout
      {...props}
      current={2}
      text="Selecione até 5 (cinco) responsáveis financeiros."
      titleL3="Administrador(es) da empresa"
    >
      <EfexCard>
        {financialResponsibles.status.list === StateStatus.loading ? (
          <Row justify="center">
            <LoadingIcon color="orange" />
          </Row>
        ) : (
          <>
            {financialResponsibles.data.allQsa.length > 0 ? (
              financialResponsibles.data.allQsa.map((qsa, index) => {
                const blockNew =
                  financialResponsibles.data.responsibles.length >= 5;
                const isChecked = financialResponsibles.data.responsibles.filter(
                  (item) => item.name === qsa.name
                ).length > 0;

                return (
                  <Row key={qsa.name}>
                    <PartnerCard
                      isResponsible={isResponsible}
                      isOdd={index % 2 === 0}
                      personData={qsa}
                      checked={isChecked}
                      removePerson={(value) => {
                        dispatch(removeQsa(qsa));
                        dispatch(toggleSelectedQsa(value));
                      }}
                      disabled={blockNew ? (isChecked ? false : true) : false}
                      onChange={(value) => dispatch(toggleSelectedQsa(value))}
                    />
                  </Row>
                );
              })
            ) : (
              <BlankQsa />
            )}
            <br />
            <Row justify="end">
              <WhiteButton
                hoverColor="orange"
                style={{ width: "180px" }}
                onClick={() => updateAddQsa(true)}
                disabled={
                  financialResponsibles.data.responsibles.length === 5
                }
              >
                Adicionar responsável
              </WhiteButton>
            </Row>
          </>
        )}
      </EfexCard>
      <AddNewQsa
        visible={addQsa}
        onCancel={() => updateAddQsa(false)}
        onOk={onAddQsa.bind(this)}
      />
      <Row style={{ marginTop: "16px" }} justify="space-between">
        {!registerUpdate &&
          <WhiteButton
            hoverColor="orange"
            onClick={() => {
              dispatch(resetStatus());
              props.history.push(Routes.legalPersonExtra);
            }}
          >
            Voltar
          </WhiteButton>}

        <OrangeButton
          style={{ width: "80px", height: "35px" }}
          disabled={
            financialResponsibles.data.responsibles.length === 0
          }
          onClick={() => {
            props.history.push(Routes.completePartnersData);
          }}
          type="primary"
          htmlType="submit"
          loading={false}
        >
          Avançar
        </OrangeButton>
      </Row>
    </RegisterBaseLayout>
  );
};

export default AdmData;

