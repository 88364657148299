import React, { useState, useEffect } from 'react';
// import square from "../../assets/organize_square.svg";
import { ReactComponent as TableIcon } from "../../assets/organize_table.svg";
import { ReactComponent as SquareIcon } from "../../assets/organize_square.svg";
import "./styles.scss";
import { Divider } from 'antd';

const ChooseGridStyle = ({ selectDefault = null, onChange = () => {} }) => {

    const [selected, setSelected] = useState("table")

    useEffect(() => {
        if(selected && selected !== setSelected) {
            setSelected(selectDefault)
            onChange(selectDefault)
        }
    }, [selectDefault])

    const change = (selectedType) => {
        setSelected(selectedType)
        onChange(selectedType)
    }

    return <div className="grid-config">
        <div className="grid-icon">
            <TableIcon onClick={() => change("table")} className={selected === "table" ? "grid-item-selected" : ""} />
            {/* <img width="24px" src={table} style={{color: "#000000"}} /> */}
        </div>
        <Divider type="vertical" />
        <div className="grid-icon">
            <SquareIcon onClick={() => change("card")} className={selected === "card" ? "grid-item-selected" : ""} />
        </div>
    </div>

}

export default ChooseGridStyle