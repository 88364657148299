import EfexModal from "../../../../common/components/Modal";
import React from "react"
import { Typography } from "antd"
import { CheckCircleOutlined } from "@ant-design/icons"
import "./styles.scss"
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";

const { Title } = Typography

const ExchangeApprovedModal = ({ isVisible, onClose }) => {
  const { url } = useRouteMatch()

  return (
    <EfexModal
      className={`modal-exchange-approved ${url.includes("empresas") ? "company" : "person"}`}
      title=""
      hasButtons={false}
      visible={isVisible}
      onCancel={() => {
        onClose()
      }}

    >
      <Title level={3}>A sua transação foi bem sucedida!</Title>
      <div className="show-icon">
        <CheckCircleOutlined />
      </div>
      <div className="description">
        Enviamos-lhe um email de confirmação da transação. Verifique a sua caixa de entrada.
      </div>
    </EfexModal >
  )
}

export default ExchangeApprovedModal