import React, { useState, useEffect } from "react";

import {
    Form,
    Input,
    Typography,
    Row,
    Col,
    Select,
    Divider,
    notification,
    InputNumber,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import Routes from "../../routes/routes";
import { DownOutlined } from "@ant-design/icons";
import { formatCurrency, scrollUp } from "../../utils/functions";

import StateStatus from "../../utils/status";
import RegisterBaseLayout from "../../common/components/RegisterBaseLayout/RegisterBaseLayout";
import { countriesWithouBrazilList } from "../../utils/lists";
import TooltipEfex from "../../common/components/Tooltip";
import "./LegalPersonExtra.scss";
import {
    comex_type_options,
    comex_operation_options,
    options,
    number_of_workers,
} from "../../utils/consts";
import IdentedField from "../CompleteQsaInfo/components/IdentedField";
import {
    selectCompanyExtraData,
    sendCompanyExtraDataSlice,
} from "./LegalPersonExtraSlice";
import { OrangeButton, WhiteButton } from "../../common/components/Button";
import { EfexCard } from "../../common/components/Card";
import { selectCompany, updateCompany, setCompany as setCompanyReducer, getCompanyByCnpj } from "../ChooseCompany/ChooseCompanySlice";

const { Title } = Typography;
const { Option } = Select;

const LegalPersonExtra = (props) => {
    const [form] = Form.useForm();
    const [isComex, setIsComex] = useState(false);
    const [isOther, setIsOther] = useState(false);
    const [isMovLess, setIsMovLess] = useState(false);
    const [companyExtraStg] = useState(
        JSON.parse(localStorage.getItem("companyExtra"))
    );
    const companyExtra = useSelector(selectCompanyExtraData);
    const [company, setCompany] = useState([])
    const [registerUpdate, setRegisterUpdate] = useState([])
    const selectedCompany = useSelector(selectCompany);

    useEffect(() => {
        if (selectedCompany.status?.getCompanyByCnpj === "succeeded") {
            setCompany(selectedCompany.company)
            setRegisterUpdate(selectedCompany.company.register_update[0])
        }
    }, [selectedCompany])


    const [value, setValue] = React.useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (companyExtraStg !== null) {
            if (
                Object.prototype.toString.call(companyExtraStg.comex) ===
                "[object Object]"
            ) {
                companyExtraStg["payment_receivement_type"] =
                    companyExtraStg.comex.payment_receivement_type;
                // companyExtraStg["radar_limit"] = companyExtraStg.comex.radar_limit;
                companyExtraStg["operation_mode"] =
                    companyExtraStg.comex.operation_mode;
                companyExtraStg["storage_comments"] =
                    companyExtraStg.comex.storage_comments;
                companyExtraStg["last_six_months_declarations"] =
                    companyExtraStg.comex.last_six_months_declarations;
                companyExtraStg["company_or_qsa_has_policy_process"] =
                    companyExtraStg.comex.company_or_qsa_has_policy_process;
                companyExtraStg["merchandise_description"] =
                    companyExtraStg.comex.merchandise_description;
                delete companyExtraStg.comex;
                companyExtraStg.comex = true;
                setIsComex(true);
            }
            if (!registerUpdate?.kyc) {
                form.setFieldsValue({
                    ...companyExtraStg,
                    countries_of_payers:
                        companyExtraStg.countries_of_payers === null
                            ? []
                            : companyExtraStg.countries_of_payers,
                    countries_of_receivers:
                        companyExtraStg.countries_of_receivers === null
                            ? []
                            : companyExtraStg.countries_of_receivers,
                    purposes_exchange:
                        companyExtraStg.purposes_exchange === null
                            ? []
                            : companyExtraStg.purposes_exchange,

                    payers_and_buyers_country:
                        !companyExtraStg.payers_and_buyers_country
                            ? []
                            : companyExtraStg.payers_and_buyers_country,
                });

                setIsMovLess(companyExtraStg.is_movement_less_15)
            }
        }
    }, [companyExtraStg, form, company, registerUpdate]);

    useEffect(() => {
        if (companyExtra.status === StateStatus.succeeded) {
            let registerSteps = JSON.parse(
                localStorage.getItem("register_steps")
            );
            registerSteps.completed_company_data = true;
            localStorage.setItem(
                "register_steps",
                JSON.stringify(registerSteps)
            );
            if (localStorage.getItem("edit-kyc")) {
                props.history.push(Routes.confirmRegister);
            }
        }
    });

    const onFinish = (values) => {
        let storageCompany = JSON.parse(localStorage.getItem("company"));

        // Address Company
        values.city_name = storageCompany.city_name;
        values.country_name = storageCompany.country_name;
        values.neighborhood = storageCompany.neighborhood;
        values.number = storageCompany.number;
        values.zipcode = storageCompany.zipcode.replace(/\D/g, "");
        values.public_place = storageCompany.public_place;
        values.province = storageCompany.province;

        // Company
        values.company_name = storageCompany.company_name;
        values.cnpj = storageCompany.cnpj;
        values.activity = storageCompany.activity;
        values.juridical_nature_description =
            storageCompany.juridical_nature_description;
        values.init_date = storageCompany.init_date.split("/").reverse().join("-");
        values.phone_0 = storageCompany.phone.replace(/\D/g, "");
        values.monthly_invoicing = storageCompany.monthly_invoicing;
        values.patrimony = storageCompany.patrimony ?? 1;
        values.email = storageCompany.email;
        if (storageCompany.comments !== null && storageCompany.comments !== "")
            values.comments = company.comments;
        if (storageCompany.complement !== null && storageCompany.complement !== "")
            values.complement = company.complement;
        values.is_movement_less_15 ? values.operational_limit = 1500000 : values.operational_limit = 0

        values.economic_activity = storageCompany.activity;
        if (isComex) {
            values.comex = {};
            values.comex.payment_receivement_type =
                values.payment_receivement_type;
            //  values.comex.radar_limit = values.radar_limit;
            values.comex.operation_mode = values.operation_mode;
            values.comex.merchandise_description =
                values.merchandise_description;
            values.comex.storage_comments = values.storage_comments;
            values.comex.last_six_months_declarations =
                values.last_six_months_declarations;
            values.comex.company_or_qsa_has_policy_process =
                values.company_or_qsa_has_policy_process;
        } else {
            delete values.comex;
        }

        const data = {
            ...values,
            company_id: company?.id,
            current_update_step: "kyc"
        };

        let update_docs = registerUpdate?.company_incorporation_document ||
            registerUpdate?.board_election_minutes ||
            registerUpdate?.representative_proof_of_address ||
            registerUpdate?.representative_identity_document ||
            registerUpdate?.financial_documentation

        if (registerUpdate?.kyc) {
            dispatch(updateCompany(data)).then((resp) => {
                if (resp.payload) {
                    dispatch(getCompanyByCnpj()).then((resp) => {
                        resp.payload && setCompanyReducer((resp.payload))
                    })
                    if (registerUpdate?.representative_form) {
                        props.history.push(Routes.selectPartners);
                    } else if (update_docs || (selectedCompany.company.is_movement_less_15 && form.getFieldValue("is_movement_less_15") === false)) {
                        props.history.push(Routes.uploadFile);
                    } else {
                        props.history.push({
                            pathname: Routes.finishedRegister,
                            state: { type: "company" }
                        });
                    }

                }
            })
        } else {
            dispatch(sendCompanyExtraDataSlice(values)).then((data) => {
                if (data.error !== undefined) {
                    notification.error({
                        message: `${data.error.name}`,
                        description: `${data.error.message}`,
                        placement: "topRight",
                        duration: 6,
                    });
                } else {
                    props.history.push(Routes.selectPartners);
                }
            });
        }
        scrollUp();
    };

    const selectProps = {
        mode: "multiple",
        style: {
            width: "100%",
        },
        options,
        value,
        onChange: (newValue) => {
            setValue(newValue);
            if (newValue.includes(0)) {
                setIsComex(true);
            } else {
                setIsComex(false);
            }

            if (newValue.includes(13)) {
                setIsOther(true);
            } else {
                setIsOther(false);
            }
        },
        placeholder: "Selecionar propósitos",
        // maxTagCount: "responsive",
    };

    return (
        <>
            <RegisterBaseLayout
                {...props}
                current={1}
                titleL3="Cadastro da empresa"
                text={registerUpdate?.registration_form ?
                    "Complete os campos em branco com as informações atualizadas da empresa" :
                    "Complete os campos em branco com as informações da empresa. Para nós, é muito importante conhecer a rotina das operações de câmbio da sua empresa e seu negócio."
                }
            >
                <Form
                    className="legal-person-extra"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    requiredMark={false}
                    scrollToFirstError={true}
                >
                    <Row gutter={[16, 16]} className="row-empresa-endereco">
                        <Col xs={24} sm={24} lg={12}>
                            <EfexCard>
                                <Title level={5}>Empresa</Title>
                                <Divider />
                                <Form.Item
                                    name="company_describe"
                                    label={
                                        <>
                                            Descreva a atuação da sua empresa
                                            <TooltipEfex title="Ex: Fabricante de artigos de vestuário; Desenvolvedor de software de gestão;" />
                                        </>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ]}
                                >
                                    <Input.TextArea placeholder="" />
                                </Form.Item>

                                <Form.Item
                                    name="website"
                                    label={
                                        <>
                                            Website{" "}
                                            <TooltipEfex title="Caso não haja Website disponível, preencher com NA" />
                                        </>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Informe o Website",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Website" />
                                </Form.Item>

                                <Form.Item
                                    name="number_of_workers"
                                    label="Número de funcionários"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Informe número de funcionários",
                                        },
                                    ]}
                                >
                                    <Select options={number_of_workers} />
                                </Form.Item>

                                <Form.Item
                                    name="reason_for_exchange"
                                    label={
                                        <>
                                            Finalidade das operações de câmbio
                                            <TooltipEfex
                                                title="Nos conte resumidamente qual é principal finalidade das operações de câmbio.
                                            Exemplos: recebimento de clientes no exterior, pagamento de fornecedores no exterior."
                                            />
                                        </>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Informe a finalidade",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Finalidade" />
                                </Form.Item>

                                <Form.Item
                                    name="is_movement_less_15"
                                    label={
                                        <>
                                            Movimentação inferior a US$
                                            15.000,00 por ano (ou equivalente em
                                            outras moedas)?
                                            <TooltipEfex
                                                title="Ao marcar essa opção, 
                                            você não precisará nos encaminhar os documentos 
                                            financeiros da empresa e o cadastro prosseguirá 
                                            com limite transacional de até US$ 15.000,00 
                                            (quinze mil dólares) por ano."
                                            />
                                        </>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Esse campo é obrigatório",
                                        },
                                    ]}
                                >
                                    <Select
                                        onChange={(v) => {
                                            setIsMovLess(v);
                                            form.setFieldValue(
                                                "estimated_values",
                                                1500000
                                            );
                                        }}
                                    >
                                        <Option value={true}>Sim</Option>
                                        <Option value={false}>Não</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="estimated_values"
                                    label="Valor aproximado, em dólares, a ser enviado e recebido anualmente"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Informe a estimativa",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (
                                                    value != null &&
                                                    (isMovLess ? value > 1499999 : value > 1500000)
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        "Valor deve deve ser maior que 15 mil"
                                                    )
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <InputNumber
                                        className="input-value-exchange"
                                        min={1}
                                        disabled={isMovLess}
                                        formatter={(value) =>
                                            formatCurrency(value, "US$")
                                        }
                                        parser={(money) =>
                                            money.replace(/\D/g, "")
                                        }
                                    ></InputNumber>
                                </Form.Item>

                                <Form.Item
                                    name="payers_and_buyers_country"
                                    label="Países dos pagadores/recebedores"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        placeholder="Países"
                                        optionLabelProp="label"
                                    >
                                        {countriesWithouBrazilList.map(
                                            (item) => {
                                                return (
                                                    <Option
                                                        key={item.pais}
                                                        value={item.pais}
                                                    >
                                                        {item.pais}
                                                    </Option>
                                                );
                                            }
                                        )}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="principal_products_services"
                                    label={
                                        <>
                                            Principais produtos e serviços
                                            {/* <TooltipEfex title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." /> */}
                                        </>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Informe os principais produtos e serviços",
                                        },
                                    ]}
                                >
                                    <Input
                                        autoComplete="off"
                                        placeholder="Principais produtos e serviços"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="principal_clients"
                                    label={
                                        <>
                                            Principais Clientes
                                            {/* <TooltipEfex title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." /> */}
                                        </>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Informe o(s) Cliente(s)",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Principais clientes" />
                                </Form.Item>
                                <Form.Item
                                    name="principal_suppliers"
                                    label={
                                        <>
                                            Principais fornecedores
                                            {/* <TooltipEfex title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." /> */}
                                        </>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Informe o(s) fornecedor(es)",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Principais fornecedores" />
                                </Form.Item>
                                <Form.Item
                                    name="purposes_exchange"
                                    label={
                                        <>
                                            As remessas são principalmente para:
                                            <TooltipEfex title="Selecione uma ou mais opções que melhor descrevam a finalidade de suas remessas." />
                                        </>
                                    }
                                    style={{
                                        width: "100%",
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Selecione o propósito",
                                        },
                                    ]}
                                >
                                    <Select {...selectProps} />
                                </Form.Item>
                                {isOther ? (
                                    <p className="subtitle">Outros:</p>
                                ) : null}
                                <IdentedField>
                                    <Form.Item
                                        hidden={!isOther}
                                        name="other_field"
                                        label=""
                                        rules={[
                                            {
                                                required: isOther,
                                                message: "Campo obrigatório",
                                            },
                                        ]}
                                    >
                                        <Input.TextArea placeholder="" />
                                    </Form.Item>
                                </IdentedField>
                                {isComex ? (
                                    <p className="subtitle">
                                        Comércio Exterior: recebimento de
                                        exportação e/ou pagamentos de importação
                                    </p>
                                ) : null}
                                <IdentedField>
                                    <Form.Item
                                        name="payment_receivement_type"
                                        rules={[
                                            {
                                                required: isComex,
                                                message:
                                                    "Selecione a modalidade",
                                            },
                                        ]}
                                        label={
                                            <>
                                                Modalidade de
                                                recebimento/pagamento
                                            </>
                                        }
                                        hidden={!isComex}
                                    >
                                        <Select
                                            mode="tags"
                                            options={comex_type_options}
                                            suffixIcon={<DownOutlined />}
                                        />
                                    </Form.Item>
                                    {/* <Form.Item
                                        name="radar_limit"
                                        label={
                                            <>
                                                Modalidade radar
                                                <TooltipEfex title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla iaculis non odio at dignissim. Pellentesque venenatis magna diam, vel consectetur odio facilisis ut." />
                                            </>
                                        }
                                        rules={[
                                            {
                                                required: isComex,
                                                message:
                                                    "Selecione a modalidade",
                                            },
                                        ]}
                                        hidden={!isComex}
                                    >
                                        <Select
                                            options={comex_radar_options}
                                            suffixIcon={<DownOutlined />}
                                        />
                                    </Form.Item> */}
                                    <Form.Item
                                        name="operation_mode"
                                        label={
                                            <>
                                                Modo operante
                                                <TooltipEfex title="Por conta própria ou por intermédio de terceiros (trading ou importadora/exportadora)" />
                                            </>
                                        }
                                        className="operation_mode"
                                        hidden={!isComex}
                                        rules={[
                                            {
                                                required: isComex,
                                                message: "Selecione o modo",
                                            },
                                        ]}
                                    >
                                        <Select
                                            mode="tags"
                                            options={comex_operation_options}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        hidden={!isComex}
                                        name="merchandise_description"
                                        label={
                                            <>
                                                Que mercadoria será
                                                exportada/importada:
                                            </>
                                        }
                                        rules={[
                                            {
                                                required: isComex,
                                                message:
                                                    "Informe as mercadorias",
                                            },
                                        ]}
                                    >
                                        <Input.TextArea placeholder="" />
                                    </Form.Item>
                                    <Form.Item
                                        hidden={!isComex}
                                        name="storage_comments"
                                        label={
                                            <>Como é feito o armazenamento</>
                                        }
                                        rules={[
                                            {
                                                required: isComex,
                                                message:
                                                    "Informe como é feito o armazenamento",
                                            },
                                        ]}
                                    >
                                        <Input.TextArea placeholder="" />
                                    </Form.Item>
                                    <Form.Item
                                        name="last_six_months_declarations"
                                        hidden={!isComex}
                                        label={
                                            <>
                                                Tem declarações de exportação e
                                                importação (últimos 6 meses)?
                                            </>
                                        }
                                        rules={[
                                            {
                                                required: isComex,
                                                message: "Seleção Obrigatória.",
                                            },
                                        ]}
                                    >
                                        <Select>
                                            <Option value={true}>Sim</Option>
                                            <Option value={false}>Não</Option>
                                        </Select>
                                    </Form.Item>
                                </IdentedField>
                            </EfexCard>
                        </Col>
                    </Row>
                    <Form.Item className="btn-back-next">
                        {!registerUpdate &&
                            <WhiteButton
                                hoverColor="orange"
                                onClick={() => {
                                    props.history.push(Routes.legalPerson);
                                    scrollUp();
                                }}
                            >
                                Voltar
                            </WhiteButton>}
                        {registerUpdate?.kyc ? (
                            <OrangeButton
                                style={{ fontWeight: "500", width: "100px" }}
                                type="primary"
                                htmlType="submit"
                                loading={
                                    companyExtra.status === StateStatus.loading
                                }
                            >
                                Atualizar
                            </OrangeButton>
                        ) : (
                            <OrangeButton
                                style={{ fontWeight: "500", width: "100px" }}
                                type="primary"
                                htmlType="submit"
                                loading={
                                    companyExtra.status === StateStatus.loading
                                }
                            >
                                {companyExtra.status === StateStatus.loading
                                    ? "Carregando"
                                    : "Próximo"}
                            </OrangeButton>
                        )}

                    </Form.Item>
                </Form>
            </RegisterBaseLayout>
        </>
    );
};

export default LegalPersonExtra;
