import React, { useEffect } from "react";
import { Form, Layout, Row, Divider, message, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";

import "./ChangePassword.scss";
import Routes from "../../routes/routes";
import { changeThunk, selectChange } from "./ChangePasswordSlice";
import StateStatus from '../../utils/status';
import ErrorMsg from '../../common/components/ErrorMsg';
import { useHistory } from "react-router-dom";
import { OrangeButton } from "../../common/components/Button";
import { OrangeLink } from "../../common/components/Link";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const change = useSelector(selectChange);
  const history = useHistory()
  const [form] = Form.useForm()

  useEffect(() => {

    // ser houver mais parametros, precisa alterar a logica
    const token = window.location.hash.split("?token=")[1]

    if (token === undefined || token === null) {
      history.replace("/")
      return
    }

    form.setFieldsValue({ token })

  }, [form, history])

  useEffect(() => {
    if (change.status === StateStatus.succeeded) {
      message.success("Senha alterada com sucesso!", 4)
      history.replace("/")
    }
  }, [change.status, history])

  const onFinish = (values) => {
    dispatch(changeThunk(values));
  };

  return (
    <Layout className="login layout">
      <div className="change-password-container">
        <div className="change-password-content">
          <div className="welcome">Alterar a Senha</div>
          {change.status === StateStatus.failed ? (
            <ErrorMsg message={change.errorMsg}></ErrorMsg>
          ) : null}
          <Form name="basic" onFinish={onFinish} form={form} layout="horizontal">
            <Form.Item
              name="token"
              style={{ display: 'none' }}
              rules={[
                {
                  required: true,
                  message: "Por favor, informe o usuário.",
                },
              ]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              name="new_password"
              rules={[
                {
                  required: true,
                  message: "Por favor, informe o usuário.",
                },
              ]}
            >
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Informe a senha",
                  },
                  () => ({
                    validator(_, value) {
                      if (value != null && value.length >= 8) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Mínimo 8 caracteres.")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Nova senha" />
              </Form.Item>
            </Form.Item>
            <Row justify="center">
              <OrangeButton htmlType="submit" loading={change.status === StateStatus.loading}>
                {change.status === StateStatus.loading ? "Carregando" : "Alterar"}
              </OrangeButton>
            </Row>
          </Form>
          <div style={{ width: "100%" }}>
            <Divider />
          </div>
          <Row justify="end">
            <OrangeLink to={Routes.login}>Voltar</OrangeLink>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default ChangePassword;
