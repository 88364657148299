import StateStatus from "../../utils/status";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { login, profile, putProfile, postChangePassword } from "./LoginService";

const initialState = {
    status: {
        login: StateStatus.idle,
        profile: StateStatus.idle,
        updateProfile: StateStatus.idle,
        updatePassword: StateStatus.idle,
    },
    profile: {},
    errorMsg: null,
};

export const loginThunk = createAsyncThunk("login/loginThunk", async (data) => {
    try {
        const response = await login(data);
        return response.data;
    } catch (err) {
        const customError = {
            name: "Api Error",
            message:
                err.response && err.response.status < 500
                    ? err.response.data.msg
                    : "Erro no sistema, tente mais tarde!",
            data: err.response.data,
        };
        throw customError;
    }
});

export const getProfile = createAsyncThunk("login/profile", async () => {
    const response = await profile();
    return response.data;
});

export const updateProfile = createAsyncThunk(
    "profile/updateProfile",
    async (data) => {
        const response = await putProfile(data);
        return response.data;
    }
);

export const updatePassword = createAsyncThunk(
    "profile/updatePassword",
    async (data) => {
        const response = await postChangePassword(data);
        return response.data;
    }
);

export const loginSlice = createSlice({
    name: "loginSlice",
    initialState: initialState,
    reducers: {
        resetUpdateProfileStatus: (state) => {
            state.status.updateProfile = StateStatus.idle;
        },
        resetPasswordStatus: (state) => {
            state.status.updatePassword = StateStatus.idle;
        },
    },
    extraReducers: {
        [loginThunk.pending]: (state) => {
            state.status.login = StateStatus.loading;
        },
        [loginThunk.fulfilled]: (state, action) => {
            state.status.login = StateStatus.succeeded;
            localStorage.setItem("access_token", action.payload.access_token);
            localStorage.setItem("refresh_token", action.payload.refresh_token);
        },
        [loginThunk.rejected]: (state, action) => {
            state.status.login = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        [getProfile.pending]: (state) => {
            state.status.profile = StateStatus.loading;
        },
        [getProfile.fulfilled]: (state, action) => {
            state.status.profile = StateStatus.succeeded;
            state.profile = action.payload;
        },
        [getProfile.rejected]: (state, action) => {
            state.status.profile = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        [updateProfile.pending]: (state) => {
            state.status.updateProfile = StateStatus.loading;
        },
        [updateProfile.fulfilled]: (state, action) => {
            state.status.updateProfile = StateStatus.succeeded;
            state.profile = action.payload.profile;
        },
        [updateProfile.rejected]: (state, action) => {
            state.status.updateProfile = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        [updatePassword.pending]: (state) => {
            state.status.updatePassword = StateStatus.loading;
        },
        [updatePassword.fulfilled]: (state, action) => {
            state.status.updatePassword = StateStatus.succeeded;
        },
        [updatePassword.rejected]: (state, action) => {
            state.status.updatePassword = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
    },
});

export const { resetUpdateProfileStatus, resetPasswordStatus } = loginSlice.actions;

export const selectLogin = (state) => state.login;
