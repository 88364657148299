import { Select, Divider } from "antd"
import React, { useState } from "react"
import { BanksToReceive, currency } from "../../../utils/consts"
import PropTypes from "prop-types"

const SelectAccountToReceive = ({ user }) => {
    const [selectedCurrency, setSelectedCurrency] = useState(null)

    return (
        <>
            <Select onSelect={(currency) => { setSelectedCurrency(currency) }} style={{ width: "100%" }} placeholder="Selecione uma moeda">
                {
                    currency.map(item => {
                        return (<Select.Option
                            value={item.value}
                            key={item.value}
                        >
                            {item.label}
                        </Select.Option>)

                    })
                }
            </Select>
            <br />
            <br />
            {
                selectedCurrency ? <BanksToReceive currency={selectedCurrency} user={user} />
                    : null

            }
        </>
    )
}

SelectAccountToReceive.propTypes = {
    user: PropTypes.object.isRequired
}

export default SelectAccountToReceive