import api from "../../service/api"
import apiRoutes from "../../service/apiRoutes"
import qs from "qs";

export const postBankAccountReceive = async (data) => api.post(`${apiRoutes.bankAccountReceive}`, data)
export const getBankAccountReceiveList = async (cpf_cnpj) => api.get(`${apiRoutes.bankAccountReceive}/list?cpf_cnpj=${cpf_cnpj}`)
export const getBankAccountReceive = async (cpf_cnpj, id) => api.get(`${apiRoutes.bankAccountReceive}?cpf_cnpj=${cpf_cnpj}&id=${id}`)
export const deleteBankAccountReceive = async (cpf_cnpj, id) => api.delete(`${apiRoutes.bankAccountReceive}?cpf_cnpj=${cpf_cnpj}&id=${id}`)
export const putBankAccountReceive = async (data) => api.put(`${apiRoutes.bankAccountReceive}`, data)

const paramsSerializer = params => { return qs.stringify(params) }

export const getReceiveValueList = async (params) => api.get(`${apiRoutes.receiveValue}s`, { params })
export const getSendValueList = async (params) => api.get(`${apiRoutes.sendValues}`, { params })
export const getTransactions = (params) => api.get(apiRoutes.transactions, { params, paramsSerializer })

// Send Files
export const postCreateFile = async (formData, config) => api.post(`${apiRoutes.files}`, formData, config)
export const deleteExchangeFile = async (cpf_cnpj, file_id) => api.delete(`${apiRoutes.files}?cpf_cnpj=${cpf_cnpj}&file_id=${file_id}`)

export const linkToSignedDoc = async (fileId) => api.get(`${apiRoutes.downloadClickSign}?file_id=${fileId}`)

// delete operations
export const deleteSendValues = async (cpf_cnpj, send_value_id) => api.patch(apiRoutes.sendValue, { cpf_cnpj, send_value_id })
export const softDeleteSendValues = async (data) => api.patch(apiRoutes.sendValue, data)
export const postMakeSeveralSendValueExchanges = async (data) => api.post(`${apiRoutes.sendValue}close_multiple_exchanges/`, data)
export const postMakeSeveralReceiveValueExchanges = async (data) => api.put(`${apiRoutes.receiveValue}/close_multiple_exchanges/`, data)
export const patchReceiveValues = async (cpf_cnpj, receive_value_id) => api.patch(apiRoutes.receiveValue, { params: { cpf_cnpj, receive_value_id } })
export const deleteReceiveValues = async (cpf_cnpj, receive_value_id) => api.patch(apiRoutes.receiveValue, { cpf_cnpj, receive_value_id })
export const softDeleteReceiveValues = async (data) => api.patch(apiRoutes.receiveValue, data)

export const patchPersonContacts = (data) => api.patch(`${apiRoutes.personProfile}update_contacts/`, data)
export const patchCompanyContacts = (data) => api.patch(`${apiRoutes.companyProfile}/update_contacts/`, data)

export const editPersonData = (data) => api.post(`${apiRoutes.personProfile}update`, data)
export const editCompanyData = (data) => api.post(`${apiRoutes.companyProfile}/update/`, data)

