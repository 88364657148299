import React, { useEffect, useState } from "react";

import { Form, Col, Row, Upload, Typography, message, Divider, notification } from "antd";

import { CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import ErroMsg from "../../common/components/ErrorMsg";
import StateStatus from "../../utils/status";
import {
  getDocumentsSlice,
  sendDocumentsSlice,
  selectDocuments,
  getQsaDocumentsThunk,
  sendQsaDocumentsThunk,
  removeDoc,
  removeQsaDoc,
  getCheckDocs,
} from "./UploadFilesSlice";

import "./UploadFiles.scss";
import RegisterBaseLayout from "../../common/components/RegisterBaseLayout/RegisterBaseLayout";
import LoadingIcon from "../../common/components/loading_icon/loading_icon";
import { useHistory } from "react-router"
import Routes from "../../routes/routes";
import { GreyButton, OrangeButton } from "../../common/components/Button";
import { handleResize } from "../../utils/responsible";
import { EfexCard } from "../../common/components/Card";
import TooltipEfex from "../../common/components/Tooltip";
import { selectCompany } from "../ChooseCompany/ChooseCompanySlice";

const { Title } = Typography;

const beforeUpload = (file) => {
  const isLt20M = file.size / 1024 / 1024 < 20;
  if (!isLt20M) {
    message.error("O Arquivo precisa ser menor que 20Mb!", 5)
    return false
  }
  return true
}

const UploadFiles = (props) => {
  const documents = useSelector(selectDocuments);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQsaDocumentsThunk());
    dispatch(getDocumentsSlice());
  }, [dispatch]);

  return (
    <RegisterBaseLayout
      {...props}
      titleL3="Upload dos documentos"
      text={
        <div>
          <p>
            Envie os documentos necessários para finalizar o cadastro da empresa.
          </p>
          <p>
            Cuidados ao enviar os arquivos:
            <br />
            - verifique se o arquivo está totalmente legível.
            <br />
            - formatos de arquivos aceitos: PDF, JPG, JPEG
            <br />
            - verifique se o arquivo enviado está em conformidade com a descrição de cada documento"
          </p>
        </div>
      }
      current={5}
    >

      {documents.status.getDocs === StateStatus.loading ||
        documents.status.getQsaDocs === StateStatus.loading ? (
        <Row className="row-loading">
          <LoadingIcon color="orange" />
        </Row>
      ) : (
        <ContentUpload />
      )}
    </RegisterBaseLayout>
  );
};

export default UploadFiles;

const ContentUpload = () => {
  const documents = useSelector(selectDocuments);
  const [isResponsible, updateResponsible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory()
  const [form] = Form.useForm()
  const [registerUpdate, setRegisterUpdate] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const selectedCompany = useSelector(selectCompany);

  useEffect(() => {
    if (selectedCompany.status?.getCompanyByCnpj === "succeeded") {
      setRegisterUpdate(selectedCompany.company.register_update[0])
    }
  }, [selectedCompany])

  useEffect(() => {
    window.addEventListener('resize', () => handleResize(updateResponsible))
    handleResize(updateResponsible)
  }, [])

  const customRequest = ({
    file,
    data,
    onProgress,
    onSuccess,
    onError
  }) => {
    setIsUploading(true)
    var formData = new FormData();
    formData.append("file", file);
    formData.append("doc_type", data.doc_type);
    registerUpdate ? formData.append("is_uploading", true) : formData.append("is_uploading", false)
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    dispatch(
      sendDocumentsSlice({
        formData,
        config,
        onSuccess: () => {
          onSuccess("Ok");
          setIsUploading(false);
          message.success(`documento enviado com sucesso.`)
        },
        onError: (err) => {
          onError({ err });
          setIsUploading(false);
          message.error(`falha no envio do documento.`)
        },
      })
    );
  };

  const customRequestQsa = ({ file, data, onProgress, onSuccess, onError }) => {
    setIsUploading(true)
    var formData = new FormData();
    formData.append("file", file);
    formData.append("doc_type", data.doc_type);
    formData.append("qsa_id", data.qsa_id);
    registerUpdate ? formData.append("is_uploading", true) : formData.append("is_uploading", false)
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    dispatch(
      sendQsaDocumentsThunk({
        formData,
        config,
        onSuccess: () => {
          onSuccess("Ok");
          setIsUploading(false);
          message.success(`documento enviado com sucesso.`)
        },
        onError: (err) => {
          onError({ err });
          setIsUploading(false);
          message.error(`falha no envio do documento.`)
        },
      })
    );
  };

  return (
    <>
      <Form form={form} className="content-upload" >
        <Row gutter={[8, 8]}>
          <Row>
            {documents.status.sendDocs === StateStatus.failed ? (
              <ErroMsg message={documents.errorMsg} />
            ) : null}
            {documents.status.sendQsaDocs === StateStatus.failed ? (
              <ErroMsg message={documents.errorMsg} />
            ) : null}
          </Row>
          {documents.docs !== null &&
            documents.docs.docs_to_send.length === 0 &&
            documents.qsaDocs.docs_to_send.length === 0
            ? "Todos os documentos foram submetidos"
            : null}

          {documents.docs !== null && documents.docs.docs_to_send.length !== 0 ? (

            <>
              <br />
              <EfexCard>
                <Row><Title level={4}>Empresa</Title></Row>
                <Divider />
                {documents.docs.docs_to_send.map((doc, key) => {
                  if (isResponsible) {
                    return (
                      <Col>
                        <div className="document-title">
                          {doc.value}
                          {" "}
                          {
                            doc.key === 'proof-of-address' ? <a href="files/declaracao.docx" target="_blank">Baixar declaração</a> : null
                          }
                        </div>
                        <Form.Item
                          key={key}
                          style={{ marginTop: "8px" }}
                          name={([key], doc.key)}
                          rules={[
                            {
                              required: true,
                              message: "Documento obrigatório",
                            },
                          ]}
                        >

                          <Upload
                            key={doc.key}
                            beforeUpload={beforeUpload}
                            data={{ doc_type: doc.key }}
                            multiple={true}
                            // maxCount={1}
                            accept=".pdf, .png, .jpg, .jpeg"
                            customRequest={customRequest}
                            onRemove={(file) => {
                              var formData = new FormData();
                              formData.append("file", file);
                              formData.append("doc_type", doc.key);
                              const config = {
                                headers: {
                                  "Content-Type": "multipart/form-data",
                                },
                                data: formData
                              };
                              dispatch(removeDoc(config))
                            }}
                            showUploadList={{
                              showDownloadIcon: false,
                              showPreviewIcon: false,
                              showRemoveIcon: true,
                              removeIcon: (<>
                                <span style={{ width: '150px' }}>
                                  <CloseCircleOutlined style={{ marginRight: '5px' }} />
                                  Excluir Documento
                                </span>
                              </>),
                            }}
                          >
                            <GreyButton
                              disabled={isUploading}
                              style={{ fontSize: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "125px", paddingLeft: "15px" }}>
                              Enviar documento<UploadOutlined />
                            </GreyButton>
                          </Upload>
                        </Form.Item>
                      </Col>
                    );
                  }

                  return (
                    <Col>
                      <Form.Item
                        key={key}
                        style={{ marginTop: "8px" }}
                        name={([key], doc.key)}
                        rules={[
                          {
                            required: true,
                            message: "Documento obrigatório",
                          },
                        ]}
                      >

                        <Upload
                          key={doc.key}
                          beforeUpload={beforeUpload}
                          data={{ doc_type: doc.key }}
                          // maxCount={1}
                          multiple={true}
                          accept=".pdf, .png, .jpg, .jpeg"
                          customRequest={customRequest}
                          onRemove={(file) => {
                            var formData = new FormData();
                            formData.append("file", file);
                            formData.append("doc_type", doc.key);
                            const config = {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                              data: formData
                            };
                            dispatch(removeDoc(config))
                          }}
                        >
                          <div className="document-title">
                            {doc.value}
                            {" "}
                            {doc.tooltip && <TooltipEfex title={doc.tooltip} />}
                            {
                              doc.key === 'proof-of-addr' ? <a href="files/declaracao.docx" target="_blank">(Baixar declaração)</a> : null
                            }
                          </div>
                          <GreyButton
                            disabled={isUploading}
                            style={{ fontSize: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "125px", paddingLeft: "15px" }}
                          >
                            Enviar documento{" "}<UploadOutlined />
                          </GreyButton>
                        </Upload>
                      </Form.Item>
                    </Col>
                  );

                })}
              </EfexCard>

            </>
          ) : null}

          {documents.qsaDocs === null ||
            documents.qsaDocs.docs_to_send.length === 0 ? null : (
            // <Col span={12} className="responsaveis">
            <>
              <EfexCard>
                <Row><Title level={4}>Responsável(eis)</Title></Row>
                <Divider />
                {documents.qsaDocs.docs_to_send != null
                  ? documents.qsaDocs.docs_to_send.map((doc, key) => {
                    if (isResponsible) {
                      return (
                        <>
                          <Title level={5} className="grey-title">{doc.key}</Title>
                          {doc.value.map((val, key) => {
                            return (
                              <Row>
                                <Col>
                                  <div className="document-title">{val.key}</div>
                                  <Form.Item
                                    key={key}
                                    style={{ marginTop: "8px" }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Documento obrigatório",
                                      },
                                    ]}
                                  >
                                    <Upload
                                      key={key}
                                      beforeUpload={beforeUpload}
                                      data={{ doc_type: val.value, qsa_id: doc.qsa_id }}
                                      // maxCount={1}
                                      multiple={true}
                                      accept=".pdf, .png, .jpg, .jpeg"
                                      customRequest={customRequestQsa}
                                      onRemove={(file) => {
                                        var formData = new FormData();
                                        formData.append("file", file);
                                        formData.append("doc_type", val.value);
                                        formData.append("qsa_id", doc.qsa_id);
                                        const config = {
                                          headers: {
                                            "Content-Type": "multipart/form-data",
                                          },
                                          data: formData
                                        };
                                        dispatch(removeQsaDoc(config))
                                      }}
                                    >
                                      <GreyButton
                                        disabled={isUploading}
                                        style={{ fontSize: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "125px", paddingLeft: "15px" }}>
                                        Enviar documento{" "}<UploadOutlined />
                                      </GreyButton>
                                    </Upload>
                                  </Form.Item>
                                </Col>
                                <div style={{ height: "70px" }} />
                              </Row>
                            );
                          })}
                        </>
                      );
                    }
                    return (
                      <>
                        <Title level={5} className="grey-title">{doc.key}</Title>
                        {doc.value.map((val, key) => {
                          return (
                            <Form.Item
                              key={key}
                              style={{ marginTop: "8px" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Documento obrigatório",
                                },
                              ]}
                            >
                              <Upload
                                key={key}
                                beforeUpload={beforeUpload}
                                data={{ doc_type: val.value, qsa_id: doc.qsa_id }}
                                // maxCount={1}
                                multiple={true}
                                accept=".pdf, .png, .jpg, .jpeg"
                                customRequest={customRequestQsa}
                                onRemove={(file) => {
                                  var formData = new FormData();
                                  formData.append("file", file);
                                  formData.append("doc_type", val.value);
                                  formData.append("qsa_id", doc.qsa_id);
                                  const config = {
                                    headers: {
                                      "Content-Type": "multipart/form-data",
                                    },
                                    data: formData
                                  };
                                  dispatch(removeQsaDoc(config))
                                }}
                              >
                                <div className="document-title">
                                  {val.key}
                                  {val.tooltip && <TooltipEfex title={val.tooltip} />}
                                </div>
                                <GreyButton
                                  disabled={isUploading}
                                  style={{ fontSize: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "125px", paddingLeft: "15px" }}>
                                  Enviar documento{" "}<UploadOutlined />
                                </GreyButton>
                              </Upload>
                            </Form.Item>
                          );
                        })}
                      </>
                    );
                  })
                  : null}
              </EfexCard>

            </>
          )}
        </Row>
      </Form>
      <br />
      <Row justify="end">
        <OrangeButton
          type="primary"
          htmlType="submit"
          loading={documents.status.getCheckDocs === StateStatus.loading}
          onClick={() => {
            const isUploading = registerUpdate ? true : false;
            dispatch(getCheckDocs(isUploading)).then((value) => {
              form.resetFields()
              if (value.payload.docs.docs_to_send.length === 0 && value.payload.qsaDocs.docs_to_send.length === 0) {
                history.replace({
                  pathname: Routes.finishedRegister,
                  state: { type: "company" }
                })
              } else {
                notification.warning({
                  message: `Atenção!`,
                  description: "Envie todos os documentos necessários.",
                  placement: "topRight",
                });
              }
            });
          }}
        >
          {documents.status === StateStatus.loading ? "Carregando" : "Próximo"}
        </OrangeButton>
      </Row>
    </>
  );
};
