import profileIcon from '../../../assets/person.png'

const ProfileIcon = () => {
    return (
        <>
            <img className="custom-icon" src={profileIcon} />
        </>
    )
}

export default ProfileIcon