import { createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from "../../../utils/status"

import {
	postCreateOperation,
	putEditOperation,
	getSendOperation,
	postMakeExchange,
	getRatesSendValue,
	postSendDepositReceipt,
} from './service'

export const createOperation = createAsyncThunk(
	'sendValues/createOperation',
	async (data) => {
		const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;
		data.cpf_cnpj = cpf_cnpj;
		return await postCreateOperation(data);
	},
)

export const editOperation = createAsyncThunk(
	'sendValues/editOperation',
	async (data) => {
		const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;
		data.cpf_cnpj = cpf_cnpj;
		return await putEditOperation(data);
	},
)

export const getOperation = createAsyncThunk(
	'sendValues/getOperation',
	async ({ sendValueId }) => {
		const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;

		const response = await getSendOperation(cpf_cnpj, sendValueId);
		return response.data
	},
)

export const getOperationBackground = createAsyncThunk(
	'sendValues/getOperationBackground',
	async ({ sendValueId }) => {
		const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;

		const response = await getSendOperation(cpf_cnpj, sendValueId);
		return response.data
	},
)

export const makeExchange = createAsyncThunk(
	'sendValues/makeExchange',
	async (data) => {
		const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;
		data.cpf_cnpj = cpf_cnpj;
		return await postMakeExchange(data);
	},
)

export const getRatesSendValues = createAsyncThunk(
	'sendValues/getRates',
	async ({ currency, send_value_id, transaction_day }) => {
		const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;
		var params = { cpf_cnpj }
		if (currency !== undefined) {
			params = { ...params, currency }
		}
		if (transaction_day !== undefined) {
			params = { ...params, transaction_day }
		}
		if (send_value_id !== undefined) {
			params = { ...params, send_value_id }
		}
		const response = await getRatesSendValue(params);
		return response.data
	}
)

export const sendDepositReceipt = createAsyncThunk(
	'sendValues/sendDepositReceipt',
	async (data) => {
		const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;

		data.cpf_cnpj = cpf_cnpj
		return await postSendDepositReceipt(data);
	}
)

export const sendValuesReducers = {
	[sendDepositReceipt.pending]: (state) => {
		state.status.sendDepositReceipt = StateStatus.loading;
	},
	[sendDepositReceipt.fulfilled]: (state, action) => {
		state.status.sendDepositReceipt = StateStatus.succeeded;
		state.sendValue = action.payload;
	},
	[sendDepositReceipt.rejected]: (state, action) => {
		state.status.sendDepositReceipt = StateStatus.failed;
		state.errorMsg = action.error.message;
	},
}