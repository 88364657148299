
import React, { useState } from "react";
import "./styles.scss";
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const Input = (props) => {
    const [isPassWord, setIsPassWord] = useState(true)
    return (
        <div style={{ width: props.width || null, backgroundColor: props.disabled ? "#f5f5f5" : "white" }} className="efex-input">
            {props.prefix ? <div className="efex-input-prefix">{props.prefix}</div> : null}
            <input {...props} type={props.type === "password" ? isPassWord ? "password" : "text" : props.type} />
            {props.type === "password" ? <div onClick={() => setIsPassWord(!isPassWord)} className="efex-input-suffix">{isPassWord ? <EyeInvisibleOutlined /> : <EyeOutlined />}</div> : null}
        </div>
    )

}

export default Input;