import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StateStatus from "../../../utils/status";
import { postAcceptReview } from "./service";

const initialState = {
	status: {
        acceptReview: StateStatus.idle,
        getAcceptReview: StateStatus.idle
	},
	reviewData: null,
	errorMsg: null,
};

export const acceptReview = createAsyncThunk(
	"thirdStep/acceptReview",
	async ({cpf}) => {
		const response = await postAcceptReview(cpf);
		return response.data;
	},
);

export const thirdStep = createSlice({
	name: "thirdStep",
	initialState,
	extraReducers: {
		[acceptReview.pending]: (state) => {
			state.status.acceptReview = StateStatus.loading;
		},
		[acceptReview.fulfilled]: (state) => {
			state.status.acceptReview = StateStatus.succeeded;
		},
		[acceptReview.rejected]: (state, action) => {
			state.status.acceptReview = StateStatus.failed;
			state.errorMsg = action.error.message;
		}
	},
});

export const selectThirdStep = (state) => state.thirdStep;
