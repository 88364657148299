import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Typography,
    Form,
    Row,
    Col,
    Card,
    Space,
    Timeline,
    notification,
    message,
    Divider,
} from "antd";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import ContactReceiveCard from "../../Contacts/ContactsSend/components/ContactSendCard/index.js";
import StateStatus from "../../../../utils/status.js";

import {
    linkToSignedDocThunk,
    resetLinkToSignedFilesStatus,
    resetSendValue,
    selectExchange,
} from "../../slice.js";
import "./styles.scss";
import {
    getOperation,
    getOperationBackground,
    makeExchange,
    sendDepositReceipt,
} from "../thunks.js";
import { useParams, useRouteMatch } from "react-router-dom";
import api from "../../../../service/api.js";
import apiRoutes from "../../../../service/apiRoutes.js";
import PriceSendValue from "../../../../common/components/PriceSend/index.js";
import { history } from "../../../../helpers/history.js";
import { DepositReceiptsModal } from "../_components/DepositReceiptsModal/index.js";
import { sendValueNatures } from "../../../../utils/consts.js";
import {
    GreenButton,
    OrangeButton,
} from "../../../../common/components/Button/index.js";
import { isCommercialHourRange } from "../../../../utils/date.js";
import TooltipEfex from "../../../../common/components/Tooltip/index.js";
import EfexModal from "../../../../common/components/Modal/index.js";
import CardNotice from "../../../../common/components/CardNotice/index.jsx";

const DownloadFileLink = ({ onClick, name, loading }) => {
    return (
        <div style={{ cursor: "pointer" }}>
            {name}{" "}
            {loading ? (
                <LoadingOutlined spin />
            ) : (
                <DownloadOutlined onClick={onClick} />
            )}
        </div>
    );
};

const SendValueApproved = () => {
    const { sendValueId } = useParams();
    const { url } = useRouteMatch();
    const [form] = Form.useForm();
    const exchange = useSelector(selectExchange);
    const dispatch = useDispatch();
    const [downloadState, updateDownloadState] = useState({});
    const [taxes, setTaxes] = useState([]);
    const [proofs, setProofs] = useState([]);
    const [receipts, setReceipts] = useState([]);
    const [showReceiptsModal, setShowReceiptsModal] = useState(false);
    const [commercialHour, setCommercialHour] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalAproved, setShowModalAproved] = useState(false);
    const [showModalSignature, setshowModalSignature] = useState(false);
    const [timeout, updateTimeOut] = useState(0);
    const [timer, updateTimer] = useState(5);
    const [selectedSendDate, setSelectedSendDate] = useState(null);
    const [showApprovedMsg, setShowApprovedMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [thereCurrency, setThereCurrency] = useState(false)

    useEffect(() => {
        if (exchange.sendValue?.status === "waiting_sign_doc") {
            setshowModalSignature(true);
        }
    }, [exchange.sendValue?.status]);

    // useEffect(() => {
    // 	if(exchange.sendValue.history?.length === 1){
    // 		setShowModal(true)
    // 	}else if(exchange.sendValue.history?.length === 2){
    // 		setShowModalAproved(true)
    // 	}
    // }, [exchange.sendValue])

    useEffect(() => {
        isCommercialHourRange().then((response) => {
            setCommercialHour(response.data.is_open_to_exchange);
        });
    }, []);

    useEffect(() => {
        if (sendValueId) {
            if (timer > 0) {
                clearTimeout(timeout);
                updateTimeOut(
                    setTimeout(() => {
                        updateTimer(timer - 1);
                    }, 1000)
                );
            } else {
                isCommercialHourRange().then((response) => {
                    setCommercialHour(response.data.is_open_to_exchange);
                });
                dispatch(getOperationBackground({ sendValueId: sendValueId }));
                updateTimer(5);
            }
        }
    }, [timer, sendValueId]);

    useEffect(() => {
        dispatch(resetSendValue());
        message.loading({ key: 1, content: "Carregando..." });
        dispatch(getOperation({ sendValueId: sendValueId }));
    }, [sendValueId]);

    // gera o estado para saber se o arquivo em especifico está sendo feito o download ou não
    useEffect(() => {
        if (exchange.sendValue.files) {
            const fileStates = {};
            exchange.sendValue.files.forEach((item) => {
                fileStates[item.id] = false;
            });
            updateDownloadState(fileStates);
        }
    }, []);

    useEffect(() => {
        if (
            exchange.status.getOperation === StateStatus.succeeded &&
            parseInt(exchange.sendValue.id) === parseInt(sendValueId)
        ) {
            // gera urls para fazer download do contrato de cambio
            if (exchange.sendValue && exchange.sendValue.contract_id) {
                dispatch(linkToSignedDocThunk(exchange.sendValue.contract_id));
            } else {
                dispatch(resetLinkToSignedFilesStatus());
            }

            if (
                exchange.sendValue.status === "draft" ||
                exchange.sendValue.status === "need_changes"
            ) {
                history.replace(
                    url.replace("operacao-envio", "enviar-valores")
                );
                return;
            }

            if (exchange.status.getOperationBackground === StateStatus.idle) {
                message.success({ key: 1, content: "Concluído!" });
            }

            form.setFieldsValue({
                target_value: exchange.sendValue.is_currency_target
                    ? exchange.sendValue.target_value
                    : parseInt(
                        (
                            exchange.sendValue.target_value /
                            exchange.rates.rate
                        ).toFixed(2) * 100
                    ),
                value: !exchange.sendValue.is_currency_target
                    ? exchange.sendValue.target_value
                    : parseInt(
                        (
                            (exchange.sendValue.target_value *
                                exchange.rates.rate) /
                            100
                        ).toFixed(2)
                    ),
            });

            setProofs(
                exchange.sendValue.receipts.filter(
                    (item) => item.origin === "client"
                )
            );

            setReceipts(
                exchange.sendValue.receipts.filter(
                    (item) => item.origin === "backoffice"
                )
            );

            const nature = sendValueNatures.filter(
                (item) => item.name === exchange.sendValue.nature
            )[0];

            if (exchange.sendValue.taxes.length === 0) {
                addTaxesByNature(nature);
            } else {
                setTaxes(exchange.sendValue.taxes);
            }
        }
    }, [exchange.status.getOperation, exchange.sendValue]);

    useEffect(() => {
        if (
            exchange.status.getOperationBackground === StateStatus.succeeded &&
            parseInt(exchange.sendValue.id) === parseInt(sendValueId)
        ) {
            // gera urls para fazer download do contrato de cambio
            if (exchange.sendValue && exchange.sendValue.contract_id) {
                dispatch(linkToSignedDocThunk(exchange.sendValue.contract_id));
            } else {
                dispatch(resetLinkToSignedFilesStatus());
            }

            if (
                exchange.sendValue.status === "draft" ||
                exchange.sendValue.status === "need_changes"
            ) {
                history.replace(
                    url.replace("operacao-envio", "enviar-valores")
                );
                return;
            }

            if (exchange.sendValue.status === "approved" && !showApprovedMsg) {
                notification.success({
                    message: `Aprovado!`,
                    description: "Você já pode fechar o câmbio.",
                    placement: "topRight",
                });
                setShowApprovedMsg(true);
            }

            form.setFieldsValue({
                target_value: exchange.sendValue.is_currency_target
                    ? exchange.sendValue.target_value
                    : parseInt(
                        (
                            exchange.sendValue.target_value /
                            exchange.rates.rate
                        ).toFixed(2) * 100
                    ),
                value: !exchange.sendValue.is_currency_target
                    ? exchange.sendValue.target_value
                    : parseInt(
                        (
                            (exchange.sendValue.target_value *
                                exchange.rates.rate) /
                            100
                        ).toFixed(2)
                    ),
            });

            setProofs(
                exchange.sendValue.receipts.filter(
                    (item) => item.origin === "client"
                )
            );

            setReceipts(
                exchange.sendValue.receipts.filter(
                    (item) => item.origin === "backoffice"
                )
            );

            const nature = sendValueNatures.filter(
                (item) => item.name === exchange.sendValue.nature
            )[0];

            if (exchange.sendValue.taxes.length === 0) {
                addTaxesByNature(nature);
            } else {
                setTaxes(exchange.sendValue.taxes);
            }
        }
    }, [exchange.status.getOperationBackground, exchange.sendValue]);

    // useEffect(() => {
    //     if (exchange.sendValue && exchange.sendValue.recipient_account && exchange.sendValue.recipient_account.currency && exchange.sendValue.recipient_account.currency === "USD" && !selectedSendDate) {
    //         setSelectedSendDate("D1")
    //     }
    // }, [exchange.sendValue]);

    const addTaxesByNature = (nature) => {
        const taxes = [];

        if (nature.taxes.IOF > 0) {
            taxes.push({
                name: "IOF",
                tax: nature.taxes.IOF,
                description: "Imposto",
            });
        }

        if (nature.taxes.IR > 0) {
            taxes.push({
                name: "IR",
                tax: nature.taxes.IR,
                description: "Imposto de Renda",
            });
        }

        setTaxes(taxes);
    };

    const onCloseOperation = () => {
        var data = {};
        data.send_value_id = exchange.sendValue.id;
        data.transaction_day = selectedSendDate;
        data.currency = exchange.sendValue.recipient_account.currency
        dispatch(makeExchange(data)).then(({ payload, error }) => {
            if (payload !== undefined) {
                setShowReceiptsModal(true);
            } else {
                notification.error({
                    message: `Erro!`,
                    description: error.message,
                    placement: "topRight",
                    duration: 8
                });
            }
        });
    };

    const downloadFile = (fileId, cpfCnpj, fileName) => {
        const newState = {};
        newState[fileId] = true;
        updateDownloadState({ ...downloadState, ...newState });
        api.get(apiRoutes.files, {
            params: { file_id: fileId, cpf_cnpj: cpfCnpj },
            responseType: "blob",
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            const newState = {};
            newState[fileId] = false;
            updateDownloadState({ newState, ...downloadState });
            setTimeout(() => {
                link.parentNode.removeChild(link);
            }, 10000);
        });
    };

    const onSendDepositReceipt = (files) => {
        var data = {};
        data.send_value_id = exchange.sendValue.id;
        data.files = files.map((item) => item.uid);
        dispatch(sendDepositReceipt(data)).then(({ payload, error }) => {
            if (payload !== undefined) {
                setShowReceiptsModal(false);
            } else {
                notification.error({
                    message: `Erro!`,
                    description: error.message,
                    placement: "topRight",
                });
            }
        });
    };

    return (
        <Col className="send-values-approved">
            {exchange.status.getOperation === StateStatus.succeeded && (
                <Form layout="vertical" form={form}>
                    <Row>
                        <Card style={{ width: "100%" }}>
                            <Col>
                                <Typography.Title level={5}>
                                    {
                                        <>
                                            Andamento
                                            {exchange.sendValue.history.length ===
                                                1 && (
                                                    <TooltipEfex
                                                        title="Aqui você acompanha o status da sua remessa enquanto estiver em análise. 
									Não se preocupe, enviaremos um e-mail assim que estiver aprovada para o fechamento do câmbio."
                                                    />
                                                )}
                                        </>
                                    }
                                </Typography.Title>
                                <br />
                                <Timeline>
                                    {exchange.sendValue !== undefined &&
                                        exchange.sendValue.history &&
                                        exchange.sendValue.history.slice(0).reverse().map((item) => {
                                            return (
                                                <Timeline.Item
                                                    color={
                                                        item.action_type ===
                                                            "negative"
                                                            ? "red"
                                                            : item.action_type ===
                                                                "neutral"
                                                                ? "grey"
                                                                : "green"
                                                    }
                                                >
                                                    {item.text} - {item.created_at}
                                                </Timeline.Item>
                                            );
                                        })}
                                </Timeline>
                            </Col>
                            {exchange.sendValue.comments ? (
                                <>
                                    <Divider />
                                    <Col>
                                        <Typography.Title level={5} style={{ marginTop: "5px" }}>
                                            Observações:
                                        </Typography.Title>
                                        <br />
                                        <Typography.Text>
                                            {exchange.sendValue.comments}
                                        </Typography.Text>
                                    </Col>
                                </>
                            ) : null}
                        </Card>
                    </Row>
                    <br />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={16}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={10}>
                                    <Card
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <Typography.Title level={5}>
                                            Dados de envio
                                        </Typography.Title>
                                        <br />
                                        <div>
                                            <strong>
                                                Motivo da operação informado:
                                            </strong>
                                            <div>
                                                {
                                                    exchange.sendValue
                                                        .nature_display
                                                }
                                            </div>
                                        </div>
                                        <br />
                                        {exchange.sendValue?.observation ?
                                            <div>
                                                <strong>
                                                    Observações:
                                                </strong>
                                                <div>
                                                    {
                                                        exchange.sendValue
                                                            .observation
                                                    }
                                                </div>
                                            </div> : null
                                        }
                                        <br />
                                        <ul>
                                            {["services"].includes(
                                                exchange.sendValue.nature
                                            ) && (
                                                    <li>
                                                        {
                                                            exchange.sendValue
                                                                .has_payment_relationship_display
                                                        }
                                                    </li>
                                                )}
                                            {[
                                                "finance",
                                                "services",
                                                "other",
                                            ].includes(
                                                exchange.sendValue.nature
                                            ) && (
                                                    <li>
                                                        {
                                                            exchange.sendValue
                                                                .is_tax_by_recipient_display
                                                        }
                                                    </li>
                                                )}

                                            {["commercial"].includes(
                                                exchange.sendValue.nature
                                            ) && (
                                                    <li>
                                                        {
                                                            exchange.sendValue
                                                                .has_advance_payment_display
                                                        }
                                                    </li>
                                                )}
                                            {["services"].includes(
                                                exchange.sendValue.nature
                                            ) && (
                                                    <li>
                                                        {
                                                            exchange.sendValue
                                                                .has_capital_gain_display
                                                        }
                                                    </li>
                                                )}
                                        </ul>
                                        <br />
                                        {exchange.sendValue.nature_exchange !==
                                            null && (
                                                <div>
                                                    <strong>
                                                        Natureza de câmbio definida
                                                        pela efexHub:
                                                    </strong>
                                                    <div>
                                                        {
                                                            exchange.sendValue
                                                                .nature_exchange
                                                        }{" "}
                                                        -{" "}
                                                        {
                                                            exchange.sendValue
                                                                .nature_display
                                                        }
                                                    </div>
                                                    <br />
                                                </div>
                                            )}
                                    </Card>
                                </Col>
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={14}
                                    className="stretch-column"
                                >
                                    {exchange.sendValue.recipient && (
                                        <ContactReceiveCard
                                            contact={
                                                exchange.sendValue.recipient
                                            }
                                            account={[
                                                exchange.sendValue
                                                    .recipient_account,
                                            ]}
                                        />
                                    )}
                                </Col>
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    className="stretch-column"
                                >
                                    {exchange.sendValue?.files.length !== 0 &&
                                        exchange.sendValue.status !==
                                        "waiting_approval" && (
                                            <Card>
                                                <Typography.Title level={4}>
                                                    Arquivos
                                                </Typography.Title>
                                                <br />
                                                <Row gutter={[16, 16]}>
                                                    <Col sm={12}>
                                                        <Typography.Title
                                                            level={5}
                                                        >
                                                            Anexos
                                                        </Typography.Title>

                                                        <div>
                                                            {exchange.sendValue
                                                                .files &&
                                                                exchange.sendValue
                                                                    .files.length >
                                                                0 ? (
                                                                exchange.sendValue.files.map(
                                                                    (
                                                                        item,
                                                                        key
                                                                    ) => {
                                                                        return (
                                                                            <DownloadFileLink
                                                                                key={
                                                                                    key
                                                                                }
                                                                                onClick={() => {
                                                                                    downloadFile(
                                                                                        item.id,
                                                                                        exchange
                                                                                            .owner
                                                                                            .cpf,
                                                                                        item.file_name
                                                                                    );
                                                                                }}
                                                                                name={
                                                                                    item.file_name
                                                                                }
                                                                                loading={
                                                                                    downloadState[
                                                                                    item
                                                                                        .id
                                                                                    ]
                                                                                }
                                                                            />
                                                                        );
                                                                    }
                                                                )
                                                            ) : (
                                                                <p>
                                                                    Nenhum
                                                                    arquivo
                                                                    anexado
                                                                </p>
                                                            )}
                                                        </div>
                                                        {proofs.length ===
                                                            0 ? null : (
                                                            <>
                                                                <br />
                                                                <Typography.Title
                                                                    level={5}
                                                                >
                                                                    Comprovante
                                                                    de Depósito
                                                                </Typography.Title>
                                                                <div>
                                                                    {proofs.map(
                                                                        (
                                                                            item,
                                                                            key
                                                                        ) => {
                                                                            return (
                                                                                <DownloadFileLink
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                    onClick={() => {
                                                                                        downloadFile(
                                                                                            item.id,
                                                                                            exchange
                                                                                                .owner
                                                                                                .cpf,
                                                                                            item.file_name
                                                                                        );
                                                                                    }}
                                                                                    name={
                                                                                        item.file_name
                                                                                    }
                                                                                    loading={
                                                                                        downloadState[
                                                                                        item
                                                                                            .id
                                                                                        ]
                                                                                    }
                                                                                />
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                    </Col>
                                                    <Col sm={12}>
                                                        {receipts.length ===
                                                            0 ? null : (
                                                            <>
                                                                <Typography.Title
                                                                    level={5}
                                                                >
                                                                    Comprovante
                                                                    de Envio
                                                                </Typography.Title>
                                                                <div>
                                                                    {receipts.map(
                                                                        (
                                                                            item,
                                                                            key
                                                                        ) => {
                                                                            return (
                                                                                <DownloadFileLink
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                    onClick={() => {
                                                                                        downloadFile(
                                                                                            item.id,
                                                                                            exchange
                                                                                                .owner
                                                                                                .cpf ??
                                                                                            exchange
                                                                                                .owner
                                                                                                .cnpj,
                                                                                            item.file_name
                                                                                        );
                                                                                    }}
                                                                                    name={
                                                                                        item.file_name
                                                                                    }
                                                                                    loading={
                                                                                        downloadState[
                                                                                        item
                                                                                            .id
                                                                                        ]
                                                                                    }
                                                                                />
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                                <br />
                                                            </>
                                                        )}
                                                        {exchange.linkSignedDoc
                                                            .original_file_url && (
                                                                <>
                                                                    <Typography.Title
                                                                        level={5}
                                                                    >
                                                                        Visualizar
                                                                        Contrato
                                                                        <TooltipEfex title="Você já pode visualizar o contrato de câmbio da operação. Não se esqueça de que a assinatura precisa ser feita através do link enviado para o e-mail do responsável legal cadastrado." />
                                                                    </Typography.Title>
                                                                    <DownloadFileLink
                                                                        name="Contrato Assinado"
                                                                        onClick={() => {
                                                                            window.open(
                                                                                exchange
                                                                                    .linkSignedDoc
                                                                                    .original_file_url
                                                                            );
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        {exchange.linkSignedDoc
                                                            .signed_file_url && (
                                                                <>
                                                                    <Typography.Title
                                                                        level={5}
                                                                    >
                                                                        Contrato de
                                                                        Câmbio
                                                                    </Typography.Title>
                                                                    <DownloadFileLink
                                                                        name="Contrato Assinado"
                                                                        onClick={() => {
                                                                            window.open(
                                                                                exchange
                                                                                    .linkSignedDoc
                                                                                    .signed_file_url
                                                                            );
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                    </Col>
                                                </Row>
                                            </Card>
                                        )}
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={8}
                            className="stretch-column"
                        >
                            <div>
                                {exchange.sendValue.status ===
                                    "waiting_approval" && (
                                        <Card style={{ marginBottom: "15px" }}>
                                            <Typography.Title level={4}>
                                                Arquivos
                                            </Typography.Title>
                                            <br />
                                            <Row gutter={[16, 16]}>
                                                <Col sm={12}>
                                                    <Typography.Title level={5}>
                                                        Anexos
                                                    </Typography.Title>

                                                    <div>
                                                        {exchange.sendValue.files &&
                                                            exchange.sendValue.files
                                                                .length > 0 ? (
                                                            exchange.sendValue.files.map(
                                                                (item, key) => {
                                                                    return (
                                                                        <DownloadFileLink
                                                                            key={
                                                                                key
                                                                            }
                                                                            onClick={() => {
                                                                                downloadFile(
                                                                                    item.id,
                                                                                    exchange
                                                                                        .owner
                                                                                        .cpf,
                                                                                    item.file_name
                                                                                );
                                                                            }}
                                                                            name={
                                                                                item.file_name
                                                                            }
                                                                            loading={
                                                                                downloadState[
                                                                                item
                                                                                    .id
                                                                                ]
                                                                            }
                                                                        />
                                                                    );
                                                                }
                                                            )
                                                        ) : (
                                                            <p>
                                                                Nenhum arquivo
                                                                anexado
                                                            </p>
                                                        )}
                                                    </div>
                                                    {proofs.length === 0 ? null : (
                                                        <>
                                                            <br />
                                                            <Typography.Title
                                                                level={5}
                                                            >
                                                                Comprovante de
                                                                Depósito
                                                            </Typography.Title>
                                                            <div>
                                                                {proofs.map(
                                                                    (item, key) => {
                                                                        return (
                                                                            <DownloadFileLink
                                                                                key={
                                                                                    key
                                                                                }
                                                                                onClick={() => {
                                                                                    downloadFile(
                                                                                        item.id,
                                                                                        exchange
                                                                                            .owner
                                                                                            .cpf,
                                                                                        item.file_name
                                                                                    );
                                                                                }}
                                                                                name={
                                                                                    item.file_name
                                                                                }
                                                                                loading={
                                                                                    downloadState[
                                                                                    item
                                                                                        .id
                                                                                    ]
                                                                                }
                                                                            />
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                </Col>
                                                <Col sm={12}>
                                                    {receipts.length ===
                                                        0 ? null : (
                                                        <>
                                                            <Typography.Title
                                                                level={5}
                                                            >
                                                                Comprovante de Envio
                                                            </Typography.Title>
                                                            <div>
                                                                {receipts.map(
                                                                    (item, key) => {
                                                                        return (
                                                                            <DownloadFileLink
                                                                                key={
                                                                                    key
                                                                                }
                                                                                onClick={() => {
                                                                                    downloadFile(
                                                                                        item.id,
                                                                                        exchange
                                                                                            .owner
                                                                                            .cpf ??
                                                                                        exchange
                                                                                            .owner
                                                                                            .cnpj,
                                                                                        item.file_name
                                                                                    );
                                                                                }}
                                                                                name={
                                                                                    item.file_name
                                                                                }
                                                                                loading={
                                                                                    downloadState[
                                                                                    item
                                                                                        .id
                                                                                    ]
                                                                                }
                                                                            />
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                            <br />
                                                        </>
                                                    )}
                                                    {exchange.linkSignedDoc
                                                        .original_file_url && (
                                                            <>
                                                                <Typography.Title
                                                                    level={5}
                                                                >
                                                                    Visualizar Contrato
                                                                    <TooltipEfex title="Você já pode visualizar o contrato de câmbio da operação. Não se esqueça de que a assinatura precisa ser feita através do link enviado para o e-mail do responsável legal cadastrado." />
                                                                </Typography.Title>
                                                                <DownloadFileLink
                                                                    name="Contrato Assinado"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            exchange
                                                                                .linkSignedDoc
                                                                                .original_file_url
                                                                        );
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    {exchange.linkSignedDoc
                                                        .signed_file_url && (
                                                            <>
                                                                <Typography.Title
                                                                    level={5}
                                                                >
                                                                    Contrato de Câmbio
                                                                </Typography.Title>
                                                                <DownloadFileLink
                                                                    name="Contrato Assinado"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            exchange
                                                                                .linkSignedDoc
                                                                                .signed_file_url
                                                                        );
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                </Col>
                                            </Row>
                                        </Card>
                                    )}
                                {exchange.sendValue.status !==
                                    "waiting_approval" ? (
                                    <PriceSendValue
                                        freeze={
                                            exchange.status.makeExchange ===
                                            StateStatus.loading
                                        }
                                        valueToSend={
                                            exchange.sendValue.target_value
                                        }
                                        isCurrencyTarget={
                                            exchange.sendValue
                                                .is_currency_target
                                        }
                                        currency={
                                            exchange.sendValue
                                                .recipient_account !== undefined
                                                ? exchange.sendValue
                                                    .recipient_account
                                                    .currency
                                                : ""
                                        }
                                        cpfCnpj={
                                            exchange.owner.cpf !== undefined
                                                ? exchange.owner.cpf
                                                : exchange.owner.cnpj
                                        }
                                        taxes={taxes}
                                        maxTime={10}
                                        onChangeSelect={setSelectedSendDate}
                                        selectedSendDate={selectedSendDate}
                                        commercialHour={commercialHour}
                                        sendValueStatus={
                                            exchange.sendValue.status
                                        }
                                        setIsLoading={setIsLoading}
                                        isLoading={isLoading}
                                        setThereCurrency={setThereCurrency}
                                        thereCurrency={thereCurrency}
                                    />
                                ) : (
                                    <CardNotice />
                                )}
                                <Row
                                    gutter={[8, 8]}
                                    justify="center"
                                    className="actions-transaction"
                                >
                                    <Space>
                                        {exchange.sendValue.status ===
                                            "waiting_approval" ||
                                            exchange.sendValue.status ===
                                            "approved" ? (
                                            window.location.hash.includes(
                                                "empresas"
                                            ) ? (
                                                <OrangeButton
                                                    disabled={
                                                        exchange.sendValue
                                                            .status !==
                                                        "approved" ||
                                                        !commercialHour ||
                                                        (!selectedSendDate &&
                                                            thereCurrency) ||
                                                        isLoading
                                                    }
                                                    loading={
                                                        exchange.status
                                                            .makeExchange ===
                                                        StateStatus.loading ||
                                                        isLoading
                                                    }
                                                    htmlType="submit"
                                                    type="primary"
                                                    onClick={onCloseOperation}
                                                >
                                                    Fechar Câmbio
                                                </OrangeButton>
                                            ) : (
                                                <GreenButton
                                                    disabled={
                                                        exchange.sendValue
                                                            .status !==
                                                        "approved" ||
                                                        !commercialHour ||
                                                        (!selectedSendDate &&
                                                            thereCurrency) ||
                                                        isLoading
                                                    }
                                                    loading={
                                                        exchange.status
                                                            .makeExchange ===
                                                        StateStatus.loading ||
                                                        isLoading
                                                    }
                                                    htmlType="submit"
                                                    type="primary"
                                                    onClick={onCloseOperation}
                                                >
                                                    Fechar Câmbio
                                                </GreenButton>
                                            )
                                        ) : null}

                                        {(exchange.sendValue.status === "waiting_exchange" || exchange.sendValue.status === "waiting_contract") &&
                                            proofs.length === 0 ? (
                                            window.location.hash.includes(
                                                "empresas"
                                            ) ? (
                                                <OrangeButton
                                                    disabled={
                                                        exchange.sendValue
                                                            .status ===
                                                        "waiting_approval" ||
                                                        exchange.sendValue
                                                            .status ===
                                                        "approved"
                                                    }
                                                    loading={
                                                        exchange.status
                                                            .sendDepositReceipt ===
                                                        StateStatus.loading
                                                    }
                                                    htmlType="submit"
                                                    type="primary"
                                                    onClick={() =>
                                                        setShowReceiptsModal(
                                                            true
                                                        )
                                                    }
                                                >
                                                    Enviar comprovante
                                                </OrangeButton>
                                            ) : (
                                                <GreenButton
                                                    disabled={
                                                        exchange.sendValue
                                                            .status ===
                                                        "waiting_approval" ||
                                                        exchange.sendValue
                                                            .status ===
                                                        "approved"
                                                    }
                                                    loading={
                                                        exchange.status
                                                            .sendDepositReceipt ===
                                                        StateStatus.loading
                                                    }
                                                    htmlType="submit"
                                                    type="primary"
                                                    onClick={() =>
                                                        setShowReceiptsModal(
                                                            true
                                                        )
                                                    }
                                                >
                                                    Enviar comprovante
                                                </GreenButton>
                                            )
                                        ) : null
                                        }
                                    </Space>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
            <DepositReceiptsModal
                valueBRL={
                    exchange.sendValue.is_currency_target
                        ? exchange.sendValue.value
                        : exchange.sendValue.target_value
                }
                value={
                    !exchange.sendValue.is_currency_target
                        ? exchange.sendValue.value
                        : exchange.sendValue.target_value
                }
                currency={exchange.sendValue.recipient_account?.currency}
                transaction_day={selectedSendDate}
                isVisible={showReceiptsModal}
                isLoading={
                    exchange.status.sendDepositReceipt === StateStatus.loading
                }
                onCancel={() => setShowReceiptsModal(false)}
                onOk={(values) => {
                    onSendDepositReceipt(values.files);
                }}
            />
            <EfexModal
                hasCancel={false}
                visible={showModal}
                centered={true}
                onOk={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
                title="Atenção!"
            >
                <p>
                    A documentação da sua remessa já está aprovada. Agora, só
                    precisaremos aguardar o crédito da moeda estrangeira. Não se
                    preocupe, pois assim que identificarmos o valor, você será
                    avisado por e-mail e poderá fechar o câmbio, ok?
                </p>
            </EfexModal>
            <EfexModal
                hasCancel={false}
                visible={showModalAproved}
                centered={true}
                onOk={() => setShowModalAproved(false)}
                onCancel={() => setShowModalAproved(false)}
                title="Atenção!"
            >
                <p>
                    Que ótimo. Identificamos o crédito da moeda estrangeira, e
                    agora você pode fechar seu câmbio quando quiser.
                </p>
            </EfexModal>
            <EfexModal
                hasCancel={false}
                visible={showModalSignature}
                centered={true}
                onOk={() => setshowModalSignature(false)}
                onCancel={() => setshowModalSignature(false)}
                title="Contrato de Câmbio enviado para assinatura!"
            >
                <p>
                    O contrato de câmbio desta operação já está disponível e
                    você já pode visualizá-lo nos arquivos da operação.
                    Lembre-se de que o contrato precisa ser assinado através do
                    link que enviamos para o e-mail do(s) responsável(is)
                    financeiro(s).
                </p>
            </EfexModal>
        </Col>
    );
};

export default SendValueApproved;
