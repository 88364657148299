import { configureStore, combineReducers } from "@reduxjs/toolkit";

import { initialRegister } from "../features/InitialRegister/InitialRegisterSlice";
import { financialResponsibles } from "../features/SelectPartners/SelectPartnersSlice";
import { loadingData } from "../features/LoadingData/LoadingDataSlice";
import { companyExtraData } from "../features/LegalPersonExtra/LegalPersonExtraSlice";
import { qsaInfoSlice } from "../features/CompleteQsaInfo/CompleteQsaInfoSlice";
import { documents } from "../features/UploadFiles/UploadFilesSlice";
import { legalPerson } from "../features/LegalPerson/LegalPersonSlice";
import { loginSlice } from "../features/Login/LoginSlice";
import { company } from '../features/ChooseCompany/ChooseCompanySlice';
import { confirmReg } from '../features/ConfirmRegister/ConfirmRegisterSlice';
import { forgotSlice } from "../features/ForgotPassword/ForgotSlice";
import { changePasswordSlice } from "../features/ChangePassword/ChangePasswordSlice"
import { person } from '../features/Person/slice';
import { firstStep } from '../features/PersonFlow/FirstStep/slice';
import { secondStep } from '../features/PersonFlow/SecondStep/slice';
import { thirdStep } from '../features/PersonFlow/ThirdStep/slice';
import { fourthStep } from '../features/PersonFlow/FourthStep/slice';
import { exchangeSlice } from "../features/Exchange/slice";
import { contactSend } from '../features/Exchange/Contacts/ContactsSend/slice';
import { homeSlice } from "../features/Home/slice";
import { notificationSlice } from "../features/Notifications/notificationsSlice"

const combinedReducer = combineReducers({
  login: loginSlice.reducer,
  home: homeSlice.reducer,
  initialRegister: initialRegister.reducer,
  loadingData: loadingData.reducer,
  companyExtraData: companyExtraData.reducer,
  financialResponsibles: financialResponsibles.reducer,
  qsaInfo: qsaInfoSlice.reducer,
  documents: documents.reducer,
  legalPerson: legalPerson.reducer,
  company: company.reducer,
  confirmReg: confirmReg.reducer,
  forgot: forgotSlice.reducer,
  changePassword: changePasswordSlice.reducer,
  person: person.reducer,
  firstStep: firstStep.reducer,
  secondStep: secondStep.reducer,
  thirdStep: thirdStep.reducer,
  fourthStep: fourthStep.reducer,
  exchange: exchangeSlice.reducer,
  contactSend: contactSend.reducer,
  notification: notificationSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "loginSlice/logout") {
    state = undefined;
    var elem = document.getElementById("jivo-script");
    elem.parentNode.removeChild(elem);
  }
  if (action.type === "company/getComp") {
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
