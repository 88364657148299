import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Typography,
    Row,
    Col,
    Table,
    Divider,
    message,
    Select,
} from "antd";
import "./styles.scss";
import { useEffect } from "react";
import StateStatus from "../../../utils/status";
import { beautifyCpfCnpj } from "../../../utils/beautify";
import { options } from "../../../utils/consts";
import {
    getExchangeOwner,
    listBankAccountReceive,
    removeBankAccountReceive,
    resetStatusRemoveBankAccountReceive,
    selectExchange,
    updatePersonData,
} from "../slice";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { formatCurrency, maskPhone } from "../../../utils/functions";
import BankAccountReceiveModal from "../_components/BankAccountReceiveModal";
import { EfexCard } from "../../../common/components/Card";
import { GreenButton, WhiteButton } from "../../../common/components/Button";
import SelectAccountToReceive from "../../../common/components/SelectAccountToReceive";
import EfexModal from "../../../common/components/Modal";
import { getPerson, listStatusTransactions, resetStatusGetPerson, selectPerson } from "../../Person/slice";
import InfiniteScrollList from "../../../common/components/InfiniteScrollList";
import { sendValueAndReceiveValueStatus } from "../../../utils/consts";
import { useHistory, useRouteMatch } from "react-router-dom";
import TooltipEfex from "../../../common/components/Tooltip";
import { UpdateContacts } from "../_components/UpdateContacts";

const { Title } = Typography;

const optionsMap = {};
options.forEach((item) => {
    optionsMap[item.value] = item.label;
});

const ProfilePerson = () => {
    const exchange = useSelector(selectExchange);
    const personState = useSelector(selectPerson);
    const person = { person: exchange.owner };
    const dispatch = useDispatch();
    const [createBankAccount, setCreateBankAccount] = useState(false);
    const [editBankAccount, setEditBankAccount] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [statusTransactions, setStatusTransactions] = useState([]);
    const [page, setPage] = useState(1);
    const [isEmpty, setIsEmpty] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [filteredStatus, setFilteredStatus] = useState(null);
    const [filteredOrder, setFilteredOrder] = useState("DESC");
    const [contactModalVisible, setContactModalVisible] = useState(false)
    const [updatetModalVisible, setUpdatetModalVisible] = useState(false)
    const history = useHistory();
    let { path } = useRouteMatch();


    const getMoreStatusTransactions = (pageNone) => {
        dispatch(
            listStatusTransactions({
                cpf: person?.person.cpf,
                page: pageNone || page + 1,
                status: filteredStatus,
                order: filteredOrder,
            })
        ).then((resp) => {
            if (resp.payload) {
                if (
                    resp.payload.pagination.total_pages <
                    resp.payload.pagination.current_page
                ) {
                    setHasMore(false);
                } else {
                    setPage(resp.payload.pagination.current_page);
                    setStatusTransactions((old) => [
                        ...old,
                        ...resp.payload.lst,
                    ]);
                }
            }
        });
    };

    useEffect(() => {
        if (person.person?.register_update) {
            history.push("/dashboard/pessoas")
        }
    }, [person, history])

    useEffect(() => {
        dispatch(listStatusTransactions({ cpf: person?.person.cpf })).then(
            (resp) => {
                if (resp.payload) {
                    setStatusTransactions(resp.payload.lst);
                }
            }
        );
    }, [dispatch, person?.person.cpf]);

    useEffect(() => {
        if (exchange.status.getOwner === StateStatus.idle) {
            dispatch(getExchangeOwner());
        }
    }, [dispatch, exchange.status.getOwner]);
    useEffect(() => {
        if (exchange.status.listBankAccountReceive === StateStatus.idle) {
            dispatch(listBankAccountReceive());
        }
    });

    useEffect(() => {
        if (
            exchange.status.removeBankAccountReceive === StateStatus.succeeded
        ) {
            message.success({
                key: "delete-msg",
                content: "Conta bancária excluída",
                duration: 3,
            });
            dispatch(listBankAccountReceive());
            dispatch(resetStatusRemoveBankAccountReceive());
        }

        if (exchange.status.removeBankAccountReceive === StateStatus.failed) {
            message.error({
                key: "delete-msg",
                content: "Conta bancária não pôde ser excluída",
                duration: 3,
            });
            dispatch(resetStatusRemoveBankAccountReceive());
        }
    }, [exchange.status.removeBankAccountReceive]);

    const handleContactUpdate = () => {
        dispatch(getExchangeOwner({
            isPerson: path.match("pessoas"),
            id: exchange?.owner.id,
        }))
    };


    const columnsAccounts = [
        { title: "Banco", dataIndex: "bank_name" },
        { title: "Agência", dataIndex: "agency_number" },
        { title: "Número da conta", dataIndex: "account_number" },
        {
            title: "",
            render: (item) => (
                <>
                    <Row justify="space-around">
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <EditOutlined
                                onClick={() => {
                                    let show = [];
                                    show[item.id] = true;
                                    setEditBankAccount(show);
                                }}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <DeleteOutlined
                                onClick={() => {
                                    message.loading({
                                        key: "delete-msg",
                                        content: "Excluindo conta bancária...",
                                        duration: 0,
                                    });
                                    dispatch(removeBankAccountReceive(item.id));
                                }}
                            />
                        </Col>
                    </Row>
                    <BankAccountReceiveModal
                        visible={editBankAccount[item.id]}
                        data={item}
                        onOk={() => {
                            let show = [];
                            show[item.id] = false;
                            setEditBankAccount(show);
                        }}
                        onCancel={() => {
                            let show = [];
                            show[item.id] = false;
                            setEditBankAccount(show);
                        }}
                    />
                </>
            ),
        },
    ];
    return (
        <div className="person-profile">
            <Row
                gutter={[16, 16]}
                justify="space-between"
                style={{ marginTop: 16, alignItems: "flex-start" }}
            >
                <Col
                    xs={24}
                    lg={12}
                    className="stretch-card"
                    style={{ gap: 10, flexDirection: "column" }}
                >
                    <EfexCard className="person-data">
                        <Title level={5}>Dados cadastrais</Title>
                        <Divider />
                        {person.person && person.person.cadastral_data && (
                            <Row gutter={[8, 8]}>
                                <Col xs={24} xl={12}>
                                    <div>
                                        <label>Nome</label>
                                        <p>{person.person.name}</p>
                                    </div>
                                    <div>
                                        <label>CPF</label>
                                        <p>
                                            {person.person &&
                                                beautifyCpfCnpj(
                                                    person.person.cpf
                                                )}
                                        </p>
                                    </div>
                                    <div>
                                        <label>IBAN</label>
                                        <p>
                                            {person.person.iban_code
                                                ? person.person.iban_code
                                                : " - "}
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12}>
                                    <div>
                                        <label>Email</label>
                                        <p>
                                            {person.person.cadastral_data.email}
                                        </p>
                                    </div>
                                    <div>
                                        <label>Telefone</label>
                                        <p>
                                            {maskPhone(
                                                person.person.cadastral_data
                                                    .cell_phone
                                            )}
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <Row justify="space-around">
                                        <Col>
                                            <WhiteButton
                                                onClick={() => setContactModalVisible(true)}
                                            ><EditOutlined /> Alterar informações de contatos</WhiteButton>
                                        </Col>
                                        <Col>
                                            <WhiteButton
                                                onClick={() => setUpdatetModalVisible(true)}
                                            ><EditOutlined /> Atualizar cadastro</WhiteButton>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </EfexCard>
                    {person?.person?.operational_limit > 0 && (

                        <EfexCard className="person-data">
                            <Row
                                align="middle"
                                style={{
                                    justifyContent: "space-between",
                                }}
                            >
                                <Title level={5}>
                                    {
                                        <>
                                            Limite operacional para envio de recursos
                                            <TooltipEfex
                                                title="Esse é o limite disponível para envio de recursos para o exterior. 
                                            As operações de recebimento não serão consideradas para esse limite. 
                                            Caso deseje aumentar seu limite, por favor, entre em contato com nossa equipe pelo chat."
                                            />
                                        </>
                                    }
                                </Title>
                            </Row>
                            <Divider />

                            <Col xs={24} xl={12}>
                                <div>
                                    <p>USD{formatCurrency(
                                        person?.person?.operational_limit,
                                        null
                                    )}</p>
                                </div>
                            </Col>
                        </EfexCard>
                    )}
                    <EfexCard>
                        <Row
                            align="middle"
                            style={{
                                justifyContent: "space-between",
                            }}
                        >
                            <Title level={5}>
                                Suas contas corrente no Brasil
                            </Title>
                            <GreenButton
                                size="small"
                                type="primary"
                                style={{ width: "140px", fontWeight: "500" }}
                                onClick={() => setCreateBankAccount(true)}
                            >
                                Adicionar conta
                            </GreenButton>
                        </Row>
                        <Divider />
                        <BankAccountReceiveModal
                            visible={createBankAccount}
                            data={{}}
                            onOk={() => {
                                setCreateBankAccount(false);
                            }}
                            onCancel={() => {
                                setCreateBankAccount(false);
                            }}
                        />
                        <Table
                            className="bank-account-table"
                            pagination={false}
                            columns={columnsAccounts}
                            dataSource={exchange.bankAccounts}
                            loading={
                                exchange.status.listBankAccountReceive ===
                                StateStatus.loading
                            }
                        />
                    </EfexCard>
                </Col>
                <Col
                    xs={24}
                    lg={12}
                    className="stretch-card"
                    style={{ gap: 10, flexDirection: "column" }}
                >
                    <EfexCard className="card-transactions">
                        <div>
                            <Row
                                justify="space-between"
                                className="transaction-status-header"
                            >
                                <Col xs={24} sm={8} md={8} lg={8}>
                                    <Title level={4}>
                                        Status das transações
                                    </Title>
                                </Col>
                                <Col
                                    xs={24}
                                    sm={16}
                                    md={16}
                                    lg={16}
                                    className="selects-col"
                                >
                                    <Row
                                        className="filter-selects"
                                        style={{ justifyContent: "flex-end" }}
                                    >
                                        <Col>
                                            <Select
                                                defaultValue="Todos status"
                                                placeholder="ordenar"
                                                className="transactions-select"
                                                dropdownStyle={{
                                                    minWidth: "250px",
                                                }}
                                                placement="bottomRight"
                                                size="small"
                                                onChange={(value) => {
                                                    setHasMore(true)
                                                    setPage(1)
                                                    setStatusTransactions([])
                                                    setFilteredStatus(value)
                                                    setIsEmpty(false)
                                                    dispatch(listStatusTransactions({ status: value, order: filteredOrder, cpf: person?.person.cpf }))
                                                        .then(resp => {
                                                            if (resp.payload) {
                                                                setStatusTransactions(resp.payload.lst)
                                                                if (resp.payload.lst.length === 0) {
                                                                    setIsEmpty(true)
                                                                }
                                                            }
                                                        });
                                                }}
                                                options={
                                                    sendValueAndReceiveValueStatus
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Select
                                                defaultValue="DESC"
                                                placeholder="ordenar"
                                                className="transactions-select"
                                                size="small"
                                                onChange={(value) => {
                                                    setPage(1);
                                                    setStatusTransactions([]);
                                                    setFilteredOrder(value);
                                                    setIsEmpty(false);
                                                    dispatch(
                                                        listStatusTransactions({
                                                            order: value,
                                                            status: filteredStatus,
                                                            cpf: person?.person
                                                                .cpf,
                                                        })
                                                    ).then((resp) => {
                                                        if (resp.payload) {
                                                            setStatusTransactions(
                                                                resp.payload.lst
                                                            );
                                                            if (
                                                                resp.payload.lst
                                                                    .length ===
                                                                0
                                                            ) {
                                                                setIsEmpty(
                                                                    true
                                                                );
                                                            }
                                                        }
                                                    });
                                                }}
                                            >
                                                <Select.Option value="DESC">
                                                    Recentes
                                                </Select.Option>
                                                <Select.Option value="ASC">
                                                    Antigas
                                                </Select.Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Divider></Divider>
                            </Row>
                            <Divider></Divider>
                        </div>

                        {!isEmpty ? (
                            <InfiniteScrollList
                                list={statusTransactions}
                                nextFn={getMoreStatusTransactions}
                                isStatus={true}
                                // isResponsible={isResponsible}
                                endMessage={
                                    <h4
                                        className="seeAllButtonPerson"
                                        onClick={() =>
                                            history.push(
                                                `/dashboard/pessoas/${person.person.id}/cambio/operacoes-abertas`
                                            )
                                        }
                                    >
                                        Ver todas as transações
                                    </h4>
                                }
                            />
                        ) : isEmpty && !filteredStatus ? (
                            <p
                                style={{
                                    textAlign: "center",
                                    color: "#ccc",
                                }}
                            >
                                Você ainda não possui nenhuma transação
                            </p>
                        ) : (
                            <h4 style={{ textAlign: "center" }}>
                                Não encontrado
                            </h4>
                        )}
                    </EfexCard>
                    <EfexCard>
                        <Row style={{ margin: 0 }}>
                            <Title level={5} style={{ margin: 0 }}>
                                Contas para receber no Brasil
                            </Title>
                        </Row>
                        <Divider />

                        <SelectAccountToReceive user={person.person} />

                        {/* <Collapse accordion>
                            {exchangeReceiveAccounts.map((item) => {
                                return (
                                    <Panel header={item.currency} key={item.id}>
                                        <p>
                                            <strong>IBAN:</strong>{" "}
                                            {person.person.iban_code
                                                ? person.person.iban_code
                                                : " - "}
                                        </p>
                                        <p>
                                            <strong>Titular:</strong>{" "}
                                            {person.person.name}
                                        </p>
                                        <p>
                                            <strong>Banco beneficiário:</strong>{" "}
                                            BANCO BONSUCESSO S.A
                                        </p>
                                        <p>
                                            <strong>Código SWIFT:</strong>{" "}
                                            BBONBRSP
                                        </p>

                                        <p>
                                            <strong>Endereço:</strong> AV RAJA
                                            GABAGLIA 1143 – 15 FL 30380-403, BH
                                            MG
                                        </p>
                                        <Divider />
                                        <p>
                                            <strong>
                                                Banco intermediário:
                                            </strong>{" "}
                                            {item.bank_name}
                                        </p>
                                        <p>
                                            <strong>Código SWIFT:</strong>{" "}
                                            {item.swift}
                                        </p>
                                        <p>
                                            <strong>Titular da conta: </strong>{" "}
                                            {item.holder}
                                        </p>
                                        <p>
                                            <strong>Número da conta: </strong>
                                            {item.number_account}
                                        </p>
                                        <p>
                                            <strong>Endereço:</strong>{" "}
                                            {item.address}
                                        </p>
                                    </Panel>
                                );
                            })}
                        </Collapse> */}
                    </EfexCard>
                </Col>
            </Row>
            <EfexModal
                hasCancel={false}
                visible={showModal}
                centered={true}
                onOk={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
                title="Nova ordem recebida!"
            >
                <p>
                    Identificamos que uma nova ordem de pagamento foi recebida.
                    Você já pode verificar os detalhes dessa remessa na sua
                    lista de recebimentos, pelo link de operações, ali no menu
                    lateral.
                </p>
            </EfexModal>
            <UpdateContacts
                contactModalVisible={contactModalVisible}
                setContactModalVisible={setContactModalVisible}
                person={person?.person}
                onContactUpdate={handleContactUpdate}
            />
            <EfexModal
                visible={updatetModalVisible}
                centered={true}
                onOk={() => {
                    dispatch(updatePersonData({ person_id: person?.person.id })).then((resp) => {
                        if (resp.payload) {
                            dispatch(resetStatusGetPerson())
                            setUpdatetModalVisible(false)
                            history.push("/dashboard/pessoas/dados-cadastrais")
                        }
                    })
                }}
                onCancel={() => setUpdatetModalVisible(false)}
                title="Deseja atualizar as infomações?"
            >
                <p>
                    Se proseguir, só será possível fechar operações dessa PF após a atualização ser encerrada.
                </p>
            </EfexModal>
        </div>
    );
};

export default ProfilePerson;
