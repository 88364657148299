import React, { useState, useEffect } from "react";
import PriceReceiveValue from "../../../common/components/Price";
import { useDispatch, useSelector } from "react-redux";
import {
    selectExchange,
    listReceiveValues,
    resetRequestReceivementValue,
} from "../slice";
import {
    Form,
    Select,
    Row,
    Col,
    InputNumber,
    Tooltip,
    Modal,
} from "antd";
import { formatCurrency } from "../../../utils/functions";
import ArrowSwitchIcon from "../../../common/components/icons/ArrowSwitchIcon";
import TooltipEfex from "../../../common/components/Tooltip";
import { isCommercialHourRange } from "../../../utils/date";
import { useRouteMatch } from "react-router-dom";
import {
    getRatesReceiveValues
} from "../ReceiveValue/thunks";
import StateStatus from "../../../utils/status";
import { currency } from "../../../utils/consts";

const ReceiveTab = ({
    isClosed,
    getRates
}) => {
    const [form] = Form.useForm();
    const [taxes, setTaxes] = useState([
        {
            name: "IOF",
            tax: 110,
            description: "Imposto",
        },
    ]);
    const [files, setFiles] = useState([]);
    const [isCurrencyTarget, setIsCurrencyTarget] = useState(true);
    const [currencySelected, setCurrencySelected] = useState('USD');
    // const [currency, setCurrency] = useState("");
    const exchange = useSelector(selectExchange);
    const dispatch = useDispatch();
    const [commercialHour, setCommercialHour] = useState(false);
    const [isReseted, setIsReseted] = useState(false);
    const [reload, updateReload] = useState(false);
    const [operationNature, setOperationNature] = useState(null);
    const [modal] = Modal.useModal();
    let { url } = useRouteMatch();
    const [isFormFilled, setIsFormFilled] = useState();

    const getCorrectValueToCalculate = () => {
        if (isCurrencyTarget) {
            return form.getFieldValue("target_value") ?? 0;
        } else {
            return form.getFieldValue("value") ?? 0;
        }
    };

    const canEditThisFieldByStatus = (state) =>
        !state.receivedValue ||
        ["draft", "need_changes", "created_by_bo"].includes(
            state.receivedValue.status
        );

    useEffect(() => {
        isCommercialHourRange().then((response) => {
            setCommercialHour(response.data.is_open_to_exchange);
        });
    }, []);

    const isCreatedByBO = (state) =>
        ["created_by_bo"].includes(state.receivedValue?.status);


    const addTaxesByNature = (nature) => {
        const taxes = [];

        Object.keys(nature.taxes).forEach((key) => {
            let description = "Imposto";
            const tax = {
                name: key,
                tax: nature.taxes[key],
                description: description,
            };
            return taxes.push(tax);
        });

        setTaxes(taxes);
    };

    useEffect(() => {
        if (exchange.status.requestReceivementValue === StateStatus.succeeded) {
            modal.success({
                title: "Negócio fechado!",
                content: (
                    <>
                        <p>
                            Em breve você receberá o contrato de câmbio em seu
                            e-mail.
                        </p>
                    </>
                ),
            });
            dispatch(listReceiveValues({ page: 1 }));
            dispatch(resetRequestReceivementValue());
        }
    }, [exchange.status.requestReceivementValue, dispatch, modal]);

    const onValuesChange = (values) => {
        updateReload(!reload);
        if (values.currency !== undefined) {
            dispatch(getRatesReceiveValues({ currency: values.currency }));
        }
    };

    return <Form
        form={form}
        layout="vertical"
        onValuesChange={onValuesChange}>
        <Row justify="space-between" style={{ alignItems: 'baseline' }}>
            <Row
                style={{
                    flexFlow: !isCurrencyTarget ? "column-reverse" : "column",
                }}
            >
                <Col>
                    <Row className="ant-form-item-label">
                        <label for="currency" className="ant-form-item-required"
                            title="Você recebe:">Você recebe</label>
                    </Row>
                    <Row align="bottom" gutter={[16, 0]} justify="space-between" style={{}}>
                        <Col xs={8} sm={8} md={12} lg={12}  >
                            <Form.Item
                                className="select-currency"
                                name="currency"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo obrigatório.",
                                    },
                                ]}
                            >
                                <Select
                                    defaultValue={'USD'}
                                    options={currency.map(x => ({
                                        label: x.value,
                                        value: x.value,
                                    }))}
                                    onChange={(value) => {
                                        setCurrencySelected(value);
                                        form.setFieldsValue({
                                            target_value: 0,
                                            value: 0,
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col id="currency-value" xs={16} sm={16} md={12} lg={12} >
                            <Form.Item
                                name="target_value"
                                initialValue="0"
                                rules={[
                                    {
                                        required: true,
                                        message: "Campo obrigatório.",
                                    },
                                ]}
                            >
                                <InputNumber
                                    disabled={
                                        !isCurrencyTarget ||
                                        !currencySelected
                                    }
                                    style={{ width: "100%" }}
                                    min={1}
                                    formatter={(value) => {
                                        return formatCurrency(value, currencySelected)
                                    }
                                    }
                                    parser={(money) =>
                                        money.replace(/\D/g, "")
                                    }
                                ></InputNumber>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col className="switch-currency">
                    <Tooltip title="Inverter">
                        <div className="switch-currency-btn">
                            <ArrowSwitchIcon
                                color={window.location.hash.includes("empresas") ? "orange" : "green"}
                                data-disabled={!canEditThisFieldByStatus(exchange)}
                                onClick={(e) => {
                                    if (canEditThisFieldByStatus(exchange)) {
                                        setIsCurrencyTarget(!isCurrencyTarget)
                                        form.setFieldsValue({ target_value: 0 });
                                        form.setFieldsValue({ value: 0 });
                                    }
                                }}
                            />
                        </div>
                    </Tooltip>
                </Col>
                <Col>
                    <Form.Item
                        label={
                            <>
                                Convertemos para:
                                <TooltipEfex title="Primeiro selecione a moeda estrangeira na qual irá receber." />
                            </>
                        }
                        name="value"
                        rules={[
                            {
                                required: true,
                                message: "Campo obrigatório.",
                            },
                        ]}
                    >
                        <InputNumber
                            disabled={isCurrencyTarget}
                            style={{ width: "100%" }}
                            min={1}
                            formatter={(value) => {
                                return formatCurrency(value, "BRL");
                            }
                            }
                            parser={(money) =>
                                money.replace(/\D/g, "")
                            }
                        ></InputNumber>
                    </Form.Item>
                </Col>
            </Row>
            <Col xs={8} sm={12} md={16} lg={15} >
                <PriceReceiveValue
                    setIsLoading={()=>{}}
                    setThereCurrency={() => {}}
                    getRates={getRates}
                    commercialHour={true}
                    isClosed={isClosed}
                    status={"new"}
                    valueToReceive={getCorrectValueToCalculate()}
                    isCurrencyTarget={
                        isCurrencyTarget
                    }
                    currency={currencySelected}
                    cpfCnpj={
                        exchange.owner.cpf
                            ? exchange.owner.cpf
                            : exchange.owner.cnpj
                    }
                    maxTime={10}
                    taxes={taxes}
                    onChange={(values) => {
                        if (isCurrencyTarget) {
                            form.setFieldsValue({
                                value: values.finalValue,
                            });
                        } else {
                            form.setFieldsValue({
                                target_value:
                                    values.finalValue,
                            });
                        }
                    }}
                />
            </Col>
        </Row>
    </Form>
}

export default ReceiveTab;