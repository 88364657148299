/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Typography,
    Form,
    Select,
    Row,
    Col,
    InputNumber,
    Upload,
    Space,
    notification,
    message,
    Divider,
    Card,
    Timeline,
    Input,
    Checkbox,
} from "antd";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";

import IdentedField from "../../../common/components/IdentedField";
import TooltipEfex from "../../../common/components/Tooltip";

import StateStatus from "../../../utils/status";

import {
    getBankInfo,
    listBankAccount,
    listContactSend,
    selectContactSend,
    setContactSend,
    getContactSend,
} from "../Contacts/ContactsSend/slice";
import { createFile, deleteExgFile, resetSendValue, selectExchange } from "../slice";
import {
    createOperation,
    editOperation,
    getOperation,
    getRatesSendValues,
} from "./thunks";
import ErrorMsg from "../../../common/components/ErrorMsg";
import ContactSendModal from "../_components/ContactSendModal";
import { formatCurrency } from "../../../utils/functions";
import { history } from "../../../helpers/history";
import { useParams, useRouteMatch } from "react-router-dom";
import BankAccountSendModal from "../_components/BankAccountSendModal";
import { sendValueNatures } from "../../../utils/consts";
import SelectAndAddItem from "./_components/SelectAndAddItem";
import {
    GreenButton,
    GreyButton,
    OrangeButton,
    WhiteButton,
} from "../../../common/components/Button";
import "./styles.scss";
import { EfexCard } from "../../../common/components/Card";
import ExchangeApprovedModal from "../_components/ExchangeApprovedModal";
import EfexModal from "../../../common/components/Modal";
import ConfirmModal from "../../../common/components/Modal/Confirm/index";
import SuccessModal from "../../../common/components/Modal/Success/index";

const beforeUpload = (file) => {
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
        message.error("O Arquivo precisa ser menor que 20Mb!", 5);
        return false;
    }
    return true;
};

const SendValue = () => {
    const { sendValueId } = useParams();
    const { url } = useRouteMatch();
    const [currency, setCurrency] = useState("");
    const [showContactModal, setShowContactModal] = useState(false);
    const [showBankAccountModal, setShowBankAccountModal] = useState(false);
    const [showApprovedModal, setShowApprovedModal] = useState(false);
    const exchange = useSelector(selectExchange);
    const [targetValueSelected, setTargetValueSelected] = useState(0)
    // True = moeda estrangeria | False = BRL
    const [isCurrencyTarget, setIsCurrencyTarget] = useState(false);
    const [isSend, setIsSend] = useState(false);
    const [operationNature, setOperationNature] = useState(null);
    const [isDraft, setIsDraft] = useState(false);
    const [contactID, setContactID] = useState(false);
    const [showModal, setshowModal] = useState(false);
    const [files, setFiles] = useState([]);
    const [taxes, setTaxes] = useState([
        {
            name: "Tarifa",
            tax: null,
            value: 0,
            description: "Taxa fixa",
        },
    ]);
    const [form] = Form.useForm();
    const [isFormFilled, setIsFormFilled] = useState();
    const [reload, updateReload] = useState(false); // gambiarra ridícula para dar update no campo "Voce transfere"

    const contactsSend = useSelector(selectContactSend);
    const dispatch = useDispatch();

    const createOrEditIsLoading =
        exchange.status.createOperation === StateStatus.loading ||
        exchange.status.editOperation === StateStatus.loading;

    const repeatedSendValueId = localStorage.getItem("repeatedSendValueId")

    useEffect(() => {
        dispatch(resetSendValue());
        newSendValue();
        if (sendValueId) {
            message.loading({ key: 1, content: "Carregando..." });
            dispatch(getOperation({ sendValueId: sendValueId }));
        }
    }, [sendValueId]);

    // lista os contatos
    useEffect(() => {
        dispatch(listContactSend());

        repeatedSendValueId && dispatch(getOperation({ sendValueId: repeatedSendValueId }))
    }, []);

    // reseta o valor do input de valor para trocar automaticamente o currency
    useEffect(() => {
        form.resetFields(["target_value"]);
    }, [isCurrencyTarget]);

    // altera placeholder enquanto lista contatos
    useEffect(() => {
        if (contactsSend.status.listContactSend === StateStatus.loading) {
            form.setFieldsValue({ recipient_name: "Carregando..." });
        }

        if (contactsSend.status.listContactSend === StateStatus.succeeded) {
            form.setFieldsValue({
                recipient_name:
                    contactsSend.contactsSend.length === 0
                        ? "Nenhum contato cadastrado"
                        : null,
            });

            if (contactID && exchange.sendValue.recipient) {
                form.setFieldsValue({
                    recipient_name: exchange.sendValue.recipient.id,
                });
            }
        }
    }, []);

    // altera placeholder enquanto lista contas bancarias
    useEffect(() => {
        if (contactsSend.status.listBankAccount === StateStatus.loading) {
            form.setFieldsValue({ accounts: "Carregando..." });
        }

        if (contactsSend.status.listBankAccount === StateStatus.succeeded) {
            form.setFieldsValue({
                accounts:
                    contactsSend.bankAccounts.length === 0
                        ? "Nenhuma conta cadastrada"
                        : null,
            });
        }
    }, [contactsSend.status.listBankAccount]);

    // coleta as infos da conta bancária
    useEffect(() => {
        // if (contactsSend.status.getBankInfo === StateStatus.loading) {
        // 	form.resetFields(["target_value", "value"]);
        // }

        if (contactsSend.status.getBankInfo === StateStatus.succeeded) {
            if (contactsSend.bankAccount.currency !== undefined) {
                setCurrency(contactsSend.bankAccount.currency);
                dispatch(
                    getRatesSendValues({
                        currency: contactsSend.bankAccount.currency,
                    })
                );
            }

            form.setFieldsValue({
                accounts: contactsSend.bankAccount.id,
            });
        }
    }, [contactsSend.status.getBankInfo]);

    useEffect(() => {
        const unlisten = history.listen(() => {
            localStorage.removeItem("repeatedSendValueId");
        });

        return () => {
            unlisten();
        };
    }, [history]);

    useEffect(() => {
        if (
            exchange.status.getOperation === StateStatus.succeeded &&
            (parseInt(exchange.sendValue.id) === parseInt(sendValueId) || parseInt(exchange.sendValue.id) === parseInt(repeatedSendValueId))
        ) {
            if (
                exchange.sendValue.status !== "draft" &&
                exchange.sendValue.status !== "need_changes" &&
                !repeatedSendValueId
            ) {
                history.replace(
                    url.replace("enviar-valores", "operacao-envio")
                );
                return;
            }

            message.success({ key: 1, content: "Concluído!" });

            setIsCurrencyTarget(exchange.sendValue.is_currency_target)
            form.setFieldsValue({
                other: exchange.sendValue.other,
                recipient_name: exchange.sendValue.recipient.id,
                accounts: exchange.sendValue.recipient_account.id,
                operation_nature: exchange.sendValue.nature,
                has_payment_relationship:
                    exchange.sendValue.has_payment_relationship,
                is_tax_by_recipient: exchange.sendValue.is_tax_by_recipient,
                has_capital_gain: exchange.sendValue.has_capital_gain,
                has_advance_payment: exchange.sendValue.has_advance_payment,
                target_value: exchange.sendValue.target_value
            });

            const nature = sendValueNatures.filter(
                (item) => item.name === exchange.sendValue.nature
            )[0];
            setOperationNature(nature);

            if (exchange.sendValue.taxes.length === 0) {
                addTaxesByNature(nature);
            } else {
                setTaxes(exchange.sendValue.taxes);
            }

            if (!repeatedSendValueId) {
                setFiles(
                    exchange.sendValue.files !== null &&
                    exchange.sendValue.files.map((item) => {
                        return {
                            name: item.file_name,
                            uid: item.id,
                            url: "#",
                            status: "done",
                            response: null,
                        };
                    })
                );
            }

            setCurrency(exchange.sendValue.recipient_account.currency);
            setIsCurrencyTarget(exchange.sendValue.is_currency_target);
            onSelectContact(exchange.sendValue.recipient.id);
            onSelectContactBank(exchange.sendValue.recipient_account.id);
            setTargetValueSelected(exchange.sendValue.target_value)
        }
        checkIfFormIsFilled();
    }, [exchange.status.getOperation]);

    const onSelectContact = (id) => {
        dispatch(listBankAccount({ contactId: id }));
        setContactID(id);
    };

    const onSelectContactBank = (bank) => {
        dispatch(getBankInfo({ bankId: bank }));
    };

    const checkIfFormIsFilled = () => {
        const values = form.getFieldsValue();
        values.observation = ""
        const isValid = Object.keys(values).every(
            (key, index) => values[key] !== undefined
        );
        if (repeatedSendValueId) {
            setIsFormFilled(true)
        } else {
            setIsFormFilled(isValid)
        }
    };

    const onValuesChange = (changedValues, values) => {
        checkIfFormIsFilled();
        updateReload(!reload);
        let nature = operationNature;

        if (changedValues.operation_nature !== undefined) {
            nature = sendValueNatures.filter(
                (item) => item.name === values.operation_nature
            )[0];

            form.resetFields([
                "has_payment_relationship",
                "is_tax_by_recipient",
                "has_capital_gain",
                "has_advance_payment",
            ]);
            setOperationNature(nature);
        }

        if (nature) {
            addTaxesByNature(nature);
        }
    };

    const addTaxesByNature = (nature) => {
        const taxes = [];

        if (nature.taxes.IOF > 0) {
            taxes.push({
                name: "IOF",
                tax: nature.taxes.IOF,
                description: "Imposto",
            });
        }

        if (nature.taxes.IR > 0) {
            taxes.push({
                name: "IR",
                tax: nature.taxes.IR,
                description: "Imposto de Renda",
            });
        }

        setTaxes(taxes);
    };

    const onSendTonBO = () => {
        setIsSend(true);
        form.validateFields()
            .then((values) => {
                var data = {};
                data.recipient = contactID;
                data.recipient_account =
                    exchange.sendValue.id !== undefined
                        ? exchange.sendValue.recipient_account.id
                        : contactsSend.bankAccount.id;
                data.is_currency_target = isCurrencyTarget;
                data.target_value = values.target_value;
                data.nature = values.operation_nature;
                data.status = "waiting_approval";
                data.has_payment_relationship =
                    values.has_payment_relationship ?? 0;
                data.is_tax_by_recipient = values.is_tax_by_recipient ?? 0;
                data.has_capital_gain = values.has_capital_gain ?? 0;
                data.has_advance_payment = values.has_advance_payment ?? 0;
                data.files = files.map((item) => item.uid);
                data.other = values.other;
                data.observation = values.observation || "";

                if ((currency === "USD" && (targetValueSelected <= 0 || targetValueSelected > 300000) && data.files.length === 0 && operationNature.requireFiles) ||
                    (currency !== "USD" && data.files.length === 0 && operationNature.requireFiles)) {
                    return notification.error({
                        message: `Atenção!`,
                        description:
                            "Arquivos referente ao câmbio não foram anexados.",
                        placement: "topRight",
                    });
                }

                if (exchange.sendValue.status !== undefined && !repeatedSendValueId) {
                    data.send_value_id = exchange.sendValue.id;
                    dispatch(editOperation(data)).then(({ payload, error }) => {
                        if (payload && payload.data && payload.data.success) {
                            history.push(
                                `${url.replace(
                                    "enviar-valores",
                                    "operacao-envio"
                                )}/${payload.data.id}`
                            );
                            SuccessModal({
                                content: (
                                    <>
                                        <p>
                                            Nossa equipe já está avaliando os
                                            documentos de sua operação, essa
                                            etapa pode demorar até duas horas
                                            para ser concluída. Mas não se
                                            preocupe, você receberá um e-mail
                                            assim que a análise for finalizada.
                                        </p>
                                        <br />
                                        <p>
                                            Caso a gente encontre qualquer
                                            problema ou precise de mais
                                            informações, entraremos em contato
                                            através do seu e-mail.
                                        </p>
                                    </>
                                ),
                            });
                        } else {
                            notification.error({
                                message: `Erro!`,
                                description: error.message,
                                placement: "topRight",
                            });
                        }
                        setIsSend(false);
                    });
                } else {
                    dispatch(createOperation(data)).then(
                        ({ payload, error }) => {
                            if (
                                payload &&
                                payload.data &&
                                payload.data.success
                            ) {
                                localStorage.removeItem("repeatedSendValueId")
                                newSendValue();
                                SuccessModal({
                                    content: (
                                        <>
                                            <p>
                                                Nossa equipe já está avaliando
                                                os documentos de sua operação,
                                                essa etapa pode demorar até duas
                                                horas para ser concluída. Mas
                                                não se preocupe, você receberá
                                                um e-mail assim que a análise
                                                for finalizada.
                                            </p>
                                            <br />
                                            <p>
                                                Caso a gente encontre qualquer
                                                problema ou precise de mais
                                                informações, entraremos em
                                                contato através do seu e-mail.
                                            </p>
                                        </>
                                    ),
                                });
                                history.push(
                                    `${url.replace(
                                        "enviar-valores",
                                        "operacao-envio"
                                    )}/${payload.data.id}`
                                );
                            } else {
                                notification.error({
                                    message: `Erro!`,
                                    description: error.message,
                                    placement: "topRight",
                                });
                            }
                            setIsSend(false);
                        }
                    );
                }
            })
            .catch((info) => { });
    };

    const onDraft = () => {
        setIsDraft(true);
        form.validateFields()
            .then((values) => {
                var data = {};
                data.other = values.other;
                data.recipient = contactID;
                data.recipient_account =
                    exchange.sendValue.id !== undefined
                        ? exchange.sendValue.recipient_account.id
                        : contactsSend.bankAccount.id;
                data.is_currency_target = isCurrencyTarget;
                data.target_value = values.target_value;
                data.nature = values.operation_nature;
                data.status = "draft";
                data.has_payment_relationship =
                    values.has_payment_relationship ?? 0;
                data.is_tax_by_recipient = values.is_tax_by_recipient ?? 0;
                data.has_capital_gain = values.has_capital_gain ?? 0;
                data.has_advance_payment = values.has_advance_payment ?? 0;
                data.files = files.map((item) => item.uid);
                if (exchange.sendValue.status !== undefined && !repeatedSendValueId) {
                    data.send_value_id = exchange.sendValue.id;
                    dispatch(editOperation(data)).then(({ payload, error }) => {
                        if (payload && payload.data && payload.data.success) {
                            notification.success({
                                message: `Sucesso!`,
                                description: "Rascunho editado com sucesso.",
                                placement: "topRight",
                            });
                        } else {
                            notification.error({
                                message: `Erro!`,
                                description: error.message,
                                placement: "topRight",
                            });
                        }
                        setIsDraft(false);
                    });
                } else {
                    dispatch(createOperation(data)).then(
                        ({ payload, error }) => {
                            if (
                                payload &&
                                payload.data &&
                                payload.data.success
                            ) {
                                newSendValue();
                                notification.success({
                                    message: `Sucesso!`,
                                    description: "Rascunho criado com sucesso.",
                                    placement: "topRight",
                                });
                            } else {
                                notification.error({
                                    message: `Erro!`,
                                    description: error.message,
                                    placement: "topRight",
                                });
                            }
                            setIsDraft(false);
                        }
                    );
                }
            })
            .catch((info) => {
                setIsDraft(false);
            });
    };

    const customRequest = ({ file, onProgress, onSuccess, onError }) => {
        var formData = new FormData();
        formData.append("file", file);
        let files_ = files;

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        dispatch(
            createFile({
                formData: formData,
                config: config,
                onSuccess: (response) => {
                    files_.push({
                        uid: response.data.file_id,
                        local_uid: file.uid,
                        name: file.name,
                        url: "#",
                        status: "done",
                        response: null,
                    });
                    setFiles(files_);
                    onSuccess(message.success(`documento enviado com sucesso.`));
                },
                onError: (err) => {
                    onError({ err })
                    message.error(`falha no envio do documento.`)
                },
            })
        );
    };

    const canEditThisFieldByStatus = (state) =>
        !state.sendValue.status ||
        ["draft", "need_changes"].includes(state.sendValue.status);

    const newSendValue = () => {
        setCurrency("");
        setIsCurrencyTarget(true);
        setTaxes([]);
        setOperationNature(null);
        setFiles([]);
        dispatch(resetSendValue());
        form.resetFields();
    };

    const getCorrectValueToCalculate = () => {
        if (isCurrencyTarget) {
            return form.getFieldValue("target_value") ?? 0;
        } else {
            return form.getFieldValue("value") ?? 0;
        }
    };

    return (
        <div className="send-values">
            {(!sendValueId ||
                exchange.status.getOperation === StateStatus.succeeded) && (
                    <>
                        {exchange.status.createOperation === StateStatus.failed ||
                            contactsSend.status.listBankAccount ===
                            StateStatus.failed ||
                            contactsSend.status.getBankInfo === StateStatus.failed ? (
                            <ErrorMsg message={exchange.errorMsg} />
                        ) : null}
                        <br />
                        {exchange.sendValue &&
                            exchange.sendValue.status === "need_changes" ? (
                            <Row>
                                <Card style={{ width: "100%" }}>
                                    <Typography.Title level={5}>
                                        {
                                            <>
                                                Andamento
                                                {exchange.sendValue.history
                                                    .length === 1 && (
                                                        <TooltipEfex
                                                            title="Aqui você acompanha o status da sua remessa enquanto estiver em análise. 
										Não se preocupe, enviaremos um e-mail assim que estiver aprovada para o fechamento do câmbio."
                                                        />
                                                    )}
                                            </>
                                        }
                                    </Typography.Title>
                                    <br />
                                    <Timeline>
                                        {exchange.sendValue !== undefined &&
                                            exchange.sendValue.history &&
                                            exchange.sendValue.history.slice(0).reverse().map(
                                                (item) => {
                                                    return (
                                                        <Timeline.Item
                                                            color={
                                                                item.action_type ===
                                                                    "negative"
                                                                    ? "red"
                                                                    : item.action_type ===
                                                                        "neutral"
                                                                        ? "grey"
                                                                        : "green"
                                                            }
                                                        >
                                                            {item.text} -{" "}
                                                            {item.created_at}
                                                        </Timeline.Item>
                                                    );
                                                }
                                            )}
                                    </Timeline>
                                </Card>
                            </Row>
                        ) : null}
                        <br />
                        <Form
                            form={form}
                            layout="vertical"
                            onValuesChange={onValuesChange}
                        >
                            <Row gutter={[16, 16]} justify="space-between">
                                <Col xs={24} sm={24} md={24} lg={14}>
                                    <EfexCard>
                                        <Row>
                                            <Typography.Title level={4}>
                                                Enviar Valores
                                            </Typography.Title>
                                            <Divider
                                                style={{ margin: "8px 0 20px" }}
                                            />
                                        </Row>
                                        <Row
                                            gutter={[24, 8]}
                                            justify="space-between"
                                        >
                                            <Col xs={24} sm={24} md={15} lg={15}>
                                                <Typography.Title level={5}>
                                                    Dados de envio
                                                </Typography.Title>
                                                <SelectAndAddItem
                                                    name="recipient_name"
                                                    label={
                                                        <>
                                                            Beneficiário no exterior
                                                            <TooltipEfex
                                                                title="Escolha abaixo o beneficiário que receberá os recursos no exterior. 
												Para cadastrar um novo beneficiário, basta clicar no “+” ao lado."
                                                            />
                                                        </>
                                                    }
                                                    placeholder="Selecione o beneficiário"
                                                    options={contactsSend.contactsSend.map(
                                                        (item) => ({
                                                            value: item.id,
                                                            label: item.tax_identity
                                                                ? `${item.name} - ${item.tax_identity}`
                                                                : `${item.name}`,
                                                        })
                                                    )}
                                                    required={true}
                                                    disabled={
                                                        !canEditThisFieldByStatus(
                                                            exchange
                                                        ) &&
                                                        !repeatedSendValueId
                                                    }
                                                    onChange={(value) =>
                                                        onSelectContact(value)
                                                    }
                                                    addBtnTitle="Adicionar novo contato"
                                                    onAdd={() => {
                                                        dispatch(
                                                            setContactSend(null)
                                                        );
                                                        setShowContactModal(true);
                                                    }}
                                                />
                                                <SelectAndAddItem
                                                    name="accounts"
                                                    label={
                                                        <>
                                                            Conta bancária
                                                            <TooltipEfex
                                                                title="Agora, precisamos que nos diga para qual conta do beneficiário 
													vamos transferir o dinheiro. Primeiro precisamos que escolha obeneficiário, ok?
													Para cadastrar uma nova conta, basta clicar no “+” aqui ao lado."
                                                            />
                                                        </>
                                                    }
                                                    placeholder="Selecione a conta bancária"
                                                    options={contactsSend.bankAccounts.map(
                                                        (item) => ({
                                                            value: item.id,
                                                            label: `${item.bank_name} - ${item.currency}`,
                                                        })
                                                    )}
                                                    required={true}
                                                    disabled={
                                                        (!canEditThisFieldByStatus(
                                                            exchange
                                                        ) || !contactID) &&
                                                        !repeatedSendValueId
                                                    }
                                                    onChange={(value) =>
                                                        onSelectContactBank(value)
                                                    }
                                                    addBtnTitle="Adicionar nova conta"
                                                    onAdd={() => {
                                                        dispatch(
                                                            setContactSend(null)
                                                        );
                                                        dispatch(
                                                            getContactSend(
                                                                contactID
                                                            )
                                                        );
                                                        setShowBankAccountModal(
                                                            true
                                                        );
                                                    }}
                                                />
                                                <Form.Item
                                                    name="operation_nature"
                                                    label={
                                                        <>
                                                            Motivo da remessa
                                                            <TooltipEfex
                                                                title="Selecione abaixo o motivo de sua operação. 
														Caso não tenha certeza ou não encontre o motivo na lista, não tem problema, 
														basta selecionar “Outro”."
                                                            />
                                                        </>
                                                    }
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Campo obrigatório.",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        placeholder="Selecione o motivo da remessa"
                                                        onChange={(e) => {
                                                            if (
                                                                e !== "commercial"
                                                            ) {
                                                                setshowModal(true);
                                                            }
                                                        }}
                                                        disabled={
                                                            !canEditThisFieldByStatus(
                                                                exchange
                                                            ) &&
                                                            !repeatedSendValueId
                                                        }
                                                    >
                                                        {sendValueNatures.map(
                                                            (item, index) => (
                                                                <Select.Option
                                                                    className={`operation-nature-option-${index}`}
                                                                    key={index}
                                                                    value={
                                                                        item.name
                                                                    }
                                                                >
                                                                    {item.label}
                                                                </Select.Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                                {operationNature && (
                                                    <>
                                                        {operationNature.name ===
                                                            "other" ? (
                                                            <IdentedField>
                                                                <Form.Item
                                                                    name="other"
                                                                    label={
                                                                        <>
                                                                            Descreva
                                                                            o motivo
                                                                            <TooltipEfex
                                                                                title="Por favor, utilize o espaço abaixo para nos detalhar o 
																	motivo da remessa que pretende realizar. Nos ajudará a classificar corretamente, 
																	evitando qualquer transtorno e/ou recolhimento de impostos indevidos"
                                                                            />
                                                                        </>
                                                                    }
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                "Campo obrigatório",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input.TextArea />
                                                                </Form.Item>
                                                            </IdentedField>
                                                        ) : null}
                                                        {operationNature.fields.includes(
                                                            "has_payment_relationship"
                                                        ) ? (
                                                            <IdentedField>
                                                                <Form.Item
                                                                    name="has_payment_relationship"
                                                                    label="O pagamento está relacionado a produtos, serviços ou destinos turísticos brasileiros?"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                "Campo obrigatório.",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        disabled={
                                                                            !canEditThisFieldByStatus(
                                                                                exchange
                                                                            ) &&
                                                                            !repeatedSendValueId
                                                                        }
                                                                    >
                                                                        <Select.Option
                                                                            value={
                                                                                0
                                                                            }
                                                                        >
                                                                            {" "}
                                                                            NÃO{" "}
                                                                        </Select.Option>
                                                                        <Select.Option
                                                                            value={
                                                                                1
                                                                            }
                                                                        >
                                                                            {" "}
                                                                            SIM{" "}
                                                                        </Select.Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </IdentedField>
                                                        ) : null}
                                                        {operationNature.fields.includes(
                                                            "is_tax_by_recipient"
                                                        ) ? (
                                                            <IdentedField>
                                                                <Form.Item
                                                                    name="is_tax_by_recipient"
                                                                    label={
                                                                        operationNature.name ===
                                                                            "finance"
                                                                            ? "Quem vai pagar os juros da operação?"
                                                                            : "Quem vai pagar o Imposto de Renda sobre a operação?"
                                                                    }
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                "Campo obrigatório.",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        disabled={
                                                                            !canEditThisFieldByStatus(
                                                                                exchange
                                                                            )
                                                                            && !repeatedSendValueId
                                                                        }
                                                                    >
                                                                        {/* //TODO: o backend espera SIM e NAO, adequar para o novo tipo de resposta à pergunta
																		//TODO: Verificar regra para exibir as opções
																	*/}
                                                                        {operationNature.name ===
                                                                            "finance" && (
                                                                                <>
                                                                                    <Select.Option
                                                                                        value={
                                                                                            0
                                                                                        }
                                                                                    >
                                                                                        {" "}
                                                                                        Quem
                                                                                        está
                                                                                        pagando
                                                                                        pelo
                                                                                        serviço{" "}
                                                                                    </Select.Option>
                                                                                    <Select.Option
                                                                                        value={
                                                                                            1
                                                                                        }
                                                                                    >
                                                                                        {" "}
                                                                                        Quem
                                                                                        está
                                                                                        recebendo
                                                                                        pelo
                                                                                        serviço{" "}
                                                                                    </Select.Option>
                                                                                </>
                                                                            )}
                                                                        {operationNature.name !==
                                                                            "finance" && (
                                                                                <>
                                                                                    <Select.Option
                                                                                        value={
                                                                                            0
                                                                                        }
                                                                                    >
                                                                                        {" "}
                                                                                        Quem
                                                                                        está
                                                                                        enviando
                                                                                        os
                                                                                        recursos{" "}
                                                                                    </Select.Option>
                                                                                    <Select.Option
                                                                                        value={
                                                                                            1
                                                                                        }
                                                                                    >
                                                                                        {" "}
                                                                                        Quem
                                                                                        está
                                                                                        recebendo
                                                                                        pelo
                                                                                        serviço{" "}
                                                                                    </Select.Option>
                                                                                </>
                                                                            )}
                                                                    </Select>
                                                                </Form.Item>
                                                            </IdentedField>
                                                        ) : null}
                                                        {operationNature.fields.includes(
                                                            "has_capital_gain"
                                                        ) ? (
                                                            <IdentedField>
                                                                <Form.Item
                                                                    name="has_capital_gain"
                                                                    label="Haverá ganho de capital com o serviço contratado?"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                "Campo obrigatório.",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        disabled={
                                                                            !canEditThisFieldByStatus(
                                                                                exchange
                                                                            ) &&
                                                                            !repeatedSendValueId
                                                                        }
                                                                    >
                                                                        <Select.Option
                                                                            value={
                                                                                0
                                                                            }
                                                                        >
                                                                            {" "}
                                                                            NÃO{" "}
                                                                        </Select.Option>
                                                                        <Select.Option
                                                                            value={
                                                                                1
                                                                            }
                                                                        >
                                                                            {" "}
                                                                            SIM{" "}
                                                                        </Select.Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </IdentedField>
                                                        ) : null}
                                                        {operationNature.fields.includes(
                                                            "has_advance_payment"
                                                        ) ? (
                                                            <IdentedField>
                                                                <Form.Item
                                                                    name="has_advance_payment"
                                                                    label="Pagamento de importação antecipado?"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                "Campo obrigatório.",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        disabled={
                                                                            !canEditThisFieldByStatus(
                                                                                exchange
                                                                            ) &&
                                                                            !repeatedSendValueId
                                                                        }
                                                                    >
                                                                        <Select.Option
                                                                            value={
                                                                                0
                                                                            }
                                                                        >
                                                                            {" "}
                                                                            NÃO{" "}
                                                                        </Select.Option>
                                                                        <Select.Option
                                                                            value={
                                                                                1
                                                                            }
                                                                        >
                                                                            {" "}
                                                                            SIM{" "}
                                                                        </Select.Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </IdentedField>
                                                        ) : null}
                                                        <IdentedField>
                                                            <Form.Item
                                                                name="observation"
                                                                label={
                                                                    <>
                                                                        Observações
                                                                        <TooltipEfex
                                                                            title="Você pode incluir abaixo informações adicionais sobre sua remessa"
                                                                        />
                                                                    </>
                                                                }
                                                            >
                                                                <Input.TextArea />
                                                            </Form.Item>
                                                        </IdentedField>
                                                    </>
                                                )}
                                            </Col>
                                            <Col xs={24} sm={24} md={8} lg={8}>
                                                <Typography.Title level={5}>
                                                    Valores
                                                    <TooltipEfex
                                                        title="Insira abaixo o montante em moeda estrangeira ou em reais que deseja enviar ao exterior.  
												Primeiro preencha ou selecione o canal bancário para o qual você está enviando 
												os recursos, a conversão já irá considerar a moeda selecionada."
                                                    />
                                                </Typography.Title>
                                                <Row
                                                    style={{
                                                        flexFlow: "column-reverse",
                                                    }}
                                                    justify="space-between"
                                                >
                                                    <Col className="switch-currency">
                                                        <div className="switch-currency-btn">
                                                            <Checkbox
                                                                defaultChecked={isCurrencyTarget}
                                                                disabled={
                                                                    (!canEditThisFieldByStatus(
                                                                        exchange
                                                                    ) || !currency) &&
                                                                    !repeatedSendValueId
                                                                }
                                                                onClick={() => {
                                                                    setIsCurrencyTarget(
                                                                        !isCurrencyTarget
                                                                    );
                                                                }}
                                                            >
                                                                Prefiro informar os
                                                                valores em reais
                                                            </Checkbox>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <Form.Item
                                                            label="Você transfere"
                                                            name="target_value"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        "Campo obrigatório.",
                                                                },
                                                            ]}
                                                            initialValue={!exchange?.sendValue.is_currency_target && exchange.sendValue.target_value}
                                                        >
                                                            <InputNumber
                                                                className="input-value-exchange"
                                                                disabled={
                                                                    currency === "" &&
                                                                    !repeatedSendValueId
                                                                }
                                                                onChange={(
                                                                    value
                                                                ) => {
                                                                    setTargetValueSelected(
                                                                        value
                                                                    );
                                                                }}
                                                                min={1}
                                                                formatter={(
                                                                    value
                                                                ) =>
                                                                    formatCurrency(
                                                                        value,
                                                                        isCurrencyTarget
                                                                            ? currency
                                                                            : "BRL"
                                                                    )
                                                                }
                                                                parser={(money) =>
                                                                    money.replace(
                                                                        /\D/g,
                                                                        ""
                                                                    )
                                                                }
                                                            ></InputNumber>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Upload
                                                        className="efex-upload-file-button"
                                                        accept=".pdf, .png, .jpg, .jpeg"
                                                        disabled={
                                                            !operationNature ||
                                                            !operationNature.requireFiles ||
                                                            (!canEditThisFieldByStatus(
                                                                exchange
                                                            ) &&
                                                                !repeatedSendValueId) ||
                                                            !isFormFilled
                                                        }
                                                        multiple={true}
                                                        beforeUpload={beforeUpload}
                                                        customRequest={
                                                            customRequest
                                                        }
                                                        onRemove={(remove) => {
                                                            let file_id;
                                                            setFiles(files.filter((item) => {
                                                                if (item.local_uid === remove.uid) {
                                                                    file_id = item.uid
                                                                }
                                                                return item.local_uid !== remove.uid
                                                            }));
                                                            dispatch(deleteExgFile({ file_id }))
                                                        }}
                                                        showUploadList={{
                                                            showDownloadIcon: false,
                                                            showPreviewIcon: false,
                                                            showRemoveIcon: true,
                                                            removeIcon: (
                                                                <>
                                                                    <span
                                                                        style={{
                                                                            width: "150px",
                                                                        }}
                                                                    >
                                                                        <CloseCircleOutlined
                                                                            style={{
                                                                                marginRight:
                                                                                    "5px",
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </>
                                                            ),
                                                        }}
                                                    >
                                                        {operationNature &&
                                                            operationNature.name !==
                                                            "deposit" ? (
                                                            <Row className="ant-form-item-label">
                                                                <label className={currency === "USD" && targetValueSelected > 0 && targetValueSelected <= 300000 ? "" : "ant-form-item-required"}>
                                                                    Documentação
                                                                </label>
                                                                <TooltipEfex
                                                                    title={
                                                                        <>
                                                                            <a
                                                                                href="https://efexhub.com.br/"
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                Clicando
                                                                                aqui
                                                                            </a>
                                                                            ,
                                                                            veja
                                                                            na
                                                                            sessão
                                                                            de
                                                                            Dúvidas,
                                                                            exemplos
                                                                            de
                                                                            documentos
                                                                            para
                                                                            cada
                                                                            motivo
                                                                            de
                                                                            remessa.
                                                                        </>
                                                                    }
                                                                />
                                                            </Row>
                                                        ) : null}
                                                        {operationNature &&
                                                            operationNature.name !==
                                                            "deposit" ? (
                                                            <Row
                                                                gutter={[16, 0]}
                                                                align="middle"
                                                            >
                                                                <Col>
                                                                    {currency === "USD" && targetValueSelected > 0 && targetValueSelected <= 300000 ? null : (
                                                                        <span
                                                                            style={{
                                                                                color: "#ff4d4f",
                                                                            }}
                                                                        >
                                                                            *
                                                                        </span>
                                                                    )}
                                                                    <GreyButton
                                                                        sendDocuments={true}
                                                                        disabled={
                                                                            !operationNature ||
                                                                            !operationNature.requireFiles ||
                                                                            (!canEditThisFieldByStatus(
                                                                                exchange
                                                                            ) &&
                                                                                !repeatedSendValueId) ||
                                                                            !isFormFilled
                                                                        }
                                                                        style={{
                                                                            fontSize:
                                                                                "10px",
                                                                            width: "140px",
                                                                        }}
                                                                    >
                                                                        Documento da remessa
                                                                        <UploadOutlined
                                                                            style={{
                                                                                position:
                                                                                    "relative",
                                                                                left: "10px",
                                                                            }}
                                                                        />
                                                                    </GreyButton>

                                                                    {currency === "USD" &&
                                                                        (targetValueSelected > 0 && targetValueSelected <= 300000) &&
                                                                        window.location.hash.includes("empresas") ? (
                                                                        <p style={{ backgroundColor: "#f6f6f6", color: "#333", padding: "2px", borderLeft: "5px solid #f99d1c", marginTop: "10px", fontSize: "14px" }}>
                                                                            Não obrigatório para essa transação
                                                                        </p>
                                                                    ) : null}
                                                                    {currency === "USD" &&
                                                                        (targetValueSelected > 0 && targetValueSelected <= 300000) &&
                                                                        window.location.hash.includes("pessoas") ? (
                                                                        <p style={{ backgroundColor: "#f6f6f6", color: "#333", padding: "2px", borderLeft: "5px solid #105151", marginTop: "10px", fontSize: "14px" }}>
                                                                            Não obrigatório para essa transação
                                                                        </p>
                                                                    ) : null}
                                                                </Col>
                                                            </Row>
                                                        ) : null}
                                                    </Upload>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </EfexCard>
                                </Col>
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={10}
                                    className="stretch-column"
                                >
                                    <div>
                                        {/* <p>
                                        Veja abaixo uma simulação dos valores*
                                    </p>
                                    <PriceSendValue
                                        valueToSend={getCorrectValueToCalculate()}
                                        isCurrencyTarget={isCurrencyTarget}
                                        currency={currency}
                                        cpfCnpj={
                                            exchange.owner.cpf !== undefined
                                                ? exchange.owner.cpf
                                                : exchange.owner.cnpj
                                        }
                                        maxTime={10}
                                        taxes={taxes}
                                        onChange={(values) => {
                                            if (isCurrencyTarget) {
                                                form.setFieldsValue({
                                                    value: values.finalValue.replace(
                                                        /[,.]/g,
                                                        ""
                                                    ),
                                                });
                                            } else {
                                                form.setFieldsValue({
                                                    target_value:
                                                        values.finalValue.replace(
                                                            /[,.]/g,
                                                            ""
                                                        ),
                                                });
                                            }
                                        }}
                                    /> */}
                                        <Row
                                            gutter={[8, 8]}
                                            justify="center"
                                            className="actions-transaction"
                                        >
                                            <Space>
                                                {window.location.hash.includes(
                                                    "empresas"
                                                ) ? (
                                                    <OrangeButton
                                                        disabled={
                                                            (!canEditThisFieldByStatus(
                                                                exchange
                                                            ) &&
                                                                !repeatedSendValueId) ||
                                                            createOrEditIsLoading ||
                                                            !isFormFilled ||
                                                            !operationNature ||
                                                            (currency === "USD" && (targetValueSelected <= 0 || targetValueSelected > 300000) && operationNature.requireFiles && files.length === 0) ||
                                                            (currency !== "USD" && operationNature.requireFiles && files.length === 0)
                                                        }
                                                        loading={
                                                            isSend &&
                                                            createOrEditIsLoading
                                                        }
                                                        type="primary"
                                                        onClick={() =>
                                                            ConfirmModal({
                                                                applyClass: true,
                                                                onOkFunc: () =>
                                                                    onSendTonBO(),
                                                                onCancelFunc: () =>
                                                                    null,
                                                                okText: "Sim",
                                                                cancelText:
                                                                    "Quero revisar",
                                                                title: "Enviar para análise",
                                                                content:
                                                                    "Você confirma que o beneficiário no exterior informado e sua respectiva conta bancária para envio da remessa estão corretos?",
                                                            })
                                                        }
                                                    >
                                                        Enviar para análise
                                                    </OrangeButton>
                                                ) : (
                                                    <GreenButton
                                                        disabled={
                                                            (!canEditThisFieldByStatus(
                                                                exchange
                                                            ) &&
                                                                !repeatedSendValueId) ||
                                                            createOrEditIsLoading ||
                                                            !isFormFilled ||
                                                            !operationNature ||
                                                            (currency === "USD" && (targetValueSelected <= 0 || targetValueSelected > 300000) && operationNature.requireFiles && files.length === 0) ||
                                                            (currency !== "USD" && operationNature.requireFiles && files.length === 0)
                                                        }
                                                        loading={
                                                            isSend &&
                                                            createOrEditIsLoading
                                                        }
                                                        type="primary"
                                                        onClick={onSendTonBO}
                                                    >
                                                        Enviar para análise
                                                    </GreenButton>
                                                )}
                                                <WhiteButton
                                                    hoverColor="orange"
                                                    disabled={
                                                        ((exchange.sendValue
                                                            .status !== undefined &&
                                                            exchange.sendValue
                                                                .status !==
                                                            "draft") && !repeatedSendValueId) ||
                                                        createOrEditIsLoading ||
                                                        !isFormFilled
                                                        // || !operationNature
                                                        // || (operationNature.requireFiles && files.length === 0)
                                                    }
                                                    loading={
                                                        isDraft &&
                                                        createOrEditIsLoading
                                                    }
                                                    onClick={onDraft}
                                                >
                                                    Salvar rascunho
                                                </WhiteButton>
                                            </Space>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                        <ContactSendModal
                            visible={showContactModal}
                            onOk={(data) => {
                                onSelectContact(data.id);
                                form.setFieldsValue({
                                    recipient_name: data.id,
                                });
                                setShowContactModal(false);
                                dispatch(listContactSend());
                            }}
                            onCancel={() => {
                                setShowContactModal(false);
                            }}
                        />
                        <BankAccountSendModal
                            visible={showBankAccountModal}
                            isReceive={false}
                            data={null}
                            onOk={(data) => {
                                setShowBankAccountModal(false);
                                form.setFieldsValue({ accounts: `Carregando ...` });
                                dispatch(
                                    listBankAccount({ contactId: contactID })
                                ).then(() => {
                                    onSelectContactBank(data.id);
                                    form.setFieldsValue({
                                        accounts: data.id,
                                    });
                                });
                            }}
                            onCancel={() => {
                                setShowBankAccountModal(false);
                            }}
                        />
                        <ExchangeApprovedModal
                            isVisible={showApprovedModal}
                            onClose={() => setShowApprovedModal(false)}
                        />
                        <EfexModal
                            hasCancel={false}
                            visible={showModal}
                            centered={true}
                            onOk={() => setshowModal(false)}
                            onCancel={() => setshowModal(false)}
                            title="Atenção!"
                        >
                            <p>
                                Os valores do IR e IOF são apenas uma estimativa. Os
                                valores exatos serão exibidos assim que a operação
                                estiver disponível para fechamento.
                            </p>
                        </EfexModal>
                    </>
                )}
        </div>
    );
};
export default SendValue;
