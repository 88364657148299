import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StateStatus from "../../../utils/status";
import { postAddCadastralData, getCadastralData as getData, findCpf } from "./service";

const initialState = {
	status: {
		addCadastralData: StateStatus.idle,
		getCadastralData: StateStatus.idle,
		getFindCpf: StateStatus.idle,
	},
	cadastralData: null,
	errorMsg: null,
};

export const addCadastralData = createAsyncThunk(
	"firstStep/addCadastralData",
	async (data) => {
		data.cpf = JSON.parse(localStorage.getItem("person")).cpf;
		const response = await postAddCadastralData(data);
		return response.data;
	},
);

export const getCadastralData = createAsyncThunk(
	"firstStep/getCadastralData",
	async () => {
		const cpf = JSON.parse(localStorage.getItem("person")).cpf;
		const response = await getData(cpf);
		return response.data;
	},
);

export const getFindCpf = createAsyncThunk(
	"firstStep/getFindCpf",
	async (user_type) => {
		const cpf = JSON.parse(localStorage.getItem("person")).cpf;
		const response = await findCpf({"cpf": cpf, "user_type": user_type});
		return response.data;
	},
);

export const firstStep = createSlice({
	name: "firstStep",
	initialState,
	reducers: {
		resetStatusGetCadastralData: (state) => {
			state.status.getCadastralData = StateStatus.idle;
		},
	},
	extraReducers: {
		[addCadastralData.pending]: (state) => {
			state.status.addCadastralData = StateStatus.loading;
		},
		[addCadastralData.fulfilled]: (state) => {
			state.status.addCadastralData = StateStatus.succeeded;
		},
		[addCadastralData.rejected]: (state, action) => {
			state.status.addCadastralData = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[getCadastralData.pending]: (state) => {
			state.status.getCadastralData = StateStatus.loading;
		},
		[getCadastralData.fulfilled]: (state, action) => {
			state.status.getCadastralData = StateStatus.succeeded;
			state.cadastralData = action.payload;
		},
		[getCadastralData.rejected]: (state, action) => {
			state.status.getCadastralData = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[getFindCpf.pending]: (state) => {
			state.status.getFindCpf = StateStatus.loading;
		},
		[getFindCpf.fulfilled]: (state, action) => {
			state.status.getFindCpf = StateStatus.succeeded;
			state.getFindCpf = action.payload;
		},
		[getFindCpf.rejected]: (state, action) => {
			state.status.getFindCpf = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
	},
});
export const { resetStatusGetCadastralData } = firstStep.actions;
export const selectFirstStep = (state) => state.firstStep;
