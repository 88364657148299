/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Form,
  Select,
  Row,
  Col,
  Tooltip,
} from "antd";
import "./styles.scss";
import { AddCircleButton } from "../../../../../common/components/Button";

const SelectAndAddItem = ({
  name,
  label,
  placeholder,
  options,
  required,
  disabled,
  onChange,
  addBtnTitle,
  onAdd,
}) => {
  return (
    <Row align="middle" className="select-and-add-item" justify="space-between">
      <Col xs={21}>
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: required,
              message: "Campo obrigatório.",
            },
          ]}
        >
          <Select
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
          >
            {options.map((item, index) => (
              <Select.Option className={`${name}-option`} value={item.value} key={index}>
                {item.label}
              </Select.Option>
            )
            )}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={3} style={{ textAlign: "center" }}>
        <div style={{ marginTop: "8px" }}>
          <Tooltip title={addBtnTitle}>
            <AddCircleButton
              disabled={disabled}
              onClick={onAdd} />
          </Tooltip>
        </div>
      </Col>
    </Row>
  );
}

export default SelectAndAddItem;