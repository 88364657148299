import { useRouteMatch } from "react-router-dom";
import oprationsIcon from "../../../assets/operations.png";
import oprationsYellowIcon from "../../../assets/operations_yellow.png";

export const OprationsIcon = () => {
    let { path } = useRouteMatch();

    return (
        <>
            <img
                className="custom-icon"
                src={
                    path.match("empresas") ? oprationsYellowIcon : oprationsIcon
                }
            />
        </>
    );
};
