import { createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from '../../../utils/status';

import {
	getRatesReceiveValue,
	getReceiveValue as getReceive,
	postReceiveValueDraft,
	putReceiveValue,
	putRequestReceivementValue,
	patchRequestReceivementValue,
} from './service'

export const createReceiveValue = createAsyncThunk(
	"receive/createReceiveValue",
	async (data) => {
		const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;
		data.cpf_cnpj = cpf_cnpj;
		const response = await postReceiveValueDraft(data);
		return response.data;

	},
);

export const editReceiveValue = createAsyncThunk(
	"receive/editReceiveValue",
	async ({ receiveId, data }) => {
		const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;
		data.cpf_cnpj = cpf_cnpj;
		data.receive_value_id = receiveId;
		const response = await putReceiveValue(data);
		return response.data;
	},
);

export const getReceivedValue = createAsyncThunk(
	"receive/getReceivedValue",
	async (id) => {
		const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;
		const response = await getReceive(id, cpf_cnpj);
		return response.data;
	},
);

export const getReceivedValueBackground = createAsyncThunk(
	"receive/getReceivedValueBackground",
	async (id) => {
		const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;
		const response = await getReceive(id, cpf_cnpj);
		return response.data;
	},
);


export const requestReceivementValue = createAsyncThunk(
	"receive/requestReceivementValue",
	async ({ id, transactionDay, currency }) => {
		var data = {
			cpf_cnpj:
				JSON.parse(localStorage.getItem("owner")).cpf !== undefined
					? JSON.parse(localStorage.getItem("owner")).cpf
					: JSON.parse(localStorage.getItem("owner")).cnpj,
			receive_value_id: id,
			transaction_day: transactionDay,
			currency: currency
		};

		const response = await putRequestReceivementValue(data);
		return response.data;
	}
);

export const scheduleExchange = createAsyncThunk(
	'sendValues/scheduleExchange',
	async (data) => {
		const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;
		data.cpf_cnpj = cpf_cnpj;
		return await patchRequestReceivementValue(data);
	},
)

export const getRatesReceiveValues = createAsyncThunk(
	'receive/getRates',
	async ({ currency, receive_value_id, transaction_day }) => {
		const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;
		var params = { cpf_cnpj }
		if (currency !== undefined) {
			params = { ...params, currency }
		}
		if (transaction_day !== undefined) {
			params = { ...params, transaction_day }
		}
		if (receive_value_id !== undefined) {
			params = { ...params, receive_value_id }
		}
		const response = await getRatesReceiveValue(params)
		return response.data;
	}
)

export const receivedValuesReducers = {

	[createReceiveValue.pending]: (state) => {
		state.status.createReceiveValue = StateStatus.loading;
	},
	[createReceiveValue.fulfilled]: (state, action) => {
		state.status.createReceiveValue = StateStatus.succeeded;
		state.receivedValue = action.payload
	},
	[createReceiveValue.rejected]: (state, action) => {
		state.status.createReceiveValue = StateStatus.failed;
		state.errorMsg = action.error.message;
	},
	[editReceiveValue.pending]: (state) => {
		state.status.editReceiveValue = StateStatus.loading;
	},
	[editReceiveValue.fulfilled]: (state, action) => {
		state.status.editReceiveValue = StateStatus.succeeded;
		state.receivedValue = action.payload;
	},
	[editReceiveValue.rejected]: (state, action) => {
		state.status.editReceiveValue = StateStatus.failed;
		state.errorMsg = action.error.message;
	},
	[requestReceivementValue.pending]: (state) => {
		state.status.requestReceivementValue = StateStatus.loading;
	},
	[requestReceivementValue.fulfilled]: (state, action) => {
		state.status.requestReceivementValue = StateStatus.succeeded;
		state.receivedValue = action.payload
	},
	[requestReceivementValue.rejected]: (state, action) => {
		state.status.requestReceivementValue = StateStatus.failed;
		state.errorMsg = action.error.message;
	},
}