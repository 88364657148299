import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import "./styles.scss";
import { useLocation } from "react-router-dom";
import { handleResize } from "../../../../utils/responsible";

const { Step } = Steps;

const SideMenuExchange = (props) => {
  const { pathname } = useLocation()
  const [isFixed, setisFixed] = useState(false);
  const [active, setActive] = useState(0);
  const [isResponsible, updateResponsible] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', () => handleResize(updateResponsible))
    handleResize(updateResponsible)

    // window.addEventListener("scroll", () => {
    //   if (window.pageYOffset > 75 && !isResponsible) {
    //     if (!isFixed) {
    //       setisFixed(true);
    //     }
    //   } else {
    //     if (isFixed) {
    //       setisFixed(false);
    //     }
    //   }
    // });
  });

  useEffect(() => {
    props.items.forEach((item, index) => {
      if (pathname.includes(item.active_path)) {
        setActive(index)
      }
    })
  }, [pathname])

  return (
    <div className={`menu-exchange steps-card ${isFixed ? 'sticky' : ''} ${props.className}`}>
      {isResponsible ?
        <p className="subtitle" style={{ "textAlign": "center" }}>Menu</p>
        :
        <p className="subtitle" style={{ "textAlign": "end", "paddingRight": "18px" }}>Menu</p>
      }
      <Steps current={active} direction="vertical">
        {props.items.map((el, index) => <Step
          id={el.id || index}
          key={index}
          title={el.title}
          icon={el.icon}
          onClick={el.onClick}
        />)}
      </Steps>
    </div>
  );
};

export default SideMenuExchange;
