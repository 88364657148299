import React from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import "./styles.scss";
import { Button } from 'antd';


export const GreyButton = (props) => {
    const conditionalClass = props.sendDocuments ? 'send-documents-button' : '';

    return <button {...props} className={`efex-grey-button ${conditionalClass}`} >{props.loading ? <LoadingOutlined /> : null}{props.children}</button>
}

export const WhiteButton = (props) => {
    return <button {...props} className={`${props.hoverColor === "orange" ? "white-button-orange-hover" : "white-button-green-hover"} ${props.classes ?? ""} `} >{props.loading ? <LoadingOutlined /> : null}{props.children}
    </button>
}

export const OrangeButton = (props) => {
    return <button {...props} className={`efex-orange-button ${props.classes ?? ""}`} >{props.loading ? <LoadingOutlined /> : null}{props.children}</button>
}

export const GreenButton = (props) => {
    return <button {...props} className={`efex-green-button ${props.classes ?? ""}`} >{props.loading ? <LoadingOutlined /> : null}{props.children}</button>
}

export const AddCircleButton = (props) => {
    return (
        <Button {...props}
            className="efex-add-circle-button"
            shape="circle"
            icon={<PlusOutlined />}
        ></Button>
    )
}