import React from "react";
import { Typography, Row, Col } from "antd";
import "./styles.scss";
import RegisterSteps from "../../../../common/components/steps/steps";
import Routes from "../../../../routes/routes";
import { useDispatch, useSelector } from "react-redux";
import { listPeople, selectPerson } from "../../../Person/slice";
import { useEffect } from "react";
import StateStatus from "../../../../utils/status";

const { Title, Text } = Typography;

const PersonBase = ({ current, titleL3, text, children, history }) => {
    const person = useSelector(selectPerson);
    const dispatch = useDispatch();
    const items = [
        { title: "Dados cadastrais", route: Routes.firstStep, key: "started" },
        { title: "Objetivo das transações cambiais", route: Routes.secondStep, key: "completed_cadastral_data" },
        { title: "Confirmar dados", route: Routes.thirdStep, key: "completed_person_data" },
        { title: "Documentos", route: Routes.fourthStep, key: "accepted_review" },
    ];

    useEffect(() => {
        if (person.status.listPeople === StateStatus.idle) {
            dispatch(listPeople());
        }
    }, [dispatch, person, person.status.listPeople]);
    
    return (
        <div className="person-base-layout layout">
            <Row className="row-steps-content">
                <Col xs={24} sm={24} lg={5} className="col-steps">
                    <RegisterSteps
                        steps={person.person?.register_steps}
                        items={items}
                        className="steps-green"
                        current={current}
                        history={history}
                    ></RegisterSteps>
                </Col>
                <Col xs={24} sm={24} lg={19} className="col-content">
                    <div className="titulo-desc">
                        <Title className="person-title" level={3}>{titleL3}</Title>
                        <Text>{text}</Text>
                    </div>
                    {children}
                </Col>
            </Row>
        </div>
    );
};

export default PersonBase;
