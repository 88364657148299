import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, message, Table, Tabs } from "antd";
import { useRouteMatch } from "react-router-dom";
import { EditFilled } from "@ant-design/icons";
import {
    listReceiveValues,
    listSendValues,
    selectExchange,
    setReceivedValue,
} from "../slice";
import StateStatus from "../../../utils/status";
import { formatCurrencyBrl } from "../../../utils/functions";
import { history } from "../../../helpers/history";
import { getReceivedValue } from "../ReceiveValue/thunks";
import CircleTransactionStatus from "../../../common/components/CircleTransactionStatus";
import { OperationCard, TableCard } from "../../../common/components/TableCard";
import ChooseGridStyle from "../../../common/components/ChooseGridStyle";
import { handleResize } from "../../../utils/responsible";

const { TabPane } = Tabs;

const CompletedOperations = () => {
    const exchange = useSelector(selectExchange);
    const dispatch = useDispatch();
    let { url } = useRouteMatch();
    const [paginationSendValues, setPaginationSendValues] = useState();
    const [paginationReceiveValues, setPaginationReceiveValues] = useState();
    const [isResponsible, updateResponsible] = useState(false);
    const [gridType, setGridType] = useState("table");

    const redirectToSendValuePage = (id, status) => {
        const baseUrl = url.split("/cambio")[0];
        if (["draft", "need_changes"].includes(status)) {
            history.push(`${baseUrl}/cambio/enviar-valores/${id}`);
        } else {
            history.push(`${baseUrl}/cambio/operacao-envio/${id}`);
        }
    };

    const redirectToReceiveValuePage = (id, status) => {
        const baseUrl = url.split("/cambio")[0];
        history.push(`${baseUrl}/cambio/receber-valores/${id}`);
    };

    const columnsReceive = [
        {
            title: "Banco no Brasil",
            dataIndex: "receipt_account",
            render: (receipt) => {
                return (
                    <>
                        {receipt.bank_name} - {receipt.account_number}
                    </>
                );
            },
        },
        {
            title: "Valor",
            dataIndex: "",
            render: (_, record) => {
                var val = formatCurrencyBrl(`${record.target_value}`);
                return (
                    <>
                        {record.is_currency_target
                            ? `${record.currency} ${val}`
                            : `BRL ${val}`}
                    </>
                );
            },
        },
        {
            title: "Status",
            render: function (_, item) {
                return (
                    <div className="col-status">
                        <CircleTransactionStatus status={item.status} />
                        <div>{item.status_display}</div>
                    </div>
                );
            },
        },
        {
            title: "",
            fixed: "right",
            width: 100,
            render: function edit(_, item) {
                return (
                    <>
                        <EditFilled
                            style={{ fontSize: "18px" }}
                            onClick={() => {
                                dispatch(setReceivedValue(item));
                                dispatch(getReceivedValue(item.id));
                                redirectToReceiveValuePage(
                                    item.id,
                                    item.status
                                );
                            }}
                        />
                    </>
                );
            },
        },
    ];

    const columnsSend = [
        {
            title: "Beneficiário",
            dataIndex: "recipient",
            render: (recipient) => {
                return <>{recipient.name}</>;
            },
        },
        {
            title: "Banco no exterior",
            dataIndex: "recipient_account",
            render: (account) => {
                return <>{account.bank_name}</>;
            },
        },
        {
            title: "Valor",
            dataIndex: "",
            render: (_, record) => {
                var val = formatCurrencyBrl(`${record.target_value}`);
                return (
                    <>
                        {record.is_currency_target
                            ? `${record.recipient_account.currency} ${val}`
                            : `BRL ${val}`}
                    </>
                );
            },
        },
        {
            title: "Status",
            render: function (_, item) {
                return (
                    <div className="col-status">
                        <CircleTransactionStatus status={item.status} />
                        <div>{item.status_display}</div>
                    </div>
                );
            },
        },
        {
            title: "",
            fixed: "right",
            width: 100,
            render: function edit(_, record) {
                return (
                    <>
                        <EditFilled
                            style={{ fontSize: "18px" }}
                            onClick={() => {
                                message.loading({
                                    key: 1,
                                    content: "Carregando...",
                                });

                                redirectToSendValuePage(
                                    record.id,
                                    record.status
                                );
                            }}
                        />
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        dispatch(listSendValues({ page: 1, status: "finished" }));
        dispatch(listReceiveValues({ page: 1, status: "finished" }));
        window.addEventListener("resize", () =>
            handleResize(updateResponsible)
        );
        handleResize(updateResponsible);
    }, []);

    useEffect(() => {
        if (isResponsible && gridType !== "card") {
            setGridType("card");
        }
    }, [isResponsible]);

    useEffect(() => {
        if (exchange.status.listSendValues === StateStatus.succeeded) {
            setPaginationSendValues({
                current: exchange.listSendValues.pagination.current_page,
                total: exchange.listSendValues.pagination.total,
                pageSize: 15,
            });
        }
    }, [exchange.status.listSendValues]);

    useEffect(() => {
        if (exchange.status.listReceiveValues === StateStatus.succeeded) {
            setPaginationReceiveValues({
                current: exchange.listReceiveValues.pagination.current_page,
                total: exchange.listReceiveValues.pagination.total,
                pageSize: 15,
            });
        }
    }, [exchange.status.listReceiveValues]);

    return (
        <Card style={{ padding: "20px" }}>
            <Tabs
                defaultActiveKey="1"
                tabBarExtraContent={
                    <ChooseGridStyle
                        selectDefault={gridType}
                        onChange={(grid) => setGridType(grid)}
                    />
                }
            >
                <TabPane tab="Envios" key="1">
                    {gridType === "card" ? (
                        <TableCard
                            dataSource={exchange.listSendValues.lst || []}
                            loading={
                                exchange.status.listSendValues ===
                                StateStatus.loading
                            }
                            pagination={paginationSendValues}
                            onChange={(page) => {
                                dispatch(
                                    listSendValues({
                                        page,
                                        status: "finished",
                                    })
                                );
                            }}
                            render={(item, index) => (
                                <OperationCard
                                    data={{
                                        id: item.id,
                                        status: item.status,
                                        status_display: item.status_display,
                                        recipient_name: item.recipient.name,
                                        bank_name:
                                            item.recipient_account.bank_name,
                                        currency:
                                            item.recipient_account.currency,
                                        is_currency_target:
                                            item.is_currency_target,
                                        target_value: item.target_value,
                                    }}
                                    onClick={() => {
                                        redirectToSendValuePage(
                                            item.id,
                                            item.status
                                        );
                                    }}
                                />
                            )}
                        />
                    ) : (
                        <Table
                            dataSource={exchange.listSendValues.lst}
                            pagination={paginationSendValues}
                            onChange={(pagination) => {
                                dispatch(
                                    listSendValues({
                                        page: pagination.current,
                                        status: "finished",
                                    })
                                );
                            }}
                            columns={columnsSend}
                            loading={
                                exchange.status.listSendValues ===
                                StateStatus.loading
                            }
                        />
                    )}
                </TabPane>
                <TabPane tab="Recebimentos" key="2">
                    {gridType === "card" ? (
                        <TableCard
                            dataSource={exchange.listReceiveValues.lst || []}
                            loading={
                                exchange.status.listReceiveValues ===
                                StateStatus.loading
                            }
                            pagination={paginationReceiveValues}
                            onChange={(page) => {
                                dispatch(
                                    listReceiveValues({
                                        page,
                                        status: "finished",
                                    })
                                );
                            }}
                            render={(item, index) => {
                                return (
                                    <OperationCard
                                        data={{
                                            id: item.id,
                                            status: item.status,
                                            status_display: item.status_display,
                                            recipient_name: null,
                                            bank_name:
                                                item.receipt_account.bank_name,
                                            currency: item.currency,
                                            is_currency_target:
                                                item.is_currency_target,
                                            target_value: item.target_value,
                                        }}
                                        onClick={() => {
                                            redirectToReceiveValuePage(
                                                item.id,
                                                item.status
                                            );
                                        }}
                                    />
                                );
                            }}
                        />
                    ) : (
                        <Table
                            dataSource={exchange.listReceiveValues.lst}
                            pagination={paginationReceiveValues}
                            onChange={(pagination) => {
                                dispatch(
                                    listReceiveValues({
                                        page: pagination.current,
                                        status: "finished",
                                    })
                                );
                            }}
                            columns={columnsReceive}
                            loading={
                                exchange.status.listReceiveValues ===
                                StateStatus.loading
                            }
                        />
                    )}
                </TabPane>
            </Tabs>
        </Card>
    );
};

export default CompletedOperations;
