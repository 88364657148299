import { createAsyncThunk } from '@reduxjs/toolkit'
import { listNewNotifications, patchAllNotifications, patchNotification, getCompanyPersonUpdates, getReceiveValue as getReceive, getSendOperation } from './service'

export const newNotifications = createAsyncThunk(
    'notifications/newNotifications',
    async ({ page }) => {
        const response = await listNewNotifications(page);
        return response.data;
    }
);

export const readNotification = createAsyncThunk(
    'notification/readNotification',
    async ({ notification_id }) => {
        const response = await patchNotification(notification_id);
        return response.data;
    }
);

export const readAllNotifications = createAsyncThunk(
    'notification/readAllNotifications',
    async ({ read_all }) => {
        const response = await patchAllNotifications(read_all)
        return response.data
    }
)

export const companyPersonUpdates = createAsyncThunk(
    "notification/update",

    async (params) => {
        const response = await getCompanyPersonUpdates(params)

        return response.data
    }
)

export const getReceivedValue = createAsyncThunk(
    "receive/getReceivedValue",
    async ({ id, cpf_cnpj }) => {
        const response = await getReceive(id, cpf_cnpj);
        return response.data;
    },
);

export const getSendValue = createAsyncThunk(
    'sendValues/getSendValue',
    async ({ id, cpf_cnpj }) => {

        const response = await getSendOperation(id, cpf_cnpj);
        return response.data
    },
)
