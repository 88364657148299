import React from "react";
import { Typography, Form, Row, Col, Card } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";

const TransactionProof = ({ ...props }) => {
	return (
		<>
			<Row>
				<Col sm={24}>
					<Card>
						<Typography.Title level={5}>
							Enviar comprovante do depósito
						</Typography.Title>
						<Form.Item>
							<Dragger>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">
									Clique ou arraste os arquivos
								</p>
							</Dragger>
						</Form.Item>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default TransactionProof;
