import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StateStatus from "../../utils/status";
import { getTransactions, sendValueDuplicate, receiveValueDuplicate, getNeededUpdates } from "./service";

const initialState = {
  status: {
    listTopTransactions: StateStatus.idle,
    listStatusTransactions: StateStatus.idle,
    listLastTransactions: StateStatus.idle,
    duplicateTransaction: StateStatus.idle,
  },
  errorMsg: null,
  topTransactions: [],
  statusTransactions: [],
  lastTransactions: [],
  duplicatedTransaction: null,
  person: null,
};

export const listTopTransactions = createAsyncThunk(
  "home/listTopTransactions",
  async ({ order, sortBy }) => {
    const response = await getTransactions({
      owner_type: null,
      sort_by: sortBy ?? "value",
      sort_order: order ?? "DESC"
    });
    return response.data;
  });


export const listStatusTransactions = createAsyncThunk(
  "home/listStatusTransactions",
  async ({ order, sortBy, status, page }) => {
    const response = await getTransactions({
      owner_type: null,
      sort_by: sortBy ?? "status_updated_at",
      sort_order: order ?? "DESC",
      status: status ?? null,
      page
    });
    return response.data;
  });

export const listLastTransactions = createAsyncThunk(
  "home/listLastTransactions",
  async ({ order, sortBy }) => {
    const response = await getTransactions({
      owner_type: null,
      sort_by: sortBy ?? "created_at",
      sort_order: order ?? "DESC",
      status: [
        // "contract_signed",
        // "waiting_deposit_receipt",
        // "completed",
        "finished",
      ]
    });
    return response.data;
  });

export const duplicateTransaction = createAsyncThunk(
  'home/duplicatedTransaction',
  async ({ id, type, cpf_cnpj, owner_id, owner_type }) => {
    let response = null;

    if (type === "send_value") {
      response = await sendValueDuplicate({
        cpf_cnpj: cpf_cnpj,
        send_value_id: id
      });
    } else {
      response = await receiveValueDuplicate({
        cpf_cnpj: cpf_cnpj,
        receive_value_id: id
      });
    }

    return { type, owner_type, owner_id, id: response.data.id }
  },
)

export const resetTransactionsStates = createAsyncThunk(
  'home/resetTransactionsStates',
  async () => {
    return { listStatusTransactions: StateStatus.idle, listLastTransactions: StateStatus.idle };
  }
);

export const needChanges = createAsyncThunk(
  'home/needChanges',
  async () => {
    const response = await getNeededUpdates()

    return response.data
  }
)

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    resetDuplicateState: (state) => {
      state.status.duplicateTransaction = StateStatus.idle
    }
  },
  extraReducers: {
    // List top transactions
    [listTopTransactions.pending]: (state) => {
      state.status.listTopTransactions = StateStatus.loading;
    },
    [listTopTransactions.fulfilled]: (state, action) => {
      state.status.listTopTransactions = StateStatus.succeeded;
      state.topTransactions = action.payload.lst.slice(0, 9);
    },
    [listTopTransactions.rejected]: (state, action) => {
      state.status.listTopTransactions = StateStatus.failed;
      state.errorMsg = action.error.message;
    },

    // List transactions by status
    [listStatusTransactions.pending]: (state) => {
      state.status.listStatusTransactions = StateStatus.loading;
    },
    [listStatusTransactions.fulfilled]: (state, action) => {
      state.status.listStatusTransactions = StateStatus.succeeded;
      state.statusTransactions = action.payload.lst;
    },
    [listStatusTransactions.rejected]: (state, action) => {
      state.status.listStatusTransactions = StateStatus.failed;
      state.errorMsg = action.error.message;
    },

    // List last transactions
    [listLastTransactions.pending]: (state) => {
      state.status.listLastTransactions = StateStatus.loading;
    },
    [listLastTransactions.fulfilled]: (state, action) => {
      state.status.listLastTransactions = StateStatus.succeeded;
      state.lastTransactions = action.payload.lst.slice(0, 4);
    },
    [listLastTransactions.rejected]: (state, action) => {
      state.status.listLastTransactions = StateStatus.failed;
      state.errorMsg = action.error.message;
    },

    [duplicateTransaction.pending]: (state) => {
      state.status.duplicateTransaction = StateStatus.loading;
    },
    [duplicateTransaction.fulfilled]: (state, action) => {
      state.status.duplicateTransaction = StateStatus.succeeded;
      state.duplicatedTransaction = action.payload;
    },
    [duplicateTransaction.rejected]: (state, action) => {
      state.status.duplicateTransaction = StateStatus.failed;
      state.errorMsg = action.error.message;
    },
    [resetTransactionsStates.fulfilled]: (state, action) => {
      state.status.listStatusTransactions = action.payload.listStatusTransactions;
      state.status.listLastTransactions = action.payload.listLastTransactions;
    },
  },
});

export const { resetDuplicateState } = homeSlice.actions;
export const selectHome = (state) => state.home;
