import StateStatus from "../../utils/status";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCompanyProfile } from "./LoadingDataService";
import { strFormatTime } from "../../utils/functions";

const initialState = {
  status: StateStatus.idle,
  errorMsg: null,
  company: null,
};

export const getCompanyProfileSlice = createAsyncThunk(
  "loadingData/getCompanyProfileSlice",
  async () => {
    const response = await getCompanyProfile();
    return response.data;
  }
);

export const loadingData = createSlice({
  name: "loadingData",
  initialState: initialState,
  extraReducers: {
    [getCompanyProfileSlice.pending]: (state) => {
      state.status = StateStatus.loading;
    },
    [getCompanyProfileSlice.fulfilled]: (state, action) => {
      state.status = StateStatus.succeeded;
      // state.company = action.payload.companies[0];
      const company = action.payload.companies[0];
      localStorage.setItem('register_steps', JSON.stringify(company.register_steps));
      localStorage.setItem(
        "company",
        JSON.stringify({
          company_name: company.company_name,
          cnpj: company.cnpj,
          activity: company.cnaes.filter(
            (item) => item.is_principal === true
          )[0].description,
          juridical_nature_description: company.juridical_nature_description,
          country_name: company.addresses[0].country_name,
          init_date: strFormatTime(company.init_date),
          share_capital: company.share_capital,
          email: company.email,
          phone: company.phone_0,
          zipcode: company.addresses[0].zipcode,
          public_place: company.addresses[0].public_place,
          number: company.addresses[0].number,
          complement: company.addresses[0].complement,
          neighborhood: company.addresses[0].neighborhood,
          city_name: company.addresses[0].city_name,
          province: company.addresses[0].province,
        })
      );
    },
    [getCompanyProfileSlice.rejected]: (state, action) => {
      state.status = StateStatus.failed;
      state.errorMsg = action.error.message;
    },
  },
});

export const selectLoadingData = (state) => state.loadingData;
