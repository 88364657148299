import api from "../../service/api";
import apiRoutes from "../../service/apiRoutes";

export const getDocuments = (cnpj) => api.get(`${apiRoutes.documents}?cnpj=${cnpj}`);
export const sendDocuments = (cnpj, formData, config) => api.post(`${apiRoutes.documents}?cnpj=${cnpj}`, formData, config);
export const deleteDocument = (cnpj, config) => api.delete(`${apiRoutes.documents}?cnpj=${cnpj}`, config);

export const getQsaDocuments = (cnpj) => api.get(`${apiRoutes.qsaDocuments}?cnpj=${cnpj}`);
export const sendQsaDocuments = (cnpj, formData, config) => api.post(`${apiRoutes.qsaDocuments}?cnpj=${cnpj}`, formData, config);
export const deleteQsaDocument = (cnpj, config) => api.delete(`${apiRoutes.qsaDocuments}?cnpj=${cnpj}`, config);

export const checkDocuments = (cnpj, isUploading) => api.get(`${apiRoutes.documents}check_documents?cnpj=${cnpj}&is_uploading=${isUploading}`);