import React, { useEffect, useState } from "react";

import { Form, Button, Typography, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PersonBase from "../common/Base";
import StateStatus from "../../../utils/status";
import { beautifyCpfCnpj } from "../../../utils/beautify";
import { scrollUp } from "../../../utils/functions";
import { selectThirdStep, acceptReview } from "./slice";
import {
    selectPerson,
    getPerson,
    resetStatusGetPerson,
} from "../../Person/slice";
import {
    selectFirstStep,
    getCadastralData,
    resetStatusGetCadastralData,
} from "../FirstStep/slice";
import Routes from "../../../routes/routes";
import "./styles.scss";
import { EfexCard } from "../../../common/components/Card";
import { GreenButton, WhiteButton } from "../../../common/components/Button";

const { Title, Text } = Typography;

const ThirdStep = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const person = useSelector(selectPerson);
    const thirdStep = useSelector(selectThirdStep);
    const firstStep = useSelector(selectFirstStep);
    const [accepting, setAccepting] = useState(false);

    useEffect(() => {
        if (person.status.getPerson === StateStatus.idle) {
            dispatch(getPerson());
        }
    });

    useEffect(() => {
        if (firstStep.status.getCadastralData === StateStatus.idle) {
            dispatch(getCadastralData());
        }
    });

    useEffect(() => {
        if (
            thirdStep.status.acceptReview === StateStatus.succeeded &&
            accepting
        ) {
            setAccepting(false);
            props.history.push(Routes.fourthStep);
        }
    }, [accepting, props.history, thirdStep.status.acceptReview]);

    return (
        <>
            <PersonBase
                {...props}
                current={2}
                titleL3="Confirmar dados"
                text=""
            >
                <Form
                    form={form}
                    layout="vertical"
                    className="confirm-register"
                    requiredMark={false}
                >
                    <Row gutter={[8, 8]}>
                        <Col xs={24} sm={24} lg={12}>
                            <EfexCard
                                actions={[
                                    <Button
                                        loading={false}
                                        onClick={() => {
                                            props.history.push(
                                                Routes.firstStep
                                            );
                                            dispatch(resetStatusGetPerson());
                                            dispatch(
                                                resetStatusGetCadastralData()
                                            );
                                        }}
                                    >
                                        Editar
                                    </Button>,
                                ]}
                            >
                                {firstStep.status.getCadastralData ===
                                StateStatus.succeeded ? (
                                    <Col className="responsavel">
                                        <Title className="subtitle" level={5}>
                                            {person.person.name}
                                        </Title>
                                        <Text type="secondary">
                                            {beautifyCpfCnpj(
                                                firstStep.cadastralData.cpf
                                            )}
                                        </Text>
                                        <Text type="secondary">
                                            {firstStep.cadastralData.doc_type} -{" "}
                                            {firstStep.cadastralData.doc_number}
                                        </Text>
                                        <Text type="secondary">
                                            {
                                                firstStep.cadastralData
                                                    .addr_public_place
                                            }
                                            ,{" "}
                                            {
                                                firstStep.cadastralData
                                                    .addr_number
                                            }
                                            ,{" "}
                                            {
                                                firstStep.cadastralData
                                                    .addr_neighborhood
                                            }
                                            ,{" "}
                                            {
                                                firstStep.cadastralData
                                                    .addr_city_name
                                            }{" "}
                                        </Text>
                                        <Text type="secondary">
                                            {firstStep.cadastralData.email} -{" "}
                                            {firstStep.cadastralData.cell_phone && firstStep.cadastralData.cell_phone}
                                        </Text>
                                    </Col>
                                ) : (
                                    "Carregando..."
                                )}
                            </EfexCard>
                        </Col>
                    </Row>
                    <Form.Item className="btn-back-next">
                        <WhiteButton
                            hoverColor="green"
                            onClick={() => {
                                scrollUp();
                                props.history.push(Routes.secondStep);
                            }}
                        >
                            Voltar
                        </WhiteButton>
                        <GreenButton
                            style={{ fontWeight: "500", width: "100px" }}
                            type="primary"
                            htmlType="submit"
                            loading={
                                thirdStep.status.acceptReview ===
                                StateStatus.loading
                            }
                            onClick={() => {
                                setAccepting(true);
                                dispatch(
                                    acceptReview({ cpf: person.person.cpf })
                                );
                                // props.history.push(Routes.fourthStep);
                            }}
                        >
                            {thirdStep.status.acceptReview ===
                            StateStatus.loading
                                ? "Carregando"
                                : "Avançar"}
                        </GreenButton>
                    </Form.Item>
                </Form>
            </PersonBase>
        </>
    );
};

export default ThirdStep;
