import React, { useEffect, useState } from "react";
import {
    Form,
    Col,
    Row,
    Upload,
    Typography,
    message,
    notification,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import StateStatus from "../../../utils/status";
import PersonBase from "../common/Base";
import "./styles.scss";
import { selectPerson, getPerson } from "../../Person/slice";
import {
    selectFourthStep,
    getDocuments,
    sendDocument,
    delDocument,
    checkDocuments,
} from "./slice";
import Routes from "../../../routes/routes";
import {
    GreenButton,
    GreyButton,
} from "../../../common/components/Button";
import { EfexCard } from "../../../common/components/Card";

const { Title } = Typography;

const FourthStep = (props) => {
    const person = useSelector(selectPerson);
    const fourthStep = useSelector(selectFourthStep);
    const [docsSent, setDocsSent] = useState([]);
    const dispatch = useDispatch();
    const [isUploading, setIsUploading] = useState(false);

    const register_update = person.person?.register_update

    useEffect(() => {
        dispatch(getPerson()).then(() => dispatch(getDocuments()))
    }, [dispatch])

    useEffect(() => {
        if (person.status.getPerson === StateStatus.idle) dispatch(getPerson());
    }, [dispatch]);

    const customRequestQsa = ({
        file,
        data,
        onProgress,
        onSuccess,
        onError,
    }) => {
        setIsUploading(true);
        var docs = [...docsSent, data.doc_type];
        setDocsSent(docs);
        var formData = new FormData();
        formData.append("file", file);
        formData.append("doc_type", data.doc_type);
        register_update ? formData.append("is_uploading", true) : formData.append("is_uploading", false);

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        dispatch(
            sendDocument({
                formData,
                config,
                onSuccess: () => {
                    onSuccess("Ok");
                    setIsUploading(false);
                    message.success(`documento enviado com sucesso.`);
                },
                onError: (err) => {
                    onError({ err });
                    setIsUploading(false);
                    message.error(`falha no envio do documento.`);
                },
            })
        );
    };

    const handleRemove = (file, doc) => {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("doc_type", doc.key);
        const index = docsSent.indexOf(doc.key);
        const updatedDocsSent = [...docsSent];
        if (index !== -1) updatedDocsSent.splice(index, 1);
        setDocsSent(updatedDocsSent);

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        };
        dispatch(
            delDocument({
                config: config,
                is_uploading: register_update ? true : false,
            })
        );
    };

    return (
        <PersonBase
            {...props}
            titleL3="Upload dos documentos"
            text={register_update ? "Envie os documentos necessários para finalizar a atualização do seu cadastro."
                : "Envie os documentos necessários para finalizar o seu cadastro."}
            current={3}
        >
            <Col className="fourth-step-upload" xs={24} sm={24} lg={14}>
                <Title className="subtitle" level={5}>
                    {person.status.getPerson === StateStatus.succeeded
                        ? person.person.name
                        : null}
                </Title>
                <EfexCard className="doc-card">
                    {fourthStep.status.getDocuments === StateStatus.succeeded
                        ? fourthStep.docs.docs_to_send.map((doc, key) => (
                            <Form.Item
                                className="doc-item"
                                key={key}
                                rules={[
                                    {
                                        required: true,
                                        message: "Documento obrigatório",
                                    },
                                ]}
                            >
                                <Upload
                                    beforeUpload={beforeUpload}
                                    data={{
                                        doc_type: doc.key,
                                    }}
                                    multiple={true}
                                    className={`${doc.key}-doc-upload`}
                                    accept=".pdf, .png, .jpg, .jpeg"
                                    customRequest={customRequestQsa}
                                    showUploadList={{
                                        showRemoveIcon: !isUploading,
                                    }}
                                    progress={{
                                        strokeColor: {
                                            '0%': '#87d068',
                                            '100%': '#87d068',
                                        },
                                        strokeWidth: 2,
                                        format: () => null,
                                    }}
                                    onRemove={(file) => handleRemove(file, doc)}
                                >
                                    <p className="doc-name">{doc.value}</p>
                                    <GreyButton disabled={isUploading}>
                                        Enviar documento{" "}
                                        <UploadOutlined />
                                    </GreyButton>
                                </Upload>
                            </Form.Item>
                        ))
                        : null}
                </EfexCard>
            </Col>
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                {fourthStep.status.getDocuments === StateStatus.succeeded ? (
                    <GreenButton
                        loading={fourthStep.status.checkDocuments === StateStatus.loading}
                        style={{ fontWeight: "500", width: "100px" }}
                        type="primary"
                        htmlType="submit"
                        onClick={() => {
                            dispatch(checkDocuments({ is_uploading: register_update ? true : false })).then((value) => {
                                if (value.payload.docs_to_send.length === 0) {
                                    props.history.replace({
                                        pathname: Routes.finishedRegister,
                                        state: { type: "person" }
                                    });
                                } else {
                                    notification.warning({
                                        message: `Atenção!`,
                                        description: "Envie todos os documentos necessários.",
                                        placement: "topRight",
                                    });
                                }
                            });
                        }}
                    >
                        Finalizar
                    </GreenButton>
                ) : null}
            </Row>
        </PersonBase>
    );
};

export default FourthStep;

const beforeUpload = (file) => {
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
        message.error("O Arquivo precisa ser menor que 20Mb!", 5);
        return false;
    }
    return true;
};
