import { WhatsAppOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import "./styles.scss";

const WhatsAppIcon = () => {
    return (
        <Tooltip title="Precisa de ajuda?">
            <a
                href="https://wa.me/551132535628"
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-icon"
            >
                <WhatsAppOutlined />
            </a>
        </Tooltip>
    );
};

export default WhatsAppIcon;
