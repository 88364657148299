/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Progress, Typography, Card, Form, Select, notification } from "antd";
import { formatCurrency, formatCurrencyExchange } from "../../../utils/functions";
import { useDispatch } from "react-redux";
import { getRatesSendValuesBatch, getRatesReceiveValuesBatch } from "./thunk";
import "./styles.scss";
import NotOpenToExchange from "../NotOpenToExchange";
import { CanCloseToday, isCommercialHourRange } from "../../../utils/date";
import TooltipEfex from "../Tooltip";
import { SyncOutlined } from "@ant-design/icons";

const CloseSeveralExchanges = ({
    maxTime,
    valueToSend = 0,
    isCurrencyTarget,
    currency,
    taxes,
    onChangeSelect = (data) => { },
    selectedSendDate,
    transaction_day,
    commercialHour = false,
    getRates = false,
    selectedExchanges,
    typeOfExchange,
    exchanges,
    setTotalValueBrl,
    setTotalValueME,
    setIsFreeze,
    isFreeze,
    isClosed,
    isScheduled
}) => {
    const [timer, updateTimer] = useState(-1);
    const [timeout, updateTimeOut] = useState(0);
    const [progress, updateProgress] = useState(100);
    const [calcValues, updateCalcValues] = useState({
        toMe: 0, toBrl: 0,
        iof: { value: 0, tax: 0 },
        ir: { value: 0, tax: 0 },
        tarifa: { value: 0, tax: 0 }
    });
    const dispatch = useDispatch();
    const [closeToday, setCloseToday] = useState(false);
    const [showSelect, setShowSelect] = useState(false);
    const [rates, setRates] = useState([])
    const [currencyPrices, setCurrencyPrices] = useState([])
    const [IOF, updateIOF] = useState(0)
    const [IR, updateIR] = useState(0)
    const [tarifa, updateTarifa] = useState(0)
    const [finalValue, updateFinalValue] = useState(0)
    const [finalSendValue, updateFinalSendValue] = useState()
    const [thereCurrencyReceive, setThereCurrencyReceive] = useState(false);
    const [thereCurrencySend, setThereCurrencySend] = useState(false);

    const calcFromME = () => {
        const calculatedValuesAccumulator = {};

        currencyPrices.forEach(({ id, currencyPrice }) => {
            const sendValue = valueToSend.find((item) => item.id === id);
            const taxesInfo = taxes.find((item) => item.id === id)?.taxes || [];

            let finalValue = 0;
            let iof = 0;
            let ir = 0;
            let tarifa = 0;

            const iofTax = taxesInfo.find((tax) => tax.name.toUpperCase() === "IOF")?.tax / 10000 || 0;
            const irTax = taxesInfo.find((tax) => tax.name.toUpperCase() === "IR")?.tax / 100000000 || 0;

            if (sendValue && currencyPrice > 0) {
                const valueMe = sendValue.sendValue / 100;
                tarifa = taxesInfo.find((tax) => tax.name.toUpperCase() === "TARIFA")?.value / 100 || 0;

                iof = (valueMe * currencyPrice) * iofTax;
                ir = (valueMe * currencyPrice) * irTax;

                iof = Math.trunc(iof * 10000) / 10000;
                ir = Math.trunc(ir * 10000) / 10000;

                finalValue = (valueMe * currencyPrice) + iof + ir + tarifa;
                finalValue = Math.trunc(finalValue * 10000) / 10000;
            }

            calculatedValuesAccumulator[id] = {
                id,
                toBrl: finalValue.toFixed(2),
                iof: {
                    value: iof.toFixed(2),
                    tax: (iofTax * 100).toFixed(2),
                },
                ir: {
                    value: ir.toFixed(2),
                    tax: (irTax * 100).toFixed(2),
                },
                tarifa: {
                    value: tarifa.toFixed(2),
                    tax: null,
                },
                vet: currencyPrice,
            };
        });

        const calcValuesArray = Object.values(calculatedValuesAccumulator);
        updateCalcValues(calcValuesArray);

        const totalIOF = calcValuesArray.reduce((acc, item) => acc + parseFloat(item.iof.value), 0);
        const totalIR = calcValuesArray.reduce((acc, item) => acc + parseFloat(item.ir.value), 0);
        const totalTarifa = calcValuesArray.reduce((acc, item) => acc + parseFloat(item.tarifa.value), 0);
        const totalFinalValue = calcValuesArray.reduce((acc, item) => acc + parseFloat(item.toBrl), 0);

        updateIOF(totalIOF.toFixed(2));
        updateIR(totalIR.toFixed(2));
        updateTarifa(totalTarifa.toFixed(2));
        updateFinalValue(totalFinalValue.toFixed(2));
        setTotalValueBrl(totalFinalValue.toFixed(2))
        updateFinalSendValue(valueToSend.reduce((acc, item) => acc + item.sendValue, 0))
        setTotalValueME(valueToSend.reduce((acc, item) => acc + item.sendValue, 0))

        return Object.values(calculatedValuesAccumulator);
    };


    const calcFromBrl = () => {
        const calculatedValuesAccumulator = {};

        currencyPrices.forEach(({ id, currencyPrice }) => {
            const sendValue = valueToSend.find((item) => item.id === id);
            const taxesInfo = taxes.find((item) => item.id === id)?.taxes || [];

            let finalValue = 0;
            let iof = 0;
            let ir = 0;
            let tarifa = 0;

            const iofTax = taxesInfo.find((tax) => tax.name.toUpperCase() === "IOF")?.tax / 10000 || 0;
            const irTax = taxesInfo.find((tax) => tax.name.toUpperCase() === "IR")?.tax / 100000000 || 0;

            if (!isCurrencyTarget && sendValue && currencyPrice > 0) {
                const valueBrl = sendValue.sendValue / 100;
                tarifa = taxesInfo.find((tax) => tax.name.toUpperCase() === "TARIFA")?.value / 100 || 0;

                let calc = valueBrl / (1 + iofTax + irTax);
                calc = Math.trunc(calc * 10000) / 10000;

                iof = calc * iofTax;
                ir = calc * irTax;

                iof = Math.trunc(iof * 10000) / 10000;
                ir = Math.trunc(ir * 10000) / 10000;

                finalValue = calc / currencyPrice;
                finalValue = Math.trunc(finalValue * 10000) / 10000;
            } else {
                iof = 0;
                ir = 0;
                tarifa = 0;
            }

            calculatedValuesAccumulator[id] = {
                id,
                toMe: finalValue.toFixed(2),
                iof: {
                    value: iof.toFixed(2),
                    tax: (iofTax * 100).toFixed(2),
                },
                ir: {
                    value: ir.toFixed(2),
                    tax: (irTax * 100).toFixed(2),
                },
                tarifa: {
                    value: tarifa.toFixed(2),
                    tax: null,
                },
                vet: currencyPrice,
            };
        });

        const calcValuesArray = Object.values(calculatedValuesAccumulator);
        updateCalcValues(calcValuesArray);

        const totalIOF = calcValuesArray.reduce((acc, item) => acc + parseFloat(item.iof.value), 0);
        const totalIR = calcValuesArray.reduce((acc, item) => acc + parseFloat(item.ir.value), 0);
        const totalTarifa = calcValuesArray.reduce((acc, item) => acc + parseFloat(item.tarifa.value), 0);
        const totalFinalValue = calcValuesArray.reduce((acc, item) => acc + parseFloat(item.toMe), 0);

        updateIOF(totalIOF.toFixed(2));
        updateIR(totalIR.toFixed(2));
        updateTarifa(totalTarifa.toFixed(2));
        updateFinalValue(totalFinalValue.toFixed(2));


        return Object.values(calculatedValuesAccumulator);
    };


    const fetchRates = () => {
        setIsFreeze(true)
        if (typeOfExchange === "send_value") {
            var send_value_ids = selectedExchanges.join(',');
            dispatch(getRatesSendValuesBatch({ send_value_ids, currency: currency, transaction_day })).then(({ payload, error }) => {
                if (payload !== undefined) {
                    setThereCurrencySend(true)
                    setRates(payload.rates)
                    setIsFreeze(false)
                    updateTimer(maxTime)
                } else {
                    notification.error({
                        message: `Erro!`,
                        description: error.message,
                        placement: "topRight",
                    })
                }
            });
        } else {
            var receive_value_ids = selectedExchanges.join(',')
            dispatch(getRatesReceiveValuesBatch({ receive_value_ids, currency: currency, transaction_day })).then(({ payload, error }) => {
                if (payload !== undefined) {
                    setThereCurrencyReceive(true)
                    setRates(payload.rates)
                    setIsFreeze(false)
                    updateTimer(maxTime)
                } else {
                    notification.error({
                        message: `Erro!`,
                        description: error.message,
                        placement: "topRight",
                    })
                }
            })
        }
    }



    useEffect(() => {
        const updatedCurrencyPrices = [];
        for (const [key, value] of Object.entries(rates)) {
            updatedCurrencyPrices.push({ id: Number(key), currencyPrice: value / 10000 });
        }
        setCurrencyPrices(updatedCurrencyPrices);
    }, [rates]);

    useEffect(() => {
        CanCloseToday().then((response) => {
            setCloseToday(response.data.can_close_today)
        })
        isCommercialHourRange().then((response) => {
            setShowSelect(response.data.is_open_to_exchange)
        })
    }, []);

    useEffect(() => {
        fetchRates()

    }, [selectedSendDate])

    useEffect(() => {
        if (isCurrencyTarget) {
            calcFromME()
        } else {
            calcFromBrl()
        }
        updateFinalSendValue(valueToSend.reduce((acc, item) => acc + item.sendValue, 0))
    }, [currency, currencyPrices, valueToSend, isCurrencyTarget, taxes])

    useEffect(() => {
        if (!isFreeze && timer <= 0) {
            setIsFreeze(true)
            updateTimer(maxTime);
        }
    }, [exchanges.success, isFreeze, timer]);

    useEffect(() => {
        if (selectedSendDate) {
            if (!isFreeze) {
                if (timer > 0) {
                    clearTimeout(timeout);
                    updateTimeOut(
                        setTimeout(() => {
                            updateTimer(timer - 1);
                        }, 1000),
                    );
                } else {
                    fetchRates()
                }
                updateProgress((100 * timer) / maxTime);
            }
        }
    }, [timer, isFreeze]);

    useEffect(() => {
        if (selectedSendDate) {
            if (getRates) {
                updateTimer(maxTime)
                updateCalcValues({
                    toMe: 0, toBrl: 0,
                    iof: { value: 0, tax: 0 },
                    ir: { value: 0, tax: 0 }
                })
                fetchRates()
            }
        }
    }, [getRates, selectedSendDate])

    return (
        <div>
            <Card className={`card-pricesend-simulate ${exchanges?.lst[0].status !== "approved" ? 'card-centered' : ''}`} data-simulation={false}>
                <Typography.Title level={4} className="title-box">{typeOfExchange === "send_value" ? "Cotação" : "Remessa Aprovada"}</Typography.Title>
                <br />
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}>
                    {(thereCurrencyReceive || thereCurrencySend) && showSelect && exchanges?.lst[0].status === "approved" && !isClosed ? (
                        <Form.Item

                            className="send-day-form"
                            label={
                                <>
                                    Escolha abaixo a data para o envio dos recursos:
                                    <TooltipEfex
                                        className="tooltip"
                                        title="A liquidação no mesmo dia só pode ser contratada até às 12:00h.
										Atenção:  data de liquidação dos recursos pode sofrer alterações quando houver feriado bancários nos Estados Unidos.
										Caso tenha alguma dúvida, basta falar conosco pelo chat."
                                    />
                                </>
                            }
                            name="send_date"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Por gentileza informe a data para o envio",
                                },
                            ]}
                        >
                            <Select onChange={onChangeSelect} disabled={isFreeze}>
                                <Select.Option value="D0" disabled={!closeToday}>
                                    Enviar os recursos hoje
                                </Select.Option>
                                <Select.Option value="D1" className="select-send-date-option">
                                    Enviar os recursos no próximo dia útil
                                </Select.Option>
                                <Select.Option value="D2">
                                    Enviar os recursos em 2 dias úteis
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    ) : null}
                </Col>
                {!commercialHour ? <NotOpenToExchange isScheduled={isScheduled} sendValue={typeOfExchange === "receive_value" ? true : false} currency={currency} /> : <Row gutter={[8, 8]} justify="space-between">
                    {!isClosed && exchanges?.lst[0].status === "approved" ? (
                        <Col xs={11} sm={11} md={11} lg={8}
                            className="box-currency-progress">
                            {(thereCurrencyReceive || thereCurrencySend) && !selectedSendDate ? null :
                                isFreeze ? <SyncOutlined spin style={{ color: "white", fontSize: "30px" }} /> :
                                    <Progress
                                        type="circle"
                                        width={60}
                                        status={timer <= 5 ? "exception" : "success"}
                                        percent={progress}
                                        format={(percent) => `${timer}s`}
                                    />}
                            <div className="box-value">
                                <label>Seu câmbio</label>
                                <div>
                                    <span>
                                        {((thereCurrencyReceive || thereCurrencySend) && !selectedSendDate) || isFreeze ? "" : formatCurrencyExchange(calcValues[0]?.vet * 10000, "")}
                                    </span>

                                </div>
                            </div>
                        </Col>) : null}
                    <Col
                        gutter={24}
                    >
                        {typeOfExchange === "send_value" ? (
                            <Row className="box-exchange-value" justify="center">
                                <Typography.Title level={5}>Convertemos para</Typography.Title>
                                <div className="box-value">
                                    {((thereCurrencyReceive || thereCurrencySend) && !selectedSendDate) || isFreeze ? <span /> :
                                        <span>
                                            {!isCurrencyTarget
                                                ? formatCurrency(finalValue, currency)
                                                : formatCurrency(finalSendValue, currency)}
                                        </span>}
                                </div>
                            </Row>
                        ) : (
                            <Row className="box-exchange-value" justify="center">
                                <div>
                                    <Typography.Title level={5}>Você Recebe</Typography.Title>
                                    <div className="box-value">
                                        {((thereCurrencyReceive || thereCurrencySend) && !selectedSendDate) || isFreeze ? <span /> :
                                            <span>
                                                {!isCurrencyTarget
                                                    ? formatCurrency(finalValue, currency)
                                                    : formatCurrency(finalSendValue, currency)}
                                            </span>}
                                    </div>
                                </div>
                            </Row>
                        )}
                        {typeOfExchange === "send_value" ? (
                            <Row className="box-exchange-value" justify="center">
                                <Typography.Title level={5}>Você transfere</Typography.Title>
                                <div className="box-value">
                                    {((thereCurrencyReceive || thereCurrencySend) && !selectedSendDate) || isFreeze ? <span /> :
                                        <span>
                                            {isCurrencyTarget ? (
                                                formatCurrency(finalValue, "BRL")
                                            ) : (
                                                formatCurrency(finalSendValue, "BRL")
                                            )}
                                        </span>}
                                </div>

                                <div className="box-exchange-taxes">
                                    {((thereCurrencyReceive || thereCurrencySend) && !selectedSendDate) || isFreeze ? <span /> :
                                        <span>
                                            <p>Impostos e tarifas inclusos no valor final:</p>
                                            {IOF > 0 && (
                                                <p>
                                                    IOF: {formatCurrency(IOF, "BRL")}
                                                </p>
                                            )}
                                            {IR > 0 && (
                                                <p>
                                                    IR: {formatCurrency(IR, "BRL")}
                                                </p>
                                            )}
                                            {tarifa > 0 && (
                                                <p>
                                                    Tarifa: {formatCurrency(tarifa, "BRL")}
                                                </p>
                                            )}
                                        </span>}
                                </div>
                            </Row>
                        ) : (
                            <Row className="box-exchange-value" justify="center">
                                <Typography.Title level={5}>Convertemos para</Typography.Title>
                                <div className="box-value">
                                    {((thereCurrencyReceive || thereCurrencySend) && !selectedSendDate) || isFreeze ? <span /> :
                                        <span>
                                            {isCurrencyTarget ? (
                                                formatCurrency(finalValue, "BRL")
                                            ) : (
                                                formatCurrency(finalSendValue, "BRL")
                                            )}
                                        </span>}
                                </div>

                                <div className="box-exchange-taxes">
                                    {((thereCurrencyReceive || thereCurrencySend) && !selectedSendDate) || isFreeze ? <span /> :
                                        <span>
                                            <p>Impostos e tarifas inclusos no valor final:</p>
                                            {IOF > 0 && (
                                                <p>
                                                    IOF: {formatCurrency(IOF, "BRL")}
                                                </p>
                                            )}
                                            {IR > 0 && (
                                                <p>
                                                    IR: {formatCurrency(IR, "BRL")}
                                                </p>
                                            )}
                                            {tarifa > 0 && (
                                                <p>
                                                    Tarifa: {formatCurrency(tarifa, "BRL")}
                                                </p>
                                            )}
                                        </span>}
                                </div>
                            </Row>
                        )}

                    </Col>
                </Row>
                }
            </Card>
            <div className="price-variation-info">
                "* Eventualmente a taxa de câmbio aplicada e os valores de impostos poderão sofrer pequenas alterações após a confirmação do fechamento."
            </div>

            {
                <div className="price-variation-info"></div>

            }
        </div>

    );
};

export default CloseSeveralExchanges;