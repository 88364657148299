import Routes from "../routes/routes";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useQuery = () => {
	const { search } = useLocation();

	return useMemo(() => new URLSearchParams(search), [search]);
}

export const strFormatTime = (str) => {
	// str.replace('-', '/');
	var aux = str.split("-");

	return `${aux[2]}/${aux[1]}/${aux[0]}`;
};

export const strFormatTimeApi = (str) => {
	// str.replace('-', '/');
	var aux = str.split("/");

	return `${aux[2]}-${aux[1]}-${aux[0]}`;
};

// export const parseCurrencyStrToFloat = (str) => {
// 	let val = str;
// 	if (typeof str === "string") {
// 		val = str.replace(/[^0-9.-]+/g, "");
// 		if (val.includes(".")) {
// 			val = val.replace(".", "");
// 		}
// 		return Number(val);
// 	} else {
// 		return parseInt(val * 100);
// 	}
// };

export const sizedNullList = (size) => {
	const resultList = [];
	for (let i = 0; i <= size; i++) {
		resultList.push(null);
	}
	return resultList;
};

export const CheckSteps = (steps, history, id, updates) => {
	let lastStep = steps[steps.length - 1].key;
	let docsSended = 0;
	let update_docs = updates[0]?.company_incorporation_document ||
		updates[0]?.board_election_minutes ||
		updates[0]?.representative_proof_of_address ||
		updates[0]?.representative_identity_document ||
		updates[0]?.financial_documentation

	steps.forEach((el) => {
		if (el.key === "sended_company_docs" || el.key === "sended_qsa_docs") {
			docsSended++
		};
	});
	if (lastStep === "finalized") {
		history.push(`/dashboard/empresas/${id}/cambio`);
	} else if (localStorage.getItem("access_token") === undefined) {
		history.push(Routes.login);
	} else if (lastStep === "started" || updates[0]?.registration_form) {
		history.push(Routes.legalPerson);
	} else if (updates[0]?.kyc) {
		history.push(Routes.kyc)
	} else if (lastStep === "completed_company_data" || updates[0]?.representative_form) {
		history.push(Routes.selectPartners);
	} else if (lastStep === "completed_financial_responsible") {
		history.push(Routes.confirmRegister);
	} else if (lastStep === "accepted_review" || docsSended < 2 || update_docs) {
		history.push(Routes.uploadFile);
	} else if (lastStep === "sended_all_docs") {
		history.push({
			pathname: Routes.finishedRegister,
			state: { type: "company" }
		});
	}


};

export const PersonCheckStep = (step, history, id, updates) => {
	let update_docs = updates?.identity_document ||
		updates?.financial_documentation ||
		updates?.proof_of_address
	if (localStorage.getItem("access_token") === undefined) {
		history.push(Routes.login);
	} else if (step === "started" || updates?.registration_form) {
		history.push(Routes.firstStep);
	} else if (step === "completed_cadastral_data" || updates?.kyc) {
		history.push(Routes.secondStep);
	} else if (step === "completed_person_data") {
		history.push(Routes.thirdStep);
	} else if (step === "accepted_review" || update_docs) {
		history.push(Routes.fourthStep);
	} else if (
		step === "sended_person_docs" ||
		// step === "waiting_sign_documents" ||
		step === "signed_docs"
	) {
		history.push({
			pathname: Routes.finishedRegister,
			state: { type: "person" }
		});
	} else if (step === "finalized") {
		history.push(`/dashboard/pessoas/${id}/cambio`);
	} else {
		// history.push(Routes.firstStep);
	}
};

export const UpdatePerson = (updates) => {
	let update_docs = updates.some(update =>
		["Atualizar comprovante de endereço", "Atualizar documento de identidade", "Atualizar documentação financeira"].includes(update)
	)

	if (updates.includes("Atualizar ficha de cadastro")) {
		return Routes.firstStep
	} else if (updates.includes("Atualizar KYC")) {
		return Routes.secondStep
	} else if (update_docs) {
		return Routes.fourthStep
	}
}

export const UpdateCompany = (updates) => {
	let update_docs = updates.some(update =>
		["Atualizar documento de constituição da empresa",
			"Atualizar Ata de Eleição da Diretoria",
			"Atualizar comprovante de endereço do representante",
			"Atualizar documento de identidade do representante",
			"Atualizar documentação financeira"].includes(update)
	)

	if (updates.includes("Atualizar ficha de cadastro")) {
		return Routes.legalPerson
	} else if (updates.includes("Atualizar KYC")) {
		return Routes.kyc
	} else if (updates.includes("Atualizar ficha de representante")) {
		return Routes.selectPartners
	} else if (update_docs) {
		return Routes.uploadFile
	}
}

export const scrollUp = () => {
	window.scrollTo({ top: 0 });
};

export const hideDigits = (number) => {
	if (number.length === 14) {
		return `***.***.${number.substring(8, 11)}-**`;
	} else {
		return `**.***.${number.substr(7, 10)}/****-**`;
	}
};

export const maskPhone = (str) => {
	if (str == null) {
		return ''
	}

	str = str.replace(/-/g, '');

	if (str.startsWith('55')) {
		str = str.slice(2);
		return `+55 (${str.substring(0, 2)}) ${str.substring(2, 7)}-${str.substring(7)}`;
	}

	return `(${str.substring(0, 2)}) ${str.substring(2, 7)}-${str.substring(
		7,
	)}`;
};

export const formatCurrencyBrl = (value) => {
	if (value.length === 1) {
		return `$ 0,0${value}`;
	}
	if (value.length === 2) {
		return `$ 0,${value}`;
	}

	const prefixValue = value
		.slice(0, -2)
		.split("")
		.reverse()
		.map((item, key) => {
			if (key % 3 === 0 && key !== 0) {
				return `${item}.`;
			}
			return item;
		})
		.reverse()
		.join("");

	return `$ ${prefixValue},${value.slice(-2)}`;
};

const currencyPrefix = {
	BRL: "BRL",
	USD: "USD",
	CAD: "CAD",
	EUR: "EUR",
	GBP: "GBP",
	CHF: "CHF",
	JPY: "JPY",
};

export const formatCurrencyPatrimony = (val, prefix) => {
	if (val) {
		if (val.length <= 2) {
			if (val.includes('-')) {
				return `${prefix} -0,${val.padStart(2, 0).replace('-', '')}`;
			} else {
				return `${prefix} 0,${val.padStart(2, 0)}`;
			}
		}
		if (val.length <= 3 && val.includes('-')) {
			return `${prefix} -0,${val.padStart(2, 0).replace('-', '')}`;
		}

		const prefixValue = val
			.slice(0, -2)
			.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

		return `${prefix} ${prefixValue},${val.slice(-2).replace(/\.(?=\d{0,2}$)/g, ",")}`;
	}
}

export const formatCurrency = (initalValue, currency, precision = 2) => {
	if (typeof initalValue === "string") {
		initalValue = initalValue.replace(new RegExp(/[.,]/g), "");
	}

	if (
		initalValue === undefined ||
		initalValue === null ||
		initalValue === "" ||
		isNaN(initalValue)
	) {
		initalValue = 0;
	}
	const value = parseInt(initalValue).toString();
	const prefix = currencyPrefix[currency] ?? currency ?? "$";

	if (value.length === 0) return "";
	if (value.length <= precision) return `${prefix} 0,${value.padStart(precision, 0)}`;

	const prefixValue = value
		.slice(0, -precision)
		.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	return `${prefix} ${prefixValue},${value.slice(-precision)}`;
};

export const formatCurrencyExchange = (initalValue, currency) => {
	if (typeof initalValue === "string") {
		initalValue = initalValue.replace(new RegExp(/[.,]/g), "");
	}

	if (
		initalValue === undefined ||
		initalValue === null ||
		initalValue === "" ||
		isNaN(initalValue)
	) {
		initalValue = 0;
	}

	const value = parseInt(initalValue).toString();
	const prefix = currencyPrefix[currency] ?? "$";

	if (value.length === 0) return "";
	if (value.length <= 4) return `${prefix} 0,${value.padStart(4, 0)}`;

	const prefixValue = value.replace(value.substring(value.length - 4), "")
	return `${prefix} ${prefixValue},${value.substring(value.length - 4)}`;
};

export const getColorByStatus = (status) => {
	switch (status) {
		case null:
		case "draft":
		case "waiting_approval":
		case "need_changes":
		case "docs_approved":
		case "created_by_bo":
			return "red";
		case "approved":
		case "waiting_exchange":
		case "making_exchange":
		case "waiting_contract":
		case "waiting_sign_doc":
		case "contract_signed":
		case "completed":
			return "yellow";
		case "finished":
			return "green";
		default:
			return "grey";
	}
}
