import StateStatus from "../../utils/status";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { forgot } from "./ForgotService";

const initialState = {
  status: StateStatus.idle,
  errorMsg: null,
};

export const forgotThunk = createAsyncThunk("forgot/sendEmail", async (data) => {
  try {
    const response = await forgot(data);
    return response.data;
  } catch (err) {
    const customError = {
      name: "Api Error",
      message:
        err.response && err.response.status < 500
          ? err.response.data.msg
          : "Erro no sistema, tente mais tarde!",
      data: err.response.data,
    };
    throw customError;
  }
});

export const forgotSlice = createSlice({
  name: "forgotSlice",
  initialState: initialState,
  extraReducers: {
    [forgotThunk.pending]: (state) => {
      state.status = StateStatus.loading;
    },
    [forgotThunk.fulfilled]: (state) => {
      state.status = StateStatus.succeeded;
    },
    [forgotThunk.rejected]: (state, action) => {
      state.status = StateStatus.failed;
      state.errorMsg = action.error.message;
    },
  },
});

export const selectForgot = (state) => state.forgot;
