import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StateStatus from "../../../utils/status";
import { Typography, Row, Col, Divider, Table, message, Select, Checkbox } from "antd";
import { beautifyCpfCnpj } from "../../../utils/beautify";
import "./styles.scss";
import {
    getExchangeOwner,
    listBankAccountReceive,
    removeBankAccountReceive,
    resetStatusRemoveBankAccountReceive,
    selectExchange,
    updateCompanyData,
} from "../slice";
import { formatCurrency, maskPhone } from "../../../utils/functions";
import { DeleteOutlined, EditOutlined, SyncOutlined } from "@ant-design/icons";
import BankAccountReceiveModal from "../_components/BankAccountReceiveModal";
import { EfexCard } from "../../../common/components/Card";
import { OrangeButton, WhiteButton } from "../../../common/components/Button";
import SelectAccountToReceive from "../../../common/components/SelectAccountToReceive";
import TooltipEfex from "../../../common/components/Tooltip";
import EfexModal from "../../../common/components/Modal";
import {
    getNotificationThunk,
    patchNotificationThunk,
    selectNotification,
} from "../../Notifications/notificationsSlice";
import {
    listStatusTransactions,
    resetGetCompany,
    resetGetCompanyByCnpj,
    setCnpj,
    setCompany,
} from "../../ChooseCompany/ChooseCompanySlice";
import InfiniteScrollList from "../../../common/components/InfiniteScrollList";
import { sendValueAndReceiveValueStatus } from "../../../utils/consts";
import { useHistory, useRouteMatch } from "react-router-dom";
import { UpdateContacts } from "../_components/UpdateContacts";

const { Title } = Typography;

const ProfileCompany = () => {
    const exchange = useSelector(selectExchange);
    const notifications = useSelector(selectNotification);
    const company = { company: exchange.owner };
    const dispatch = useDispatch();
    const [createBankAccount, setCreateBankAccount] = useState(false);
    const [editBankAccount, setEditBankAccount] = useState([]);
    const [_, update] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [statusTransactions, setStatusTransactions] = useState([]);
    const [page, setPage] = useState(1);
    const [isEmpty, setIsEmpty] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [filteredStatus, setFilteredStatus] = useState(null);
    const [filteredOrder, setFilteredOrder] = useState("DESC");
    const [contactModalVisible, setContactModalVisible] = useState(false)
    const [updatetModalVisible, setUpdatetModalVisible] = useState(false)
    const [typeOfUpdate, setTypeOfUpdate] = useState("")
    let { path } = useRouteMatch();
    const history = useHistory();

    const getMoreStatusTransactions = (pageNone) => {
        dispatch(
            listStatusTransactions({
                cnpj: company?.company.cnpj,
                page: pageNone || page + 1,
                status: filteredStatus,
                order: filteredOrder,
            })
        ).then((resp) => {
            if (resp.payload) {
                if (
                    resp.payload.pagination.total_pages <
                    resp.payload.pagination.current_page
                ) {
                    setHasMore(false);
                } else {
                    setPage(resp.payload.pagination.current_page);
                    setStatusTransactions((old) => [
                        ...old,
                        ...resp.payload.lst,
                    ]);
                }
            }
        });
    };

    const handleCheckboxChange = (e) => {
        setTypeOfUpdate(e.target.value);
    };

    useEffect(() => {
        dispatch(setCnpj(company?.company.cnpj))
        dispatch(setCompany(company?.company))
    }, [company, dispatch])

    useEffect(() => {
        if (company.company?.register_update?.length > 0) {
            history.push("/dashboard/empresas");
        }
    }, [company, history])

    useEffect(() => {
        dispatch(listStatusTransactions({ cnpj: company?.company.cnpj })).then(
            (resp) => {
                if (resp.payload) {
                    setStatusTransactions(resp.payload.lst);
                }
            }
        );
    }, [company.cnpj, company?.company.cnpj, dispatch]);

    useEffect(() => {
        dispatch(getNotificationThunk());
    }, []);

    useEffect(() => {
        if (notifications.notification.notification) {
            setShowModal(true);
        }
    }, [notifications.notification?.notification]);

    useEffect(() => {
        if (exchange.status.getOwner === StateStatus.idle) {
            dispatch(getExchangeOwner());
        }
    });

    useEffect(() => {
        if (exchange.status.listBankAccountReceive === StateStatus.idle) {
            dispatch(listBankAccountReceive());
            update(true);
        }
    });

    useEffect(() => {
        if (
            exchange.status.removeBankAccountReceive === StateStatus.succeeded
        ) {
            message.success({
                key: "delete-msg",
                content: "Conta bancária excluída",
                duration: 3,
            });
            dispatch(listBankAccountReceive());
            dispatch(resetStatusRemoveBankAccountReceive());
        }

        if (exchange.status.removeBankAccountReceive === StateStatus.failed) {
            message.error({
                key: "delete-msg",
                content:
                    "Esta conta bancária está atrelada a operações e por isso não pôde ser excluída",
                duration: 5,
            });
            dispatch(resetStatusRemoveBankAccountReceive());
        }
    }, [exchange.status.removeBankAccountReceive]);

    const handleContactUpdate = () => {
        dispatch(getExchangeOwner({
            isPerson: path.match("pessoas"),
            id: exchange?.owner.id,
        }))
    };

    const columnsAccounts = [
        { title: "Banco", dataIndex: "bank_name" },
        { title: "Agência", dataIndex: "agency_number" },
        { title: "Número da conta", dataIndex: "account_number" },
        {
            title: "",
            render: (item) => (
                <>
                    <Row justify="space-around">
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <EditOutlined
                                onClick={() => {
                                    let show = [];
                                    show[item.id] = true;
                                    setEditBankAccount(show);
                                }}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <DeleteOutlined
                                onClick={() => {
                                    message.loading({
                                        key: "delete-msg",
                                        content: "Excluindo conta bancária...",
                                        duration: 0,
                                    });
                                    dispatch(removeBankAccountReceive(item.id));
                                }}
                            />
                        </Col>
                    </Row>
                    <BankAccountReceiveModal
                        visible={editBankAccount[item.id]}
                        data={item}
                        onOk={() => {
                            let show = [];
                            show[item.id] = false;
                            setEditBankAccount(show);
                        }}
                        onCancel={() => {
                            let show = [];
                            show[item.id] = false;
                            setEditBankAccount(show);
                        }}
                    />
                </>
            ),
        },
    ];

    return (
        <div className="company-profile">
            <Row
                gutter={[16, 16]}
                style={{ marginTop: 16, alignItems: "flex-start" }}
            >
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    className="stretch-card"
                    style={{ gap: 10, flexDirection: "column" }}
                >
                    <EfexCard className="company-data">
                        <Title level={5}>Dados cadastrais</Title>
                        <Divider />
                        {company.company && (
                            <Row gutter={[8, 8]}>
                                <Col xs={24} xl={12}>
                                    <div>
                                        <label>Razão social</label>
                                        <p>{company.company.company_name}</p>
                                    </div>
                                    <div>
                                        <label>CNPJ</label>
                                        <p>
                                            {company.company &&
                                                beautifyCpfCnpj(
                                                    company.company.cnpj
                                                )}
                                        </p>
                                    </div>
                                    <div>
                                        <label>IBAN</label>
                                        <p>
                                            {company.company.iban_code
                                                ? company.company.iban_code
                                                : " - "}
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={24} xl={12}>
                                    <div>
                                        <label>Nome fantasia</label>
                                        <p>
                                            {company.company.fantasy_name
                                                ? company.company.fantasy_name
                                                : " - "}
                                        </p>
                                    </div>
                                    <div>
                                        <label>E-mail</label>
                                        <p>{company.company.email}</p>
                                    </div>
                                    <div>
                                        <label>Telefones</label>
                                        <p>
                                            {maskPhone(
                                                company.company.phone_0 || ""
                                            )}{" "}
                                            <br />
                                            {maskPhone(company.company.phone_1)}
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <Row justify="space-around">
                                        <Col>
                                            <WhiteButton
                                                onClick={() => setContactModalVisible(true)}
                                            ><EditOutlined /> Alterar informações de contatos</WhiteButton>
                                        </Col>
                                        <Col>
                                            <WhiteButton
                                                onClick={() => setUpdatetModalVisible(true)}
                                            ><EditOutlined /> Atualizar cadastro</WhiteButton>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </EfexCard>
                    {company?.company?.operational_limit > 0 && (

                        <EfexCard className="company-data">
                            <Row
                                align="middle"
                                style={{
                                    justifyContent: "space-between",
                                }}
                            >
                                <Title level={5}>
                                    {
                                        <>
                                            Limite operacional para envio de recursos
                                            <TooltipEfex
                                                title="Esse é o limite disponível para envio de recursos para o exterior. 
                                            As operações de recebimento não serão consideradas para esse limite. 
                                            Caso deseje aumentar seu limite, por favor, entre em contato com nossa equipe pelo chat."
                                            />
                                        </>
                                    }
                                </Title>
                            </Row>
                            <Divider />

                            <Col xs={24} xl={12}>
                                <div>
                                    <p>USD{formatCurrency(
                                        company?.company?.operational_limit,
                                        null
                                    )}</p>
                                </div>
                            </Col>
                        </EfexCard>
                    )}
                    <EfexCard>
                        <Row
                            align="middle"
                            style={{
                                justifyContent: "space-between",
                                marginBottom: "16px",
                            }}
                        >
                            <Title level={5}>
                                {
                                    <>
                                        Contas correntes cadastradas no Brasil
                                        <TooltipEfex
                                            title="Por favor, insira os dados bancários da empresa cadastrada 
                                    para transferirmos os reais nos casos de recebimentos do exterior."
                                        />
                                    </>
                                }
                            </Title>
                            <OrangeButton
                                size="small"
                                type="primary"
                                style={{ width: "140px", fontWeight: "500" }}
                                onClick={() => setCreateBankAccount(true)}
                            >
                                Adicionar conta
                            </OrangeButton>
                        </Row>
                        <Divider />
                        <BankAccountReceiveModal
                            visible={createBankAccount}
                            data={{}}
                            onOk={() => {
                                setCreateBankAccount(false);
                            }}
                            onCancel={() => {
                                setCreateBankAccount(false);
                            }}
                        />
                        <Table
                            className="bank-account-table"
                            pagination={false}
                            columns={columnsAccounts}
                            dataSource={exchange.bankAccounts}
                            loading={
                                exchange.status.listBankAccountReceive ===
                                StateStatus.loading
                            }
                        />
                    </EfexCard>
                </Col>
                <Col
                    xs={24}
                    lg={12}
                    className="stretch-card"
                    style={{ gap: 8, flexDirection: "column" }}
                >
                    <EfexCard className="card-transactions">
                        <div>
                            <Row
                                justify="space-between"
                                className="transaction-status-header"
                            >
                                <Col xs={24} sm={8} md={8} lg={8}>
                                    <Title level={4}>
                                        Status das transações
                                    </Title>
                                </Col>
                                <Col
                                    xs={24}
                                    sm={16}
                                    md={16}
                                    lg={16}
                                    className="selects-col"
                                >
                                    <Row
                                        className="filter-selects"
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <Col>
                                            <Select
                                                defaultValue="Todos status"
                                                placeholder="ordenar"
                                                className="transactions-select"
                                                dropdownStyle={{
                                                    minWidth: "250px",
                                                }}
                                                placement="bottomRight"
                                                size="small"
                                                onChange={(value) => {
                                                    setHasMore(true)
                                                    setPage(1);
                                                    setStatusTransactions([]);
                                                    setFilteredStatus(value);
                                                    setIsEmpty(false);
                                                    dispatch(
                                                        listStatusTransactions({
                                                            status: value,
                                                            order: filteredOrder,
                                                            cnpj: company
                                                                ?.company.cnpj,
                                                        })
                                                    ).then((resp) => {
                                                        if (resp.payload) {
                                                            setStatusTransactions(
                                                                resp.payload.lst
                                                            );
                                                            if (
                                                                resp.payload.lst
                                                                    .length ===
                                                                0
                                                            ) {
                                                                setIsEmpty(
                                                                    true
                                                                );
                                                            }
                                                        }
                                                    });
                                                }}
                                                options={
                                                    sendValueAndReceiveValueStatus
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Select
                                                defaultValue="DESC"
                                                placeholder="ordenar"
                                                className="transactions-select"
                                                size="small"
                                                onChange={(value) => {
                                                    setHasMore(true)
                                                    setPage(1);
                                                    setStatusTransactions([]);
                                                    setFilteredOrder(value);
                                                    setIsEmpty(false);
                                                    dispatch(
                                                        listStatusTransactions({
                                                            order: value,
                                                            status: filteredStatus,
                                                            cnpj: company
                                                                ?.company.cnpj,
                                                        })
                                                    ).then((resp) => {
                                                        if (resp.payload) {
                                                            setStatusTransactions(
                                                                resp.payload.lst
                                                            );
                                                            if (
                                                                resp.payload.lst
                                                                    .length ===
                                                                0
                                                            ) {
                                                                setIsEmpty(
                                                                    true
                                                                );
                                                            }
                                                        }
                                                    });
                                                }}
                                            >
                                                <Select.Option value="DESC">
                                                    Recentes
                                                </Select.Option>
                                                <Select.Option value="ASC">
                                                    Antigas
                                                </Select.Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Divider></Divider>
                            </Row>
                        </div>

                        {!isEmpty ? (
                            <InfiniteScrollList
                                list={statusTransactions}
                                nextFn={getMoreStatusTransactions}
                                isStatus={true}
                                // isResponsible={isResponsible}
                                endMessage={
                                    <h4
                                        className="seeAllButtonCompany"
                                        onClick={() =>
                                            history.push(
                                                `/dashboard/empresas/${company.company.id}/cambio/operacoes-abertas`
                                            )
                                        }
                                    >
                                        Ver todas as transações
                                    </h4>
                                }
                            />
                        ) : isEmpty && !filteredStatus ? (
                            <p
                                style={{
                                    textAlign: "center",
                                    color: "#ccc",
                                }}
                            >
                                Você ainda não possui nenhuma transação
                            </p>
                        ) : (
                            <h4 style={{ textAlign: "center" }}>
                                Não encontrado
                            </h4>
                        )}
                    </EfexCard>
                    <EfexCard>
                        <Row>
                            <Title level={5}>
                                {
                                    <>
                                        Contas para receber no Brasil
                                        <TooltipEfex
                                            title="Escolha abaixo a moeda a ser recebida, 
                                        para verificar os dados bancários para os quais o valor precisará ser transferido.
                                        Não se esqueça de passar essas informações para o pagador no exterior, ok?"
                                        />
                                    </>
                                }
                            </Title>
                        </Row>
                        <Divider />
                        <SelectAccountToReceive user={company.company} />
                    </EfexCard>
                </Col>
            </Row>
            <EfexModal
                hasCancel={false}
                visible={showModal}
                centered={true}
                onOk={() => {
                    setShowModal(false);
                    dispatch(
                        patchNotificationThunk(
                            notifications.notification?.notification.id
                        )
                    );
                }}
                onCancel={() => {
                    setShowModal(false);
                    dispatch(
                        patchNotificationThunk(
                            notifications.notification?.notification.id
                        )
                    );
                }}
                title="Nova ordem recebida!"
            >
                <p>
                    Identificamos que uma nova ordem de pagamento foi recebida.
                    Você já pode verificar os detalhes dessa remessa na sua
                    lista de recebimentos, pelo link de operações, ali no menu
                    lateral.
                </p>
            </EfexModal>
            <UpdateContacts
                contactModalVisible={contactModalVisible}
                setContactModalVisible={setContactModalVisible}
                company={company?.company}
                onContactUpdate={handleContactUpdate}
            />
            <EfexModal
                visible={updatetModalVisible}
                centered={true}
                onOk={() => {
                    dispatch(updateCompanyData({
                        company_id: company?.company.id,
                        juridical_nature: company?.company.juridical_nature,
                        is_movement_less_15: company?.company.is_movement_less_15,
                        type_of_update: typeOfUpdate
                    })).then((resp) => {
                        if (resp.payload) {
                            resetGetCompanyByCnpj()
                            setUpdatetModalVisible(false)
                            history.push("/dashboard/empresas/inserir-cadastro")
                        }
                    })
                }}
                onCancel={() => setUpdatetModalVisible(false)}
                title="Deseja atualizar as infomações?"
            >
                <p>
                    Se proseguir, só será possível fechar operações dessa empresa após a atualização ser encerrada.
                </p>
                <h3 style={{ marginTop: "10px" }}>Escolha o Tipo de Atualização:</h3>
                <Row>
                    <Col span={24}>
                        <Checkbox
                            value="company"
                            onChange={handleCheckboxChange}
                            checked={typeOfUpdate === "company"}
                        >
                            Atualizar dados da empresa
                        </Checkbox>
                    </Col>
                    <Col span={24}>
                        <Checkbox
                            value="finresp"
                            onChange={handleCheckboxChange}
                            checked={typeOfUpdate === "finresp"}
                        >
                            Atualizar dados dos representante financeiros
                        </Checkbox>
                    </Col>
                </Row>


            </EfexModal>
        </div>
    );
};

export default ProfileCompany;
