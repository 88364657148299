import api from "../../../../service/api"
import apiRoutes from "../../../../service/apiRoutes"

export const postContactsSend = async (data) => api.post(`${apiRoutes.contactSend}`, data)
export const getContactSendList = async (cpf_cnpj) => api.get(`${apiRoutes.contactSend}/list?cpf_cnpj=${cpf_cnpj}`)
export const getContactSend = async (cpf_cnpj, id) => api.get(`${apiRoutes.contactSend}?cpf_cnpj=${cpf_cnpj}&id=${id}`)
export const putContactSend = async (data) => api.put(`${apiRoutes.contactSend}`, data)
export const deleteContactSend = async (cpf_cnpj, contactId) => api.delete(`${apiRoutes.contactSend}?cpf_cnpj=${cpf_cnpj}&id=${contactId}`)

export const getBankAccounts = async (cpf_cnpj, contactId) => api.get(`${apiRoutes.contactSendBankAccount}/list?cpf_cnpj=${cpf_cnpj}&contact_id=${contactId}`)
export const postBankAccount = async (data) => api.post(`${apiRoutes.contactSendBankAccount}`, data)
export const putBankAccount = async (data) => api.put(`${apiRoutes.contactSendBankAccount}`, data)
export const getBankAccount = async (cpf_cnpj, id) => api.get(`${apiRoutes.contactSendBankAccount}?cpf_cnpj=${cpf_cnpj}&id=${id}`)
export const deleteBankAccount = async (cpf_cnpj, id) => api.delete(`${apiRoutes.contactSendBankAccount}?cpf_cnpj=${cpf_cnpj}&id=${id}`)