import StateStatus from "../../utils/status";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sendCompanyExtraData } from "./LegalPersonExtraService";

const initialState = {
  status: StateStatus.idle,
  errorMsg: null,
  companyExtra: null,
};

export const sendCompanyExtraDataSlice = createAsyncThunk(
  "companyExtraData/sendCompanyExtraDataSlice",
  async (data) => {
    const response = await sendCompanyExtraData(data);
    localStorage.setItem("companyExtra", JSON.stringify(data));
    return response.data;
  }
);

export const companyExtraData = createSlice({
  name: "companyExtraData",
  initialState: initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = StateStatus.idle;
    }
  },
  extraReducers: {
    [sendCompanyExtraDataSlice.pending]: (state) => {
      state.status = StateStatus.loading;
    },
    [sendCompanyExtraDataSlice.fulfilled]: (state) => {
      state.status = StateStatus.succeeded;
    },
    [sendCompanyExtraDataSlice.rejected]: (state, action) => {
      state.status = StateStatus.failed;
      state.errorMsg = action.error.message;
    },
  },
});

export const { resetStatus } = companyExtraData.actions;

export const selectCompanyExtraData = (state) => state.companyExtraData;
