import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createFile } from "../../../slice";
import { Typography, Form, Upload, Modal, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import EfexModal from "../../../../../common/components/Modal";
import { useRouteMatch } from "react-router-dom";
import TooltipEfex from "../../../../../common/components/Tooltip";
import { formatCurrency } from "../../../../../utils/functions";

const { Dragger } = Upload;

const DepositReceiptsModal = ({ valueBRL, value, currency, isVisible, isLoading, onOk, onCancel, transaction_day }) => {
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch();

    const { success } = Modal

    const successMessage = () => {
        success({
            content:
                <>
                    Muito obrigado pelo envio do comprovante da transferência.
                    Agora estamos preparando seu contrato de câmbio, que será enviado para assinatura por e-mail,
                    dentro das próximas horas.
                </>,
        });
    };

    const customRequest = ({ file, onProgress, onSuccess, onError }) => {
        var formData = new FormData();
        formData.append("file", file);

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        dispatch(
            createFile({
                formData: formData,
                config: config,
                onSuccess: (response) => {
                    setFiles([
                        ...files,
                        {
                            uid: response.data.file_id,
                            name: file.name,
                            url: "#",
                        },
                    ]);
                    onSuccess(message.success(`documento enviado com sucesso.`));
                },
                onError: (err) => {
                    onError({ err })
                    message.error(`falha no envio do documento.`)
                },
            })
        );
    };
    let { path } = useRouteMatch();

    return (
        <EfexModal
            title={"Seu câmbio foi fechado com sucesso"}
            visible={isVisible}
            confirmLoading={isLoading}
            cancelText="Enviar depois"
            okText="Enviar agora"
            onCancel={onCancel}
            classes="send-deposit-receipt-button"
            onOk={() => {
                successMessage()
                onOk({ files })
            }}
            okButtonProps={{
                disabled: files.length === 0,
            }}
            className={
                path.match("empresas") ? "modal-company" : "modal-person"
            }
        > {currency === "USD" && transaction_day === "D0" && (
            <p className="subtitle">Atenção: para podermos enviar os recursos ainda hoje, os reais precisam ser transferidos até às 14:00h, e o contrato de câmbio enviado por e-mail deverá ser assinado até às 16:30h. Combinado?</p>
        )}
            <p className="subtitle">Você receberá em alguns minutos um email confirmando o fechamento.</p>
            <Typography.Title level={5}>
                {
                    <>
                        Dados para depósito dos Reais
                        <TooltipEfex title="Agora, precisamos que você transfira os Reais para o canal bancário abaixo e nos encaminhe o comprovante.
                        Não se preocupe, pois você pode anexar o comprovante da transferência depois. 
                        Não esqueça que precisaremos dele antes de enviarmos o contrato de câmbio para assinatura, combinado?"/>
                    </>
                }
            </Typography.Title>
            <p>
                <strong>Banco:</strong> Banco BS2
            </p>
            <p>
                <strong>Nº Banco:</strong> 218
            </p>
            <p>
                <strong>Favorecido:</strong> Banco BS2
            </p>
            <p>
                <strong>CNPJ:</strong> 71.027.866/0001-34
            </p>
            <p>
                <strong>Agência:</strong> 0001
            </p>
            <p>
                <strong>Conta nº:</strong> 14601-3
            </p>
            <p>
                <strong>Chave Pix:</strong> cambio@bs2.com
            </p>
            <p>
                <strong>Valor:</strong> {formatCurrency(String(valueBRL), "BRL")}
            </p>
            <p>
                <strong>Moeda estrangeira:</strong> {formatCurrency(String(value), currency)}
            </p>
            <br />
            <Typography.Title level={5}>
                Enviar comprovante de depósito
            </Typography.Title>
            <br />
            <Form.Item>
                <Dragger
                    customRequest={customRequest}
                    fileList={files}
                    onRemove={(remove) => {
                        setFiles(
                            files.filter((item) => item.uid !== remove.uid)
                        );
                    }}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                        Clique ou arraste os arquivos
                    </p>
                </Dragger>
            </Form.Item>
        </EfexModal >
    );
};

export { DepositReceiptsModal };
