import { Form, Input, notification } from "antd";
import { GreenButton, WhiteButton } from "../../../../common/components/Button";
import EfexModal from "../../../../common/components/Modal";
import br from "world_countries_lists/data/countries/en/world.json";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import TooltipEfex from "../../../../common/components/Tooltip";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateCompanyContacts, updatePersonContacts } from "../../slice";

export const UpdateContacts = ({
    contactModalVisible,
    setContactModalVisible,
    person,
    company,
    onContactUpdate,
}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const [valueCountryNumber, setValueCountryNumber] = useState({
        short: "BR",
        code: 55,
        phone: null,
    });
    const [isLoading, setIsLoading] = useState(false)

    const onFinish = (values) => {
        const cellPhone = form.getFieldValue("cell_phone") || form.getFieldValue("phone_0");
        const email = form.getFieldValue("email");

        if (!cellPhone && !email) {
            form.setFields([
                {
                    name: person ? "cell_phone" : "phone_0",
                    errors: ["Por favor, insira pelo menos um número de telefone ou um e-mail."],
                },
                {
                    name: "email",
                    errors: ["Por favor, insira pelo menos um número de telefone ou um e-mail."],
                },
            ]);
            return;
        }

        let data = { ...values };

        if (!email) {
            delete data.email;
        }

        if (cellPhone && typeof cellPhone === "object") {
            if (person) {
                data.cell_phone = `${cellPhone.code}-${cellPhone.phone}`;
            } else {
                data.phone_0 = `${cellPhone.code}-${cellPhone.phone}`;
            }
        }

        if (!cellPhone?.phone) {
            if (person) {
                delete data.cell_phone;
            } else {
                delete data.phone_0;
            }
        }

        setIsLoading(true);

        const updateContacts = person ? updatePersonContacts : updateCompanyContacts;
        const id = person ? { cpf: person.cpf } : { cnpj: company.cnpj };

        dispatch(updateContacts({ ...data, ...id })).then((resp) => {
            setIsLoading(false);
            if (resp.payload) {
                onContactUpdate();
                setContactModalVisible(false);
            } else {
                notification.error({
                    message: "Erro!",
                    description: resp.error.message,
                    placement: "topRight",
                });
            }
        });
    };

    return (
        <EfexModal
            className={"modal-person"}
            visible={contactModalVisible}
            form={form}
            onCancel={() => {
                setContactModalVisible(false);
                form.resetFields();
            }}
            title="Atualizar informações de contato"
            footer={
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                    }}
                >
                    <WhiteButton onClick={() => setContactModalVisible(false)}>
                        Cancelar
                    </WhiteButton>
                    <div style={{ width: "10px" }}></div>
                    <GreenButton loading={isLoading} onClick={form.submit}>
                        OK
                    </GreenButton>
                </div>
            }
        >
            <div
                style={{
                    fontSize: "14px",
                    lineHeight: "25px",
                    marginBottom: "20px",
                }}
            >
                <p>Atenção! Mantenha suas informações de contato sempre atualizadas</p>
            </div>

            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Form.Item
                    label="Telefone"
                    name={person ? "cell_phone" : "phone_0"}
                >
                    <ConfigProvider locale={br}>
                        <CountryPhoneInput
                            className="register-country-phone"
                            onChange={(v) => {
                                form.setFieldsValue({
                                    [person ? "cell_phone" : "phone_0"]: v,
                                });
                                setValueCountryNumber(v);
                            }}
                            value={valueCountryNumber}
                        />
                    </ConfigProvider>
                </Form.Item>
                <Form.Item
                    label={
                        <>
                            E-mail
                            <TooltipEfex title="Enviaremos a ficha cadastral e os contratos de câmbio para assinatura" />
                        </>
                    }
                    name="email"
                    rules={[
                        {
                            type: "email",
                            message: "Informe um email válido.",
                        },
                    ]}
                >
                    <Input placeholder="E-mail" type="email"></Input>
                </Form.Item>
            </Form>
        </EfexModal>
    );
};
