import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StateStatus from "../../../utils/status";
import { postDocument, getDocuments as getDocs, deleteDocument } from "./service";

const initialState = {
	status: {
		sendDocument: StateStatus.idle,
		getDocuments: StateStatus.idle,
		delDocument: StateStatus.idle,
		checkDocuments: StateStatus.idle
	},
	docs: null,
	errorMsg: null,
};

export const delDocument = createAsyncThunk(
	"fourthStep/delDocument",
	async ({ config, is_uploading }) => {
		try {
			const cpf = JSON.parse(localStorage.getItem("person")).cpf;
			const response = await deleteDocument(cpf, config, is_uploading);
			return response.data;
		} catch (err) {
			throw err;
		}
	},
);


export const sendDocument = createAsyncThunk(
	"fourthStep/sendDocument",
	async ({ formData, config, onSuccess, onError }) => {
		try {
			const cpf = JSON.parse(localStorage.getItem("person")).cpf;
			const response = await postDocument(cpf, formData, config);
			onSuccess();
			return response.data;
		} catch (err) {
			onError(err);
			throw err;
		}
	},
);

export const getDocuments = createAsyncThunk(
	"fourthStep/getDocuments",
	async () => {
		const cpf = JSON.parse(localStorage.getItem("person")).cpf;
		const response = await getDocs(cpf);
		return response.data;
	},
);

export const checkDocuments = createAsyncThunk(
	"fourthStep/checkDocuments",
	async ({ is_uploading }) => {
		const cpf = JSON.parse(localStorage.getItem("person")).cpf;
		const response = await getDocs(cpf, is_uploading);
		return response.data;
	},
);

export const fourthStep = createSlice({
	name: "fourthStep",
	initialState,
	extraReducers: {
		[sendDocument.pending]: (state) => {
			state.status.sendDocument = StateStatus.loading;
		},
		[sendDocument.fulfilled]: (state) => {
			state.status.sendDocument = StateStatus.succeeded;
		},
		[sendDocument.rejected]: (state, action) => {
			state.status.sendDocument = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[getDocuments.pending]: (state) => {
			state.status.getDocuments = StateStatus.loading;
		},
		[getDocuments.fulfilled]: (state, action) => {
			state.status.getDocuments = StateStatus.succeeded;
			state.docs = action.payload;
		},
		[getDocuments.rejected]: (state, action) => {
			state.status.getDocuments = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[checkDocuments.pending]: (state) => {
			state.status.checkDocuments = StateStatus.loading;
		},
		[checkDocuments.fulfilled]: (state, action) => {
			state.status.checkDocuments = StateStatus.succeeded;
			state.docs = action.payload;
		},
		[checkDocuments.rejected]: (state, action) => {
			state.status.checkDocuments = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[delDocument.pending]: (state) => {
			state.status.delDocument = StateStatus.loading;
		},
		[delDocument.fulfilled]: (state) => {
			state.status.delDocument = StateStatus.succeeded;
		},
		[delDocument.rejected]: (state, action) => {
			state.status.delDocument = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
	},
});

export const selectFourthStep = (state) => state.fourthStep;
