import React from "react";
import { Typography, Col, Modal } from "antd";
import company from "../../common/assets/company.png";
import './styles.scss';
import EfexModal from "../../common/components/Modal";
import { useRouteMatch } from "react-router-dom";

const { Text } = Typography;

const CantRegisterModal = (props) => {
  const { path } = useRouteMatch();

  return (
    <EfexModal {...props} hasCancel={false} className={path.match("empresas") ? "modal-company cant-register-modal" : "modal-person cant-register-modal"} title="Ops, empresa inativa.">
    	<img
        src={company}
        alt="imagem para representar que a empresa está inativa"
      />
      <Col>
        <Text>
          Esta empresa encontra-se inativa, para prosseguir com o cadastro, por favor volte à tela anterior e informe um CNPJ ativo.
        </Text>
      </Col>
    </EfexModal>
  );
};

export default CantRegisterModal;
