import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StateStatus from "../../utils/status";
import { addPerson, deletePerson, getPeople, getPerson_, getTransactions, receiveValueDuplicate, sendValueDuplicate } from "./service";

const initialState = {
	status: {
		addPerson: StateStatus.idle,
		listPeople: StateStatus.idle,
		listPeopleCompleted: StateStatus.idle,
		getPerson: StateStatus.idle,
		listStatusTransactions: StateStatus.idle,
		listLastTransactions: StateStatus.idle,
		duplicateTransaction: StateStatus.idle,
		deletePerson: StateStatus.idle,
	},
	errorMsg: null,
	people: [],
	peopleCompleted: [],
	statusTransactions: [],
	lastTransactions: [],
	duplicatedTransaction: null,
	person: null,
};

export const postAddPerson = createAsyncThunk(
	"person/addPerson",
	async (data) => {
		const response = await addPerson(data);
		localStorage.setItem("person", JSON.stringify(response.data));
		return response.data;
	},
);

export const listPeople = createAsyncThunk("person/listPeople", async () => {
	const finalized = false
	const response = await getPeople({ finalized });
	return response.data;
});


export const deletePersonThunk = createAsyncThunk("person/delete", async (personId) => {
	const response = await deletePerson(personId);
	return response.data;
});


export const listPeopleCompleted = createAsyncThunk("person/listPeopleCompleted", async () => {
	const finalized = true
	const response = await getPeople({ finalized });
	return response.data;
});

export const getPerson = createAsyncThunk("person/getPerson", async (id) => {
	if (id === undefined) {
		return JSON.parse(localStorage.getItem("person"));
	} else {
		const response = await getPerson_({ id });
		localStorage.setItem("person", JSON.stringify(response.data));
		return response.data;
	}
});

export const listStatusTransactions = createAsyncThunk(
	"person/listStatusTransactions",
	async ({ order, sortBy, cnpj, status, cpf, page }) => {
		const response = await getTransactions({
			cnpj: cnpj ?? null,
			owner_type: "person",
			sort_by: sortBy ?? "status_updated_at",
			sort_order: order ?? "DESC",
			status: status ?? null,
			cpf: cpf ?? null,
			page
		});
		return response.data;
	});

export const listLastTransactions = createAsyncThunk(
	"person/listLastTransactions",
	async ({ order, sortBy }) => {
		const response = await getTransactions({
			owner_type: "person",
			sort_by: sortBy ?? "created_at",
			sort_order: order ?? "DESC",
			status: [
				// "contract_signed",
				// "waiting_deposit_receipt",
				// "completed",
				"finished",
			]
		});
		return response.data;
	});

export const duplicateTransaction = createAsyncThunk(
	'person/duplicatedTransaction',
	async ({ id, type, cpf, owner_id }) => {
		let response = null;

		if (type === "send_value") {
			response = await sendValueDuplicate({
				cpf_cnpj: cpf,
				send_value_id: id
			});
		} else {
			response = await receiveValueDuplicate({
				cpf_cnpj: cpf,
				receive_value_id: id
			});
		}

		return { type, owner_id, id: response.data.id }
	},
)

export const resetPeopleStates = createAsyncThunk(
	'person/resetPeopleStates',
	async () => {
		return { listPeople: StateStatus.idle, listPeopleCompleted: StateStatus.idle };
	}
);

export const person = createSlice({
	name: "person",
	initialState,
	reducers: {
		/*setPerson: (state, action) => {
			localStorage.setItem("person", JSON.stringify(action.payload));
			state.person = action.payload;
		},*/
		resetStatusGetPerson: (state) => {
			state.status.getPerson = StateStatus.idle;
		},
		resetAddPerson: (state) => {
			state.status.addPerson = StateStatus.idle;
		},
		resetDuplicateState: (state) => {
			state.status.duplicateTransaction = StateStatus.idle;
		},
	},
	extraReducers: {
		[postAddPerson.pending]: (state) => {
			state.status.addPerson = StateStatus.loading;
		},
		[postAddPerson.fulfilled]: (state, action) => {
			state.status.addPerson = StateStatus.succeeded;
			state.person = action.payload
		},
		[postAddPerson.rejected]: (state, action) => {
			state.status.addPerson = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[listPeople.pending]: (state) => {
			state.status.listPeople = StateStatus.loading;
		},
		[listPeople.fulfilled]: (state, action) => {
			state.status.listPeople = StateStatus.succeeded;
			state.people = action.payload.people;
			localStorage.setItem(
				"people",
				JSON.stringify(action.payload.people),
			);
		},
		[listPeople.rejected]: (state, action) => {
			state.status.listPeople = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[listPeopleCompleted.pending]: (state) => {
			state.status.listPeopleCompleted = StateStatus.loading;
		},
		[listPeopleCompleted.fulfilled]: (state, action) => {
			state.status.listPeopleCompleted = StateStatus.succeeded;
			state.peopleCompleted = action.payload.people;
			localStorage.setItem(
				"peopleCompleted",
				JSON.stringify(action.payload.people),
			);
		},
		[listPeopleCompleted.rejected]: (state, action) => {
			state.status.listPeopleCompleted = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[getPerson.pending]: (state) => {
			state.status.getPerson = StateStatus.loading;
		},
		[getPerson.fulfilled]: (state, action) => {
			state.status.getPerson = StateStatus.succeeded;
			state.person = action.payload
			// const dispatch = useDispatch()
			// dispatch(getCadastralData())
		},
		[getPerson.rejected]: (state, action) => {
			state.status.getPerson = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[listStatusTransactions.pending]: (state) => {
			state.status.listStatusTransactions = StateStatus.loading;
		},
		[listStatusTransactions.fulfilled]: (state, action) => {
			state.status.listStatusTransactions = StateStatus.succeeded;
			state.statusTransactions = action.payload.lst.slice(0, 4);
		},
		[listStatusTransactions.rejected]: (state, action) => {
			state.status.listStatusTransactions = StateStatus.failed;
			state.errorMsg = action.error.message;
		},

		[listLastTransactions.pending]: (state) => {
			state.status.listLastTransactions = StateStatus.loading;
		},
		[listLastTransactions.fulfilled]: (state, action) => {
			state.status.listLastTransactions = StateStatus.succeeded;
			state.lastTransactions = action.payload.lst.slice(0, 4);
		},
		[listLastTransactions.rejected]: (state, action) => {
			state.status.listLastTransactions = StateStatus.failed;
			state.errorMsg = action.error.message;
		},

		[duplicateTransaction.pending]: (state) => {
			state.status.duplicateTransaction = StateStatus.loading;
		},
		[duplicateTransaction.fulfilled]: (state, action) => {
			state.duplicatedTransaction = action.payload;
			state.status.duplicateTransaction = StateStatus.succeeded;
		},
		[duplicateTransaction.rejected]: (state, action) => {
			state.status.duplicateTransaction = StateStatus.failed;
			state.errorMsg = action.error.message;
		},

		// delete person

		[deletePersonThunk.pending]: (state) => {
			state.status.deletePerson = StateStatus.loading;
		},
		[deletePersonThunk.fulfilled]: (state) => {
			state.status.deletePerson = StateStatus.succeeded;
		},
		[deletePersonThunk.rejected]: (state, action) => {
			state.status.deletePerson = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[resetPeopleStates.fulfilled]: (state, action) => {
			state.status.listPeople = action.payload.listPeople;
			state.status.listPeopleCompleted = action.payload.listPeopleCompleted;
		},
	},
});

export const { resetAddPerson, resetStatusGetPerson, resetDuplicateState } =
	person.actions;
export const selectPerson = (state) => state.person;
