import React, { useEffect, useState } from "react";
import { Row, Col, Divider, Typography, message, Button, Select } from "antd";
import { EfexCard } from "../../common/components/Card";
import { SyncOutlined, UserOutlined } from "@ant-design/icons";
import CompanyIcon from "../../common/components/icons/CompanyIcon";
import { TransactionCard } from "../../common/components/TransactionCard";
import { useDispatch, useSelector } from "react-redux";
import {
    duplicateTransaction,
    listLastTransactions,
    listStatusTransactions,
    needChanges,
    resetDuplicateState,
    selectHome,
} from "./slice";
import StateStatus from "../../utils/status";
import "./styles.scss";
import { handleResize } from "../../utils/responsible";
import {
    getCompaniesCompleted,
    selectCompany,
    getCompanies,
    getCompany,
    resetAddCompany,
    resetGetCompany,
} from "../ChooseCompany/ChooseCompanySlice";
import BlankCompany from "../ChooseCompany/components/BlankCompany";
import CompanyCard from "../ChooseCompany/components/CompanyCard";
import {
    getPerson,
    listPeople,
    listPeopleCompleted,
    selectPerson,
} from "../Person/slice";
import PersonCard from "../Person/components/PersonCard";
import { PersonCheckStep, UpdateCompany, UpdatePerson } from "../../utils/functions";
import { sendValueAndReceiveValueStatus } from "../../utils/consts";
import InfiniteScrollList from "../../common/components/InfiniteScrollList";
import BlankPerson from "../Person/components/BlankPerson";
import EfexModal from "../../common/components/Modal";
import { GreenButton } from "../../common/components/Button";
import { GreenLink, OrangeLink } from "../../common/components/Link";

const { Title } = Typography;

const Home = ({ history }) => {
    const dispatch = useDispatch();
    const homeState = useSelector(selectHome);
    const companies = useSelector(selectCompany);
    const person = useSelector(selectPerson);
    const [filteredStatus, setFilteredStatus] = useState(null);
    const [filteredOrder, setFilteredOrder] = useState("DESC");
    const [statusTransactions, setStatusTransactions] = useState([]);
    const [page, setPage] = useState(1);
    const [isEmpty, setIsEmpty] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [neededChanges, setNeededChanges] = useState([])
    const [visibleModals, setVisibleModals] = useState({});

    const getMoreStatusTransactions = () => {
        dispatch(
            listStatusTransactions({
                page: page + 1,
                status: filteredStatus,
                order: filteredOrder,
            })
        ).then((resp) => {
            if (resp.payload) {
                if (resp.payload.pagination.total_pages <= resp.payload.pagination.current_page) {
                    setHasMore(false)
                } else {
                    setPage(resp.payload.pagination.current_page)
                    setStatusTransactions((old) => [...old, ...resp.payload.lst]);
                }
            }
        });
    };

    const [isResponsible, updateResponsible] = useState(false);

    const ResponsiveOwnerButton = ({ owner, onClick, isResponsible }) => {
        return (
            <Button
                className={`button-owner-responsible ${owner}`}
                onClick={onClick}
                style={{
                    width: isResponsible ? "220px" : "180px",
                    height: isResponsible ? "40px" : "180px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {owner === "company" ? <CompanyIcon /> : <UserOutlined />}
                    {isResponsible && (
                        <>
                            <div style={{ width: "10px" }} />
                            {owner === "company" ? "Empresas" : "Pessoas"}
                        </>
                    )}
                </div>
            </Button>
        );
    };

    useEffect(() => {
        dispatch(needChanges()).then((resp) => {
            if (resp.payload && resp.payload.lst) {
                const items = resp.payload.lst;
                setNeededChanges(items);
                const initialVisibility = items.reduce((acc, item, index) => {
                    acc[index] = true; // ou false, dependendo do comportamento desejado
                    return acc;
                }, {});
                setVisibleModals(initialVisibility);
            }

            ;
        });
    }, [dispatch]);

    useEffect(() => {
        setHasMore(true)
        dispatch(listStatusTransactions({})).then(resp => {
            if (resp.payload) {
                resp.payload.pagination.total_pages <= resp.payload.pagination.current_page && setHasMore(false)
                setStatusTransactions(resp.payload.lst)
            }
        });
        dispatch(listLastTransactions({}));
        window.addEventListener("resize", () =>
            handleResize(updateResponsible)
        );
        handleResize(updateResponsible);
    }, []);

    useEffect(() => {
        if (homeState.status.duplicateTransaction === StateStatus.loading) {
            message.loading({ key: 1, content: "Duplicando transação..." });
        }

        if (homeState.status.duplicateTransaction === StateStatus.succeeded) {
            dispatch(resetDuplicateState());
            const { id, type, owner_id, owner_type } =
                homeState.duplicatedTransaction;
            let route = null;

            if (owner_type === "person") {
                route = `/dashboard/pessoas/${owner_id}/cambio`;
            } else {
                route = `/dashboard/empresas/${owner_id}/cambio`;
            }

            if (type === "send_value") {
                history.push(`${route}/enviar-valores/${id}`);
            } else {
                history.push(`${route}/operacoes-abertas/${id}`);
            }
        }

        if (homeState.status.duplicateTransaction === StateStatus.failed) {
            message.error({ key: 1, content: "Erro ao duplicar transação." });
        }
    }, [homeState.status.duplicateTransaction]);

    useEffect(() => {
        if (companies.status.getCompanies === StateStatus.idle) {
            dispatch(getCompanies());
        }
        if (companies.status.getCompaniesCompleted === StateStatus.idle) {
            dispatch(getCompaniesCompleted());
        }
    }, [
        companies.status.getCompanies,
        dispatch,
        history,
        companies.status.getCompaniesCompleted,
    ]);

    useEffect(() => {
        if (person.status.listPeople === StateStatus.idle) {
            dispatch(listPeople());
        }
        if (person.status.listPeopleCompleted === StateStatus.idle) {
            dispatch(listPeopleCompleted());
        }
    }, [
        person.status.listPeople,
        dispatch,
        history,
        person.status.listPeopleCompleted,
    ]);

    return (
        <Row className="home-page">
            {isResponsible ? (
                <Row style={{ width: "100%" }} gutter={[0, 16]}>
                    <Col style={{ width: "100%" }} align="center">
                        <ResponsiveOwnerButton
                            onClick={() => {
                                dispatch(resetGetCompany());
                                dispatch(resetAddCompany());
                                dispatch(getCompanies());
                                dispatch(getCompaniesCompleted());
                                history.push("/dashboard/empresas");
                            }}
                            owner="company"
                            isResponsible={isResponsible}
                        />
                        <div style={{ height: "10px" }} />
                        <ResponsiveOwnerButton
                            onClick={() => history.push("/dashboard/pessoas")}
                            owner="person"
                            isResponsible={isResponsible}
                        />
                        <div style={{ height: "10px" }} />
                    </Col>
                </Row>
            ) : (
                <Col md={2} lg={4}>
                    <Row
                        style={{ width: "100%", flexDirection: "column" }}
                        gutter={[0, 16]}
                        justify="center"
                    >
                        <Col sm={12} lg={12}>
                            <ResponsiveOwnerButton
                                onClick={() => {
                                    dispatch(resetGetCompany());
                                    dispatch(resetAddCompany());
                                    dispatch(getCompanies());
                                    dispatch(getCompaniesCompleted());
                                    history.push("/dashboard/empresas");
                                }}
                                owner="company"
                                isResponsible={isResponsible}
                            />
                        </Col>
                        <Col sm={12}>
                            <ResponsiveOwnerButton
                                onClick={() =>
                                    history.push("/dashboard/pessoas")
                                }
                                owner="person"
                                isResponsible={isResponsible}
                            />
                        </Col>
                    </Row>
                </Col>
            )}
            <Col xs={24} sm={24} lg={20} style={{ width: "100%" }}>
                <Row gutter={[16, 16]}>
                    <Col sm={24} lg={12} style={{ width: "100%" }}>
                        <EfexCard className="card-top-transactions">
                            <div>
                                <Row justify="space-between">
                                    <Title level={4}>
                                        Empresas cadastradas
                                    </Title>
                                </Row>
                                <Divider></Divider>
                            </div>
                            {companies.status.getCompaniesCompleted ===
                                StateStatus.loading ? (
                                <Row justify="center">
                                    <SyncOutlined
                                        spin
                                        className="loading-icon"
                                    />
                                </Row>
                            ) : (
                                <>
                                    {companies.companiesCompleted.length > 0 ? (
                                        companies.companiesCompleted.map(
                                            (company, index) => {
                                                return (
                                                    <Row key={index}>
                                                        <CompanyCard
                                                            isResponsible={
                                                                isResponsible
                                                            }
                                                            isOdd={
                                                                index % 2 === 0
                                                            }
                                                            loading={
                                                                companies.status
                                                                    .addCompany ===
                                                                StateStatus.loading &&
                                                                companies.status
                                                                    .getResponsables ===
                                                                StateStatus.loading
                                                            }
                                                            company={company}
                                                            onClick={() => {
                                                                message.loading(
                                                                    {
                                                                        key: 1,
                                                                        content:
                                                                            "Carregando...",
                                                                    }
                                                                );
                                                                dispatch(
                                                                    getCompany(
                                                                        company.id
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </Row>
                                                );
                                            }
                                        )
                                    ) : (
                                        <BlankCompany />
                                    )}
                                </>
                            )}
                        </EfexCard>
                        <EfexCard
                            className="person-list"
                            style={{ marginTop: "15px" }}
                        >
                            <div>
                                <Row justify="space-between">
                                    <Title level={4}>Pessoas cadastradas</Title>
                                </Row>
                                <Divider></Divider>
                            </div>
                            {person.status.listPeopleCompleted ===
                                StateStatus.loading ? (
                                <Row justify="center">
                                    <SyncOutlined
                                        spin
                                        className="loading-icon"
                                    />
                                </Row>
                            ) : (
                                <>
                                    {person.peopleCompleted.length > 0 ? (
                                        person.peopleCompleted.map(
                                            (per, index) => {
                                                return (
                                                    <Row key={index}>
                                                        <PersonCard
                                                            isResponsible={
                                                                isResponsible
                                                            }
                                                            isOdd={
                                                                index % 2 === 0
                                                            }
                                                            loading={
                                                                person.status
                                                                    .addPerson ===
                                                                StateStatus.loading
                                                            }
                                                            person={per}
                                                            onClick={() => {
                                                                message.loading(
                                                                    {
                                                                        key: 1,
                                                                        content:
                                                                            "Carregando...",
                                                                    }
                                                                );
                                                                dispatch(
                                                                    getPerson(
                                                                        per.id
                                                                    )
                                                                ).then(
                                                                    ({
                                                                        payload,
                                                                    }) => {
                                                                        if (
                                                                            payload &&
                                                                            payload.id
                                                                        ) {
                                                                            PersonCheckStep(
                                                                                payload
                                                                                    .register_steps[
                                                                                    payload
                                                                                        .register_steps
                                                                                        .length -
                                                                                    1
                                                                                ]
                                                                                    .key,
                                                                                history,
                                                                                payload.id
                                                                            );
                                                                        }
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    </Row>
                                                );
                                            }
                                        )
                                    ) : (
                                        <BlankPerson />
                                    )}
                                </>
                            )}
                        </EfexCard>
                    </Col>
                    <Col sm={24} lg={12} style={{ width: "100%" }}>
                        <Row gutter={[16, 16]}>
                            <Col sm={24} style={{ width: "100%" }}>
                                <EfexCard className="card-transactions">
                                    <div>
                                        <Row
                                            justify="space-between"
                                            className="transaction-status-header"
                                        >
                                            <Col xs={24} sm={8} md={8} lg={8}>
                                                <Title level={4}>
                                                    Status das transações
                                                </Title>
                                            </Col>
                                            <Col
                                                xs={24}
                                                sm={16}
                                                md={16}
                                                lg={16}
                                                className="selects-col"
                                            >
                                                <Row
                                                    className="filter-selects"
                                                    style={{
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <Col>
                                                        <Select
                                                            defaultValue="Todos status"
                                                            placeholder="ordenar"
                                                            className="transactions-select"
                                                            dropdownStyle={{
                                                                minWidth:
                                                                    "250px",
                                                            }}
                                                            placement="bottomRight"
                                                            size="small"
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setHasMore(true)
                                                                setPage(1);
                                                                setStatusTransactions(
                                                                    []
                                                                );
                                                                setFilteredStatus(
                                                                    value
                                                                );
                                                                setIsEmpty(
                                                                    false
                                                                );
                                                                dispatch(
                                                                    listStatusTransactions(
                                                                        {
                                                                            status: value,
                                                                            order: filteredOrder,
                                                                        }
                                                                    )
                                                                ).then(
                                                                    (resp) => {
                                                                        if (
                                                                            resp.payload
                                                                        ) {
                                                                            setStatusTransactions(
                                                                                resp
                                                                                    .payload
                                                                                    .lst
                                                                            );

                                                                            if (
                                                                                resp
                                                                                    .payload
                                                                                    .lst
                                                                                    .length ===
                                                                                0
                                                                            ) {
                                                                                setIsEmpty(
                                                                                    true
                                                                                );
                                                                            }
                                                                        }
                                                                    });
                                                            }}
                                                            options={
                                                                sendValueAndReceiveValueStatus
                                                            }
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Select
                                                            defaultValue="DESC"
                                                            placeholder="ordenar"
                                                            className="transactions-select"
                                                            size="small"
                                                            onChange={(value) => {
                                                                setHasMore(true)
                                                                setPage(1)
                                                                setStatusTransactions([])
                                                                setFilteredOrder(value)
                                                                setIsEmpty(false)
                                                                dispatch(listStatusTransactions({ order: value, status: filteredStatus }))
                                                                    .then(resp => {
                                                                        setStatusTransactions([])
                                                                        if (resp.payload) {
                                                                            resp.payload.pagination.total_pages <= resp.payload.pagination.current_page && setHasMore(false)
                                                                            setStatusTransactions(resp.payload.lst)
                                                                            if (resp.payload.lst.length === 0) {
                                                                                setIsEmpty(true)
                                                                            }
                                                                        }
                                                                    });
                                                            }}
                                                        >
                                                            <Select.Option value="DESC">
                                                                Recentes
                                                            </Select.Option>
                                                            <Select.Option value="ASC">
                                                                Antigas
                                                            </Select.Option>
                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Divider></Divider>
                                        </Row>
                                    </div>


                                    {!isEmpty ? (
                                        <InfiniteScrollList
                                            list={statusTransactions}
                                            nextFn={getMoreStatusTransactions}
                                            isStatus={true}
                                            isResponsible={isResponsible}
                                            hasMore={hasMore}
                                            endMessage={<h4 style={{ textAlign: "center" }}>Fim</h4>}
                                        />
                                    ) : isEmpty && !filteredStatus ? (
                                        <p
                                            style={{
                                                textAlign: "center",
                                                color: "#ccc",
                                            }}
                                        >
                                            Você ainda não possui nenhuma
                                            transação
                                        </p>
                                    ) : (
                                        <h4 style={{ textAlign: "center" }}>Não encontrado</h4>
                                    )}
                                </EfexCard>
                            </Col>
                            <Col sm={24} style={{ width: "100%" }}>
                                <EfexCard className="card-transactions">
                                    <div>
                                        <Row justify="space-between">
                                            <Title level={4}>
                                                Transações concluídas
                                            </Title>
                                            <Select
                                                defaultValue="DESC"
                                                placeholder="ordenar"
                                                className="transactions-select"
                                                style={{ width: "160px" }}
                                                onChange={(value) => {
                                                    dispatch(
                                                        listLastTransactions({
                                                            order: value,
                                                        })
                                                    );
                                                }}
                                            >
                                                <Select.Option value="DESC">
                                                    Recentes
                                                </Select.Option>
                                                <Select.Option value="ASC">
                                                    Antigas
                                                </Select.Option>
                                            </Select>
                                        </Row>
                                        <Divider></Divider>
                                    </div>
                                    {homeState.status.listLastTransactions ===
                                        StateStatus.loading ? (
                                        <Row
                                            justify="center"
                                            className="row-loading"
                                        >
                                            <SyncOutlined spin />
                                        </Row>
                                    ) : !homeState.lastTransactions.length ===
                                        0 ? (
                                        <Row>
                                            {homeState.lastTransactions.map(
                                                (item, index) => {
                                                    return (
                                                        <TransactionCard
                                                            key={item.id}
                                                            index={index}
                                                            isResponsible={
                                                                isResponsible
                                                            }
                                                            data={{
                                                                id: item.id,
                                                                created_at:
                                                                    item.created_at,
                                                                owner: item.owner,
                                                                type: item.type,
                                                                value: item.value,
                                                                currency:
                                                                    item.currency,
                                                                status: item.status,
                                                                status_display:
                                                                    item.status_display,
                                                                status_updated_at:
                                                                    item.status_updated_at,
                                                            }}
                                                            onClickDuplicate={() => {
                                                                dispatch(
                                                                    duplicateTransaction(
                                                                        {
                                                                            id: item.id,
                                                                            type: item.type,
                                                                            cpf_cnpj:
                                                                                item
                                                                                    .owner
                                                                                    .cpf_cnpj,
                                                                            owner_type:
                                                                                item
                                                                                    .owner
                                                                                    .type,
                                                                            owner_id:
                                                                                item
                                                                                    .owner
                                                                                    .id,
                                                                        }
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    );
                                                }
                                            )}
                                        </Row>
                                    ) : (
                                        <p
                                            style={{
                                                textAlign: "center",
                                                color: "#ccc",
                                            }}
                                        >
                                            Você ainda não concluiu nenhuma
                                            transação
                                        </p>
                                    )}
                                </EfexCard>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            {neededChanges.map((item, index) => {
                return (

                    <EfexModal
                        visible={visibleModals[index] || false}
                        onCancel={() => setVisibleModals((prevState) => ({ ...prevState, [index]: false }))}
                        onOk={() => setVisibleModals((prevState) => ({ ...prevState, [index]: false }))}
                        footer={
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <div style={{ width: "10px" }}></div>
                                <GreenButton
                                    loading={
                                        person.status.addPerson === StateStatus.loading
                                    }
                                    onClick={() => setVisibleModals((prevState) => ({ ...prevState, [index]: false }))}
                                >
                                    OK
                                </GreenButton>
                            </div>
                        }
                    >
                        <div
                            style={{
                                fontSize: "14px",
                                lineHeight: "25px",
                                marginBottom: "20px",
                                marginTop: "20px",
                                padding: "10px"
                            }}
                        >
                            <p>Olá, <strong>{item.user}</strong>.</p>
                            <p>O cadastro de <strong>{item.name}</strong> precisará ser atualizado.</p>
                            <p>{item.type === "company" ? (
                                <OrangeLink
                                    to={UpdateCompany(item.updates)}
                                    style={{ display: 'inline' }}
                                    isUploading={true}
                                    item={item}
                                >Clique aqui</OrangeLink>
                            ) : (
                                <GreenLink
                                    to={UpdatePerson(item.updates)}
                                    style={{ display: 'inline' }}
                                    isUploading={true}
                                    item={item}
                                >Clique aqui</GreenLink>
                            )} para iniciar sua atualização.</p>
                            <p>Se preferir, você pode realizar sua atualização depois, acessando a área da {item.type === "company" ? "EMPRESA" : "PESSOA"}.
                                Mas lembre-se, a atualização será necessária para que novas operações de câmbio possam ser realizadas.
                            </p>
                            <p>Se tiver alguma dúvida, é só chamar nossa equipe pelo chat.</p>
                        </div>
                    </EfexModal>)
            })}
        </Row>
    );
};

{/* <EfexModal
    className={"modal-person"}
>
    <div
        style={{
            fontSize: "14px",
            lineHeight: "25px",
            marginBottom: "20px",
        }}
    >
        <p>
            Atenção! Você precisará ter em mãos alguns documentos e
            informações da pessoa.
        </p>
        <p>
            Para iniciar, insira abaixo o <strong>CPF</strong> e o{" "}
            <strong>nome completo</strong>.
        </p>
    </div>
    {person.status.addPerson === StateStatus.failed ? (
        <ErrorMsg message={person.errorMsg} />
    ) : null}

    <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
            name={"cpf"}
            rules={[
                {
                    required: true,
                    message: "Informe o CPF",
                },
                () => ({
                    validator(_, value) {
                        if (!value || cpfValidator.isValid(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(
                            new Error("CPF inválido.")
                        );
                    },
                }),
            ]}
        >
            <MaskedInput
                placeholder="CPF"
                prefix={
                    <UserOutlined className="site-form-item-icon" />
                }
                mask={mask.cpf}
            ></MaskedInput>
        </Form.Item>
        <Form.Item
            name={"name"}
            rules={[
                {
                    required: true,
                    message: "Informe o nome completo",
                },
            ]}
        >
            <EfexInput placeholder="Nome completo" />
        </Form.Item>
    </Form>
</EfexModal> */}

export default Home;
