import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useRouteMatch, Link } from "react-router-dom";
import {
    CloseCircleOutlined,
    LoadingOutlined,
    UploadOutlined,
    SyncOutlined
} from "@ant-design/icons";
import {
    Space,
    Typography,
    Form,
    Select,
    Row,
    Col,
    InputNumber,
    Card,
    Upload,
    notification,
    Timeline,
    Modal,
    Divider,
    message,
    Input,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import {
    createFile,
    deleteExgFile,
    linkToSignedDocThunk,
    listBankAccountReceive,
    listReceiveValues,
    resetLinkToSignedFilesStatus,
    resetRates,
    resetRequestReceivementValue,
    resetStatusCreateReceiveValue,
    resetStatusEditReceiveValue,
    selectExchange,
    setReceivedValue,
} from "../slice";
import {
    createReceiveValue,
    editReceiveValue,
    getRatesReceiveValues,
    getReceivedValue,
    getReceivedValueBackground,
    requestReceivementValue,
    scheduleExchange,
} from "./thunks";
import StateStatus from "../../../utils/status";
import ErrorMsg from "../../../common/components/ErrorMsg";
import { formatCurrency } from "../../../utils/functions";
import { currency, receiveNatures } from "../../../utils/consts";
import api from "../../../service/api";
import apiRoutes from "../../../service/apiRoutes";
import PriceReceiveValue from "../../../common/components/Price";
import IdentedField from "../../../common/components/IdentedField";
import { history } from "../../../helpers/history";
import BankAccountReceiveModal from "../_components/BankAccountReceiveModal";
import SelectAndAddItem from "../SendValue/_components/SelectAndAddItem";
import {
    GreenButton,
    GreyButton,
    WhiteButton,
    OrangeButton,
} from "../../../common/components/Button";
import "./styles.scss";
import ExchangeApprovedModal from "../_components/ExchangeApprovedModal";
import TooltipEfex from "../../../common/components/Tooltip";
import { isCommercialHourRange } from "../../../utils/date";
import ConfirmModal from "../../../common/components/Modal/Confirm/index";
import SuccessModal from "../../../common/components/Modal/Success/index";
import EfexModal from "../../../common/components/Modal";
import _ from "lodash";
import CardNotice from "../../../common/components/CardNotice";

const DownloadFileLink = ({ onClick, name, loading }) => {
    return (
        <div style={{ cursor: "pointer" }}>
            {name}{" "}
            {loading ? (
                <LoadingOutlined spin />
            ) : (
                <DownloadOutlined onClick={onClick} />
            )}
        </div>
    );
};

const beforeUpload = (file) => {
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
        message.error("O Arquivo precisa ser menor que 20Mb!", 5);
        return false;
    }
    return true;
};

const ReceiveValue = () => {
    const [form] = Form.useForm();
    const [files, setFiles] = useState([]);
    const [taxes, setTaxes] = useState([
        {
            name: "IOF",
            tax: 110,
            description: "Imposto",
        },
    ]);
    const [isCurrencyTarget, setIsCurrencyTarget] = useState(true);
    const [showBankAccountModal, setShowBankAccountModal] = useState(false);
    const [showApprovedModal, setShowApprovedModal] = useState(false);
    const [currencySelected, setCurrencySelected] = useState(null);
    const [targetValueSelected, setTargetValueSelected] = useState(0)
    const [isReseted, setIsReseted] = useState(false);
    const [reload, updateReload] = useState(false);
    const [___, updateGotReceiveById] = useState(false);
    const [downloadState, updateDownloadState] = useState({});
    const [operationNature, setOperationNature] = useState(null);
    const exchange = useSelector(selectExchange);
    const dispatch = useDispatch();
    const [modal, contextHolder] = Modal.useModal();
    let { receiveid } = useParams();
    let { url, path } = useRouteMatch();
    const [isClosed, setIsClosed] = useState(false);
    const [isFormFilled, setIsFormFilled] = useState();
    const [commercialHour, setCommercialHour] = useState(false);
    const [showModal, setshowModal] = useState(false);
    const [showModalBO, setshowModalBO] = useState(false);
    const [showModalSignature, setshowModalSignature] = useState(false);
    const ownerId = JSON.parse(localStorage.getItem("owner")).id;
    const [timeout, updateTimeOut] = useState(0);
    const [timer, updateTimer] = useState(5);
    const [selectedReceiveDate, setSelectedReceiveDate] = useState(null);
    const [isDrafting, setIsDrafting] = useState(false)
    const [isSendingToBo, setIsSendingToBo] = useState(false)
    const [showScheduleModal, setShowScheduleModal] = useState(false)
    const [isLoadingSchedule, setIsLoadingSchedule] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [thereCurrency, setThereCurrency] = useState(false)
    const checkIfFormIsFilled = useCallback(() => {
        const values = form.getFieldsValue();
        values.observation = ""
        const isValid = Object.keys(values).every(
            (key, index) => values[key] !== undefined
        );
        if (repeatedReceiveValueId) {
            setIsFormFilled(true)
        } else {
            setIsFormFilled(isValid);
        }
    }, [form])
    const createOrEditIsLoading =
        exchange.status.createReceiveValue === StateStatus.loading ||
        exchange.status.editReceiveValue === StateStatus.loading
    const repeatedReceiveValueId = localStorage.getItem("repeatedReceiveValueId")


    useEffect(() => {
        if (exchange.receivedValue?.status === "waiting_sign_doc") {
            setshowModalSignature(true);
        }
    }, [exchange.receivedValue?.status]);

    useEffect(() => {
        if (
            exchange.receivedValue?.status === "created_by_bo" &&
            exchange.receivedValue?.history.length === 1
        ) {
            setshowModalBO(true);
        }
    }, [exchange.receivedValue?.history.length, exchange.receivedValue?.status]);

    useEffect(() => {
        isCommercialHourRange().then((response) => {
            setCommercialHour(response.data.is_open_to_exchange);
        });

        repeatedReceiveValueId && dispatch(getReceivedValue(repeatedReceiveValueId))
    }, []);

    const canEditThisFieldByStatus = (state) =>
        !state.receivedValue ||
        ["draft", "need_changes", "created_by_bo"].includes(
            state.receivedValue.status
        );
    const isCreatedByBO = (state) =>
        ["created_by_bo"].includes(state.receivedValue?.status);

    useEffect(() => {
        if (receiveid === undefined) {
            setIsCurrencyTarget(true);
            setCurrencySelected(null);
            setOperationNature(null);
            dispatch(setReceivedValue(null));
            dispatch(resetRates());
            setFiles([]);
            form.resetFields();
        } else {
            dispatch(getReceivedValue(receiveid));
            updateGotReceiveById(true);
        }
    }, [dispatch, form, receiveid]);

    useEffect(() => {
        const unlisten = history.listen(() => {
            localStorage.removeItem("repeatedReceiveValueId");
        });

        return () => {
            unlisten();
        };
    }, [history]);

    // gera o estado para saber se o arquivo em especifico está sendo feito o download ou não
    useEffect(() => {
        if (exchange.receivedValue && exchange.receivedValue?.files) {
            const fileStates = {};
            exchange.receivedValue?.files.forEach((item) => {
                fileStates[item.id] = false;
            });
            updateDownloadState(fileStates);
        }

        // gera urls para fazer download do contrato de cambio
        if (exchange.receivedValue && exchange.receivedValue?.contract_id) {
            dispatch(linkToSignedDocThunk(exchange.receivedValue?.contract_id));
        } else {
            dispatch(resetLinkToSignedFilesStatus());
        }
    }, [dispatch, exchange.receivedValue]);

    useEffect(() => {
        dispatch(listBankAccountReceive());
    }, []);

    useEffect(() => {
        if (exchange.status.listBankAccountReceive === StateStatus.loading) {
            form.setFieldsValue({ receipt_account: "Carregando..." });
        }

        if (exchange.status.listBankAccountReceive === StateStatus.succeeded) {
            form.setFieldsValue({
                receipt_account:
                    exchange.bankAccounts.length === 0
                        ? "Nenhuma conta cadastrada"
                        : null,
            });
        }
    }, []);

    useEffect(() => {
        if (exchange.receivedValue !== null) {
            if (exchange.receivedValue?.status === "created_by_bo") {
                form.setFieldsValue({
                    target_value: exchange.receivedValue?.target_value,
                    currency: exchange.receivedValue?.currency,
                });
            }
            else if (repeatedReceiveValueId) {
                form.setFieldsValue({
                    other: exchange.receivedValue?.other,
                    receipt_account: exchange.receivedValue?.receipt_account?.id,
                    value: 0,
                    target_value: exchange.receivedValue?.target_value,
                    currency: exchange.receivedValue?.currency,
                    nature: exchange.receivedValue?.nature,
                    is_external_service:
                        exchange.receivedValue?.is_external_service,
                    has_advance_payment:
                        exchange.receivedValue?.has_advance_payment,
                });

                let nature = receiveNatures.filter(
                    (item) => item.name === exchange.receivedValue?.nature
                )[0];
                if (exchange.receivedValue?.taxes.length > 0) {
                    setTaxes(exchange.receivedValue?.taxes);
                } else if (!_.isEmpty(exchange.receivedValue?.nature)) {
                    if (exchange.receivedValue?.is_external_service === 1) {
                        nature.taxes.IOF = 0;
                    } else {
                        nature.taxes.IOF = 38;
                    }
                    addTaxesByNature(nature);
                }

                setOperationNature(nature);
                setCurrencySelected(exchange.receivedValue?.currency)
                setTargetValueSelected(exchange.receivedValue?.target_value)
            } else {
                form.setFieldsValue({
                    observation: exchange.receivedValue?.observation,
                    other: exchange.receivedValue?.other,
                    receipt_account: exchange.receivedValue?.receipt_account?.id,
                    value: 0,
                    target_value: exchange.receivedValue?.target_value,
                    currency: exchange.receivedValue?.currency,
                    nature: exchange.receivedValue?.nature,
                    is_external_service:
                        exchange.receivedValue?.is_external_service,
                    has_advance_payment:
                        exchange.receivedValue?.has_advance_payment,
                });

                let nature = receiveNatures.filter(
                    (item) => item.name === exchange.receivedValue?.nature
                )[0];
                if (exchange.receivedValue?.taxes.length > 0) {
                    setTaxes(exchange.receivedValue?.taxes);
                } else if (!_.isEmpty(exchange.receivedValue?.nature)) {
                    if (exchange.receivedValue?.is_external_service === 1) {
                        nature.taxes.IOF = 0;
                    } else {
                        nature.taxes.IOF = 38;
                    }
                    addTaxesByNature(nature);
                }

                setFiles(
                    exchange.receivedValue?.files !== null &&
                    exchange.receivedValue?.files.map((item) => {
                        return {
                            name: item.file_name,
                            uid: item.id,
                            url: "#",
                        };
                    })
                );

                setOperationNature(nature);
            }
            setCurrencySelected(exchange.receivedValue?.currency);
            setIsCurrencyTarget(exchange.receivedValue?.is_currency_target);
        } else if (!isReseted) {
            form.resetFields();
            form.setFieldsValue({ value: 0, target_value: 0 });
            setIsReseted(true);
        }

        checkIfFormIsFilled();
    }, [checkIfFormIsFilled, exchange.receivedValue, form, isReseted]);

    useEffect(() => {
        if (exchange.status.requestReceivementValue === StateStatus.succeeded) {
            modal.success({
                title: "Negócio fechado!",
                content: (
                    <>
                        <p>
                            Em breve você receberá o contrato de câmbio em seu
                            e-mail.
                        </p>
                        {exchange.receivedValue.currency === "USD" && selectedReceiveDate === "D0" && (
                            <p>
                                Para que os reais possam ser transferidos ainda hoje para sua conta, precisaremos que o contrato seja assinado até as 16:30h, combinado?
                            </p>
                        )}
                    </>
                ),
            })
        }
    }, [exchange.status.requestReceivementValue, dispatch, modal]);

    const onClickLinkModal = (e) => {
        e.preventDefault();
        if (path.match("empresas")) {
            history.push(`/dashboard/empresas/${ownerId}/cambio/perfil`)
        } else {
            history.push(`/dashboard/pessoas/${ownerId}/cambio/perfil`)
        }
    }

    useEffect(() => {
        if (exchange.status.createReceiveValue === StateStatus.succeeded) {
            if (
                exchange.receivedValue !== null &&
                exchange.receivedValue?.status === "draft"
            ) {
                notification.success({
                    message: `Sucesso!`,
                    description: "O seu rascunho foi criado com sucesso.",
                    placement: "topRight",
                });
            } else {
                SuccessModal({
                    content: (
                        <>
                            <p>
                                Nossa equipe já está avaliando os documentos de
                                sua operação, essa etapa pode demorar até duas
                                horas para ser concluída. Mas não se preocupe,
                                você receberá um e-mail assim que a análise for
                                finalizada.
                            </p>
                            <br />
                            <p>
                                Caso a gente encontre qualquer problema ou
                                precise de mais informações, entraremos em
                                contato através do seu e-mail.
                            </p>
                            <br />
                            <p>
                                Ah, não se esqueça de informar os dados
                                bancários ao pagador no exterior, para que ele
                                possa transferir os recursos. Você pode checar
                                os dados
                                <a onClick={onClickLinkModal}> clicando aqui</a>
                            </p>
                        </>
                    ),
                });
            }
            history.push(
                `${url.replace("receber-valores", "operacoes-abertas")}/${exchange.receivedValue?.id
                }`
            );
            dispatch(resetStatusCreateReceiveValue());
        }
    }, []);

    useEffect(() => {
        if (exchange.status.editReceiveValue === StateStatus.succeeded) {
            if (
                exchange.receivedValue !== null &&
                exchange.receivedValue?.status === "draft"
            ) {
                notification.success({
                    message: `Sucesso!`,
                    description: "O seu rascunho foi editado com sucesso.",
                    placement: "topRight",
                });
            } else {
                notification.success({
                    message: `Sucesso!`,
                    description: "A operação foi enviada para nossa equipe",
                    placement: "topRight",
                });
            }
            dispatch(listReceiveValues({ page: 1 }));
            dispatch(resetStatusEditReceiveValue());
        }
    }, [dispatch, exchange.receivedValue, exchange.status.editReceiveValue]);

    useEffect(() => {
        if (receiveid) {
            if (timer > 0) {
                clearTimeout(timeout);
                updateTimeOut(
                    setTimeout(() => {
                        updateTimer(timer - 1);
                    }, 1000),
                );
            } else {
                isCommercialHourRange().then((response) => {
                    setCommercialHour(response.data.is_open_to_exchange);
                });
                if (exchange.receivedValue?.status !== "need_changes") {
                    dispatch(getReceivedValueBackground(receiveid));
                    updateTimer(5)
                }
            }
        }

    }, [timer, receiveid]);

    const onDraft = () => {
        form.validateFields()
            .then((values) => {
                setIsDrafting(true)

                values.is_currency_target = isCurrencyTarget;
                values.status = "draft";
                values.files = files.map((item) => item.uid);
                values.is_external_service = values.is_external_service ?? 0;
                values.has_advance_payment = values.has_advance_payment ?? 0;

                const action = exchange.receivedValue !== null && !repeatedReceiveValueId ?
                    editReceiveValue({ receiveId: exchange.receivedValue?.id, data: values }) :
                    createReceiveValue(values);

                dispatch(action)
                    .then(resp => resp && setIsDrafting(false));
            })
            .catch((info) => { });
    };


    const onSendToBO = () => {
        if ((exchange?.receivedValue && (exchange?.receivedValue?.currency === "USD" && (exchange?.receivedValue?.target_value <= 0 || exchange?.receivedValue?.target_value > 300000) && operationNature.requireFiles && files.length === 0)) ||
            (!exchange?.receivedValue && (currencySelected === "USD" && (targetValueSelected <= 0 || targetValueSelected > 300000) && operationNature.requireFiles && files.length === 0)) ||
            ((currencySelected !== "USD" || (exchange?.receivedValue && exchange?.receivedValue?.currency !== "USD")) && operationNature.requireFiles && files.length === 0)) {
            return notification.error({
                message: `Atenção!`,
                description: "Arquivos referente ao câmbio não foram anexados.",
                placement: "topRight",
            });
        }

        form.validateFields()
            .then((values) => {
                setIsSendingToBo(true)

                values.is_currency_target = isCurrencyTarget;
                values.status = "waiting_approval";
                values.files = files.map((item) => item.uid);
                values.is_external_service = values.is_external_service ?? 0;
                values.has_advance_payment = values.has_advance_payment ?? 0;

                if (exchange.receivedValue !== null && !repeatedReceiveValueId) {
                    values.currency = exchange.receivedValue?.currency;

                    dispatch(editReceiveValue({
                        receiveId: exchange.receivedValue?.id,
                        data: values,
                    })).then(resp => {
                        resp.payload && setIsSendingToBo(false)
                    });
                } else {
                    dispatch(createReceiveValue(values)).then(resp => {
                        if (resp.payload) {
                            history.push(`${url}/${resp.payload.id}`)
                            setIsSendingToBo(false)
                            localStorage.removeItem("repeatedReceiveValueId")
                            dispatch(resetStatusCreateReceiveValue())
                        }
                    });
                }
            })
            .catch((info) => { });
    };


    const onCloseExchange = () => {
        var data = {};
        data.id = exchange.receivedValue?.id;
        data.transactionDay = selectedReceiveDate;
        data.currency = currencySelected

        dispatch(requestReceivementValue(data)).then(({ payload, error }) => {
            if (payload !== undefined) {
                setIsClosed(true);
            } else {
                notification.error({
                    message: `Erro!`,
                    description: error.message,
                    placement: "topRight",
                });
            }
        });
    };

    const onScheduleOperation = () => {
        var data = {};
        data.receive_value_ids = [exchange.receivedValue?.id];
        data.transaction_day = selectedReceiveDate;
        data.currency = currencySelected
        data.isScheduling = true

        setIsLoadingSchedule(true)
        dispatch(scheduleExchange(data)).then((resp) => {
            if (resp.payload !== undefined) {
                setIsClosed(true);
                dispatch(getReceivedValueBackground(receiveid)).then(() => {
                    setIsLoadingSchedule(false)
                    setShowScheduleModal(false)
                })
            } else {
                setIsLoadingSchedule(false)
                console.log(resp);
                notification.error({
                    message: `Erro!`,
                    description: resp.meta.arg.transaction_day === null ? "Escolha a data para o envio dos recursos" : resp.error.message,
                    placement: "topRight",
                });
            }
        });
    }

    const onCancelScheduleOperation = () => {
        var data = {};
        data.receive_value_ids = [exchange.receivedValue?.id];

        setSelectedReceiveDate(null)
        setIsLoadingSchedule(true)
        dispatch(scheduleExchange(data)).then(({ payload, error }) => {
            if (payload !== undefined) {
                setIsClosed(true);
                setShowScheduleModal(false)
                setSelectedReceiveDate(null)
                dispatch(getReceivedValueBackground(receiveid)).then(() => setIsLoadingSchedule(false))
            } else {
                setIsLoadingSchedule(false)
                notification.error({
                    message: `Erro!`,
                    description: error.message,
                    placement: "topRight",
                });
            }
        });
    }

    const customRequest = ({ file, data, onProgress, onSuccess, onError }) => {
        var formData = new FormData();
        formData.append("file", file);
        let files_ = files;

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        dispatch(
            createFile({
                formData: formData,
                config: config,
                onSuccess: (response) => {
                    files_.push({
                        uid: response.data.file_id,
                        local_uid: file.uid,
                        name: file.name,
                        url: "#",
                    });
                    setFiles(files_);
                    onSuccess(message.success(`documento enviado com sucesso.`));
                },
                onError: (err) => {
                    onError({ err })
                    message.error(`falha no envio do documento.`)
                },
            })
        );
    };

    const downloadFile = (fileId, cpfCnpj, fileName) => {
        const newState = {};
        newState[fileId] = true;
        updateDownloadState({ ...downloadState, ...newState });
        api.get(apiRoutes.files, {
            params: { file_id: fileId, cpf_cnpj: cpfCnpj },
            responseType: "blob",
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            const newState = {};
            newState[fileId] = false;
            updateDownloadState({ newState, ...downloadState });

            setTimeout(() => {
                link.parentNode.removeChild(link);
            }, 10000);
        });
    };

    const onValuesChange = (values) => {
        checkIfFormIsFilled();
        updateReload(!reload);
        if (values.currency !== undefined) {
            dispatch(getRatesReceiveValues({ currency: values.currency, transaction_day: selectedReceiveDate || undefined }));
        }
        let nature = operationNature;
        if (values.nature !== undefined) {
            nature = receiveNatures.filter(
                (item) => item.name === values.nature
            )[0];

            setOperationNature(nature);
            if (nature) {
                addTaxesByNature(nature);
            }
        }

        if (values.is_external_service === 1) {
            if (nature) {
                nature.taxes.IOF = 0;
                addTaxesByNature(nature);
            }
        } else if (values.is_external_service === 0) {
            if (nature) {
                nature.taxes.IOF = 38;
                addTaxesByNature(nature);
            }
        }
    };

    const addTaxesByNature = (nature) => {
        const taxes = [];

        Object.keys(nature.taxes).forEach((key) => {
            let description = "Imposto";
            const tax = {
                name: key,
                tax: nature.taxes[key],
                description: description,
            };
            return taxes.push(tax);
        });

        setTaxes(taxes);
    };

    const getCorrectValueToCalculate = () => {
        if (isCurrencyTarget) {
            return form.getFieldValue("target_value") ?? 0;
        } else {
            return form.getFieldValue("value") ?? 0;
        }
    };

    return (
        <div className="receive-values">
            {(!receiveid ||
                exchange.status.getReceivedValue === StateStatus.succeeded) && (
                    <>
                        {exchange.status.createReceiveValue ===
                            StateStatus.failed ||
                            exchange.status.editReceiveValue === StateStatus.failed ||
                            exchange.status.sendFileReceiveValue ===
                            StateStatus.failed ? (
                            <ErrorMsg message={exchange.errorMsg} />
                        ) : null}
                        <br />
                        <Form
                            form={form}
                            layout="vertical"
                            onValuesChange={onValuesChange}
                        >
                            {exchange.receivedValue &&
                                !repeatedReceiveValueId &&
                                exchange.receivedValue?.history &&
                                exchange.receivedValue?.history.length > 0 ? (
                                <Row>
                                    <Card
                                        style={{
                                            width: "100%",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        <Col>
                                            <Typography.Title level={5}>
                                                {" "}
                                                Andamento{" "}
                                            </Typography.Title>
                                            <Divider />
                                            <Timeline>
                                                {exchange.receivedValue?.history.slice(0).reverse().map(
                                                    (item, index) => {
                                                        return (
                                                            <Timeline.Item
                                                                key={index}
                                                                color={
                                                                    item.action_type ===
                                                                        "negative"
                                                                        ? "red"
                                                                        : item.action_type ===
                                                                            "neutral"
                                                                            ? "grey"
                                                                            : "green"
                                                                }
                                                            >
                                                                {item.text} -{" "}
                                                                {item.created_at}
                                                            </Timeline.Item>
                                                        );
                                                    }
                                                )}
                                            </Timeline>
                                        </Col>
                                        {exchange.receivedValue.comments ? (
                                            <>
                                                <Divider />
                                                <Col>
                                                    <Typography.Title level={5} style={{ marginTop: "5px" }}>
                                                        Observações:
                                                    </Typography.Title>
                                                    <br />
                                                    <Typography.Text>
                                                        {exchange.receivedValue.comments}
                                                    </Typography.Text>
                                                </Col>
                                            </>
                                        ) : null}
                                    </Card>
                                </Row>
                            ) : null}
                            <Row gutter={[16, 16]} justify="space-between">
                                <Col xs={24} sm={24} md={24} lg={14}>
                                    <Row gutter={[16, 16]}>
                                        <Col span={24}>
                                            <Card>
                                                <Row>
                                                    <Typography.Title level={4}>
                                                        Receber valores
                                                    </Typography.Title>
                                                    <Divider
                                                        style={{
                                                            margin: "8px 0 20px",
                                                        }}
                                                    />
                                                </Row>
                                                <Row
                                                    gutter={[24, 8]}
                                                    justify="space-between"
                                                >
                                                    <Col
                                                        xs={24}
                                                        sm={24}
                                                        md={13}
                                                        lg={13}
                                                    >
                                                        <Typography.Title level={5}>
                                                            Dados de recebimento
                                                        </Typography.Title>
                                                        <SelectAndAddItem
                                                            name="receipt_account"
                                                            label="Conta bancária"
                                                            placeholder="Selecione a conta bancária"
                                                            loading={
                                                                exchange.status
                                                                    .listBankAccountReceive ===
                                                                StateStatus.loading
                                                            }
                                                            options={exchange.bankAccounts.map(
                                                                (item) => ({
                                                                    value: item.id,
                                                                    label:
                                                                        `${item.bank_name} | Ag: ${item.agency_number}` +
                                                                        ` | Conta: ${item.account_number}`,
                                                                })
                                                            )}
                                                            required={true}
                                                            disabled={
                                                                !canEditThisFieldByStatus(
                                                                    exchange
                                                                ) && !repeatedReceiveValueId
                                                            }
                                                            addBtnTitle="Adicionar conta bancária"
                                                            onAdd={() =>
                                                                setShowBankAccountModal(
                                                                    true
                                                                )
                                                            }
                                                        />
                                                        <Form.Item
                                                            name="nature"
                                                            label={
                                                                <>
                                                                    Motivo da
                                                                    remessa
                                                                    <TooltipEfex
                                                                        title="Selecione abaixo o motivo de sua operação. 
																	Caso não tenha certeza ou não encontre o motivo na lista, não tem problema, 
																	basta selecionar “Outro”."
                                                                    />
                                                                </>
                                                            }
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        "Campo obrigatório.",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                placeholder="Selecione o motivo da remessa"
                                                                onChange={(e) => {
                                                                    if (
                                                                        e !==
                                                                        "commercial"
                                                                    ) {
                                                                        setshowModal(
                                                                            true
                                                                        );
                                                                    }
                                                                }}
                                                                disabled={
                                                                    !canEditThisFieldByStatus(
                                                                        exchange
                                                                    ) &&
                                                                    !repeatedReceiveValueId
                                                                }
                                                            >
                                                                {receiveNatures.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <Select.Option
                                                                                key={
                                                                                    index
                                                                                }
                                                                                value={
                                                                                    item.name
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.label
                                                                                }
                                                                            </Select.Option>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                        {operationNature && (
                                                            <>
                                                                {operationNature.name ===
                                                                    "other" ? (
                                                                    <IdentedField>
                                                                        <Form.Item
                                                                            name="other"
                                                                            label={
                                                                                <>
                                                                                    Descreva
                                                                                    o
                                                                                    motivo
                                                                                    <TooltipEfex
                                                                                        title="Por favor, utilize o espaço abaixo para nos detalhar o 
																				motivo da remessa que pretende realizar. Nos ajudará a classificar corretamente, 
																				evitando qualquer transtorno e/ou recolhimento de impostos indevidos"
                                                                                    />
                                                                                </>
                                                                            }
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message:
                                                                                        "Campo obrigatório",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input.TextArea
                                                                                disabled={
                                                                                    !canEditThisFieldByStatus(
                                                                                        exchange
                                                                                    ) &&
                                                                                    !repeatedReceiveValueId
                                                                                }
                                                                            />
                                                                        </Form.Item>
                                                                    </IdentedField>
                                                                ) : null}
                                                                {operationNature.fields.includes(
                                                                    "is_external_service"
                                                                ) ? (
                                                                    <IdentedField>
                                                                        <Form.Item
                                                                            name="is_external_service"
                                                                            label={
                                                                                "Trata-se de serviço prestado para residente no exterior?"
                                                                            }
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message:
                                                                                        "Campo obrigatório.",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                disabled={
                                                                                    !canEditThisFieldByStatus(
                                                                                        exchange
                                                                                    ) && !repeatedReceiveValueId
                                                                                }
                                                                            >
                                                                                <Select.Option
                                                                                    value={
                                                                                        1
                                                                                    }
                                                                                >
                                                                                    SIM
                                                                                </Select.Option>
                                                                                <Select.Option
                                                                                    value={
                                                                                        0
                                                                                    }
                                                                                >
                                                                                    NÃO
                                                                                </Select.Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </IdentedField>
                                                                ) : null}
                                                                {operationNature.fields.includes(
                                                                    "has_advance_payment"
                                                                ) ? (
                                                                    <IdentedField>
                                                                        <Form.Item
                                                                            name="has_advance_payment"
                                                                            label="Pagamento de importação antecipado?"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message:
                                                                                        "Campo obrigatório.",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                disabled={
                                                                                    !canEditThisFieldByStatus(
                                                                                        exchange
                                                                                    ) && !repeatedReceiveValueId
                                                                                }
                                                                            >
                                                                                <Select.Option
                                                                                    value={
                                                                                        1
                                                                                    }
                                                                                >
                                                                                    SIM
                                                                                </Select.Option>
                                                                                <Select.Option
                                                                                    value={
                                                                                        0
                                                                                    }
                                                                                >
                                                                                    NÃO
                                                                                </Select.Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </IdentedField>
                                                                ) : null}
                                                                <IdentedField>
                                                                    <Form.Item
                                                                        name="observation"
                                                                        label={
                                                                            <>
                                                                                Observações
                                                                                <TooltipEfex
                                                                                    title="Você pode incluir abaixo informações adicionais sobre sua remessa"
                                                                                />
                                                                            </>
                                                                        }
                                                                    >
                                                                        <Input.TextArea
                                                                            disabled={
                                                                                !canEditThisFieldByStatus(
                                                                                    exchange
                                                                                ) &&
                                                                                !repeatedReceiveValueId
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </IdentedField>
                                                            </>
                                                        )}
                                                        {exchange.receivedValue !==
                                                            null &&
                                                            exchange.receivedValue
                                                                ?.nature_exchange &&
                                                            !repeatedReceiveValueId && (
                                                                <div>
                                                                    <strong>
                                                                        Natureza de
                                                                        câmbio
                                                                        definida
                                                                        pela
                                                                        efexHub:
                                                                    </strong>
                                                                    <br />
                                                                    {
                                                                        exchange
                                                                            .receivedValue
                                                                            ?.nature_exchange
                                                                    }{" "}
                                                                    -{" "}
                                                                    {
                                                                        exchange
                                                                            .receivedValue
                                                                            ?.nature_display
                                                                    }
                                                                </div>
                                                            )}
                                                        <br />
                                                    </Col>
                                                    <Col
                                                        xs={24}
                                                        sm={24}
                                                        md={11}
                                                        lg={11}
                                                    >
                                                        <Typography.Title level={5}>
                                                            {
                                                                <>
                                                                    Valores
                                                                    <TooltipEfex
                                                                        title={
                                                                            exchange
                                                                                .receivedValue
                                                                                ?.status !==
                                                                                "created_by_bo"
                                                                                ? "Selecione abaixo em qual moeda estrangeira irá receber e por favor inclua o valor total a ser recebido."
                                                                                : "O valor abaixo não pode ser alterado, mas caso tenha alguma dúvida, basta falar conosco pelo Chat."
                                                                        }
                                                                    />
                                                                </>
                                                            }
                                                        </Typography.Title>
                                                        <Row
                                                            gutter={[8, 8]}
                                                            style={{
                                                                flexFlow: "column",
                                                            }}
                                                        >
                                                            <Col>
                                                                <Row className="ant-form-item-label">
                                                                    <label
                                                                        for="currency"
                                                                        className="ant-form-item-required"
                                                                        title="Você recebe:"
                                                                    >
                                                                        Você recebe:
                                                                    </label>
                                                                </Row>
                                                                <Row
                                                                    align="bottom"
                                                                    gutter={[16, 0]}
                                                                    justify="space-between"
                                                                >
                                                                    <Col
                                                                        xs={8}
                                                                        sm={8}
                                                                        md={12}
                                                                        lg={12}
                                                                    >
                                                                        <Form.Item
                                                                            className="select-currency"
                                                                            name="currency"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message:
                                                                                        "Campo obrigatório.",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                options={currency.map(
                                                                                    (
                                                                                        x
                                                                                    ) => ({
                                                                                        label: x.value,
                                                                                        value: x.value,
                                                                                    })
                                                                                )}
                                                                                onChange={(
                                                                                    value
                                                                                ) => {
                                                                                    setCurrencySelected(
                                                                                        value
                                                                                    );
                                                                                }}
                                                                                disabled={
                                                                                    !repeatedReceiveValueId && (
                                                                                        !canEditThisFieldByStatus(
                                                                                            exchange
                                                                                        ) ||
                                                                                        isCreatedByBO(
                                                                                            exchange
                                                                                        ))
                                                                                }
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col
                                                                        id="currency-value"
                                                                        xs={16}
                                                                        sm={16}
                                                                        md={12}
                                                                        lg={12}
                                                                    >
                                                                        <Form.Item
                                                                            name="target_value"
                                                                            initialValue="0"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message:
                                                                                        "Campo obrigatório.",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <InputNumber
                                                                                onChange={(
                                                                                    value
                                                                                ) => {
                                                                                    setTargetValueSelected(
                                                                                        value
                                                                                    );
                                                                                }}
                                                                                disabled={
                                                                                    !repeatedReceiveValueId && (!currencySelected ||
                                                                                        !canEditThisFieldByStatus(
                                                                                            exchange
                                                                                        ) ||
                                                                                        isCreatedByBO(
                                                                                            exchange
                                                                                        ))
                                                                                }
                                                                                style={{
                                                                                    width: "100%",
                                                                                }}
                                                                                min={
                                                                                    1
                                                                                }
                                                                                formatter={(
                                                                                    value
                                                                                ) =>
                                                                                    formatCurrency(
                                                                                        value,
                                                                                        null
                                                                                    )
                                                                                }
                                                                                parser={(
                                                                                    money
                                                                                ) =>
                                                                                    money.replace(
                                                                                        /\D/g,
                                                                                        ""
                                                                                    )
                                                                                }
                                                                            ></InputNumber>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Row>
                                                                <Upload
                                                                    className="efex-upload-file-button"
                                                                    accept=".pdf, .png, .jpg, .jpeg"
                                                                    multiple={true}
                                                                    disabled={
                                                                        (!canEditThisFieldByStatus(
                                                                            exchange
                                                                        ) && !repeatedReceiveValueId) ||
                                                                        !isFormFilled
                                                                    }
                                                                    beforeUpload={
                                                                        beforeUpload
                                                                    }
                                                                    customRequest={
                                                                        customRequest
                                                                    }
                                                                    onRemove={(
                                                                        remove
                                                                    ) => {
                                                                        let file_id;
                                                                        setFiles(files.filter((item) => {
                                                                            if (item.local_uid === remove.uid) {
                                                                                file_id = item.uid
                                                                            }
                                                                            return item.local_uid !== remove.uid
                                                                        }));
                                                                        dispatch(deleteExgFile({ file_id }))
                                                                    }}
                                                                    showUploadList={{
                                                                        showDownloadIcon: false,
                                                                        showPreviewIcon: false,
                                                                        showRemoveIcon: true,
                                                                        removeIcon:
                                                                            (
                                                                                <>
                                                                                    <CloseCircleOutlined />
                                                                                </>
                                                                            ),
                                                                    }}
                                                                >

                                                                    {operationNature &&
                                                                        operationNature.name !==
                                                                        "deposit" ? (
                                                                        <Row className="ant-form-item-label">
                                                                            <label className={(currencySelected === "USD" || exchange?.receivedValue?.currency === "USD") &&
                                                                                ((targetValueSelected > 0 && targetValueSelected <= 300000) ||
                                                                                    (exchange?.receivedValue?.target_value > 0 && exchange?.receivedValue?.target_value <= 300000)
                                                                                ) ? "" : "ant-form-item-required"}>
                                                                                Documentação
                                                                            </label>
                                                                            <TooltipEfex
                                                                                title={
                                                                                    <>
                                                                                        <a
                                                                                            href="https://efexhub.com.br/"
                                                                                            target="_blank"
                                                                                            rel="noreferrer"
                                                                                        >
                                                                                            Clicando
                                                                                            aqui
                                                                                        </a>

                                                                                        ,
                                                                                        veja
                                                                                        no
                                                                                        na
                                                                                        sessão
                                                                                        de
                                                                                        Dúvidas,
                                                                                        exemplos
                                                                                        de
                                                                                        documentos
                                                                                        para
                                                                                        cada
                                                                                        motivo
                                                                                        de
                                                                                        remessa.
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </Row>
                                                                    ) : null}
                                                                    {/* {operationNature.name} */}
                                                                    {operationNature &&
                                                                        operationNature.name !==
                                                                        "deposit" ? (
                                                                        <Row
                                                                            gutter={[
                                                                                16,
                                                                                0,
                                                                            ]}
                                                                            align="middle"
                                                                        >
                                                                            <Col>
                                                                                {(currencySelected === "USD" || exchange?.receivedValue?.currency === "USD") &&
                                                                                    ((targetValueSelected > 0 && targetValueSelected <= 300000) ||
                                                                                        (exchange?.receivedValue?.target_value > 0 && exchange?.receivedValue?.target_value <= 300000)) ? null : (
                                                                                    <span
                                                                                        style={{
                                                                                            color: "#ff4d4f",
                                                                                        }}
                                                                                    >
                                                                                        *
                                                                                    </span>
                                                                                )}
                                                                                <GreyButton
                                                                                    disabled={
                                                                                        (!canEditThisFieldByStatus(
                                                                                            exchange
                                                                                        ) && !repeatedReceiveValueId) ||
                                                                                        !isFormFilled
                                                                                    }
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "10px",
                                                                                        width: "140px",
                                                                                    }}
                                                                                >
                                                                                    Documento da remessa
                                                                                    <UploadOutlined
                                                                                        style={{
                                                                                            position:
                                                                                                "relative",
                                                                                            left: "10px",
                                                                                        }}
                                                                                    />
                                                                                </GreyButton>
                                                                                {(currencySelected === "USD"
                                                                                    || exchange?.receivedValue?.currency === "USD") &&
                                                                                    ((targetValueSelected > 0 && targetValueSelected <= 300000) ||
                                                                                        (exchange?.receivedValue?.target_value > 0 && exchange?.receivedValue?.target_value <= 300000)
                                                                                    ) &&
                                                                                    window.location.hash.includes("empresas") ? (
                                                                                    <p style={{ backgroundColor: "#f6f6f6", color: "#333", padding: "2px", borderLeft: "5px solid #f99d1c", marginTop: "10px", fontSize: "14px" }}>
                                                                                        Não obrigatório para essa transação
                                                                                    </p>
                                                                                ) : null}
                                                                                {(currencySelected === "USD"
                                                                                    || exchange?.receivedValue?.currency === "USD") &&
                                                                                    ((targetValueSelected > 0 && targetValueSelected <= 300000) ||
                                                                                        (exchange?.receivedValue?.target_value > 0 && exchange?.receivedValue?.target_value <= 300000)
                                                                                    ) &&
                                                                                    window.location.hash.includes("pessoas") ? (
                                                                                    <p style={{ backgroundColor: "#f6f6f6", color: "#333", padding: "2px", borderLeft: "5px solid #105151", marginTop: "10px", fontSize: "14px" }}>
                                                                                        Não obrigatório para essa transação
                                                                                    </p>
                                                                                ) : null}
                                                                            </Col>
                                                                            {/* <Col>
																	<Tooltip title="Adicionar Arquivo">
																		<AddCircleButton
																			disabled={!canEditThisFieldByStatus(exchange)}
																		/>
																	</Tooltip>
																</Col> */}
                                                                        </Row>
                                                                    ) : null}
                                                                </Upload>
                                                            </Row>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                        {exchange.receivedValue?.files.length !== 0 &&
                                            exchange.receivedValue?.status !== "waiting_approval" &&
                                            !canEditThisFieldByStatus(exchange) && !repeatedReceiveValueId && (
                                                <Col span={24}>
                                                    <Card>
                                                        <Typography.Title level={4}>
                                                            Arquivos
                                                        </Typography.Title>
                                                        <Divider />
                                                        <Row>
                                                            <Col sm={12}>
                                                                <Typography.Title
                                                                    level={5}
                                                                >
                                                                    Anexos
                                                                </Typography.Title>
                                                                <div>
                                                                    {exchange.receivedValue &&
                                                                        exchange.receivedValue?.files.map(
                                                                            (item) => {
                                                                                return (
                                                                                    <DownloadFileLink
                                                                                        name={
                                                                                            item.file_name
                                                                                        }
                                                                                        onClick={() => {
                                                                                            downloadFile(
                                                                                                item.id,
                                                                                                exchange
                                                                                                    .owner
                                                                                                    .cpf ??
                                                                                                exchange
                                                                                                    .owner
                                                                                                    .cnpj,
                                                                                                item.file_name
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                </div>
                                                            </Col>
                                                            <Col sm={12}>
                                                                {exchange.linkSignedDoc
                                                                    .original_file_url && (
                                                                        <>
                                                                            <Typography.Title
                                                                                level={5}
                                                                            >
                                                                                Contrato de Câmbio
                                                                            </Typography.Title>
                                                                            <DownloadFileLink
                                                                                name={
                                                                                    <>
                                                                                        Visualizar
                                                                                        Contrato
                                                                                        <TooltipEfex title="Você já pode visualizar o contrato de câmbio da operação. Não se esqueça de que a assinatura precisa ser feita através do link enviado para o e-mail do responsável legal cadastrado." />
                                                                                    </>
                                                                                }
                                                                                onClick={() => {
                                                                                    window.open(
                                                                                        exchange
                                                                                            .linkSignedDoc
                                                                                            .original_file_url
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                                {exchange.linkSignedDoc
                                                                    .signed_file_url && (
                                                                        <>
                                                                            <DownloadFileLink
                                                                                name="Contrato Assinado"
                                                                                onClick={() => {
                                                                                    window.open(
                                                                                        exchange
                                                                                            .linkSignedDoc
                                                                                            .signed_file_url
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Col>
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={10}
                                    className="stretch-column"
                                >
                                    <div>
                                        {/* este componente está recebendo os valores da transacao, calculando os valores, descontando os impostos e retornando para o componente pai */}
                                        {repeatedReceiveValueId || (exchange.receivedValue?.history ===
                                            undefined) ||
                                            (exchange.receivedValue?.status ===
                                                "created_by_bo") ||
                                            (exchange.receivedValue?.status ===
                                                "draft") ||
                                            (exchange.receivedValue?.status ===
                                                "docs_approved") ||
                                            (exchange.receivedValue?.status ===
                                                "waiting_approval") ||
                                            (exchange.receivedValue?.status === "need_changes") ? null : (
                                            <>
                                                <PriceReceiveValue
                                                    isScheduled={exchange.receivedValue?.scheduled}
                                                    receiveValueStatus={exchange.receivedValue?.status}
                                                    commercialHour={commercialHour}
                                                    isClosed={isClosed}
                                                    valueToReceive={getCorrectValueToCalculate()}
                                                    isCurrencyTarget={
                                                        isCurrencyTarget
                                                    }
                                                    currency={
                                                        exchange.receivedValue
                                                            .currency !== undefined
                                                            ? exchange.receivedValue
                                                                .currency
                                                            : ""
                                                    }
                                                    cpfCnpj={
                                                        exchange.owner.cpf
                                                            ? exchange.owner.cpf
                                                            : exchange.owner.cnpj
                                                    }
                                                    maxTime={10}
                                                    onChangeSelect={setSelectedReceiveDate}
                                                    selectedReceiveDate={selectedReceiveDate}
                                                    taxes={taxes}
                                                    onChange={(values) => {
                                                        if (isCurrencyTarget) {
                                                            form.setFieldsValue({
                                                                value: values.finalValue,
                                                            });
                                                        } else {
                                                            form.setFieldsValue({
                                                                target_value:
                                                                    values.finalValue,
                                                            });
                                                        }
                                                    }}
                                                    valueExchange={
                                                        exchange.receivedValue &&
                                                            [
                                                                "waiting_exchange",
                                                                "waiting_sign_doc",
                                                                "contract_signed",
                                                                "waiting_contract",
                                                            ].includes(
                                                                exchange.receivedValue
                                                                    ?.status
                                                            ) &&
                                                            !isCurrencyTarget
                                                            ? exchange.receivedValue
                                                                ?.value
                                                            : 0
                                                    }
                                                    finalCurrency={
                                                        exchange.receivedValue &&
                                                            exchange.receivedValue
                                                                ?.currency_rates !== 0
                                                            ? exchange.receivedValue
                                                                ?.currency_rates /
                                                            10000
                                                            : 0
                                                    }
                                                    status={
                                                        exchange.receivedValue &&
                                                            exchange.receivedValue
                                                                ?.status
                                                            ? exchange.receivedValue
                                                                ?.status
                                                            : "new"
                                                    }
                                                    setIsLoading={setIsLoading}
                                                    isLoading={isLoading}
                                                    setThereCurrency={setThereCurrency}
                                                    thereCurrency={thereCurrency}
                                                />
                                            </>
                                        )}
                                        {(exchange.receivedValue?.status === "waiting_approval") &&
                                            <Card style={{ marginBottom: "10px" }}>
                                                <Typography.Title level={4}>
                                                    Arquivos
                                                </Typography.Title>
                                                <Divider />
                                                <Row>
                                                    <Col sm={12}>
                                                        <Typography.Title
                                                            level={5}
                                                        >
                                                            Anexos
                                                        </Typography.Title>
                                                        <div>
                                                            {exchange.receivedValue &&
                                                                exchange.receivedValue?.files.map(
                                                                    (item) => {
                                                                        return (
                                                                            <DownloadFileLink
                                                                                name={
                                                                                    item.file_name
                                                                                }
                                                                                onClick={() => {
                                                                                    downloadFile(
                                                                                        item.id,
                                                                                        exchange
                                                                                            .owner
                                                                                            .cpf ??
                                                                                        exchange
                                                                                            .owner
                                                                                            .cnpj,
                                                                                        item.file_name
                                                                                    );
                                                                                }}
                                                                            />
                                                                        );
                                                                    }
                                                                )}
                                                        </div>
                                                    </Col>
                                                    <Col sm={12}>
                                                        {exchange.linkSignedDoc
                                                            .original_file_url && (
                                                                <>
                                                                    <Typography.Title
                                                                        level={5}
                                                                    >
                                                                        Contrato de Câmbio
                                                                    </Typography.Title>
                                                                    <DownloadFileLink
                                                                        name={
                                                                            <>
                                                                                Visualizar
                                                                                Contrato
                                                                                <TooltipEfex title="Você já pode visualizar o contrato de câmbio da operação. Não se esqueça de que a assinatura precisa ser feita através do link enviado para o e-mail do responsável legal cadastrado." />
                                                                            </>
                                                                        }
                                                                        onClick={() => {
                                                                            window.open(
                                                                                exchange
                                                                                    .linkSignedDoc
                                                                                    .original_file_url
                                                                            );
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        {exchange.linkSignedDoc
                                                            .signed_file_url && (
                                                                <>
                                                                    <DownloadFileLink
                                                                        name="Contrato Assinado"
                                                                        onClick={() => {
                                                                            window.open(
                                                                                exchange
                                                                                    .linkSignedDoc
                                                                                    .signed_file_url
                                                                            );
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                    </Col>
                                                </Row>
                                            </Card>
                                        }
                                        {exchange.receivedValue?.status === "waiting_approval" &&
                                            <CardNotice />
                                        }
                                        <Row
                                            gutter={[8, 8]}
                                            justify="center"
                                            className="actions-transaction"
                                        >
                                            <Space>
                                                {(canEditThisFieldByStatus(
                                                    exchange
                                                ) || repeatedReceiveValueId) ? (
                                                    window.location.hash.includes(
                                                        "empresas"
                                                    ) ? (
                                                        <OrangeButton
                                                            type="primary"
                                                            onClick={() =>
                                                                ConfirmModal({
                                                                    onOkFunc: () =>
                                                                        onSendToBO(),
                                                                    onCancelFunc:
                                                                        () => null,
                                                                    okText: "Sim",
                                                                    cancelText:
                                                                        "Quero revisar",
                                                                    title: "Enviar para análise",
                                                                    content:
                                                                        "Você confirma que a conta bancária para recebimento da remessa está correta? É importante também que a conta indicada seja de titularidade da empresa cadastrada.",
                                                                })
                                                            }
                                                            disabled={
                                                                (!canEditThisFieldByStatus(
                                                                    exchange
                                                                ) && !repeatedReceiveValueId) ||
                                                                !isFormFilled ||
                                                                !operationNature ||
                                                                (exchange?.receivedValue && (exchange?.receivedValue?.currency === "USD" && (exchange?.receivedValue?.target_value <= 0 || exchange?.receivedValue?.target_value > 300000) && operationNature.requireFiles && files.length === 0)) ||
                                                                (!exchange?.receivedValue && (currencySelected === "USD" && (targetValueSelected <= 0 || targetValueSelected > 300000) && operationNature.requireFiles && files.length === 0)) ||
                                                                ((currencySelected !== "USD" || (exchange?.receivedValue && exchange?.receivedValue?.currency !== "USD")) && operationNature.requireFiles && files.length === 0)
                                                            }
                                                            loading={isSendingToBo}
                                                        >
                                                            Enviar para análise
                                                        </OrangeButton>
                                                    ) : (
                                                        <GreenButton
                                                            type="primary"
                                                            onClick={onSendToBO}
                                                            disabled={
                                                                (!exchange.receivedValue && ((!canEditThisFieldByStatus(exchange) && !repeatedReceiveValueId) ||
                                                                    !isFormFilled ||
                                                                    !operationNature ||
                                                                    (exchange?.receivedValue && (exchange?.receivedValue?.currency === "USD" && (exchange?.receivedValue?.target_value <= 0 || exchange?.receivedValue?.target_value > 300000) && operationNature.requireFiles && files.length === 0)) ||
                                                                    (!exchange?.receivedValue && (currencySelected === "USD" && (targetValueSelected <= 0 || targetValueSelected > 300000) && operationNature.requireFiles && files.length === 0)) ||
                                                                    ((currencySelected !== "USD" || (exchange?.receivedValue && exchange?.receivedValue?.currency !== "USD")) && operationNature.requireFiles && files.length === 0)))
                                                            }
                                                            loading={isSendingToBo && createOrEditIsLoading}
                                                        >
                                                            Enviar para análise
                                                        </GreenButton>
                                                    )
                                                ) : null}
                                                {!exchange.receivedValue ||
                                                    !exchange.receivedValue?.status ||
                                                    exchange.receivedValue?.status ===
                                                    "draft" || repeatedReceiveValueId ? (
                                                    <WhiteButton
                                                        onClick={onDraft}
                                                        disabled={
                                                            (!canEditThisFieldByStatus(
                                                                exchange
                                                            ) && !repeatedReceiveValueId) ||
                                                            !isFormFilled
                                                            // || !operationNature
                                                            // || (operationNature.requireFiles && files.length === 0)
                                                        }
                                                        loading={
                                                            isDrafting && createOrEditIsLoading
                                                        }
                                                    >
                                                        Salvar rascunho
                                                    </WhiteButton>
                                                ) : null}
                                                {exchange.receivedValue &&
                                                    (exchange.receivedValue?.status === "approved" ||
                                                        exchange.receivedValue?.status === "scheduled") &&
                                                    !repeatedReceiveValueId &&
                                                    (
                                                        (commercialHour && (
                                                            <GreenButton
                                                                type="primary"
                                                                onClick={onCloseExchange}
                                                                disabled={!commercialHour || (!selectedReceiveDate && thereCurrency) || isLoading}
                                                                loading={exchange.status.requestReceivementValue === StateStatus.loading || isLoading}
                                                            >
                                                                Fechar Câmbio
                                                            </GreenButton>
                                                        )) ||
                                                        (!commercialHour &&
                                                            currencySelected === "USD" &&
                                                            !exchange.receivedValue?.scheduled && (
                                                                <GreenButton
                                                                    type="primary"
                                                                    classes="schedule-exchange-button"
                                                                    onClick={() => setShowScheduleModal(true)}
                                                                    disabled={commercialHour}
                                                                >
                                                                    Programar fechamento automático
                                                                </GreenButton>
                                                            )) ||
                                                        (!commercialHour &&
                                                            currencySelected === "USD" &&
                                                            exchange.receivedValue?.scheduled && (
                                                                <GreenButton
                                                                    type="primary"
                                                                    classes="schedule-exchange-button"
                                                                    onClick={onCancelScheduleOperation}
                                                                    disabled={commercialHour}
                                                                    loading={isLoadingSchedule}
                                                                >
                                                                    Cancelar fechamento programado
                                                                </GreenButton>
                                                            ))
                                                    )
                                                }
                                            </Space>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                        {contextHolder}
                        <BankAccountReceiveModal
                            visible={showBankAccountModal}
                            data={null}
                            onOk={(data) => {
                                setShowBankAccountModal(false);
                                form.setFieldsValue({
                                    receipt_account: `Carregando ...`,
                                });
                                dispatch(listBankAccountReceive()).then(() => {
                                    form.setFieldsValue({
                                        receipt_account: data.id,
                                    });
                                });
                            }}
                            onCancel={() => {
                                setShowBankAccountModal(false);
                            }}
                        />
                        <ExchangeApprovedModal
                            isVisible={showApprovedModal}
                            onClose={() => setShowApprovedModal(false)}
                        />
                        <EfexModal
                            hasCancel={false}
                            visible={showModal}
                            centered={true}
                            onOk={() => setshowModal(false)}
                            title="Atenção!"
                        >
                            <p>
                                Os valores do IR e IOF são apenas uma estimativa. Os
                                valores exatos serão exibidos assim que a operação
                                estiver disponível para fechamento.
                            </p>
                        </EfexModal>
                        <EfexModal
                            hasCancel={false}
                            visible={showModalBO}
                            centered={true}
                            onOk={() => setshowModalBO(false)}
                            onCancel={() => setshowModalBO(false)}
                            title="Nova remessa!"
                        >
                            <p>
                                Que bom que sua remessa já está disponível para ser
                                recebida. Agora, precisamos que você nos informe a
                                <span className="subtitle"> conta bancária</span>, o{" "}
                                <span className="subtitle">motivo da operação</span>{" "}
                                e{" "}
                                <span className="subtitle">
                                    anexe os documentos da transação
                                </span>
                                , combinado?
                            </p>
                            <br />
                            <p>
                                Depois é só enviar para nossa equipe analisar que em
                                até <span className="subtitle">2 horas</span> a
                                transação já estará liberada para que você possa
                                fechar o câmbio.
                            </p>
                            <br />
                            <p>
                                Ah, e se tiver alguma dúvida ou problema, é só falar
                                com a gente pelo chat.
                            </p>
                        </EfexModal>
                        <EfexModal
                            hasCancel={false}
                            visible={showModalSignature}
                            centered={true}
                            onOk={() => setshowModalSignature(false)}
                            // onCancel={() => setshowModalSignature(false)}
                            title="Contrato de Câmbio enviado para assinatura!"
                        >
                            <p>
                                O contrato de câmbio desta operação já está
                                disponível e você já pode visualizá-lo nos arquivos
                                da operação. Lembre-se de que o contrato precisa ser
                                assinado através do link que enviamos para o e-mail
                                do(s) responsável(is) financeiro(s).
                            </p>
                        </EfexModal>
                        <EfexModal
                            hasCancel={true}
                            classes="is-scheduling-exchange-button"
                            visible={showScheduleModal}
                            centered={true}
                            onOk={onScheduleOperation}
                            onCancel={() => setShowScheduleModal(false)}
                            title="Programar Operação"
                            cancelText="Não, quero cancelar"
                            okText={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {isLoadingSchedule && <SyncOutlined spin />}
                                    <p style={{ marginLeft: 8 }}>Sim, confirmar operação programada</p>
                                </div>
                            }

                        >
                            <p>
                                Olá. você está optando por deixar sua operação programada para fechamento automático.
                            </p>
                            <p>
                                Nesse caso, o fechamento acontecerá automaticamente às 09:00 horas do próximo dia útil. A taxa utilizada dependerá da cotação disponível no momento em que a ordem for fechada, mas não se procupe, você receberá todas as informações em seu e-mail.
                            </p> <br />
                            <Form.Item
                                label={
                                    <>
                                        Escolha abaixo a data para o recebimento dos recursos:
                                        <TooltipEfex
                                            title="Atenção:  data de liquidação dos recursos pode sofrer alterações quando houver feriado bancários nos Estados Unidos.
										Caso tenha alguma dúvida, basta falar conosco pelo chat."
                                        />
                                    </>
                                }
                                labelCol={{ span: 24 }}
                                name="send_date"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por gentileza informe a data para o envio",
                                        validator: (_, value) => {
                                            if (value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error("Por favor, selecione uma opção."));
                                        },
                                    },
                                ]}
                            >
                                <Select onChange={(value) => setSelectedReceiveDate(value)} value={selectedReceiveDate}>
                                    <Select.Option value="D0">
                                        Receber os recursos no dia do fechamento
                                    </Select.Option>
                                    <Select.Option value="D1">
                                        Receber os recursos em um dia útil após o fechamento
                                    </Select.Option>
                                    <Select.Option value="D2">
                                        Receber os recursos em 2 dias úteis após o fechamento
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                            <p>
                                Confirmar?
                            </p>
                        </EfexModal>
                    </>
                )}
        </div>
    );
};
export default ReceiveValue;