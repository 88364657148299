import EfexModal from "../Modal"
import { GreenButton, OrangeButton, WhiteButton } from "../Button";

const WelcomeModal = ({ visible, onCompany, onPerson, onCancel }) => {
    return <EfexModal
        hasCancel={false}
        visible={visible}
        centered={true}
        title="Parabéns, seu acesso foi criado com sucesso!"
        onCancel={onCancel}
        footer={<>
            <OrangeButton style={{marginRight: '8px' }} onClick={onCompany}>Cadastrar Empresa</OrangeButton>
            <GreenButton style={{marginRight: '8px' }} onClick={onPerson}>Cadastrar Pessoa</GreenButton>
            <WhiteButton onClick={onCancel}> Cadastrar Mais Tarde</WhiteButton>
        </>}
    >
        <p>Para cadastrar uma pessoa ou uma empresa, clique abaixo para começar.</p>
    </EfexModal>
}

export default WelcomeModal