import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message, Table, Tabs, Card, Space, Form, Input, Col, Select, Row, Button, Checkbox } from "antd";
import { useRouteMatch } from "react-router-dom";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import {
    deleteReceiveValuesThunk,
    deleteSendValuesThunk,
    listReceiveValues,
    listSendValues,
    listStatusTransactions,
    selectExchange,
    setReceivedValue,
    softDeleteReceiveValuesThunk,
    softDeleteSendValuesThunk,
} from "../slice";
import StateStatus from "../../../utils/status";
import { formatCurrencyBrl } from "../../../utils/functions";
import { history } from "../../../helpers/history";
import { getReceivedValue } from "../ReceiveValue/thunks";
import "./styles.scss";
import { TableCard, OperationCard } from "../../../common/components/TableCard";
import ChooseGridStyle from "../../../common/components/ChooseGridStyle";
import CircleTransactionStatus from "../../../common/components/CircleTransactionStatus";
import { handleResize } from "../../../utils/responsible";
import EfexModal from "../../../common/components/Modal";
import TooltipEfex from "../../../common/components/Tooltip";
import { receiveValueStatus, sendValueStatus } from "../../../utils/consts";

const { TabPane } = Tabs;

const OngoingOperations = () => {
    const [form] = Form.useForm();
    const exchange = useSelector(selectExchange);
    const dispatch = useDispatch();
    let { url } = useRouteMatch();
    const [paginationSendValues, setPaginationSendValues] = useState();
    const [paginationReceiveValues, setPaginationReceiveValues] = useState();
    const [selectedExchanges, setSelectedExchanges] = useState([])
    const [selectedCurrency, setSelectedCurrency] = useState("")
    const [deleteModal, setDeleteModal] = useState({
        visible: false,
        operationId: null,
        operationType: null,
        status: null,
    });
    const [isResponsible, updateResponsible] = useState(false);
    const [gridType, setGridType] = useState("table");
    const [typeOfExchange, setTypeOfExchange] = useState("send_value");
    const [isCurrencyTarget, setIsCurrencyTarget] = useState()
    const [filteredExchange, setFilteredExchange] = useState([])
    const [isFiltered, setIsFiltered] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false);
    const owner_type = exchange.owner.cpf ? "person" : exchange.owner.cnpj ? "company" : null;
    const send_value_data_source = isFiltered ? filteredExchange : exchange.listSendValues.lst
    const receive_value_data_source = isFiltered ? filteredExchange : exchange.listReceiveValues.lst
    const [selectedStatus, setSelectedStatus] = useState("Todos status");
    const isSmallScreen = window.innerWidth < 820


    const redirectToSendValuePage = (id, status) => {
        const baseUrl = url.split("/cambio")[0];
        if (["draft", "need_changes"].includes(status)) {
            history.push(`${baseUrl}/cambio/enviar-valores/${id}`);
        } else {
            history.push(`${baseUrl}/cambio/operacao-envio/${id}`);
        }
    };

    const redirectToReceiveValuePage = (id, status) => {
        const baseUrl = url.split("/cambio")[0];
        history.push(`${baseUrl}/cambio/receber-valores/${id}`);
    };


    const columnsReceive = [
        {
            title: (
                <div>
                    <span>Selecionar</span>
                    <TooltipEfex
                        title="Você pode selecionar operações, aprovadas, para serem fechadas em lote. As operações selecionadas podem ser de envio ou de recebimento, e todas selecionadas devem ser da mesma moeda."
                    />
                </div>
            ),
            dataIndex: "",
            render: (_, exchange) => {
                if (exchange.status === "approved") {
                    return (
                        <Checkbox
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            disabled={(selectedCurrency && ((exchange.currency && exchange.currency !== selectedCurrency) || (exchange.recipient_account?.currency && exchange.recipient_account?.currency !== selectedCurrency))) || (selectedExchanges.length > 0 && exchange.is_currency_target !== isCurrencyTarget)}
                            checked={selectedExchanges.includes(exchange.id)}
                            onChange={(event) => {
                                if (event.target.checked) {
                                    setSelectedExchanges((prevSelected) => [...prevSelected, exchange.id]);
                                    if (!selectedCurrency) {
                                        setSelectedCurrency(exchange.recipient_account?.currency ? exchange.recipient_account?.currency : exchange.currency);
                                        setIsCurrencyTarget(exchange.is_currency_target)
                                    }
                                } else {
                                    if (selectedExchanges.length > 0) {
                                        setSelectedExchanges((prevSelected) => {
                                            const updatedSelection = prevSelected.filter((id) => id !== exchange.id);
                                            if (updatedSelection.length <= 0) {
                                                setSelectedCurrency("");
                                                setIsCurrencyTarget()
                                            }
                                            return updatedSelection;
                                        });
                                    }
                                }
                            }}
                        />
                    );

                }
            }
        },
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Banco no Brasil",
            dataIndex: "receipt_account",
            render: (receipt) => {
                if (receipt)
                    return (
                        <>
                            {receipt.bank_name || receipt.recipient_account.bank_name} - {receipt.account_number || receipt.recipient_account.account_number}
                        </>
                    );
            },
        },
        {
            title: "Valor",
            dataIndex: "",
            render: (_, record) => {
                var val = formatCurrencyBrl(`${record.target_value}`);
                return (
                    <>
                        {record.is_currency_target
                            ? `${record.currency || record.recipient_account?.currency} ${val}`
                            : `BRL ${val}`}
                    </>
                );
            },
        },
        {
            title: "Status",
            render: function (_, item) {
                return (
                    <div className="col-status">
                        <CircleTransactionStatus status={item.status} />
                        <div>
                            {item.status_display === "Operação criada pelo BO"
                                ? "Ordem de Pagamento Recebida"
                                : item.status_display}
                            {item.status_display ===
                                "Operação criada pelo BO" && (
                                    <TooltipEfex title="Por favor, clique ao lado para preencher as informações desta operação" />
                                )}
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Ação",
            fixed: "right",
            width: 100,
            render: function edit(_, record) {
                return (
                    <Space align="horizontal">
                        <EyeOutlined
                            id={record.id}
                            style={{ fontSize: "18px" }}
                            onClick={() => {
                                dispatch(setReceivedValue(record));
                                dispatch(getReceivedValue(record.id));
                                redirectToReceiveValuePage(
                                    record.id,
                                    record.status
                                );
                            }}
                        />
                        {[
                            "draft",
                            "waiting_approval",
                            "need_changes",
                            "approved",
                            "waiting_exchange",
                        ].includes(record.status) ? (
                            <DeleteOutlined
                                style={{ fontSize: "18px" }}
                                onClick={() => {
                                    setDeleteModal({
                                        visible: true,
                                        operationId: record.id,
                                        operationType: "receive_value",
                                        status: record.status,
                                    });
                                }}
                            />
                        ) : null}
                    </Space>
                );
            },
        },
    ];

    const columnsSend = [
        {
            title: (
                <div>
                    <span>Selecionar</span>
                    <TooltipEfex
                        title="Você pode selecionar operações, aprovadas, para serem fechadas em lote. As operações selecionadas podem ser de envio ou de recebimento, e todas selecionadas devem ser da mesma moeda."
                    />
                </div>
            ),
            dataIndex: "",
            render: (_, exchange) => {
                if (exchange.status === "approved") {
                    return (
                        <Checkbox
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            disabled={(selectedCurrency && exchange.recipient_account.currency !== selectedCurrency) || (selectedExchanges.length > 0 && exchange.is_currency_target !== isCurrencyTarget)}
                            checked={selectedExchanges.includes(exchange.id)}
                            onChange={(event) => {
                                if (event.target.checked) {
                                    setSelectedExchanges((prevSelected) => [...prevSelected, exchange.id]);
                                    if (!selectedCurrency) {
                                        setSelectedCurrency(exchange.recipient_account?.currency);
                                        setIsCurrencyTarget(exchange.is_currency_target)
                                    }
                                } else {
                                    if (selectedExchanges.length > 0) {
                                        setSelectedExchanges((prevSelected) => {
                                            const updatedSelection = prevSelected.filter((id) => id !== exchange.id);
                                            if (updatedSelection.length <= 0) {
                                                setSelectedCurrency("");
                                                setIsCurrencyTarget()
                                            }
                                            return updatedSelection;
                                        });
                                    }
                                }
                            }}
                        />
                    );

                }
            }
        },
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Beneficiário",
            dataIndex: "recipient",
            render: (recipient) => {
                return <>{recipient.name}</>;
            },
        },
        {
            title: "Banco no exterior",
            dataIndex: "recipient_account",
            render: (account) => {
                return <>{account.bank_name}</>;
            },
        },
        {
            title: "Valor",
            dataIndex: "",
            render: (_, record) => {
                var val = formatCurrencyBrl(`${record.target_value}`);
                return (
                    <>
                        {record.is_currency_target
                            ? `${record.recipient_account.currency} ${val}`
                            : `BRL ${val}`}
                    </>
                );
            },
        },
        {
            title: "Status",
            render: function (_, item) {
                return (
                    <div className="col-status">
                        <CircleTransactionStatus status={item.status} />
                        <div>{item.status_display}</div>
                    </div>
                );
            },
        },
        {
            title: "Ação",
            fixed: "right",
            width: 100,
            render: function edit(_, record) {
                return (
                    <Space align="horizontal">
                        <EyeOutlined
                            style={{ fontSize: "18px" }}
                            onClick={() => {
                                message.loading({
                                    key: 1,
                                    content: "Carregando...",
                                });
                                redirectToSendValuePage(
                                    record.id,
                                    record.status
                                );
                            }}
                        />
                        {[
                            "draft",
                            "waiting_approval",
                            "need_changes",
                            "approved",
                            "waiting_exchange",
                        ].includes(record.status) ? (
                            <DeleteOutlined
                                style={{ fontSize: "18px" }}
                                onClick={() => {
                                    setDeleteModal({
                                        visible: true,
                                        operationId: record.id,
                                        operationType: "send_value",
                                        status: record.status,
                                    });
                                }}
                            />
                        ) : null}
                    </Space>
                );
            },
        },
    ];

    const onDeleteOperation = (isSoftDelete) => {
        const reason = form.getFieldsValue();
        if (deleteModal.operationType === "send_value") {
            const resp = dispatch(
                isSoftDelete
                    ? softDeleteSendValuesThunk({
                        send_value_id: deleteModal.operationId,
                        ...reason,
                    })
                    : deleteSendValuesThunk(deleteModal.operationId)
            );

            if (resp?.error) {
                message.error({ key: 0, content: resp.error.message });
            }

            form.resetFields();
        } else if (deleteModal.operationType === "receive_value") {
            const resp = dispatch(
                isSoftDelete
                    ? softDeleteReceiveValuesThunk({
                        receive_value_id: deleteModal.operationId,
                        ...reason,
                    })
                    : deleteReceiveValuesThunk(deleteModal.operationId)
            );

            if (resp?.error) {
                message.error({ key: 0, content: resp.error.message });
            }

            form.resetFields();
        }

        setDeleteModal({ ...deleteModal, visible: false });
    };


    useEffect(() => {
        if (
            exchange.status.deleteSendValues === StateStatus.succeeded ||
            exchange.status.softDeleteSendValues === StateStatus.succeeded
        ) {
            message.destroy(5)
            message.success({ key: 1, content: "Envio excluído com sucesso!" });
            dispatch(listSendValues({ page: 1 }));
        }
    }, [
        dispatch,
        exchange.status.deleteSendValues,
        exchange.status.softDeleteSendValues,
    ]);

    useEffect(() => {
        if (
            exchange.status.deleteReceiveValues === StateStatus.succeeded ||
            exchange.status.softDeleteReceiveValues === StateStatus.succeeded
        ) {
            message.destroy(5)
            message.success({
                key: 1,
                content: "Recebimento excluído com sucesso!",
            });
            dispatch(listReceiveValues({ page: 1 }));
        }
    }, [
        dispatch,
        exchange.status.deleteReceiveValues,
        exchange.status.softDeleteReceiveValues,
    ]);

    useEffect(() => {
        dispatch(listSendValues({ page: 1 }));
        dispatch(listReceiveValues({ page: 1 }));
        window.addEventListener("resize", () =>
            handleResize(updateResponsible)
        );
        handleResize(updateResponsible);
    }, []);

    useEffect(() => {
        if (isResponsible && gridType !== "card") {
            setGridType("card");
        }
    }, [isResponsible]);

    useEffect(() => {
        if (exchange.status.listSendValues === StateStatus.succeeded) {
            setPaginationSendValues({
                current: exchange.listSendValues.pagination.current_page,
                total: exchange.listSendValues.pagination.total,
                pageSize: 15,
                pageSizeOptions: [],
            });
            setIsLoaded(true);
        }
    }, [exchange.status.listSendValues]);

    useEffect(() => {
        if (exchange.status.listReceiveValues === StateStatus.succeeded) {
            setPaginationReceiveValues({
                current: exchange.listReceiveValues.pagination.current_page,
                total: exchange.listReceiveValues.pagination.total,
                pageSize: 15,
            });
            setIsLoaded(true);
        }
    }, [exchange.status.listReceiveValues]);

    const renderExtraContent = () => (
        <Row className="filter-and-display-row">
            {selectedExchanges.length > 0 &&
                <Col>
                    <Button
                        type="primary"
                        onClick={() => {
                            localStorage.setItem('selectedExchanges', JSON.stringify(selectedExchanges));
                            localStorage.setItem("typeOfExchange", JSON.stringify(typeOfExchange))
                            history.push(`${url.split("operacoes-abertas")[0]}operacao-envio/operacoes-aprovadas`);
                        }}>Fechar Operações</Button>
                </Col>}
            <Col>
                <Select
                    disabled={!isLoaded}
                    value={selectedStatus}
                    placeholder="ordenar"
                    className="transactions-select"
                    dropdownStyle={{ minWidth: "250px" }}
                    placement="bottomRight"
                    size="small"
                    onChange={(value) => {
                        setIsFiltered(false)
                        setIsLoaded(false)
                        setSelectedStatus(value);
                        dispatch(listStatusTransactions({
                            cnpj: exchange.owner?.cnpj || null, cpf: exchange.owner?.cpf || null, status: value, type: typeOfExchange, owner_type: owner_type
                        })).then((resp) => {
                            if (resp.payload.success) {
                                const transformedData = resp.payload.lst.map((item) => ({
                                    id: item.id,
                                    status: item.status,
                                    status_display: item.status_display,
                                    recipient: { name: item.recipient_name } || null,
                                    recipient_account: {
                                        bank_name: item.bank_name,
                                        currency: String(item.currency),
                                    },
                                    is_currency_target: item.currency_target,
                                    target_value: item.value
                                }));
                                setFilteredExchange(transformedData);
                                setIsFiltered(true)
                                setIsLoaded(true)
                            } else {
                                message.error("Erro ao filtrar transações")
                            }
                            setIsFiltered(true)
                        })
                    }}
                    options={typeOfExchange === "send_value" ? sendValueStatus : receiveValueStatus}
                />
            </Col>
            <Col>
                <ChooseGridStyle
                    selectDefault={gridType}
                    onChange={(grid) => setGridType(grid)}
                />
            </Col>
        </Row>
    );


    return (
        <Card style={{ padding: "20px" }}>
            <EfexModal
                title={`Deseja excluir a operação número ${deleteModal.operationId}?`}
                visible={deleteModal.visible}
                onOk={() => {
                    form.validateFields()
                        .then((resp) => {
                            message.loading({
                                duration: 1000,
                                key: 5,
                                content: "Carregando...",
                            });
                            [
                                "need_changes",
                                "approved",
                                "waiting_exchange",
                            ].includes(deleteModal.status)
                                ? onDeleteOperation(true)
                                : onDeleteOperation();
                        })
                        .catch((err) => { });
                }}
                onCancel={() => {
                    setDeleteModal({ ...deleteModal, visible: false });
                    form.resetFields();
                }}
            >
                {["need_changes", "approved", "waiting_exchange"].includes(
                    deleteModal.status
                ) ? (
                    <Form form={form}>
                        <Form.Item
                            className="box_reason"
                            name="reason"
                            label="Descreva o motivo da exclusão para continuar:"
                            rules={[
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ]}
                        >
                            <Input.TextArea placeholder="Motivo..." />
                        </Form.Item>
                    </Form>
                ) : (
                    <p>
                        Esta operação <span style={{ color: "red" }}>NÃO</span>{" "}
                        poderá ser desfeita. Deseja continuar?
                    </p>
                )}
            </EfexModal>
            <div>
                {isSmallScreen ? renderExtraContent() : null}
                <Tabs
                    onChange={(exchangeType) => {
                        if (exchangeType === "1") {
                            setTypeOfExchange("send_value")
                            setFilteredExchange(exchange.listSendValues.lst)
                        } else {
                            setTypeOfExchange("receive_value")
                            setFilteredExchange(exchange.listReceiveValues.lst)
                        }
                        setSelectedStatus("Todos status");
                        setSelectedCurrency("")
                        setSelectedExchanges([])
                        setIsCurrencyTarget()
                    }}
                    defaultActiveKey="1"
                    destroyInactiveTabPane={isSmallScreen ? true : false}
                    tabBarExtraContent={isSmallScreen ? null : renderExtraContent()}
                >
                    <TabPane tab="Envios" key="1" extra={isSmallScreen ? renderExtraContent() : null}>
                        {gridType === "card" ? (
                            <TableCard
                                dataSource={send_value_data_source}
                                loading={
                                    exchange.status.listSendValues ===
                                    StateStatus.loading || !isLoaded
                                }
                                pagination={paginationSendValues}
                                onChange={(page) => {
                                    dispatch(listSendValues({ page }));
                                }}
                                render={(item, index) => (
                                    <OperationCard
                                        setIsCurrencyTarget={setIsCurrencyTarget}
                                        isCurrencyTarget={isCurrencyTarget}
                                        currency={item.recipient_account?.currency}
                                        selectedCurrency={selectedCurrency}
                                        setSelectedExchanges={setSelectedExchanges}
                                        setSelectedCurrency={setSelectedCurrency}
                                        id={item.id}
                                        isAproved={item.status === "approved"}
                                        selectedExchanges={selectedExchanges}
                                        exchange={item}
                                        data={{
                                            id: item.id,
                                            status: item.status,
                                            status_display: item.status_display,
                                            recipient_name: item.recipient?.name,
                                            bank_name:
                                                item.recipient_account?.bank_name,
                                            currency:
                                                item.recipient_account?.currency || "",
                                            is_currency_target:
                                                item.is_currency_target,
                                            target_value: item.target_value,
                                        }}
                                        onClick={() => {
                                            redirectToSendValuePage(
                                                item.id,
                                                item.status
                                            );
                                        }}
                                        onDelete={() => {
                                            setDeleteModal({
                                                visible: true,
                                                operationId: item.id,
                                                operationType: "send_value",
                                                status: item.status,
                                            });
                                        }}
                                        typeOfExchange={typeOfExchange}
                                    />
                                )}
                            />
                        ) : (
                            <Table
                                locale={{ emptyText: "Sem resultado" }}
                                dataSource={send_value_data_source}
                                pagination={paginationSendValues}
                                onChange={(pagination) => {
                                    dispatch(
                                        listSendValues({ page: pagination.current, status: selectedStatus })
                                    );
                                }}
                                columns={columnsSend}
                                rowClassName={(exchange) => selectedExchanges.includes(exchange.id) ? 'selected-card' : ''}
                                loading={
                                    exchange.status.listSendValues ===
                                    StateStatus.loading || !isLoaded
                                }
                            />
                        )}
                    </TabPane>
                    <TabPane tab="Recebimentos" key="2" extra={isSmallScreen ? renderExtraContent() : null}>
                        {gridType === "card" ? (
                            <TableCard
                                dataSource={receive_value_data_source}
                                loading={
                                    exchange.status.listReceiveValues ===
                                    StateStatus.loading || !isLoaded
                                }
                                pagination={paginationReceiveValues}
                                onChange={(page) => {
                                    dispatch(listReceiveValues({ page }));
                                }}
                                render={(item, index) => {
                                    return (
                                        <OperationCard
                                            setIsCurrencyTarget={setIsCurrencyTarget}
                                            isCurrencyTarget={isCurrencyTarget}
                                            currency={item.currency || item.recipient_account?.currency}
                                            selectedCurrency={selectedCurrency}
                                            setSelectedExchanges={setSelectedExchanges}
                                            setSelectedCurrency={setSelectedCurrency}
                                            id={item.id}
                                            isAproved={item.status === "approved"}
                                            selectedExchanges={selectedExchanges}
                                            exchange={item}
                                            data={{
                                                id: item.id,
                                                status: item.status,
                                                status_display: item.status_display,
                                                recipient_name: null,
                                                bank_name:
                                                    item.receipt_account?.bank_name || item.recipient_account?.bank_name || "Banco não informado",
                                                currency: item.currency || item.recipient_account?.currency,
                                                is_currency_target:
                                                    item.is_currency_target,
                                                target_value: item.target_value,
                                            }}
                                            onClick={() => {
                                                redirectToReceiveValuePage(
                                                    item.id,
                                                    item.status
                                                );
                                            }}
                                            onDelete={() => {
                                                setDeleteModal({
                                                    visible: true,
                                                    operationId: item.id,
                                                    operationType: "receive_value",
                                                    status: item.status,
                                                });
                                            }}
                                            typeOfExchange={typeOfExchange}
                                        />
                                    );
                                }}
                            />
                        ) : (
                            <Table
                                locale={{ emptyText: "Sem resultado" }}
                                dataSource={receive_value_data_source}
                                pagination={paginationReceiveValues}
                                rowClassName={(exchange) => selectedExchanges.includes(exchange.id) ? 'selected-card' : ''}
                                onChange={(pagination) => {
                                    dispatch(
                                        listReceiveValues({
                                            page: pagination.current,
                                            status: selectedStatus,
                                        })
                                    );
                                }}
                                columns={columnsReceive}
                                loading={
                                    exchange.status.listReceiveValues ===
                                    StateStatus.loading || !isLoaded
                                }
                            />
                        )}
                    </TabPane>
                </Tabs>
            </div>
        </Card>
    );
};

export default OngoingOperations;