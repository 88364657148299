import React from 'react'
import PropTypes from 'prop-types'
import { beautifyCpfCnpj, ellipsis } from '../../../utils/beautify'
import { Card, Typography, Row, Col, Checkbox } from 'antd'
import { DeleteOutlined } from "@ant-design/icons";
import './styles.scss'

const { Title, Text } = Typography

const PartnerCard = ({ personData, onChange, removePerson, checked, disabled, isOdd, isResponsible }) => {
    return (
        <div className={`partner-card ${disabled ? 'partner-card-disabled' : ''} ${!isOdd ? 'odd' : ''}`} onClick={() => {
            onChange({ name: personData.name, cpf: personData.cpf, id: personData.id, qualification: personData.qualification })
        }} >
            <Row style={{ padding: "8px 0" }} className='partner-card-row'>
                <Col sm={1}>
                    <div className="box-check">
                        <Checkbox disabled={disabled} checked={checked} />
                    </div>
                </Col>
                <Col>
                    {
                        isResponsible ?
                            <>
                                <Title level={5} className="partner-name" >{personData.name}</Title>
                                <Text type="secondary"> {personData.qualification === 'resp-legal' ? 'Responsável Legal' : ellipsis(personData.qualification, 20)} - {beautifyCpfCnpj(personData.cpf)}</Text>
                                {/* <Text>{beautifyCpfCnpj(personData.cpf)}</Text> */}

                            </>
                            :
                            <>
                                <Title level={5}>{personData.name}</Title>
                                <Text type="secondary"> {personData.qualification === 'resp-legal' ? 'Responsável Legal' : personData.qualification} - {beautifyCpfCnpj(personData.cpf)}</Text>
                            </>
                    }
                </Col>
                {["resp-legal", "procurador"].indexOf(personData.qualification) !== -1 ? <DeleteOutlined
                    onClick={(evt) => {
                        evt.stopPropagation();
                        if (removePerson) removePerson(personData);
                    }}
                /> : null}
            </Row>
        </div>
    )
}

PartnerCard.propTypes = {
    personData: PropTypes.object,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    removePerson: PropTypes.func,
}
export default PartnerCard