/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Progress, Typography, Card, Form, Select, notification } from "antd";
import { formatCurrency, formatCurrencyExchange } from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { selectExchange } from "../../../features/Exchange/slice";
import StateStatus from "../../../utils/status";
import { getRatesSendValues } from "../../../features/Exchange/SendValue/thunks";
import "./styles.scss";
import NotOpenToExchange from "../NotOpenToExchange";
import { CanCloseToday, isCommercialHourRange } from "../../../utils/date";
import TooltipEfex from "../Tooltip";
import { SyncOutlined } from "@ant-design/icons";

const PriceSendValue = ({
	maxTime,
	valueToSend = 0,
	isCurrencyTarget,
	currency,
	taxes,
	freeze = false,
	onChange = (data) => { },
	onChangeSelect = (data) => { },
	commercialHour = false,
	sendValueStatus,
	getRates = false,
	selectedSendDate,
	setIsLoading,
	isLoading,
	setThereCurrency,
	thereCurrency
}) => {
	const [timer, updateTimer] = useState(-1);
	const [timeout, updateTimeOut] = useState(0);
	const [progress, updateProgress] = useState(100);
	const [calcValues, updateCalcValues] = useState({
		toMe: 0, toBrl: 0,
		iof: { value: 0, tax: 0 },
		ir: { value: 0, tax: 0 },
		tarifa: { value: 0, tax: 0 }
	});
	const dispatch = useDispatch();
	const exchange = useSelector(selectExchange);
	const [closeToday, setCloseToday] = useState(false);
	const [showSelect, setShowSelect] = useState(false);
	const [fields, setFields] = useState([]);

	const waitingExchange = [
		undefined,
		"draft",
		"waiting_approval",
		"need_changes",
		"docs_approved",
		"approved",
	].includes(exchange.sendValue.status)

	const currencyPrice = waitingExchange
		? (exchange.rates.rate ?? 0) / 10000
		: exchange.sendValue.currency_rates / 10000;

	const calcFromME = () => {
		let finalValue = 0;
		let iof = taxes.filter(x => x.name.toUpperCase() === "IOF")[0] ?? 0
		let ir = taxes.filter(x => x.name.toUpperCase() === "IR")[0] ?? 0
		let tarifa = taxes.filter((x) => x.name.toUpperCase() === "TARIFA")[0] ?? 0
		const valueMe = parseInt(valueToSend) / 100
		const iofTax = iof === 0 ? 0 : iof.tax / 10000;
		const irTax = ir === 0 ? 0 : ir.tax / 100000000; // define quantidade de casas registradas pelo BO
		tarifa = tarifa === 0 ? 0 : tarifa.value / 100;

		if (isCurrencyTarget && valueMe > 0 && currencyPrice > 0) {
			iof = (valueMe * currencyPrice) * iofTax;
			ir = (valueMe * currencyPrice) * irTax;

			iof = Math.trunc(iof * 10000) / 10000 // manter 4 casas decimais
			ir = Math.trunc(ir * 10000) / 10000 // manter 4 casas decimais

			finalValue = (valueMe * currencyPrice) + iof + ir + tarifa;
			finalValue = Math.trunc(finalValue * 10000) / 10000 // manter 4 casas decimais
		} else {
			iof = 0
			ir = 0
			tarifa = 0
		}

		onChange({
			finalValue: finalValue.toFixed(2),
		});

		// toFixed foi usado para arredondar casas decimais e mostrar exibir 2 apenas.
		// Ex.: 39.387 -> 39.39; 
		//      39.384 -> 39.38;
		updateCalcValues({
			toBrl: finalValue.toFixed(2),
			iof: {
				value: iof.toFixed(2),
				tax: (iofTax * 100).toFixed(2)
			},
			ir: {
				value: ir.toFixed(2),
				tax: (irTax * 100).toFixed(2)
			},
			tarifa: {
				value: tarifa.toFixed(2),
				tax: null
			},
			vet: currencyPrice,
		});
	};

	const calcFromBrl = () => {
		let finalValue = 0;
		let iof = taxes.filter(x => x.name.toUpperCase() === "IOF")[0] ?? 0
		let ir = taxes.filter(x => x.name.toUpperCase() === "IR")[0] ?? 0
		let tarifa = taxes.filter((x) => x.name.toUpperCase() === "TARIFA")[0] ?? 0;

		const valueBrl = parseInt(valueToSend) / 100
		const iofTax = iof === 0 ? 0 : iof.tax / 10000;
		const irTax = ir === 0 ? 0 : ir.tax / 100000000;
		tarifa = tarifa === 0 ? 0 : tarifa.value / 100;

		if (!isCurrencyTarget && valueBrl > 0 && currencyPrice > 0) {
			let calc = valueBrl / (1 + iofTax + irTax)

			calc = Math.trunc(calc * 10000) / 10000 // manter 4 casas decimais

			iof = calc * iofTax
			ir = calc * irTax


			iof = Math.trunc(iof * 10000) / 10000 // manter 4 casas decimais
			ir = Math.trunc(ir * 10000) / 10000 // manter 4 casas decimais

			finalValue = calc / currencyPrice
			finalValue = Math.trunc(finalValue * 10000) / 10000  // manter 4 casas decimais
		} else {
			iof = 0
			ir = 0
			tarifa = 0
		}

		onChange({
			finalValue: finalValue.toFixed(2),
		});

		// toFixed foi usado para arredondar casas decimais e mostrar exibir 2 apenas.
		// Ex.: 39.387 -> 39.39; 
		//      39.384 -> 39.38;
		updateCalcValues({
			toMe: finalValue.toFixed(2),
			iof: {
				value: iof.toFixed(2),
				tax: (iofTax * 100).toFixed(2)
			},
			ir: {
				value: ir.toFixed(2),
				tax: (irTax * 100).toFixed(2)
			},
			tarifa: {
				value: tarifa.toFixed(2),
				tax: null,
			},
			vet: currencyPrice,
		})
	};

	const fetchRates = () => {
		setIsLoading(true)
		var send_value_id = exchange.sendValue.id;
		dispatch(getRatesSendValues({
			send_value_id: send_value_id || undefined,
			currency: currency || undefined,
			transaction_day: selectedSendDate || undefined
		})).then((resp) => {
			if (resp.payload) {
				setThereCurrency(resp?.payload?.there_currency_coverages)
				setFields(resp?.payload?.fields)
				updateTimer(maxTime)
			} else {
				notification.error({
					message: `Erro!`,
					description: resp.error.message || "Erro interno do servidor, tente novamente.",
					placement: "topRight",
				})
			}
			setIsLoading(false)
		})
	}

	const formatTax = () => {
		if (isCurrencyTarget) {
			const calc = (calcValues.toBrl / valueToSend)
			const calcArray = calc.toString().split("").slice(3, 8)
			calcArray.splice(1, 0, ",")
			const calcStr = calcArray.join("")

			return `BRL ${calcStr}`
		} else {
			const calc = (valueToSend / calcValues.toMe)
			const calcArray = calc.toString().replace(".", "").split("")
			calcArray.splice(1, 0, ",")
			const calcStr = calcArray.slice(0, 6).join("")

			return `BRL ${calcStr}`
		}
	}

	useEffect(() => {
		CanCloseToday().then((response) => {
			setCloseToday(response.data.can_close_today)
		})
		isCommercialHourRange().then((response) => {
			setShowSelect(response.data.is_open_to_exchange)
		})
	}, []);

	useEffect(() => {
		fetchRates()
	}, [selectedSendDate])

	useEffect(() => {
		if (isCurrencyTarget) {
			calcFromME()
		} else {
			calcFromBrl()
		}
	}, [currency, currencyPrice, valueToSend, isCurrencyTarget, taxes])


	useEffect(() => {
		if (exchange.status.getRatesSendValues === StateStatus.succeeded) {
			updateTimer(maxTime);
		}
	}, [exchange.status.getRatesSendValues]);

	useEffect(() => {
		if (selectedSendDate) {
			if (waitingExchange && !freeze) {
				if (timer > 0) {
					clearTimeout(timeout);
					updateTimeOut(
						setTimeout(() => {
							updateTimer(timer - 1);
						}, 1000),
					);
				} else {
					fetchRates()
				}
				updateProgress((100 * timer) / maxTime);
			}
		}
	}, [timer, currency, freeze, dispatch, exchange.sendValue.id, waitingExchange, selectedSendDate]);

	useEffect(() => {
		if (selectedSendDate) {
			if (getRates) {
				updateTimer(maxTime)
				updateCalcValues({
					toMe: 0, toBrl: 0,
					iof: { value: 0, tax: 0 },
					ir: { value: 0, tax: 0 }
				})
				fetchRates()
			}
		}
	}, [getRates, selectedSendDate])

	return (
		<div>
			{/* fundo verde quando: cotação fechada, ou aprovado para cambio */}
			<Card className="card-pricesend-simulate" data-simulation={!(exchange.sendValue.status === "approved" || !waitingExchange)}>
				<Typography.Title level={4} className="title-box">
					{exchange.sendValue.status === "approved" ? "Cotação" : waitingExchange ? "Simulação" : "Remessa Aprovada"}
				</Typography.Title>
				<br />
				<Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}>
					{thereCurrency && showSelect && (sendValueStatus === "waiting_approval" || sendValueStatus === "approved") ? (
						<Form.Item
							className="send-day-form"
							label={
								<>
									Escolha abaixo a data para o envio dos recursos:
									<TooltipEfex
										className="tooltip"
										title="A liquidação no mesmo dia só pode ser contratada até às 12:00h.
                    Atenção:  data de liquidação dos recursos pode sofrer alterações quando houver feriado bancários nos Estados Unidos.
                    Caso tenha alguma dúvida, basta falar conosco pelo chat."
									/>
								</>
							}
							name="send_date"
							rules={[
								{
									required: true,
									message: "Por gentileza informe a data para o envio",
									validator: (_, value) => {
										if (value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error("Por favor, selecione uma opção."));
									},
								},
							]}
						>
							<Select onChange={onChangeSelect} disabled={isLoading}>
								<Select.Option value="D0" disabled={!closeToday || !fields.includes('D0')}>
									Enviar os recursos hoje
								</Select.Option>
								<Select.Option value="D1" className="select-send-date-option" disabled={!fields.includes('D1')}>
									Enviar os recursos no próximo dia útil
								</Select.Option>
								<Select.Option value="D2" disabled={!fields.includes('D2')}>
									Enviar os recursos em 2 dias úteis
								</Select.Option>
							</Select>
						</Form.Item>
					) : null}
				</Col>
				{
					exchange.sendValue.status === "approved" && !commercialHour ? <NotOpenToExchange /> : <Row gutter={[8, 8]} justify="space-between" className={waitingExchange ? "simulation-content" : ""}>
						{waitingExchange && (
							<Col xs={11} sm={11} md={11} lg={8}
								className="box-currency-progress">
								{(thereCurrency && !selectedSendDate) ? null :
									isLoading ? <SyncOutlined spin style={{ color: "white", fontSize: "30px" }} /> :
										<Progress
											type="circle"
											width={60}
											status={timer <= 5 ? "exception" : "success"}
											percent={progress}
											format={(percent) => `${timer}s`}
										/>}
								<div className="box-value">
									<label>Seu câmbio</label>
									<div>
										<span>
											{(thereCurrency && !selectedSendDate) || isLoading ? "" : formatCurrencyExchange(calcValues.vet * 10000, "")}
										</span>
									</div>
								</div>
							</Col>
						)}
						<Col
							xs={waitingExchange ? 13 : 24}
							sm={waitingExchange ? 13 : 24}
							md={waitingExchange ? 13 : 24}
							lg={waitingExchange ? 16 : 24}
						>
							<Row className="box-exchange-value" justify="center">
								<Typography.Title level={5}>Convertemos para</Typography.Title>
								<div className="box-value">
									{(thereCurrency && !selectedSendDate && exchange.sendValue.status === "approved") || isLoading ? <span /> :
										<span>
											{!isCurrencyTarget
												? formatCurrency(calcValues.toMe?.toString(), currency)
												: formatCurrency(valueToSend.toString(), currency)}
										</span>}
								</div>
							</Row>
							<Row className="box-exchange-value" justify="center">
								<Typography.Title level={5}>Você transfere</Typography.Title>
								<div className="box-value">
									{(thereCurrency && !selectedSendDate && exchange.sendValue.status === "approved") || isLoading ? <span /> :
										<span>
											{isCurrencyTarget ? (
												formatCurrency(calcValues.toBrl?.toString(), "BRL")
											) : (
												formatCurrency(valueToSend.toString(), "BRL")
											)}
										</span>}
								</div>

								<div className="box-exchange-taxes">
									{(thereCurrency && !selectedSendDate && exchange.sendValue.status === "approved") || isLoading ? <span /> :
										<span>
											<p>Impostos e tarifas inclusos no valor final:</p>
											{calcValues.iof?.value > 0 && (
												<p>
													IOF: {formatCurrency(calcValues.iof?.value, "BRL")}
													{" "}({calcValues.iof.tax}%)
												</p>
											)}
											{(calcValues?.vet > 0 && sendValueStatus !== "approved") && (
												<p>
													VET: {formatTax()}
												</p>
											)}
											{calcValues.ir.value > 0 && (
												<p>
													IR: {formatCurrency((calcValues.ir.value), "BRL")}
													{" "}({calcValues.ir.tax}%)
												</p>
											)}
											{calcValues.tarifa?.value > 0 && (
												<p>
													Tarifa: {formatCurrency(calcValues.tarifa?.value, "BRL")}
												</p>
											)}
										</span>}
								</div>
							</Row>
						</Col>
					</Row>
				}

			</Card>
			<div className="price-variation-info">
				{exchange.sendValue.status === "approved"
					? "* Eventualmente a taxa de câmbio aplicada e os valores de impostos poderão sofrer pequenas alterações após a confirmação do fechamento."
					: "* A taxa de câmbio e valores de impostos efetivamente aplicadas só serão definidas no fechamento da operação e podem ser diferentes das mostradas acima"}
			</div>

			{
				<div className="price-variation-info"></div>

			}
		</div>
	);
};

export default PriceSendValue;