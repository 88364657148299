import StateStatus from "../../utils/status";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAddressByCep } from "./LegalPersonService";

const dataToSend = JSON.parse(localStorage.getItem("dataToSend"));

const initialState = {
  status: {
    cep: StateStatus.idle,
  },
  companyExtraInfo: dataToSend,
  addressLegalPerson: null,
  errorMsg: null,
};

export const getAddressByCepThunk = createAsyncThunk(
  "legalPerson/getAddressByCep",
  async (data) => {
    const response = await getAddressByCep(data);
    return response.data;
  }
);

export const legalPerson = createSlice({
  name: "legalPerson",
  initialState: initialState,
  extraReducers: {
    [getAddressByCepThunk.pending]: (state) => {
      state.status.cep = StateStatus.loading;
    },
    [getAddressByCepThunk.fulfilled]: (state, action) => {
      state.status.cep = StateStatus.succeeded;
      state.addressLegalPerson = action.payload;
    },
    [getAddressByCepThunk.rejected]: (state, action) => {
      state.status.cep = StateStatus.failed;
      state.errorMsg = action.error.message;
    },
  },
});

export const selectLegalPerson = (state) => state.legalPerson;
