import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listReceiveValues, listSendValues, makeReceiveValueExchanges, makeSendValueExchanges } from "../../slice";
import CloseSeveralExchanges from "../../../../common/components/CloseSeveralExchanges";
import { formatCurrencyBrl, formatCurrency } from "../../../../utils/functions";
import CircleTransactionStatus from "../../../../common/components/CircleTransactionStatus";
import TooltipEfex from "../../../../common/components/Tooltip";
import { Card, Col, Form, Modal, Row, Select, Space, Table, Tabs, notification } from "antd";
import { EyeOutlined, LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import { handleResize } from "../../../../utils/responsible";
import ChooseGridStyle from "../../../../common/components/ChooseGridStyle";
import { OperationCard, TableCard } from "../../../../common/components/TableCard";
import { GreenButton, OrangeButton } from "../../../../common/components/Button";
import { isCommercialHourRange } from "../../../../utils/date";
import { DepositReceiptsModal } from "../_components/DepositReceiptsModal";
import { getOperation } from "../thunks";
import { getReceivedValue, getReceivedValueBackground, scheduleExchange } from "../../ReceiveValue/thunks";
import EfexModal from "../../../../common/components/Modal";
import Routes from "../../../../routes/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { TabPane } = Tabs;

const MultApproved = () => {
    const storedSelectedExchanges = JSON.parse(localStorage.getItem('selectedExchanges')) || [];
    const storedTypeOfExchanges = JSON.parse(localStorage.getItem('typeOfExchange')) || '';
    const dispatch = useDispatch();
    const history = useHistory();
    const [modal, contextHolder] = Modal.useModal();
    const [exchanges, setExchanges] = useState([]);
    const [isResponsible, updateResponsible] = useState(false);
    const [gridType, setGridType] = useState("table");
    const [pagination, setPagination] = useState();
    const [selectedSendDate, setSelectedSendDate] = useState(null);
    const [commercialHour, setCommercialHour] = useState(false);
    const [isClosing, setIsClosing] = useState(false)
    const [totalValueBrl, setTotalValueBrl] = useState(0)
    const [totalVauleME, setTotalValueME] = useState(0)
    const [showReceiptsModal, setShowReceiptsModal] = useState(false);
    const [isFreeze, setIsFreeze] = useState(false)
    const [isClosed, setIsClosed] = useState(false)
    const [loading, setIsLoading] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedOperation, setSelectedOperation] = useState({})
    const [showScheduleModal, setShowScheduleModal] = useState(false)
    const [isLoadingSchedule, setIsLoadingSchedule] = useState(false)
    const [calcValues, updateCalcValues] = useState({
        toMe: 0,
        toBrl: 0,
        iof: { value: 0, tax: 0 },
        tarifa: { value: 0, tax: null },
    });

    const paginationArgument = storedTypeOfExchanges === "send_value"
        ? { page: 1, send_values_ids: storedSelectedExchanges }
        : { page: 1, receive_values_ids: storedSelectedExchanges };

    const calcFromME = (exchange) => {
        let finalValue = 0;
        let iof = exchange.taxes.filter(x => x.name.toUpperCase() === "IOF")[0] ?? 0
        let ir = exchange.taxes.filter(x => x.name.toUpperCase() === "IR")[0] ?? 0
        let tarifa = exchange.taxes.filter((x) => x.name.toUpperCase() === "TARIFA")[0] ?? 0
        const valueMe = parseInt(exchange.target_value) / 100
        const iofTax = iof === 0 ? 0 : iof.tax / 10000;
        const irTax = ir === 0 ? 0 : ir.tax / 100000000; // define quantidade de casas registradas pelo BO
        tarifa = tarifa === 0 ? 0 : tarifa.value / 100;

        if (exchange.is_currency_target && valueMe > 0 && exchange.currency_rates / 10000 > 0) {
            iof = (valueMe * (exchange.currency_rates / 10000)) * iofTax;
            ir = (valueMe * (exchange.currency_rates / 10000)) * irTax;

            iof = Math.trunc(iof * 10000) / 10000 // manter 4 casas decimais
            ir = Math.trunc(ir * 10000) / 10000 // manter 4 casas decimais

            finalValue = (valueMe * (exchange.currency_rates / 10000)) + iof + ir + tarifa;
            finalValue = Math.trunc(finalValue * 10000) / 10000 // manter 4 casas decimais
        } else {
            iof = 0
            ir = 0
            tarifa = 0
        }

        updateCalcValues({
            toBrl: finalValue.toFixed(2),
            iof: {
                value: iof.toFixed(2),
                tax: (iofTax * 100).toFixed(2)
            },
            ir: {
                value: ir.toFixed(2),
                tax: (irTax * 100).toFixed(2)
            },
            tarifa: {
                value: tarifa.toFixed(2),
                tax: null
            },
            vet: exchange.currency_rates / 10000,
        });
    };

    const calcFromBrl = (exchange) => {
        let finalValue = 0;
        let iof = exchange.taxes(x => x.name.toUpperCase() === "IOF")[0] ?? 0
        let ir = exchange.taxes(x => x.name.toUpperCase() === "IR")[0] ?? 0
        let tarifa = exchange.taxes((x) => x.name.toUpperCase() === "TARIFA")[0] ?? 0;

        const valueBrl = parseInt(exchange.target_value) / 100
        const iofTax = iof === 0 ? 0 : iof.tax / 10000;
        const irTax = ir === 0 ? 0 : ir.tax / 100000000;
        tarifa = tarifa === 0 ? 0 : tarifa.value / 100;

        if (!exchange.is_currency_target && valueBrl > 0 && exchange.currency_rates / 10000 > 0) {
            let calc = valueBrl / (1 + iofTax + irTax)

            calc = Math.trunc(calc * 10000) / 10000 // manter 4 casas decimais

            iof = calc * iofTax
            ir = calc * irTax


            iof = Math.trunc(iof * 10000) / 10000 // manter 4 casas decimais
            ir = Math.trunc(ir * 10000) / 10000 // manter 4 casas decimais

            finalValue = calc / exchange.currency_rates / 10000
            finalValue = Math.trunc(finalValue * 10000) / 10000  // manter 4 casas decimais
        } else {
            iof = 0
            ir = 0
            tarifa = 0
        }

        updateCalcValues({
            toMe: finalValue.toFixed(2),
            iof: {
                value: iof.toFixed(2),
                tax: (iofTax * 100).toFixed(2)
            },
            ir: {
                value: ir.toFixed(2),
                tax: (irTax * 100).toFixed(2)
            },
            tarifa: {
                value: tarifa.toFixed(2),
                tax: null,
            },
            vet: exchange.currency_rates / 10000,
        })
    };

    const columnsReceive = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Banco no Brasil",
            dataIndex: "receipt_account",
            render: (receipt) => {
                if (receipt)
                    return (
                        <>
                            {receipt.bank_name} - {receipt.account_number}
                        </>
                    );
            },
        },
        {
            title: "Ordenante",
            dataIndex: "payer",
            render: (payer) => {
                if (payer)
                    return (
                        <>
                            {payer}
                        </>
                    );
            },
        },
        {
            title: "Valor",
            dataIndex: "",
            render: (_, record) => {
                var val = formatCurrencyBrl(`${record.target_value}`);
                return (
                    <>
                        {record.is_currency_target
                            ? `${record.currency} ${val}`
                            : `BRL ${val}`}
                    </>
                );
            },
        },
        {
            title: "Status",
            render: function (_, item) {
                return (
                    <div className="col-status">
                        <CircleTransactionStatus status={item.status} />
                        <div>
                            {item.status_display === "Operação criada pelo BO"
                                ? "Ordem de Pagamento Recebida"
                                : item.status_display}
                            {item.status_display ===
                                "Operação criada pelo BO" && (
                                    <TooltipEfex title="Por favor, clique ao lado para preencher as informações desta operação" />
                                )}
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Ação",
            fixed: "right",
            width: 100,
            render: function edit(_, record) {
                return (
                    <Space align="horizontal">
                        {loading[record.id] ? (
                            <LoadingOutlined style={{ fontSize: "18px" }} />
                        ) : (
                            <EyeOutlined
                                style={{ fontSize: "18px" }}
                                onClick={() => {
                                    operationDetails(record)
                                }}
                            />
                        )}

                        {/* <DeleteOutlined
                            style={{ fontSize: "18px" }}
                            onClick={() => showDeleteConfirm(record.id)}
                        /> */}

                    </Space>
                );
            },
        },
    ];

    const columnsSend = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Beneficiário",
            dataIndex: "recipient",
            render: (recipient) => {
                return <>{recipient.name}</>;
            },
        },
        {
            title: "Banco no exterior",
            dataIndex: "recipient_account",
            render: (account) => {
                return <>{account.bank_name}</>;
            },
        },
        {
            title: "Valor",
            dataIndex: "",
            render: (_, record) => {
                var val = formatCurrencyBrl(`${record.target_value}`);
                return (
                    <>
                        {record.is_currency_target
                            ? `${record.recipient_account.currency} ${val}`
                            : `BRL ${val}`}
                    </>
                );
            },
        },
        {
            title: "Status",
            render: function (_, item) {
                return (
                    <div className="col-status">
                        <CircleTransactionStatus status={item.status} />
                        <div>{item.status_display}</div>
                    </div>
                );
            },
        },
        {
            title: "Ação",
            fixed: "right",
            width: 100,
            render: function edit(_, record) {
                return (
                    <Space align="horizontal">
                        {loading[record.id] ? (
                            <LoadingOutlined style={{ fontSize: "18px" }} />
                        ) : (
                            <EyeOutlined
                                style={{ fontSize: "18px" }}
                                onClick={() => { operationDetails(record) }}
                            />
                        )}
                        {/* {[
                            "draft",
                            "waiting_approval",
                            "need_changes",
                            "approved",
                            "waiting_exchange",
                        ].includes(record.status) ? (
                            <DeleteOutlined
                                style={{ fontSize: "18px" }}
                                onClick={() => {
                                    setDeleteModal({
                                        visible: true,
                                        operationId: record.id,
                                        operationType: "send_value",
                                        status: record.status,
                                    });
                                }}
                            />
                        ) : null} */}
                    </Space>
                );
            },
        },
    ];

    const onCloseOperations = () => {
        setIsClosing(true)
        setIsFreeze(true)
        var data = {};
        data.transaction_day = selectedSendDate ? selectedSendDate : null

        if (storedTypeOfExchanges === "send_value") {
            data.send_value_ids = storedSelectedExchanges
            dispatch(makeSendValueExchanges(data)).then(({ payload, error }) => {
                if (payload !== undefined) {
                    setShowReceiptsModal(true)
                    setIsClosing(false);
                    setIsClosed(true)
                    setIsFreeze(true)
                    notification.success({
                        message: `Sucesso!`,
                        description: "Câmbio fechado com sucesso",
                        placement: "topRight",
                    })
                    history.push(`${Routes.dashboard}`)
                } else {
                    setIsClosing(false);
                    notification.error({
                        message: `Erro!`,
                        description: error.message,
                        placement: "topRight",
                    });
                }
            });
        } else {
            data.receive_value_ids = storedSelectedExchanges
            dispatch(makeReceiveValueExchanges(data)).then(({ payload, error }) => {
                if (payload !== undefined) {
                    setShowReceiptsModal(true)
                    setIsClosing(false)
                    setIsClosed(true)
                    setIsFreeze(true)
                    notification.success({
                        message: `Sucesso!`,
                        description: "Câmbio fechado com sucesso",
                        placement: "topRight",
                    })
                    history.push(`${Routes.dashboard}`)
                } else {
                    setIsClosing(false);
                    notification.error({
                        message: `Erro!`,
                        description: error.message,
                        placement: "topRight",
                    });
                }
            })
        }
    };

    useEffect(() => {
        if (selectedOperation.id && selectedOperation.is_currency_target) {
            calcFromME(selectedOperation)
        } else if (selectedOperation.id && !selectedOperation.is_currency_target) {
            calcFromBrl(selectedOperation)
        }
        selectedOperation.id ? setIsModalVisible(true) : setIsModalVisible(false)
    }, [selectedOperation])

    const operationDetails = (record) => {
        if (storedTypeOfExchanges === "send_value") {
            setIsLoading({ ...loading, [record.id]: true })
            dispatch(getOperation({ sendValueId: record.id }))
                .then((response) => {
                    setSelectedOperation(response.payload)
                    setIsLoading({})
                })
                .catch((error) => {
                    setIsLoading({})
                    setIsClosing(false);
                    notification.error({
                        message: `Erro!`,
                        description: error.message,
                        placement: "topRight",
                    });

                });
        } else {
            setIsLoading({ ...loading, [record.id]: true })
            dispatch(getReceivedValue(record.id))
                .then((response) => {
                    setSelectedOperation(response.payload)
                    setIsLoading({})
                })
                .catch((error) => {
                    setIsLoading({})
                    setIsClosing(false);

                    notification.error({
                        message: `Erro!`,
                        description: error.message,
                        placement: "topRight",
                    });
                });
        }
    }

    const exchangesData = () => {
        return new Promise((resolve, reject) => {
            if (storedTypeOfExchanges === "send_value") {
                dispatch(listSendValues({ page: 1, send_values_ids: storedSelectedExchanges }))
                    .then(response => {
                        setExchanges(response.payload);
                        resolve(response.payload);
                    })
                    .catch(error => {
                        reject(error);
                    });
            } else {
                dispatch(listReceiveValues({ page: 1, receive_values_ids: storedSelectedExchanges }))
                    .then(response => {
                        setExchanges(response.payload);
                        resolve(response.payload);
                    })
                    .catch(error => {
                        reject(error);
                    });
            }
        });

    }

    useEffect(() => {
        exchangesData()
    }, []);

    useEffect(() => {
        dispatch(listSendValues({ page: 1 }));
        dispatch(listReceiveValues({ page: 1 }));
        window.addEventListener("resize", () =>
            handleResize(updateResponsible)
        );
        handleResize(updateResponsible);
    }, []);

    useEffect(() => {
        isCommercialHourRange().then((response) => {
            setCommercialHour(response.data.is_open_to_exchange);
        });
    }, []);

    useEffect(() => {
        if (isResponsible && gridType !== "card") {
            setGridType("card");
        }
    }, [isResponsible]);

    useEffect(() => {
        if (exchanges?.success && storedTypeOfExchanges === "send_value") {
            setPagination({
                current: exchanges?.pagination.current_page,
                total: exchanges?.pagination.total,
                pageSize: 15,
            });
        }
    }, [exchanges?.success]);

    useEffect(() => {
        if (storedTypeOfExchanges === "receive_value" && isClosed && showReceiptsModal) {
            modal.success({
                title: "Negócio fechado!",
                content: (
                    <>
                        <p>
                            Em breve você receberá o contrato de câmbio em seu
                            e-mail.
                        </p>
                        {exchanges?.success && exchanges?.lst[0].currency === "USD" && selectedSendDate === "D0" && (
                            <p>
                                Para que os reais possam ser transferidos ainda hoje para sua conta, precisaremos que o contrato seja assinado até as 16:30h, combinado?
                            </p>
                        )}
                    </>
                ),
            })
        }
    }, [modal, isClosed, showReceiptsModal, exchanges?.success]);

    const onScheduleOperation = () => {
        var data = {};
        data.receive_value_ids = exchanges?.lst.map(item => item.id)
        data.transaction_day = selectedSendDate;
        data.currency = exchanges?.lst[0].currency
        data.isScheduling = true

        setIsLoadingSchedule(true)
        dispatch(scheduleExchange(data)).then(({ payload, error }) => {

            if (payload !== undefined) {
                exchangesData().then(() => {
                    setIsLoadingSchedule(false)
                    setShowScheduleModal(false)
                })
            } else {
                setIsLoadingSchedule(false)
                notification.error({
                    message: `Erro!`,
                    description: error.message,
                    placement: "topRight",
                });
            }
        });
    }

    const onCancelScheduleOperation = () => {
        var data = {};
        data.receive_value_ids = exchanges?.lst.map(item => item.id)

        setSelectedSendDate(null)
        setIsLoadingSchedule(true)
        dispatch(scheduleExchange(data)).then(({ payload, error }) => {

            if (payload !== undefined) {
                setSelectedSendDate(null)
                exchangesData().then(() => {
                    setIsLoadingSchedule(false)
                })
            } else {
                setIsLoadingSchedule(false)
                notification.error({
                    message: `Erro!`,
                    description: error.message,
                    placement: "topRight",
                });
            }
        });
    }

    // const handleRenderUpdatedData = async () => {
    //     // Lógica para renderizar novamente as informações na tela
    //     if (storedTypeOfExchanges === 'send_value') {
    //         const response = dispatch(listSendValues({ page: 1, send_values_ids: storedSelectedExchanges }));
    //         setExchanges(response.payload);
    //     } else {
    //         const response = dispatch(listReceiveValues({ page: 1, receive_values_ids: storedSelectedExchanges }));
    //         setExchanges(response.payload);
    //     }
    // };


    // const showDeleteConfirm = (transactionId) => {
    //     Modal.confirm({
    //         title: 'Confirmação',
    //         content: 'Tem certeza que deseja deletar?',
    //         okText: 'Sim',
    //         okType: 'danger',
    //         cancelText: 'Cancelar',
    //         async onOk() {
    //             try {
    //                 const updatedSelectedExchanges = storedSelectedExchanges.filter(
    //                     (id) => id !== transactionId
    //                 );

    //                 localStorage.setItem('selectedExchanges', JSON.stringify(updatedSelectedExchanges));

    //                 await handleRenderUpdatedData();

    //             } catch (error) {
    //                 message.error({ content: error.message });
    //             }
    //         },
    //         onCancel() {
    //         },
    //     });
    // };

    return (
        <Row lg={24} style={{ gap: "10px" }}>

            <Col xl={16} lg={16} md={24} sm={24} xs={24}>
                <Card style={{ padding: "20px" }}>
                    <Tabs
                        defaultActiveKey="1"
                        tabBarExtraContent={
                            <ChooseGridStyle
                                selectDefault={gridType}
                                onChange={(grid) => setGridType(grid)}
                            />
                        }
                    >
                        <TabPane tab="Operações Selecionadas" key="1">
                            {gridType === "card" ? (
                                <TableCard
                                    dataSource={exchanges?.lst}
                                    loading={
                                        !exchanges?.success
                                    }
                                    pagination={pagination}
                                    onChange={(page) => {
                                        dispatch(listSendValues({ page, paginationArgument }));
                                    }}
                                    render={(item, index) => (
                                        <OperationCard
                                            loading={loading}
                                            id={item.id}
                                            isAproved={item.status === "approved"}
                                            exchange={item}
                                            storedSelectedExchanges={storedSelectedExchanges}
                                            onClick={() => { operationDetails(item) }}
                                            data={{
                                                id: item.id,
                                                status: item.status,
                                                status_display: item.status_display,
                                                recipient_name: item.recipient?.name || null,
                                                bank_name:
                                                    item.receipt_account?.bank_name || item.recipient_account?.bank_name || "Banco não informado",
                                                currency:
                                                    item.currency || item.recipient_account?.currency,
                                                is_currency_target:
                                                    item.is_currency_target,
                                                target_value: item.target_value,
                                                payer: item.payer
                                            }}
                                            isMultiple={true}
                                            operationDetails={operationDetails}
                                            typeOfExchange={storedTypeOfExchanges}
                                        />
                                    )}
                                />
                            ) : (
                                <Table
                                    locale={{ emptyText: "Sem resultado" }}
                                    dataSource={exchanges?.lst}
                                    pagination={pagination}
                                    onChange={(pagination) => {
                                        dispatch(
                                            listSendValues({ page: pagination.current, paginationArgument })
                                        );
                                    }}
                                    columns={(storedTypeOfExchanges === "send_value" ? columnsSend : columnsReceive)}
                                    // rowClassName={(exchange) => selectedExchanges.includes(exchange.id) ? 'selected-card' : ''}
                                    loading={
                                        !exchanges?.success
                                    }
                                />
                            )}
                        </TabPane>
                    </Tabs>
                </Card>
            </Col>

            <Col xl={7} lg={7} md={24} sm={24} xs={24}>
                <Row style={{ justifyContent: "center", gap: "10px" }}>
                    {exchanges?.success &&
                        <Col xl={24} lg={24} md={16} sm={24} xs={24}>
                            <CloseSeveralExchanges
                                selectedExchanges={storedSelectedExchanges}
                                transaction_day={selectedSendDate}
                                typeOfExchange={storedTypeOfExchanges}
                                exchanges={exchanges}
                                isApproved={true}
                                setTotalValueBrl={setTotalValueBrl}
                                setTotalValueME={setTotalValueME}
                                setIsFreeze={setIsFreeze}
                                isFreeze={isFreeze}
                                isClosed={isClosed}
                                valueToSend={exchanges?.lst.map((item) => {
                                    return { id: item.id, sendValue: item.target_value }
                                })}
                                isCurrencyTarget={exchanges?.lst[0].is_currency_target}
                                currency={exchanges?.lst[0].recipient_account?.currency || exchanges?.lst[0].currency}
                                taxes={exchanges?.lst.map((item) => {
                                    return { id: item.id, taxes: item.taxes }
                                })}
                                maxTime={10}
                                onChangeSelect={setSelectedSendDate}
                                selectedSendDate={selectedSendDate}
                                commercialHour={commercialHour}
                                sendValueStatus={"approved"}
                            />
                            {exchanges?.success &&
                                !isClosed && (
                                    (exchanges?.lst[0].status === "approved" ||
                                        exchanges?.lst[0].status === "scheduled") && (
                                        (commercialHour && (
                                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                <Space>
                                                    {window.location.hash.includes("empresas") ? (
                                                        <OrangeButton
                                                            disabled={isFreeze ||
                                                                !commercialHour ||
                                                                (!selectedSendDate &&
                                                                    (exchanges?.lst[0].recipient_account?.currency === "USD" ||
                                                                        exchanges?.lst[0].currency === "USD"))}
                                                            loading={isClosing}
                                                            htmlType="submit"
                                                            type="primary"
                                                            onClick={onCloseOperations}
                                                        >
                                                            Fechar Câmbio
                                                        </OrangeButton>
                                                    ) : (
                                                        <GreenButton
                                                            disabled={isFreeze ||
                                                                !commercialHour ||
                                                                (!selectedSendDate &&
                                                                    (exchanges?.lst[0].recipient_account?.currency === "USD" ||
                                                                        exchanges?.lst[0].currency === "USD"))}
                                                            loading={isClosing}
                                                            htmlType="submit"
                                                            type="primary"
                                                            onClick={onCloseOperations}
                                                        >
                                                            Fechar Câmbio
                                                        </GreenButton>
                                                    )}
                                                </Space>
                                            </Col>
                                        )) ||
                                        (!commercialHour &&
                                            exchanges?.lst[0].currency === "USD" &&
                                            storedTypeOfExchanges === "receive_value" &&
                                            exchanges?.lst.some(item => item.scheduled === false) && (
                                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                    <Space>
                                                        {window.location.hash.includes("empresas") ? (
                                                            <OrangeButton
                                                                type="primary"
                                                                classes="schedule-exchange-button"
                                                                onClick={() => setShowScheduleModal(true)}
                                                                disabled={commercialHour}
                                                            >
                                                                Programar fechamento automático
                                                            </OrangeButton>
                                                        ) : (
                                                            <GreenButton
                                                                type="primary"
                                                                classes="schedule-exchange-button"
                                                                onClick={() => setShowScheduleModal(true)}
                                                                disabled={commercialHour}
                                                            >
                                                                Programar fechamento automático
                                                            </GreenButton>
                                                        )}
                                                    </Space>
                                                </Col>
                                            )) ||
                                        (!commercialHour &&
                                            exchanges?.lst[0].currency === "USD" &&
                                            storedTypeOfExchanges === "receive_value"
                                            && exchanges?.lst.every(item => item.scheduled === true) && (
                                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                    <Space>
                                                        {window.location.hash.includes("empresas") ? (
                                                            <OrangeButton
                                                                type="primary"
                                                                classes="schedule-exchange-button"
                                                                onClick={onCancelScheduleOperation}
                                                                disabled={commercialHour}
                                                                loading={isLoadingSchedule}
                                                            >
                                                                Cancelar fechamento programado
                                                            </OrangeButton>
                                                        ) : (
                                                            <GreenButton
                                                                type="primary"
                                                                classes="schedule-exchange-button"
                                                                onClick={onCancelScheduleOperation}
                                                                disabled={commercialHour}
                                                                loading={isLoadingSchedule}
                                                            >
                                                                Cancelar fechamento programado
                                                            </GreenButton>
                                                        )}
                                                    </Space>
                                                </Col>
                                            ))
                                    )
                                )}
                        </Col>}
                </Row>
            </Col >
            {contextHolder}
            {
                storedTypeOfExchanges === "send_value" &&
                <DepositReceiptsModal
                    valueBRL={totalValueBrl}
                    value={totalVauleME}
                    currency={exchanges?.success && (exchanges?.lst[0].recipient_account?.currency || exchanges?.lst[0].currency)}
                    transaction_day={selectedSendDate}
                    isVisible={showReceiptsModal}
                    isLoading={
                        isClosing
                    }
                    onCancel={() => setShowReceiptsModal(false)}
                // onOk={(values) => {
                //     onSendDepositReceipt(values.files);
                // }}
                />
            }
            <EfexModal
                title="Dados da Transação"
                onOk={() => {
                    setIsModalVisible(false)
                    setSelectedOperation({})
                }}
                onCancel={() => {
                    setIsModalVisible(false)
                    setSelectedOperation({})
                }}
                visible={isModalVisible}
                hasCancel={false}
            >
                <div div
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div>
                        <strong>
                            Motivo da operação informado:
                        </strong>
                        <div>
                            {
                                selectedOperation.nature_display
                            }
                        </div>
                    </div>
                    <br />
                    <ul>
                        {["services"].includes(
                            selectedOperation.nature
                        ) && selectedOperation.has_payment_relationship_display
                            && (
                                <li>
                                    {
                                        selectedOperation.has_payment_relationship_display
                                    }
                                </li>
                            )}
                        {[
                            "finance",
                            "services",
                            "other",
                        ].includes(
                            selectedOperation.nature
                        ) && selectedOperation.is_tax_by_recipient_display
                            && (
                                <li>
                                    {
                                        selectedOperation.is_tax_by_recipient_display
                                    }
                                </li>
                            )}

                        {["commercial"].includes(
                            selectedOperation.nature
                        ) && selectedOperation.has_advance_payment_display
                            && (
                                <li>
                                    {
                                        selectedOperation.has_advance_payment_display
                                    }
                                </li>
                            )}
                        {["services"].includes(
                            selectedOperation.nature
                        ) && selectedOperation.has_capital_gain_display
                            && (
                                <li>
                                    {
                                        selectedOperation.has_capital_gain_display
                                    }
                                </li>
                            )}
                    </ul>
                    <br />
                    {selectedOperation.nature_exchange !==
                        null && (
                            <div>
                                <strong>
                                    Natureza de câmbio definida
                                    pela efexHub:
                                </strong>
                                <div>
                                    {
                                        selectedOperation.nature_exchange
                                    }{" "}
                                    -{" "}
                                    {
                                        selectedOperation.nature_display
                                    }
                                </div>
                                <br />
                            </div>
                        )}
                    <div>
                        <strong>
                            Dados Bancários:
                        </strong>
                        <div>
                            Banco:{" "}
                            {selectedOperation.receipt_account?.bank_name || selectedOperation.recipient_account?.bank_name}
                        </div>
                        <div>
                            Agência e Conta:{" "}
                            {selectedOperation.receipt_account?.agency_number || selectedOperation.recipient_account?.agency_number}
                            {" "}-{" "}
                            {selectedOperation.receipt_account?.account_number || selectedOperation.recipient_account?.account_number}
                        </div>
                        <br />
                        <span>
                            <strong>Impostos e tarifas inclusos no valor final:</strong>
                            {calcValues.iof?.value > 0 && (
                                <p>
                                    IOF: {formatCurrency(calcValues.iof?.value, "BRL")}
                                    {" "}({calcValues.iof.tax}%)
                                </p>
                            )}
                            {calcValues.ir?.value > 0 && (
                                <p>
                                    IR: {formatCurrency((calcValues.ir?.value), "BRL")}
                                    {" "}({calcValues.ir?.tax}%)
                                </p>
                            )}
                            {calcValues.tarifa?.value > 0 && (
                                <p>
                                    Tarifa: {formatCurrency(calcValues.tarifa?.value, "BRL")}
                                </p>
                            )}
                            {calcValues.toBrl > 0 && (
                                <p>
                                    Total da Operação: {formatCurrency(calcValues.toBrl, "BRL")}
                                </p>
                            )}
                        </span>
                    </div>
                </div>
            </EfexModal>
            <EfexModal
                hasCancel={true}
                classes="is-scheduling-exchange-button"
                visible={showScheduleModal}
                centered={true}
                onOk={onScheduleOperation}
                onCancel={() => setShowScheduleModal(false)}
                title="Programar Operação"
                cancelText="Não, quero cancelar"
                okText={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {isLoadingSchedule && <SyncOutlined spin />}
                        <p style={{ marginLeft: 8 }}>Sim, confirmar operação programada</p>
                    </div>
                }

            >
                <p>
                    Olá. você está optando por deixar sua operação programada para fechamento automático.
                </p>
                <p>
                    Nesse caso, o fechamento acontecerá automaticamente às 09:00 horas do próximo dia útil. A taxa utilizada dependerá da cotação disponível no momento em que a ordem for fechada, mas não se procupe, você receberá todas as informações em seu e-mail.
                </p> <br />
                <Form.Item
                    label={
                        <>
                            Escolha abaixo a data para o recebimento dos recursos:
                            <TooltipEfex
                                title="Atenção:  data de liquidação dos recursos pode sofrer alterações quando houver feriado bancários nos Estados Unidos.
										Caso tenha alguma dúvida, basta falar conosco pelo chat."
                            />
                        </>
                    }
                    labelCol={{ span: 24 }}
                    name="send_date"
                    rules={[
                        {
                            required: true,
                            message: "Por gentileza informe a data para o envio",
                            validator: (_, value) => {
                                if (value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("Por favor, selecione uma opção."));
                            },
                        },
                    ]}
                >
                    <Select onChange={(value) => setSelectedSendDate(value)} value={selectedSendDate}>
                        <Select.Option value="D0">
                            Receber os recursos no dia do fechamento
                        </Select.Option>
                        <Select.Option value="D1">
                            Receber os recursos em um dia útil após o fechamento
                        </Select.Option>
                        <Select.Option value="D2">
                            Receber os recursos em 2 dias úteis após o fechamento
                        </Select.Option>
                    </Select>
                </Form.Item>
                <p>
                    Confirmar?
                </p>
            </EfexModal>
        </Row >

    );
}

export default MultApproved
