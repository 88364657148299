import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Input, notification, Tooltip, Button } from "antd";
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";

import MaskedInput from "antd-mask-input";
import { useRouteMatch } from "react-router-dom";
import { cpf as cpfValidator } from "cpf-cnpj-validator";
import { selectExchange } from "../../Exchange/slice.js";
import {
    selectLogin,
    updateProfile,
    resetUpdateProfileStatus,
} from "../../Login/LoginSlice.js";
import EfexModal from "../../../common/components/Modal";
import StateStatus from "../../../utils/status";
import { maskPhone } from "../../../utils/functions.js";
import "../styles.scss";
import { WhiteButton } from "../../../common/components/Button/index.js";
import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal.js";
import { mask } from "../../../utils/consts.js";

const ManagerProfile = ({ visible, data, onOk, onCancel }) => {
    const [form] = Form.useForm();
    const exchange = useSelector(selectExchange);
    const loginProfile = useSelector(selectLogin);
    const [isVisible, updateVisible] = useState(false);

    const dispatch = useDispatch();
    const { path } = useRouteMatch();

    const onCancelChangePwd = () => {
        updateVisible(false);
    };
    const openChangePwd = () => {
        updateVisible(true);
    };

    useEffect(() => {
        form.setFieldsValue(loginProfile.profile);
    }, [form]);

    useEffect(() => {
        if (loginProfile.status.updateProfile == StateStatus.succeeded) {
            notification.success({
                message: `Sucesso!`,
                description: "Perfil atualizado com sucesso!",
                placement: "topRight",
            });
            form.setFieldsValue(loginProfile.profile);
            dispatch(resetUpdateProfileStatus());
            onCancel();
        }

        if (loginProfile.status.updateProfile == StateStatus.failed) {
            notification.error({
                message: `Erro!`,
                description: loginProfile.errorMsg,
                placement: "topRight",
            });
            dispatch(resetUpdateProfileStatus());
        }
    }, [loginProfile]);

    return (
        <EfexModal
            className={
                path.match("empresas") ? "modal-company" : "modal-person"
            }
            title={"Gerenciar Conta"}
            visible={visible}
            confirmLoading={
                loginProfile.status.updateProfile == StateStatus.loading
            }
            onCancel={() => {
                form.setFieldsValue(loginProfile.profile);
                onCancel();
            }}
            cancelText="Excluir conta"
            onDelete={() => {
                notification.info({
                    message: "Atenção",
                    description: "Para deletar sua conta deve entrar em contato conosco pelo WhatsApp."
                })
                form.setFieldsValue(loginProfile.profile);
            }}
            isDelete={true}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        let data = {
                            ...values,
                            phone: cpfValidator.strip(values.phone),
                        };
                        dispatch(updateProfile(data));
                    })
                    .catch((info) => { });
            }}
        >
            <Form aria-autocomplete="none" form={form} layout="vertical">
                {/* <ErrorMsg message={exchange.errorMsg}></ErrorMsg> */}
                <Row gutter={[8, 0]}>
                    <Col span={24}>
                        <Form.Item label="Nome" name={"name"}>
                            <Input
                                placeholder="Nome"
                                type="text"
                                disabled
                            ></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={
                                <>
                                    Email
                                    {loginProfile.profile.is_email_confirmed ? (
                                        <Tooltip title="Email verificado.">
                                            <CheckCircleOutlined
                                                style={{
                                                    marginLeft: "4px",
                                                    color: "green",
                                                }}
                                            />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Email não verificado, clique aqui para reenviar o link de verificação.">
                                            <ExclamationCircleOutlined
                                                style={{
                                                    marginLeft: "4px",
                                                    color: "red",
                                                }}
                                                onClick={() => { }}
                                            />
                                        </Tooltip>
                                    )}
                                </>
                            }
                            name={"email"}
                            rules={[
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ]}
                        >
                            <Input placeholder="Email" type="text"></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Telefone" name={"phone"}>
                            <Input placeholder="Telefone" type="text"></Input>

                            {/* <MaskedInput
                                mask="(00) 00000-0000"
                                placeholder="Telefone"
                            ></MaskedInput> */}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <WhiteButton
                style={{ width: "100px" }}
                hoverColor={path.match("empresas") ? "orange" : "green"}
                onClick={openChangePwd}
            >
                Trocar senha
            </WhiteButton>
            <ChangePasswordModal
                visible={isVisible}
                onCancel={onCancelChangePwd}
            />
        </EfexModal>
    );
};

export default ManagerProfile;
