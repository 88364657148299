import StateStatus from "../../utils/status";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getNotification, putNotification } from "./notificationService";

const initialState = {
    notification: StateStatus.idle,
    status: {
        getNotification: StateStatus.idle,
        patchNotification: StateStatus.idle
    },
    errorMsg: null,
};

export const getNotificationThunk = createAsyncThunk(
    "exchange/getNotificationThunk",
    async () => {
        const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;
        const response = await getNotification({cpf_cnpj});
		return response.data;
    }
);

export const patchNotificationThunk = createAsyncThunk(
    "exchange/notifications",
    async (notification_id) => {
        const cpf_cnpj =
			JSON.parse(localStorage.getItem("owner")).cpf !== undefined
				? JSON.parse(localStorage.getItem("owner")).cpf
				: JSON.parse(localStorage.getItem("owner")).cnpj;
        
        const response = await putNotification({cpf_cnpj, notification_id});
        return response.data;
    }
);

export const notificationSlice = createSlice({
    name: "notification",
    initialState: initialState,
    extraReducers: {
        [getNotificationThunk.pending]: (state) => {
            state.status.getNotification = StateStatus.loading;
        },
        [getNotificationThunk.fulfilled]: (state, action) => {
            state.status.getNotification = StateStatus.succeeded;
            state.notification = action.payload;
        },
        [getNotificationThunk.rejected]: (state, action) => {
            state.status.getNotification = StateStatus.failed;
            state.errorMsg = action.error.message;
        },

        [patchNotificationThunk.pending]: (state) => {
            state.status.patchNotification = StateStatus.loading;
        },
        [patchNotificationThunk.fulfilled]: (state) => {
            state.status.patchNotification = StateStatus.succeeded;
            state.notification = StateStatus.idle;
        },
        [patchNotificationThunk.rejected]: (state, action) => {
            state.status.patchNotification = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
    },
});

export const selectNotification = (state) => state.notification;
