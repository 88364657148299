import React from "react"
import addQsa from "../../../../common/assets/add-qsa.svg"
import "./styles.scss"

const BlankQsa = () => {
    return (<div className="blank-container">
        <img src={addQsa} alt='imagem para representar a função: Adicione um responsável financeiro'/>
        <p>Adicione um responsável financeiro</p>
    </div>)
}


export default BlankQsa