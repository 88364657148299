import React, { useState, useEffect } from "react";

import {
    Form,
    Input,
    Typography,
    Row,
    Col,
    Select,
    Divider,
    InputNumber,
} from "antd";

import { useDispatch, useSelector } from "react-redux";

import TypeSelectorInput from "../../../common/components/TypeSelectorInput/TypeSelectorInput";
import IdentedField from "../../../common/components/IdentedField";

import { formatCurrency, scrollUp } from "../../../utils/functions";
import { countriesWithouBrazilList } from "../../../utils/lists";
import StateStatus from "../../../utils/status";

import { estimate, purposePerson } from "../../../utils/consts";

import { selectSecondStep, getKycData, addKycData } from "./slice";
import PersonBase from "../common/Base";

import Routes from "../../../routes/routes";
import { selectPerson, getPerson, resetStatusGetPerson } from "../../Person/slice";
import "./styles.scss";
import {
    getCadastralData,
    resetStatusGetCadastralData,
} from "../FirstStep/slice";

import { GreenButton, WhiteButton } from "../../../common/components/Button";
import { EfexCard } from "../../../common/components/Card";
import TooltipEfex from "../../../common/components/Tooltip";

const { Title } = Typography;
const { Option } = Select;

const SecondStep = (props) => {
    const [form] = Form.useForm();
    const [isOther, setIsOther] = useState(false);
    const [value, setValue] = useState([]);
    const [isFinishing, setIsFinishing] = useState(false);
    const [isMovLess, setIsMovLess] = useState(false);
    const person = useSelector(selectPerson);
    const secondStep = useSelector(selectSecondStep);
    const dispatch = useDispatch();

    const register_update = person.person?.register_update

    useEffect(() => {
        if (person.status.getPerson === StateStatus.succeeded) {
            dispatch(resetStatusGetPerson());
        }
    }, [])

    useEffect(() => {
        if (person.status.getPerson === StateStatus.idle && !register_update) {
            dispatch(getPerson()).then(({ payload }) => {
                dispatch(getKycData({ cpf: payload.cpf })).then(
                    ({ payload }) => {
                        form.setFieldsValue({
                            ...payload,
                            countries_of_payers:
                                payload.countries_of_payers !== null
                                    ? payload.countries_of_payers
                                    : [],
                            countries_of_receivers:
                                payload.countries_of_receivers !== null
                                    ? payload.countries_of_receivers
                                    : [],
                            purposes_exchange:
                                payload.purposes_exchange !== null
                                    ? payload.purposes_exchange
                                    : [],
                            payers_and_buyers_country:
                                payload.payers_and_buyers_country
                                    ? payload.payers_and_buyers_country
                                    : [],
                        });
                        setIsMovLess(payload.is_movement_less_15)
                    }
                );
            });
        }
        if (secondStep.cadastralData === null) dispatch(getKycData());
    });

    useEffect(() => {
        if (
            secondStep.status.addKycData === StateStatus.succeeded &&
            isFinishing
        ) {
            setIsFinishing(false);
            dispatch(getCadastralData());
            if (register_update) {
                let update_docs = register_update?.identity_document ||
                    register_update?.financial_documentation ||
                    register_update?.proof_of_address

                if (update_docs || (person.person.is_movement_less_15 && form.getFieldValue("is_movement_less_15") === false)) {
                    props.history.push(Routes.fourthStep);
                } else {
                    props.history.push({
                        pathname: Routes.finishedRegister,
                        state: { type: "person" }
                    });
                }
            } else {
                props.history.push(Routes.thirdStep);
            }
        }
    }, [secondStep.status.addKycData, isFinishing, dispatch, props.history, form, register_update, person]);


    const onFinish = (values) => {
        let data = {}
        data = values

        setIsFinishing(true);
        let cpf = person.person.cpf;
        let name = person.person.name;
        values.cpf = cpf;
        values.name = name;
        values.is_movement_less_15 ? values.operational_limit = 1500000 : values.operational_limit = 0
        if (values.other_field === undefined) delete values.other_field;

        if (register_update) data.is_uploading = true


        dispatch(addKycData(values)).then(() => {
            register_update && dispatch(getPerson())
        });
    };

    const selectProps = {
        mode: "multiple",
        style: {
            width: "100%",
        },
        options: purposePerson,
        value,
        onChange: (newValue) => {
            setValue(newValue);
            if (newValue.includes(13)) {
                setIsOther(true);
            } else {
                setIsOther(false);
            }
        },
        placeholder: "Selecionar propósitos",
    };

    return (
        <>
            <PersonBase
                {...props}
                current={1}
                titleL3="Objetivo das transações cambiais"
                text={!register_update ? "Complete os campos que faltam com as informações." : "Atualize as informações do cadastro"}
            >
                <Form
                    className="legal-person-extra"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    requiredMark={false}
                    scrollToFirstError={true}
                >
                    <Row gutter={[16, 16]} className="row-empresa-endereco">
                        <Col>
                            <EfexCard>
                                <Title className="subtitle" level={5}>
                                    Dados complementares
                                </Title>
                                <Divider />
                                <Form.Item
                                    name="economic_activity"
                                    label="Fale um pouco mais sobre qual sua principal atividade econômica?"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ]}
                                >
                                    <Input.TextArea placeholder="" />
                                </Form.Item>

                                <Form.Item
                                    name="reason_for_exchange"
                                    label={
                                        <>
                                            Finalidade das operações de câmbio
                                            <TooltipEfex title="Ex: Pagamento de cliente no exterior" />
                                        </>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Informe a finalidade",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Finalidade" />
                                </Form.Item>

                                <Form.Item
                                    name="is_movement_less_15"
                                    label={
                                        <>
                                            Movimentação inferior a US$
                                            15.000,00 por ano (ou equivalente em
                                            outras moedas)?
                                            <TooltipEfex
                                                title="Ao marcar essa opção, 
                                            você não precisará nos encaminhar os documentos 
                                            financeiros da pessoa e o cadastro prosseguirá 
                                            com limite transacional de até US$ 15.000,00 
                                            (quinze mil dólares) por ano."
                                            />
                                        </>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Esse campo é obrigatório",
                                        },
                                    ]}
                                >
                                    <Select
                                        className="is-movement-less-15-select"
                                        onChange={(v) => {
                                            setIsMovLess(v);
                                            form.setFieldValue(
                                                "estimated_values",
                                                1500000
                                            );
                                        }}
                                    >
                                        <Option value={true} className="is-movement-less-15-option">Sim</Option>
                                        <Option value={false}>Não</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="estimated_values"
                                    label="Valor aproximado, em dólares, a ser enviado e recebido anualmente"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Informe a estimativa",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (
                                                    value != null &&
                                                    (isMovLess ? value > 1499999 : value > 1500000)
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        "Valor deve ser maior que 15 mil"
                                                    )
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <InputNumber
                                        className="input-value-exchange"
                                        min={1}
                                        disabled={isMovLess}
                                        formatter={(value) =>
                                            formatCurrency(value, "US$")
                                        }
                                        parser={(money) =>
                                            money.replace(/\D/g, "")
                                        }
                                    ></InputNumber>
                                </Form.Item>

                                <Form.Item
                                    name="payers_and_buyers_country"
                                    label="Países dos pagadores/recebedores"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Informe o(s) país(es)",
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        placeholder="Países dos pagadores e recebedores"
                                        optionLabelProp="label"
                                    >
                                        {countriesWithouBrazilList.map(
                                            (item) => {
                                                return (
                                                    <Option
                                                        key={item.pais}
                                                        value={item.pais}
                                                        className={`${item.pais}-option`}
                                                    >
                                                        {item.pais}
                                                    </Option>
                                                );
                                            }
                                        )}
                                    </Select>
                                </Form.Item>
                                {/* <Form.Item
                                    name="countries_of_receivers"
                                    label="Países dos recebedores"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Informe o(s) pais(es)",
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        placeholder="Países dos recebedores"
                                        optionLabelProp="label"
                                    >
                                        {countriesWithouBrazilList.map(
                                            (item) => {
                                                return (
                                                    <Option
                                                        key={item.pais}
                                                        value={item.pais}
                                                    >
                                                        {item.pais}
                                                    </Option>
                                                );
                                            }
                                        )}
                                    </Select>
                                </Form.Item> */}
                            </EfexCard>
                        </Col>
                        <Col className="purpose-col">
                            <EfexCard>
                                <Title className="subtitle" level={5}>
                                    As remessas são principalmente para:
                                </Title>
                                <Divider />
                                <Form.Item
                                    name="purposes_exchange"
                                    style={{
                                        width: "100%",
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Selecione o propósito",
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        value={value}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                            if (newValue.includes(13)) {
                                                setIsOther(true);
                                            } else {
                                                setIsOther(false);
                                            }
                                        }}
                                        placeholder="Selecionar propósitos"
                                    >
                                        {purposePerson.map(
                                            (item) => {
                                                return (
                                                    <Option
                                                        key={item.value}
                                                        value={item.value}
                                                        className={`purposes-exchange-option-${item.value}`}
                                                    >
                                                        {item.label}
                                                    </Option>
                                                );
                                            }
                                        )}
                                    </Select>
                                </Form.Item>
                                {isOther ? (
                                    <p className="subtitle">Outros:</p>
                                ) : null}
                                <IdentedField>
                                    <Form.Item
                                        hidden={!isOther}
                                        name="other_field"
                                        label=""
                                        rules={[
                                            {
                                                required: isOther,
                                                message: "Campo obrigatório",
                                            },
                                        ]}
                                    >
                                        <Input.TextArea placeholder="" />
                                    </Form.Item>
                                </IdentedField>
                            </EfexCard>
                        </Col>
                    </Row>
                    <Form.Item className="btn-back-next">
                        {!register_update &&
                            <WhiteButton
                                hoverColor="green"
                                onClick={() => {
                                    props.history.push(Routes.firstStep);
                                    scrollUp();
                                }}
                            >
                                Voltar
                            </WhiteButton>
                        }

                        <GreenButton
                            style={{ fontWeight: "500", width: "100px" }}
                            type="primary"
                            htmlType="submit"
                            loading={
                                secondStep.status.addKycData ===
                                StateStatus.loading
                            }
                        >
                            {secondStep.status.addKycData ===
                                StateStatus.loading
                                ? "Carregando"
                                : register_update
                                    ? "Atualizar"
                                    : "Próximo"}
                        </GreenButton>
                    </Form.Item>
                </Form>
            </PersonBase>
        </>
    );
};

export default SecondStep;
