import Home from "../features/Home";
import ChooseCompany from "../features/ChooseCompany/ChooseCompany";
import CompleteQsaInfo from "../features/CompleteQsaInfo/CompleteQsaInfo";
import ConfirmRegister from "../features/ConfirmRegister/ConfirmRegister";
import LegalPerson from "../features/LegalPerson/LegalPerson";
import LegalPersonExtra from "../features/LegalPersonExtra/LegalPersonExtra";
import LoadingData from "../features/LoadingData/LoadingData";
import Person from "../features/Person";
import FirstStep from "../features/PersonFlow/FirstStep";
import FourthStep from "../features/PersonFlow/FourthStep";
import SecondStep from "../features/PersonFlow/SecondStep";
import ThirdStep from "../features/PersonFlow/ThirdStep";
import FinishedRegister from "../features/RegisterFinished/RegisterFinished";
import AdmData from "../features/SelectPartners/SelectPartners";
import UploadFiles from "../features/UploadFiles/UploadFiles";
import Exchange from "../features/Exchange";
import ReceiveValue from '../features/Exchange/ReceiveValue';
import ReceiveApproved from '../features/Exchange/SendValue/Approved';

export default class Routes {
	static login = "/login";
	static register = "/registrar";
	static legalPerson = "/dashboard/empresas/inserir-cadastro";
	static kyc = "/dashboard/empresas/dados-extras"
	static loadingData = "/dashboard/empresas/carregando-dados";
	static legalPersonExtra = "/dashboard/empresas/dados-extras";
	static selectPartners = "/dashboard/empresas/escolher-responsaveis";
	static uploadFile = "/dashboard/empresas/upload-arquivos";
	static completePartnersData = "/dashboard/empresas/completar-dados-resp";
	static confirmRegister = "/dashboard/empresas/confirmar-registro";
	static chooseCompany = "/dashboard/empresas";
	static forgotPassword = "/login/esqueci-senha";
	static changePassword = "/login/mudar-senha";
	static dashboard = "/dashboard";
	static people = "/dashboard/pessoas";
	static firstStep = "/dashboard/pessoas/dados-cadastrais";
	static secondStep = "/dashboard/pessoas/dados-extras";
	static thirdStep = "/dashboard/pessoas/confirmar-dados";
	static fourthStep = "/dashboard/pessoas/upload-documentos";
	static finishedRegister = "/dashboard/finished";
	static sendValues = "/dashboard/cambio/enviar";
	static contactsSend = "/dashboard/envio/contatos";
	static contactSend = "/dashboard/envio/contato";
	static receiveValues = "/dashboard/cambio/receber";
	static receiveApproved = "/dashboard/cambio/receber/aprovado"
	static exchangeRouteBase = '/dashboard/pessoas/:id/cambio'
}

export const ExchangeRoutes = [
	{
		path: "/dashboard/pessoas/:id/cambio",
		component: () => Exchange,
	},
];

export const DashRoutes = [
	{
		path: "/dashboard",
		component: () => Home,
	},
	{
		path: "/dashboard/empresas",
		component: () => ChooseCompany,
	},
	{
		path: "/dashboard/empresas/inserir-cadastro",
		component: () => LegalPerson,
	},
	{
		path: "/dashboard/empresas/carregando-dados",
		component: () => LoadingData,
	},
	{
		path: "/dashboard/empresas/dados-extras",
		component: () => LegalPersonExtra,
	},
	{
		path: "/dashboard/empresas/escolher-responsaveis",
		component: () => AdmData,
	},
	{
		path: Routes.completePartnersData,
		component: () => CompleteQsaInfo,
	},
	{
		path: "/dashboard/empresas/confirmar-registro",
		component: () => ConfirmRegister,
	},
	{
		path: "/dashboard/empresas/upload-arquivos",
		component: () => UploadFiles,
	},
	{
		path: "/dashboard/pessoas",
		component: () => Person,
	},
	{
		path: "/dashboard/pessoas/dados-cadastrais",
		component: () => FirstStep,
	},
	{
		path: "/dashboard/pessoas/dados-extras",
		component: () => SecondStep,
	},
	{
		path: "/dashboard/pessoas/confirmar-dados",
		component: () => ThirdStep,
	},
	{
		path: "/dashboard/pessoas/upload-documentos",
		component: () => FourthStep,
	},
	{
		path: "/dashboard/finished",
		component: () => FinishedRegister,
	},
	{
		path: Routes.receiveValues,
		component: () => ReceiveValue,
	},
	{
		path: Routes.receiveApproved,
		component: () => ReceiveApproved
	}
];
