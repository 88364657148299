import React from "react";
import { Route, Switch } from "react-router-dom";
import { DashRoutes } from "../../routes/routes";
import Exchange from "../Exchange";


const Dashboard = () => {

    return (
        <>
            <div className="site-layout-content">
                <Switch>
                    {DashRoutes.map((item) => (
                        <Route
                            exact
                            key={item.path}
                            path={item.path}
                            component={item.component()}
                        />
                    ))}
                    <Route
                        path={[
                            "/dashboard/pessoas/:id/cambio",
                            "/dashboard/empresas/:id/cambio",
                        ]}
                    >
                        <Exchange />
                    </Route>
                </Switch>
                {/* <EfexFooter /> */}
            </div>
            {/* </Content> */}
        </>
    );
};

export default Dashboard;
