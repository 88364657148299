const actualStatus = (register_steps) => {
    if (!register_steps.completed_company_data) {
        return "Cadastrar da empresa"
    } else if (!register_steps.completed_financial_responsible) {
        return "Cadastrar resp. financeiros"
    } else if (!register_steps.accepted_review) {
        return "Revisar informações"
    } else if (!register_steps.sended_company_docs) {
        return "Enviar documentos da empresa"
    } else if (!register_steps.sended_qsa_docs) {
        return "Envair doc. dos resp. financeiros"
    } else  {
        return "Aguardar aprovação"
    }
}
export default actualStatus