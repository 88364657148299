export const mask = {
	cpf: "000.000.000-00",
	cnpj: "00.000.000/0000-00",
	phone: "(00) 0000-0000",
	cell: "(00) 00000-0000",
	cep: "00.000-000",
	date: "00/00/0000"
}

export const estimate = [
	// 	(0, "Até US$ 10.000"),
	//     (1, "De US$ 10.000 até US$ 20.000"),
	//     (2, "De US$ 20.000 até US$ 50.000"),
	//     (3, "De US$ 50.000 até US$ 100.000"),
	//     (4, "De US$ 100.000 até US$ 250.000"),
	//     (5, "De US$ 250.000 até US$ 1.500.000"),
	//     (6, "Acima de US$ 1.500.000"),
	// ]

	{ label: "Até US$ 10.000,00", value: 0 },
	{ label: "De US$ 10.000,01 até US$ 20.000,00", value: 1 },
	{ label: "De US$ 20.000,01 até US$ 50.000,00", value: 2 },
	{ label: "De US$ 50.000,01 até US$ 100.000,00", value: 3 },
	{ label: "De US$ 100.000,01 até US$ 250.000,00", value: 4 },
	{ label: "De US$ 250.000,01 até US$ 1.500.000,00", value: 5 },
	{ label: "Acima de US$ 1.500.000,01", value: 6 },
];

export const currency = [
	{ label: "USD - Dólar Americano", value: "USD" },
	{ label: "EUR - Euro", value: "EUR" },
	{ label: "AUD - Dólar Australiano", value: "AUD" },
	{ label: "CAD - Dólar Canadense", value: "CAD" },
	{ label: "GBP - Libra Esterlina", value: "GBP" },
	{ label: "JPY - Iene Japonês", value: "JPY" },
	{ label: "CHF - Franco Suiço", value: "CHF" },
];

export const comex_type_options = [
	{ label: "Antecipado/A vista (sem DUE/DUIMP)", value: "antecipado" },
	{ label: "Pronta (com DUE/DUIMP)", value: "pronta" },
];

export const comex_radar_options = [
	{ label: "Limitado", value: true },
	{ label: "Ilimitado", value: false },
];

export const comex_operation_options = [
	{ label: "Conta própria", value: "CONTA PROPRIA" },
	{ label: "Por conta e ordem de terceiros ", value: "TERCEIROS" },
];

export const options = [
	{ value: 0, label: "Comércio exterior (exportação e/ou importação de mercadorias)" },
	{ value: 1, label: "Transportes (fretes sobre exportação ou importação)" },
	{ value: 2, label: "Seguros (resseguros, prêmios, indenizações e sinistros)" },
	{ value: 3, label: "Capitais brasileiros no exterior (empréstimos e financiamentos, disponibilidades no exterior, compra e venda de imóveis no exterior, doações, investimentos, lucros e dividendos, etc)" },
	{ value: 4, label: "Capitais estrangeiros (empréstimos e financiamentos, disponibilidades no país, compra e venda de imóveis no país, doações, investimentos, lucros e dividendos, etc)" },
	{ value: 5, label: "Serviços diversos (serviços técnicos e profissionais, transações comerciais)" },
	{ value: 6, label: "Intermediação de pagamentos (prestadores de serviços e-FX, facilitadoras de pagamento internacional)" },
	{ value: 7, label: "Agências de viagens e turismo" },
];

export const purposePerson = [
	{ value: 0, label: "Comércio exterior (exportação e/ou importação de mercadorias)" },
	{ value: 2, label: "Seguros (resseguros, prêmios, indenizações e sinistros)" },
	{ value: 3, label: "Capitais brasileiros (empréstimos e financiamentos, disponibilidades no exterior, compra e venda de imóveis no exterior, doações, investimentos, lucros e dividendos, outras transferências correntes)" },
	{ value: 4, label: "Capitais estrangeiros (empréstimos e financiamentos, disponibilidades no país, compra e venda de imóveis no país, doações, investimentos, lucros e dividendos, outras transferências correntes)" },
	{ value: 8, label: "Serviços diretos (serviços técnicos e profissionais, transações comerciais)" }
]

export const number_of_workers = [
	{ label: "Até 9", value: "9" },
	{ label: "De 9 a 20", value: "9-20" },
	{ label: "De 20 a 50", value: "20-50" },
	{ label: "De 50 a 100", value: "50-100" },
	{ label: "De 100 a 500", value: "100-500" },
	{ label: "Acima de 500", value: "500+" },
];

export const required_items = [
	"- dados cadastrais",
	"- informações da rotina operacional",
	"- documento de identidade e comprovante de endereço da pessoa que é responsável legal",
	"- documentos financeiros atualizados",
	"- última versão do documento registrado na junta comercial",
	"- indicação de todas as pessoas que são beneficiárias finais",
];

export const minimum_wage = [
	{ label: "Até 1 mil reais", value: "2S" },
	{ label: "De 2 a 4 mil reais", value: "2S-4S" },
	{ label: "De 4 a 10 mil reais", value: "4S-10S" },
	{ label: "De 10 a 20 mil reais", value: "10S-20S" },
	{ label: "Acima de 20 mil reais", value: "20S+" },
];

export const options_nat = [
	{ label: "Brasileiro", value: "brasileiro" },
	{ label: "Estrangeiro", value: "estrangeiro" },
];

export const qualifications_options = [
	{ label: "Responsável Legal", value: "resp-legal" },
	{ label: "Procurador", value: "procurador" },
];


export const BanksToReceive = ({ currency, user }) => {

	if (currency === "USD") {
		return (
			<div>
				<p><strong>Field 57 - Beneficiary bank:</strong> BANCO BONSUCESSO S.A</p>
				<p><strong>Field 57 - SWIFT code:</strong> BBONBRSP</p>
				<p><strong>Field 57 - Account:</strong> 8901306894</p>
				<p><strong>Field 59 - IBAN:</strong> {user.iban_code}</p>
				<p><strong>Field 59 - Beneficiary:</strong> {user.company_name || user.name}</p>
				<p><strong>Field 59 - Addess:</strong> AV RAJA GABAGLIA 1143 – 15 FL 30380-403, BH MG</p>
				<hr style={{ width: "100%" }} />
				<p><strong>Field 56 - Intermediary bank:</strong> THE BANK OF NEW YORK MELLON</p>
				<p><strong>Field 56 - SWIFT code:</strong> IRVTUS3N</p>
				<p><strong>Field 56 - ABA:</strong> 0210-0001-8</p>
				<p><strong>Field 56 - Address:</strong> 225 LIBERTY STREET NEW YORK, NY 10281</p>
			</div>
		)
	} else if (currency === "EUR") {
		return (
			<div>
				<p><strong>Field 57 - Beneficiary bank:</strong> BANCO BONSUCESSO SA</p>
				<p><strong>Field 57 - SWIFT code:</strong> BBONBRSP</p>
				<p><strong>Field 57 - Account:</strong> 0049 5494 80 2310455559</p>
				<p><strong>Field 59 - IBAN:</strong> {user.iban_code}</p>
				<p><strong>Field 59 - Beneficiary:</strong> {user.company_name || user.name}</p>
				<p><strong>Field 59 - Addess:</strong> AV RAJA GABAGLIA 1143 – 15 FL 30380-403 – BH - MG</p>
				<hr style={{ width: "100%" }} />
				<p><strong>Field 56 - Intermediary bank:</strong> BANCO SANTANDER</p>
				<p><strong>Field 56 - SWIFT code:</strong> BSCHESMM</p>
				<p><strong>Field 56 - Address:</strong> SPAIN</p>
			</div>
		)
	} else if (currency === "GBP") {
		return (
			<div>
				<p><strong>Field 57 - Beneficiary bank:</strong> BANCO BONSUCESSO SA</p>
				<p><strong>Field 57 - SWIFT code:</strong> BBONBRSP</p>
				<p><strong>Field 57 - Account:</strong> GB21IRVT70022579491160</p>
				<p><strong>Field 59 - IBAN:</strong> {user.iban_code}</p>
				<p><strong>Field 59 - Beneficiary:</strong> {user.company_name || user.name}</p>
				<p><strong>Field 59 - Addess:</strong> AV RAJA GABAGLIA 1143 – 15 FL 30380-403 – BH - MG</p>
				<hr style={{ width: "100%" }} />
				<p><strong>Field 56 - Intermediary bank:</strong> BNY Mellon London Branch</p>
				<p><strong>Field 56 - SWIFT code:</strong> IRVTGB2X</p>
				<p><strong>Field 56 - Address:</strong> One Canada Square Canary Wharf, London E14 5AL - United Kingdom</p>
			</div>
		)
	} else if (currency === "CHF") {
		return (
			<div>
				<p><strong>Field 57 - Beneficiary bank:</strong> BANCO BONSUCESSO SA</p>
				<p><strong>Field 57 - SWIFT code:</strong> BBONBRSP</p>
				<p><strong>Field 57 - Account:</strong> PT50000700000032104148323</p>
				<p><strong>Field 59 - IBAN:</strong> {user.iban_code}</p>
				<p><strong>Field 59 - Beneficiary:</strong> {user.company_name || user.name}</p>
				<p><strong>Field 59 - Addess:</strong> AV RAJA GABAGLIA 1143 – 15 FL 30380-403 – BH - MG</p>
				<hr style={{ width: "100%" }} />
				<p><strong>Field 56 - Intermediary bank:</strong> NOVO BANCO, SA</p>
				<p><strong>Field 56 - SWIFT code:</strong> BESCPTPL</p>
				<p><strong>Field 56 - Address:</strong> LISBOA – PORTUGAL</p>
			</div>
		)
	} else if (currency === "AUD") {
		return (
			<div>
				<p><strong>Field 57 - Beneficiary bank:</strong> BANCO BS2 SA</p>
				<p><strong>Field 57 - SWIFT code:</strong> BBONBRSP</p>
				<p><strong>Field 59 - IBAN:</strong> {user.iban_code}</p>
				<p><strong>Field 59 - Beneficiary:</strong> {user.company_name || user.name}</p>
				<p><strong>Field 59 - Address:</strong> AV RAJA GABAGLIA 1143 – 15 FL 30380-403 – BH - MG</p>
				<hr style={{ width: "100%" }} />
				<p><strong>Field 56 - Intermediary bank:</strong> Commerzbank AG</p>
				<p><strong>Field 56 - SWIFT code:</strong> COBADEFFXXX</p>
				<p><strong>Field 56 - Address:</strong> ADDRESS – FRANKFURT – DE</p>
			</div>
		)
	} else if (currency === "CAD") {
		return (
			<div>
				<p><strong>Field 57 - Beneficiary bank:</strong> BANCO BS2 SA</p>
				<p><strong>Field 57 - SWIFT code:</strong> BBONBRSP</p>
				<p><strong>Field 59 - IBAN:</strong> {user.iban_code}</p>
				<p><strong>Field 59 - Beneficiary:</strong> {user.company_name || user.name}</p>
				<p><strong>Field 59 - Address:</strong> AV RAJA GABAGLIA 1143 – 15 FL 30380-403 – BH - MG</p>
				<hr style={{ width: "100%" }} />
				<p><strong>Field 56 - Intermediary bank:</strong> Commerzbank AG</p>
				<p><strong>Field 56 - SWIFT code:</strong> COBADEFFXXX</p>
				<p><strong>Field 56 - Address:</strong> ADDRESS – FRANKFURT – DE</p>
			</div>
		)
	} else if (currency === "JPY") {
		return (
			<div>
				<p><strong>Field 57 - Beneficiary bank:</strong> BANCO BS2 SA</p>
				<p><strong>Field 57 - SWIFT code:</strong> BBONBRSP</p>
				<p><strong>Field 59 - IBAN:</strong> {user.iban_code}</p>
				<p><strong>Field 59 - Beneficiary:</strong> {user.company_name || user.name}</p>
				<p><strong>Field 59 - Addess:</strong> AV RAJA GABAGLIA 1143 – 15 FL 30380-403 – BH - MG </p>
				<hr style={{ width: "100%" }} />
				<p><strong>Field 56 - Intermediary bank:</strong> Commerzbank AG</p>
				<p><strong>Field 56 - SWIFT code:</strong> COBADEFFXXX</p>
				<p><strong>Field 56 - Address:</strong> ADDRESS – FRANKFURT – DE</p>
			</div>
		)
	}

}

export const exchangeReceiveAccounts = [
	{
		id: 0,
		currency: "USD - Dólar americano",
		bank_name: "THE BANK OF NEW YORK MELLON",
		swift: "IRVTUS3N",
		holder: "Banco Bonsucesso S.A",
		number_account: "8901306894",
		address: "225 LIBERTY STREET NEW YORK, NY 10281",
	},
	{
		id: 1,
		currency: "EUR - Euro",
		bank_name: "BANCO SANTANDER",
		swift: "BSCHESMM",
		holder: "Banco Bonsucesso S.A",
		number_account: "0049 5494 80 2310455559",
		address: "SPAIN",
	},
	{
		id: 2,
		currency: "GBP - Libra esterlina",
		bank_name: "BNY Mellon London Branch",
		swift: "IRVTGB2X",
		holder: "Banco Bonsucesso S.A",
		number_account: "GB21IRVT70022579491160",
		address:
			"One Canada Square Canary Wharf, London E14 5AL - United Kingdom",
	},
	{
		id: 3,
		currency: "CHF - Franco suiço",
		bank_name: "NOVO BANCO, SA",
		swift: "BESCPTPL",
		holder: "Banco Bonsucesso S.A",
		number_account: "PT50000700000032104148323",
		address: "AVENIDA DA LIBERDADE 195 - LISBOA – PORTUGAL – ZIP 1250 ",
	},
	{
		id: 4,
		currency: "AUD - Dólar australiano",
		bank_name: "Commerzbank AG",
		swift: "COBADEFFXXX",
		holder: "Banco Bonsucesso S.A",
		number_account: "",
		address: "ADDRESS – FRANKFURT – DE ",
	},
	{
		id: 5,
		currency: "CAD - Dólar canadence",
		bank_name: "Commerzbank AG",
		swift: "COBADEFFXXX",
		holder: "Banco Bonsucesso S.A",
		number_account: "400871803300CAD",
		address: "ADDRESS – FRANKFURT – DE",
	},
	{
		id: 6,
		currency: "JPY - Iene japonês",
		bank_name: "Commerzbank AG",
		swift: "COBADEFFXXX",
		holder: "Banco Bonsucesso S.A",
		number_account: "",
		address: "ADDRESS – FRANKFURT – DE",
	},
];

export const fullCurrency = [
	{ label: "USD - Dólar americano", value: "USD" },
	{ label: "EUR - Euro", value: "EUR" },
	{ label: "AUD - Dólar australiano", value: "AUD" },
	{ label: "CAD - Dólar canadence", value: "CAD" },
	{ label: "GBP - Libra esterlina", value: "GBP" },
	{ label: "JPY - Iene japonês", value: "JPY" },
	{ label: "CHF - Franco suiço", value: "CHF" },
];

export const receiveNatures = [
	{
		name: "services",
		label: "Recebimento de serviço",
		fields: ["is_external_service"],
		taxes: { IOF: 38 },
		requireFiles: true,
	},
	{
		name: "commercial",
		label: "Exportação de Mercadoria",
		fields: ["has_advance_payment"],
		taxes: { IOF: 0 },
		requireFiles: true,
	},
	{
		name: "finance",
		label: "Empréstimo",
		fields: [],
		taxes: { IOF: 38 },
		requireFiles: true,
	},
	{
		name: "capital",
		label: "Aumento/Redução de Capital",
		fields: [],
		taxes: { IOF: 38 },
		requireFiles: true,
	},
	{
		name: "deposit",
		label: "Transf. entre contas de mesma titularidade",
		fields: [],
		taxes: { IOF: 38 },
		requireFiles: false,
	},
	{
		name: "other",
		label: "Outro",
		fields: [],
		taxes: { IOF: 38 },
		requireFiles: true,
	},
];

export const sendValueNatures = [
	{
		name: "services",
		label: "Pagamento de serviço",
		fields: ["has_payment_relationship", "is_tax_by_recipient", "has_capital_gain"],
		taxes: { IOF: 38, IR: 15000000 },
		requireFiles: true,
	},
	{
		name: "commercial",
		label: "Importação de mercadoria",
		fields: ["has_advance_payment"],
		taxes: { IOF: 0, IR: 0 },
		requireFiles: true,
	},
	{
		name: "finance",
		label: "Empréstimo",
		fields: ["is_tax_by_recipient"],
		taxes: { IOF: 38, IR: 15000000 },
		requireFiles: true,
	},
	{
		name: "capital",
		label: "Aumento/Redução de Capital",
		fields: [],
		taxes: { IOF: 38, IR: 15000000 },
		requireFiles: true,
	},
	{
		name: "deposit",
		label: "Transf. entre contas de mesma titularidade",
		fields: [],
		taxes: { IOF: 110, IR: 0 },
		requireFiles: false,
	},
	{
		name: "other",
		label: "Outro",
		fields: ["is_tax_by_recipient"],
		taxes: { IOF: 38, IR: 15000000 },
		requireFiles: true,
	},
]

export const sendValueAndReceiveValueStatus = [
	{ value: null, label: "Todos status" },
	{ value: "draft", label: "Rascunho" },
	{ value: "waiting_approval", label: "Aguardando Aprovação" },
	{ value: "need_changes", label: "Aguardando Alterações" },
	{ value: "approved", label: "Aprovada" },
	{ value: "scheduled", label: "Operação agendada" },
	{ value: "waiting_contract", label: "Aguardando envio do contrato" },
	{ value: "docs_approved", label: "Documentação aprovada" },
	{ value: "waiting_exchange", label: "Aguardando câmbio" },
	{ value: "making_exchange", label: "Realizando câmbio" },
	{ value: "waiting_sign_doc", label: "Aguardando assinatura de contrato" },
	{ value: "contract_signed", label: "Contrato assinado" },
	{ value: "waiting_deposit_receipt", label: "Aguardando comprovante de depósito" },
	{ value: "completed", label: "Operação completada" },
	{ value: "finished", label: "Operação finalizada" },
	{ value: "created_by_bo", label: "Operação criada pelo BO" },
	{ value: "deleted", label: "Excluída" }
]

export const sendValueStatus = [
	{ value: null, label: "Todos status" },
	{ value: "draft", label: "Rascunho" },
	{ value: "waiting_approval", label: "Aguardando Aprovação" },
	{ value: "need_changes", label: "Aguardando Alterações" },
	{ value: "approved", label: "Aprovada" },
	{ value: "waiting_contract", label: "Aguardando envio do contrato" },
	{ value: "waiting_exchange", label: "Aguardando câmbio" },
	{ value: "making_exchange", label: "Realizando câmbio" },
	{ value: "waiting_sign_doc", label: "Aguardando assinatura de contrato" },
	{ value: "contract_signed", label: "Contrato assinado" },
	{ value: "waiting_deposit_receipt", label: "Aguardando comprovante de depósito" },
	{ value: "completed", label: "Operação completada" },
	{ value: "finished", label: "Operação finalizada" },
	{ value: "created_by_bo", label: "Operação criada pelo BO" },
	{ value: "deleted", label: "Excluída" }
]

export const receiveValueStatus = [
	{ value: null, label: "Todos status" },
	{ value: "draft", label: "Rascunho" },
	{ value: "waiting_approval", label: "Aguardando Aprovação" },
	{ value: "need_changes", label: "Aguardando Alterações" },
	{ value: "docs_approved", label: "Documentação aprovada" },
	{ value: "approved", label: "Aprovada" },
	{ value: "scheduled", label: "Operação agendada" },
	{ value: "waiting_exchange", label: "Aguardando câmbio" },
	{ value: "making_exchange", label: "Realizando câmbio" },
	{ value: "waiting_contract", label: "Aguardando envio do contrato" },
	{ value: "waiting_sign_doc", label: "Aguardando assinatura de contrato" },
	{ value: "contract_signed", label: "Contrato assinado" },
	{ value: "completed", label: "Operação completada" },
	{ value: "finished", label: "Operação finalizada" },
	{ value: "created_by_bo", label: "Operação criada pelo BO" },
	{ value: "deleted", label: "Excluída" }
]

export const repeatTransactionStatus = [
	"approved",
	"waiting_contract",
	"waiting_exchange",
	"making_exchange",
	"waiting_sign_doc",
	"contract_signed",
	"waiting_deposit_receipt",
	"completed",
	"finished",
]