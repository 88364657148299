import React from 'react'
import './style.css'
const IdentedField = ({children}) => {
    return (
        <div className="idented-box">
            {children}
        </div>
    )
}

export default IdentedField