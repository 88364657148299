import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Input, Select, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons"
import StateStatus from "../../../../utils/status";
import {
	editBankAccount,
	saveBankAccount,
	selectContactSend,
	getContactSend,
} from "../../Contacts/ContactsSend/slice";
import { fullCurrency } from "../../../../utils/consts";
import { useState } from "react";
import ErrorMsg from "../../../../common/components/ErrorMsg";
import IdentedField from "../../../CompleteQsaInfo/components/IdentedField";
import EfexModal from "../../../../common/components/Modal";
import { useRouteMatch } from "react-router-dom";
import "./styles.scss";
import TooltipEfex from "../../../../common/components/Tooltip";


const BankAccountSendModal = ({ visible, data, onOk, onCancel }) => {
	const { confirm } = Modal

	const [form] = Form.useForm();
	const [account, setAccount] = useState({});
	const [hasIban, setHasIban] = useState(true);
	const [hasIntermediaryBank, setHasIntermediaryBank] = useState(false);
	const contactState = useSelector(selectContactSend);
	const { path } = useRouteMatch();

	const dispatch = useDispatch();

	useEffect(() => {
		if (data === null || data === undefined) {
			form.resetFields();
		} else {
			setAccount(data);
			setHasIntermediaryBank(data.has_intermediary_bank)
			form.setFieldsValue(account);
		}
	}, [data, form, account]);

	const onValuesChange = (values, allValues) => {
		if (values.iban_code !== undefined || values.account_number !== undefined) {
			form.setFields([
				{ name: "iban_code", errors: [] },
				{ name: "account_number", errors: [] },
			])
			if (allValues.iban_code !== "") {
				setHasIban(true);
			} else {
				setHasIban(false);
			}
		}

		if (values.has_intermediary_bank !== undefined) {
			setHasIntermediaryBank(Boolean(values.has_intermediary_bank));
		}
	};

	const showConfirm = () => {
		confirm({
			okText:'Sim',
			cancelText:'Quero revisar',
			title: 'Atenção!',
			icon: <ExclamationCircleFilled />,
			content: 'As informações bancárias e de identificação incluídas são de sua responsabilidade. Você confirma que os dados estão corretos?',
			onOk() {
				form.validateFields()
					.then((values) => {
						values.contact_id = contactState.contactSend.id;
						if (values.has_intermediary_bank) {
							values.intermediary_bank = {
								intermediary_bank_name: values.intermediary_bank_name,
								intermediary_bank_address: values.intermediary_bank_address,
								intermediary_bank_swift_code: values.intermediary_bank_swift_code,
								intermediary_account_number: values.intermediary_account_number,
							}
						}
						if (account.id) {
							dispatch(
								editBankAccount({
									bankId: account.id,
									data: values,
								}),
							).then(({ payload }) => {
								if (payload !== undefined) {
									onOk(payload.data);
									dispatch(
										getContactSend(
											contactState.contactSend.id,
										),
									);
								}
							});
						} else {
							dispatch(saveBankAccount(values)).then(
								({ payload }) => {
									if (payload !== undefined) {
										onOk(payload.data);
										dispatch(
											getContactSend(
												contactState.contactSend.id,
											),
										);
										form.resetFields();
									}
								},
							);
						}
					})
					.catch((info) => {
					});
			},
			onCancel() {
				
			},
		});
	};

	return (
		<EfexModal
			className={path.match("empresas") ? "modal-company" : "modal-person"}
			title={
				<>
					{(account.id ? "Editar" : "Nova") + " conta bancária"}
					<TooltipEfex title="Lembre-se de checar com seu contato no exterior os dados corretos, para garantir que os recursos cheguem corretamente, ok?"/>
				</>
			}
			visible={visible}	
			okText="Salvar"
			confirmLoading={
				contactState.status.saveBankAccount === StateStatus.loading ||
				contactState.status.editBankAccount === StateStatus.loading
			}
			onCancel={() => {
				form.resetFields()
				onCancel()
			}}
			onOk={() => showConfirm()}
		>
			<p className="subtitle">Agora, inclua as informações bancárias de quem vai receber os recursos.</p>
			<Form
				aria-autocomplete="none"
				form={form}
				layout="vertical"
				onValuesChange={onValuesChange}
			>
				{contactState.status.saveBankAccount === StateStatus.failed ||
					contactState.status.editBankAccount === StateStatus.failed ? (
					<ErrorMsg message={contactState.errorMsg}></ErrorMsg>
				) : null}

				<Row gutter={[8, 0]}>
					<Col span={24}>
						<Form.Item
							label={
								<>
								IBAN
								<TooltipEfex title="IBAN é a sigla para International Bank Account Number. 
								Nem sempre haverá esse código no canal bancário, mas ele pode ser solicitado ao banco 
								do beneficiário. Exemplo do formato: PT50002700000001234567833" />
								</>
							}
							name={"iban_code"}
							rules={[
								{
									min: 8,
									// max: 11,
									message: "IBAN inválido.",
								},
							]}
						>
							<Input
								placeholder="Número IBAN"
								type="text"
							></Input>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label={
								<>
								SWIFT/BIC
								<TooltipEfex title="O código SWIFT, também conhecido como BIC, 
								é um código universal único de um banco que pode ter de 8 a 11 caracteres. 
								Esse código é obrigatório para transferências e pode ser solicitado ao banco do beneficiário. 
								Exemplo do formato: ABCD AB XX XXX" />
								</>
							}
							name={"swift_code"}
							rules={[
								{
									required: true,
									message: "Campo obrigatório.",
								},
								{
									min: 8,
									max: 11,
									message: "SWIFT inválido.",
								},
							]}
						>
							<Input
								placeholder="Código SWIFT"
								type="text"
							></Input>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[8, 0]}>
					<Col span={24}>
						<Form.Item
							label={
								<>
								ABA
								<TooltipEfex title="O Código ABA possui o mesmo propósito do código SWIFT/BIC, 
								com a diferença que é válido apenas para identificar os bancos dos Estados Unidos. 
								Assim como os demais códigos, ele também pode ser solicitado ao banco do beneficiário. 
								Exemplo do formato: AAAA BBBB C"/>
								</>
							}
							name={"aba_bic_code"}
							rules={[
								{
									min: 8,
									max: 11,
									message: "ABA/BIC inválido.",
								},
							]}
						>
							<Input
								placeholder="Código ABA/BIC"
								type="text"
							></Input>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label="Banco"
							name={"bank_name"}
							rules={[
								{
									required: true,
									message: "Campo obrigatório",
								},
							]}
						>
							<Input
								placeholder="Nome do banco"
								type="text"
							></Input>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[8, 0]}>
					<Col span={12}>
						<Form.Item
							label="Agência"
							name={"agency_number"}
							rules={[
								{
									required: false,
									message: "Campo obrigatório",
								},
							]}
						>
							<Input placeholder="Agência" type="text"></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Número da conta"
							name={"account_number"}
							rules={[
								{
									required: true,
									message: "Campo obrigatório.",
								},
							]}
						>
							<Input
								placeholder="Número da conta"
								type="text"
							></Input>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[8, 0]}>
					<Col span={24}>
						<Form.Item
							label={
								<>
								Moeda
								<TooltipEfex title="Agora, selecione a moeda da qual deseja realizar a remessa de envio
								para a conta cadastrada. Vale ressaltar que ao utilizar esse canal bancário nas próximas
								remessas, será considerada a mesma moeda." />
								</>
							}
							name="currency"
							rules={[
								{
									required: true,
									message: "Seleção Obrigatória.",
								},
							]}
						>
							<Select options={fullCurrency} />
						</Form.Item>
						<Form.Item label="Endereço" name={"bank_address"}>
							<Input
								placeholder="Endereço do banco"
								type="text"
							></Input>
						</Form.Item>
						<Form.Item
							label={
								<>
								É necessário incluir banco intermediário?
								<TooltipEfex title="Algumas instituições no exterior precisam de um banco intermediário 
								para receber recursos do Brasil. Se for o caso, basta selecionar “sim” abaixo, 
								que você poderá incluir estas informações." />
								</>
							}
							name="has_intermediary_bank"
							rules={[
								{
									required: true,
									message: "Seleção Obrigatória.",
								},
							]}
						>
							<Select
								options={[
									{ label: "Sim", value: true },
									{ label: "Não", value: false },
								]}
							/>
						</Form.Item>
						{hasIntermediaryBank ? (
							<IdentedField>
								<Form.Item
									label="Banco intermediário"
									name={"intermediary_bank_name"}
									rules={[
										{
											required: hasIntermediaryBank,
											message: "Campo obrigatório",
										},
									]}
								>
									<Input
										placeholder="Nome do banco intermediário"
										type="text"
									></Input>
								</Form.Item>
								<Form.Item
									label="Endereço"
									name={"intermediary_bank_address"}
									rules={[
										{
											required: hasIntermediaryBank,
											message: "Campo obrigatório",
										},
									]}
								>
									<Input
										placeholder="Endereço"
										type="text"
									></Input>
								</Form.Item>
								<Form.Item
									label="SWIFT"
									name={"intermediary_bank_swift_code"}
									rules={[
										{
											required: hasIntermediaryBank,
											message: "Campo obrigatório.",
										},
										{
											min: 8,
											max: 11,
											message: "SWIFT inválido.",
										},
									]}
								>
									<Input
										placeholder="Código SWIFT"
										type="text"
									></Input>
								</Form.Item>
								<Form.Item
									label="Número da conta"
									name={"intermediary_account_number"}
									rules={[
										{
											required: hasIntermediaryBank,
											message: "Campo obrigatório.",
										},
									]}
								>
									<Input
										placeholder="Número da conta"
										type="text"
									></Input>
								</Form.Item>
							</IdentedField>
						) : null}
						<Form.Item name="comments" initialValue="" label="Observações">
                            <Input.TextArea placeholder="Caso alguma informação esteja inconsistente, informe neste campo" />
                        </Form.Item>
						<p className="subtitle">Ainda com dúvidas? Fale com a gente pelo chat.</p>
					</Col>
				</Row>
			</Form>
		</EfexModal>
	);
};

export default BankAccountSendModal;
