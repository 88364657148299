export const rfQualification = [
    { label: "Procurador", value: 'procurador' },
    { label: "Responsável Legal", value: 'resp-legal' },
    { label: "Sócio", value: 'socio' }
];

export const BankList = [
    {
        "key": "001 | BANCO DO BRASIL S.A.",
        "value": "001 | BANCO DO BRASIL S.A."
    },
    {
        "key": "003 | BANCO DA AMAZONIA S.A.",
        "value": "003 | BANCO DA AMAZONIA S.A."
    },
    {
        "key": "004 | BANCO DO NORDESTE DO BRASIL S.A.",
        "value": "004 | BANCO DO NORDESTE DO BRASIL S.A."
    },
    {
        "key": "007 | BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL",
        "value": "007 | BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL"
    },
    {
        "key": "010 | CREDICOAMO CREDITO RURAL COOPERATIVA",
        "value": "010 | CREDICOAMO CREDITO RURAL COOPERATIVA"
    },
    {
        "key": "011 | CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A",
        "value": "011 | CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A"
    },
    {
        "key": "012 | BANCO INBURSA S.A.",
        "value": "012 | BANCO INBURSA S.A."
    },
    {
        "key": "014 | STATE STREET BRASIL S.A. - BANCO COMERCIAL",
        "value": "014 | STATE STREET BRASIL S.A. - BANCO COMERCIAL"
    },
    {
        "key": "015 | UBS BRASIL CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
        "value": "015 | UBS BRASIL CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "016 | COOPERATIVA DE CR\u00c9DITO M\u00daTUO DOS DESPACHANTES DE TR\u00c2NSITO DE SANTA CATARINA E RI",
        "value": "016 | COOPERATIVA DE CR\u00c9DITO M\u00daTUO DOS DESPACHANTES DE TR\u00c2NSITO DE SANTA CATARINA E RI"
    },
    {
        "key": "017 | BNY MELLON BANCO S.A.",
        "value": "017 | BNY MELLON BANCO S.A."
    },
    {
        "key": "018 | BANCO TRICURY S.A.",
        "value": "018 | BANCO TRICURY S.A."
    },
    {
        "key": "021 | BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO",
        "value": "021 | BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO"
    },
    {
        "key": "024 | BANCO BANDEPE S.A.",
        "value": "024 | BANCO BANDEPE S.A."
    },
    {
        "key": "025 | BANCO ALFA S.A.",
        "value": "025 | BANCO ALFA S.A."
    },
    {
        "key": "029 | BANCO ITA\u00da CONSIGNADO S.A.",
        "value": "029 | BANCO ITA\u00da CONSIGNADO S.A."
    },
    {
        "key": "033 | BANCO SANTANDER (BRASIL) S.A.",
        "value": "033 | BANCO SANTANDER (BRASIL) S.A."
    },
    {
        "key": "036 | BANCO BRADESCO BBI S.A.",
        "value": "036 | BANCO BRADESCO BBI S.A."
    },
    {
        "key": "037 | BANCO DO ESTADO DO PAR\u00c1 S.A.",
        "value": "037 | BANCO DO ESTADO DO PAR\u00c1 S.A."
    },
    {
        "key": "040 | BANCO CARGILL S.A.",
        "value": "040 | BANCO CARGILL S.A."
    },
    {
        "key": "041 | BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.",
        "value": "041 | BANCO DO ESTADO DO RIO GRANDE DO SUL S.A."
    },
    {
        "key": "047 | BANCO DO ESTADO DE SERGIPE S.A.",
        "value": "047 | BANCO DO ESTADO DE SERGIPE S.A."
    },
    {
        "key": "060 | CONFIDENCE CORRETORA DE C\u00c2MBIO S.A.",
        "value": "060 | CONFIDENCE CORRETORA DE C\u00c2MBIO S.A."
    },
    {
        "key": "062 | HIPERCARD BANCO M\u00daLTIPLO S.A.",
        "value": "062 | HIPERCARD BANCO M\u00daLTIPLO S.A."
    },
    {
        "key": "063 | BANCO BRADESCARD S.A.",
        "value": "063 | BANCO BRADESCARD S.A."
    },
    {
        "key": "064 | GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.",
        "value": "064 | GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A."
    },
    {
        "key": "065 | BANCO ANDBANK (BRASIL) S.A.",
        "value": "065 | BANCO ANDBANK (BRASIL) S.A."
    },
    {
        "key": "066 | BANCO MORGAN STANLEY S.A.",
        "value": "066 | BANCO MORGAN STANLEY S.A."
    },
    {
        "key": "069 | BANCO CREFISA S.A.",
        "value": "069 | BANCO CREFISA S.A."
    },
    {
        "key": "070 | BRB - BANCO DE BRASILIA S.A.",
        "value": "070 | BRB - BANCO DE BRASILIA S.A."
    },
    {
        "key": "074 | BANCO J. SAFRA S.A.",
        "value": "074 | BANCO J. SAFRA S.A."
    },
    {
        "key": "075 | BANCO ABN AMRO S.A.",
        "value": "075 | BANCO ABN AMRO S.A."
    },
    {
        "key": "076 | BANCO KDB DO BRASIL S.A.",
        "value": "076 | BANCO KDB DO BRASIL S.A."
    },
    {
        "key": "077 | BANCO INTER S.A.",
        "value": "077 | BANCO INTER S.A."
    },
    {
        "key": "078 | HAITONG BANCO DE INVESTIMENTO DO BRASIL S.A.",
        "value": "078 | HAITONG BANCO DE INVESTIMENTO DO BRASIL S.A."
    },
    {
        "key": "079 | PICPAY BANK - BANCO M\u00daLTIPLO S.A",
        "value": "079 | PICPAY BANK - BANCO M\u00daLTIPLO S.A"
    },
    {
        "key": "080 | B&T CORRETORA DE CAMBIO LTDA.",
        "value": "080 | B&T CORRETORA DE CAMBIO LTDA."
    },
    {
        "key": "081 | BANCOSEGURO S.A.",
        "value": "081 | BANCOSEGURO S.A."
    },
    {
        "key": "082 | BANCO TOP\u00c1ZIO S.A.",
        "value": "082 | BANCO TOP\u00c1ZIO S.A."
    },
    {
        "key": "083 | BANCO DA CHINA BRASIL S.A.",
        "value": "083 | BANCO DA CHINA BRASIL S.A."
    },
    {
        "key": "084 | UNIPRIME DO BRASIL - COOPERATIVA DE CR\u00c9DITO",
        "value": "084 | UNIPRIME DO BRASIL - COOPERATIVA DE CR\u00c9DITO"
    },
    {
        "key": "085 | COOPERATIVA CENTRAL DE CR\u00c9DITO - AILOS",
        "value": "085 | COOPERATIVA CENTRAL DE CR\u00c9DITO - AILOS"
    },
    {
        "key": "088 | BANCO RANDON S.A.",
        "value": "088 | BANCO RANDON S.A."
    },
    {
        "key": "089 | CREDISAN COOPERATIVA DE CR\u00c9DITO",
        "value": "089 | CREDISAN COOPERATIVA DE CR\u00c9DITO"
    },
    {
        "key": "092 | BRK S.A. CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
        "value": "092 | BRK S.A. CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
    },
    {
        "key": "093 | P\u00d3LOCRED SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORT",
        "value": "093 | P\u00d3LOCRED SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORT"
    },
    {
        "key": "094 | BANCO FINAXIS S.A.",
        "value": "094 | BANCO FINAXIS S.A."
    },
    {
        "key": "095 | TRAVELEX BANCO DE C\u00c2MBIO S.A.",
        "value": "095 | TRAVELEX BANCO DE C\u00c2MBIO S.A."
    },
    {
        "key": "096 | BANCO B3 S.A.",
        "value": "096 | BANCO B3 S.A."
    },
    {
        "key": "097 | CREDISIS - CENTRAL DE COOPERATIVAS DE CR\u00c9DITO LTDA.",
        "value": "097 | CREDISIS - CENTRAL DE COOPERATIVAS DE CR\u00c9DITO LTDA."
    },
    {
        "key": "098 | CREDIALIAN\u00c7A COOPERATIVA DE CR\u00c9DITO RURAL",
        "value": "098 | CREDIALIAN\u00c7A COOPERATIVA DE CR\u00c9DITO RURAL"
    },
    {
        "key": "099 | UNIPRIME CENTRAL - CENTRAL NACIONAL DE COOPERATIVA DE CR\u00c9DITO LTDA.",
        "value": "099 | UNIPRIME CENTRAL - CENTRAL NACIONAL DE COOPERATIVA DE CR\u00c9DITO LTDA."
    },
    {
        "key": "100 | PLANNER CORRETORA DE VALORES S.A.",
        "value": "100 | PLANNER CORRETORA DE VALORES S.A."
    },
    {
        "key": "101 | RENASCENCA DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA",
        "value": "101 | RENASCENCA DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA"
    },
    {
        "key": "102 | XP INVESTIMENTOS CORRETORA DE C\u00c2MBIO,T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S/A",
        "value": "102 | XP INVESTIMENTOS CORRETORA DE C\u00c2MBIO,T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S/A"
    },
    {
        "key": "104 | CAIXA ECONOMICA FEDERAL",
        "value": "104 | CAIXA ECONOMICA FEDERAL"
    },
    {
        "key": "105 | LECCA CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S/A",
        "value": "105 | LECCA CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S/A"
    },
    {
        "key": "107 | BANCO BOCOM BBM S.A.",
        "value": "107 | BANCO BOCOM BBM S.A."
    },
    {
        "key": "108 | PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO",
        "value": "108 | PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO"
    },
    {
        "key": "111 | OLIVEIRA TRUST DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILIARIOS S.A.",
        "value": "111 | OLIVEIRA TRUST DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILIARIOS S.A."
    },
    {
        "key": "113 | NEON CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
        "value": "113 | NEON CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "114 | CENTRAL COOPERATIVA DE CR\u00c9DITO NO ESTADO DO ESP\u00cdRITO SANTO - CECOOP",
        "value": "114 | CENTRAL COOPERATIVA DE CR\u00c9DITO NO ESTADO DO ESP\u00cdRITO SANTO - CECOOP"
    },
    {
        "key": "117 | ADVANCED CORRETORA DE C\u00c2MBIO LTDA",
        "value": "117 | ADVANCED CORRETORA DE C\u00c2MBIO LTDA"
    },
    {
        "key": "119 | BANCO WESTERN UNION DO BRASIL S.A.",
        "value": "119 | BANCO WESTERN UNION DO BRASIL S.A."
    },
    {
        "key": "120 | BANCO RODOBENS S.A.",
        "value": "120 | BANCO RODOBENS S.A."
    },
    {
        "key": "121 | BANCO AGIBANK S.A.",
        "value": "121 | BANCO AGIBANK S.A."
    },
    {
        "key": "122 | BANCO BRADESCO BERJ S.A.",
        "value": "122 | BANCO BRADESCO BERJ S.A."
    },
    {
        "key": "124 | BANCO WOORI BANK DO BRASIL S.A.",
        "value": "124 | BANCO WOORI BANK DO BRASIL S.A."
    },
    {
        "key": "125 | BANCO GENIAL S.A.",
        "value": "125 | BANCO GENIAL S.A."
    },
    {
        "key": "126 | BR PARTNERS BANCO DE INVESTIMENTO S.A.",
        "value": "126 | BR PARTNERS BANCO DE INVESTIMENTO S.A."
    },
    {
        "key": "127 | CODEPE CORRETORA DE VALORES E C\u00c2MBIO S.A.",
        "value": "127 | CODEPE CORRETORA DE VALORES E C\u00c2MBIO S.A."
    },
    {
        "key": "128 | MS BANK S.A. BANCO DE C\u00c2MBIO",
        "value": "128 | MS BANK S.A. BANCO DE C\u00c2MBIO"
    },
    {
        "key": "129 | UBS BRASIL BANCO DE INVESTIMENTO S.A.",
        "value": "129 | UBS BRASIL BANCO DE INVESTIMENTO S.A."
    },
    {
        "key": "130 | CARUANA S.A. - SOCIEDADE DE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
        "value": "130 | CARUANA S.A. - SOCIEDADE DE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
    },
    {
        "key": "131 | TULLETT PREBON BRASIL CORRETORA DE VALORES E C\u00c2MBIO LTDA",
        "value": "131 | TULLETT PREBON BRASIL CORRETORA DE VALORES E C\u00c2MBIO LTDA"
    },
    {
        "key": "132 | ICBC DO BRASIL BANCO M\u00daLTIPLO S.A.",
        "value": "132 | ICBC DO BRASIL BANCO M\u00daLTIPLO S.A."
    },
    {
        "key": "133 | CONFEDERA\u00c7\u00c3O NACIONAL DAS COOPERATIVAS CENTRAIS DE CR\u00c9DITO E ECONOMIA FAMILIAR E",
        "value": "133 | CONFEDERA\u00c7\u00c3O NACIONAL DAS COOPERATIVAS CENTRAIS DE CR\u00c9DITO E ECONOMIA FAMILIAR E"
    },
    {
        "key": "134 | BGC LIQUIDEZ DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA",
        "value": "134 | BGC LIQUIDEZ DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA"
    },
    {
        "key": "136 | CONFEDERA\u00c7\u00c3O NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI",
        "value": "136 | CONFEDERA\u00c7\u00c3O NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI"
    },
    {
        "key": "138 | GET MONEY CORRETORA DE C\u00c2MBIO S.A.",
        "value": "138 | GET MONEY CORRETORA DE C\u00c2MBIO S.A."
    },
    {
        "key": "139 | INTESA SANPAOLO BRASIL S.A. - BANCO M\u00daLTIPLO",
        "value": "139 | INTESA SANPAOLO BRASIL S.A. - BANCO M\u00daLTIPLO"
    },
    {
        "key": "140 | NU INVEST CORRETORA DE VALORES S.A.",
        "value": "140 | NU INVEST CORRETORA DE VALORES S.A."
    },
    {
        "key": "142 | BROKER BRASIL CORRETORA DE C\u00c2MBIO LTDA.",
        "value": "142 | BROKER BRASIL CORRETORA DE C\u00c2MBIO LTDA."
    },
    {
        "key": "143 | TREVISO CORRETORA DE C\u00c2MBIO S.A.",
        "value": "143 | TREVISO CORRETORA DE C\u00c2MBIO S.A."
    },
    {
        "key": "144 | BEXS BANCO DE C\u00c2MBIO S/A",
        "value": "144 | BEXS BANCO DE C\u00c2MBIO S/A"
    },
    {
        "key": "145 | LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.",
        "value": "145 | LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA."
    },
    {
        "key": "146 | GUITTA CORRETORA DE CAMBIO LTDA.",
        "value": "146 | GUITTA CORRETORA DE CAMBIO LTDA."
    },
    {
        "key": "149 | FACTA FINANCEIRA S.A. - CR\u00c9DITO FINANCIAMENTO E INVESTIMENTO",
        "value": "149 | FACTA FINANCEIRA S.A. - CR\u00c9DITO FINANCIAMENTO E INVESTIMENTO"
    },
    {
        "key": "157 | ICAP DO BRASIL CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
        "value": "157 | ICAP DO BRASIL CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
    },
    {
        "key": "159 | CASA DO CR\u00c9DITO S.A. SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR",
        "value": "159 | CASA DO CR\u00c9DITO S.A. SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR"
    },
    {
        "key": "163 | COMMERZBANK BRASIL S.A. - BANCO M\u00daLTIPLO",
        "value": "163 | COMMERZBANK BRASIL S.A. - BANCO M\u00daLTIPLO"
    },
    {
        "key": "173 | BRL TRUST DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
        "value": "173 | BRL TRUST DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "174 | PEFISA S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
        "value": "174 | PEFISA S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
    },
    {
        "key": "177 | GUIDE INVESTIMENTOS S.A. CORRETORA DE VALORES",
        "value": "177 | GUIDE INVESTIMENTOS S.A. CORRETORA DE VALORES"
    },
    {
        "key": "180 | CM CAPITAL MARKETS CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA",
        "value": "180 | CM CAPITAL MARKETS CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA"
    },
    {
        "key": "183 | SOCRED S.A. - SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO P",
        "value": "183 | SOCRED S.A. - SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO P"
    },
    {
        "key": "184 | BANCO ITA\u00da BBA S.A.",
        "value": "184 | BANCO ITA\u00da BBA S.A."
    },
    {
        "key": "188 | ATIVA INVESTIMENTOS S.A. CORRETORA DE T\u00cdTULOS, C\u00c2MBIO E VALORES",
        "value": "188 | ATIVA INVESTIMENTOS S.A. CORRETORA DE T\u00cdTULOS, C\u00c2MBIO E VALORES"
    },
    {
        "key": "189 | HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS",
        "value": "189 | HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS"
    },
    {
        "key": "190 | SERVICOOP - COOPERATIVA DE CR\u00c9DITO DOS SERVIDORES P\u00daBLICOS ESTADUAIS E MUNICIPAI",
        "value": "190 | SERVICOOP - COOPERATIVA DE CR\u00c9DITO DOS SERVIDORES P\u00daBLICOS ESTADUAIS E MUNICIPAI"
    },
    {
        "key": "191 | NOVA FUTURA CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
        "value": "191 | NOVA FUTURA CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
    },
    {
        "key": "194 | PARMETAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA",
        "value": "194 | PARMETAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA"
    },
    {
        "key": "195 | VALOR SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "195 | VALOR SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "196 | FAIR CORRETORA DE CAMBIO S.A.",
        "value": "196 | FAIR CORRETORA DE CAMBIO S.A."
    },
    {
        "key": "197 | STONE INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A.",
        "value": "197 | STONE INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A."
    },
    {
        "key": "208 | BANCO BTG PACTUAL S.A.",
        "value": "208 | BANCO BTG PACTUAL S.A."
    },
    {
        "key": "212 | BANCO ORIGINAL S.A.",
        "value": "212 | BANCO ORIGINAL S.A."
    },
    {
        "key": "213 | BANCO ARBI S.A.",
        "value": "213 | BANCO ARBI S.A."
    },
    {
        "key": "217 | BANCO JOHN DEERE S.A.",
        "value": "217 | BANCO JOHN DEERE S.A."
    },
    {
        "key": "218 | BANCO BS2 S.A.",
        "value": "218 | BANCO BS2 S.A."
    },
    {
        "key": "222 | BANCO CR\u00c9DIT AGRICOLE BRASIL S.A.",
        "value": "222 | BANCO CR\u00c9DIT AGRICOLE BRASIL S.A."
    },
    {
        "key": "224 | BANCO FIBRA S.A.",
        "value": "224 | BANCO FIBRA S.A."
    },
    {
        "key": "233 | BANCO CIFRA S.A.",
        "value": "233 | BANCO CIFRA S.A."
    },
    {
        "key": "237 | BANCO BRADESCO S.A.",
        "value": "237 | BANCO BRADESCO S.A."
    },
    {
        "key": "241 | BANCO CLASSICO S.A.",
        "value": "241 | BANCO CLASSICO S.A."
    },
    {
        "key": "243 | BANCO MASTER S/A",
        "value": "243 | BANCO MASTER S/A"
    },
    {
        "key": "246 | BANCO ABC BRASIL S.A.",
        "value": "246 | BANCO ABC BRASIL S.A."
    },
    {
        "key": "249 | BANCO INVESTCRED UNIBANCO S.A.",
        "value": "249 | BANCO INVESTCRED UNIBANCO S.A."
    },
    {
        "key": "250 | BCV - BANCO DE CR\u00c9DITO E VAREJO S.A.",
        "value": "250 | BCV - BANCO DE CR\u00c9DITO E VAREJO S.A."
    },
    {
        "key": "253 | BEXS CORRETORA DE C\u00c2MBIO S/A",
        "value": "253 | BEXS CORRETORA DE C\u00c2MBIO S/A"
    },
    {
        "key": "254 | PARAN\u00c1 BANCO S.A.",
        "value": "254 | PARAN\u00c1 BANCO S.A."
    },
    {
        "key": "259 | MONEYCORP BANCO DE C\u00c2MBIO S.A.",
        "value": "259 | MONEYCORP BANCO DE C\u00c2MBIO S.A."
    },
    {
        "key": "260 | NU PAGAMENTOS S.A. - INSTITUI\u00c7\u00c3O DE PAGAMENTO",
        "value": "260 | NU PAGAMENTOS S.A. - INSTITUI\u00c7\u00c3O DE PAGAMENTO"
    },
    {
        "key": "265 | BANCO FATOR S.A.",
        "value": "265 | BANCO FATOR S.A."
    },
    {
        "key": "266 | BANCO CEDULA S.A.",
        "value": "266 | BANCO CEDULA S.A."
    },
    {
        "key": "268 | BARI COMPANHIA HIPOTEC\u00c1RIA",
        "value": "268 | BARI COMPANHIA HIPOTEC\u00c1RIA"
    },
    {
        "key": "269 | BANCO HSBC S.A.",
        "value": "269 | BANCO HSBC S.A."
    },
    {
        "key": "270 | SAGITUR CORRETORA DE C\u00c2MBIO S.A.",
        "value": "270 | SAGITUR CORRETORA DE C\u00c2MBIO S.A."
    },
    {
        "key": "271 | IB CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
        "value": "271 | IB CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "272 | AGK CORRETORA DE CAMBIO S.A.",
        "value": "272 | AGK CORRETORA DE CAMBIO S.A."
    },
    {
        "key": "273 | COOPERATIVA DE CR\u00c9DITO RURAL DE S\u00c3O MIGUEL DO OESTE - SULCREDI/S\u00c3O MIGUEL",
        "value": "273 | COOPERATIVA DE CR\u00c9DITO RURAL DE S\u00c3O MIGUEL DO OESTE - SULCREDI/S\u00c3O MIGUEL"
    },
    {
        "key": "274 | MONEY PLUS SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT",
        "value": "274 | MONEY PLUS SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT"
    },
    {
        "key": "276 | BANCO SENFF S.A.",
        "value": "276 | BANCO SENFF S.A."
    },
    {
        "key": "278 | GENIAL INVESTIMENTOS CORRETORA DE VALORES MOBILI\u00c1RIOS S.A.",
        "value": "278 | GENIAL INVESTIMENTOS CORRETORA DE VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "279 | COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE",
        "value": "279 | COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE"
    },
    {
        "key": "280 | WILL FINANCEIRA S.A. CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
        "value": "280 | WILL FINANCEIRA S.A. CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
    },
    {
        "key": "281 | COOPERATIVA DE CR\u00c9DITO RURAL COOPAVEL",
        "value": "281 | COOPERATIVA DE CR\u00c9DITO RURAL COOPAVEL"
    },
    {
        "key": "283 | RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA",
        "value": "283 | RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA"
    },
    {
        "key": "285 | FRENTE CORRETORA DE C\u00c2MBIO LTDA.",
        "value": "285 | FRENTE CORRETORA DE C\u00c2MBIO LTDA."
    },
    {
        "key": "286 | COOPERATIVA DE CR\u00c9DITO RURAL DE OURO SULCREDI/OURO",
        "value": "286 | COOPERATIVA DE CR\u00c9DITO RURAL DE OURO SULCREDI/OURO"
    },
    {
        "key": "288 | CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
        "value": "288 | CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA."
    },
    {
        "key": "289 | EFX CORRETORA DE C\u00c2MBIO LTDA.",
        "value": "289 | EFX CORRETORA DE C\u00c2MBIO LTDA."
    },
    {
        "key": "290 | PAGSEGURO INTERNET INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A.",
        "value": "290 | PAGSEGURO INTERNET INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A."
    },
    {
        "key": "292 | BS2 DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
        "value": "292 | BS2 DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "293 | LASTRO RDV DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
        "value": "293 | LASTRO RDV DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
    },
    {
        "key": "296 | OZ CORRETORA DE C\u00c2MBIO S.A.",
        "value": "296 | OZ CORRETORA DE C\u00c2MBIO S.A."
    },
    {
        "key": "298 | VIP'S CORRETORA DE C\u00c2MBIO LTDA.",
        "value": "298 | VIP'S CORRETORA DE C\u00c2MBIO LTDA."
    },
    {
        "key": "299 | BANCO SOROCRED S.A. - BANCO M\u00daLTIPLO",
        "value": "299 | BANCO SOROCRED S.A. - BANCO M\u00daLTIPLO"
    },
    {
        "key": "300 | BANCO DE LA NACION ARGENTINA",
        "value": "300 | BANCO DE LA NACION ARGENTINA"
    },
    {
        "key": "301 | BPP INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A.",
        "value": "301 | BPP INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A."
    },
    {
        "key": "306 | PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
        "value": "306 | PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA."
    },
    {
        "key": "307 | TERRA INVESTIMENTOS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
        "value": "307 | TERRA INVESTIMENTOS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
    },
    {
        "key": "309 | CAMBIONET CORRETORA DE C\u00c2MBIO LTDA.",
        "value": "309 | CAMBIONET CORRETORA DE C\u00c2MBIO LTDA."
    },
    {
        "key": "310 | VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
        "value": "310 | VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA."
    },
    {
        "key": "311 | DOURADA CORRETORA DE C\u00c2MBIO LTDA.",
        "value": "311 | DOURADA CORRETORA DE C\u00c2MBIO LTDA."
    },
    {
        "key": "312 | HSCM - SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORTE LT",
        "value": "312 | HSCM - SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORTE LT"
    },
    {
        "key": "313 | AMAZ\u00d4NIA CORRETORA DE C\u00c2MBIO LTDA.",
        "value": "313 | AMAZ\u00d4NIA CORRETORA DE C\u00c2MBIO LTDA."
    },
    {
        "key": "318 | BANCO BMG S.A.",
        "value": "318 | BANCO BMG S.A."
    },
    {
        "key": "319 | OM DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA",
        "value": "319 | OM DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA"
    },
    {
        "key": "320 | CHINA CONSTRUCTION BANK (BRASIL) BANCO M\u00daLTIPLO S/A",
        "value": "320 | CHINA CONSTRUCTION BANK (BRASIL) BANCO M\u00daLTIPLO S/A"
    },
    {
        "key": "321 | CREFAZ SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT",
        "value": "321 | CREFAZ SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT"
    },
    {
        "key": "322 | COOPERATIVA DE CR\u00c9DITO RURAL DE ABELARDO LUZ - SULCREDI/CREDILUZ",
        "value": "322 | COOPERATIVA DE CR\u00c9DITO RURAL DE ABELARDO LUZ - SULCREDI/CREDILUZ"
    },
    {
        "key": "323 | MERCADO PAGO INSTITUI\u00c7\u00c3O DE PAGAMENTO LTDA.",
        "value": "323 | MERCADO PAGO INSTITUI\u00c7\u00c3O DE PAGAMENTO LTDA."
    },
    {
        "key": "324 | CARTOS SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "324 | CARTOS SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "325 | \u00d3RAMA DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
        "value": 325 | "\u00d3RAMA DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "326 | PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
        "value": "326 | PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A."
    },
    {
        "key": "328 | COOPERATIVA DE ECONOMIA E CR\u00c9DITO M\u00daTUO DOS FABRICANTES DE CAL\u00c7ADOS DE SAPIRANGA",
        "value": "328 | COOPERATIVA DE ECONOMIA E CR\u00c9DITO M\u00daTUO DOS FABRICANTES DE CAL\u00c7ADOS DE SAPIRANGA"
    },
    {
        "key": "329 | QI SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "329 | QI SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "330 | BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.",
        "value": "330 | BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A."
    },
    {
        "key": "331 | FRAM CAPITAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
        "value": "331 | FRAM CAPITAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "332 | ACESSO SOLU\u00c7\u00d5ES DE PAGAMENTO S.A.",
        "value": "332 | ACESSO SOLU\u00c7\u00d5ES DE PAGAMENTO S.A."
    },
    {
        "key": "335 | BANCO DIGIO S.A.",
        "value": "335 | BANCO DIGIO S.A."
    },
    {
        "key": "336 | BANCO C6 S.A.",
        "value": "336 | BANCO C6 S.A."
    },
    {
        "key": "340 | SUPERDIGITAL INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A.",
        "value": "340 | SUPERDIGITAL INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A."
    },
    {
        "key": "341 | ITA\u00da UNIBANCO S.A.",
        "value": "341 | ITA\u00da UNIBANCO S.A."
    },
    {
        "key": "342 | CREDITAS SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "342 | CREDITAS SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "343 | FFA SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORTE LTDA.",
        "value": "343 | FFA SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORTE LTDA."
    },
    {
        "key": "348 | BANCO XP S.A.",
        "value": "348 | BANCO XP S.A."
    },
    {
        "key": "349 | AL5 S.A. CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
        "value": "349 | AL5 S.A. CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
    },
    {
        "key": "350 | COOPERATIVA DE CR\u00c9DITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGR\u00c1RIA DO CE",
        "value": "350 | COOPERATIVA DE CR\u00c9DITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGR\u00c1RIA DO CE"
    },
    {
        "key": "352 | TORO CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
        "value": "352 | TORO CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "354 | NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILI\u00c1RIOS E COMMODITIES",
        "value": "354 | NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILI\u00c1RIOS E COMMODITIES"
    },
    {
        "key": "355 | \u00d3TIMO SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": 355 | "\u00d3TIMO SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "358 | MIDWAY S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
        "value": "358 | MIDWAY S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
    },
    {
        "key": "359 | ZEMA CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S/A",
        "value": "359 | ZEMA CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S/A"
    },
    {
        "key": "360 | TRINUS CAPITAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
        "value": "360 | TRINUS CAPITAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "362 | CIELO S.A.",
        "value": "362 | CIELO S.A."
    },
    {
        "key": "363 | SINGULARE CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
        "value": "363 | SINGULARE CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "364 | GERENCIANET S.A.",
        "value": "364 | GERENCIANET S.A."
    },
    {
        "key": "365 | SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A.",
        "value": "365 | SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A."
    },
    {
        "key": "366 | BANCO SOCIETE GENERALE BRASIL S.A.",
        "value": "366 | BANCO SOCIETE GENERALE BRASIL S.A."
    },
    {
        "key": "367 | VITREO DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
        "value": "367 | VITREO DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "368 | BANCO CSF S.A.",
        "value": "368 | BANCO CSF S.A."
    },
    {
        "key": "370 | BANCO MIZUHO DO BRASIL S.A.",
        "value": "370 | BANCO MIZUHO DO BRASIL S.A."
    },
    {
        "key": "371 | WARREN CORRETORA DE VALORES MOBILI\u00c1RIOS E C\u00c2MBIO LTDA.",
        "value": "371 | WARREN CORRETORA DE VALORES MOBILI\u00c1RIOS E C\u00c2MBIO LTDA."
    },
    {
        "key": "373 | UP.P SOCIEDADE DE EMPR\u00c9STIMO ENTRE PESSOAS S.A.",
        "value": "373 | UP.P SOCIEDADE DE EMPR\u00c9STIMO ENTRE PESSOAS S.A."
    },
    {
        "key": "374 | REALIZE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S.A.",
        "value": "374 | REALIZE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S.A."
    },
    {
        "key": "376 | BANCO J.P. MORGAN S.A.",
        "value": "376 | BANCO J.P. MORGAN S.A."
    },
    {
        "key": "377 | BMS SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "377 | BMS SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "378 | BANCO BRASILEIRO DE CR\u00c9DITO SOCIEDADE AN\u00d4NIMA",
        "value": "378 | BANCO BRASILEIRO DE CR\u00c9DITO SOCIEDADE AN\u00d4NIMA"
    },
    {
        "key": "379 | COOPERFORTE - COOPERATIVA DE ECONOMIA E CR\u00c9DITO M\u00daTUO DE FUNCION\u00c1RIOS DE INSTITU",
        "value": "379 | COOPERFORTE - COOPERATIVA DE ECONOMIA E CR\u00c9DITO M\u00daTUO DE FUNCION\u00c1RIOS DE INSTITU"
    },
    {
        "key": "380 | PICPAY INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A.",
        "value": "380 | PICPAY INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A."
    },
    {
        "key": "381 | BANCO MERCEDES-BENZ DO BRASIL S.A.",
        "value": "381 | BANCO MERCEDES-BENZ DO BRASIL S.A."
    },
    {
        "key": "382 | FID\u00daCIA SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORTE L",
        "value": "382 | FID\u00daCIA SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO PORTE L"
    },
    {
        "key": "383 | EBANX INSTITUICAO DE PAGAMENTOS LTDA.",
        "value": "383 | EBANX INSTITUICAO DE PAGAMENTOS LTDA."
    },
    {
        "key": "384 | GLOBAL FINAN\u00c7AS SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO",
        "value": "384 | GLOBAL FINAN\u00c7AS SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESA DE PEQUENO"
    },
    {
        "key": "385 | COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS TRABALHADORES PORTUARIOS DA GRANDE V",
        "value": "385 | COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS TRABALHADORES PORTUARIOS DA GRANDE V"
    },
    {
        "key": "386 | NU FINANCEIRA S.A. - SOCIEDADE DE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
        "value": "386 | NU FINANCEIRA S.A. - SOCIEDADE DE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
    },
    {
        "key": "387 | BANCO TOYOTA DO BRASIL S.A.",
        "value": "387 | BANCO TOYOTA DO BRASIL S.A."
    },
    {
        "key": "389 | BANCO MERCANTIL DO BRASIL S.A.",
        "value": "389 | BANCO MERCANTIL DO BRASIL S.A."
    },
    {
        "key": "390 | BANCO GM S.A.",
        "value": "390 | BANCO GM S.A."
    },
    {
        "key": "391 | COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM",
        "value": "391 | COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM"
    },
    {
        "key": "393 | BANCO VOLKSWAGEN S.A.",
        "value": "393 | BANCO VOLKSWAGEN S.A."
    },
    {
        "key": "394 | BANCO BRADESCO FINANCIAMENTOS S.A.",
        "value": "394 | BANCO BRADESCO FINANCIAMENTOS S.A."
    },
    {
        "key": "395 | F.D'GOLD - DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
        "value": "395 | F.D'GOLD - DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
    },
    {
        "key": "396 | HUB PAGAMENTOS S.A",
        "value": "396 | HUB PAGAMENTOS S.A"
    },
    {
        "key": "397 | LISTO SOCIEDADE DE CREDITO DIRETO S.A.",
        "value": "397 | LISTO SOCIEDADE DE CREDITO DIRETO S.A."
    },
    {
        "key": "398 | IDEAL CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
        "value": "398 | IDEAL CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "399 | KIRTON BANK S.A. - BANCO M\u00daLTIPLO",
        "value": "399 | KIRTON BANK S.A. - BANCO M\u00daLTIPLO"
    },
    {
        "key": "400 | COOPERATIVA DE CR\u00c9DITO, POUPAN\u00c7A E SERVI\u00c7OS FINANCEIROS DO CENTRO OESTE - CREDIT",
        "value": "400 | COOPERATIVA DE CR\u00c9DITO, POUPAN\u00c7A E SERVI\u00c7OS FINANCEIROS DO CENTRO OESTE - CREDIT"
    },
    {
        "key": "401 | IUGU INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A.",
        "value": "401 | IUGU INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A."
    },
    {
        "key": "402 | COBUCCIO SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "402 | COBUCCIO SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "403 | CORA SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "403 | CORA SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "404 | SUMUP SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "404 | SUMUP SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "406 | ACCREDITO - SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "406 | ACCREDITO - SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "407 | \u00cdNDIGO INVESTIMENTOS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
        "value": 407 | "\u00cdNDIGO INVESTIMENTOS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
    },
    {
        "key": "408 | BONUSPAGO SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "408 | BONUSPAGO SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "410 | PLANNER SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR S.A.",
        "value": "410 | PLANNER SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR S.A."
    },
    {
        "key": "411 | VIA CERTA FINANCIADORA S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTOS",
        "value": "411 | VIA CERTA FINANCIADORA S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTOS"
    },
    {
        "key": "412 | SOCIAL BANK BANCO M\u00daLTIPLO S/A",
        "value": "412 | SOCIAL BANK BANCO M\u00daLTIPLO S/A"
    },
    {
        "key": "413 | BANCO BV S.A.",
        "value": "413 | BANCO BV S.A."
    },
    {
        "key": "414 | WORK SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "414 | WORK SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "416 | LAMARA SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "416 | LAMARA SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "418 | ZIPDIN SOLU\u00c7\u00d5ES DIGITAIS SOCIEDADE DE CR\u00c9DITO DIRETO S/A",
        "value": "418 | ZIPDIN SOLU\u00c7\u00d5ES DIGITAIS SOCIEDADE DE CR\u00c9DITO DIRETO S/A"
    },
    {
        "key": "419 | NUMBRS SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "419 | NUMBRS SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "421 | LAR COOPERATIVA DE CR\u00c9DITO - LAR CREDI",
        "value": "421 | LAR COOPERATIVA DE CR\u00c9DITO - LAR CREDI"
    },
    {
        "key": "422 | BANCO SAFRA S.A.",
        "value": "422 | BANCO SAFRA S.A."
    },
    {
        "key": "423 | COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILI\u00c1RIOS",
        "value": "423 | COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILI\u00c1RIOS"
    },
    {
        "key": "425 | SOCINAL S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
        "value": "425 | SOCINAL S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
    },
    {
        "key": "426 | BIORC FINANCEIRA - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S.A.",
        "value": "426 | BIORC FINANCEIRA - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO S.A."
    },
    {
        "key": "427 | COOPERATIVA DE CREDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO",
        "value": "427 | COOPERATIVA DE CREDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO"
    },
    {
        "key": "428 | CREDSYSTEM SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "428 | CREDSYSTEM SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "429 | CREDIARE S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
        "value": "429 | CREDIARE S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
    },
    {
        "key": "430 | COOPERATIVA DE CREDITO RURAL SEARA - CREDISEARA",
        "value": "430 | COOPERATIVA DE CREDITO RURAL SEARA - CREDISEARA"
    },
    {
        "key": "433 | BR-CAPITAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
        "value": "433 | BR-CAPITAL DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "435 | DELCRED SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "435 | DELCRED SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "438 | PLANNER TRUSTEE DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
        "value": "438 | PLANNER TRUSTEE DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
    },
    {
        "key": "439 | ID CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
        "value": "439 | ID CORRETORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "440 | CREDIBRF - COOPERATIVA DE CR\u00c9DITO",
        "value": "440 | CREDIBRF - COOPERATIVA DE CR\u00c9DITO"
    },
    {
        "key": "442 | MAGNETIS - DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA",
        "value": "442 | MAGNETIS - DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA"
    },
    {
        "key": "443 | CREDIHOME SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "443 | CREDIHOME SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "444 | TRINUS SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "444 | TRINUS SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "445 | PLANTAE S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
        "value": "445 | PLANTAE S.A. - CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
    },
    {
        "key": "447 | MIRAE ASSET WEALTH MANAGEMENT (BRAZIL) CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MO",
        "value": "447 | MIRAE ASSET WEALTH MANAGEMENT (BRAZIL) CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MO"
    },
    {
        "key": "448 | HEMERA DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
        "value": "448 | HEMERA DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
    },
    {
        "key": "449 | DMCARD SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "449 | DMCARD SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "450 | FITBANK PAGAMENTOS ELETRONICOS S.A.",
        "value": "450 | FITBANK PAGAMENTOS ELETRONICOS S.A."
    },
    {
        "key": "451 | J17 - SOCIEDADE DE CR\u00c9DITO DIRETO S/A",
        "value": "451 | J17 - SOCIEDADE DE CR\u00c9DITO DIRETO S/A"
    },
    {
        "key": "452 | CREDIFIT SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "452 | CREDIFIT SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "454 | M\u00c9RITO DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
        "value": "454 | M\u00c9RITO DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
    },
    {
        "key": "456 | BANCO MUFG BRASIL S.A.",
        "value": "456 | BANCO MUFG BRASIL S.A."
    },
    {
        "key": "457 | UY3 SOCIEDADE DE CR\u00c9DITO DIRETO S/A",
        "value": "457 | UY3 SOCIEDADE DE CR\u00c9DITO DIRETO S/A"
    },
    {
        "key": "458 | HEDGE INVESTMENTS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
        "value": "458 | HEDGE INVESTMENTS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
    },
    {
        "key": "459 | COOPERATIVA DE CR\u00c9DITO M\u00daTUO DE SERVIDORES P\u00daBLICOS DO ESTADO DE S\u00c3O PAULO - CRE",
        "value": "459 | COOPERATIVA DE CR\u00c9DITO M\u00daTUO DE SERVIDORES P\u00daBLICOS DO ESTADO DE S\u00c3O PAULO - CRE"
    },
    {
        "key": "460 | UNAVANTI SOCIEDADE DE CR\u00c9DITO DIRETO S/A",
        "value": "460 | UNAVANTI SOCIEDADE DE CR\u00c9DITO DIRETO S/A"
    },
    {
        "key": "461 | ASAAS GEST\u00c3O FINANCEIRA INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A.",
        "value": "461 | ASAAS GEST\u00c3O FINANCEIRA INSTITUI\u00c7\u00c3O DE PAGAMENTO S.A."
    },
    {
        "key": "462 | STARK SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "462 | STARK SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "463 | AZUMI DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
        "value": "463 | AZUMI DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
    },
    {
        "key": "464 | BANCO SUMITOMO MITSUI BRASILEIRO S.A.",
        "value": "464 | BANCO SUMITOMO MITSUI BRASILEIRO S.A."
    },
    {
        "key": "465 | CAPITAL CONSIG SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "465 | CAPITAL CONSIG SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "467 | MASTER S/A CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS",
        "value": "467 | MASTER S/A CORRETORA DE C\u00c2MBIO, T\u00cdTULOS E VALORES MOBILI\u00c1RIOS"
    },
    {
        "key": "468 | PORTOSEG S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO",
        "value": "468 | PORTOSEG S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO"
    },
    {
        "key": "469 | LIGA INVEST DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA",
        "value": "469 | LIGA INVEST DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA"
    },
    {
        "key": "470 | CDC SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESADE PEQUENO PORTE LTDA.",
        "value": "470 | CDC SOCIEDADE DE CR\u00c9DITO AO MICROEMPREENDEDOR E \u00c0 EMPRESADE PEQUENO PORTE LTDA."
    },
    {
        "key": "471 | COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS SERVIDORES PUBLICOS DE PINH\u00c3O - CRES",
        "value": "471 | COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS SERVIDORES PUBLICOS DE PINH\u00c3O - CRES"
    },
    {
        "key": "473 | BANCO CAIXA GERAL - BRASIL S.A.",
        "value": "473 | BANCO CAIXA GERAL - BRASIL S.A."
    },
    {
        "key": "477 | CITIBANK N.A.",
        "value": "477 | CITIBANK N.A."
    },
    {
        "key": "478 | GAZINCRED S.A. SOCIEDADE DE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO",
        "value": "478 | GAZINCRED S.A. SOCIEDADE DE CR\u00c9DITO, FINANCIAMENTO E INVESTIMENTO"
    },
    {
        "key": "479 | BANCO ITAUBANK S.A.",
        "value": "479 | BANCO ITAUBANK S.A."
    },
    {
        "key": "484 | MAF DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A.",
        "value": "484 | MAF DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS S.A."
    },
    {
        "key": "487 | DEUTSCHE BANK S.A. - BANCO ALEMAO",
        "value": "487 | DEUTSCHE BANK S.A. - BANCO ALEMAO"
    },
    {
        "key": "488 | JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
        "value": "488 | JPMORGAN CHASE BANK, NATIONAL ASSOCIATION"
    },
    {
        "key": "492 | ING BANK N.V.",
        "value": "492 | ING BANK N.V."
    },
    {
        "key": "495 | BANCO DE LA PROVINCIA DE BUENOS AIRES",
        "value": "495 | BANCO DE LA PROVINCIA DE BUENOS AIRES"
    },
    {
        "key": "505 | BANCO CREDIT SUISSE (BRASIL) S.A.",
        "value": "505 | BANCO CREDIT SUISSE (BRASIL) S.A."
    },
    {
        "key": "506 | RJI CORRETORA DE TITULOS E VALORES MOBILIARIOS LTDA",
        "value": "506 | RJI CORRETORA DE TITULOS E VALORES MOBILIARIOS LTDA"
    },
    {
        "key": "508 | AVENUE SECURITIES DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
        "value": "508 | AVENUE SECURITIES DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
    },
    {
        "key": "511 | MAGNUM SOCIEDADE DE CR\u00c9DITO DIRETO S.A.",
        "value": "511 | MAGNUM SOCIEDADE DE CR\u00c9DITO DIRETO S.A."
    },
    {
        "key": "512 | CAPTALYS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA.",
        "value": "512 | CAPTALYS DISTRIBUIDORA DE T\u00cdTULOS E VALORES MOBILI\u00c1RIOS LTDA."
    },
    {
        "key": "545 | SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A",
        "value": "545 | SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A"
    },
    {
        "key": "600 | BANCO LUSO BRASILEIRO S.A.",
        "value": "600 | BANCO LUSO BRASILEIRO S.A."
    },
    {
        "key": "604 | BANCO INDUSTRIAL DO BRASIL S.A.",
        "value": "604 | BANCO INDUSTRIAL DO BRASIL S.A."
    },
    {
        "key": "610 | BANCO VR S.A.",
        "value": "610 | BANCO VR S.A."
    },
    {
        "key": "611 | BANCO PAULISTA S.A.",
        "value": "611 | BANCO PAULISTA S.A."
    },
    {
        "key": "612 | BANCO GUANABARA S.A.",
        "value": "612 | BANCO GUANABARA S.A."
    },
    {
        "key": "613 | OMNI BANCO S.A.",
        "value": "613 | OMNI BANCO S.A."
    },
    {
        "key": "623 | BANCO PAN S.A.",
        "value": "623 | BANCO PAN S.A."
    },
    {
        "key": "626 | BANCO C6 CONSIGNADO S.A.",
        "value": "626 | BANCO C6 CONSIGNADO S.A."
    },
    {
        "key": "630 | BANCO LETSBANK S.A.",
        "value": "630 | BANCO LETSBANK S.A."
    },
    {
        "key": "633 | BANCO RENDIMENTO S.A.",
        "value": "633 | BANCO RENDIMENTO S.A."
    },
    {
        "key": "634 | BANCO TRIANGULO S.A.",
        "value": "634 | BANCO TRIANGULO S.A."
    },
    {
        "key": "637 | BANCO SOFISA S.A.",
        "value": "637 | BANCO SOFISA S.A."
    },
    {
        "key": "643 | BANCO PINE S.A.",
        "value": "643 | BANCO PINE S.A."
    },
    {
        "key": "652 | ITA\u00da UNIBANCO HOLDING S.A.",
        "value": "652 | ITA\u00da UNIBANCO HOLDING S.A."
    },
    {
        "key": "653 | BANCO VOITER S.A.",
        "value": "653 | BANCO VOITER S.A."
    },
    {
        "key": "654 | BANCO DIGIMAIS S.A.",
        "value": "654 | BANCO DIGIMAIS S.A."
    },
    {
        "key": "655 | BANCO VOTORANTIM S.A.",
        "value": "655 | BANCO VOTORANTIM S.A."
    },
    {
        "key": "707 | BANCO DAYCOVAL S.A.",
        "value": "707 | BANCO DAYCOVAL S.A."
    },
    {
        "key": "712 | BANCO OURINVEST S.A.",
        "value": "712 | BANCO OURINVEST S.A."
    },
    {
        "key": "720 | BANCO RNX S.A.",
        "value": "720 | BANCO RNX S.A."
    },
    {
        "key": "739 | BANCO CETELEM S.A.",
        "value": "739 | BANCO CETELEM S.A."
    },
    {
        "key": "741 | BANCO RIBEIRAO PRETO S.A.",
        "value": "741 | BANCO RIBEIRAO PRETO S.A."
    },
    {
        "key": "743 | BANCO SEMEAR S.A.",
        "value": "743 | BANCO SEMEAR S.A."
    },
    {
        "key": "745 | BANCO CITIBANK S.A.",
        "value": "745 | BANCO CITIBANK S.A."
    },
    {
        "key": "746 | BANCO MODAL S.A.",
        "value": "746 | BANCO MODAL S.A."
    },
    {
        "key": "747 | BANCO RABOBANK INTERNATIONAL BRASIL S.A.",
        "value": "747 | BANCO RABOBANK INTERNATIONAL BRASIL S.A."
    },
    {
        "key": "748 | BANCO COOPERATIVO SICREDI S.A.",
        "value": "748 | BANCO COOPERATIVO SICREDI S.A."
    },
    {
        "key": "751 | SCOTIABANK BRASIL S.A. BANCO M\u00daLTIPLO",
        "value": "751 | SCOTIABANK BRASIL S.A. BANCO M\u00daLTIPLO"
    },
    {
        "key": "752 | BANCO BNP PARIBAS BRASIL S.A.",
        "value": "752 | BANCO BNP PARIBAS BRASIL S.A."
    },
    {
        "key": "753 | NOVO BANCO CONTINENTAL S.A. - BANCO M\u00daLTIPLO",
        "value": "753 | NOVO BANCO CONTINENTAL S.A. - BANCO M\u00daLTIPLO"
    },
    {
        "key": "754 | BANCO SISTEMA S.A.",
        "value": "754 | BANCO SISTEMA S.A."
    },
    {
        "key": "755 | BANK OF AMERICA MERRILL LYNCH BANCO M\u00daLTIPLO S.A.",
        "value": "755 | BANK OF AMERICA MERRILL LYNCH BANCO M\u00daLTIPLO S.A."
    },
    {
        "key": "756 | BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB",
        "value": "756 | BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB"
    },
    {
        "key": "757 | BANCO KEB HANA DO BRASIL S.A.",
        "value": "757 | BANCO KEB HANA DO BRASIL S.A."
    }
]

export const countriesList = [{ "pais": "Antígua e Barbuda", "gentilico": "Antiguano" },
{ "pais": "Argentina", "gentilico": "Argentino" },
{ "pais": "Bahamas", "gentilico": "Bahamense" },
{ "pais": "Barbados", "gentilico": "Barbadiano, barbadense" },
{ "pais": "Belize", "gentilico": "Belizenho" },
{ "pais": "Bolívia", "gentilico": "Boliviano" },
{ "pais": "Brasil", "gentilico": "Brasileiro" },
{ "pais": "Chile", "gentilico": "Chileno" },
{ "pais": "Colômbia", "gentilico": "Colombiano" },
{ "pais": "Costa Rica", "gentilico": "Costarriquenho" },
{ "pais": "Cuba", "gentilico": "Cubano" },
{ "pais": "Dominica", "gentilico": "Dominicano" },
{ "pais": "Equador", "gentilico": "Equatoriano" },
{ "pais": "El Salvador", "gentilico": "Salvadorenho" },
{ "pais": "Granada", "gentilico": "Granadino" },
{ "pais": "Guatemala", "gentilico": "Guatemalteco" },
{ "pais": "Guiana", "gentilico": "Guianês" },
{ "pais": "Guiana Francesa", "gentilico": "Guianense" },
{ "pais": "Haiti", "gentilico": "Haitiano" },
{ "pais": "Honduras", "gentilico": "Hondurenho" },
{ "pais": "Jamaica", "gentilico": "Jamaicano" },
{ "pais": "México", "gentilico": "Mexicano" },
{ "pais": "Nicarágua", "gentilico": "Nicaraguense" },
{ "pais": "Panamá", "gentilico": "Panamenho" },
{ "pais": "Paraguai", "gentilico": "Paraguaio" },
{ "pais": "Peru", "gentilico": "Peruano" },
{ "pais": "Porto Rico", "gentilico": "Portorriquenho" },
{ "pais": "República Dominicana", "gentilico": "Dominicana" },
{ "pais": "São Cristóvão e Nevis", "gentilico": "São-cristovense" },
{ "pais": "São Vicente e Granadinas", "gentilico": "São-vicentino" },
{ "pais": "Santa Lúcia", "gentilico": "Santa-lucense" },
{ "pais": "Suriname", "gentilico": "Surinamês" },
{ "pais": "Trinidad e Tobago", "gentilico": "Trindadense" },
{ "pais": "Uruguai", "gentilico": "Uruguaio" },
{ "pais": "Venezuela", "gentilico": "Venezuelano" },
{ "pais": "Alemanha", "gentilico": "Alemão" },
{ "pais": "Áustria", "gentilico": "Austríaco" },
{ "pais": "Bélgica", "gentilico": "Belga" },
{ "pais": "Croácia", "gentilico": "Croata" },
{ "pais": "Dinamarca", "gentilico": "Dinamarquês" },
{ "pais": "Eslováquia", "gentilico": "Eslovaco" },
{ "pais": "Eslovênia", "gentilico": "Esloveno" },
{ "pais": "Espanha", "gentilico": "Espanhol" },
{ "pais": "França", "gentilico": "Francês" },
{ "pais": "Grécia", "gentilico": "Grego" },
{ "pais": "Hungria", "gentilico": "Húngaro" },
{ "pais": "Irlanda", "gentilico": "Irlandês" },
{ "pais": "Itália", "gentilico": "Italiano" },
{ "pais": "Noruega", "gentilico": "Noruego" },
{ "pais": "Países Baixos", "gentilico": "Holandês" },
{ "pais": "Polônia", "gentilico": "Polonês" },
{ "pais": "Portugal", "gentilico": "Português" },
{ "pais": "Reino Unido", "gentilico": "Britânico" },
{ "pais": "Inglaterra", "gentilico": "Inglês" },
{ "pais": "País de Gales", "gentilico": "Galês" },
{ "pais": "Escócia", "gentilico": "Escocês" },
{ "pais": "Romênia", "gentilico": "Romeno" },
{ "pais": "Rússia", "gentilico": "Russo" },
{ "pais": "Sérvio", "gentilico": "Sérvio" },
{ "pais": "Suécia", "gentilico": "Sueco" },
{ "pais": "Suíça", "gentilico": "Suíço" },
{ "pais": "Turquia", "gentilico": "Turco" },
{ "pais": "Ucrânia", "gentilico": "Ucraniano" },
{ "pais": "Estados Unidos", "gentilico": "Americano" },
{ "pais": "Canadá", "gentilico": "Canadense" },
{ "pais": "Angola", "gentilico": "Angolano" },
{ "pais": "Moçambique", "gentilico": "Moçambicano" },
{ "pais": "África do Sul", "gentilico": "Sul-africano" },
{ "pais": "Zimbabue", "gentilico": "Zimbabuense" },
{ "pais": "Argélia", "gentilico": "Argélia" },
{ "pais": "Comores", "gentilico": "Comorense" },
{ "pais": "Egito", "gentilico": "Egípcio" },
{ "pais": "Líbia", "gentilico": "Líbio" },
{ "pais": "Marrocos", "gentilico": "Marroquino" },
{ "pais": "Gana", "gentilico": "Ganés" },
{ "pais": "Quênia", "gentilico": "Queniano" },
{ "pais": "Ruanda", "gentilico": "Ruandês" },
{ "pais": "Uganda", "gentilico": "Ugandense" },
{ "pais": "Botsuana", "gentilico": "Bechuano" },
{ "pais": "Costa do Marfim", "gentilico": "Marfinense" },
{ "pais": "Camarões", "gentilico": "Camaronense" },
{ "pais": "Nigéria", "gentilico": "Nigeriano" },
{ "pais": "Somália", "gentilico": "Somali" },
{ "pais": "Austrália", "gentilico": "Australiano" },
{ "pais": "Nova Zelândia", "gentilico": "Neozelandês" },
{ "pais": "Afeganistão", "gentilico": "Afegão" },
{ "pais": "Arábia Saudita", "gentilico": "Saudita" },
{ "pais": "Armênia", "gentilico": "Armeno" },
{ "pais": "Armeno", "gentilico": "Bangladesh" },
{ "pais": "China", "gentilico": "Chinês" },
{ "pais": "Coréia do Norte", "gentilico": "Norte-coreano, coreano" },
{ "pais": "Coréia do Sul", "gentilico": "Sul-coreano, coreano" },
{ "pais": "Índia", "gentilico": "Indiano" },
{ "pais": "Indonésia", "gentilico": "Indonésio" },
{ "pais": "Iraque", "gentilico": "Iraquiano" },
{ "pais": "Irã", "gentilico": "Iraniano" },
{ "pais": "Israel", "gentilico": "Israelita" },
{ "pais": "Japão", "gentilico": "Japonês" },
{ "pais": "Malásia", "gentilico": "Malaio" },
{ "pais": "Nepal", "gentilico": "Nepalês" },
{ "pais": "Omã", "gentilico": "Omanense" },
{ "pais": "Paquistão", "gentilico": "Paquistanês" },
{ "pais": "Palestina", "gentilico": "Palestino" },
{ "pais": "Qatar", "gentilico": "Qatarense" },
{ "pais": "Síria", "gentilico": "Sírio" },
{ "pais": "Sri Lanka", "gentilico": "Cingalês" },
{ "pais": "Tailândia", "gentilico": "Tailandês" },
{ "pais": "Timor-Leste", "gentilico": "Timorense, maubere" },
{ "pais": "Emirados Árabes Unidos", "gentilico": "Árabe, emiratense" },
{ "pais": "Vietnã", "gentilico": "Vietnamita" },
{ "pais": "Iêmen", "gentilico": "Iemenita" }]

export const countriesWithouBrazilList = [{ "pais": "Antígua e Barbuda", "gentilico": "Antiguano" },
{ "pais": "Argentina", "gentilico": "Argentino" },
{ "pais": "Bahamas", "gentilico": "Bahamense" },
{ "pais": "Barbados", "gentilico": "Barbadiano, barbadense" },
{ "pais": "Belize", "gentilico": "Belizenho" },
{ "pais": "Bolívia", "gentilico": "Boliviano" },
// { "pais": "Brasil", "gentilico": "Brasileiro" },
{ "pais": "Chile", "gentilico": "Chileno" },
{ "pais": "Colômbia", "gentilico": "Colombiano" },
{ "pais": "Costa Rica", "gentilico": "Costarriquenho" },
{ "pais": "Cuba", "gentilico": "Cubano" },
{ "pais": "Dominica", "gentilico": "Dominicano" },
{ "pais": "Equador", "gentilico": "Equatoriano" },
{ "pais": "El Salvador", "gentilico": "Salvadorenho" },
{ "pais": "Granada", "gentilico": "Granadino" },
{ "pais": "Guatemala", "gentilico": "Guatemalteco" },
{ "pais": "Guiana", "gentilico": "Guianês" },
{ "pais": "Guiana Francesa", "gentilico": "Guianense" },
{ "pais": "Haiti", "gentilico": "Haitiano" },
{ "pais": "Honduras", "gentilico": "Hondurenho" },
{ "pais": "Jamaica", "gentilico": "Jamaicano" },
{ "pais": "México", "gentilico": "Mexicano" },
{ "pais": "Nicarágua", "gentilico": "Nicaraguense" },
{ "pais": "Panamá", "gentilico": "Panamenho" },
{ "pais": "Paraguai", "gentilico": "Paraguaio" },
{ "pais": "Peru", "gentilico": "Peruano" },
{ "pais": "Porto Rico", "gentilico": "Portorriquenho" },
{ "pais": "República Dominicana", "gentilico": "Dominicana" },
{ "pais": "São Cristóvão e Nevis", "gentilico": "São-cristovense" },
{ "pais": "São Vicente e Granadinas", "gentilico": "São-vicentino" },
{ "pais": "Santa Lúcia", "gentilico": "Santa-lucense" },
{ "pais": "Suriname", "gentilico": "Surinamês" },
{ "pais": "Trinidad e Tobago", "gentilico": "Trindadense" },
{ "pais": "Uruguai", "gentilico": "Uruguaio" },
{ "pais": "Venezuela", "gentilico": "Venezuelano" },
{ "pais": "Alemanha", "gentilico": "Alemão" },
{ "pais": "Áustria", "gentilico": "Austríaco" },
{ "pais": "Bélgica", "gentilico": "Belga" },
{ "pais": "Croácia", "gentilico": "Croata" },
{ "pais": "Dinamarca", "gentilico": "Dinamarquês" },
{ "pais": "Eslováquia", "gentilico": "Eslovaco" },
{ "pais": "Eslovênia", "gentilico": "Esloveno" },
{ "pais": "Espanha", "gentilico": "Espanhol" },
{ "pais": "França", "gentilico": "Francês" },
{ "pais": "Grécia", "gentilico": "Grego" },
{ "pais": "Hungria", "gentilico": "Húngaro" },
{ "pais": "Irlanda", "gentilico": "Irlandês" },
{ "pais": "Itália", "gentilico": "Italiano" },
{ "pais": "Noruega", "gentilico": "Noruego" },
{ "pais": "Países Baixos", "gentilico": "Holandês" },
{ "pais": "Polônia", "gentilico": "Polonês" },
{ "pais": "Portugal", "gentilico": "Português" },
{ "pais": "Reino Unido", "gentilico": "Britânico" },
{ "pais": "Inglaterra", "gentilico": "Inglês" },
{ "pais": "País de Gales", "gentilico": "Galês" },
{ "pais": "Escócia", "gentilico": "Escocês" },
{ "pais": "Romênia", "gentilico": "Romeno" },
{ "pais": "Rússia", "gentilico": "Russo" },
{ "pais": "Sérvio", "gentilico": "Sérvio" },
{ "pais": "Suécia", "gentilico": "Sueco" },
{ "pais": "Suíça", "gentilico": "Suíço" },
{ "pais": "Turquia", "gentilico": "Turco" },
{ "pais": "Ucrânia", "gentilico": "Ucraniano" },
{ "pais": "Estados Unidos", "gentilico": "Americano" },
{ "pais": "Canadá", "gentilico": "Canadense" },
{ "pais": "Angola", "gentilico": "Angolano" },
{ "pais": "Moçambique", "gentilico": "Moçambicano" },
{ "pais": "África do Sul", "gentilico": "Sul-africano" },
{ "pais": "Zimbabue", "gentilico": "Zimbabuense" },
{ "pais": "Argélia", "gentilico": "Argélia" },
{ "pais": "Comores", "gentilico": "Comorense" },
{ "pais": "Egito", "gentilico": "Egípcio" },
{ "pais": "Líbia", "gentilico": "Líbio" },
{ "pais": "Marrocos", "gentilico": "Marroquino" },
{ "pais": "Gana", "gentilico": "Ganés" },
{ "pais": "Quênia", "gentilico": "Queniano" },
{ "pais": "Ruanda", "gentilico": "Ruandês" },
{ "pais": "Uganda", "gentilico": "Ugandense" },
{ "pais": "Botsuana", "gentilico": "Bechuano" },
{ "pais": "Costa do Marfim", "gentilico": "Marfinense" },
{ "pais": "Camarões", "gentilico": "Camaronense" },
{ "pais": "Nigéria", "gentilico": "Nigeriano" },
{ "pais": "Somália", "gentilico": "Somali" },
{ "pais": "Austrália", "gentilico": "Australiano" },
{ "pais": "Nova Zelândia", "gentilico": "Neozelandês" },
{ "pais": "Afeganistão", "gentilico": "Afegão" },
{ "pais": "Arábia Saudita", "gentilico": "Saudita" },
{ "pais": "Armênia", "gentilico": "Armeno" },
{ "pais": "Armeno", "gentilico": "Bangladesh" },
{ "pais": "China", "gentilico": "Chinês" },
{ "pais": "Coréia do Norte", "gentilico": "Norte-coreano, coreano" },
{ "pais": "Coréia do Sul", "gentilico": "Sul-coreano, coreano" },
{ "pais": "Índia", "gentilico": "Indiano" },
{ "pais": "Indonésia", "gentilico": "Indonésio" },
{ "pais": "Iraque", "gentilico": "Iraquiano" },
{ "pais": "Irã", "gentilico": "Iraniano" },
{ "pais": "Israel", "gentilico": "Israelita" },
{ "pais": "Japão", "gentilico": "Japonês" },
{ "pais": "Malásia", "gentilico": "Malaio" },
{ "pais": "Nepal", "gentilico": "Nepalês" },
{ "pais": "Omã", "gentilico": "Omanense" },
{ "pais": "Paquistão", "gentilico": "Paquistanês" },
{ "pais": "Palestina", "gentilico": "Palestino" },
{ "pais": "Qatar", "gentilico": "Qatarense" },
{ "pais": "Síria", "gentilico": "Sírio" },
{ "pais": "Sri Lanka", "gentilico": "Cingalês" },
{ "pais": "Tailândia", "gentilico": "Tailandês" },
{ "pais": "Timor-Leste", "gentilico": "Timorense, maubere" },
{ "pais": "Emirados Árabes Unidos", "gentilico": "Árabe, emiratense" },
{ "pais": "Vietnã", "gentilico": "Vietnamita" },
{ "pais": "Iêmen", "gentilico": "Iemenita" }]

export const countriesListBrazilIncluded = [{ "pais": "Antígua e Barbuda", "gentilico": "Antiguano" },
{ "pais": "Argentina", "gentilico": "Argentino" },
{ "pais": "Bahamas", "gentilico": "Bahamense" },
{ "pais": "Barbados", "gentilico": "Barbadiano, barbadense" },
{ "pais": "Belize", "gentilico": "Belizenho" },
{ "pais": "Bolívia", "gentilico": "Boliviano" },
{ "pais": "Brasil", "gentilico": "Brasileiro" },
{ "pais": "Chile", "gentilico": "Chileno" },
{ "pais": "Colômbia", "gentilico": "Colombiano" },
{ "pais": "Costa Rica", "gentilico": "Costarriquenho" },
{ "pais": "Cuba", "gentilico": "Cubano" },
{ "pais": "Dominica", "gentilico": "Dominicano" },
{ "pais": "Equador", "gentilico": "Equatoriano" },
{ "pais": "El Salvador", "gentilico": "Salvadorenho" },
{ "pais": "Granada", "gentilico": "Granadino" },
{ "pais": "Guatemala", "gentilico": "Guatemalteco" },
{ "pais": "Guiana", "gentilico": "Guianês" },
{ "pais": "Guiana Francesa", "gentilico": "Guianense" },
{ "pais": "Haiti", "gentilico": "Haitiano" },
{ "pais": "Honduras", "gentilico": "Hondurenho" },
{ "pais": "Jamaica", "gentilico": "Jamaicano" },
{ "pais": "México", "gentilico": "Mexicano" },
{ "pais": "Nicarágua", "gentilico": "Nicaraguense" },
{ "pais": "Panamá", "gentilico": "Panamenho" },
{ "pais": "Paraguai", "gentilico": "Paraguaio" },
{ "pais": "Peru", "gentilico": "Peruano" },
{ "pais": "Porto Rico", "gentilico": "Portorriquenho" },
{ "pais": "República Dominicana", "gentilico": "Dominicana" },
{ "pais": "São Cristóvão e Nevis", "gentilico": "São-cristovense" },
{ "pais": "São Vicente e Granadinas", "gentilico": "São-vicentino" },
{ "pais": "Santa Lúcia", "gentilico": "Santa-lucense" },
{ "pais": "Suriname", "gentilico": "Surinamês" },
{ "pais": "Trinidad e Tobago", "gentilico": "Trindadense" },
{ "pais": "Uruguai", "gentilico": "Uruguaio" },
{ "pais": "Venezuela", "gentilico": "Venezuelano" },
{ "pais": "Alemanha", "gentilico": "Alemão" },
{ "pais": "Áustria", "gentilico": "Austríaco" },
{ "pais": "Bélgica", "gentilico": "Belga" },
{ "pais": "Croácia", "gentilico": "Croata" },
{ "pais": "Dinamarca", "gentilico": "Dinamarquês" },
{ "pais": "Eslováquia", "gentilico": "Eslovaco" },
{ "pais": "Eslovênia", "gentilico": "Esloveno" },
{ "pais": "Espanha", "gentilico": "Espanhol" },
{ "pais": "França", "gentilico": "Francês" },
{ "pais": "Grécia", "gentilico": "Grego" },
{ "pais": "Hungria", "gentilico": "Húngaro" },
{ "pais": "Irlanda", "gentilico": "Irlandês" },
{ "pais": "Itália", "gentilico": "Italiano" },
{ "pais": "Noruega", "gentilico": "Noruego" },
{ "pais": "Países Baixos", "gentilico": "Holandês" },
{ "pais": "Polônia", "gentilico": "Polonês" },
{ "pais": "Portugal", "gentilico": "Português" },
{ "pais": "Reino Unido", "gentilico": "Britânico" },
{ "pais": "Inglaterra", "gentilico": "Inglês" },
{ "pais": "País de Gales", "gentilico": "Galês" },
{ "pais": "Escócia", "gentilico": "Escocês" },
{ "pais": "Romênia", "gentilico": "Romeno" },
{ "pais": "Rússia", "gentilico": "Russo" },
{ "pais": "Sérvio", "gentilico": "Sérvio" },
{ "pais": "Suécia", "gentilico": "Sueco" },
{ "pais": "Suíça", "gentilico": "Suíço" },
{ "pais": "Turquia", "gentilico": "Turco" },
{ "pais": "Ucrânia", "gentilico": "Ucraniano" },
{ "pais": "Estados Unidos", "gentilico": "Americano" },
{ "pais": "Canadá", "gentilico": "Canadense" },
{ "pais": "Angola", "gentilico": "Angolano" },
{ "pais": "Moçambique", "gentilico": "Moçambicano" },
{ "pais": "África do Sul", "gentilico": "Sul-africano" },
{ "pais": "Zimbabue", "gentilico": "Zimbabuense" },
{ "pais": "Argélia", "gentilico": "Argélia" },
{ "pais": "Comores", "gentilico": "Comorense" },
{ "pais": "Egito", "gentilico": "Egípcio" },
{ "pais": "Líbia", "gentilico": "Líbio" },
{ "pais": "Marrocos", "gentilico": "Marroquino" },
{ "pais": "Gana", "gentilico": "Ganés" },
{ "pais": "Quênia", "gentilico": "Queniano" },
{ "pais": "Ruanda", "gentilico": "Ruandês" },
{ "pais": "Uganda", "gentilico": "Ugandense" },
{ "pais": "Botsuana", "gentilico": "Bechuano" },
{ "pais": "Costa do Marfim", "gentilico": "Marfinense" },
{ "pais": "Camarões", "gentilico": "Camaronense" },
{ "pais": "Nigéria", "gentilico": "Nigeriano" },
{ "pais": "Somália", "gentilico": "Somali" },
{ "pais": "Austrália", "gentilico": "Australiano" },
{ "pais": "Nova Zelândia", "gentilico": "Neozelandês" },
{ "pais": "Afeganistão", "gentilico": "Afegão" },
{ "pais": "Arábia Saudita", "gentilico": "Saudita" },
{ "pais": "Armênia", "gentilico": "Armeno" },
{ "pais": "Armeno", "gentilico": "Bangladesh" },
{ "pais": "China", "gentilico": "Chinês" },
{ "pais": "Coréia do Norte", "gentilico": "Norte-coreano, coreano" },
{ "pais": "Coréia do Sul", "gentilico": "Sul-coreano, coreano" },
{ "pais": "Índia", "gentilico": "Indiano" },
{ "pais": "Indonésia", "gentilico": "Indonésio" },
{ "pais": "Iraque", "gentilico": "Iraquiano" },
{ "pais": "Irã", "gentilico": "Iraniano" },
{ "pais": "Israel", "gentilico": "Israelita" },
{ "pais": "Japão", "gentilico": "Japonês" },
{ "pais": "Malásia", "gentilico": "Malaio" },
{ "pais": "Nepal", "gentilico": "Nepalês" },
{ "pais": "Omã", "gentilico": "Omanense" },
{ "pais": "Paquistão", "gentilico": "Paquistanês" },
{ "pais": "Palestina", "gentilico": "Palestino" },
{ "pais": "Qatar", "gentilico": "Qatarense" },
{ "pais": "Síria", "gentilico": "Sírio" },
{ "pais": "Sri Lanka", "gentilico": "Cingalês" },
{ "pais": "Tailândia", "gentilico": "Tailandês" },
{ "pais": "Timor-Leste", "gentilico": "Timorense, maubere" },
{ "pais": "Emirados Árabes Unidos", "gentilico": "Árabe, emiratense" },
{ "pais": "Vietnã", "gentilico": "Vietnamita" },
{ "pais": "Iêmen", "gentilico": "Iemenita" }]

export const BrazilStates = [
    { key: 'AC', value: 'Acre' },
    { key: 'AL', value: 'Alagoas' },
    { key: 'AP', value: 'Amapá' },
    { key: 'AM', value: 'Amazonas' },
    { key: 'BA', value: 'Bahia' },
    { key: 'CE', value: 'Ceará' },
    { key: 'DF', value: 'Distrito Federal' },
    { key: 'ES', value: 'Espírito Santo' },
    { key: 'GO', value: 'Goiás' },
    { key: 'MA', value: 'Maranhão' },
    { key: 'MT', value: 'Mato Grosso' },
    { key: 'MS', value: 'Mato Grosso do Sul' },
    { key: 'MG', value: 'Minas Gerais' },
    { key: 'PA', value: 'Pará' },
    { key: 'PB', value: 'Paraíba' },
    { key: 'PR', value: 'Paraná' },
    { key: 'PE', value: 'Pernambuco' },
    { key: 'PI', value: 'Piauí' },
    { key: 'RJ', value: 'Rio de Janeiro' },
    { key: 'RN', value: 'Rio Grande do Norte' },
    { key: 'RS', value: 'Rio Grande do Sul' },
    { key: 'RO', value: 'Rondônia' },
    { key: 'RR', value: 'Roraima' },
    { key: 'SC', value: 'Santa Catarina' },
    { key: 'SP', value: 'São Paulo' },
    { key: 'SE', value: 'Sergipe' },
    { key: 'TO', value: 'Tocantins' }
];

export const replaceByIndex = (list, index, value) => {
    return list.map((item, i) => {
        if (i === index) {
            return value
        } else {
            return item
        }
    })
}