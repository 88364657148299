import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StateStatus from "../../utils/status";
import {
    postBankAccountReceive,
    getBankAccountReceiveList,
    getBankAccountReceive as getBankAccount,
    putBankAccountReceive,
    getReceiveValueList,
    postCreateFile,
    getSendValueList,
    linkToSignedDoc,
    deleteBankAccountReceive,
    deleteSendValues,
    deleteReceiveValues,
    softDeleteSendValues,
    softDeleteReceiveValues,
    deleteExchangeFile,
    getTransactions,
    postMakeSeveralSendValueExchanges,
    postMakeSeveralReceiveValueExchanges,
    patchPersonContacts,
    patchCompanyContacts,
    editPersonData,
    editCompanyData
} from "./service";

import {
    getRatesReceiveValues,
    getReceivedValue,
    getReceivedValueBackground,
    receivedValuesReducers,
} from "./ReceiveValue/thunks";

import {
    createOperation,
    editOperation,
    getOperation,
    getOperationBackground,
    makeExchange,
    getRatesSendValues,
    sendValuesReducers,
    sendDepositReceipt,
} from "./SendValue/thunks";
import { getPerson_ } from "../Person/service";
import { getCompany_ } from "../ChooseCompany/ChooseCompanyService";

const initialState = {
    status: {
        getOwner: StateStatus.idle,
        listBankAccountReceive: StateStatus.idle,
        saveBankAccountReceive: StateStatus.idle,
        getBankAccountReceive: StateStatus.idle,
        editBankAccountReceive: StateStatus.idle,
        removeBankAccountReceive: StateStatus.idle,

        // Receive values
        listReceiveValues: StateStatus.idle,
        createReceiveValue: StateStatus.idle,
        editReceiveValue: StateStatus.idle,
        getReceiveValue: StateStatus.idle,
        requestReceivementValue: StateStatus.idle,
        deleteReceiveValues: StateStatus.idle,
        softDeleteReceiveValues: StateStatus.idle,

        // Rates
        getRatesReceiveValues: StateStatus.idle,
        getRatesSendValues: StateStatus.idle,

        // Send Values
        createOperation: StateStatus.idle,
        editOperation: StateStatus.idle,
        listSendValues: StateStatus.idle,
        getOperation: StateStatus.idle,
        getOperationBackground: StateStatus.idle,
        makeExchange: StateStatus.idle,
        sendDepositReceipt: StateStatus.idle,
        deleteSendValues: StateStatus.idle,
        softDeleteSendValues: StateStatus.idle,

        // Files
        createFile: StateStatus.idle,
        deleteExgFile: StateStatus.idle,
        linkToSignedDoc: StateStatus.idle,
    },
    errorMsg: null,
    owner: {},
    bankAccounts: [],
    listReceiveValues: [],
    receivedValue: null,
    fileInfo: {},
    pagination: {
        count: 0,
        page: 1,
        totalPages: 1,
    },
    rates: {},
    listSendValues: {},
    sendValue: {},
    files: [],
    linkSignedDoc: {},
};

export const deleteSendValuesThunk = createAsyncThunk(
    "send/deleteSendValues",
    async (id) => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj;
        const response = await deleteSendValues(cpf_cnpj, id);
        return response.data;
    }
);

export const softDeleteSendValuesThunk = createAsyncThunk(
    "send/deleteSendValues",
    async (dataForm) => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj;
        const data = { ...dataForm, cpf_cnpj };
        const response = await softDeleteSendValues(data);
        return response.data;
    }
);

export const deleteReceiveValuesThunk = createAsyncThunk(
    "receive/deleteReceiveValues",
    async (id) => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj;
        const response = await deleteReceiveValues(cpf_cnpj, id);
        return response.data;
    }
);

export const softDeleteReceiveValuesThunk = createAsyncThunk(
    "receive/deleteReceiveValues",
    async (dataForm) => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj;
        const data = { ...dataForm, cpf_cnpj };
        const response = await softDeleteReceiveValues(data);
        return response.data;
    }
);

export const getExchangeOwner = createAsyncThunk(
    "exchange/getOwner",
    async ({ isPerson, id }) => {
        if (isPerson) {
            const response = await getPerson_({ id });
            localStorage.setItem("owner", JSON.stringify(response.data));
            return response.data;
        } else {
            const response = await getCompany_({ id });
            localStorage.setItem("owner", JSON.stringify(response.data));
            return response.data;
        }
    }
);

export const listBankAccountReceive = createAsyncThunk(
    "contactReceive/listBankAccountReceive",
    async () => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj;
        const response = await getBankAccountReceiveList(cpf_cnpj);
        return response.data;
    }
);

export const linkToSignedDocThunk = createAsyncThunk(
    "exchange/SignedDoc",
    async (fileId) => {
        const response = await linkToSignedDoc(fileId);
        return response.data;
    }
);

export const getBankAccountReceive = createAsyncThunk(
    "contactReceive/getBankAccountReceive",
    async (id) => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj;
        const response = await getBankAccount(cpf_cnpj, id);
        return response.data;
    }
);

export const removeBankAccountReceive = createAsyncThunk(
    "contactReceive/removeBankAccountReceive",
    async (id) => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj;

        const response = await deleteBankAccountReceive(cpf_cnpj, id);
        return response.data;
    }
);

export const saveBankAccountReceive = createAsyncThunk(
    "contactReceive/saveBankAccountReceive",
    async (data) => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj;
        data.cpf_cnpj = cpf_cnpj;
        const response = await postBankAccountReceive(data);
        return response.data;
    }
);

export const editBankAccountReceive = createAsyncThunk(
    "contactReceive/editBankAccountReceive",
    async ({ bankId, data }) => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj;
        data.cpf_cnpj = cpf_cnpj;
        data.id = bankId;
        const response = await putBankAccountReceive(data);
        return response.data;
    }
);

export const listReceiveValues = createAsyncThunk(

    "receive/listReceiveValues",
    async ({ page, status, receive_values_ids }) => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj;
        const response = await getReceiveValueList({ cpf_cnpj, page, status, receive_values_ids });
        return response.data
    },
);

export const listSendValues = createAsyncThunk(
    "sendValues/listSendValues ",
    async ({ page, status, send_values_ids }) => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj;
        const response = await getSendValueList({ cpf_cnpj, page, status, send_values_ids })
        return response.data;
    },
);

export const createFile = createAsyncThunk(
    "exchange/createFile",
    async ({ formData, config, onSuccess, onError }) => {
        try {
            const cpf_cnpj =
                JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                    ? JSON.parse(localStorage.getItem("owner")).cpf
                    : JSON.parse(localStorage.getItem("owner")).cnpj;
            formData.append("cpf_cnpj", cpf_cnpj);
            const response = await postCreateFile(formData, config);
            onSuccess(response);
            return response;
        } catch (err) {
            onError(err);
            throw err;
        }
    }
);

export const deleteExgFile = createAsyncThunk(
    "exchange/deleteExgFile",
    async ({ file_id }) => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj;
        const response = await deleteExchangeFile(cpf_cnpj, file_id);
        return response.data;
    }
);

export const makeSendValueExchanges = createAsyncThunk(
    'sendValues/makeExchanges',
    async (data) => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj;
        data.cpf_cnpj = cpf_cnpj;
        return await postMakeSeveralSendValueExchanges(data);
    },
)

export const makeReceiveValueExchanges = createAsyncThunk(
    'receiveValues/makeExchanges',
    async (data) => {
        const cpf_cnpj =
            JSON.parse(localStorage.getItem("owner")).cpf !== undefined
                ? JSON.parse(localStorage.getItem("owner")).cpf
                : JSON.parse(localStorage.getItem("owner")).cnpj;
        data.cpf_cnpj = cpf_cnpj
        return await postMakeSeveralReceiveValueExchanges(data)
    }
)

export const updatePersonContacts = createAsyncThunk("person/updatePersonContacts", async (data) => {
    const response = await patchPersonContacts(data)
    return response.data
})

export const updateCompanyContacts = createAsyncThunk("company/updatePersonContacts", async (data) => {
    const response = await patchCompanyContacts(data)
    return response.data
})

export const updatePersonData = createAsyncThunk("person/updatePersonData",
    async (data) => {
        const response = await editPersonData(data)
        return response.data
    })

export const updateCompanyData = createAsyncThunk("person/updatePersonData",
    async (data) => {
        const response = await editCompanyData(data)
        return response.data
    })

export const exchangeSlice = createSlice({
    name: "exchange",
    initialState,
    reducers: {
        setExchangeOwner: (state, action) => {
            localStorage.setItem("owner", JSON.stringify(action.payload));
            state.owner = action.payload;
        },
        setReceivedValue: (state, action) => {
            state.receivedValue = action.payload;
        },
        setFileInfo: (state, action) => {
            state.fileInfo = action.payload;
        },
        resetSendValue: (state) => {
            state.sendValue = {};
            state.status.getOperation = StateStatus.idle;
        },
        resetRates: (state) => {
            state.rates = {};
        },
        resetRequestReceivementValue: (state) => {
            state.status.requestReceivementValue = StateStatus.idle;
        },
        resetStatusCreateReceiveValue: (state) => {
            state.status.createReceiveValue = StateStatus.idle;
        },
        resetStatusEditReceiveValue: (state) => {
            state.status.editReceiveValue = StateStatus.idle;
        },
        resetStatusRemoveBankAccountReceive: (state) => {
            state.status.removeBankAccountReceive = StateStatus.idle;
        },
        resetLinkToSignedFilesStatus: (state) => {
            state.status.linkToSignedDoc = StateStatus.idle;
            state.linkSignedDoc = {};
        },
    },
    extraReducers: {
        ...sendValuesReducers,
        ...receivedValuesReducers,
        [getExchangeOwner.pending]: (state) => {
            state.status.getOwner = StateStatus.loading;
        },
        [getExchangeOwner.fulfilled]: (state, action) => {
            state.status.getOwner = StateStatus.succeeded;
            state.owner = action.payload;
        },
        [getExchangeOwner.rejected]: (state, action) => {
            state.status.getOwner = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // getRatesReceiveValues
        [getRatesReceiveValues.pending]: (state) => {
            state.status.getRatesReceiveValues = StateStatus.loading;
        },
        [getRatesReceiveValues.fulfilled]: (state, action) => {
            state.status.getRatesReceiveValues = StateStatus.succeeded;
            state.rates = action.payload;
        },
        [getRatesReceiveValues.rejected]: (state, action) => {
            state.status.getRatesReceiveValues = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // getRatesSendValues
        [getRatesSendValues.pending]: (state) => {
            state.status.getRatesSendValues = StateStatus.loading;
        },
        [getRatesSendValues.fulfilled]: (state, action) => {
            state.status.getRatesSendValues = StateStatus.succeeded;
            state.rates = action.payload;
        },
        [getRatesSendValues.rejected]: (state, action) => {
            state.status.getRatesSendValues = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // listBankAccountReceive
        [listBankAccountReceive.pending]: (state) => {
            state.status.listBankAccountReceive = StateStatus.loading;
        },
        [listBankAccountReceive.fulfilled]: (state, action) => {
            state.status.listBankAccountReceive = StateStatus.succeeded;
            state.bankAccounts = action.payload.bank_accounts;
        },
        [listBankAccountReceive.rejected]: (state, action) => {
            state.status.listBankAccountReceive = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // saveBankAccountReceive
        [saveBankAccountReceive.pending]: (state) => {
            state.status.saveBankAccountReceive = StateStatus.loading;
        },
        [saveBankAccountReceive.fulfilled]: (state, action) => {
            state.status.saveBankAccountReceive = StateStatus.succeeded;
        },
        [saveBankAccountReceive.rejected]: (state, action) => {
            state.status.saveBankAccountReceive = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // editBankAccountReceive
        [editBankAccountReceive.pending]: (state) => {
            state.status.editBankAccountReceive = StateStatus.loading;
        },
        [editBankAccountReceive.fulfilled]: (state, action) => {
            state.status.editBankAccountReceive = StateStatus.succeeded;
        },
        [editBankAccountReceive.rejected]: (state, action) => {
            state.status.editBankAccountReceive = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // removeBankAccountReceive
        [removeBankAccountReceive.pending]: (state) => {
            state.status.removeBankAccountReceive = StateStatus.loading;
        },
        [removeBankAccountReceive.fulfilled]: (state, action) => {
            state.status.removeBankAccountReceive = StateStatus.succeeded;
        },
        [removeBankAccountReceive.rejected]: (state, action) => {
            state.status.removeBankAccountReceive = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // listReceiveValues
        [listReceiveValues.pending]: (state) => {
            state.status.listReceiveValues = StateStatus.loading;
        },
        [listReceiveValues.fulfilled]: (state, action) => {
            state.status.listReceiveValues = StateStatus.succeeded;
            state.listReceiveValues = action.payload;
        },
        [listReceiveValues.rejected]: (state, action) => {
            state.status.listReceiveValues = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // listSendValues
        [listSendValues.pending]: (state) => {
            state.status.listSendValues = StateStatus.loading;
        },
        [listSendValues.fulfilled]: (state, action) => {
            state.status.listSendValues = StateStatus.succeeded;
            state.listSendValues = action.payload;
        },
        [listSendValues.rejected]: (state, action) => {
            state.status.listSendValues = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // getReceivedValue
        [getReceivedValue.pending]: (state) => {
            state.status.getReceivedValue = StateStatus.loading;
        },
        [getReceivedValue.fulfilled]: (state, action) => {
            state.status.getReceivedValue = StateStatus.succeeded;
            state.receivedValue = action.payload;
        },
        [getReceivedValue.rejected]: (state, action) => {
            state.status.getReceivedValue = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // getReceivedValueBackground
        [getReceivedValueBackground.pending]: (state) => {
            state.status.getReceivedValueBackground = StateStatus.loading;
        },
        [getReceivedValueBackground.fulfilled]: (state, action) => {
            state.status.getReceivedValueBackground = StateStatus.succeeded;
            state.receivedValue = action.payload;
        },
        [getReceivedValueBackground.rejected]: (state, action) => {
            state.status.getReceivedValueBackground = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // createFile
        [createFile.pending]: (state) => {
            state.status.createFile = StateStatus.loading;
        },
        [createFile.fulfilled]: (state, action) => {
            state.status.createFile = StateStatus.succeeded;
            state.fileInfo = action.payload.data;
        },
        [createFile.rejected]: (state, action) => {
            state.status.createFile = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // deleteExgFile
        [deleteExgFile.pending]: (state) => {
            state.status.deleteExgFile = StateStatus.loading;
        },
        [deleteExgFile.fulfilled]: (state, action) => {
            state.status.deleteExgFile = StateStatus.succeeded;
            state.fileInfo = action.payload.data;
        },
        [deleteExgFile.rejected]: (state, action) => {
            state.status.deleteExgFile = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // createOperation
        [createOperation.pending]: (state) => {
            state.status.createOperation = StateStatus.loading;
        },
        [createOperation.fulfilled]: (state, action) => {
            state.status.createOperation = StateStatus.succeeded;
            state.sendValue = action.payload.data;
        },
        [createOperation.rejected]: (state, action) => {
            state.status.createOperation = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // editOperation
        [editOperation.pending]: (state) => {
            state.status.editOperation = StateStatus.loading;
        },
        [editOperation.fulfilled]: (state, action) => {
            state.status.editOperation = StateStatus.succeeded;
            state.sendValue = action.payload.data;
        },
        [editOperation.rejected]: (state, action) => {
            state.status.editOperation = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // getOperation
        [getOperation.pending]: (state) => {
            state.status.getOperation = StateStatus.loading;
        },
        [getOperation.fulfilled]: (state, action) => {
            state.status.getOperation = StateStatus.succeeded;
            state.sendValue = action.payload;
        },
        [getOperation.rejected]: (state, action) => {
            state.status.getOperation = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // getOperationBackground
        [getOperationBackground.pending]: (state) => {
            state.status.getOperationBackground = StateStatus.loading;
        },
        [getOperationBackground.fulfilled]: (state, action) => {
            state.status.getOperationBackground = StateStatus.succeeded;
            state.sendValue = action.payload;
        },
        [getOperationBackground.rejected]: (state, action) => {
            state.status.getOperationBackground = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // makeExchange
        [makeExchange.pending]: (state) => {
            state.status.makeExchange = StateStatus.loading;
        },
        [makeExchange.fulfilled]: (state, action) => {
            state.status.makeExchange = StateStatus.succeeded;
            state.sendValue = action.payload.data;
        },
        [makeExchange.rejected]: (state, action) => {
            state.status.makeExchange = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // sendDepositReceipt
        [sendDepositReceipt.pending]: (state) => {
            state.status.sendDepositReceipt = StateStatus.loading;
        },
        [sendDepositReceipt.fulfilled]: (state, action) => {
            state.status.sendDepositReceipt = StateStatus.succeeded;
            state.sendValue = action.payload.data;
        },
        [sendDepositReceipt.rejected]: (state, action) => {
            state.status.sendDepositReceipt = StateStatus.failed;
            state.errorMsg = action.error.message;
        },

        // get link to download click sign file
        [linkToSignedDocThunk.pending]: (state) => {
            state.status.linkToSignedDoc = StateStatus.loading;
        },
        [linkToSignedDocThunk.fulfilled]: (state, action) => {
            state.status.linkToSignedDoc = StateStatus.succeeded;
            state.linkSignedDoc = action.payload;
        },
        [linkToSignedDocThunk.rejected]: (state, action) => {
            state.status.linkToSignedDoc = StateStatus.failed;
            state.errorMsg = action.error.message;
            state.linkSignedDoc = initialState.linkSignedDoc;
        },

        // delete send values
        [deleteSendValuesThunk.pending]: (state) => {
            state.status.deleteSendValues = StateStatus.loading;
        },
        [deleteSendValuesThunk.fulfilled]: (state, action) => {
            state.status.deleteSendValues = StateStatus.succeeded;
            state.linkSignedDoc = action.payload;
        },
        [deleteSendValuesThunk.rejected]: (state, action) => {
            state.status.deleteSendValues = StateStatus.failed;
            state.errorMsg = action.error.message;
        },

        // soft delete send values
        [softDeleteSendValuesThunk.pending]: (state) => {
            state.status.softDeleteSendValues = StateStatus.loading;
        },
        [softDeleteSendValuesThunk.fulfilled]: (state, action) => {
            state.status.softDeleteSendValues = StateStatus.succeeded;
            state.linkSignedDoc = action.payload;
        },
        [softDeleteSendValuesThunk.rejected]: (state, action) => {
            state.status.softDeleteSendValues = StateStatus.failed;
            state.errorMsg = action.error.message;
        },

        // delete receive values
        [deleteReceiveValuesThunk.pending]: (state) => {
            state.status.deleteReceiveValues = StateStatus.loading;
        },
        [deleteReceiveValuesThunk.fulfilled]: (state, action) => {
            state.status.deleteReceiveValues = StateStatus.succeeded;
            state.linkSignedDoc = action.payload;
        },
        [deleteReceiveValuesThunk.rejected]: (state, action) => {
            state.status.deleteReceiveValues = StateStatus.failed;
            state.errorMsg = action.error.message;
        },

        // soft delete receive values
        [softDeleteReceiveValuesThunk.pending]: (state) => {
            state.status.deleteReceiveValues = StateStatus.loading;
        },
        [softDeleteReceiveValuesThunk.fulfilled]: (state, action) => {
            state.status.deleteReceiveValues = StateStatus.succeeded;
            state.linkSignedDoc = action.payload;
        },
        [softDeleteReceiveValuesThunk.rejected]: (state, action) => {
            state.status.deleteReceiveValues = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
    },
});

export const listStatusTransactions = createAsyncThunk(
    "company/listStatusTransactions",
    async ({ cnpj, status, type, cpf, owner_type }) => {
        const response = await getTransactions({
            cnpj: cnpj ?? null,
            owner_type: owner_type ?? null,
            status: status ?? null,
            type: type ?? null,
            cpf: cpf ?? null,
        });
        return response.data;
    }
);

export const {
    setExchangeOwner,
    setReceivedValue,
    setFileInfo,
    resetSendValue,
    resetRates,
    resetRequestReceivementValue,
    resetStatusCreateReceiveValue,
    resetStatusEditReceiveValue,
    resetStatusRemoveBankAccountReceive,
    resetLinkToSignedFilesStatus,
} = exchangeSlice.actions;

export const selectExchange = (state) => state.exchange;
