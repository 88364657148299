import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SyncOutlined } from "@ant-design/icons";
import StateStatus from "../../utils/status";

import Routes from "../../routes/routes";

import "./LoadingData.scss";
import {
	selectCompany,
	resetGetCompaniesStatus,
	resetAddCompany,
	getCompanyByCnpj,
  resetGetCompanyByCnpj,
} from "../ChooseCompany/ChooseCompanySlice";
import CantRegisterModal from "../CantRegisterModal";
const { Title } = Typography;

const LoadingData = (props) => {
	const company = useSelector(selectCompany);
	const dispatch = useDispatch();
	const [showModal, setshowModal] = useState(false);
	const [gotByCnpj, setgotByCnpj] = useState(false);

	useEffect(() => {
		if (company.status.getCompanyByCnpj === StateStatus.idle) {
			dispatch(getCompanyByCnpj());
		}
	});

	useEffect(() => {
		if (company.status.getCompanyByCnpj === StateStatus.succeeded) {
			if (company.company !== null && company.company.can_register) {
				props.history.push(Routes.legalPerson);
			} else {
				setgotByCnpj(true);
				setshowModal(true);
      }
      dispatch(resetGetCompanyByCnpj())
		}
	}, [company, dispatch, props.history, gotByCnpj]);

	return (
		<div className="loading-data">
			<SyncOutlined spin className="loading-icon" />
			<Title level={2}>Carregando seus dados</Title>
			<CantRegisterModal
				visible={showModal}
				centered={true}
				onOk={() => {
					dispatch(resetGetCompaniesStatus());
					dispatch(resetAddCompany());
					props.history.push(Routes.chooseCompany);
				}}
			/>
		</div>
	);
};

export default LoadingData;
