import React, { useEffect, useState } from "react";
import ErrorMsg from "../../common/components/ErrorMsg";
import { cnpj as cnpjValidator, cpf as cpfValidator } from "cpf-cnpj-validator";
import EfexInput from "../../common/components/Input";
import Checkbox from "../../common/components/Checkbox";
import { GreyButton } from "../../common/components/Button";
import { OrangeLink } from "../../common/components/Link";
import { Form, Row, Col, Layout, notification } from "antd";

import { useDispatch, useSelector } from "react-redux";
import "./InitialRegister.scss";
import { registerCompany, selectInitialRegister } from "./InitialRegisterSlice";
import StateStatus from "../../utils/status";
import Routes from "../../routes/routes";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import br from "world_countries_lists/data/countries/en/world.json";
import { SmileOutlined } from "@ant-design/icons";

const Register = (props) => {
    const [form] = Form.useForm();
    const [valueCountryNumber, setValueCountryNumber] = useState({
        short: "BR",
    });

    const initialRegister = useSelector(selectInitialRegister);

    const dispatch = useDispatch();

    const onFinish = (values) => {
        values.cnpj = cnpjValidator.strip(values.cnpj);
        values.phone = `${values.phone.code}-${cnpjValidator.strip(
            values.phone.phone
        )}`;
        values.cpf = cpfValidator.strip(values.cpf);
        delete values.isAgree;
        dispatch(registerCompany(values));
    };

    useEffect(() => {
        const accessToken = localStorage.getItem("access_token");
        if (accessToken !== null && accessToken !== "undefined") {
            props.history.push(Routes.loadingData);
        }
    });

    useEffect(() => {
        if (initialRegister.status === StateStatus.succeeded) {
            props.history.push(Routes.login);
            notification.success({
                message: (
                    <p>
                        Seja bem vindo! <SmileOutlined color="#F2F200" />
                    </p>
                ),
                description: (
                    <p>
                        Enviamos uma confirmação para o seu email, verifique
                        para fazer login.
                    </p>
                ),
                duration: 5,
            });
        }
    });

    return (
        <Layout className="left-right-layout">
            <div className="left-container"></div>
            <div className="right-container">
                <div className="welcome">Registrar</div>
                {initialRegister.status === StateStatus.failed ? (
                    <ErrorMsg message={initialRegister.errorMsg}></ErrorMsg>
                ) : null}
                <Row
                    className="register-row"
                    gutter={[24, 0]}
                    justify="space-between"
                >
                    <Col>
                        <Form
                            form={form}
                            layout="horizontal"
                            name="basic"
                            onFinish={onFinish}
                            scrollToFirstError={true}
                        >
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe o nome completo",
                                    },
                                ]}
                            >
                                <EfexInput placeholder="Nome completo" />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe o email",
                                    },
                                    {
                                        type: "email",
                                        message: "Informe um email válido.",
                                    },
                                ]}
                            >
                                <EfexInput type="email" placeholder="Email" />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe o telefone",
                                    },
                                ]}
                            >
                                <ConfigProvider locale={br}>
                                    <CountryPhoneInput
                                        className="register-country-phone"
                                        onChange={(v) => {
                                            form.setFieldsValue({ phone: v });
                                        }}
                                        value={valueCountryNumber}
                                    />
                                </ConfigProvider>
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Informe a senha",
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (
                                                value != null &&
                                                value.length >= 8
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    "Mínimo 8 caracteres."
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <EfexInput
                                    type="password"
                                    placeholder="Senha"
                                />
                            </Form.Item>
                            <Form.Item
                                name="confirm_password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Confirme a senha",
                                    },
                                    () => ({
                                        validator(_, value) {
                                            const password =
                                                form.getFieldValue("password");
                                            if (value === password) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    "As senhas devem ser iguais."
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <EfexInput
                                    type="password"
                                    placeholder="Confirmar senha"
                                />
                            </Form.Item>
                            <Form.Item
                                name="isAgree"
                                valuePropName="checked"
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value
                                                ? Promise.resolve()
                                                : Promise.reject(
                                                      new Error(
                                                          "É necessário aceitar os termos."
                                                      )
                                                  ),
                                    },
                                ]}
                            >
                                <Checkbox>
                                    Eu concordo com as{" "}
                                    <a
                                        href="files/termos_de_uso.pdf"
                                        target="_blank"
                                    >
                                        <u>
                                            Políticas de Privacidade & Termos de
                                            Uso.
                                        </u>
                                    </a>
                                </Checkbox>
                            </Form.Item>
                            <Row justify="center">
                                <GreyButton
                                    type="primary"
                                    htmlType="submit"
                                    loading={
                                        initialRegister.status ===
                                        StateStatus.loading
                                    }
                                >
                                    {initialRegister.status ===
                                    StateStatus.loading
                                        ? "Carregando"
                                        : "Registrar"}
                                </GreyButton>
                            </Row>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "20px",
                                }}
                            >
                                Eu já tenho&nbsp;
                                <OrangeLink to="/login">registro</OrangeLink>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </Layout>
    );
};

export default Register;
