import React, { useEffect, useState } from "react";
import { Col, Card, Typography, message, Table, Divider, Row } from "antd";

import "./styles.scss";
import { ShopOutlined, UserOutlined, DeleteOutlined } from "@ant-design/icons";


const { Title } = Typography;

const ContactReceiveCard = ({ contact, account = null, onClick, onDelete, loading, resume, ...props }) => {

  useEffect(() => {
    if (loading) {
      message.loading({
        key: "loading",
        content: "Carregando"
      });
    }
  });

  const columnsAccounts = [
    {
      title: 'Banco',
      render: (obj) => <>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            {obj.bank_name}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} >
            <div><span className="feature">IBAN: </span>{obj.iban_code}</div>
            <div><span className="feature">SWIFT: </span>{obj.swift_code}</div>
            <div><span className="feature">ABA/BIC: </span>{obj.aba_bic_code}</div>
          </Col>
        </Row>
      </>
    },
    { title: 'Moeda', dataIndex: 'currency' },
  ]

  const columnsWithoutIban = [
    { title: 'Agência', dataIndex: 'agency_number' },
    { title: 'Número da conta', dataIndex: 'account_number' }
  ]

  if (account && account[0].iban_code === "") {
    columnsWithoutIban.forEach(item => columnsAccounts.push(item))
  }

  return (
    <Card
      className={`contact-receive-card`}
    >
      <Col style={{width: "100%"}}>
        <div className="card-header" onClick={onClick}>
          <Row gutter={[16, 0]} align="middle">
            <div style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <Row gutter={[10, 0]}>
                <Col>
                  <div className="icon-type">
                    {contact.type === "company" ? <ShopOutlined /> : <UserOutlined />}
                  </div>
                </Col>
                <Col>
                  <Title level={5}>{contact.name}</Title>
                  <div>{contact.addr_country} - {contact.addr_city_name}</div>
                </Col>
              </Row>
              <Col>
                {/* <div className="delete-icon">
                  <DeleteOutlined className="delete-icon" onClick={() => onDelete()}/>
                </div>   */}
              </Col>
            </div>
          </Row>
        </div>
        {!resume && (
          <div className="card-body">
            <Divider />
            <Row gutter={[8, 0]}>
              <Col sm={24}>
                <Row gutter={[8, 8]} style={{ marginBottom: '8px' }}>
                  <Col sm={12}>
                    <div className="feature">Endereço:</div>
                    <Row gutter={[8, 0]}>
                      <Col>{contact.addr_country} - {contact.addr_city_name}</Col>
                    </Row>
                    <Row gutter={[8, 0]}>
                      <Col>
                        {contact.addr}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12}>
                    {typeof contact.is_governmental === "boolean" && (
                      <Row>
                        <span className="feature">Orgão Governamental:</span>
                        <span>{contact.is_governmental ? "Sim" : "Não"}</span>
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={24}>
                    <Table
                      pagination={false}
                      dataSource={account !== null ? account : contact.accounts}
                      columns={columnsAccounts}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </Col>
    </Card >
  );
};

export default ContactReceiveCard;
