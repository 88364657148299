import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StateStatus from "../../../../utils/status";
import {
	getContactSendList,
	postContactsSend,
	getContactSend as getContact,
	putContactSend,
	getBankAccounts,
	postBankAccount,
	putBankAccount,
	getBankAccount,
	deleteBankAccount,
	deleteContactSend
} from "./service";

const initialState = {
	status: {
		listContactSend: StateStatus.idle,
		getContactSend: StateStatus.idle,
		saveContactSend: StateStatus.idle,
		editContact: StateStatus.idle,
		deleteContact: StateStatus.idle,
		listBankAccount: StateStatus.idle,
		saveBankAccount: StateStatus.idle,
		editBankAccount: StateStatus.idle,
		getBankInfo: StateStatus.idle,
		removeBankAccount: StateStatus.idle
	},
	errorMsg: null,
	contactsSend: [],
	contactSend: {},
	bankAccounts: [],
	bankAccount: {},
};

export const listContactSend = createAsyncThunk(
	"contactSend/listContactSend",
	async () => {
		const cpf_cnpj = JSON.parse(localStorage.getItem("owner")).cpf !== undefined ? JSON.parse(localStorage.getItem("owner")).cpf : JSON.parse(localStorage.getItem("owner")).cnpj;
		const response = await getContactSendList(cpf_cnpj);
		return response.data;
	},
);

export const getContactSend = createAsyncThunk(
	"contactSend/getContactSend",
	async (id) => {
		const idContact = JSON.parse(localStorage.getItem("contactSend")).id;
		const cpf_cnpj = JSON.parse(localStorage.getItem("owner")).cpf !== undefined ? JSON.parse(localStorage.getItem("owner")).cpf : JSON.parse(localStorage.getItem("owner")).cnpj;
		const response = await getContact(
			cpf_cnpj,
			id !== undefined ? id : idContact,
		);
		return response.data;
	},
);

export const saveContactSend = createAsyncThunk(
	"contactSend/saveContactSend",
	async (data) => {
		const cpf_cnpj = JSON.parse(localStorage.getItem("owner")).cpf !== undefined ? JSON.parse(localStorage.getItem("owner")).cpf : JSON.parse(localStorage.getItem("owner")).cnpj;
		data.cpf_cnpj = cpf_cnpj
		return await postContactsSend(data);
	},
);

export const editContact = createAsyncThunk(
	"contactSend/editContact",
	async ({ contactId, data }) => {
		const cpf_cnpj = JSON.parse(localStorage.getItem("owner")).cpf !== undefined ? JSON.parse(localStorage.getItem("owner")).cpf : JSON.parse(localStorage.getItem("owner")).cnpj;
		data.cpf_cnpj = cpf_cnpj
		data.id = contactId
		return await putContactSend(data);
	},
);

export const deleteContact = createAsyncThunk(
	"contactSend/deleteContact",
	async ( contactId ) => {
		const cpf_cnpj = JSON.parse(localStorage.getItem("owner")).cpf !== undefined ? JSON.parse(localStorage.getItem("owner")).cpf : JSON.parse(localStorage.getItem("owner")).cnpj;
		return await deleteContactSend(cpf_cnpj, contactId);
	},
);

export const listBankAccount = createAsyncThunk(
	"contactSend/listBankAccount",
	async ({ contactId }) => {
		const cpf_cnpj = JSON.parse(localStorage.getItem("owner")).cpf !== undefined ? JSON.parse(localStorage.getItem("owner")).cpf : JSON.parse(localStorage.getItem("owner")).cnpj;
		return await getBankAccounts(cpf_cnpj, contactId);
	},
);

export const saveBankAccount = createAsyncThunk(
	"contactSend/saveBankAccount",
	async (data) => {
		const cpf_cnpj = JSON.parse(localStorage.getItem("owner")).cpf !== undefined ? JSON.parse(localStorage.getItem("owner")).cpf : JSON.parse(localStorage.getItem("owner")).cnpj;
		data.cpf_cnpj = cpf_cnpj
		return await postBankAccount(data);
	},
);

export const editBankAccount = createAsyncThunk(
	"contactSend/editBankAccount",
	async ({ bankId, data }) => {
		const cpf_cnpj = JSON.parse(localStorage.getItem("owner")).cpf !== undefined ? JSON.parse(localStorage.getItem("owner")).cpf : JSON.parse(localStorage.getItem("owner")).cnpj;
		data.id = bankId
		data.cpf_cnpj = cpf_cnpj
		return await putBankAccount(data);
	},
);
export const getBankInfo = createAsyncThunk(
	"contactSend/getBankInfo",
	async ({ bankId }) => {
		const cpf_cnpj = JSON.parse(localStorage.getItem("owner")).cpf !== undefined ? JSON.parse(localStorage.getItem("owner")).cpf : JSON.parse(localStorage.getItem("owner")).cnpj;
		return await getBankAccount(cpf_cnpj, bankId);
	},
);
export const removeBankAccount = createAsyncThunk(
	"contactSend/removeBankAccount",
	async (bankId) => {
		const cpf_cnpj = JSON.parse(localStorage.getItem("owner")).cpf !== undefined ? JSON.parse(localStorage.getItem("owner")).cpf : JSON.parse(localStorage.getItem("owner")).cnpj;
		const resp = await deleteBankAccount(cpf_cnpj, bankId);
		return resp.data
	},
);



export const contactSend = createSlice({
	name: "contactSend",
	initialState,
	reducers: {
		setContactSend: (state, action) => {
			const contact = action.payload || {};
			localStorage.setItem("contactSend", JSON.stringify(contact));
			state.contactSend = contact;
			state.status.getContactSend = StateStatus.idle;
		},
		resetStatusRemoveBankAccount: (state) => {
			state.status.removeBankAccount = StateStatus.idle;
		},
	},
	extraReducers: {
		[listContactSend.pending]: (state) => {
			state.status.listContactSend = StateStatus.loading;
		},
		[listContactSend.fulfilled]: (state, action) => {
			state.status.listContactSend = StateStatus.succeeded;
			state.contactsSend = action.payload.contacts;
		},
		[listContactSend.rejected]: (state, action) => {
			state.status.listContactSend = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[getContactSend.pending]: (state) => {
			state.status.getContactSend = StateStatus.loading;
		},
		[getContactSend.fulfilled]: (state, action) => {
			state.status.getContactSend = StateStatus.succeeded;
			state.contactSend = action.payload;
		},
		[getContactSend.rejected]: (state, action) => {
			state.status.getContactSend = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[saveContactSend.pending]: (state) => {
			state.status.saveContactSend = StateStatus.loading;
		},
		[saveContactSend.fulfilled]: (state, action) => {
			state.status.saveContactSend = StateStatus.succeeded;
			state.contactSend = action.payload.data;
		},
		[saveContactSend.rejected]: (state, action) => {
			state.status.saveContactSend = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[editContact.pending]: (state) => {
			state.status.editContact = StateStatus.loading;
		},
		[editContact.fulfilled]: (state, action) => {
			state.status.editContact = StateStatus.succeeded;
			state.contactSend = action.payload.data;
		},
		[editContact.rejected]: (state, action) => {
			state.status.editContact = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[deleteContact.pending]: (state) => {
			state.status.deleteContact = StateStatus.loading;
		},
		[deleteContact.fulfilled]: (state, action) => {
			state.status.deleteContact = StateStatus.succeeded;
		},
		[deleteContact.rejected]: (state, action) => {
			state.status.deleteContact = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[listBankAccount.pending]: (state) => {
			state.status.listBankAccount = StateStatus.loading;
		},
		[listBankAccount.fulfilled]: (state, action) => {
			state.status.listBankAccount = StateStatus.succeeded;
			state.bankAccounts = action.payload.data.bank_accounts
		},
		[listBankAccount.rejected]: (state, action) => {
			state.status.listBankAccount = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[saveBankAccount.pending]: (state) => {
			state.status.saveBankAccount = StateStatus.loading;
		},
		[saveBankAccount.fulfilled]: (state, action) => {
			state.status.saveBankAccount = StateStatus.succeeded;
		},
		[saveBankAccount.rejected]: (state, action) => {
			state.status.saveBankAccount = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[editBankAccount.pending]: (state) => {
			state.status.editBankAccount = StateStatus.loading;
		},
		[editBankAccount.fulfilled]: (state, action) => {
			state.status.editBankAccount = StateStatus.succeeded;
		},
		[editBankAccount.rejected]: (state, action) => {
			state.status.editBankAccount = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[getBankInfo.pending]: (state) => {
			state.status.getBankInfo = StateStatus.loading;
		},
		[getBankInfo.fulfilled]: (state, action) => {
			state.status.getBankInfo = StateStatus.succeeded;
			state.bankAccount = action.payload.data
		},
		[getBankInfo.rejected]: (state, action) => {
			state.status.getBankInfo = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
		[removeBankAccount.pending]: (state) => {
			state.status.removeBankAccount = StateStatus.loading;
		},
		[removeBankAccount.fulfilled]: (state, action) => {
			state.status.removeBankAccount = StateStatus.succeeded;
			state.status.getContactSend = StateStatus.idle
		},
		[removeBankAccount.rejected]: (state, action) => {
			state.status.removeBankAccount = StateStatus.failed;
			state.errorMsg = action.error.message;
		},
	},
});

export const { setContactSend, resetStatusRemoveBankAccount } = contactSend.actions;

export const selectContactSend = (state) => state.contactSend;
