import React from "react";
import { Col, Row } from "antd";
import { cpf as cpfValidator } from "cpf-cnpj-validator";
import { ExclamationOutlined } from "@ant-design/icons"
import { GreenLink } from "../../../../common/components/Link";
import "./styles.scss";
import { ellipsis } from "../../../../utils/beautify";
import { DeleteOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";


const PersonCard = ({ person, onClick, loading, index, inCreating, isOdd, isResponsible, onDelete }) => {
    const [statusTextLength, setStatusTextLength] = useState(35);
    const [personNameLength, setPersonNameLength] = useState(35)

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 373) {
                setPersonNameLength(30)
                setStatusTextLength(23)
            } else if (window.innerWidth > 372 && window.innerWidth < 425) {
                setPersonNameLength(35)
                setStatusTextLength(32)
            } else {
                setPersonNameLength(60)
                setStatusTextLength(60)
            }
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    // useEffect(() => {
    //     if (loading) {
    //         message.loading({
    //             key: "loading",
    //             content: "Carregando",
    //         });
    //     }
    // });
    return isResponsible ?
        <div
            style={{ marginBottom: "18px", padding: '4px', width: "100%", backgroundColor: isOdd ? "white" : "#F7F7F7" }}
        >
            <Row>
                {
                    inCreating ? <Col sm={1}>
                        <div style={{ marginTop: "4px", marginRight: "5px", display: "flex", justifyContent: "center", alignItems: "center", width: "18px", height: "18px", backgroundColor: "var(--green)", borderRadius: "50px", color: "white" }}>
                            <ExclamationOutlined />
                        </div>
                    </Col>
                        : null
                }
                <Col className="person-infos" sm={inCreating ? 22 : 24} >
                    <Row>
                        <Col sm={16} style={{ cursor: "pointer" }} onClick={onClick}>
                            <div className="person-name">{ellipsis(person.name, personNameLength)}</div>
                            <div style={{ fontSize: "14px", fontFamily: "Roboto", color: "#838282" }}>{cpfValidator.format(person.cpf)}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: "100%" }}>
                                {
                                    inCreating ?
                                        <div><b>Status: </b>&nbsp;{ellipsis(person.register_steps[
                                            person.register_steps.length - 1
                                        ].value, statusTextLength)}</div>
                                        : <GreenLink to={`/dashboard/pessoas/${person.id}/cambio`}>Ver pessoa e fazer câmbio</GreenLink>
                                }
                            </div>
                        </Col>
                    </Row>
                    {
                        inCreating ?
                            <Row>
                                <Col sm={1} className="delete-column-mobile">
                                    <DeleteOutlined style={{ fontSize: "18px" }} onClick={onDelete} />
                                </Col>
                            </Row>
                            : null
                    }
                </Col>
            </Row >
        </div >
        : <div
            style={{ width: "100%", backgroundColor: isOdd ? "white" : "#F7F7F7", display: 'flex', alignItems: 'center' }}
        >
            <Row align="center" alignItems="center" style={{ width: '100%' }}>
                {
                    inCreating ? <Col>
                        <div style={{ marginTop: "4px", marginRight: "5px", display: "flex", justifyContent: "center", alignItems: "center", width: "18px", height: "18px", backgroundColor: "var(--green)", borderRadius: "50px", color: "white" }}>
                            <ExclamationOutlined />
                        </div>
                    </Col>
                        : null
                }
                <Col sm={inCreating ? 22 : 24} style={{ width: '100%' }}>
                    <Row>
                        <Col sm={16} style={{ cursor: "pointer", padding: '4px' }} onClick={onClick}>
                            <div className={`person-name person-name-option-${index}`}>{person.name}</div>
                            <div style={{ fontSize: "14px", fontFamily: "Roboto", color: "#838282" }}>{cpfValidator.format(person.cpf)}</div>
                        </Col>
                        <Col sm={7}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: "100%" }}>
                                {
                                    inCreating ?
                                        <div><b>Status: </b>&nbsp;{person.register_steps[
                                            person.register_steps.length - 1
                                        ].value}</div>
                                        : <GreenLink to={`/dashboard/pessoas/${person.id}/cambio`}>Ver pessoa e fazer câmbio</GreenLink>
                                }
                            </div>
                        </Col>
                        {
                            inCreating ? <Col sm={1} className="delete-column-mobile">
                                <DeleteOutlined style={{ fontSize: "18px" }} onClick={onDelete} />
                            </Col>
                                : null
                        }
                    </Row>
                </Col>
            </Row>
        </div>
};

export default PersonCard;
