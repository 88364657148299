import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//
import { Divider, Col, Form, Input, Row, Typography } from "antd";
import RegisterBaseLayout from "../../common/components/RegisterBaseLayout/RegisterBaseLayout";
import Routes from "../../routes/routes";
import StateStatus from "../../utils/status";
import { hideDigits, scrollUp,} from "../../utils/functions";
import "./ConfirmRegister.scss";
import {
    resetCompleteStatus,
    selectCompleteQsaInfo,
} from "../CompleteQsaInfo/CompleteQsaInfoSlice";

import { beautifyCpfCnpj } from "../../utils/beautify";
import { acceptReview, selectConfirmReg } from "./ConfirmRegisterSlice";

import { resetStatus as resetCompanyExtra } from "../LegalPersonExtra/LegalPersonExtraSlice";
import {
    selectQsa,
    getResponsibles,
} from "../SelectPartners/SelectPartnersSlice";
import { OrangeButton, WhiteButton } from "../../common/components/Button";
import { EfexCard } from "../../common/components/Card";
import TooltipEfex from "../../common/components/Tooltip";

const { Title, Text } = Typography;

const ConfirmRegister = (props) => {
    const [form] = Form.useForm();
    const financialResponsibles = useSelector(selectQsa);
    const confirmReg = useSelector(selectConfirmReg);
    const completeQsaInfo = useSelector(selectCompleteQsaInfo);
    const [company] = useState(JSON.parse(localStorage.getItem("company")));
    const [editResponsibles, setEditResponsibles] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (financialResponsibles.status.responsibles === StateStatus.idle) {
            dispatch(getResponsibles());
        }

        if (confirmReg.status.confirmRegister === StateStatus.succeeded) {
            props.history.push(Routes.uploadFile);
        }
        if (completeQsaInfo.status === StateStatus.idle && editResponsibles) {
            props.history.push(Routes.completePartnersData);
        }
    });

    useEffect(() => {
        if (company !== null) {
            form.setFieldsValue({
                ...company,
                cnpj: beautifyCpfCnpj(company.cnpj),

            });
        }
    });

    return (
        <>
            <RegisterBaseLayout
                {...props}
                current={4}
                titleL3="Confirmar Registro"
                text="Confirme abaixo a empresa e o(s) responsável(eis) cadastrado(s) antes de clicar em avançar."
            >
                <Form
                    form={form}
                    layout="vertical"
                    className="confirm-register"
                    requiredMark={false}
                >
                    <Row gutter={[8, 8]}>
                        <Col xs={24} sm={24} lg={12}>
                            <EfexCard
                                actions={[
                                    <WhiteButton
                                        hoverColor="orange"
                                        onClick={() => {
                                            dispatch(resetCompanyExtra());
                                            localStorage.setItem(
                                                "edit-kyc",
                                                true
                                            );
                                            props.history.push(
                                                Routes.legalPerson
                                            );
                                        }}
                                    >
                                        Editar
                                    </WhiteButton>,
                                ]}
                            >
                                <Title level={5}>Empresa</Title>
                                <Divider/>
                                <Form.Item
                                    name="company_name"
                                    label="Razão social"
                                >
                                    <Input
                                        placeholder="Razão social"
                                        disabled
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="cnpj"
                                    label="CNPJ"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Informe o cnpj",
                                        }   
                                    ]}
                                >
                                    <Input
                                        placeholder="CNPJ"
                                        disabled
                                    />
                                </Form.Item>
                            </EfexCard>
                        </Col>
                        <Col xs={24} sm={24} lg={12}>
                            <EfexCard
                                actions={[
                                    <WhiteButton
                                        hoverColor="orange"
                                        loading={
                                            financialResponsibles.status
                                                .responsibles ===
                                            StateStatus.loading
                                        }
                                        onClick={() => {
                                            // TODO: centralizar tudo de responsáveis financeiros no SelectPartnerSlice
                                            dispatch(resetCompleteStatus());
                                            setEditResponsibles(true);
                                            // dispatch(resetResponsiblesStatus());
                                            scrollUp();
                                        }}
                                    >
                                        Editar
                                    </WhiteButton>,
                                ]}
                            >
                                <Title level={5}>
                                    Responsáveis pela empresa
                                    <TooltipEfex title="Abaixo estão os dados das pessoas que foram cadastradas como responsáveis legais pela empresa" />
                                </Title>
                                <Divider/>
                                {financialResponsibles.status.responsibles ===
                                StateStatus.succeeded
                                    ? financialResponsibles.data.responsibles.map(
                                        (responsible, index) => {
                                            return (
                                                <Col
                                                    key={index}
                                                    className="responsavel"
                                                >
                                                    <Title level={5}>
                                                        {responsible.name}
                                                    </Title>
                                                    <Text type="secondary">
                                                        {responsible.qualification ===
                                                        "resp-legal"
                                                            ? "Responsável Legal"
                                                            : responsible.qualification}{" "}
                                                        -{" "}
                                                        {hideDigits(
                                                            beautifyCpfCnpj(
                                                                responsible.cpf
                                                            )
                                                        )}
                                                    </Text>
                                                    <Text type="secondary">
                                                        {responsible.doc_type}{" "}
                                                        -{" "}
                                                        {
                                                            responsible.doc_number
                                                        }
                                                    </Text>
                                                    <Text type="secondary">
                                                        {
                                                            responsible.addr_public_place
                                                        }
                                                        ,{" "}
                                                        {
                                                            responsible.addr_number
                                                        }
                                                        ,{" "}
                                                        {
                                                            responsible.addr_neighborhood
                                                        }
                                                        ,{" "}
                                                        {
                                                            responsible.addr_city_name
                                                        }{" "}
                                                    </Text>
                                                    <Text type="secondary">
                                                        {responsible.email} -{" "}
                                                        {responsible.cell_phone}
                                                    </Text>
                                                </Col>
                                            );
                                        }
                                    )
                                    : financialResponsibles.status
                                            .responsibles === StateStatus.failed
                                    ? "Erro, contate o suporte"
                                    : "Carregando..."}
                            </EfexCard>
                        </Col>
                    </Row>
                    <Form.Item className="btn-back-next">
                        <WhiteButton
                            hoverColor="orange"
                            onClick={() => {
                                dispatch(resetCompleteStatus());
                                setEditResponsibles(true);
                                // dispatch(resetResponsiblesStatus());
                                scrollUp();
                            }}
                        >
                            Voltar
                        </WhiteButton>
                        <OrangeButton
                            type="primary"
                            htmlType="submit"
                            loading={confirmReg.status.confirmRegister === StateStatus.loading}
                            onClick={() => {
                                dispatch(acceptReview());
                            }}
                        >
                            {confirmReg.status.confirmRegister === StateStatus.loading
                                ? "Carregando"
                                : "Avançar"}
                        </OrangeButton>
                    </Form.Item>
                </Form>
            </RegisterBaseLayout>
        </>
    );
};

export default ConfirmRegister;
