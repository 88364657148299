import React, { useEffect, useState } from "react";
import MaskedInput from "antd-mask-input";
import { cnpj as cnpjValidator } from "cpf-cnpj-validator";
import { Form, Select, Typography, Row, Col, message, Divider } from "antd";
import { SyncOutlined, ShopOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import StateStatus from "../../utils/status";
import Routes from "../../routes/routes";
import CompanyCard from "./components/CompanyCard";
import BlankCompany from "./components/BlankCompany";
import ErrorMsg from "../../common/components/ErrorMsg";
import { sendValueAndReceiveValueStatus } from "../../utils/consts";

import {
    getCompanies,
    postAddCompany,
    selectCompany,
    setCnpj,
    getCompany,
    setCompany,
    getCompaniesCompleted,
    listLastTransactions,
    listStatusTransactions,
    resetDuplicateState,
    duplicateTransaction,
    deleteCompanyThunk,
} from "./ChooseCompanySlice";
import "./ChooseCompany.scss";
import { CheckSteps, useQuery } from "../../utils/functions";
import EfexModal from "../../common/components/Modal";
import { OrangeButton } from "../../common/components/Button";
import { EfexCard } from "../../common/components/Card";
import { TransactionCard } from "../../common/components/TransactionCard";
import { handleResize } from "../../utils/responsible";
import { mask } from "../../utils/consts";
import InfiniteScrollList from "../../common/components/InfiniteScrollList";
const { Title } = Typography;

const ChooseCompany = ({ history }) => {
    const [form] = Form.useForm();
    const companies = useSelector(selectCompany);
    const [showModal, updateShowModal] = useState(false);
    const [isResponsible, updateResponsible] = useState(false);
    const [deleteModal, setDeleteModal] = useState({
        visible: false,
        company_id: null,
    });
    const [filteredStatus, setFilteredStatus] = useState(null);
    const [filteredOrder, setFilteredOrder] = useState("DESC");
    const [statusTransactions, setStatusTransactions] = useState([]);
    const [page, setPage] = useState(1);
    const [isEmpty, setIsEmpty] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const getMoreStatusTransactions = () => {
        dispatch(
            listStatusTransactions({
                page: page + 1,
                status: filteredStatus,
                order: filteredOrder,
            })
        ).then((resp) => {
            if (resp.payload) {
                if (
                    resp.payload.pagination.total_pages <=
                    resp.payload.pagination.current_page
                ) {
                    setHasMore(false);
                } else {
                    setPage(resp.payload.pagination.current_page);
                    setStatusTransactions((old) => [
                        ...old,
                        ...resp.payload.lst,
                    ]);
                }
            }
        });
    };

    let query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        setHasMore(true);
        dispatch(listStatusTransactions({})).then((resp) => {
            if (resp.payload) {
                resp.payload.pagination.total_pages <=
                    resp.payload.pagination.current_page && setHasMore(false);
                setStatusTransactions(resp.payload.lst);
            }
        });
        dispatch(listLastTransactions({}));
        window.addEventListener("resize", () =>
            handleResize(updateResponsible)
        );
        handleResize(updateResponsible);
    }, []);

    useEffect(() => {
        if (companies.status.getCompany === StateStatus.succeeded) {
            const status =
                companies.company.register_steps[
                    companies.company.register_steps.length - 1
                ].key;
            message.destroy(1);
            if (status === "waiting_sign_documents") {
                message.info({
                    key: 1,
                    content:
                        "Para concluir esta etapa, pedimos que verifique seu email e assine os documentos necessários para concluirmos o cadastro.",
                });
            }
            dispatch(setCompany(companies.company));
            dispatch(setCnpj(companies.company.cnpj));
            CheckSteps(
                companies.company.register_steps,
                history,
                companies.company.id,
                companies.company.register_update || false,
            );
        }
    }, [companies.company, companies.status.getCompany, dispatch, history]);

    useEffect(() => {
        if (companies.status.getCompanies === StateStatus.idle) {
            dispatch(getCompanies());
        }
        if (companies.status.getCompaniesCompleted === StateStatus.idle) {
            dispatch(getCompaniesCompleted());
        }
        if (companies.status.addCompany === StateStatus.succeeded) {
            updateShowModal(false);
            history.push(Routes.loadingData);
        }
    }, [
        companies.status.getCompanies,
        companies.status.addCompany,
        dispatch,
        history,
        companies.status.getCompaniesCompleted,
    ]);

    useEffect(() => {
        if (query.get("open_register")) {
            updateShowModal(true);
            history.replace({
                search: "",
            });
        }
    }, [history, query]);

    useEffect(() => {
        if (companies.status.duplicateTransaction === StateStatus.loading) {
            message.loading({ key: 1, content: "Duplicando transação..." });
        }

        if (companies.status.duplicateTransaction === StateStatus.succeeded) {
            dispatch(resetDuplicateState());
            const { id, type, owner_id } = companies.duplicatedTransaction;
            const route = `/dashboard/empresas/${owner_id}/cambio`;

            if (type === "send_value") {
                history.push(`${route}/enviar-valores/${id}`);
            } else {
                history.push(`${route}/operacoes-abertas/${id}`);
            }
        }

        if (companies.status.duplicateTransaction === StateStatus.failed) {
            message.error({ key: 1, content: "Erro ao duplicar transação." });
        }
    }, [companies.status.duplicateTransaction]);

    const onFinish = (values) => {
        values.cnpj = cnpjValidator.strip(values.cnpj);
        dispatch(setCnpj(values.cnpj));
        dispatch(postAddCompany(values));
    };

    useEffect(() => {
        if (companies.status.deleteCompany === StateStatus.succeeded) {
            message.success({
                key: 1,
                content: "Empresa excluída com sucesso!",
            });
            dispatch(getCompanies());
        }
    }, [companies.status.deleteCompany]);

    const onDeleteCompany = (companyId) => {
        dispatch(deleteCompanyThunk(companyId));
        setDeleteModal({ visible: false });
    };

    return (
        <div className="choose-company-section">
            <EfexModal
                title="Você deseja excluir esta empresa?"
                onOk={() => onDeleteCompany(deleteModal.companyId)}
                visible={deleteModal.visible}
                onCancel={() => setDeleteModal({ visible: false })}
            >
                Você deseja excluir a empresa {deleteModal.companyName}?
                <br />
                ATENÇÃO: Esta operação <span style={{ color: "red" }}>
                    NÃO
                </span>{" "}
                poderá ser desfeita.
            </EfexModal>
            <div>
                <Row gutter={[16, 16]} justify="space-between">
                    {isResponsible ? (
                        <Row style={{ width: "100%" }} justify="center">
                            <OrangeButton
                                size="small"
                                type="primary"
                                style={{
                                    width: "140px",
                                    fontWeight: "500",
                                }}
                                onClick={() => updateShowModal(true)}
                            >
                                Nova empresa
                            </OrangeButton>
                        </Row>
                    ) : null}
                    <Col xs={24} sm={24} lg={12} style={{ width: "100%" }}>
                        <EfexCard>
                            <div
                                style={{
                                    borderBottom: "1px solid #E0E0E0",
                                    paddingBottom: "10px",
                                    marginBottom: "10px",
                                }}
                                className="companies-in-register-header"
                            >
                                <Title level={4}>
                                    Empresas com cadastro em análise
                                </Title>
                                {!isResponsible ? (
                                    <OrangeButton
                                        size="small"
                                        type="primary"
                                        style={{
                                            width: "140px",
                                            fontWeight: "500",
                                        }}
                                        onClick={() => updateShowModal(true)}
                                    >
                                        Nova empresa
                                    </OrangeButton>
                                ) : null}
                            </div>
                            {companies.status.getCompanies ===
                                StateStatus.loading ? (
                                <Row justify="center">
                                    <SyncOutlined
                                        spin
                                        className="loading-icon"
                                    />
                                </Row>
                            ) : (
                                <>
                                    {companies.companies.length > 0 ? (
                                        companies.companies.map(
                                            (company, index) => {
                                                return (
                                                    <Row key={index}>
                                                        <CompanyCard
                                                            inCreating={true}
                                                            isResponsible={
                                                                isResponsible
                                                            }
                                                            isOdd={
                                                                index % 2 === 0
                                                            }
                                                            // key={index}
                                                            loading={
                                                                companies.status
                                                                    .addCompany ===
                                                                StateStatus.loading &&
                                                                companies.status
                                                                    .getResponsables ===
                                                                StateStatus.loading
                                                            }
                                                            company={company}
                                                            onDelete={() => {
                                                                setDeleteModal({
                                                                    visible: true,
                                                                    companyId:
                                                                        company.id,
                                                                    companyName:
                                                                        company.company_name,
                                                                });
                                                            }}
                                                            onClick={() => {
                                                                message.loading(
                                                                    {
                                                                        key: 1,
                                                                        content:
                                                                            "Carregando...",
                                                                    }
                                                                );
                                                                dispatch(
                                                                    getCompany(
                                                                        company.id
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </Row>
                                                );
                                            }
                                        )
                                    ) : (
                                        <BlankCompany />
                                    )}
                                </>
                            )}
                        </EfexCard>
                        <div style={{ height: "16px" }} />
                        <EfexCard>
                            <div
                                style={{
                                    borderBottom: "1px solid #E0E0E0",
                                    paddingBottom: "10px",
                                    marginBottom: "10px",
                                }}
                                className="companies-in-register-header"
                            >
                                <Title level={4}>Empresas cadastradas</Title>
                            </div>

                            {companies.status.getCompaniesCompleted ===
                                StateStatus.loading ? (
                                <Row justify="center">
                                    <SyncOutlined
                                        spin
                                        className="loading-icon"
                                    />
                                </Row>
                            ) : (
                                <>
                                    {companies.companiesCompleted.length > 0 ? (
                                        companies.companiesCompleted.map(
                                            (company, index) => {
                                                return (
                                                    <Row key={index}>
                                                        <CompanyCard
                                                            isResponsible={
                                                                isResponsible
                                                            }
                                                            isOdd={
                                                                index % 2 === 0
                                                            }
                                                            // key={index}
                                                            loading={
                                                                companies.status
                                                                    .addCompany ===
                                                                StateStatus.loading &&
                                                                companies.status
                                                                    .getResponsables ===
                                                                StateStatus.loading
                                                            }
                                                            company={company}
                                                            onClick={() => {
                                                                message.loading(
                                                                    {
                                                                        key: 1,
                                                                        content:
                                                                            "Carregando...",
                                                                    }
                                                                );
                                                                dispatch(
                                                                    getCompany(
                                                                        company.id
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </Row>
                                                );
                                            }
                                        )
                                    ) : (
                                        <BlankCompany />
                                    )}
                                </>
                            )}
                        </EfexCard>
                    </Col>
                    <Col xs={24} sm={24} lg={12} style={{ width: "100%" }}>
                        <Row gutter={[16, 16]}>
                            <Col sm={24} style={{ width: "100%" }}>
                                <EfexCard className="card-transactions">
                                    <div>
                                        <Row
                                            justify="space-between"
                                            className="transaction-status-header"
                                        >
                                            <Col xs={24} sm={8} md={8} lg={8}>
                                                <Title level={4}>
                                                    Status das transações
                                                </Title>
                                            </Col>
                                            <Col
                                                xs={24}
                                                sm={16}
                                                md={16}
                                                lg={16}
                                                className="selects-col"
                                            >
                                                <Row
                                                    className="filter-selects"
                                                    style={{
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <Col>
                                                        <Select
                                                            defaultValue="Todos status"
                                                            placeholder="ordenar"
                                                            className="transactions-select"
                                                            dropdownStyle={{
                                                                minWidth:
                                                                    "250px",
                                                            }}
                                                            placement="bottomRight"
                                                            size="small"
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setHasMore(true)
                                                                setPage(1);
                                                                setStatusTransactions(
                                                                    []
                                                                );
                                                                setFilteredStatus(
                                                                    value
                                                                );
                                                                setIsEmpty(
                                                                    false
                                                                );
                                                                dispatch(
                                                                    listStatusTransactions(
                                                                        {
                                                                            status: value,
                                                                            order: filteredOrder,
                                                                        }
                                                                    )
                                                                ).then(
                                                                    (resp) => {
                                                                        if (
                                                                            resp.payload
                                                                        ) {
                                                                            resp
                                                                                .payload
                                                                                .pagination
                                                                                .total_pages <=
                                                                                resp
                                                                                    .payload
                                                                                    .pagination
                                                                                    .current_page &&
                                                                                setHasMore(
                                                                                    false
                                                                                );
                                                                            setStatusTransactions(
                                                                                resp
                                                                                    .payload
                                                                                    .lst
                                                                            );
                                                                            if (
                                                                                resp
                                                                                    .payload
                                                                                    .lst
                                                                                    .length ===
                                                                                0
                                                                            ) {
                                                                                setIsEmpty(
                                                                                    true
                                                                                );
                                                                            }
                                                                        }
                                                                    }
                                                                );
                                                            }}
                                                            options={
                                                                sendValueAndReceiveValueStatus
                                                            }
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Select
                                                            defaultValue="DESC"
                                                            placeholder="ordenar"
                                                            className="transactions-select"
                                                            size="small"
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                setHasMore(
                                                                    true
                                                                );
                                                                setPage(1);
                                                                setStatusTransactions(
                                                                    []
                                                                );
                                                                setFilteredOrder(
                                                                    value
                                                                );
                                                                setIsEmpty(
                                                                    false
                                                                );
                                                                dispatch(
                                                                    listStatusTransactions(
                                                                        {
                                                                            order: value,
                                                                            status: filteredStatus,
                                                                        }
                                                                    )
                                                                ).then(
                                                                    (resp) => {
                                                                        if (
                                                                            resp.payload
                                                                        ) {
                                                                            resp
                                                                                .payload
                                                                                .pagination
                                                                                .total_pages <=
                                                                                resp
                                                                                    .payload
                                                                                    .pagination
                                                                                    .current_page &&
                                                                                setHasMore(
                                                                                    false
                                                                                );
                                                                            setStatusTransactions(
                                                                                resp
                                                                                    .payload
                                                                                    .lst
                                                                            );
                                                                            if (
                                                                                resp
                                                                                    .payload
                                                                                    .lst
                                                                                    .length ===
                                                                                0
                                                                            ) {
                                                                                setIsEmpty(
                                                                                    true
                                                                                );
                                                                            }
                                                                        }
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <Select.Option value="DESC">
                                                                Recentes
                                                            </Select.Option>
                                                            <Select.Option value="ASC">
                                                                Antigas
                                                            </Select.Option>
                                                        </Select>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Divider></Divider>
                                        </Row>
                                    </div>

                                    {!isEmpty ? (
                                        <InfiniteScrollList
                                            list={statusTransactions}
                                            nextFn={getMoreStatusTransactions}
                                            isStatus={true}
                                            isResponsible={isResponsible}
                                            hasMore={hasMore}
                                            endMessage={
                                                <h4
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Fim
                                                </h4>
                                            }
                                        />
                                    ) : isEmpty && !filteredStatus ? (
                                        <p
                                            style={{
                                                textAlign: "center",
                                                color: "#ccc",
                                            }}
                                        >
                                            Você ainda não possui nenhuma
                                            transação
                                        </p>
                                    ) : (
                                        <h4 style={{ textAlign: "center" }}>
                                            Não encontrado
                                        </h4>
                                    )}
                                </EfexCard>
                            </Col>
                            <Col sm={24} style={{ width: "100%" }}>
                                <EfexCard className="card-transactions">
                                    <div>
                                        <Row justify="space-between">
                                            <Title level={4}>
                                                Transações concluídas
                                            </Title>
                                            <Select
                                                defaultValue="DESC"
                                                placeholder="ordenar"
                                                className="transactions-select"
                                                style={{ width: "160px" }}
                                                onChange={(value) => {
                                                    dispatch(
                                                        listLastTransactions({
                                                            order: value,
                                                        })
                                                    );
                                                }}
                                            >
                                                <Select.Option value="DESC">
                                                    Recentes
                                                </Select.Option>
                                                <Select.Option value="ASC">
                                                    Antigas
                                                </Select.Option>
                                            </Select>
                                        </Row>
                                        <Divider></Divider>
                                    </div>
                                    {companies.status.listLastTransactions ===
                                        StateStatus.loading ? (
                                        <Row
                                            justify="center"
                                            className="row-loading"
                                        >
                                            <SyncOutlined spin />
                                        </Row>
                                    ) : !companies.lastTransactions.length ===
                                        0 ? (
                                        <Row>
                                            {companies.lastTransactions.map(
                                                (item, index) => {
                                                    return (
                                                        <Col
                                                            sm={24}
                                                            key={index}
                                                        >
                                                            <TransactionCard
                                                                isResponsible={
                                                                    isResponsible
                                                                }
                                                                data={{
                                                                    id: item.id,
                                                                    created_at:
                                                                        item.created_at,
                                                                    owner: item.owner,
                                                                    type: item.type,
                                                                    value: item.value,
                                                                    currency:
                                                                        item.currency,
                                                                    status: item.status,
                                                                    status_display:
                                                                        item.status_display,
                                                                    status_updated_at:
                                                                        item.status_updated_at,
                                                                }}
                                                                onClickDuplicate={() => {
                                                                    dispatch(
                                                                        duplicateTransaction(
                                                                            {
                                                                                id: item.id,
                                                                                type: item.type,
                                                                                owner_id:
                                                                                    item
                                                                                        .owner
                                                                                        .id,
                                                                                cnpj: item
                                                                                    .owner
                                                                                    .cpf_cnpj,
                                                                            }
                                                                        )
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                    );
                                                }
                                            )}
                                        </Row>
                                    ) : (
                                        <p
                                            style={{
                                                textAlign: "center",
                                                color: "#ccc",
                                            }}
                                        >
                                            Você ainda não concluiu nenhuma
                                            transação
                                        </p>
                                    )}
                                </EfexCard>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <EfexModal
                className={"modal-company"}
                confirmLoading={
                    companies.status.addCompany === StateStatus.loading
                }
                onCancel={() => {
                    updateShowModal(false);
                    form.resetFields();
                }}
                onOk={() => form.submit()}
                title="Adicionar nova empresa"
                visible={showModal}
            >
                {companies.status.addCompany === StateStatus.failed ? (
                    <ErrorMsg message={companies.errorMsg} />
                ) : null}
                <div
                    style={{
                        fontSize: "14px",
                        lineHeight: "25px",
                        marginBottom: "20px",
                    }}
                >
                    <p>
                        Atenção! Você precisará ter em mãos alguns documentos e
                        informações da empresa.
                    </p>
                    <p>
                        Para iniciar, insira abaixo o <strong>CNPJ</strong>.
                    </p>
                </div>
                <Form form={form} onFinish={onFinish}>
                    <Form.Item
                        name={"cnpj"}
                        rules={[
                            {
                                required: true,
                                message: "Informe o CNPJ",
                            },
                            () => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        cnpjValidator.isValid(value)
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error("CNPJ inválido.")
                                    );
                                },
                            }),
                        ]}
                    >
                        <MaskedInput
                            prefix={<ShopOutlined />}
                            placeholder="CNPJ"
                            mask={mask.cnpj}
                        />
                    </Form.Item>
                </Form>
            </EfexModal>
        </div>
    );
};

export default ChooseCompany;
