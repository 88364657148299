import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Row, Col, Divider, message } from "antd";
import {
    getContactSend,
    listContactSend,
    selectContactSend,
    setContactSend,
    deleteContact,
} from "./slice";
import StateStatus from "../../../../utils/status";
import { SyncOutlined } from "@ant-design/icons";
import BlankContactSend from "./components/BlankContactSend";
import ContactSendCard from "./components/ContactSendCard";
import { history } from "../../../../helpers/history";
import { useRouteMatch } from "react-router-dom";
import { useState } from "react";
import ContactSendModal from "../../_components/ContactSendModal";
import { EfexCard } from "../../../../common/components/Card";
import {
    GreenButton,
    OrangeButton,
} from "../../../../common/components/Button";
import "./styles.scss";


const { Title, Text } = Typography;


const ContactsSend = () => {
    const contact = useSelector(selectContactSend);
    const dispatch = useDispatch();
    const [showContactModal, setShowContactModal] = useState(false);
    let { url } = useRouteMatch();

    useEffect(() => {
        if (url.includes("novo")) {
            dispatch(setContactSend(null));
            setShowContactModal(true);
            history.replace(url.replace("/novo", ""));
        }
    }, []);

    useEffect(() => {
        dispatch(listContactSend());
    }, []);
    
    useEffect(() => {
        if(contact.status.deleteContact === StateStatus.succeeded){
            dispatch(listContactSend());
        }
        if(contact.status.deleteContact === StateStatus.loading){
            message.loading({key: 1, content: "Carregando..."})
        }
    }, [contact.status.deleteContact, dispatch]);

    const deleteContactFunc = (contactId) => {
        dispatch(deleteContact(contactId)).then((resp) => {
            if(resp?.payload){
                message.success("Contato excluído com sucesso!")
                message.destroy(1)
            }else{
                message.error(resp?.error.name || "Erro ao deletar contato!")
                message.destroy(1)
            }
        })
    }

    return (
        <div className="contacts-send">
            <EfexCard>
                <Row justify="space-between" align="middle">
                    <Title level={4}>Contatos cadastrados</Title>
                    {url.includes("empresas") ? (
                        <OrangeButton
                            id="button-add-contact-top"
                            onClick={() => {
                                dispatch(setContactSend(null));
                                setShowContactModal(true);
                            }}
                        >
                            Novo contato
                        </OrangeButton>
                    ) : (
                        <GreenButton
                            id="button-add-contact-top"
                            onClick={() => {
                                dispatch(setContactSend(null));
                                setShowContactModal(true);
                            }}
                        >
                            Novo contato
                        </GreenButton>
                    )}
                </Row>
                <Text type="second" style={{ fontSize: "16px" }}>
                    Dados bancários para envio de pagamentos
                </Text>
                <Divider />
                {contact.status.listContactSend === StateStatus.loading ? (
                    <Row justify="center">
                        <SyncOutlined spin className="loading-icon" />
                    </Row>
                ) : (
                    <>
                        <Row
                            justify="start"
                            gutter={[16, 16]}
                            className="contacts-list"
                        >
                            {contact.contactsSend.map((contact, index) => {
                                return (
                                    <Col xs={24} sm={24} md={12} lg={12} key={index}>
                                        <ContactSendCard
                                            contact={contact}
                                            resume={true}
                                            onClick={() => {
                                                dispatch(setContactSend(contact));
                                                dispatch(getContactSend(contact.id));
                                                history.push(`${url}/${contact.id}`);
                                            }}
                                            onDelete={() => deleteContactFunc(contact.id)}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                        {contact.contactsSend.length === 0 && (
                            <BlankContactSend />
                        )}
                    </>
                )}
            </EfexCard>
            <Row justify="center" style={{ padding: "20px 0" }}>
                {url.includes("empresas") ? (
                    <OrangeButton
                        id="button-add-contact-bottom"
                        onClick={() => {
                            dispatch(setContactSend(null));
                            setShowContactModal(true);
                        }}
                    > Novo contato </OrangeButton>
                ) : (
                    <GreenButton
                        id="button-add-contact-bottom"
                        onClick={() => {
                            dispatch(setContactSend(null));
                            setShowContactModal(true);
                        }}
                    > Novo contato </GreenButton>
                )}
            </Row>
            <ContactSendModal
                visible={showContactModal}
                onOk={(data) => {
                    setShowContactModal(false);
                    dispatch(setContactSend(null));
                    dispatch(getContactSend(data.id));
                    history.push(`${url}/${data.id}`);
                }}
                onCancel={() => {
                    setShowContactModal(false);
                }}
            />
        </div>
    );
};

export default ContactsSend;
