export const cpfMask = value => {
    return `${value.slice(0,3)}.${value.slice(3,6)}.${value.slice(6,9)}-${value.slice(9)}`
}

export const cnpjMask = value => {
    return value
        .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const beautifyCpfCnpj = value => {
    if (!value) return '';
    
    const final = value.replace(/[.\-/]/g, '')
    
    if(final.length < 12) {
        return cpfMask(value);
    } else {
        return cnpjMask(value);
    }
}


export const ellipsis = (value, maxSize) => {
    if (value.length > maxSize) {
        const newValue = value.slice(0, maxSize - 3);
        return `${newValue}...`
    } else {
        return value;
    }
}