import React from "react";
import './styles.scss';

const LoadingIcon = ({color}) => {
  return (
    <div className={color === "orange" ? "loading-orange" : "loading-green"}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingIcon;