import api from "../service/api"
import apiRoutes from "../service/apiRoutes"

export const toBrazilianDateString = (dateString) => {
    if (dateString === undefined || dateString === null) {
        return ''
    }
    const splitedDate = dateString.split("-")
    return `${splitedDate[2]}/${splitedDate[1]}/${splitedDate[0]}`
}

export const isCommercialHourRange = () => {
    return api.get(apiRoutes.isOpen)
}

export const CanCloseToday = () => {
    return api.get(apiRoutes.CanCloseToday)
}