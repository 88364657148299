import { useRouteMatch } from "react-router-dom";
import calendarIcon from "../../../assets/calendar.png";
import calendarYellowIcon from "../../../assets/calendar_yellow.png";

export const CalendarIcon = () => {
    let { path } = useRouteMatch();
    return (
        <>
            <img
                className="custom-icon"
                src={path.match("empresas") ? calendarYellowIcon : calendarIcon}
            />
        </>
    );
};
