import React, { useEffect, useState } from "react";
import { Form, Layout, Row, Divider, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../routes/routes";
import { forgotThunk, selectForgot } from "./ForgotSlice";
import StateStatus from '../../utils/status';
import ErrorMsg from '../../common/components/ErrorMsg';
import { OrangeLink } from "../../common/components/Link";
import { GreyButton } from "../../common/components/Button";
import EfexInput from "../../common/components/Input";
import "./Forgot.scss";
import { mask } from "../../utils/consts";


const Forgot = () => {
  const dispatch = useDispatch();
  const forgot = useSelector(selectForgot);

  useEffect(() => {
    if (forgot.status === StateStatus.succeeded) {
      message.success("Foi enviado um email com as instruções para você recuperar seu email!", 5)
    }
  }, [forgot.status])

  const onFinish = (values) => {
    dispatch(forgotThunk(values));
  };

  return (
    <Layout className="left-right-layout">
      <div className="left-container">
      </div>
      <div className="right-container">
        <div className="welcome">Esqueci a senha</div>
        {forgot.status === StateStatus.failed ? (
          <ErrorMsg message={forgot.errorMsg}></ErrorMsg>
        ) : null}
        <Form name="basic" onFinish={onFinish} layout="horizontal">
        <Form.Item
              name="username"
              rules={[
                  {
                      required: true,
                      message: "Informe o email",
                  },
                  {
                      type: "email",
                      message: "Informe um email válido.",
                  },
              ]}
          >
              <EfexInput
                  type="email"
                  placeholder="Email"
                  prefix={
                      <UserOutlined className="site-form-item-icon" />
                  }
              />
          </Form.Item>
          <Row justify="center">
            <Form.Item>
              <GreyButton type="primary" htmlType="submit" loading={forgot.status === StateStatus.loading}>
                {forgot.status === StateStatus.loading ? "Carregando" : "Alterar"}
              </GreyButton>
            </Form.Item>
          </Row>
        </Form>
        <div style={{ width: "150px" }}>
          <Divider />
        </div>
        <Row justify="end">
          <OrangeLink to={Routes.login}>Voltar</OrangeLink>
        </Row>
      </div>
    </Layout>
  );
};

export default Forgot;
