import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Form, Input, Select, Divider, Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import MaskedInput from "antd-mask-input";
import { cpf as cpfValidator, cnpj as cnpjValidator } from "cpf-cnpj-validator";
import moment from "moment";

import StateStatus from "../../../utils/status";
import { beautifyCpfCnpj } from "../../../utils/beautify";
import {
    BankList,
    BrazilStates,
    countriesListBrazilIncluded,
} from "../../../utils/lists";
import { mask, options_nat } from "../../../utils/consts";
import { getAddressByCep } from "../../../service/viacep.service";
import IdentedField from "../../../common/components/IdentedField";
import TooltipEfex from "../../../common/components/Tooltip";
import {
    selectPerson,
    getPerson,
    resetStatusGetPerson,
} from "../../Person/slice";
import { selectFirstStep, addCadastralData, getCadastralData, resetStatusGetCadastralData, getFindCpf } from "./slice";
import PersonBase from "../common/Base";
import "./styles.scss";
import Routes from "../../../routes/routes";
import { scrollUp, strFormatTimeApi } from "../../../utils/functions";
import { toBrazilianDateString } from "../../../utils/date";
import ErrorMsg from "../../../common/components/ErrorMsg";
import { GreenButton, WhiteButton } from "../../../common/components/Button";
import { EfexCard } from "../../../common/components/Card";
import CurrencyField from "../../../common/components/currency_field/currency_field";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import br from "world_countries_lists/data/countries/en/world.json";
import _ from "lodash";
import EfexModal from "../../../common/components/Modal";
import { SyncOutlined, UserOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
const { Option } = Select;

const FirstStep = (props) => {
    const [form] = Form.useForm();
    const [state, setState] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [valueCountryNumber, setValueCountryNumber] = useState({
        short: "BR",
        code: 55,
        phone: null,
    });
    const [useCep, setUseCep] = useState(false);
    const [finished, setFinished] = useState(false);
    const [findCpfModal, setFindCpfModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const person = useSelector(selectPerson);
    const firstStep = useSelector(selectFirstStep);
    const dispatch = useDispatch();

    const register_update = person.person?.register_update

    useEffect(() => {
        if (person.status.getPerson === StateStatus.succeeded) {
            dispatch(resetStatusGetCadastralData());
        }
    }, [])

    useEffect(() => {
        if (person.status.getPerson === StateStatus.succeeded) {
            setLoading(true)
            dispatch(getFindCpf("pf"))
                .then(resp => {
                    setLoading(false)
                    if (resp.payload) {
                        setFindCpfModal(true)
                    }
                })
        }
    }, [dispatch, person.status.getPerson])

    useEffect(() => {
        if (person.status.getPerson === StateStatus.idle) dispatch(getPerson());
    });

    useEffect(() => {
        if (firstStep.status.getCadastralData === StateStatus.idle) {
            dispatch(getCadastralData()).then(({ payload }) => {
                form.setFieldsValue(payload);
                let data = {
                    ...payload,
                    birthday:
                        payload.birthday !== undefined
                            ? toBrazilianDateString(payload.birthday)
                            : null,
                    doc_shipping_date:
                        payload.doc_shipping_date !== undefined
                            ? toBrazilianDateString(payload.doc_shipping_date)
                            : null,
                    public_agent_end_date:
                        payload.public_agent_end_date !== undefined
                            ? toBrazilianDateString(
                                payload.public_agent_end_date
                            )
                            : null,
                    public_agent_start_date:
                        payload.public_agent_start_date !== undefined
                            ? toBrazilianDateString(
                                payload.public_agent_start_date
                            )
                            : null,
                };

                if (
                    !_.isUndefined(data.cell_phone) &&
                    data.cell_phone.includes("-")
                ) {
                    let phone = data["cell_phone"].split("-");
                    setValueCountryNumber({
                        code: parseInt(phone[0]),
                        phone: phone[1],
                    });
                } else {
                    setValueCountryNumber({
                        short: "BR",
                        code: 55,

                    });
                }
                if ((!_.isUndefined(data['residential_country']) && data['residential_country'].toLowerCase() === 'brasil') || _.isUndefined(data['residential_country'])) {
                    setUseCep(true);
                } else {
                    setUseCep(false);
                }

                form.setFieldValue("birthday", data.birthday)
                form.setFieldValue("doc_shipping_date", data.doc_shipping_date)
            });
        }
    }, [dispatch, firstStep.status.getCadastralData, form]);

    useEffect(() => {
        if (
            firstStep.status.addCadastralData === StateStatus.succeeded &&
            isSaving
        ) {
            setIsSaving(false);
            dispatch(resetStatusGetPerson());
            scrollUp();
            if (register_update) {
                let update_docs = register_update?.identity_document ||
                    register_update?.financial_documentation ||
                    register_update?.proof_of_address

                if (register_update?.kyc) {
                    props.history.push(Routes.secondStep)
                } else if (update_docs) {
                    props.history.push(Routes.fourthStep)
                } else (
                    props.history.push({
                        pathname: Routes.finishedRegister,
                        state: { type: "person" }
                    })
                )
            } else {
                props.history.push(Routes.secondStep);
            }

        }
    }, [dispatch, firstStep.status.addCadastralData, isSaving, props.history]);

    const updateState = (key, value) => {
        state[key] = value;
        setState({ ...state });
    };

    const onFinish = (values) => {
        setIsSaving(true);
        let data = {};
        data = values;
        // TODO: confirmar essa info 'is_pep', provavelmente nao será mais necessário
        data.is_pep = values.is_public_agent;
        data.birthday = strFormatTimeApi(values.birthday);
        data.doc_shipping_date = strFormatTimeApi(values.doc_shipping_date);
        data.addr_zipcode = values.addr_zipcode.replace(/[^a-zA-Z0-9]/g, '');

        if (typeof form.getFieldValue("cell_phone") !== "string") {
            data.cell_phone = `${values.cell_phone.code}-${values.cell_phone.phone}`;
        }

        if (values.spouse_cpf !== undefined)
            data.spouse_cpf = cpfValidator.strip(values.spouse_cpf);

        if (values.public_agent_cnpj !== undefined)
            data.public_agent_cnpj = cnpjValidator.strip(
                values.public_agent_cnpj
            );
        if (values.is_public_agent) {
            data["public_ocupation_details"] = {
                public_agent_occupation: values.public_agent_occupation,
                public_agent_start_date: strFormatTimeApi(
                    values.public_agent_start_date
                ),
                public_agent_end_date: values.public_agent_end_date
                    ? strFormatTimeApi(values.public_agent_end_date)
                    : null,
                public_agent_cnpj: cnpjValidator.strip(
                    values.public_agent_cnpj
                ),
            };
            delete data.public_agent_occupation;
            delete data.public_agent_start_date;
            delete data.public_agent_end_date;
            delete data.public_agent_cnpj;
        }
        if (values.has_public_agent_link) {
            data["public_agent_link_details"] = {
                public_agent_link_name: values.public_agent_link_name,
                public_agent_link_cpf: cpfValidator.strip(
                    values.public_agent_link_cpf
                ),
                public_agent_link_occupation:
                    values.public_agent_link_occupation,
                public_agent_link_type: values.public_agent_link_type,
            };

            delete data.public_agent_link_name;
            delete data.public_agent_link_cpf;
            delete data.public_agent_link_occupation;
            delete data.public_agent_link_type;
        }

        // TODO: decidir qual utilizar, os dois estão obrigatórios
        if (data.addr_complement === "") delete data.addr_complement;
        if (data.father_name === "") data.father_name = null;

        if (register_update) data.is_uploading = true;

        dispatch(addCadastralData(data));
    };

    return (
        <PersonBase
            {...props}
            current={0}
            titleL3="Dados cadastrais"
            text={!register_update ? "Complete as informações do cadastro" : "Atualize as informações do cadastro"}
        >
            {loading ? (
                <SyncOutlined spin style={{ display: "block", margin: "0 auto" }} />
            ) : (
                <Form
                    aria-autocomplete="none"
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    scrollToFirstError={true}
                    onValuesChange={(val, fields) => {
                        if (!_.isUndefined(val["residential_country"])) {
                            if (
                                val["residential_country"].toLowerCase() ===
                                "brasil"
                            ) {
                                setUseCep(true);
                            } else {
                                setUseCep(false);
                            }
                        }
                    }}
                >
                    <div className="person-info-first-step">
                        <Title className="person-title" level={5}>
                            {person.person !== null && person.person.name}
                        </Title>
                        <Text type="secondary">
                            {beautifyCpfCnpj(
                                person.person !== null && person.person.cpf
                            )}
                        </Text>
                    </div>

                    {firstStep.status.addCadastralData === StateStatus.failed ||
                        firstStep.status.getCadastralData === StateStatus.failed ? (
                        <ErrorMsg message={firstStep.errorMsg}></ErrorMsg>
                    ) : null}
                    <Row gutter={[8, 8]}>
                        <Col xs={24} sm={24} lg={12}>
                            <EfexCard>
                                <Title className="subtitle" level={5}>
                                    Dados cadastrais
                                </Title>
                                <Divider />
                                <Form.Item hidden={true} name={"qualification"}>
                                    <Input type="text"></Input>
                                </Form.Item>
                                <Row gutter={[8, 0]} justify="space-between">
                                    <Col xs={24} sm={24} lg={10}>
                                        <Form.Item
                                            label="Tipo e número do documento de identificação"
                                            name={"doc_number"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Documento inválido.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="remove-right-border"
                                                addonBefore={TypeSelector()}
                                                placeholder="Número"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} sm={12} lg={6}>
                                        <Form.Item
                                            label="Orgão emissor"
                                            name={"doc_dispatcher"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Orgão emissor obrigatório",
                                                },
                                                {
                                                    pattern: new RegExp(/^\D{2,}$/),
                                                    message: "Nome inválido",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Nome do orgão"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} sm={12} lg={6}>
                                        <Form.Item
                                            label="UF Emissora"
                                            name="doc_uf"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "UF emissora obrigatório",
                                                },
                                            ]}
                                        >
                                            <Select placeholder="UF">
                                                <Select.Option value="AC" className="uf-option">
                                                    AC
                                                </Select.Option>
                                                <Select.Option value="AL">
                                                    AL
                                                </Select.Option>
                                                <Select.Option value="AP">
                                                    AP
                                                </Select.Option>
                                                <Select.Option value="AM">
                                                    AM
                                                </Select.Option>
                                                <Select.Option value="BA">
                                                    BA
                                                </Select.Option>
                                                <Select.Option value="CE">
                                                    CE
                                                </Select.Option>
                                                <Select.Option value="DF">
                                                    DF
                                                </Select.Option>
                                                <Select.Option value="ES">
                                                    ES
                                                </Select.Option>
                                                <Select.Option value="GO">
                                                    GO
                                                </Select.Option>
                                                <Select.Option value="MA">
                                                    MA
                                                </Select.Option>
                                                <Select.Option value="MS">
                                                    MS
                                                </Select.Option>
                                                <Select.Option value="MT">
                                                    MT
                                                </Select.Option>
                                                <Select.Option value="MG">
                                                    MG
                                                </Select.Option>
                                                <Select.Option value="PA">
                                                    PA
                                                </Select.Option>
                                                <Select.Option value="PB">
                                                    PB
                                                </Select.Option>
                                                <Select.Option value="PR">
                                                    PR
                                                </Select.Option>
                                                <Select.Option value="PE">
                                                    PE
                                                </Select.Option>
                                                <Select.Option value="PI">
                                                    PI
                                                </Select.Option>
                                                <Select.Option value="RJ">
                                                    RJ
                                                </Select.Option>
                                                <Select.Option value="RN">
                                                    RN
                                                </Select.Option>
                                                <Select.Option value="RS">
                                                    RS
                                                </Select.Option>
                                                <Select.Option value="RO">
                                                    RO
                                                </Select.Option>
                                                <Select.Option value="RR">
                                                    RR
                                                </Select.Option>
                                                <Select.Option value="SC">
                                                    SC
                                                </Select.Option>
                                                <Select.Option value="SP">
                                                    SP
                                                </Select.Option>
                                                <Select.Option value="SE">
                                                    SE
                                                </Select.Option>
                                                <Select.Option value="TO">
                                                    TO
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]} justify="space-between">
                                    <Col xs={24} sm={24} lg={12}>
                                        <Form.Item
                                            label="Data de expedição"
                                            name={"doc_shipping_date"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Campo obrigatório",
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        const dateOnlyNumbers =
                                                            value.replace(
                                                                /\D/g,
                                                                ""
                                                            );
                                                        const date = moment(
                                                            value,
                                                            "DD/MM/YYYY"
                                                        );
                                                        if (
                                                            dateOnlyNumbers.length <
                                                            8 ||
                                                            !date.isValid()
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    "Data inválida"
                                                                )
                                                            );
                                                        } else {
                                                            return Promise.resolve();
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <MaskedInput
                                                mask={mask.date}
                                            ></MaskedInput>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} lg={12}>
                                        <Form.Item
                                            label="Data de nascimento"
                                            name={"birthday"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Data obrigatória",
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        const dateOnlyNumbers =
                                                            value.replace(
                                                                /\D/g,
                                                                ""
                                                            );
                                                        const date = moment(
                                                            value,
                                                            "DD/MM/YYYY"
                                                        );
                                                        const actualDate =
                                                            new Date();
                                                        const miniumAge = moment([
                                                            actualDate.getFullYear() -
                                                            18,
                                                            actualDate.getMonth(),
                                                            actualDate.getDate(),
                                                        ]);
                                                        if (
                                                            dateOnlyNumbers.length <
                                                            8 ||
                                                            !date.isValid()
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    "Data inválida"
                                                                )
                                                            );
                                                        } else if (
                                                            date.isBefore(miniumAge)
                                                        ) {
                                                            return Promise.resolve();
                                                        } else {
                                                            return Promise.reject(
                                                                new Error(
                                                                    "Não pode ser menor de idade"
                                                                )
                                                            );
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <MaskedInput
                                                mask={mask.date}
                                            ></MaskedInput>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} lg={24}>
                                        <Form.Item
                                            label={
                                                <>
                                                    E-mail
                                                    <TooltipEfex title="Enviaremos a ficha cadastral e os contratos de câmbio para assinatura" />
                                                </>
                                            }
                                            name={"email"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Informe o email.",
                                                },
                                                {
                                                    type: "email",
                                                    message:
                                                        "Informe um email válido.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="E-mail"
                                                type="email"
                                            ></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} lg={24}>
                                        <Form.Item
                                            label="Profissão"
                                            name={"profession"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Profissão inválida.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Profissão"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} lg={12}>
                                        <Form.Item
                                            label="Nome completo da mãe"
                                            name={"mother_name"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Nome obrigatório",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Nome completo"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} lg={12}>
                                        <Form.Item
                                            label="Nome completo do pai"
                                            name={"father_name"}
                                        >
                                            <Input
                                                placeholder="Nome completo"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} lg={24}>
                                        <Form.Item
                                            initialValue={0}
                                            label="Renda mensal aproximada"
                                            name={"monthly_income"}
                                        >
                                            <CurrencyField
                                                style={{ width: "100%" }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]} justify="space-between">
                                    <Col xs={24} sm={24} lg={12}>
                                        <Form.Item
                                            name={"gender"}
                                            label="Gênero"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Seleção Obrigatória.",
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Escolher">
                                                <Option value="M" className="gender-option">Masculino</Option>
                                                <Option value="F">Feminino</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} lg={12}>
                                        <Form.Item
                                            label="Telefone"
                                            name={"cell_phone"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Estado inválido.",
                                                },
                                            ]}
                                        >
                                            <ConfigProvider locale={br}>
                                                <CountryPhoneInput
                                                    className="register-country-phone"
                                                    onChange={(v) => {
                                                        form.setFieldsValue({
                                                            cell_phone: v,
                                                        });
                                                        setValueCountryNumber(v);
                                                    }}
                                                    value={valueCountryNumber}
                                                />
                                            </ConfigProvider>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item
                                    label="Cidade natal"
                                    name={"birthplace"}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Nome inválido.",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Nome completo"
                                        type="text"
                                    ></Input>
                                </Form.Item>
                                <Row gutter={[8, 0]} justify="space-between">
                                    <Col xs={24} sm={24} lg={12}>
                                        <Form.Item
                                            label="País onde mora"
                                            name={"residential_country"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Nome inválido.",
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                                onChange={(e) => {
                                                    const resetAddressFields = {
                                                        addr_public_place: undefined,
                                                        addr_neighborhood: undefined,
                                                        addr_city_name: undefined,
                                                        addr_province: undefined,
                                                        addr_zipcode: undefined,
                                                        addr_complement: undefined,
                                                        addr_number: undefined,
                                                    };
                                                    form.setFieldsValue(resetAddressFields);
                                                    setFinished(false)
                                                }}

                                            >
                                                {countriesListBrazilIncluded.map(
                                                    (item) => {
                                                        return (
                                                            <Option
                                                                key={item.pais}
                                                                value={item.pais}
                                                                className="residential-country-option"
                                                            >
                                                                {item.pais}
                                                            </Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} lg={12}>
                                        <Form.Item
                                            label="Nacionalidade"
                                            name={"nationality"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Nome inválido.",
                                                },
                                            ]}
                                        >
                                            <Select >
                                                {options_nat.map(
                                                    (item) => {
                                                        return (
                                                            <Option
                                                                key={item.value}
                                                                value={item.value}
                                                                className="nationality-option"
                                                            >
                                                                {item.pais}
                                                            </Option>
                                                        );
                                                    }
                                                )} </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item
                                    name={"marital_status"}
                                    label="Estado civil"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Seleção Obrigatória.",
                                        },
                                    ]}
                                >
                                    <Select
                                        onSelect={(value) =>
                                            updateState(`marital`, value)
                                        }
                                    >
                                        <Option className="marital-status-option" value="C">Casado(a)</Option>
                                        <Option value="S">Solteiro(a)</Option>
                                        <Option value="D">Divorciado(a)</Option>
                                        <Option value="V">Viúvo(a)</Option>
                                        <Option value="U">União Estável</Option>
                                    </Select>
                                </Form.Item>
                                {state[`marital`] === "C" ||
                                    state[`marital`] === "U" ? (
                                    <IdentedField>
                                        <Form.Item
                                            label="Nome do cônjuge"
                                            name={"spouse_name"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Nome inválido.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Nome completo"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                        <Form.Item
                                            label="CPF do cônjuge"
                                            name={"spouse_cpf"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Informe o CPF",
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        if (
                                                            !value ||
                                                            cpfValidator.isValid(
                                                                value
                                                            )
                                                        ) {
                                                            return Promise.resolve();
                                                        }

                                                        return Promise.reject(
                                                            new Error(
                                                                "CPF inválido."
                                                            )
                                                        );
                                                    },
                                                }),
                                            ]}
                                        >
                                            <MaskedInput
                                                mask={mask.cpf}
                                            ></MaskedInput>
                                        </Form.Item>
                                    </IdentedField>
                                ) : null}
                                <Form.Item
                                    initialValue={form.getFieldValue('addr_zipcode')}
                                    label="CEP"
                                    name={"addr_zipcode"}
                                    value
                                    rules={[
                                        {
                                            required: true,
                                            message: "CEP inválido.",
                                        },
                                    ]}
                                >
                                    {useCep && !finished ? (
                                        <MaskedInput
                                            autoComplete="false"
                                            mask={mask.cep}
                                            value={form.getFieldValue(
                                                "addr_zipcode"
                                            )}
                                            onChange={(e) => {
                                                let val = e.target.value;
                                                form.setFieldsValue({ 'addr_zipcode': e.target.value });
                                                const zipcode = val.replace(/\D/g, "");
                                                if (zipcode.length === 8) {
                                                    getAddressByCep(zipcode).then((response) => {
                                                        let fields = {};
                                                        fields.addr_public_place = response.logradouro;
                                                        fields.addr_neighborhood = response.bairro;
                                                        fields.addr_city_name = response.localidade;
                                                        fields.addr_province = response.uf;
                                                        form.setFieldsValue(fields);
                                                    });
                                                }
                                            }}></MaskedInput>)
                                        : <Input
                                            placeholder="Código Postal" type="text"
                                            onChange={(e) => {
                                                let val = e.target.value;
                                                form.setFieldsValue({ 'addr_zipcode': e.target.value });
                                                const zipcode = val.replace(/\D/g, "");
                                                if (zipcode.length === 0) {
                                                    setFinished(false)
                                                }
                                            }}></Input>}
                                </Form.Item>
                                <Row gutter={[8, 0]} justify="space-between">
                                    <Col xs={24} sm={24} lg={16}>
                                        <Form.Item
                                            label="Logradouro"
                                            name={"addr_public_place"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Endereço inválido.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Informar"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} lg={8}>
                                        <Form.Item
                                            label="Número"
                                            name={"addr_number"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Número inválido.",
                                                },
                                            ]}
                                        >
                                            <Input type="text"></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]} justify="space-between">
                                    <Col xs={24} sm={24} lg={12}>
                                        <Form.Item
                                            label="Bairro"
                                            name={"addr_neighborhood"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Bairro inválido.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Informar"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} lg={12}>
                                        <Form.Item
                                            label="Complemento"
                                            name={"addr_complement"}
                                            rules={[
                                                {
                                                    message:
                                                        "Complemento inválido.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Informar"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]} justify="space-between">
                                    <Col xs={24} sm={24} lg={19}>
                                        <Form.Item
                                            label="Cidade"
                                            name={"addr_city_name"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Cidade inválido.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Informar"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} lg={5}>
                                        <Form.Item
                                            label="Estado"
                                            name={"addr_province"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Estado inválido.",
                                                },
                                            ]}
                                        >
                                            {form.getFieldValue("residential_country") === "Brasil" || form.getFieldValue("residential_country") === undefined ? (
                                                <Select
                                                    placeholder="Informar"
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {BrazilStates.map((item) => (
                                                        <Option key={item.key} value={item.key}>
                                                            {item.value}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            ) : (
                                                <Input
                                                    placeholder="Informar"
                                                    type="text" />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Title level={5} className="subtitle">
                                    Dados bancários
                                </Title>
                                <Divider />
                                <Form.Item
                                    label="Banco"
                                    name={"bank_name"}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Nome do banco inválido.",
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        onChange={(value) => {
                                            const bank = BankList.filter(
                                                (item) => item.value === value
                                            )[0].key.split(" | ");
                                            form.setFieldsValue({
                                                bank_code: bank[0],
                                            });
                                        }}
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {BankList.map((item) => (
                                            <Option
                                                key={item.key}
                                                value={item.value}
                                                className="bank-name-option"
                                            >
                                                {item.key}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    style={{ display: "none" }}
                                    name="bank_code"
                                >
                                    <Input></Input>
                                </Form.Item>
                                <Row justify="space-between">
                                    <Col xs={11} sm={11} lg={11}>
                                        <Form.Item
                                            label="Agência"
                                            name={"agency_number"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Cidade inválido.",
                                                },
                                            ]}
                                            labelCol={{ span: 24, whiteSpace: 'nowrap' }}
                                        >
                                            <Input
                                                placeholder="Informar"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={11} sm={11} lg={11}>
                                        <Form.Item
                                            label="Número da conta"
                                            name={"bank_account_number"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Cidade inválido.",
                                                },
                                            ]}
                                            labelCol={{ span: 24, whiteSpace: 'nowrap' }}
                                        >
                                            <Input
                                                placeholder="Informar"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </EfexCard>
                        </Col>
                        <Col xs={24} sm={24} lg={12}>
                            <EfexCard>
                                <Title className="subtitle" level={5}>
                                    Dados autodeclarados
                                </Title>
                                <Form.Item
                                    shouldUpdate={(prevValues, curValues) => {
                                        if (
                                            prevValues.is_public_agent !==
                                            curValues.is_public_agent
                                        ) {
                                            updateState(
                                                `publicAgent`,
                                                curValues.is_public_agent
                                            );
                                        }
                                    }}
                                    name={"is_public_agent"}
                                    label="Ocupou cargo, emprego ou função pública no Brasil ou no exterior nos últimos cinco anos?"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Seleção Obrigatória.",
                                        },
                                    ]}
                                >
                                    <Select
                                        onSelect={(value) =>
                                            updateState(`publicAgent`, value)
                                        }
                                    >
                                        <Option value={true} className="is-public-agent-option">Sim</Option>
                                        <Option value={false}>Não</Option>
                                    </Select>
                                </Form.Item>
                                {state[`publicAgent`] ? (
                                    <IdentedField>
                                        <Form.Item
                                            label="CNPJ"
                                            name={"public_agent_cnpj"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Informe o CNPJ",
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        if (
                                                            !value ||
                                                            cnpjValidator.isValid(
                                                                value
                                                            )
                                                        ) {
                                                            return Promise.resolve();
                                                        }

                                                        return Promise.reject(
                                                            new Error(
                                                                "CNPJ inválido."
                                                            )
                                                        );
                                                    },
                                                }),
                                            ]}
                                        >
                                            <MaskedInput
                                                mask={mask.cnpj}
                                            ></MaskedInput>
                                        </Form.Item>
                                        <Form.Item
                                            label="Ocupação"
                                            name={"public_agent_occupation"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Valor inválido.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Informe"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                        <Row justify="space-between">
                                            <Form.Item
                                                label="Data de início"
                                                name={"public_agent_start_date"}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Data obrigatória",
                                                    },
                                                    () => ({
                                                        validator(_, value) {
                                                            const dateOnlyNumbers =
                                                                value.replace(
                                                                    /\D/g,
                                                                    ""
                                                                );
                                                            const date = moment(
                                                                value,
                                                                "DD/MM/YYYY"
                                                            );
                                                            if (
                                                                dateOnlyNumbers.length <
                                                                8 ||
                                                                !date.isValid()
                                                            ) {
                                                                return Promise.reject(
                                                                    new Error(
                                                                        "Data inválida"
                                                                    )
                                                                );
                                                            } else {
                                                                return Promise.resolve();
                                                            }
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <MaskedInput
                                                    mask={mask.date}
                                                ></MaskedInput>
                                            </Form.Item>
                                            <Form.Item
                                                label="Data de término"
                                                name={"public_agent_end_date"}
                                            >
                                                <MaskedInput
                                                    mask={mask.date}
                                                ></MaskedInput>
                                            </Form.Item>
                                        </Row>
                                    </IdentedField>
                                ) : null}
                                <Form.Item
                                    shouldUpdate={(prevValues, curValues) => {
                                        if (
                                            prevValues.has_public_agent_link !==
                                            curValues.has_public_agent_link
                                        ) {
                                            updateState(
                                                `publicLink`,
                                                curValues.has_public_agent_link
                                            );
                                        }
                                    }}
                                    name={"has_public_agent_link"}
                                    label="É casado, parente ou tem relação de proximidade com pessoa que ocupou cargo, emprego ou função pública no Brasil ou no exterior nos últimos cinco anos?"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Seleção Obrigatória.",
                                        },
                                    ]}
                                >
                                    <Select
                                        onSelect={(value) => {
                                            updateState(`publicLink`, value);
                                        }}
                                    >
                                        <Option value={true} className="has-public-agent-link-option">Sim</Option>
                                        <Option value={false}>Não</Option>
                                    </Select>
                                </Form.Item>
                                {state[`publicLink`] ? (
                                    <IdentedField>
                                        <Form.Item
                                            label="Nome completo da pessoa"
                                            name={"public_agent_link_name"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Nome inválido.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Informar"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                        <Form.Item
                                            label="CPF da pessoa"
                                            name={"public_agent_link_cpf"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "CPF inválido.",
                                                },
                                            ]}
                                        >
                                            <MaskedInput
                                                mask={mask.cpf}
                                            ></MaskedInput>
                                        </Form.Item>
                                        <Form.Item
                                            label="Ocupação da pessoa"
                                            name={"public_agent_link_occupation"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Ocupação inválida.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Informar"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                        <Form.Item
                                            label="Tipo do relacionamento com a pessoa"
                                            name={"public_agent_link_type"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Relacionamento inválido.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Informar"
                                                type="text"
                                            ></Input>
                                        </Form.Item>
                                    </IdentedField>
                                ) : null}
                                <Form.Item
                                    name={"is_us_person"}
                                    label={
                                        <>
                                            É US person?{" "}
                                            <TooltipEfex title="Tem Cidadania norte-americana, incluindo dupla nacionalidade e passaporte norte-americano, ainda que resida fora dos Estados Unidos; tem residência fiscal nos EUA, ou seja, que possui Green Card e pode se tornar um residente permanente legalizado; esteve presente nos Estados Unidos para fins tributários por pelo menos 31 dias neste ano ou 183 dias nos últimos 3 anos." />
                                        </>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Seleção Obrigatória.",
                                        },
                                    ]}
                                >
                                    <Select>
                                        <Option value={true}>Sim</Option>
                                        <Option value={false} className="is-us-person-option">Não</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name={"has_link_with_bs2"}
                                    label={
                                        <>
                                            Tem relação com o Banco BS2?{" "}
                                            <TooltipEfex title="É colaborador direto/indireto do Banco BS2" />
                                        </>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Seleção Obrigatória.",
                                        },
                                    ]}
                                >
                                    <Select>
                                        <Option value={true}>Sim</Option>
                                        <Option value={false} className="has-link-with-bs2-option">Não</Option>
                                    </Select>
                                </Form.Item>
                            </EfexCard>
                        </Col>
                    </Row>
                    <Form.Item className="btn-back-next">
                        <WhiteButton
                            hoverColor="green"
                            onClick={() => {
                                props.history.push(Routes.people);
                            }}
                        >
                            Voltar
                        </WhiteButton>
                        <GreenButton
                            style={{ fontWeight: "500", width: "100px" }}
                            type="primary"
                            htmlType="submit"
                            loading={
                                firstStep.status.addCadastralData ===
                                StateStatus.loading
                            }
                        >
                            {firstStep.status.addCadastralData ===
                                StateStatus.loading
                                ? "Carregando"
                                : register_update
                                    ? "Atualizar"
                                    : "Avançar"}
                        </GreenButton>
                    </Form.Item>
                </Form>
            )}

            {findCpfModal &&
                <EfexModal
                    className={"modal-person"}
                    error={person.errorMsg}
                    visible={true}
                    // confirmLoading={person.status.addPerson === StateStatus.loading}
                    onCancel={() => {
                        setFindCpfModal(false);
                    }}
                    title="Encontrado"
                    footer={
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "end",
                            }}
                        >
                            <WhiteButton
                                onClick={() => {
                                    setFindCpfModal(false);
                                }}
                            >
                                Cancelar
                            </WhiteButton>
                            <div style={{ width: "10px" }}></div>
                            <GreenButton
                                loading={
                                    person.status.addPerson === StateStatus.loading
                                }
                                onClick={() => {
                                    form.setFieldsValue(firstStep.getFindCpf.financial_responsible)
                                    setFindCpfModal(false)
                                }}
                            >
                                Sim
                            </GreenButton>
                        </div>
                    }
                >
                    <div className="box_info"
                        style={{
                            fontSize: "14px",
                            lineHeight: "25px",
                            marginBottom: "20px",
                        }}
                    >
                        <p>Encontramos este cpf cadastrado em responsaveis financeiros, deseja reutilizar alguns dados?</p>
                        <div className="info">
                            <Avatar icon={<UserOutlined />} />
                            <p>{firstStep.getFindCpf?.financial_responsible.name}</p>
                        </div>
                    </div>
                </EfexModal>}
        </PersonBase>
    );
};

export default FirstStep;

const TypeSelector = () => (
    <Form.Item
        name={"doc_type"}
        rules={[
            {
                required: true,
                message: "Valor inválido.",
            },
        ]}
        noStyle
    >
        <Select placeholder="Escolher">
            <Option value="CNH" className="doc-type-option">CNH</Option>
            <Option value="RG">RG</Option>
            <Option value="RNE">RNE</Option>
        </Select>
    </Form.Item>
);
