import React from "react";
import { Modal, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useRouteMatch } from "react-router-dom";
import "./styles.scss";


const EfexModal = (props) => {
    let { path } = useRouteMatch();

    return (
        <Modal
        footer={
            <Space>    
                {(props.hasCancel === true || props.hasCancel === undefined) && (
                    <button 
                        className={`
                            ${path.match("empresas") ? "white-button-orange-hover" : "white-button-green-hover"}
                        `}
                        onClick={props.onDelete || props.onCancel}
                    >
                        {props.cancelText || "Cancelar"}
                    </button>
                )}

                <button
                    className={`
                        ${path.match("empresas") ? "efex-orange-button" : "efex-green-button"}
                    `}
                    type="primary"
                    onClick={props.onOk}
                    disabled={props.disabled}
                >
                    {props.confirmLoading ? <LoadingOutlined /> : null} {props.okText || "Ok"}
                </button>
            </Space>
        }
            {...props}
        />
    );
};

export default EfexModal;
