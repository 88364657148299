import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, Button, Typography, Table, Tooltip, message } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import { getContactSend, removeBankAccount, selectContactSend, resetStatusRemoveBankAccount } from "../slice";
import StateStatus from "../../../../../utils/status";
import "./styles.scss";
import ContactSendModal from "../../../_components/ContactSendModal";
import BankAccountSendModal from "../../../_components/BankAccountSendModal";
import {
    GreenButton,
    OrangeButton,
} from "../../../../../common/components/Button";
import { useRouteMatch } from "react-router-dom";
import { EfexCard } from "../../../../../common/components/Card";

const { Title } = Typography;

const CreateContactSend = (props) => {
    const [state, setState] = useState({});
    const [showContactModal, setShowContactModal] = useState(false);
    const [showCreateBankModal, setCreateShowBankModal] = useState(false);
    const [showBankModal, setShowBankModal] = useState([]);
    const dispatch = useDispatch();
    const contact = useSelector(selectContactSend);
    let { url } = useRouteMatch();

    useEffect(() => {
        if (contact.status.getContactSend === StateStatus.idle) {
            dispatch(getContactSend()).then(({ payload }) => {
                if (payload !== undefined && payload.id) {
                    updateState("accounts", payload.accounts);
                    updateState("account", null);
                }
            });
        }
    });

    useEffect(() => {
        if (contact.status.removeBankAccount === StateStatus.succeeded) {
            message.success({
                key: "delete-msg",
                content: "Conta bancária excluída",
                duration: 3
            })
            dispatch(resetStatusRemoveBankAccount())
        }

        if (contact.status.removeBankAccount === StateStatus.failed) {
            message.error({
                key: "delete-msg",
                content: "Esta conta bancária está atrelada a operações e por isso não pôde ser excluída",
                duration: 5
            })
            dispatch(resetStatusRemoveBankAccount())
        }
    }, [contact.status.removeBankAccount])

    const updateState = (key, value) => {
        state[key] = value;
        setState({ ...state });
    };

    const columnsAccounts = [
        {
            title: "Banco",
            render: (obj) => (
                <>
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>{obj.bank_name}</Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <div>
                                <span className="feature">IBAN: </span>
                                {obj.iban_code}
                            </div>
                            <div>
                                <span className="feature">SWIFT: </span>
                                {obj.swift_code}
                            </div>
                            <div>
                                <span className="feature">ABA/BIC: </span>
                                {obj.aba_bic_code}
                            </div>
                        </Col>
                    </Row>
                </>
            ),
        },
        { title: "Moeda", dataIndex: "currency" },
        { title: "Agência", dataIndex: "agency_number" },
        { title: "Número da conta", dataIndex: "account_number" },
        {
            title: "",
            render: (item) => (
                <>
                    <Row justify="space-between">
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <EditOutlined
                                onClick={() => {
                                    updateState("account", item);
                                    let show = [];
                                    show[item.id] = true;
                                    setShowBankModal(show);
                                }}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <DeleteOutlined
                                onClick={() => {
                                    dispatch(removeBankAccount(item.id))
                                }}
                            />
                        </Col>
                    </Row>
                    <BankAccountSendModal
                        visible={showBankModal[item.id]}
                        data={item}
                        onOk={(values) => {
                            let show = [];
                            show[item.id] = false;
                            setShowBankModal(show);
                        }}
                        onCancel={() => {
                            let show = [];
                            show[item.id] = false;
                            setShowBankModal(show);
                        }}
                    />
                </>
            ),
        },
    ];

    return (
        <div className="contact-send">
            <Row gutter={[24, 0]} justify="space-between" align="middle">
                <Col style={{ padding: "0 16px" }}>
                    <Title level={5}>{contact.contactSend.name}</Title>
                </Col>
                <Col>
                    {url.includes("empresas") ? (
                        <OrangeButton
                            id="button-add-contact-top"
                            onClick={() => {
                                setShowContactModal(true);
                            }}
                        >
                            Editar
                        </OrangeButton>
                    ) : (
                        <GreenButton
                            id="button-add-contact-top"
                            onClick={() => {
                                setShowContactModal(true);
                            }}
                        >
                            Editar
                        </GreenButton>
                    )}

                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col sm={24}>
                    <Row gutter={[16, 16]} className="contact-data">
                        <Col xs={24} sm={12} className="stretch-card">
                            <Card>
                                <Title level={5}>Contato de Envio</Title>
                                <br />
                                <Row gutter={[4, 0]}>
                                    <Col sm={24} lg={12}>
                                        <div>
                                            <label>Tipo de contato</label>
                                            <div>
                                                {contact.contactSend.type ===
                                                "company"
                                                    ? "Empresa"
                                                    : "Pessoa"}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={24} lg={12}>
                                        <div>
                                            <label>
                                                {contact.contactSend.type ===
                                                "company"
                                                    ? "Razão social"
                                                    : "Nome completo"}
                                            </label>
                                            <div>
                                                {contact.contactSend.name}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={[4, 0]}>
                                    {contact.contactSend.type === "company" && (
                                        <Col sm={24} lg={12}>
                                            <div>
                                                <label>
                                                    É orgão governamental?
                                                </label>
                                                <div>
                                                    {contact.contactSend
                                                        .is_governmental
                                                        ? "Sim"
                                                        : "Não"}
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} className="stretch-card">
                            <Card>
                                <Title level={5}>
                                    {state.type === "company"
                                        ? "Endereço de domicílio"
                                        : "Endereço residencial"}
                                </Title>
                                <br />
                                <Row gutter={[4, 0]} justify="space-between">
                                    <Col sm={24} lg={16}>
                                        <label>Cidade</label>
                                        <div>
                                            {contact.contactSend.addr_city_name}
                                        </div>
                                    </Col>
                                    <Col sm={24} lg={8}>
                                        <label>País</label>
                                        <div>
                                            {contact.contactSend.addr_country}
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={[4, 0]} justify="space-between">
                                    <Col sm={24} lg={16}>
                                        <label>Endereço</label>
                                        <div>{contact.contactSend.addr}</div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <EfexCard>
                <Row gutter={[24, 0]} justify="start" align="middle">
                    <Row
                        align="middle"
                        style={{
                            justifyContent: "space-between",
                            marginBottom: "16px",
                            padding: "0 16px",
                            width: "100%",
                        }}
                    >
                        <Title level={5}>Contas Bancárias</Title>
                        {url.includes("empresas") ? (
                            <OrangeButton
                                width={500}
                                onClick={() => {
                                    updateState("account", null);
                                    setCreateShowBankModal(true);
                                }}
                            >
                                Nova conta
                            </OrangeButton>
                        ) : (
                            <GreenButton
                                onClick={() => {
                                    updateState("account", null);
                                    setCreateShowBankModal(true);
                                }}
                            >
                                Nova conta
                            </GreenButton>
                        )}
                        <BankAccountSendModal
                            visible={showCreateBankModal}
                            data={{}}
                            onOk={(values) => {
                                setCreateShowBankModal(false);
                            }}
                            onCancel={() => {
                                setCreateShowBankModal(false);
                            }}
                        />
                    </Row>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col sm={24}>
                        <Table
                            className="bank-account-table"
                            pagination={false}
                            dataSource={contact.contactSend.bank_accounts}
                            columns={columnsAccounts}
                            loading={
                                contact.status.getContactSend ===
                                StateStatus.loading
                            }
                        />
                    </Col>
                </Row>
            </EfexCard>
            <ContactSendModal
                visible={showContactModal}
                data={contact.contactSend}
                onOk={() => {
                    setShowContactModal(false);
                }}
                onCancel={() => {
                    setShowContactModal(false);
                }}
            />
        </div>
    );
};

export default CreateContactSend;
