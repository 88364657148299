import { Layout } from "antd";
import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Forgot from "./features/ForgotPassword/Forgot";
import Register from "./features/InitialRegister/InitialRegister";
import Login from "./features/Login/Login";
import Routes from "./routes/routes";
import ChangePassword from "./features/ChangePassword/ChangePassword";
import Dashboard from "./features/Dashboard/Dashboard";
import "./App.scss";
import EfexFooter from "./common/components/EfexFooter/EfexFooter";
import EfexHeader from "./common/components/EfexHeader";
import WhatsAppIcon from "./common/components/WhatsAppIcon";

const { Content } = Layout;

const App = () => {
	return (
		<Layout>
			<Router basename="/" hashType="slash">
				<Switch>
					<Route path={Routes.dashboard} component={EfexHeader} />
				</Switch>
			</Router>
			<Content style={{ overflow: 'initial' }}>
				<Router basename="/" hashType="slash">
					<Switch>
						<Route
							exact
							path={["/", Routes.login]}
							component={Login}
						/>
						<Route
							exact
							path={Routes.register}
							component={Register}
						/>
						<Route
							exact
							path={Routes.forgotPassword}
							component={Forgot}
						/>
						<Route
							path={Routes.changePassword}
							component={ChangePassword}
						/>
						<Route path={Routes.dashboard} component={Dashboard} />
					</Switch>
				</Router>
			</Content>
			<WhatsAppIcon />
			<EfexFooter />
		</Layout>
	);
};

export default App;
