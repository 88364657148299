import api from "../../service/api";
import apiRoutes from "../../service/apiRoutes";
import qs from "qs";

const paramsSerializer = params => { return qs.stringify(params) } // fix array query param

export const addPerson = (data) => api.post(apiRoutes.person, data)

export const getPeople = (params) => api.get(apiRoutes.person, { params })

export const getPerson_ = (params) => api.get(`${apiRoutes.person}profile`, { params })

export const getTransactions = (params) => api.get(apiRoutes.transactions, { params, paramsSerializer })

export const sendValueDuplicate = async (data) => api.post(`${apiRoutes.sendValue}duplicate`, data)

export const receiveValueDuplicate = async (data) => api.post(`${apiRoutes.receiveValue}/duplicate`, data)

export const deletePerson = (person_id) => api.delete(apiRoutes.person, { params: { person_id } })