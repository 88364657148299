import axios from "axios";

export const getAddressByCep = (cep) => {
  let url = `https://viacep.com.br/ws/${cep}/json`;

  return axios
    .get(url)
    .then((response) => {
      // TODO: necessário salvar no localStorage? localStorage.setItem('address', JSON.stringify(response.data));
      return response.data;
    })
    .catch((response) => response);
};

